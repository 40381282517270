import React, { Component } from 'react';
import PropTypes from 'prop-types';

class PublicLayoutComponent extends Component {
	render() {
		return (
			<div>
				<div className="auth">
					<header className="auth__sidebar">
						<img src="/static/media/sola-logo.svg" alt="Sola Logo" className="auth__sidebar__logo" />
					</header>
					<div className="auth__main">{this.props.children}</div>
				</div>
			</div>
		);
	}
}
PublicLayoutComponent.propTypes = {
	children: PropTypes.node.isRequired,
};

export default PublicLayoutComponent;
