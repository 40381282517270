import React, { Component, Fragment } from 'react';
import { get, isEmpty, replace, toNumber, isNaN } from 'lodash';
import { NumericFormat as NumberFormat } from 'react-number-format';
import PropTypes from 'prop-types';
import { Toggle, ToggleContainer } from '../../common/components/toggle';
import { CheckboxTxtInput } from '../../common/components/checkbox-text-input';
import { FileDropzoneComponent } from '../../common/components/file-dropzone';
import { YesNoRadio } from '../../common/components/yes-no-radio';
import { DropdownContainer } from '../../common/components/dropdown-container';
import { ProcessorDropdownComponent } from '../../common/components/processor-dropdown';
import ZeroAsEmptyNumberFormat from '../../common/components/zero-as-empty-number-format/ZeroAsEmptyNumberFormat';
import { isFd, isElavon } from '../../common/utilities';
import { fileTypes } from '../../common/components/file-dropzone/fileTypeConstants';

const allowedFileTypes = [fileTypes.jpeg, fileTypes.png, fileTypes.bmp, fileTypes.txt, fileTypes.pdf];
export default class USSetupForm extends Component {
	dotRegex = /\./g;
	fixFieldName = field => replace(field, this.dotRegex, '_');

	handleToggleCheckboxChange = e => {
		e.target.checked = !e.target.checked;
		this.props.handleChange(e);
	};

	getPercentage = key => parseFloat(this.props.setup.processingDetails[`${key}TransactionsPercentage`] || 0);

	getTotalProcessing = () => {
		const { setup } = this.props;
		if (!setup) return 0;
		return this.getPercentage('swiped') + this.getPercentage('keyed') + this.getPercentage('internet');
	};

	checkIfAllowedValue = (min, max) => ({ value }) => {
		if (isNaN(toNumber(value))) return 0 >= min && 0 <= max;
		return toNumber(value) >= min && toNumber(value) <= max;
	};

	handlePSDependantValuesChanged = event => {
		const { value, name } = event.target;
		const { proposedSavings } = this.props.setup;
		const proposedSavingsEvent = { target: { name: 'proposedSavings', value: null } };

		const defaultAmountValues = {
			amexSavingsPercentage: 0,
			combinedSavingsPercentage: 0,
			bankcardSavingsPercentage: 0,
			monthlyDollarSavingsAmounts: 0,
		};
		if (name === 'proposedSavings_type') {
			proposedSavingsEvent.target.value = {
				...defaultAmountValues,
				type: value,
				rateReductionType: 'Unknown',
			};
		} else if (name === 'proposedSavings_rateReductionType') {
			proposedSavingsEvent.target.value = {
				...defaultAmountValues,
				rateReductionType: value,
				type: proposedSavings.type,
			};
		} else if (name === 'additionalSetupDetails_isCurrentlyAcceptingCreditCards') {
			proposedSavingsEvent.target.value = {
				...defaultAmountValues,
				type: 'Unknown',
				rateReductionType: 'Unknown',
			};
			this.props.handleChange(event, () => this.props.handleChange(proposedSavingsEvent));
			return;
		}
		this.props.handleChange(proposedSavingsEvent);
	};

	render() {
		const { setup } = this.props;
		return (
			<ToggleContainer>
				{this.renderGeneralSection(setup)}
				{this.renderProposedSavingsSection(setup)}
				{this.renderPricingStructureSection(setup)}
				{this.renderAmexEntitlementSection(setup)}
				{this.renderProposedFeesSection(setup)}
				{this.renderEIDSEnrollmentSection(setup)}
				{this.renderInternalNotes(setup)}
			</ToggleContainer>
		);
	}

	renderField(field, label, placeholder) {
		const { setup } = this.props;
		const name = this.fixFieldName(field);
		return (
			<div className="form__group">
				<div className="form__group__header">
					<label className="form__group__label" htmlFor={name}>
						{label}{' '}
					</label>
				</div>
				<input
					id={name}
					name={name}
					type="text"
					placeholder={placeholder}
					className="input input--med"
					value={get(setup, field)}
					onChange={this.props.handleChange}
				/>
			</div>
		);
	}

	renderInternalNotes() {
		return (
			<Card title="Internal Notes" tooltip="This will be included in the Setup Form sent to Applications.">
				{this.renderField('internalNotes', 'Note', 'Notes')}
			</Card>
		);
	}

	renderEIDSEnrollmentSection(setup) {
		if (!setup.eidsEnrollmentDetails || !isFd(setup)) return null;
		return (
			<Card title="EIDS Enrollment" showSeparator={true}>
				<div>
					<div>
						<div className="flex--primary flex--gap--tny spc--bottom--med">
							<p className="form__group__label">Enroll with EIDS (Electronic Integrated Disputes SystemSM)</p>
							<span className="form__group__required" data-tooltip="Required">
								*
							</span>
						</div>
						<DropdownContainer
							type="radio"
							selectName="eidsEnrollmentDetails_shouldEnrollEids"
							toBooleanTrueFalse={true}
							value={setup.eidsEnrollmentDetails.shouldEnrollEids}
							onChange={this.props.handleChange}
							labelText=""
							options={[
								{
									value: true,
									text: 'Yes',
									children: (
										<div className="row">
											<div className="col col-sml-12 col-lrg-6 form__group">
												<div className="form__group__header">
													<label className="form__group__label" htmlFor="eidsEnrollmentDetails_eidsMerchantPrice">
														Enter price to bill merchant (cost is $3.95):
													</label>
												</div>
												<input
													className="input input--med spc--bottom--lrg"
													id="eidsEnrollmentDetails_eidsMerchantPrice"
													name="eidsEnrollmentDetails_eidsMerchantPrice"
													type="decimal"
													value={setup.eidsEnrollmentDetails.eidsMerchantPrice}
													onChange={this.props.handleChange}
												/>
												<div className="notes notes--primary">
													<i className="icon"></i>
													<p className="type--p3">
														Please note, if you would like to bill more than $5.95, you'll need to obtain a signed form
														from the merchant authorizing the charge. Banking Services will follow up with you
														accordingly for a signature.
													</p>
												</div>
											</div>

											<div className="col col-sml-12 col-lrg-6 form__group">
												<div className="form__group__header">
													<label className="form__group__label" htmlFor="eidsEnrollmentDetails_eidsMerchantEmail">
														Enter the email the login credentials will be sent to:
													</label>
													<span className="form__group__required" data-tooltip="Required">
														*
													</span>
												</div>
												<input
													className="input input--med spc--bottom--lrg"
													id="eidsEnrollmentDetails_eidsMerchantEmail"
													name="eidsEnrollmentDetails_eidsMerchantEmail"
													type="text"
													value={setup.eidsEnrollmentDetails.eidsMerchantEmail}
													onChange={this.props.handleChange}
												/>
												<div className="notes notes--primary">
													<i className="icon"></i>
													<p className="type--p3">
														It usually takes up to 2-3 days to get an approval and before the dispute reporting kicks
														in.
													</p>
												</div>
											</div>
										</div>
									),
								},
								{ value: false, text: 'No' },
							]}
							wrapperClass="flex flex--primary spc--bottom--sml"
							divClass=""
						/>
					</div>
				</div>
			</Card>
		);
	}

	renderProposedFee(setup, fee, label, required) {
		const name = `proposedFees_${fee}`;
		return (
			<div className="col col-sml-12 col-med-3 form__group">
				<div id={`${name}_div`} className="form__group__header">
					<p className="form__group__label">{label} </p>
					{required && (
						<span className="form__group__required" data-tooltip="Required">
							*
						</span>
					)}
				</div>
				{this.renderAmountInput(setup.proposedFees[fee], name)}
			</div>
		);
	}

	renderRetrievalCharge(setup) {
		return this.renderProposedFee(setup, 'retrievalCharge', 'Retrieval Charge', false);
	}

	renderPciFee(setup) {
		return this.renderProposedFee(setup, 'pciAnnualFee', 'PCI Annual Fee', false);
	}

	renderProposedFeesSection(setup) {
		return (
			<Card title="Proposed Fees">
				<div className="row">
					{this.renderProposedFee(setup, 'batchFee', 'Batch Fee', false)}
					{this.renderProposedFee(setup, 'statementFee', 'Statement Fee', true)}
					{this.renderProposedFee(setup, 'annualFee', 'Annual Fee', false)}
					{this.renderProposedFee(setup, 'chargebackFee', 'Chargeback Fee', true)}
					{this.renderRetrievalCharge(setup)}
					{this.renderProposedFee(setup, 'monthlyMinimumFee', 'Monthly Minimum Fee', true)}
					{this.renderPciFee(setup)}
					{setup.doesAcceptEbt ? (
						<div className="col col-sml-12 col-med-3 form__group">
							<div className="form__group__header">
								<p className="form__group__label">EBT Transaction Fee </p>
								<span className="form__group__required" data-tooltip="Required">
									*
								</span>
							</div>
							{this.renderAmountInput(setup.proposedFees.ebtTransactionFee, 'proposedFees_ebtTransactionFee')}
						</div>
					) : null}
					<div className="col col-sml-12 spc--bottom--xxlrg">
						<div className="flex--primary flex--gap--xxlrg">
							<div>
								<CheckboxTxtInput
									labelText="Early Termination Fee (ETF)"
									labelClass="type--p2 type--color--text"
									checkboxName="proposedFees_isEarlyTerminationFeeWaived"
									inputName="proposedFees_earlyTerminationFee"
									placeholder="$0"
									checked={!setup.proposedFees.isEarlyTerminationFeeWaived}
									value={String(setup.proposedFees.earlyTerminationFee)}
									onCheckChanged={this.handleToggleCheckboxChange}
									onValueChanged={this.props.handleChange}
									format="NumberFormat"
								/>
							</div>
							<div>
								<input
									className="input--check"
									type="checkbox"
									id="proposedFees_isEarlyTerminationFeeWaived"
									name="proposedFees_isEarlyTerminationFeeWaived"
									checked={setup.proposedFees.isEarlyTerminationFeeWaived}
									onChange={this.props.handleChange}
								/>
								<label htmlFor="proposedFees_isEarlyTerminationFeeWaived">Waived</label>
							</div>
						</div>
					</div>
				</div>
				{this.renderOnlineReportingSection()}
			</Card>
		);
	}

	renderOnlineReportingSection() {
		const { setup, alreadyUploadedTaxExemption } = this.props;
		if (!setup || !isFd(setup)) return null;
		return (
			<React.Fragment>
				{isFd(setup) && (
					<p className="type--p2 type--p2--medium spc--bottom--lrg">Online reporting (Check whichever is applicable)</p>
				)}
				<div>
					{isFd(setup) && (
						<div className="spc--bottom--med">
							<input
								className="input--check"
								type="checkbox"
								name="onlineReportingOptions_isEnabled"
								id="onlineReportingOptions_isEnabled"
								checked={setup.onlineReportingOptions.isEnabled}
								onChange={this.props.handleEnableAccessOneChange}
							/>
							<label htmlFor="onlineReportingOptions_isEnabled">Enable Access One</label>
						</div>
					)}
					{setup.onlineReportingOptions.isEnabled && (
						<div className="spc--bottom--lrg">
							<div className="flex--primary flex--gap--tny spc--bottom--med">
								<span className="form__group__label" id="onlineReportingOptions_markupFee_div">
									Price
								</span>
								<span className="form__group__required" data-tooltip="Required">
									*
								</span>
							</div>
							<div className="spc--bottom--sml">
								<input
									type="radio"
									className="input--radio"
									id="radMarkupFeeFree"
									name="onlineReportingOptions_markupFee"
									value="0"
									checked={setup.onlineReportingOptions.markupFee === '0'}
									onChange={this.props.handleChange}
								/>
								<label htmlFor="radMarkupFeeFree">Free ($0)</label>
							</div>
							<div className="spc--bottom--sml">
								<input
									type="radio"
									className="input--radio"
									id="radMarkupFee5"
									name="onlineReportingOptions_markupFee"
									value="5"
									checked={setup.onlineReportingOptions.markupFee === '5'}
									onChange={this.props.handleChange}
								/>
								<label htmlFor="radMarkupFee5">$5</label>
							</div>
							<div>
								<input
									type="radio"
									className="input--radio"
									id="radMarkupFee10"
									name="onlineReportingOptions_markupFee"
									value="10"
									checked={setup.onlineReportingOptions.markupFee === '10'}
									onChange={this.props.handleChange}
								/>
								<label htmlFor="radMarkupFee10">$10</label>
							</div>
						</div>
					)}
					<div className="spc--bottom--med">
						<input
							className="input--check"
							type="checkbox"
							name="additionalSetupDetails_isTaxExemptionRequested"
							id="isTaxExemptionRequested"
							checked={setup.additionalSetupDetails.isTaxExemptionRequested}
							onChange={this.props.handleChange}
						/>
						<label htmlFor="isTaxExemptionRequested">Request tax exemption</label>
					</div>
					{setup.additionalSetupDetails.isTaxExemptionRequested && (
						<div>
							<div className="form__group">
								<div className="form__group__header">
									<p className="form__group__label">Upload Tax Document </p>
									<span className="form__group__required">*</span>
								</div>
								<FileDropzoneComponent
									multiple={true}
									tag="TaxExemption"
									fileList={this.props.files}
									onDrop={this.props.onDropFile}
									onRemoveFile={(fileType, i) =>
										this.props.handleRemoveFile(fileType, i, this.props.validateTaxExemptionFiles)
									}
									validateFileSize={false}
									allowedFileTypes={allowedFileTypes}
								/>
							</div>
						</div>
					)}
					{setup.additionalSetupDetails.isTaxExemptionRequested && !isEmpty(alreadyUploadedTaxExemption) && (
						<p className="type--validation spc--top--med">Tax Exempt File already uploaded.</p>
					)}
				</div>
			</React.Fragment>
		);
	}

	renderProcessorDropdown() {
		const { setup, processorList } = this.props;
		return (
			<ProcessorDropdownComponent
				className="input input--med input--select"
				id="processorId"
				value={setup.processorId}
				processorList={processorList}
				setup={setup}
				onChange={this.props.handleProcessorChange}
				merchId={setup.appId}
			/>
		);
	}

	renderProposedStructure() {
		const { setup } = this.props;
		return (
			<div className="col col-sml-12 col-lrg-4 form__group">
				<div className="form__group__header">
					<p className="form__group__label">Proposed Structure </p>
				</div>
				<select
					className="input input--med input--select"
					name="pricingStructure_pinDebitStructureType"
					value={setup.pricingStructure.pinDebitStructureType}
					onChange={this.props.handleChange}
				>
					<option>Please select</option>
					<option value="PassThrough">Pass Through</option>
					<option value="FlatRate">Flat Rate</option>
				</select>
			</div>
		);
	}

	renderPinDebitStructure() {
		const { setup } = this.props;
		return (
			<div className="row">
				{this.renderProposedStructure()}
				<div className="col col-sml-12 col-lrg-4 fomr__group">
					<div className="form__group__header">
						<p className="form__group__label">PIN Based Debit Trans Fee </p>
					</div>
					{this.renderNumberInput(
						setup.pricingStructure.pinDebitTransactionFee,
						'pricingStructure_pinDebitTransactionFee',
						'$'
					)}
				</div>
				<div className="col col-sml-12 col-lrg-4 fomr__group">
					<div className="form__group__header">
						<p className="form__group__label">PIN Based Debit Markup </p>
					</div>
					{this.renderNumberInput(
						setup.pricingStructure.pinDebitMarkupAmount,
						'pricingStructure_pinDebitMarkupAmount',
						'%',
						[0, 100]
					)}
				</div>
			</div>
		);
	}

	renderPinDebit() {
		const { setup } = this.props;
		return (
			<div className="spc--bottom--xlrg">
				<div className="form__group">
					<div className="form__group__header">
						<p className="form__group__label">Setup Merchant with Pin Debit</p>
						<span className="form__group__required" data-tooltip="Required">
							*
						</span>
					</div>
					<YesNoRadio
						name="pricingStructure_shouldSetupPinDebit"
						yes={setup.pricingStructure.shouldSetupPinDebit}
						onChange={this.props.handleChange}
					/>
				</div>
				{setup.pricingStructure.shouldSetupPinDebit && this.renderPinDebitStructure()}
			</div>
		);
	}

	renderPricingStructureSection(setup) {
		return (
			<Card title="Pricing Structure">
				<div className="row">
					<div className="col col-sml-12 col-med-6 form__group">
						<div id="processor_div" className="flex--primary flex--gap--tny">
							<p className="form__group__label">Bank</p>
							<span className="form__group__required" data-tooltip="Required">
								*
							</span>
						</div>
						{this.renderProcessorDropdown()}
					</div>
					<div className="col col-sml-12 col-med-6">
						{this.renderRateStructure('pricingStructure', '', false, setup.pricingStructure.pricingStructureType)}
					</div>
					<div className="col col-sml-12 col-med-6 spc--bottom--xxxlrg">
						<div className="form__group__header">
							<p className="form__group__label">Authorization Fee</p>
						</div>
						{this.renderNumberInput(setup.pricingStructure.authorizationFee, 'pricingStructure_authorizationFee', '$0')}
					</div>
					<div className="col col-sml-12 col-med-6 spc--bottom--xxxlrg">
						<div className="form__group__header">
							<p className="form__group__label">V/MC/Disc Transaction Fee</p>
						</div>
						{this.renderNumberInput(
							setup.pricingStructure.bankCardTransactionFee,
							'pricingStructure_bankCardTransactionFee',
							'$0'
						)}
					</div>
				</div>
				{this.renderPinDebit()}
				{!setup || isFd(setup) ? (
					<div className="form__group">
						<div className="form__group__header">
							<p className="form__group__label">Discount Method</p>
							<span className="form__group__required" data-tooltip="Required">
								*
							</span>
						</div>
						<select
							className="input input--med input--select"
							name="pricingStructure_discountMethod"
							value={setup.pricingStructure.discountMethod}
							onChange={this.props.handleChange}
						>
							<option>Please select</option>
							<option value="Monthly">Monthly</option>
							<option value="Daily">Daily</option>
						</select>
					</div>
				) : null}
				{setup && isElavon(setup) ? (
					<div className="row">
						<div className="col col-sml-12 col-med-6 form__group">
							<input
								className="input--check"
								type="checkbox"
								id="pricingStructure_shouldRequireNextDayFunding"
								name="pricingStructure_shouldRequireNextDayFunding"
								value={setup.pricingStructure.shouldRequireNextDayFunding}
								onChange={this.props.handleChange}
							/>
							<label htmlFor="pricingStructure_shouldRequireNextDayFunding">
								Next Day Funding Required ($5 monthly fee applies)
							</label>
						</div>
					</div>
				) : null}
			</Card>
		);
	}

	renderProposedSavingsSection(setup) {
		if (!get(setup, 'additionalSetupDetails.isCurrentlyAcceptingCreditCards')) return null;
		return (
			<Card title="Proposed Savings (only if applicable)">
				<div className="row">
					<div className="col col-sml-12 col-med-6">
						<div>
							<DropdownContainer
								labelText="Proposed Savings Type"
								labelClass=" form__group__label"
								selectClass="input input--med input--select"
								wrapperClass="form__group"
								selectName="proposedSavings_type"
								value={setup.proposedSavings.type}
								onChange={this.handlePSDependantValuesChanged}
								options={[
									{
										value: 'Unknown',
										text: 'Please select',
										default: true,
										children: <React.Fragment></React.Fragment>,
									},
									{
										value: 'MonthlyDollarSavings',
										text: 'Monthly Dollar Savings',
										children: (
											<React.Fragment>
												<div className="form__group">
													<div className="form__group__header">
														<span className="form__group__label">Monthly Dollar Savings</span>
														<span className="form__group__required" data-tooltip="Required">
															*
														</span>
													</div>
													{this.renderNumberInput(
														setup.proposedSavings.monthlyDollarSavingsAmounts,
														'proposedSavings_monthlyDollarSavingsAmounts',
														'$'
													)}
												</div>
											</React.Fragment>
										),
									},
									{
										value: 'EffectiveRateReduction',
										text: 'Effective Rate Reduction',
										children: (
											<DropdownContainer
												labelText="Effective Rate Reduction Type"
												required={true}
												labelClass="form__group__label"
												selectClass="input input--med input--select"
												wrapperClass="form__group"
												selectName="proposedSavings_rateReductionType"
												value={setup.proposedSavings.rateReductionType || ''}
												onChange={this.handlePSDependantValuesChanged}
												options={[
													{ value: 'Unknown', text: 'Please select', default: true, children: null },
													{
														value: 'Combined',
														text: 'Combined',
														children: (
															<div className="row">
																<div className="col col-sml-12 col-med-6 form__group">
																	<div className="form__group__header">
																		<span className="form__group__label">Combined</span>
																		<span className="form__group__required" data-tooltip="Required">
																			*
																		</span>
																	</div>
																	{this.renderNumberInput(
																		setup.proposedSavings.combinedSavingsPercentage,
																		'proposedSavings_combinedSavingsPercentage',
																		'%',
																		[0, 100]
																	)}
																</div>
															</div>
														),
													},
													{
														value: 'Individual',
														text: 'Individual',
														children: (
															<React.Fragment>
																<div className="row">
																	<div className="col col-sml-12 col-med-6 form__group">
																		<div className="form__group__header">
																			<span className="form__group__label">Bankcard</span>
																			<span className="form__group__required" data-tooltip="Required">
																				*
																			</span>
																		</div>
																		{this.renderNumberInput(
																			setup.proposedSavings.bankcardSavingsPercentage,
																			'proposedSavings_bankcardSavingsPercentage',
																			'%',
																			[0, 100]
																		)}
																	</div>
																	<div className="col col-sml-12 col-med-6 form__group">
																		<div className="form__group__header">
																			<p className="form__group__label">Amex</p>
																			<span className="form__group__required" data-tooltip="Required">
																				*
																			</span>
																		</div>
																		{this.renderNumberInput(
																			setup.proposedSavings.amexSavingsPercentage,
																			'proposedSavings_amexSavingsPercentage',
																			'%',
																			[0, 100]
																		)}
																	</div>
																</div>
															</React.Fragment>
														),
													},
												]}
											></DropdownContainer>
										),
									},
								]}
							></DropdownContainer>
						</div>
					</div>
				</div>
			</Card>
		);
	}

	renderAmexEntitlementSection(setup) {
		return (
			<Card title="Amex">
				<div>
					<div className="form__group__header">
						<p className="form__group__label">Amex Entitlement</p>
						<span className="form__group__required" data-tooltip="Required">
							*
						</span>
					</div>
					<div className="flex--primary flex--gap--xxlrg spc--bottom--xxlrg">
						<div>
							<input
								className="input--radio"
								type="radio"
								id="amexDetails_status_yes"
								name="amexDetails_status_yes"
								checked={setup.amexDetails.status && setup.amexDetails.status !== 'None'}
								value="New"
								onChange={this.props.handleAmexChange}
							/>
							<label htmlFor="amexDetails_status_yes">Yes</label>
						</div>
						<div>
							<input
								className="input--radio"
								type="radio"
								id="amexDetails_status"
								name="amexDetails_status"
								value="None"
								checked={setup.amexDetails.status === 'None'}
								onChange={this.props.handleAmexChange}
							/>
							<label htmlFor="amexDetails_status">No</label>
						</div>
					</div>
					{setup.amexDetails.status && setup.amexDetails.status !== 'None' && (
						<DropdownContainer
							type="radio"
							selectName="amexDetails_program"
							value={setup.amexDetails.program}
							onChange={this.props.handleProgramChange}
							labelText=""
							options={[
								{ value: 'OptBlue', text: 'Opt Blue', children: this.renderOptBlue() },
								{ value: 'ESA', text: 'Amex ESA', children: this.renderAmex() },
							]}
							wrapperClass="display--n"
							divClass="flex--primary flex--gap--xxlrg"
						/>
					)}
				</div>
			</Card>
		);
	}

	renderGeneralSection(setup) {
		const additionalSetupDetails = get(setup, 'additionalSetupDetails', {});
		const isCardknox = get(setup, 'telemarketerList.length', 0) > 0;
		const telemarketerLabel = isCardknox ? 'Rep 2' : 'Rep 2 Email';
		const total = this.getTotalProcessing();
		return (
			<Card title="General">
				<div className="spc--bottom--xxxlrg">
					<div className="form__group__header">
						<p className="form__group__label">{telemarketerLabel}</p>
						{isCardknox && (
							<span className="form__group__required" data-tooltip="Required">
								*
							</span>
						)}
					</div>
					{isCardknox ? (
						<select
							placeholder="Telemarketer"
							className="input input--med input--select"
							name="telemarketers"
							value={get(
								setup.telemarketerList.find(x => x.agentName === setup.telemarketerEmail),
								'agentName',
								''
							)}
							onChange={this.props.handleTelemarketerChange}
						>
							<option value="">Please select...</option>
							{setup.telemarketerList.map(telemarketer => (
								<option key={telemarketer.agentId}>{telemarketer.agentName}</option>
							))}
						</select>
					) : (
						<input
							type="text"
							placeholder="Rep 2 Email"
							className="input input--med"
							name="telemarketerEmail"
							value={setup.telemarketerEmail.trim()}
							onChange={this.props.handleChange}
						></input>
					)}
				</div>

				<div>
					<p className="type--p2 type--p2--medium spc--bottom--lrg">Check whichever is applicable</p>

					<div className="spc--bottom--xxxlrg">
						<div className="form__group__header">
							<p className="form__group__label">
								The signer or business has, or has previously had, an account with Sola, Fidelity or Cardknox
							</p>
							<span className="form__group__required" data-tooltip="Required">
								*
							</span>
						</div>
						<YesNoRadio
							name="hasExistingFidelityAccount"
							yes={setup.hasExistingFidelityAccount}
							onChange={this.props.handleChange}
						/>
						{setup.hasExistingFidelityAccount && (
							<div className="form__group spc--top--lrg">
								<div className="form__group__header">
									<p className="form__group__label">DBA/MID</p>
								</div>
								<input
									type="text"
									placeholder="DBA/MID of existing account"
									className="input input--med"
									name="existingAccountDba"
									value={setup.existingAccountDba}
									onChange={this.props.handleChange}
								></input>
							</div>
						)}
					</div>
					<div className="spc--bottom--xxxlrg">
						<div>
							<div className="form__group__header">
								<p className="form__group__label">Merchant currently accepts credit cards</p>
								<span className="form__group__required" data-tooltip="Required">
									*
								</span>
							</div>
							<YesNoRadio
								name="additionalSetupDetails_isCurrentlyAcceptingCreditCards"
								yes={additionalSetupDetails.isCurrentlyAcceptingCreditCards}
								onChange={this.handlePSDependantValuesChanged}
							/>
						</div>
						{additionalSetupDetails.isCurrentlyAcceptingCreditCards && (
							<div className="spc--top--lrg">
								<YesNoRadio
									name="additionalSetupDetails_hadStatementsReviewed"
									yes={additionalSetupDetails.hadStatementsReviewed}
									onChange={this.props.handleChange}
									yesLabel="Statements reviewed"
									noLabel="No statements reviewed"
								/>
							</div>
						)}
						{additionalSetupDetails.isCurrentlyAcceptingCreditCards === false && (
							<div className="spc--top--lrg">
								<YesNoRadio
									name="additionalSetupDetails_requiresPricingAnalysis"
									yes={additionalSetupDetails.requiresPricingAnalysis}
									onChange={this.props.handleChange}
									yesLabel="Standard industry rates"
									noLabel="Use rates provided by agent"
								/>
							</div>
						)}
						{additionalSetupDetails.isCurrentlyAcceptingCreditCards && !additionalSetupDetails.hadStatementsReviewed && (
							<div className="spc--top--lrg">
								<YesNoRadio
									name="additionalSetupDetails_requiresPricingAnalysis"
									yes={additionalSetupDetails.requiresPricingAnalysis}
									onChange={this.props.handleChange}
									yesLabel="Standard industry rates"
									noLabel="Use rates provided by agent"
								/>
							</div>
						)}
					</div>
					{additionalSetupDetails.isCurrentlyAcceptingCreditCards && additionalSetupDetails.hadStatementsReviewed && (
						<div className="form__group">
							<div id="statementsReviewedDba_div" className="form__group__header">
								<p className="form__group__label">DBA As Reviewed </p>
							</div>
							<input
								type="text"
								className="input input--med"
								name="additionalSetupDetails_statementsReviewedDba"
								value={additionalSetupDetails.statementsReviewedDba}
								onChange={this.props.handleChange}
							></input>
						</div>
					)}
				</div>

				<div className="spc--bottom--xxxlrg">
					<div id="goodsOrServicesDescription_div" className="form__group__header">
						<p className="form__group__label">Goods/Services Sold </p>
						<span className="form__group__required" data-tooltip="Required">
							*
						</span>
					</div>
					<input
						type="text"
						className="input input--med"
						name="goodsOrServicesDescription"
						value={setup.goodsOrServicesDescription}
						onChange={this.props.handleChange}
					></input>
				</div>

				{this.renderProcessingMethodLabel()}

				<div className="row">
					<div className="col col-sml-12 col-lrg-3 form__group">
						<div className="form__group__header" id="processingDetails_swipedTransactionsPercentage_div">
							<p className="form__group__label">Swiped </p>
						</div>
						{this.renderNumberInput(
							setup.processingDetails.swipedTransactionsPercentage,
							'processingDetails_swipedTransactionsPercentage',
							'%',
							[0, 100]
						)}
					</div>
					<div className="col col-sml-12 col-lrg-3 form__group">
						<div className="form__group__header" id="processingDetails_keyedTransactionsPercentage_div">
							<p className="form__group__label">Keyed </p>
						</div>
						{this.renderNumberInput(
							setup.processingDetails.keyedTransactionsPercentage,
							'processingDetails_keyedTransactionsPercentage',
							'%',
							[0, 100]
						)}
					</div>
					<div className="col col-sml-12 col-lrg-3 form__group">
						<div className="form__group__header" id="processingDetails_internetTransactionsPercentage_div">
							<p className="form__group__label">Internet </p>
						</div>
						{this.renderNumberInput(
							setup.processingDetails.internetTransactionsPercentage,
							'processingDetails_internetTransactionsPercentage',
							'%',
							[0, 100]
						)}
					</div>
					<div className="col col-sml-12 col-lrg-3 form__group">
						<div className="form__group__header">
							<p className="form__group__label">Total </p>
						</div>
						<div
							name="totalProcessing"
							className={`input input--med input--total ${total === 100 ? 'success' : 'error'}`}
						>
							{total}
						</div>
					</div>
				</div>
				<p className="type--p2 type--p2--medium spc--bottom--lrg spc--top--lrg">V/M Transaction Information:</p>
				<div className="row flex--bottom">
					<div className="col col-sml-12 col-lrg-4 form__group">
						<div id="processingDetails_yearlyVmcVolume_div" className="form__group__header">
							<p className="form__group__label">V/M Average Annual Volume</p>
							<span className="form__group__required" data-tooltip="Required">
								*
							</span>
						</div>
						{this.renderNumberInput(setup.processingDetails.yearlyVmcVolume, 'processingDetails_yearlyVmcVolume', '$0')}
					</div>
					<div className="col col-sml-12 col-lrg-4 form__group">
						<div id="processingDetails_averageTransactionAmount_div" className="form__group__header">
							<p className="form__group__label">V/M Average transaction dollar amount</p>
							<span className="form__group__required" data-tooltip="Required">
								*
							</span>
						</div>
						{this.renderNumberInput(
							setup.processingDetails.averageTransactionAmount,
							'processingDetails_averageTransactionAmount',
							'$0'
						)}
					</div>
					<div className="col col-sml-12 col-lrg-4 form__group">
						<div id="processingDetails_maxTransactionAmount_div" className="form__group__header">
							<p className="form__group__label">V/M highest transaction dollar amount</p>
						</div>
						{this.renderNumberInput(
							setup.processingDetails.maxTransactionAmount,
							'processingDetails_maxTransactionAmount',
							'$0'
						)}
					</div>
					{this.renderTransactionInformation()}
				</div>
			</Card>
		);
	}

	renderOptBlue() {
		const { setup } = this.props;
		return (
			<div className="row fullwidth">
				<div className="col col-sml-12 form__group">
					{this.renderRateStructure('amexDetails', 'AMEX ', false, setup.amexDetails.pricingStructureType)}
				</div>
				<div className="col col-sml-12 col-med-6 form__group">
					<div className="form__group__header">
						<p className="form__group__label">Amex Trans/Auth Fee (if diff than Visa/MC/Disc)</p>
					</div>
					{this.renderNumberInput(setup.amexDetails.transactionFee, 'amexDetails_transactionFee', '$0')}
				</div>
				<div id="amexDetails_yearlyAmexVolume_div" className="col col-sml-12 col-med-6 form__group">
					<div className="form__group__header">
						<p className="form__group__label">Opt-Blue Average Annual Volume </p>
					</div>
					{this.renderNumberInput(setup.amexDetails.yearlyAmexVolume, 'amexDetails_yearlyAmexVolume', '$0')}
				</div>
			</div>
		);
	}

	renderAmex() {
		const { setup } = this.props;
		return (
			<React.Fragment>
				<DropdownContainer
					labelClass="form__group__label"
					selectClass="input input--med input--select"
					wrapperClass="col col-sml-12 col-med-6 form__group"
					labelText="Amex Entitlement"
					divClass="row"
					selectName="amexDetails_status"
					value={setup.amexDetails.status}
					onChange={this.props.handleChange}
					options={[
						{
							value: 'New',
							text: 'New AMEX',
							children: this.renderESA(true),
						},
						{
							value: 'Existing',
							text: 'Existing AMEX',
							children: this.renderESA(false),
						},
					]}
				></DropdownContainer>
				{this.renderTransactionFee(setup)}
			</React.Fragment>
		);
	}

	renderTransactionFee(setup) {
		return (
			<div className="row fullwidth">
				<div className="col col-sml-12 col-med-6 form__group">
					<div className="form__group__header">
						<p className="form__group__label">Amex Trans/Auth Fee (if diff than Visa/MC/Disc) </p>
					</div>
					{this.renderNumberInput(setup.amexDetails.transactionFee, 'amexDetails_transactionFee', '$0')}
				</div>
			</div>
		);
	}

	renderESA(isNew) {
		const { setup } = this.props;
		if (isNew) {
			return (
				<div className="row">
					<div className="col col-sml-12 col-med-6 form__group">
						<div className="form__group__header">
							<p className="form__group__label">ESA Annual Volume</p>
						</div>
						{this.renderNumberInput(setup.amexDetails.esaAnnualVolume, 'amexDetails_esaAnnualVolume', '$')}
					</div>
					<div className="col col-sml-12 col-med-6 form__group">
						<div className="form__group__header">
							<p className="form__group__label">ESA Average Transaction Dollar Amount</p>
						</div>
						{this.renderNumberInput(setup.amexDetails.esaAverageTicket, 'amexDetails_esaAverageTicket', '$')}
					</div>
					<div className="col col-sml-12 col-med-6 form__group">
						<div className="form__group__header">
							<p className="form__group__label">ESA Highest Transaction Dollar Amount</p>
						</div>
						{this.renderNumberInput(setup.amexDetails.esaHighTicket, 'amexDetails_esaHighTicket', '$')}
					</div>
					<div className="col col-sml-12 form__group">
						<input
							className="input--check"
							type="checkbox"
							id="amexDetails_hasReversePip"
							name="amexDetails_hasReversePip"
							value={setup.amexDetails.hasReversePip}
							checked={setup.amexDetails.hasReversePip}
							onChange={this.props.handleChange}
						/>
						<label htmlFor="amexDetails_hasReversePip">Set Up Merchant with Pip</label>
					</div>
				</div>
			);
		}
		return (
			<div className="row">
				<div className="col col-sml-12 col-med-6 form__group">
					<NumberFormat
						className="input input--med"
						name="amexDetails_esaNumber"
						value={setup.amexDetails.esaNumber}
						placeholder="Amex ESA number"
						thousandSeparator={false}
						fixedDecimalScale={false}
						decimalScale={0}
						allowNegative={false}
						onValueChange={({ formattedValue }) =>
							this.props.handleChange({
								target: {
									value: formattedValue,
									name: 'amexDetails_esaNumber',
								},
							})
						}
					/>
				</div>
			</div>
		);
	}

	renderRateStructure(rateObjectName, labelPrefix, readonly, defaultValue, tooltip) {
		const { setup } = this.props;
		const namePrefix = rateObjectName + '_';
		// temporary code to allow ui to work without api
		if (!setup[rateObjectName]) setup[rateObjectName] = {};

		let rateObj = setup[rateObjectName];
		return (
			<DropdownContainer
				labelText={labelPrefix + 'Rate Structure'}
				labelClass="form__group__label"
				selectClass="input input--med input--select"
				wrapperClass="form__group"
				selectName={namePrefix + 'pricingStructureType'}
				required={true}
				value={defaultValue}
				onChange={this.handleChangeWithUpdate}
				disabled={readonly}
				tooltip={tooltip}
				options={[
					{ value: 'Unknown', text: 'Please Select', default: true },
					{
						value: 'PassThrough',
						text: 'Pass Through',
						children: (
							<div className="form__group">
								<div className="form__group__header">
									<p className="form__group__label">{labelPrefix} Pass Through </p>
								</div>
								{this.renderNumberInput(
									rateObj.passThroughPercentage,
									namePrefix + 'passThroughPercentage',
									'Pass Through Rate'
								)}
							</div>
						),
					},
					{
						value: 'FlatRate',
						text: 'Flat Rate',
						children: (
							<div className="form__group">
								<div className="form__group__header">
									<p className="form__group__label">{labelPrefix} Flat Rate </p>
								</div>
								{this.renderNumberInput(rateObj.flatRatePercentage, namePrefix + 'flatRatePercentage', 'Flat Rate')}
							</div>
						),
					},
					{
						value: 'Tiered',
						text: 'Tiered',
						children: (
							<div className="row">
								<div className="col col-sml-12 col-lrg-3 form__group">
									<div className="form__group__header">
										<p className="form__group__label">Qual</p>
									</div>
									{this.renderNumberInput(
										rateObj.tieredRatePercentages.qualified,
										namePrefix + 'tieredRatePercentages_qualified',
										'%',
										[0, 100]
									)}
								</div>
								<div className="col col-sml-12 col-lrg-3 form__group">
									<div className="form__group__header">
										<p className="form__group__label">Mid-Qual</p>
									</div>
									{this.renderNumberInput(
										rateObj.tieredRatePercentages.midQualified,
										namePrefix + 'tieredRatePercentages_midQualified',
										'%',
										[0, 100]
									)}
								</div>
								{this.renderNonQual(rateObj, namePrefix)}
								{namePrefix.indexOf('amex') === 0 ? null : (
									<div className="col col-sml-12 col-lrg-3 form__group">
										<div className="form__group__header">
											<p className="form__group__label">Rewards</p>
										</div>
										{this.renderNumberInput(
											rateObj.tieredRatePercentages.rewards,
											namePrefix + 'tieredRatePercentages_rewards',
											'%',
											[0, 100]
										)}
									</div>
								)}
							</div>
						),
					},
					{
						value: 'BillBack',
						text: 'Bill Back',
						children: (
							<div className="row">
								<div className="col col-sml-12 col-med-6 form__group">
									<div className="form__group__header">
										<p className="form__group__label">{labelPrefix} Base Rate </p>
									</div>
									{this.renderNumberInput(
										rateObj.billBackRatePercentages.base,
										namePrefix + 'billBackRatePercentages_base',
										'Base Rate'
									)}
								</div>
								<div className="col col-sml-12 col-med-6 form__group">
									{this.renderEnhancedRate(rateObj.billBackRatePercentages.enhanced, labelPrefix, namePrefix)}
								</div>
							</div>
						),
					},
				]}
			></DropdownContainer>
		);
	}

	renderEnhancedRate(value, labelPrefix, namePrefix) {
		return namePrefix.indexOf('amex') === 0 ? null : (
			<React.Fragment>
				<div className="form__group__header">
					<p className="form__group__label">{labelPrefix} Enhanced Rate </p>
				</div>
				{this.renderNumberInput(value, namePrefix + 'billBackRatePercentages_enhanced', 'Enhanced Rate')}
			</React.Fragment>
		);
	}

	renderAmountInput(value, name) {
		return this.renderNumberInput(value, name, '$0', [], '$');
	}

	renderNumberInput(value, name, placeholder, allowedValues = [], prefix = '') {
		const componentProps = {
			id: name,
			value: value,
			className: 'input input--med',
			placeholder: placeholder,
			thousandSeparator: true,
			decimalScale: 2,
			onValueChange: ({ floatValue }) => this.props.handleChange({ target: { name, value: floatValue } }),
			prefix,
		};

		if (!isEmpty(allowedValues)) {
			componentProps.isAllowed = this.checkIfAllowedValue(...allowedValues);
		}
		return <ZeroAsEmptyNumberFormat {...componentProps} />;
	}

	renderNonQual(rateObj, namePrefix) {
		return (
			<div className="col col-sml-12 col-lrg-3 form__group">
				<div className="form__group__header">
					<p className="form__group__label">Non-Qual </p>
				</div>
				{this.renderNumberInput(
					rateObj.tieredRatePercentages.nonQualified,
					namePrefix + 'tieredRatePercentages_nonQualified',
					'%',
					[0, 100]
				)}
			</div>
		);
	}

	handleChangeWithUpdate = e => {
		this.props.handleChange(e);
	};

	renderTransactionInformation() {
		return null;
	}

	renderProcessingMethodLabel() {
		return (
			<p className="type--p2 type--p2--medium spc--bottom--lrg">
				Processing method (Must equal 100%){' '}
				<span className="type--color--error datatooltip--auto datatooltip--no-wrap" data-tooltip="Required">
					*
				</span>
			</p>
		);
	}
}

USSetupForm.propTypes = {
	setup: PropTypes.object,
	handleChange: PropTypes.func,
};

class Card extends Component {
	getToggledClassName = isToggled => (isToggled ? '' : ' is-expanded');
	getIconClassName = isToggled => (isToggled ? 'right' : 'top');
	getHiddenClassName = isToggled => (isToggled ? '' : ' display--n');

	render() {
		const { title, showSeparator = true, children, tooltip } = this.props;
		return (
			<Toggle initialToggle={true}>
				{({ isToggled, handleToggle }) => (
					<div className={`card${this.getToggledClassName(isToggled)} spc--bottom--lrg`}>
						<button onClick={handleToggle} className="card__header card__header--expandable">
							<div className="flex--primary flex--gap--sml">
								<h5>{title}</h5>
								{tooltip && (
									<i className="icon icon--sml icon--regular--info datatooltip--150" data-tooltip={tooltip}></i>
								)}
							</div>
							<i className={`icon icon--lrg icon--chevron--${this.getIconClassName(isToggled)}--primary`}></i>
						</button>
						{this.getHiddenClassName(isToggled) && <div className="card__body">{children}</div>}
					</div>
				)}
			</Toggle>
		);
	}
}
