import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
	cloneDeep,
	toLower,
	get,
	map,
	isEmpty,
	noop,
	startCase,
	each,
	findLastIndex,
	replace,
	split,
	last,
	join,
	uniq,
	includes,
	set,
	has,
} from 'lodash';
import { Link } from 'react-router-dom';
import { renderIf, validateOnBlur } from './../../common/utilities';
import ReactToPrint from 'react-to-print';
import { ExportToCsv } from 'export-to-csv';
import { exportOptions } from './../../common/components/export/export-options';
import { generateFileName } from './../../common/components/export/helpers';
import withBlock from './../../common/components/block/block-hoc';
import { ModalWrapper, modalNames } from './../../common/components/modal-wrapper';
import Schema from '../../validation/BaseSchema';
import { withLoader } from './../../common/components';
import { Notification } from '../../common/components/notifications';
import { default as GeneralInfo } from './general-info';
import { default as BusinessInformation } from './business-info';
import { default as ContactInformation } from './contact-info';
import { default as AdditionalInformation } from './additional-info';
import { LeadStatusComponent, LabelComponent } from '../LeadGrid/formatters';
import { appService } from '../../services/appService';
import ReviewPricingResults from './reviewPricingResults';
import { principalService } from '../../services';
import { leadFormTemplate } from '../../validation';
import { defaultImplicitParse, defaultReactOutput } from 'simple-markdown';
import { _sharedFunctions } from '../../services/_sharedFunctions';

class LeadDetails extends React.Component {
	constructor(props) {
		super(props);

		const principal = principalService.get();
		const agentList = principal.agentList;
		const agentSugarId = principal.agentSugarId;
		const linkedUser = principal.isLinkedUser;
		const canBoardGoPlus = principal.canBoardGoPlus;
		this.setSchema(true);

		this.state = {
			isSubmit: false,
			lead: null,
			modal: {
				name: modalNames.none,
				data: null,
			},
			showUserDropDown: false,
			errorList: [],
			errorListPaths: [],
			agentList,
			agentSugarId,
			partners: '',
			isAgentSubUser: principal.isAgentSubUser,
			agentGoPlusLookup: principal.agentGoPlusLookup,
			isLinkedUserSubAgent: principal.isLinkedUserSubAgent,
			isLinkedUserSupportRep: principal.isLinkedUserSupportRep,
			canBoardGoPlus,
			canBoardAchq: false,
			isLinkedUser: linkedUser,
		};
		this.detailsRef = React.createRef();
		this.printRef = React.createRef();
		this.errorRef = React.createRef();
		this.notificationRef = React.createRef();
	}

	async componentDidMount() {
		const { leadid } = this.props.match ? this.props.match.params : '';
		const { agentList } = this.state;
		if (leadid) {
			await this.loadLeadFromService(leadid);
		} else {
			// new lead
			const lead = {
				isNewError: false,
				leadId: '',
				generalInfo: {},
				contactInfo: {
					address: {},
				},
				businessInfo: {
					businessAddress: { country: 'USA' },
					ownershipType: 'Unknown',
				},
				addlInfo: {},
			};
			Promise.all([this.setCanBoardAchq(), appService.getAgentSettings()])
				.then(
					([
						_,
						{
							settings: { defaultEappType },
						},
					]) => {
						let processor = '';
						switch (defaultEappType) {
							case 1:
								processor = 'GoPlus';
								break;
							case 2:
								processor = 'ProfitStars';
								break;
							case 3:
								processor = 'Achq';
						}
						this.setState(prevState => {
							return { lead: { ...prevState.lead, processor } };
						});
					}
				)
				.catch(() => {
					lead.processor = '';
				});
			this.getPartnersForAgent();
			lead.agentSugarId = !!agentList && Object.keys(agentList).length > 0 ? '' : this.state.agentSugarId;
			this.setState({ lead });
		}
	}

	componentDidUpdate = (_, prevState) => {
		if (
			(this.state.errorMessage && !prevState.errorMessage) ||
			(!isEmpty(this.state.errorList) && this.state.isNewError)
		) {
			setTimeout(() => {
				if (this.errorRef.current) this.errorRef.current.scrollIntoView({ behavior: 'smooth' });
			}, 200);
			this.setState({ isNewError: false });
		}
	};

	setSchema = dbaRequired => {
		leadFormTemplate.lead.generalInfo.dba.required = dbaRequired;
		this.formSchema = new Schema(leadFormTemplate, { strip: false, typecast: true });
	};

	loadLeadFromService = async leadid => {
		this.props.showLoader(true);
		try {
			const results = await Promise.all([appService.getLeadDetails(leadid), this.setCanBoardAchq()]);
			const lead = results[0];
			if (!lead.businessInfo.businessAddress.country) lead.businessInfo.businessAddress.country = 'USA';
			if (!get(lead, 'businessInfo.ownershipType', null)) set(lead, 'businessInfo.ownershipType', 'Unknown');
			await this.setStateAsync({ lead }, this.getPartnersForAgent());
			this.props.showLoader(false);
		} catch (err) {
			console.log('getLeadDetails error', err);
			this.props.showLoader(false);
			await this.setStateAsync({ errorMessage: 'An error occurred: ' + err });
		}
	};

	getGoPlusRoles = () => {
		const { lead, agentGoPlusLookup, isLinkedUser } = this.state;
		if (!lead) {
			return false;
		}
		if (isLinkedUser) return { hasOwnGoPlusTiers: true, canBoardGoPlus: true };
		return agentGoPlusLookup[lead.agentSugarId];
	};

	async setCanBoardAchq() {
		const { canBoardAchq } = this.state;
		if (canBoardAchq) return;
		let { allowAchqApplication } = await appService.getAllowAchqApplication();
		allowAchqApplication = allowAchqApplication || appService.isDev();
		if (allowAchqApplication) {
			const achTiers = await this.loadAchTiers();
			allowAchqApplication = !!achTiers;
		}
		await this.setStateAsync({ canBoardAchq: allowAchqApplication });
	}

	async loadAchTiers() {
		let { achTiers } = this.state;
		if (!achTiers) {
			const data = await appService.getGoPlusTiers('0', true, true);
			achTiers = data.tiers;
			if (achTiers && Object.keys(achTiers).length > 0) {
				this.setState({ achTiers });
			} else {
				achTiers = null;
			}
		}
		return achTiers;
	}

	handleChange = (itemId, itemKey, itemValue, parentItemKey, arrayIdx) => {
		let lead = cloneDeep(this.state.lead);
		let itemToSet;
		this.props.handleBlockChange(true);
		if (parentItemKey) {
			itemToSet = lead[itemId][parentItemKey];
		} else {
			itemToSet = lead[itemId];
		}

		if (Array.isArray(itemToSet)) {
			if (!isNaN(arrayIdx)) {
				itemToSet[arrayIdx][itemKey] = itemValue;
			} else {
				itemToSet.push({
					[itemKey]: itemValue,
				});
			}
		} else if (Array.isArray(itemToSet[itemKey]) && !isNaN(arrayIdx)) {
			itemToSet[itemKey][arrayIdx] = itemValue;
		} else {
			//prefill 'https://' in website for new GoPlus accounts
			if (
				lead &&
				lead.processor === 'GoPlus' &&
				itemKey === 'website' &&
				itemValue &&
				itemToSet &&
				!itemToSet[itemKey]
			) {
				itemValue = `https://${itemValue}`;
			}

			itemToSet[itemKey] = itemValue;
		}
		this.setState({ lead }, () => {
			if (this.state.isSubmit) {
				this.validateFields();
			}
		});
	};

	handleProcessorChange = e => {
		const { lead } = this.state;
		const leadProcessor = e.target.value;
		this.setState({ lead: { ...lead, processor: leadProcessor } });
	};

	handleLinkedAppChange = e => {
		const { lead } = this.state;
		if (e.target.name === 'hasAccountWithFidelity') {
			this.setSchema(!e.target.checked || !this.state.lead?.relatedAppId);
			this.setState({ lead: { ...lead, hasAccountWithFidelity: e.target.checked } });
		} else if (e.target.name === 'relatedAppId') {
			this.setSchema(!e.target.value);
			this.setState({ lead: { ...lead, relatedAppId: e.target.value } });
		}
	};

	validateFields = (scrollToTop = false) => {
		const { lead } = this.state;
		const goPlusRoles = this.getGoPlusRoles();
		const hasOwnTiers = goPlusRoles && goPlusRoles.hasOwnGoPlusTiers;
		const tiersHaveAchq = hasOwnTiers && this.canBoardAchq;
		const validateFields = { lead: lead, hasOwnGoPlusTiers: hasOwnTiers, tiersHaveAchq: tiersHaveAchq };
		const errorList = this.formSchema.validate(Object.assign({}, validateFields));
		const errorListPaths = errorList.map(e => e.path);
		const newState = {
			errorList,
			errorListPaths,
		};
		const hasErrors = !isEmpty(errorList);
		if (scrollToTop && hasErrors) {
			newState.isNewError = true;
		}

		this.setState(newState);
		return !hasErrors;
	};

	handleCreateApp = (leadId, hasAccountWithFidelity = false, relatedAppId = '', processor = '') => {
		this.props.showLoader(true);
		return appService
			.convertLeadToApp(leadId, hasAccountWithFidelity, relatedAppId, processor)
			.then(resp => {
				this.props.showLoader(false);
				this.props.handleBlockChange(false);
				if (this.props.closeModal) this.props.closeModal();
				return resp;
			})
			.catch(err => {
				console.log('convert error', err);
				this.props.showLoader(false);
				this.setState({
					errorMessage: 'An error occurred: ' + err,
				});
			});
	};

	viewEapp = () => {
		const { appId } = this.state.lead;
		const { history } = this.props;
		history.push(this.getRedirectUrl() + appId);
	};
	refresh = () => this.loadLeadFromService(this.state.lead.leadId);

	handleSaveLead = (redirectAfterSave, displayNotification) => async () => {
		await this.setStateAsync({ isSubmit: true });
		if (!this.validateFields(true)) return;
		return this.saveLead(redirectAfterSave, displayNotification);
	};

	saveLead = (redirectAfterSave, displayNotification = false) => {
		this.props.showLoader(true);
		const lead = cloneDeep(this.state.lead);
		return appService
			.saveLeadDetails(lead)
			.then(async leadId => {
				this.props.showLoader(false);
				await this.props.handleBlockChange(false);
				this.setState({ errorMessage: '' });
				if (lead.leadId) {
					this.loadLeadFromService(lead.leadId);
				}

				if (displayNotification) {
					if (this.props.closeModal) this.props.closeModal();
					const addNotification =
						this.props.addNotification || get(this.notificationRef, 'current.addNotification', noop);
					addNotification({
						message: 'Saved successfully',
						success: true,
						onClose: redirectAfterSave ? () => this.handleRedirect(leadId, this.props.history) : noop,
					});
				}
				redirectAfterSave && this.handleRedirect(leadId, this.props.history);
				return leadId;
			})
			.catch(err => {
				console.log('save error', err);
				this.props.showLoader(false);
				this.setState({ errorMessage: 'An error occurred: ' + err });
			});
	};

	setStateAsync = state => {
		return new Promise(resolve => {
			this.setState(state, resolve);
		});
	};

	scrollTo = id => {
		const elem = document.getElementById(replace(replace(id, /^lead\./, ''), /\./gi, '_'));
		elem && elem.scrollIntoView({ behavior: 'smooth', block: 'center' });
	};

	focusField = id => {
		const elem = document.getElementById(replace(replace(id, /^lead\./, ''), /\./gi, '_'));
		elem && elem.focus();
	};

	saveAndConvertLead = () => async () => {
		await this.setStateAsync({ isSubmit: true });
		if (!this.validateFields(true)) {
			return;
		}
		this.saveLead(false, false).then(leadId => {
			if (!leadId) return;
			let lead = cloneDeep(this.state.lead);
			lead.leadId = leadId;
			this.handleCreateApp(
				leadId,
				lead.hasAccountWithFidelity,
				lead.relatedAppId,
				lead.processor || 'Traditional'
			).then(resp => {
				if (!resp) {
					return;
				}
				this.setState({ lead });
				const { history } = this.props;
				history.push(this.getRedirectUrl() + resp.newAppId);
			});
		});
	};
	getRedirectUrl = () => {
		const processor = toLower(this.state.lead.processor);
		if (processor === 'ck-go' || processor === 'goplus') {
			return '/eapp/cardknox-go/';
		}
		if (processor === 'profitstars') {
			return '/eapp/ach/';
		}
		if (processor === 'achq') {
			return '/eapp/achq/';
		}
		return '/eapp/mpa/';
	};

	handleRedirect = (leadId, history) => {
		if (!!leadId && !!history) history.push('/leads/account/' + leadId);
	};

	renderPrintButton = () => {
		return (
			<React.Fragment>
				<button onClick={this.printAll}>
					<i className="icon icon--sml icon--print" />
					<span>Print</span>
				</button>

				<ReactToPrint
					ref={this.printRef}
					trigger={() => <div style={{ display: 'none' }}></div>}
					content={() => this.detailsRef.current}
					onAfterPrint={this.collapseAll}
				/>
			</React.Fragment>
		);
	};

	printAll = () => {
		this.printRef.current.handlePrint();
	};

	replaceColumns = data => {
		if (!isEmpty(data)) {
			let newData = {};
			each(data, (value, key) => {
				const lastDotIndex = findLastIndex(key, i => i === '.');
				let label = lastDotIndex > -1 ? '' : key;
				if (lastDotIndex > -1) {
					const lastNumberIndex = findLastIndex(key, i => i === ']');
					const splitKeyValues = split(
						lastNumberIndex > -1 && key.length - 1 !== lastNumberIndex
							? key.slice(lastNumberIndex - 1)
							: replace(key, /\./gi, ' '),
						' '
					);
					each(splitKeyValues, item => {
						const keyPart = split(startCase(item), ' ')[0];
						if (last(splitKeyValues) === item) {
							label += startCase(item);
						} else {
							label += keyPart;
						}
					});
					label = join(uniq(split(startCase(label), ' ')), ' ');
				}

				if (includes(key, 'file')) {
					label = key;
				}

				newData[startCase(label)] = value;
			});
			return newData;
		}
		return [];
	};

	download = () => {
		let merchId = this.state.lead.leadId;
		const exporter = new ExportToCsv({ filename: generateFileName(false, merchId), ...exportOptions });

		let flattenedLead = this.replaceColumns(_sharedFunctions.flatten(this.state.lead));
		exporter.generateCsv([flattenedLead]);
	};

	openCloseModal = (modalObj, ...rest) => {
		let state = {
			modal: modalObj,
		};
		this.setState(state);
	};

	renderExportLead = () => {
		return (
			<button onClick={() => this.download()}>
				<i className="icon icon--sml icon--download" />
				<span>Export</span>
			</button>
		);
	};

	renderReviewPricingPopup = () => {
		let merchantInfo = {
			leadId: this.state.lead.leadId,
			dba: this.state.lead.generalInfo.dba,
			appId: this.state.lead.appId,
			market: this.state.lead.businessInfo.businessAddress.country,
		};
		return (
			<button
				type="button"
				className="btn btn--primary btn--med"
				onClick={() => this.openCloseModal({ name: modalNames.reviewPricing, data: { merchantInfo } })}
			>
				Submit to Pricing Analysis
			</button>
		);
	};

	renderAgentDropdown = () => {
		const { agentList, lead, errorListPaths } = this.state;
		return (
			!!agentList &&
			Object.keys(agentList).length > 0 && (
				<div className="modal__body--info-panel--section">
					<div className="row">
						<div className="col col-sml-12 col-med-6 form__group">
							<div className="form__group__header">
								<p className="form__group__label">Agent:</p>
								<span className="form__group__required" data-tooltip="Required">
									*
								</span>
							</div>
							<select
								id="agentSugarId"
								name="agentSugarId"
								className={`input input--med input--select${
									includes(errorListPaths, 'agentSugarId') ? ' is-invalid' : ''
								}`}
								disabled={lead.leadId}
								onBlur={this.validateFieldOnBlur('')}
								onChange={e => {
									this.setState(
										{ lead: { ...lead, agentSugarId: e.target.value } },
										this.getPartnersForAgent(e.target.value)
									);
								}}
							>
								<option key="" value="">
									Please Select
								</option>
								{map(agentList, a => (
									<option key={a.agentSugarId} value={a.agentSugarId} selected={lead.agentSugarId == a.agentSugarId}>
										{a.name}
									</option>
								))}
							</select>
						</div>
					</div>
				</div>
			)
		);
	};

	getPartnersForAgent = () => {
		appService
			.getAssociatedPartners()
			.then(resp => {
				this.setState({ partners: resp.partners });
			})
			.catch(err => {
				console.log('getAssociatedPartners error', err);
				this.setState({ errorMessage: 'An error occurred: ' + err });
			});
	};

	renderTelemarketerDropdown = () => {
		const { partners, lead } = this.state;
		const goPlusRoles = this.getGoPlusRoles();
		const hasOwnTiers = goPlusRoles && goPlusRoles.hasOwnGoPlusTiers;
		return (
			!!partners &&
			Object.keys(partners).length > 0 &&
			lead.processor === 'GoPlus' && (
				<div className="row">
					<div className="col col-sml-12 col-med-6 form__group">
						<div className="form__group__header">
							<p className="form__group__label">Rep 2:</p>
							{!hasOwnTiers && (
								<span className="form__group__required" data-tooltip="Required">
									*
								</span>
							)}
						</div>
						<select
							id="telemarketerSugarId"
							name="telemarketerSugarId"
							className={`input input--med input--select`}
							onBlur={this.validateFieldOnBlur('')}
							onChange={e => {
								this.setState({ lead: { ...lead, telemarketerSugarId: e.target.value } });
							}}
						>
							<option key="" value="">
								Please Select
							</option>
							{map(partners, tm => (
								<option key={tm.agentId} value={tm.agentSugarId} selected={lead.telemarketerSugarId == tm.agentSugarId}>
									{tm.name}
								</option>
							))}
						</select>
					</div>
				</div>
			)
		);
	};

	toggleUserDropDown = () => {
		this.setState({
			showUserDropDown: !this.state.showUserDropDown,
		});
	};

	validateFieldOnBlur = prefix => ({ target: { name } }) => {
		const { isSubmit, lead, errorList, canBoardAchq } = this.state;
		if (isSubmit) return;
		const fieldName = prefix ? `${prefix}.${name}` : `${name}`;
		const goPlusRoles = this.getGoPlusRoles();
		const hasOwnTiers = goPlusRoles && goPlusRoles.hasOwnGoPlusTiers;
		const tiersHaveAchq = hasOwnTiers && canBoardAchq;
		const validateFields = { lead: lead, hasOwnGoPlusTiers: hasOwnTiers, tiersHaveAchq: tiersHaveAchq };
		const newState = validateOnBlur(this.formSchema, validateFields, errorList, fieldName);
		this.setState(newState);
	};

	render() {
		const { isLoading } = this.props;
		const {
			lead,
			errorMessage,
			errorList,
			errorListPaths,
			newAppId,
			showUserDropDown,
			isLinkedUserSupportRep,
			isAgentSubUser,
			isLinkedUserSubAgent,
			canBoardAchq,
		} = this.state;
		const isCanadaLead = lead && lead.businessInfo.businessAddress.country == 'Canada';
		const hasLinkedApp = lead?.hasAccountWithFidelity;
		const isGoPlusOrProfitStarsAchOrAchq =
			lead && (lead.processor === 'GoPlus' || lead.processor === 'ProfitStars' || lead.processor === 'Achq');
		const goPlusPermissions = this.getGoPlusRoles();
		const userDropDownActiveClass = showUserDropDown ? ' is-active' : '';
		const isEappStarted =
			lead &&
			(toLower(lead.leadStatus) === 'eapp started' ||
				toLower(lead.leadStatus) === 'pend underwriting' ||
				toLower(lead.leadStatus) === 'pend bank approval');

		let eAppButtonText = '';

		switch (true) {
			case lead && lead.processor === 'ProfitStars':
				eAppButtonText = 'ProfitStars Ach';
				break;
			case lead && lead.processor === 'GoPlus':
				eAppButtonText = 'Cardknox Go';
				break;
			case lead && lead.processor === 'Achq':
				eAppButtonText = 'ACHQ';
				break;
			case isCanadaLead:
				eAppButtonText = 'Canadian';
				break;
			default:
				eAppButtonText = 'Regular';
		}

		const saveAsButtonText = `Save as ${eAppButtonText} eApp`;
		const dbaRequired = !lead?.hasAccountWithFidelity || !lead?.relatedAppId;

		return (
			<React.Fragment>
				<Notification ref={this.notificationRef} />
				<div className="leads" ref={this.detailsRef}>
					{newAppId ? (
						<div className="hide--on-print">
							<Link className="btn btn--link" to={`/merchants/${newAppId}/account`}>
								Go to App
							</Link>
						</div>
					) : null}
					{lead ? (
						<React.Fragment>
							<ModalWrapper modal={this.state.modal} onModalClose={this.openCloseModal} />
							{lead.leadId ? (
								<header className="leads__header">
									<div className="flex--primary flex--gap--sml">
										<h3>{lead.generalInfo.dba}</h3>
										<div className="flex--primary flex--nowrap flex--gap--sml">
											<LeadStatusComponent value={lead.leadStatus} />
											<LabelComponent value={lead.label} />
										</div>
									</div>

									<div className="flex--primary flex--gap--sml">
										{lead.leadStatus === 'Converted' ? null : isEappStarted ? (
											<button
												className="btn btn--primary btn--med"
												onClick={this.viewEapp}
											>{`View ${eAppButtonText} eApp`}</button>
										) : (
											<Fragment>
												{!isLinkedUserSupportRep && (
													<div className="pos--rel">
														{goPlusPermissions && !goPlusPermissions.canBoardGoPlus ? (
															<button
																className="btn btn--med btn--tertiary"
																disabled={isLoading}
																onClick={this.saveAndConvertLead()}
															>
																Create eApp
															</button>
														) : (
															<button
																className="btn btn--med btn--tertiary"
																disabled={isLoading}
																onClick={this.saveAndConvertLead()}
															>
																<i className="icon icon--sml icon--add"></i>
																<span>{`New ${eAppButtonText} eApp`}</span>
															</button>
														)}
													</div>
												)}
											</Fragment>
										)}
										<div className="pos--rel">
											<button
												onClick={this.toggleUserDropDown}
												className={`btn btn--action btn--action--secondary${userDropDownActiveClass}`}
											>
												<i className="icon icon--sml icon--more"></i>
											</button>

											{showUserDropDown && (
												<div className="popover popover--down w--170">
													<ul className="popover__list">
														<li className="item">{this.renderPrintButton()}</li>
														<li className="item">{this.renderExportLead()}</li>
													</ul>
												</div>
											)}
										</div>

										{this.renderReviewPricingPopup()}
									</div>
								</header>
							) : (
								<header className="modal__header">
									<h4>Add New Lead</h4>
								</header>
							)}

							<div>
								<div className="application-type">
									{this.renderAgentDropdown()}
									{!isCanadaLead && (
										<div className="row flex--bottom spc--bottom--sml">
											<div className="col col-sml-12 col-med-6 form__group">
												<div className="form__group__header">
													<p className="form__group__label">Application Type</p>
												</div>
												<select
													className="input input--med input--select"
													name={'processor'}
													id={'processor'}
													onChange={this.handleProcessorChange}
													value={lead.processor}
													disabled={!has(lead, 'processor') || hasLinkedApp}
												>
													<option value={''}>Traditional</option>
													{goPlusPermissions?.canBoardGoPlus && <option value={'GoPlus'}>Cardknox Go</option>}
													<option value={'ProfitStars'}>ProfitStars Ach</option>
													{canBoardAchq && <option value={'Achq'}>ACHQ</option>}
												</select>
											</div>
											{canBoardAchq && (lead.processor === 'Achq' || lead.processor === 'GoPlus') && (
												<div className="col col-sml-12 col-lrg-6 form__group">
													<div className="flex--primary flex--gap--tny spc--bottom--sml">
														<div>
															<input
																className="input--check"
																name="hasAccountWithFidelity"
																id="hasAccountWithFidelity"
																type="checkbox"
																checked={lead.hasAccountWithFidelity}
																onChange={this.handleLinkedAppChange}
															/>
															<label htmlFor="hasAccountWithFidelity">Link To Existing Account?</label>
														</div>
														<i
															className="icon icon--tny icon--regular--info datatooltip--down-left datatooltip--200"
															data-tooltip="Providing the App ID will prefill certain fields in this application and ensure that the account is set up with the same gateway equipment as the linked application."
														/>
													</div>
												</div>
											)}
										</div>
									)}
									{lead.hasAccountWithFidelity && (
										<div className="row">
											<div className="col col-sml-12 col-lrg-6 form__group">
												<div className="form__group__header">
													<p className="form__group__label">Existing Account App ID</p>
													<span className="form__group__required" data-tooltip="Required">
														*
													</span>
												</div>
												<input
													type="text"
													className="input input--med"
													disabled={!lead.hasAccountWithFidelity}
													name={'relatedAppId'}
													id={'relatedAppId'}
													value={lead.relatedAppId}
													onBlur={this.validateFieldOnBlur('')}
													onChange={this.handleLinkedAppChange}
												></input>
											</div>
										</div>
									)}
								</div>

								{this.renderTelemarketerDropdown()}

								<GeneralInfo
									dbaRequired={dbaRequired}
									disabled={isEappStarted}
									generalInfo={lead.generalInfo}
									onChange={this.handleChange}
									errorListPaths={errorListPaths}
									validateFieldOnBlur={this.validateFieldOnBlur('generalInfo')}
									isCanadaLead={isCanadaLead}
								/>
								<BusinessInformation
									disabled={isEappStarted}
									businessInfo={lead.businessInfo}
									corporateName={lead.generalInfo.corporateName}
									onChange={this.handleChange}
									hideCanadaOption={isGoPlusOrProfitStarsAchOrAchq}
									isCanada={isCanadaLead}
									processor={lead.processor}
								/>
								<ContactInformation
									disabled={isEappStarted}
									contactInfo={lead.contactInfo}
									onChange={this.handleChange}
									errorListPaths={errorListPaths}
									validateFieldOnBlur={this.validateFieldOnBlur('contactInfo')}
								/>

								<AdditionalInformation
									disabled={isEappStarted}
									addlInfo={lead.addlInfo}
									onChange={this.handleChange}
									openCloseModal={this.openCloseModal}
									reload={this.refresh}
									showLoader={this.props.showLoader}
								/>
								{lead.leadId && !isAgentSubUser && !isLinkedUserSubAgent && !isLinkedUserSupportRep && (
									<ReviewPricingResults leadId={lead.leadId} />
								)}

								<div ref={this.errorRef} className="modal__body--info-panel--section">
									{errorMessage && <div className="type--validation spc--bottom--med">{errorMessage}</div>}
									{!isEmpty(errorList) && (
										<div className="type--validation spc--bottom--med">
											<ul>
												{map(errorList, elem => (
													<li key={elem.path}>
														<button
															className="btn btn--link btn--link--error flex--gap--tny"
															onClick={() => {
																let elemId = elem.path;
																this.scrollTo(elemId);
																this.focusField(elemId);
															}}
														>
															{defaultReactOutput(defaultImplicitParse(elem.message))}
														</button>
													</li>
												))}
											</ul>
										</div>
									)}
								</div>
							</div>
							{renderIf(!lead.leadId)(
								<div className="modal__footer">
									{!isLinkedUserSupportRep && (
										<button
											className="btn btn--med btn--secondary"
											disabled={isLoading || !has(lead, 'processor')}
											onClick={this.saveAndConvertLead()}
										>
											{saveAsButtonText}
										</button>
									)}
									<button
										className="btn btn--med btn--primary"
										disabled={isLoading || !has(lead, 'processor') || hasLinkedApp}
										onClick={this.handleSaveLead(true, false)}
									>
										Save as Lead
									</button>
								</div>
							)}
							{renderIf(!!lead.leadId && !isEappStarted)(
								<div className="leads__footer">
									{!hasLinkedApp && (
										<button
											className="btn btn--med btn--primary"
											disabled={isLoading}
											onClick={this.handleSaveLead(false, true)}
										>
											Save
										</button>
									)}
									{hasLinkedApp && (
										<button
											className="btn btn--med btn--primary"
											disabled={isLoading || !has(lead, 'processor')}
											onClick={this.saveAndConvertLead()}
										>
											{saveAsButtonText}
										</button>
									)}
								</div>
							)}
						</React.Fragment>
					) : null}
				</div>
			</React.Fragment>
		);
	}
}

LeadDetails.propTypes = {
	match: PropTypes.object,
	history: PropTypes.object,
	isLoading: PropTypes.bool,
	showLoader: PropTypes.func,
	closeModal: PropTypes.func,
	addNotification: PropTypes.func,
	handleBlockChange: PropTypes.func,
};

export default withLoader(withBlock(LeadDetails));
