import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { map, find, noop, startsWith, replace, filter } from 'lodash';
import { Switch, NavLink, Redirect } from 'react-router-dom';
import sectionKeys from '../../routing/sections';
import { withError } from '../../common/components/error';
import { Security } from '../../components/Login';
import { OutsideClick, invokeIfFunction } from '../../common/utilities';
import Route from '../../routing/Route';
import { principalService } from '../../services';
import { withLoader } from '../../common/components';
import EquipmentTemplate from '../EquipmentTemplate/EquipmentTemplate';
import PartnerSettings from './PartnerSettings';
import CobrandingSettings from './CobrandingSettings';

const PlaceholderComponent = ({ location }) => (
	<div className="settings--main settings--main--alt">
		<h3 className="spc--top--sml">
			Placeholder For <code>{location.pathname}</code>
		</h3>
	</div>
);
PlaceholderComponent.propTypes = { location: PropTypes.object.isRequired };

const routes = [
	{
		path: '/equipment-template',
		label: 'Default Equipment',
		component: EquipmentTemplate,
		isDefault: true,
		isPublic: false,
		section: sectionKeys.settings,
	},
	{
		path: '/partner-settings',
		label: 'General Settings',
		component: PartnerSettings,
		isDefault: false,
		isPublic: false,
		section: sectionKeys.validpartner,
	},
	{
		path: '/cobranding-settings',
		label: 'Cobranding Settings',
		component: CobrandingSettings,
		isDefault: false,
		isPublic: false,
		section: sectionKeys.cobrand,
	},
	{
		path: '/security',
		label: 'Security',
		component: Security,
		isDefault: false,
		isPublic: false,
		section: sectionKeys.settings,
	},
];

class Settings extends Component {
	constructor(props) {
		super(props);
		this.principal = principalService.get();
		this.routes = [...routes];
		this.state = {
			isNavOpen: false,
		};

		this.routes.push({
			path: '',
			exact: true,
			component: () => {
				return <Redirect to={{ pathname: this.defaultRoute }} />;
			},
		});
	}

	async componentDidMount() {
		if (this.principal.isDropinUser) {
			const { history } = this.props;
			history.push('/');
		}
	}

	get routesWithAccess() {
		return filter(routes, ({ section }) => this.authorize(section));
	}

	get defaultRoute() {
		const defRoute = find(this.routes, ({ isDefault }) => isDefault);
		if (defRoute) {
			return `${this.props.match.url}${defRoute.path}`;
		}
		return '';
	}

	get redirectRoute() {
		const { match } = this.props;
		if (replace(match.path, /\//g, '') === replace('settings', /\//g, '')) return '';
		return 'settings/';
	}

	openCloseNav = () => {
		this.setState({
			isNavOpen: !this.state.isNavOpen,
		});
	};

	authorize = section => {
		const { principal } = this;
		return !section || (principal && principal.hasAccess && !!principal.hasAccess[section]);
	};

	renderHeaderPanel() {
		const urlParams = new URLSearchParams(this.props.location.search);
		const isStepup = urlParams.get('isStepup');

		if (isStepup) {
			return null;
		}

		const currentRoute = this.routes.find(route => this.props.location.pathname.includes(route.path));
		const routeLabel = currentRoute ? currentRoute.label : 'Settings';

		return (
			<header>
				<h3 className="spc--bottom--lrg">{routeLabel}</h3>
			</header>
		);
	}
	renderRoutes() {
		return (
			<Switch>
				{map(this.routes, (routeData, i) => {
					return invokeIfFunction(routeData.hidden) || routeData.disabled ? null : (
						<Route
							key={routeData.path}
							{...routeData}
							defaultRoute={this.defaultRoute}
							path={`${this.props.match.path}${routeData.path}`}
						/>
					);
				})}
				<Redirect to={{ pathname: this.redirectRoute }} />
			</Switch>
		);
	}

	renderShowSidebar(onNavClick, navigationList, navClasses, pathname, url) {
		return (
			<div className="settings__aside">
				<div className="settings__dropdown">
					<OutsideClick action={onNavClick}>
						<Fragment>
							<div className="pos--rel">
								<div className="input input--med input--select" onClick={this.openCloseNav}>
									{
										(
											find(this.routes, ({ exact, path }) =>
												exact ? pathname === `${url}${path}` : startsWith(pathname, `${url}${path}`)
											) || { label: 'Account Settings' }
										).label
									}
								</div>
								<div className={navClasses}>
									<ul className="popover__list">{navigationList}</ul>
								</div>
							</div>
						</Fragment>
					</OutsideClick>
				</div>
				<ul className="nav--secondary">{navigationList}</ul>
			</div>
		);
	}

	render() {
		const {
			match: { url },
			location: { pathname },
		} = this.props;
		const { isNavOpen } = this.state;
		const navClasses = `popover popover--down fullwidth${isNavOpen ? ' is-active' : ''}`;
		const onNavClick = isNavOpen ? this.openCloseNav : noop;

		const navigationList = map(this.routesWithAccess, ({ path, label }) => (
			<li key={path} className="nav--secondary__item">
				<NavLink className="nav--secondary__link" onClick={onNavClick} activeClassName="is-active" to={`${url}${path}`}>
					{label}
				</NavLink>
			</li>
		));

		return (
			<div className="settings">
				{this.renderShowSidebar(onNavClick, navigationList, navClasses, pathname, url)}
				<div className="settings__main">
					{this.renderHeaderPanel()}
					{this.renderRoutes()}
				</div>
			</div>
		);
	}
}

Settings.propTypes = {
	match: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	handleError: PropTypes.func.isRequired,
	makePendingRequest: PropTypes.func.isRequired,
};

export default withError(withLoader(Settings));
