import React, { createRef } from 'react';
import { keys } from 'lodash';

import { renderIf } from './../../common/utilities';
import { DisplayBooleanComponent } from '../../common/formatters';
import { Toggle, ToggleContainer } from './../../common/components/toggle';

const isExpand = true;

class BusinessInformation extends React.Component {
	toggleRef = createRef();
	showMoreForPrint = async () => {
		if (!this.toggleRef.current) {
			return;
		}
		const { isToggled, handleToggle } = this.toggleRef.current.state.toggles[
			keys(this.toggleRef.current.state.toggles)[0]
		];
		this.collapseAfterPrint = isToggled ? () => {} : handleToggle;
		if (!isToggled) {
			handleToggle();
		}
	};
	collapseAfterPrint = () => {};
	render() {
		const { merchant } = this.props;
		const taxableInfo =
			'Please note! Sales to this merchant are subject to NY sales tax. ' +
			'Non-profits and religious organizations must obtain a NY Exemption Certificate to be exempt from tax. ' +
			'Please advise merchant to fill out form 119.2 and mail it to NYS to obtain an Exemption Certificate. ' +
			'We do not have an exemption certificate on file.';
		//const taxableInfo = (
		//    <div className="datatooltip merchant__tooltip merchant__tooltip--alt">
		//        Please note! Sales to this merchant are subject to NY sales tax.
		//        Non-profits and religious organizations must obtain a NY Exemption Certificate to be exempt from tax.
		//        Please advise merchant to fill out form 119.2 and mail it to NYS to obtain an Exemption Certificate.
		//        <div className="type--wgt--bold">
		//            We do not have an exemption certificate on file.
		//        </div>
		//    </div>
		//);
		return (
			<ToggleContainer ref={this.toggleRef}>
				<Toggle initialToggle={isExpand}>
					{({ isToggled, handleToggle }) => (
						<React.Fragment>
							<div className={`card ${isToggled ? 'is-expanded' : ''} spc--bottom--lrg`}>
								<button className="card__header card__header--expandable" onClick={handleToggle}>
									<h5>Business Information</h5>
									<i className={`icon icon--lrg icon--chevron--${isToggled ? 'down' : 'right'}--primary`}></i>
								</button>
								{isToggled ? (
									<div className="card__body">
										<div className="row">
											<div className="col col-sml-12 col-med-6 form__group">
												<div className="form__group__header">
													<label className="form__group__label" htmlFor="lblCorpName">
														Business Legal Name
													</label>
												</div>
												<p className="input input--med input--disabled" name="lblCorpName" id="lblCorpName">
													{merchant.corporateName}
												</p>
											</div>
											<div className="col col-sml-12 col-med-6 form__group">
												<div className="form__group__header">
													<label className="form__group__label" htmlFor="lblOwnerName">
														Owner Name
													</label>
												</div>
												<label className="input input--med input--disabled" name="lblOwnerName" id="lblOwnerName">
													{merchant.ownerName}
												</label>
											</div>
											<div className="col col-sml-12 col-med-6 form__group">
												<div className="form__group__header">
													<label className="form__group__label" htmlFor="lblCorpAddress">
														Corporate Address
													</label>
												</div>
												<label className="input input--med input--disabled" name="lblCorpAddress" id="lblCorpAddress">
													{merchant.corporateAddress.streetAddress}
												</label>
											</div>
											<div className="col col-sml-12 col-med-6 form__group">
												<div className="form__group__header">
													<label className="form__group__label" htmlFor="lblCorporateAddressCity">
														Corporate City
													</label>
												</div>
												<label
													className="input input--med input--disabled"
													name="lblCorporateAddressCity"
													id="lblCorporateAddressCity"
												>
													{merchant.corporateAddress.city}
												</label>
											</div>
											<div className="col col-sml-12 col-med-6 form__group">
												<div className="form__group__header">
													<label className="form__group__label" htmlFor="lblCorporateAddressState">
														Corporate State
													</label>
												</div>
												<label
													className="input input--med input--disabled"
													name="lblCorporateAddressState"
													id="lblCorporateAddressState"
												>
													{merchant.corporateAddress.state}
												</label>
											</div>
											<div className="col col-sml-12 col-med-6 form__group">
												<div className="form__group__header">
													<label className="form__group__label" htmlFor="lblCorporateAddressZip">
														Corporate Zip
													</label>
												</div>
												<label
													className="input input--med input--disabled"
													name="lblCorporateAddressZip"
													id="lblCorporateAddressZip"
												>
													{merchant.corporateAddress.zip}
												</label>
											</div>
											<div className="col col-sml-12 col-med-6 form__group">
												<div className="form__group__header">
													<label className="form__group__label" htmlFor="lblCorpPhone">
														Corp Phone
													</label>
												</div>
												<label className="input input--med input--disabled" name="lblCorpPhone" id="lblCorpPhone">
													{merchant.corporatePhone}
												</label>
											</div>
											<div className="col col-sml-12 col-med-6 form__group">
												<div className="form__group__header">
													<label className="form__group__label" htmlFor="lblBusinessAddress">
														Physical Address
													</label>
												</div>
												<label
													className="input input--med input--disabled"
													name="lblBusinessAddress"
													id="lblBusinessAddress"
												>
													{merchant.businessAddress.streetAddress}
												</label>
											</div>
											<div className="col col-sml-12 col-med-6 form__group">
												<div className="form__group__header">
													<label className="form__group__label" htmlFor="lblBusinessAddressCity">
														Physical City
													</label>
												</div>
												<label
													className="input input--med input--disabled"
													name="lblBusinessAddressCity"
													id="lblBusinessAddressCity"
												>
													{merchant.businessAddress.city}
												</label>
											</div>
											<div className="col col-sml-12 col-med-6 form__group">
												<div className="form__group__header">
													<label className="form__group__label" htmlFor="lblBusinessAddressState">
														Physical State
													</label>
												</div>
												<label
													className="input input--med input--disabled"
													name="lblBusinessAddressState"
													id="lblBusinessAddressState"
												>
													{merchant.businessAddress.state}
												</label>
											</div>
											<div className="col col-sml-12 col-med-6 form__group">
												<div className="form__group__header">
													<label className="form__group__label" htmlFor="lblBusinessAddressZip">
														Physical Zip
													</label>
												</div>
												<label
													className="input input--med input--disabled"
													name="lblBusinessAddressZip"
													id="lblBusinessAddressZip"
												>
													{merchant.businessAddress.zip}
												</label>
											</div>
											{renderIf(merchant.shouldShowTaxInfo)(
												<div className="col col-sml-12 col-med-6 form__group">
													<div className="form__group__header">
														<label className="form__group__label" htmlFor="lblTaxable">
															Taxable:
														</label>
														<span className="flex--primary flex--gap--sml" name="lblTaxable" id="lblTaxable">
															<DisplayBooleanComponent value={merchant.isTaxable} />
															{renderIf(merchant.isTaxable)(
																<i className="icon icon--tny icon--required--info" data-tooltip={taxableInfo} />
															)}
														</span>
													</div>
												</div>
											)}
										</div>
									</div>
								) : null}
							</div>
						</React.Fragment>
					)}
				</Toggle>
			</ToggleContainer>
		);
	}
}

export default BusinessInformation;
