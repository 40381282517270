import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import QuantityStepper from './QuantityStepper';
import OptionComponent from './OptionComponent';

const QuantityComponent = ({
	isPaper,
	data,
	options,
	disableAddButton,
	adjustQuantity,
	handleOptionChange,
	accessories,
}) => {
	return (
		<div className="equipment__details__aside__row">
			<p className="type--p3 type--p3--medium type--color--text--light">Quantity</p>
			{isPaper ? (
				<OptionComponent
					equipmentId={get(data, 'equipmentId')}
					equipmentOptions={options}
					acccessories={accessories}
					handleChange={handleOptionChange}
					isHardware={true}
					accessoryName="Quantity"
					data={data}
				/>
			) : (
				<div className="w--120">
					<QuantityStepper disabled={disableAddButton} adjustQuantity={adjustQuantity} quantity={data.quantity} />
				</div>
			)}
			{data.isSelected && (
				<p className="type--p3 type--color--success fullwidth">This item has been added to your order</p>
			)}
		</div>
	);
};

QuantityComponent.propTypes = {
	isPaper: PropTypes.bool,
	data: PropTypes.object,
	options: PropTypes.array,
	disableAddButton: PropTypes.bool,
	adjustQuantity: PropTypes.func,
	handleOptionChange: PropTypes.func,
	accessories: PropTypes.array,
};

export default QuantityComponent;
