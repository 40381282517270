import React from 'react';
import { noop, replace, some, isObject } from 'lodash';
import { StateDropdownComponent } from '../state-dropdown';

export default function AddressComponent({
	address,
	required,
	namePrefix,
	onChange,
	validateField = noop,
	showName = false,
	streetPrefix = 'Street',
	requiredFields = {},
	isCanada,
	disabled,
}) {
	function isRequired(field) {
		return (
			required ||
			requiredFields[`${replace(namePrefix, '_', '.')}.${field}`] ||
			some(requiredFields, reqField => !isObject(reqField) && reqField === `${replace(namePrefix, '_', '.')}.${field}`)
		);
	}
	return (
		<div className="fullwidth">
			<div className="row spc--top--sml">
				{showName && (
					<div className="col col-sml-12 col-lrg-6 form__group">
						<div id={namePrefix + '_name_div'} className="form__group__header">
							<p className="form__group__label">Name</p>
							{isRequired('name') ? (
								<span className="form__group__required" data-tooltip="Required">
									*
								</span>
							) : null}
						</div>
						<input
							type="text"
							className={`input input--med ${validateField(namePrefix + '_name')}`}
							name={namePrefix + '_name'}
							id={namePrefix + '_name'}
							value={address.name}
							disabled={disabled}
							onChange={onChange}
						></input>
					</div>
				)}
				<div className="col col-sml-12 col-lrg-6 form__group">
					<div id={namePrefix + '_streetAddress_div'} className="form__group__header">
						<p className="form__group__label">{streetPrefix} Address</p>
						<span className="form__group__required" data-tooltip="Required">
							*
						</span>
					</div>
					<input
						type="text"
						className={`input input--med ${validateField(namePrefix + '_streetAddress')}`}
						name={namePrefix + '_streetAddress'}
						id={namePrefix + '_streetAddress'}
						disabled={disabled}
						value={address.streetAddress}
						onChange={onChange}
					></input>
				</div>
				<div className="col col-sml-12 col-lrg-6 form__group">
					<div id={namePrefix + '_city_div'} className="form__group__header">
						<p className="form__group__label">City</p>
						{isRequired('city') ? (
							<span className="form__group__required" data-tooltip="Required">
								*
							</span>
						) : null}
					</div>
					<input
						type="text"
						className={`input input--med ${validateField(namePrefix + '_city')}`}
						name={namePrefix + '_city'}
						id={namePrefix + '_city'}
						disabled={disabled}
						value={address.city}
						onChange={onChange}
					></input>
				</div>
				<div className="col col-sml-12 col-lrg-6 form__group">
					<div id={namePrefix + '_state_div'} className="form__group__header">
						<p className="form__group__label">{isCanada ? 'Province' : 'State'} </p>
						{isRequired('state') ? (
							<span className="form__group__required" data-tooltip="Required">
								*
							</span>
						) : null}
					</div>
					<StateDropdownComponent
						className={`input input--med input--select ${validateField(namePrefix + '_state')}`}
						id={namePrefix + '_state'}
						selectedState={address.state}
						disabled={disabled}
						onChange={onChange}
						isCanada={isCanada}
					/>
				</div>
				<div className="col col-sml-12 col-lrg-6 form__group">
					<div id={namePrefix + '_zip_div'} className="form__group__header">
						<p className="form__group__label">{isCanada ? 'Postal Code' : 'ZIP'} </p>
						{isRequired('zip') ? (
							<span className="form__group__required" data-tooltip="Required">
								*
							</span>
						) : null}
					</div>
					<input
						type="text"
						className={`input input--med ${validateField(namePrefix + '_zip')}`}
						name={namePrefix + '_zip'}
						id={namePrefix + '_zip'}
						value={address.zip}
						disabled={disabled}
						onChange={onChange}
					></input>
				</div>
			</div>
		</div>
	);
}
