import React, { Component, Fragment, createRef } from 'react';
import { get, toLower, map, trim, isEmpty, orderBy } from 'lodash';
import { func, object } from 'prop-types';
import { appService } from '../../../services';
import { DisplayDateComponent } from '../../../common/formatters';
import { Toggle, ToggleContainer } from '../../../common/components/toggle';
import { RenderSanitizedHTML } from '../../../common/utilities';
import { TicketDetailsRenderer } from './components/TicketDetailsRenderer';
import TabList from '../../../common/components/TabList/TabList';

const statusClass = {
	'closed - resolved': 'default',
	'closed - unresolved': 'default',
	open: 'success',
	new: 'success',
	'deleted [closed]': 'default',
};

const noteTabs = {
	new: 'new',
	archive: 'archive',
};

class TicketDetailsComponent extends Component {
	constructor() {
		super();

		this.state = {
			isLoading: false,
			notes: [],
			details: null,
			newNote: '',
			noteTab: noteTabs.new,
			errorMessage: null,
			isTicketDetailsToggled: false,
			activeTab: 'notes',
		};

		this.errorRef = createRef();
	}

	componentDidMount() {
		this.showLoader(true);
		appService
			.getTicketDetails(get(this.props, 'row.ticketNumber'))
			.then(({ ticketDetails: { notes, details } }) => this.setState({ notes, details, isLoading: false }));
	}

	componentDidUpdate() {
		if (this.state.errorMessage && this.state.isNewError) {
			setTimeout(() => {
				if (this.errorRef.current) {
					this.errorRef.current.scrollIntoView({
						behavior: 'smooth',
						block: 'center',
					});
				}
			}, 200);
			this.setState({ isNewError: false });
		}
	}

	handleChange = ({ target: { name, value } }) => this.setState({ [name]: value });

	handleTabChange = tab => this.setState({ activeTab: tab });

	handleSave = () => {
		const {
			row: { ticketNumber },
		} = this.props;
		const { newNote } = this.state;

		this.showLoader(true);
		appService
			.addNoteToTicket(parseInt(ticketNumber), [trim(newNote)])
			.then(() =>
				appService
					.getTicketDetails(get(this.props, 'row.ticketNumber'))
					.then(({ ticketDetails: { notes, details } }) =>
						this.setState({ notes, details, isLoading: false, newNote: '' })
					)
			)
			.catch(err => {
				this.showLoader();
				this.setState({ errorMessage: 'An error occurred: ' + err, isNewError: true });
			});
	};

	showLoader = (isLoading = false) => this.setState({ isLoading });

	renderWithToggle = (initialToggle, title, content) => (
		<Toggle initialToggle={initialToggle}>
			{({ isToggled, handleToggle }) => (
				<button
					className="btn input--check--enable-form type--p2 spc--bottom--xxlrg cursor--pointer fullwidth"
					onClick={handleToggle}
				>
					<div className="flex--secondary fullwidth">
						<span className="type--p2--medium">{title}</span>
						<i className={`icon icon--sml icon--chevron--${isToggled ? 'top' : 'right'}--primary`}></i>
					</div>
					<div className={isToggled ? '' : 'display--n'}>{content}</div>
				</button>
			)}
		</Toggle>
	);

	renderDetails = () =>
		this.renderWithToggle(
			false,
			'Original note:',
			<RenderSanitizedHTML content={this.state.details} title={'originalTicketNote'} />
		);

	renderNewNote = () => {
		const { newNote, isLoading } = this.state;

		return (
			<div className="ticket__notes__texteditor">
				<textarea
					className="input"
					type="text"
					name="newNote"
					id="newNote"
					placeholder="Write your note..."
					value={newNote}
					onChange={this.handleChange}
					rows={3}
				/>
				<div className="ticket__notes__texteditor__footer">
					<button
						className="btn btn--sml btn--primary"
						onClick={this.handleSave}
						disabled={!trim(newNote) || isLoading}
					>
						Send
					</button>
				</div>
			</div>
		);
	};

	renderArchiveOfNotes = () =>
		isEmpty(this.state.notes) ? (
			<p className="type--p2 type--color--text--light spc--bottom--xxlrg">This ticket does not have any notes.</p>
		) : (
			<div>
				{map(
					orderBy(this.state.notes, [note => new Date(note.dateCreated)], ['desc']),
					({ createdBy, dateCreated, emailed, note }) => (
						<div className="ticket__notes" key={`${createdBy}.${dateCreated}`}>
							<div className="flex--primary flex--gap--sml--alt spc--bottom--sml">
								<div className="ticket__notes__avatar">IP</div>
								<p className="type--p2 type--p2--medium">{createdBy}</p>
								<p className="type--p4 type--color--text--light">
									<DisplayDateComponent value={dateCreated} />
								</p>
							</div>
							<div className="ticket__notes__body">
								<p className="type--p2 type--color--text--light">{note}</p>
							</div>
						</div>
					)
				)}
			</div>
		);

	renderNotes = () => (
		<div>
			{this.renderArchiveOfNotes()}
			{this.renderNewNote()}
		</div>
	);

	render() {
		const { isLoading, errorMessage, activeTab } = this.state;
		const {
			row: { ticketNumber, assignedTo, status },
		} = this.props;

		return (
			<React.Fragment>
				<div className="modal__header">
					<div className="flex--primary flex--gap--sml spc--bottom--tny">
						<h4>
							<span className="type--color--text--light">Ticket</span> #{ticketNumber}
						</h4>
						<div className={`badge badge--${statusClass[toLower(status)] || 'pending'}`}>{status}</div>
					</div>
					<button className="btn btn--action btn--action--secondary" onClick={this.props.closeModal}>
						<i className="icon icon--sml icon--close"></i>
					</button>
					<div className="flex--primary flex--gap--tny fullwidth">
						<p className="type--p3 type--color--text--light">Assigned to:</p>
						<p className="type--p3 type--p3--medium">{assignedTo}</p>
					</div>
				</div>
				<div className="modal__body">
					<TabList
						className="tabs spc--bottom--lrg"
						onChange={this.handleTabChange}
						titles={[
							{ label: 'Notes', key: 'notes' },
							{ label: 'Details', key: 'details' },
						]}
					/>

					{activeTab === 'notes' && (
						<Fragment>
							{isLoading ? (
								<div className="loader">
									<div className="loader__spinner"></div>
								</div>
							) : (
								<Fragment>
									{errorMessage ? (
										<div className="type--validation" ref={this.errorRef}>
											{errorMessage}
										</div>
									) : null}

									<ToggleContainer>{this.renderDetails()}</ToggleContainer>

									{this.renderNotes()}
								</Fragment>
							)}
						</Fragment>
					)}
					{activeTab === 'details' && <TicketDetailsRenderer {...this.props.row} />}
				</div>
			</React.Fragment>
		);
	}
}

TicketDetailsComponent.defaultProps = {
	row: {},
};

TicketDetailsComponent.propTypes = {
	closeModal: func.isRequired,
	row: object.isRequired,
	addNotification: func.isRequired,
};

export default TicketDetailsComponent;
