import React from 'react';
import PropTypes from 'prop-types';
import { Button, CaptionDropdowns, CaptionLabel, IconLeft, IconRight, useDayPicker, useNavigation } from 'react-day-picker';

function CaptionNavigation(props) {
  const { previousMonth, nextMonth, goToMonth } =
    useNavigation();

  const handlePreviousClick = () => {
    if (!previousMonth) return;
    goToMonth(previousMonth);
  };

  const handleNextClick = () => {
    if (!nextMonth) return;
    goToMonth(nextMonth);
  };

  return (
    <Navigation
      displayMonth={props.displayMonth}
      hideNext={false}
      hidePrevious={false}
      nextMonth={nextMonth}
      previousMonth={previousMonth}
      onPreviousClick={handlePreviousClick}
      onNextClick={handleNextClick}
    />
  );
}

CaptionNavigation.propTypes = {
  displayMonth: PropTypes.instanceOf(Date).isRequired,
};

function Navigation(props) {
  const {
    dir,
    locale,
    classNames,
    styles,
    labels: { labelPrevious, labelNext },
    components
  } = useDayPicker();

  if (!props.nextMonth && !props.previousMonth) {
    return <></>;
  }

  const previousLabel = labelPrevious(props.previousMonth, { locale });
  const previousClassName = [
    classNames.nav_button,
    classNames.nav_button_previous
  ].join(" ");

  const nextLabel = labelNext(props.nextMonth, { locale });
  const nextClassName = [
    classNames.nav_button,
    classNames.nav_button_next
  ].join(" ");

  const IconRightComponent = components?.IconRight ?? IconRight;
  const IconLeftComponent = components?.IconLeft ?? IconLeft;
  return (
    <div className={classNames.nav} style={styles.nav}>
      {!props.hidePrevious && (
        <Button
          name="previous-month"
          aria-label={previousLabel}
          className={previousClassName}
          style={styles.nav_button_previous}
          disabled={!props.previousMonth}
          onClick={props.onPreviousClick}
        >
          {dir === "rtl" ? (
            <IconRightComponent
              className={classNames.nav_icon}
              style={styles.nav_icon}
            />
          ) : (
            <IconLeftComponent
              className={classNames.nav_icon}
              style={styles.nav_icon}
            />
          )}
        </Button>
      )}
      {!props.hideNext && (
        <Button
          name="next-month"
          aria-label={nextLabel}
          className={nextClassName}
          style={styles.nav_button_next}
          disabled={!props.nextMonth}
          onClick={props.onNextClick}
        >
          {dir === "rtl" ? (
            <IconLeftComponent
              className={classNames.nav_icon}
              style={styles.nav_icon}
            />
          ) : (
            <IconRightComponent
              className={classNames.nav_icon}
              style={styles.nav_icon}
            />
          )}
        </Button>
      )}
    </div>
  );
}

Navigation.propTypes = {
  previousMonth: PropTypes.instanceOf(Date),
  nextMonth: PropTypes.instanceOf(Date),
  hidePrevious: PropTypes.bool,
  hideNext: PropTypes.bool,
  onNextClick: PropTypes.func.isRequired,
  onPreviousClick: PropTypes.func.isRequired,
};

export default function Caption(props) {
  const { classNames, disableNavigation, styles, captionLayout, components } =
    useDayPicker();

  const CaptionLabelComponent = components?.CaptionLabel ?? CaptionLabel;

  let caption;
  if (disableNavigation) {
    caption = (
      <CaptionLabelComponent id={props.id} displayMonth={props.displayMonth} />
    );
  } else if (captionLayout === "dropdown") {
    caption = (
      <CaptionDropdowns displayMonth={props.displayMonth} id={props.id} />
    );
  } else if (captionLayout === "dropdown-buttons") {
    caption = (
      <>
        <CaptionDropdowns
          displayMonth={props.displayMonth}
          displayIndex={props.displayIndex}
          id={props.id}
        />
        <CaptionNavigation
          displayMonth={props.displayMonth}
          displayIndex={props.displayIndex}
          id={props.id}
        />
      </>
    );
  } else {
    caption = (
      <>
        <CaptionLabelComponent
          id={props.id}
          displayMonth={props.displayMonth}
          displayIndex={props.displayIndex}
        />
        <CaptionNavigation displayMonth={props.displayMonth} id={props.id} />
      </>
    );
  }

  return (
    <div className={classNames.caption} style={styles.caption}>
      {caption}
    </div>
  );
}

Caption.propTypes = {
  id: PropTypes.string,
  displayMonth: PropTypes.instanceOf(Date).isRequired,
  displayIndex: PropTypes.number,
};
