import React from 'react';
class EAppCompleteComponent extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div id="main-div" className="l--success-submit">
				<i className="icon icon--regular--check"></i>
				<div className="type--center">
					<h3>Thank you!</h3>
					<h5 className="type--color--text--light spc--top--sml">Your application has been submitted successfully.</h5>
				</div>
			</div>
		);
	}
}

export default EAppCompleteComponent;
