import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { Toggle, ToggleContainer } from './../../common/components/toggle';
import { AddressComponent } from '../../common/components/address';
import { DropdownContainer } from '../../common/components/dropdown-container';
import { AddressAutocomplete } from '../EApp';

const isExpand = true;

class BusinessInformation extends React.Component {
	renderGoogleMapsAutocomplete = () => {
		return (
			<div className="row">
				<div className="col col-lrg-12 form__group">
					<AddressAutocomplete onSelectAddress={this.handleSelectAddress}  />
				</div>
			</div>
		)
	};

	handleSelectAddress = (address) => {
            if (address) {
                const {businessInfo: {businessAddress}} = this.props;
                const updatedBusinessAddress = cloneDeep(businessAddress);
                updatedBusinessAddress.streetAddress = address.street;
                updatedBusinessAddress.city = address.city;
                updatedBusinessAddress.state = address.state;
                updatedBusinessAddress.zip = address.zip;
                this.props.onChange('businessInfo', 'businessAddress', updatedBusinessAddress);
            }
        };

	handleChange = (nestedKey, prefix = 'businessInfo') => e => {
		const { name, value } = e.target;
		const parts = name.split('_');
		const lastPart = parts[parts.length - 1];
		this.props.onChange(prefix, lastPart, value, nestedKey);
	};

	renderCountryOption = () => {
		const { businessInfo, hideCanadaOption } = this.props;
		let countryOptions = [{ value: 'USA', text: 'USA' }];
		if (!hideCanadaOption) {
			countryOptions.push({ value: 'Canada', text: 'Canada' });
		}
		return (
			<React.Fragment>
				<div className="form__group">
					<div className="form__group__header">
						<p className="form__group__label">Country</p>
					</div>
					<DropdownContainer
						type="radio"
						selectName="country"
						value={businessInfo.businessAddress.country}
						onChange={this.handleChange('businessAddress')}
						labelText=""
						options={countryOptions}
						labelClass="display--n"
						disabled={this.props.disabled}
					/>
				</div>
			</React.Fragment>
		);
	};

	render() {
		const { businessInfo, corporateName, processor } = this.props;

		return (
			<div className="form--popup">
				<ToggleContainer>
					<Toggle initialToggle={isExpand}>
						{({ isToggled, handleToggle }) => (
							<div className={`card${isToggled ? ' is-expanded' : ''} spc--bottom--lrg`}>
								<button className="card__header card__header--expandable" onClick={handleToggle}>
									<h5>Additional Business Information</h5>
									<i className={`icon icon--sml icon--chevron--${isToggled ? 'top' : 'right'}--primary`}></i>
								</button>
								{isToggled && (
									<div className="card__body">
										{processor === "GoPlus" && this.renderGoogleMapsAutocomplete()}
										<div className="row">
											<div className="col col-sml-12 col-lrg-6 form__group">
												<div className="form__group__header">
													<p className="form__group__label">Corporate Name:</p>
												</div>
												<input
													disabled={this.props.disabled}
													type="text"
													className="input input--med"
													name="corporateName"
													id="corporateName"
													value={corporateName}
													onChange={this.handleChange(null, 'generalInfo')}
												></input>
											</div>
											<div className="col col-sml-12 col-lrg-6 form__group">
												<div className="form__group__header">
													<p className="form__group__label">Website:</p>
												</div>
												<input
													disabled={this.props.disabled}
													type="text"
													className="input input--med"
													name="website"
													id="businessInfo_website"
													value={businessInfo.website}
													onChange={this.handleChange(null)}
												></input>
											</div>
										</div>

										<AddressComponent
											address={businessInfo.businessAddress}
											namePrefix="businessInfo_businessAddress"
											onChange={this.handleChange('businessAddress')}
											isCanada={this.props.isCanada}
											disabled={this.props.disabled}
										/>

										<div className="row">
											<div className="col col-sml-12 col-lrg-6">{this.renderCountryOption()}</div>
											<div className="col col-sml-12 col-lrg-6">
												<div className="form__group">
													<div className="form__group__header">
														<p className="form__group__label">Fax:</p>
													</div>
													<input
														disabled={this.props.disabled}
														type="text"
														className="input input--med"
														name="businessFax"
														id="businessInfo_businessFax"
														value={businessInfo.businessFax}
														onChange={this.handleChange(null)}
													></input>
												</div>
											</div>
										</div>
									</div>
								)}
							</div>
						)}
					</Toggle>
				</ToggleContainer>
			</div>
		);
	}
}

BusinessInformation.propTypes = {
	hideCanadaOption: PropTypes.bool,
};

BusinessInformation.defaultProps = {
	hideCanadaOption: false,
};

export default BusinessInformation;
