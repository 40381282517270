import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { Data } from '../../common/components/react-data-grid-addons';
import { cloneDeep, each, find, unionBy, map, get, noop, concat, includes, split, lowerFirst } from 'lodash';
import { FeeReportColumns as Columns } from './column-filter/feeReportColumns';
import { FeeReportFilter as Filter, compileFilter } from './filter/feeReportFilter';
import withError from '../../common/components/error/error-hoc';
import { withLoader } from '../../common/components';
import { GridComponent, ToolbarComponent } from '../../common/components/grid';
import { ModalWrapper, modalNames } from './../../common/components/modal-wrapper';
import { ZebraRenderer } from '../../common/components/row';
import { Notification } from '../../common/components/notifications';
import { LoadMoreOptions } from '../../common/utilities';
import { GridTooltip } from '../../common/components/tooltips';
import {
	formatColumns,
	getPage,
	hasMoreData,
	mapCellArgs,
	mapDataToGridCommon,
	onLoadMoreLimitChange,
	openActions,
	queryFilterValues,
	parseQueriesToFilters,
} from '../../common/components/grid/commonGridMethods';
import { appService } from '../../services/appService';
import moment from 'moment';

const loadMoreOptionsAndAll = concat(LoadMoreOptions, [0]);

class FeeReportGrid extends Component {
	constructor(props) {
		super(props);
		this.state = cloneDeep(this.initialState);
		this.gridRef = createRef();
		this.notificationRef = createRef();
		this.setState = this.setState.bind(this);
		this.mapDataToGrid = mapDataToGridCommon.bind(this);
		this.queryFilterValues = queryFilterValues.bind(this);
		this.parseQueriesToFilters = (() => parseQueriesToFilters.bind(this)(false));
		this.onLoadMoreLimitChange = onLoadMoreLimitChange.bind(this);
	}

	get initialState() {
		const filters = unionBy(this.props.location.filters, Filter, 'key') || Filter;
		return {
			data: null,
			expanded: {},
			rowsPerPage: 20,
			activePage: 1,
			filters: filters,
			filteredRows: [],
			maxGridHeight: 0,
			columns: Columns,
			totalRowCount: 0,
			showFilters: true,
			fetchingData: true,
			fullFilteredRows: [],
			lastApiRefNum: null,
			fetchAdditionalData: false,
			defaultColumns: cloneDeep(Columns),
			activeFilters: cloneDeep(filters),
			modal: {
				data: null,
				name: modalNames.none,
			},
			inlineFilters: {
			},
		};
	}
	componentDidMount() {
		this.parseQueriesToFilters();
	}
	handlePageChange = page => {
		this.handleChange([{ key: 'activePage', value: page }]);
	};
	fetchData = async filters => {
		const filter = compileFilter(filters);
		this.setState({
			data: null,
			fullFilteredRows: [],
			filteredRows: [],
			fetchingData: true,
			expanded: {},
			totalRowCount: 0,
			lastApiRefNum: null,
		});

		let lastApiRefNum = null;
		const { rowsPerPage, activePage } = this.state;
		let data = { reportFiles: [] };
		try {
			data = await appService.GetFeeReports();
		} catch (error) {
			const addNotification = get(this.notificationRef, 'current.addNotification', noop);
			addNotification({
				message: 'Failed to fetch data',
				success: false,
			});
		}
		if (this.gridRef.current) {
			lastApiRefNum = data.refNum;
			let dataObj = { xReportData: data.reportFiles };
			if (get(dataObj, 'xReportData', false)) {
				dataObj.xReportData = this.createRows(dataObj.xReportData);
			}
			const formattedColumns = formatColumns(this.state.columns, cloneDeep(filter));
			this.mapData(dataObj);
			this.mapDataToGrid(dataObj, dataObj.xReportData, activePage, rowsPerPage, formattedColumns, lastApiRefNum);
		}
	};
	refetchData = () => this.fetchData(this.state.activeFilters);
	mapData = data => {
		let i = 0;
		if (get(data, 'xReportData', false) && data.xReportData.length > 0) {
			each(data.xReportData, item => {
				item.gridRowNumber = i;
				item.openActions = openActions((e, d = noop) => this.setState(e, d), this.gridRef);
				item.index = i;
				i++;
			});
		}
	};
	getCollectionItem = (collection, key) => {
		return find(collection, { key });
	};
	createRows = (data) => {
		let rows = [];
		map(data, (item) => {
			const year = item.year;
			const monthNumeric = item.month.toString().padStart(2, '0');
			const itemToAlter = find(rows, { year, monthNumeric });
			if(itemToAlter) {
				itemToAlter[lowerFirst(item.reportType)] = `${item.reportType}/${year}/${monthNumeric}/${item.fileName}`;
			}
			else {
				rows.push({
					year,
					monthNumeric,
					month: `${moment().month(monthNumeric - 1).format('MMMM')}`,
					[lowerFirst(item.reportType)]: `${item.reportType}/${year}/${monthNumeric}/${item.fileName}`,
					rowId: `${year}_${monthNumeric}`,
					showLoader: this.props.showLoader,
					notificationRef: this.notificationRef,
					refreshGridData: this.gridRef.current ? this.gridRef.current.refreshGridData : null,
				})
			}
		})
		return rows;
	}
	onRowClick = () => null;
	createDetailsLink = row => null;
	calculatePopupLeft = () => {
		const { infoDimensions: width } = this.state;
		return width - 36;
	};
	calculatePopupTop = () => {
		let offset = 0;
		if (get(this.gridRef, 'current.gridHolderRef.current', null)) {
			offset = this.gridRef.current.gridHolderRef.current.getBoundingClientRect().y - this.gridRef.current.gridHolderRef.current.scrollTop - 25;
		}
		return this.state.infoDimensions.height - offset;
	};
	renderTooltip = () =>
		this.state.tooltip ? (
			<div
				style={{
					position: 'absolute',
					zIndex: 99,
					backgroundColor: '#fff',
					left: this.calculatePopupLeft(),
					top: this.calculatePopupTop(),
				}}
			>
				{this.state.tooltip}
			</div>
		) : null;
	handleChange = items => {
		const newState = {};
		each(items, ({ key, value }) => {
			if (key === 'data' || key === 'inlineFilters' || key === 'activePage') {
				let filters, data, activePage;
				if (key === 'data') {
					activePage = 1;
					data = value;
					filters = this.state.inlineFilters;
				} else if (key === 'inlineFilters') {
					filters = value;
					activePage = 1;
					data = this.state.data;
				} else {
					activePage = value;
					data = this.state.data;
					filters = this.state.inlineFilters;
				}
				let filteredRows =
					data && data.xReportData
						? Data.Selectors.getRows({ filters, rows: data.xReportData }) : [];
				const pagedFilteredRows = getPage(filteredRows, activePage, this.state.rowsPerPage);
				newState.filteredRows = pagedFilteredRows;
				newState.activePage = activePage;
				newState.fullFilteredRows = filteredRows;
				newState.totalRowCount = filteredRows.length;
			}
			newState[key] = value;
		});
		return new Promise(resolve => {
			this.setState(newState, resolve);
		});
	};
	openCloseModal = (modalObj, ...rest) => {
		let state = {
			modal: modalObj,
		};
		this.setState(state);
	};
	render = () => {
		const {
			data,
			modal,
			columns,
			filters,
			expanded,
			activePage,
			filterProps,
			rowsPerPage,
			tooltipProps,
			fetchingData,
			filteredRows,
			totalRowCount,
			inlineFilters,
			activeFilters,
			lastApiRefNum,
			defaultColumns,
			fullFilteredRows,
			fetchAdditionalData,
		} = this.state;

		return (
			<div className="l--content--grid">
				<Notification ref={this.notificationRef} />
				<ModalWrapper modal={modal} onModalClose={this.openCloseModal} />
				<GridComponent
					data={data}
					type="feeReports"
					title="FeeReports"
					rowKey="rowId"
					fixHeader={true}
					hasPaging={true}
					filters={filters}
					columns={columns}
					enablePrint={true}
					showFilters={true}
					showResults={true}
					ref={this.gridRef}
					isExpandable={true}
					expanded={expanded}
					initialFetch={false}
					enableExport={true}
					filterColumns={true}
					useInlineFilters={true}
					enableFilters={false}
					canReorderColumns={true}
					mapCellArgs={mapCellArgs}
					syncQueryFilters={true}
					showPrintDropdown={false}
					filterProps={filterProps}
					tooltipProps={tooltipProps}
					showExportDropdown={false}
					filteredRows={filteredRows}
					fetchingData={fetchingData}
					onChange={this.handleChange}
					loadMoreLimit={rowsPerPage}
					onRowClick={this.onRowClick}
					fetchData={this.refetchData}
					lastApiRefNum={lastApiRefNum}
					inlineFilters={inlineFilters}
					defaultColumns={defaultColumns}
					activeFilters={activeFilters}
					loadMoreOptions={loadMoreOptionsAndAll}
					fullFilteredRows={fullFilteredRows}
					createDetailsLink={this.createDetailsLink}
					handlePageChange={this.handlePageChange}
					fetchingAdditionalData={fetchAdditionalData}
					queryFilterValues={this.queryFilterValues}
					onLoadMoreLimitChange={this.onLoadMoreLimitChange}
					emptyMessage="You should change your filter options"
					hasMoreData={hasMoreData(rowsPerPage, totalRowCount)}
					pagination={{ activePage, rowsPerPage, totalRowCount }}
					components={{
						toolbar: ToolbarComponent,
						rowRenderer: ZebraRenderer,
						tooltip: GridTooltip,
					}}
				/>
			</div>
		);
	};
}

FeeReportGrid.propTypes = {
	location: PropTypes.object.isRequired,
	showLoader: PropTypes.func,
};

export default withError(withLoader(FeeReportGrid));
