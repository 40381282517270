import React from 'react';
import { string, bool } from 'prop-types';

class GeneralInformation extends React.Component {
	render() {
		const { lead, wrapperClass, hideTitle } = this.props;

		return (
			<div className={wrapperClass}>
				<div className="form--popup">
					{!hideTitle && <p className="type--p2 type--p2--medium">General Information</p>}

					<div className="row">
						<div className="col col-sml-12 col-med-6 form__group">
							<div className="form__group__header">
								<label className="form__group__label" htmlFor="lblBusinessName">
									Business Name:
								</label>
							</div>
							<div className="input input--med input--disabled" name="lblBusinessName" id="lblBusinessName">
								{lead.businessName}
							</div>
						</div>
						<div className="col col-sml-12 col-med-6 form__group">
							<div className="form__group__header">
								<label className="form__group__label" htmlFor="lblProcessedBy">
									Processed by:
								</label>
							</div>
							<div className="input input--med input--disabled" name="lblProcessedBy" id="lblProcessedBy">
								{lead.processedBy}
							</div>
						</div>
						<div className="col col-sml-12 col-med-6 form__group">
							<div className="form__group__header">
								<label className="form__group__label" htmlFor="lblAgentName">
									Agent Name:
								</label>
							</div>
							<div className="input input--med input--disabled" name="lblAgentName" id="lblAgentName">
								{lead.agentName}
							</div>
						</div>
						<div className="col col-sml-12 col-med-6 form__group">
							<div className="form__group__header">
								<label className="form__group__label" htmlFor="lblStatementMonth">
									Statement Month:
								</label>
							</div>
							<div className="input input--med input--disabled" name="lblStatementMonth" id="lblStatementMonth">
								{lead.statementMonth}
							</div>
						</div>
						<div className="col col-sml-12 col-med-6 form__group">
							<div className="form__group__header">
								<label className="form__group__label" htmlFor="lblCurrentBank">
									Current Bank:
								</label>
							</div>
							<div className="input input--med input--disabled" name="lblCurrentBank" id="lblCurrentBank">
								{lead.currentBank}
							</div>
						</div>
						<div className="col col-sml-12 col-med-6 form__group">
							<div className="form__group__header">
								<label className="form__group__label" htmlFor="lblMarket">
									Market:
								</label>
							</div>
							<div className="input input--med input--disabled" name="lblMarket" id="lblMarket">
								{lead.market}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

GeneralInformation.defaultProps = {
	wrapperClass: 'card--primary card--sml card--primary--popup',
	hideTitle: false,
};

GeneralInformation.propTypes = {
	wrapperClass: string,
	hideTitle: bool,
};

export default GeneralInformation;
