import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const ColumnFilterFooter = ({ onReset, onApply, filterButtonDisabled }) => {
	return (
		<Fragment>
			<button type="button" onClick={onReset} className="btn btn--link btn--link--underline">
				Reset
			</button>
			<button type="button" onClick={onApply} disabled={filterButtonDisabled} className="btn btn--sml btn--primary">
				Done
			</button>
		</Fragment>
	);
};

ColumnFilterFooter.propTypes = {
	onApply: PropTypes.func.isRequired,
	onReset: PropTypes.func.isRequired,
	filterButtonDisabled: PropTypes.bool,
};

export default ColumnFilterFooter;
