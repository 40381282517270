import React from 'react';
import PropTypes from 'prop-types';
import { DropdownContainer } from '../dropdown-container';
import { map } from 'lodash';
import { isFd } from '../../utilities/processorType';
import { equipmentDB, equipmentDatabaseKeys as keys } from '../../../helpers/indexedDB';

class ProcessorDropdownComponent extends React.Component {
	get options() {
		const options = [
			// {
			//     value: '',
			//     text: 'Please select',
			//     default: true,
			//     children: <React.Fragment></React.Fragment>
			// }
		];
		map(this.props.processorList, o => {
			options.push({
				value: o.key,
				text: o.value,
				children: isFd({ processorId: o.key }) ? <React.Fragment></React.Fragment> : this.renderProcessorReason(),
			});
		});
		return options;
	}

	renderProcessorReason = () => {
		return (
			<React.Fragment>
				<div className="form__group spc--top--lrg">
					<div className="form__group__header">
						<p className="form__group__label">Reason for choosing processor</p>
						<span className="form__group__required">*</span>
					</div>
					<input
						type="text"
						className="input input--med"
						name="otherProcessorReason"
						value={this.props.setup.otherProcessorReason}
						onChange={this.handleChange}
					></input>
				</div>
			</React.Fragment>
		);
	};

	handleChange = e => {
		const { onChange } = this.props;
		equipmentDB.deleteEquipment(keys.equipmentList, this.props.merchId);
		onChange(e);
	};

	render() {
		const { id, className } = this.props;
		const selectedVal = this.props.value;
		return (
			<DropdownContainer
				selectClass="input input--med input--select"
				value={selectedVal}
				id={id}
				selectName={id}
				className={className}
				onChange={this.handleChange}
				options={this.options}
			></DropdownContainer>
		);
	}
}

ProcessorDropdownComponent.propTypes = {
	id: PropTypes.string,
	onChange: PropTypes.func,
	className: PropTypes.string,
	value: PropTypes.number,
};

export default ProcessorDropdownComponent;
