import React, { Component, Fragment } from 'react';
import { map, isEmpty } from 'lodash';
import { string } from 'prop-types';

import { Toggle, ToggleContainer } from './../../common/components/toggle';
import { appService } from '../../services';
import { withLoader } from '../../common/components';
import GeneralInformation from '../ReviewPricingSummary/general-information';
import PricingBreakdown from '../ReviewPricingSummary/pricing-breakdown';
import ProfitBreakdown from '../ReviewPricingSummary/profit-breakdown';
import AdditionalNotes from '../ReviewPricingSummary/additional-notes';
import AccountRecap from '../ReviewPricingSummary/account-recap';

const isExpand = true;

const tabs = [
	{
		key: 'General',
		label: 'General',
	},
	{
		key: 'CurrentPricingBreakdown',
		label: 'Current Pricing Breakdown',
	},
	{
		key: 'ProfitBreakdown',
		label: 'Profit Breakdown',
	},
	{
		key: 'AdditionalNotes',
		label: 'Additional Notes',
	},
	{
		key: 'AccountRecap',
		label: 'Account Recap',
	},
];

class ReviewPricingResults extends Component {
	constructor() {
		super();

		this.state = {
			selectedTab: 'General',
			summary: null,
		};
	}

	componentDidMount() {
		const leadId = this.props.leadId;
		this.props.showLoader(true);

		appService
			.getReviewPricingSummary(leadId)
			.then(lead => {
				this.props.showLoader(false);
				this.setState({ summary: lead });
			})
			.catch(err => {
				console.log('getReviewPricingSummary error', err);
				this.props.showLoader(false);
				this.setState({ errorMessage: 'An error occurred: ' + err });
			});
	}

	handleTabChange = selectedTab => {
		this.setState({ selectedTab });
	};

	renderSelectedTab = () => <div>{this[`render${this.state.selectedTab}`]()}</div>;

	renderAccountRecap = () => {
		const {
			summary: { accountInformation },
		} = this.state;

		return (
			!isEmpty(accountInformation) && (
				<AccountRecap hideTitle={true} wrapperClass="spc--top--sml" accountInfo={accountInformation} />
			)
		);
	};

	renderAdditionalNotes = () => {
		const { summary } = this.state;

		return !isEmpty(summary) && <AdditionalNotes hideTitle={true} wrapperClass="spc--top--sml" notes={summary} />;
	};

	renderProfitBreakdown = () => {
		const {
			summary: { profitBreakdown },
		} = this.state;

		return (
			!isEmpty(profitBreakdown) && (
				<ProfitBreakdown hideTitle={true} wrapperClass="spc--top--sml" profit={profitBreakdown} />
			)
		);
	};

	renderCurrentPricingBreakdown = () => {
		const {
			summary: { pricingBreakdown },
		} = this.state;

		return (
			!isEmpty(pricingBreakdown) && (
				<PricingBreakdown hideTitle={true} wrapperClass="spc--top--sml" pricing={pricingBreakdown} />
			)
		);
	};

	renderGeneral = () =>
		!isEmpty(this.state.summary) && (
			<GeneralInformation hideTitle={true} wrapperClass="spc--top--sml" lead={this.state.summary} />
		);

	render() {
		const { selectedTab, summary } = this.state;

		return (
			!isEmpty(summary) && (
				<ToggleContainer>
					<Toggle initialToggle={isExpand}>
						{({ isToggled, handleToggle }) => (
							<Fragment>
								<div className={`card${isToggled ? ' is-expanded' : ''} spc--bottom--lrg`}>
									<button className="card__header card__header--expandable" onClick={handleToggle}>
										<h5>Pricing Analysis Results</h5>
										<i className={`icon icon--sml icon--chevron--${isToggled ? 'top' : 'right'}--primary`}></i>
									</button>
									{isToggled && (
										<div className="card__body">
											<ul className="tabs spc--bottom--xlrg">
												{map(tabs, ({ key, label }) => {
													const isSelected = key === selectedTab;
													return (
														<li
															className={`tabs__link${isSelected ? ' is-active' : ''}`}
															key={key}
															onClick={() => this.handleTabChange(key)}
														>
															{label}
														</li>
													);
												})}
											</ul>
											{this.renderSelectedTab()}
										</div>
									)}
								</div>
							</Fragment>
						)}
					</Toggle>
				</ToggleContainer>
			)
		);
	}
}

ReviewPricingResults.propTypes = {
	leadId: string,
};

export default withLoader(ReviewPricingResults);
