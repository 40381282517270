import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toNumber } from 'lodash';

import { NumericFormat as NumberFormat } from 'react-number-format';

import { CurrencyMap, renderIf } from './../utilities';

class CurrencyComponent extends Component {
	constructor(props) {
		super(props);
		this.getCurrencyCode = this.getCurrencyCode.bind(this);
	}

	get currency() {
		return this.props.dependentValues.xCurrency;
	}

	get isVoided() {
		return this.props.dependentValues.xVoid == 1;
	}

	get isAuthOnly() {
		return this.props.dependentValues.xCommand && this.props.dependentValues.xCommand.toLowerCase() === 'cc:authonly';
	}

	getCurrencyCode() {
		let currencyCode = CurrencyMap.resolveCurrency(this.currency);
		if (!currencyCode) {
			currencyCode = '$';
		}
		return currencyCode;
	}

	render() {
		const isNumber = !isNaN(toNumber(this.props.value));
		return (
			<div>
				<div className="pull type--wgt--medium">
					{renderIf(!isNumber)(<span>{this.props.value}</span>)}
					{renderIf(isNumber && !this.isVoided)(
						<NumberFormat
							value={this.props.value}
							displayType="text"
							thousandSeparator={true}
							prefix={this.getCurrencyCode()}
							decimalScale={2}
							fixedDecimalScale={true}
						/>
					)}
					{renderIf(isNumber && !this.isVoided && this.isAuthOnly)(
						<span>
							<img src="/static/media/tags/auth.svg" className="spc--left--tny" />
						</span>
					)}
					{renderIf(isNumber && this.isVoided)(
						<React.Fragment>
							<NumberFormat
								value={this.props.dependentValues.xRequestAmount}
								displayType="text"
								thousandSeparator={true}
								className="type--linethrough type--wgt--light type--color--primary"
								prefix={this.getCurrencyCode()}
								decimalScale={2}
								fixedDecimalScale={true}
							/>
							<span>
								<img src="/static/media/tags/void.svg" className="spc--left--tny" />
							</span>
						</React.Fragment>
					)}
				</div>
			</div>
		);
	}
}

CurrencyComponent.propTypes = {
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
	dependentValues: PropTypes.object,
};

export default CurrencyComponent;
