import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

class ConfirmActionComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
		};
	}

	onConfirm = () => {
		const { isLoading } = this.state;
		if (isLoading) {
			return;
		}
		this.setState(
			{
				isLoading: true,
			},
			async () => {
				const { onConfirm, notificationHandler, notificationRef, closeModal } = this.props;
				let result;
				try {
					result = await onConfirm();
				} catch (e) {
					result = e;
				} finally {
					if (notificationHandler) {
						const notification = notificationHandler(result);
						notificationRef.addNotification(notification);
					}
					this.setState(
						{
							isLoading: false,
						},
						closeModal
					);
				}
			}
		);
	};

	render() {
		return (
			<React.Fragment>
				<div className="modal__body">
					<h6>{this.props.question}</h6>
					{get(this.props, 'bodyHTML')}
				</div>
				<div className="modal__footer">
					{this.props.buttonsHTML ? (
						this.props.buttonsHTML
					) : (
						<button onClick={this.onConfirm} className="btn btn--med btn--primary">
							Confirm
						</button>
					)}
				</div>
			</React.Fragment>
		);
	}
}

ConfirmActionComponent.defaultProps = {
	buttonsHTML: null,
};

ConfirmActionComponent.propTypes = {
	buttonsHTML: PropTypes.object,
	bodyHTML: PropTypes.object,
	notificationRef: PropTypes.object,
	notificationHandler: PropTypes.func,
	onConfirm: PropTypes.func.isRequired,
	closeModal: PropTypes.func.isRequired,
	question: PropTypes.string.isRequired,
};

export default ConfirmActionComponent;
