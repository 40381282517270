import React from 'react';

function isValidAddress(address) {
	return address?.streetAddress && address?.city && address?.state && address?.zip;
}

export default function AddressDisplayComponent({ address }) {
	return isValidAddress(address) ? (
		<span className="label--sub">
			{address.streetAddress}, {address.city}, {address.state}, {address.zip}
		</span>
	) : null;
}
