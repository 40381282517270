import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import { trim, toLower, isEmpty, map } from 'lodash';
import { Link } from 'react-router-dom';

import { principalService } from '../../services';
import { PasswordInput } from '../../common/components/password-input';
import { mapPasswordRequirements } from './loginUtils';
import PrivacyPolicy from '../privacy-policy/PrivacyPolicy';

class RegisterComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			username: '',
			password: '',
			password2: '',
			errorMessage: null,
			isFocusedPassword: false,
		};
	}

	componentWillMount() {
		if (principalService.get()) {
			this.redirect();
		}
	}

	setErrorMessage = errorMessage => {
		this.setState({ errorMessage });
	};

	handleChange = ({ target: { name, value } }) => {
		this.setState({
			[name]: value,
		});
	};

	handleFocusChange = isFocusedPassword => this.setState({ isFocusedPassword });

	handleSubmit = async event => {
		event.preventDefault();
		if (!this.formValidation()) {
			return;
		}

		const { history } = this.props;
		const { password } = this.state;
		let { username } = this.state;
		username = trim(toLower(username));

		Auth.signUp({
			username,
			password,
			attributes: {
				email: username,
			},
		})
			.then(data => {
				if (data.userConfirmed) {
					history.push({
						pathname: '/login',
						state: { username: username },
					});
				} else {
					history.push({
						pathname: '/confirm-registration',
						state: { username: username },
					});
				}
				return data;
			})
			.catch(err => {
				//eslint-disable-next-line
				console.log('signUp error', err);
				let message;
				switch (err && err.code) {
					case 'InvalidParameterException': {
						message = err.message;
						break;
					}
					case 'UsernameExistsException': {
						message = (
							<span>
								{err.message} Click{' '}
								<Link
									className="btn btn--link type--underline type--wgt--bold"
									to={{ pathname: '/login', state: { username: username } }}
								>
									here
								</Link>{' '}
								to sign in.
							</span>
						);
						break;
					}
					case 'InvalidPasswordException': {
						message =
							'Your password needs to be at least 8 characters long and contain at least one uppercase character, one lowercase character, one special character and one number.';
						break;
					}
					default: {
						message = 'Something went wrong. Please try again.';
						break;
					}
				}
				this.setState({
					errorMessage: message,
				});
			});
	};

	formValidation = () => {
		const { username, password, password2 } = this.state;

		if (username.length <= 0) {
			this.setState({
				errorMessage: 'Please enter your email',
			});
			return false;
		}

		if (password.length <= 0 || password2.length <= 0) {
			this.setState({
				errorMessage: 'Please enter your password',
			});
			return false;
		}

		if (password !== password2) {
			this.setState({
				errorMessage: 'Passwords do not match',
			});
			return false;
		}

		return true;
	};

	renderPasswordRequirements = () => {
		const requirements = mapPasswordRequirements(this.state.password);
		if (!isEmpty(requirements) && this.state.isFocusedPassword) {
			return (
				<div className="validation spc--bottom--sml">
					<p>Your password needs to contain at least:</p>
					<ul className="validation__list">
						{map(requirements, (item, index) => (
							<li key={index}>{item}</li>
						))}
					</ul>
				</div>
			);
		}
	};

	redirectToLogin = () => {
		const { history } = this.props;
		history.push('/login');
	};

	redirect() {
		const { history, location } = this.props;
		let redirectUrl = '/';

		if (location.state && location.state.returnUrl) {
			redirectUrl = location.state.returnUrl;
		}

		history.push(redirectUrl);
	}

	render() {
		const { username, password, password2, errorMessage } = this.state;

		return (
			<React.Fragment>
				<form className="auth__form" onSubmit={this.handleSubmit}>
					<h2 className="auth__form__title">Register with your account email</h2>
					<div className="auth__form__description flex--primary">
						<p className="spc--right--tny">Already have a Partner Portal account?</p>
						<button type="button" onClick={this.redirectToLogin} className="btn btn--sml btn--link">
							Sign in
						</button>
					</div>
					<div className="form__group">
						<div className="form__group__header">
							<p className="form__group__label">Email</p>
						</div>
						<input
							name="username"
							type="text"
							className="input input--med"
							placeholder="user@gmail.com"
							value={username}
							onChange={this.handleChange}
							tabIndex="1"
						/>
					</div>
					<div className="form__group">
						<div className="form__group__header">
							<p className="form__group__label">Create new Password</p>
						</div>
						{this.renderPasswordRequirements()}
						<PasswordInput
							value={password}
							onChange={this.handleChange}
							tabIndex="2"
							setErrorMessage={this.setErrorMessage}
							onFocus={this.handleFocusChange}
						/>
					</div>
					<div className="form__group">
						<div className="form__group__header">
							<p className="form__group__label">Confirm Password</p>
						</div>
						<PasswordInput
							placeholder="Confirm your password"
							name="password2"
							value={password2}
							onChange={this.handleChange}
							tabIndex="3"
							setErrorMessage={this.setErrorMessage}
						/>
						{errorMessage ? (
							<div className="spc--top--sml spc--bottom--med type--validation">{errorMessage}</div>
						) : null}
					</div>
					<button type="submit" className="btn btn--primary btn--lrg">
						Register
					</button>
				</form>
				<PrivacyPolicy showCopyright />
			</React.Fragment>
		);
	}
}

RegisterComponent.propTypes = {
	history: PropTypes.object,
	location: PropTypes.object,
};

export default RegisterComponent;
