import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { map, find, sumBy, toNumber, get } from 'lodash';

import { formatCurrency } from '../../helpers/currency-formatter';
import { NumericFormat as NumberFormat } from 'react-number-format';
import QuantityComponent from './components/QuantityComponent';

class HardwarePurchasePlanComponent extends Component {
	get isBillAgentPaymentSchedule() {
		return this.props.paymentSchedule === 'BillAgent';
	}

	isBillAgentAndOneTimeFee = fee => get(fee, 'feeType') === 'OneTimeFee' && this.isBillAgentPaymentSchedule;

	calculateAccessoriesTotalPrice() {
		return (
			sumBy(this.props.accessories, ({ isSelected, quantity, fees }) => {
				if (isSelected && fees) {
					const fee = find(fees, { feeType: 'OneTimeFee' });
					return (this.isBillAgentPaymentSchedule ? fee.agentCost : fee.merchantPrice || fee.retailPrice) * quantity;
				}
				return 0;
			}) || 0
		);
	}

	calculateTotalPrice(merchantFee, merchantPrice) {
		let agentCost =
			merchantFee && Object.keys(merchantFee).includes('agentCost')
				? toNumber(merchantFee.agentCost)
				: toNumber(merchantFee.retailPrice);
		let total = this.isBillAgentAndOneTimeFee(merchantFee) ? agentCost : merchantPrice || 0;
		return total;
	}

	render() {
		const { purchaseType, plan, quantity, openAccessoriesPopup, displayAddonsButton } = this.props;
		const accessoriesTotalPrice = this.calculateAccessoriesTotalPrice();
		let totalMerchantPrice = 0;
		const tooltip = this.isBillAgentPaymentSchedule
			? 'Merchant Cost is not editable when Payment Schedule is Bill Agent.'
			: null;

		return (
			<Fragment>
				<div className="equipment__details__aside__body">
					{plan.fees
						.filter((fee, i) => fee.purchaseTypes.includes(purchaseType))
						.map((fee, idx) => {
							if (Object.keys(fee.dependencySettings || {}).length > 0) {
								let renderDependentFee = false;
								// check if have option this depends on
								if (this.props.options) {
									let dependentOption = Object.keys(this.props.options).find(o =>
										Object.keys(fee.dependencySettings).includes(o)
									);
									if (
										dependentOption &&
										fee.dependencySettings[dependentOption].includes(this.props.options[dependentOption])
									) {
										renderDependentFee = true;
									}
								}
								if (!renderDependentFee) return;
							}

							let merchantFee = this.props.merchantFees.find(f => f.feeId == fee.feeId);
							let merchantPrice =
								merchantFee && Object.keys(merchantFee).includes('merchantPrice')
									? toNumber(merchantFee.merchantPrice)
									: toNumber(fee.retailPrice);
							const minFee = get(fee, 'minFee');
							const maxFee = get(fee, 'maxFee');
							const isMerchantPriceDisabled = minFee > 0 && maxFee > 0 && minFee === maxFee;

							totalMerchantPrice += this.calculateTotalPrice(merchantFee, merchantPrice);

							return (
								<Fragment key={idx}>
									<div className="equipment__details__aside__row">
										<p className="type--p3 type--p3--medium type--color--text--light">{fee.feeName}</p>
									</div>
									<div className="equipment__details__aside__row">
										<p className="type--p3 type--p3--medium type--color--text--light">Agent Cost</p>
										<p className="type--p2 type--p2--medium">{formatCurrency(fee.agentCost)}</p>
									</div>
									<div className="equipment__details__aside__row">
										<p className="type--p3 type--p3--medium type--color--text--light">Merchant Cost</p>
										<div className="flex--primary">
											<div
												className="datatooltip--w--150 datatooltip--left"
												data-tooltip={this.isBillAgentAndOneTimeFee(fee) ? tooltip : null}
											>
												<NumberFormat
													id={`fee_${fee.feeId}`}
													className="input input--sml w--130"
													style={
														this.isBillAgentAndOneTimeFee(fee)
															? { cursor: 'not-allowed', backgroundColor: '#f3f6f9' }
															: {}
													}
													value={this.isBillAgentAndOneTimeFee(fee) ? 0 : merchantPrice}
													disabled={this.isBillAgentAndOneTimeFee(fee) || isMerchantPriceDisabled}
													name={'fee_' + idx}
													placeholder="Merchant Cost"
													thousandSeparator={true}
													decimalScale={2}
													fixedDecimalScale={false}
													onValueChange={this.props.onFeeChange(plan.planId, fee.feeId)}
												/>
											</div>
											{isMerchantPriceDisabled && !this.isBillAgentAndOneTimeFee(fee) && (
												<i
													className="icon icon--sml icon--info datatooltip--100 spc--left--tny flex--shrink--0"
													data-tooltip={`Merchant cost must equal ${minFee}`}
												/>
											)}
										</div>
									</div>
									{totalMerchantPrice < fee.agentCost && (
										<div className="notes notes--default">
											Note: Merchant Cost is below Agent Cost
											<br />
											Agent will be billed for Remainder Amount: {formatCurrency(fee.agentCost - totalMerchantPrice)}
										</div>
									)}
								</Fragment>
							);
						})}
					{this.renderAccessioriesInfo()}
					<QuantityComponent {...this.props.quantityComponentProps} />
					<div className="equipment__details__aside__row total">
						<div className="flex--primary flex--gap--tny">
							<i className="icon icon--lrg icon--transactions"></i>
							<p className="type--p2 type--p2--medium">Total due</p>
						</div>
						<p className="type--h5 type--wgt--medium">
							{formatCurrency(quantity * totalMerchantPrice + accessoriesTotalPrice)}
						</p>
					</div>
				</div>
				{displayAddonsButton && (
					<button className="btn btn--med btn--tertiary fullwidth" onClick={openAccessoriesPopup}>
						<i className="icon icon--sml icon--add" />
						<span>Accessories</span>
					</button>
				)}
			</Fragment>
		);
	}

	renderAccessioriesInfo() {
		const { accessories } = this.props;

		return (
			<Fragment>
				{map(accessories, ({ equipmentId, name, quantity, isSelected, fees }) => {
					const fee = find(fees, { feeType: 'OneTimeFee' });

					return (
						isSelected &&
						quantity > 0 && (
							<div className="equipment__details__aside__row" key={`${name}.${equipmentId}`}>
								<div className="flex--primary flex--gap--tny">
									<p className="type--p3 type--p3--medium type--color--text--light">{name}</p>
									<span>({quantity})</span>
									<button className="btn btn--link" onClick={() => this.props.toggleAccessory(equipmentId)}>
										<i className="icon icon--tny icon--delete"></i>
									</button>
								</div>
								<div className="checkout__list__addon__value">
									{formatCurrency(
										this.isBillAgentPaymentSchedule ? fee.agentCost : fee.merchantPrice || fee.retailPrice
									)}
								</div>
							</div>
						)
					);
				})}
			</Fragment>
		);
	}
}

HardwarePurchasePlanComponent.propTypes = {
	onFeeChange: PropTypes.func.isRequired,
	merchantFees: PropTypes.array,
	plan: PropTypes.object.isRequired,
	purchaseType: PropTypes.string.isRequired,
	accessories: PropTypes.array,
	toggleAccessory: PropTypes.func.isRequired,
	displayAddonsButton: PropTypes.bool,
	openAccessoriesPopup: PropTypes.func,
	outOfStock: PropTypes.bool,
	quantityComponentProps: PropTypes.object,
};

export default HardwarePurchasePlanComponent;
