import React from 'react';
import { string, bool } from 'prop-types';

class ProfitBreakdown extends React.Component {
	render() {
		const { profit, wrapperClass, hideTitle } = this.props;

		return (
			<div className={wrapperClass}>
				<div className="form--popup">
					{!hideTitle && <p className="type--p2 type--p2--medium spc--bottom--lrg">Profit Breakdown </p>}

					<div>
						<div className="row">
							<div className="col col-sml-12 col-med-6 form__group">
								<div className="form__group__header">
									<label className="form__group__label" htmlFor="lblBatchFee">
										Batch Fee
									</label>
								</div>
								<div className="input input--med input--disabled" name="lblBatchFee" id="lblBatchFee">
									{profit.batchFee}
								</div>
							</div>
							<div className="col col-sml-12 col-med-6 form__group">
								<div className="form__group__header">
									<label className="form__group__label" htmlFor="lblMiscProfit">
										MISC Profit
									</label>
								</div>
								<div className="input input--med input--disabled" name="lblMiscProfit" id="lblMiscProfit">
									{profit.miscProfit}
								</div>
							</div>
						</div>
						<div className="row spc--bottom--lrg">
							<div className="col col-sml-12 col-med-6">
								<h6 className="spc--bottom--lrg">Base Rate Profit</h6>
								<div className="row">
									<div className="col col-sml-12 col-med-6 form__group">
										<div className="form__group__header">
											<label className="form__group__label" htmlFor="lblBankcardBaseRateProfit">
												V/MC
											</label>
										</div>
										<div
											className="input input--med input--disabled"
											name="lblBankcardBaseRateProfit"
											id="lblBankcardBaseRateProfit"
										>
											{profit.bankcardProfitBreakdown.baseRateProfit}
										</div>
									</div>
									<div className="col col-sml-12 col-med-6 form__group">
										<div className="form__group__header">
											<label className="form__group__label" htmlFor="lblAmexBaseRateProfit">
												AMEX
											</label>
										</div>
										<div
											className="input input--med input--disabled"
											name="lblAmexBaseRateProfit"
											id="lblAmexBaseRateProfit"
										>
											{profit.amexProfitBreakdown.baseRateProfit}
										</div>
									</div>
								</div>
							</div>

							<div className="col col-sml-12 col-med-6">
								<h6 className="spc--bottom--lrg">Downgrade Profit</h6>
								<div className="row">
									<div className="col col-sml-12 col-med-6 form__group">
										<div className="form__group__header">
											<label className="form__group__label" htmlFor="lblBankcardDowngradeProfit">
												V/MC
											</label>
										</div>
										<div
											className="input input--med input--disabled"
											name="lblBankcardDowngradeProfit"
											id="lblBankcardDowngradeProfit"
										>
											{profit.bankcardProfitBreakdown.downgradeProfit}
										</div>
									</div>
									<div className="col col-sml-12 col-med-6 form__group">
										<div className="form__group__header">
											<label className="form__group__label" htmlFor="lblAmexDowngradeProfit">
												AMEX
											</label>
										</div>
										<div
											className="input input--med input--disabled"
											name="lblAmexDowngradeProfit"
											id="lblAmexDowngradeProfit"
										>
											{profit.amexProfitBreakdown.downgradeProfit}
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="row spc--bottom--lrg">
							<div className="col col-sml-12 col-med-6">
								<h6 className="spc--bottom--lrg">Transaction Fee Profit</h6>
								<div className="row">
									<div className="col col-sml-12 col-med-6 form__group">
										<div className="form__group__header">
											<label className="form__group__label" htmlFor="lblBankcardTransactionFeeProfit">
												V/MC
											</label>
										</div>
										<div
											className="input input--med input--disabled"
											name="lblBankcardTransactionFeeProfit"
											id="lblBankcardTransactionFeeProfit"
										>
											{profit.bankcardProfitBreakdown.transactionFeeProfit}
										</div>
									</div>
									<div className="col col-sml-12 col-med-6 form__group">
										<div className="form__group__header">
											<label className="form__group__label" htmlFor="lblAmexTransactionFeeProfit">
												AMEX
											</label>
										</div>
										<div
											className="input input--med input--disabled"
											name="lblAmexTransactionFeeProfit"
											id="lblAmexTransactionFeeProfit"
										>
											{profit.amexProfitBreakdown.transactionFeeProfit}
										</div>
									</div>
								</div>
							</div>
							<div className="col col-sml-12 col-med-6">
								<h6 className="spc--bottom--lrg">Statement Fee</h6>
								<div className="row">
									<div className="col col-sml-12 col-med-6 form__group">
										<div className="form__group__header">
											<label className="form__group__label" htmlFor="lblBankcardStatementFee">
												V/MC
											</label>
										</div>
										<div
											className="input input--med input--disabled"
											name="lblBankcardStatementFee"
											id="lblBankcardStatementFee"
										>
											{profit.bankcardProfitBreakdown.statementFee}
										</div>
									</div>
									<div className="col col-sml-12 col-med-6 form__group">
										<div className="form__group__header">
											<label className="form__group__label" htmlFor="lblAmexStatementFee">
												AMEX
											</label>
										</div>
										<div
											className="input input--med input--disabled"
											name="lblAmexStatementFee"
											id="lblAmexStatementFee"
										>
											{profit.amexProfitBreakdown.statementFee}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="row spc--bottom--lrg">
							<div className="col col-sml-12 col-med-6">
								<h6 className="spc--bottom--lrg">Total Current Profit</h6>
								<div className="row">
									<div className="col col-sml-12 col-med-6 form__group">
										<div className="form__group__header">
											<label className="form__group__label" htmlFor="lblBankcardTotalCurrentProfit">
												V/MC
											</label>
										</div>
										<div
											className="input input--med input--disabled"
											name="lblBankcardTotalCurrentProfit"
											id="lblBankcardTotalCurrentProfit"
										>
											{profit.bankcardProfitBreakdown.totalCurrentProfit}
										</div>
									</div>
									<div className="col col-sml-12 col-med-6 form__group">
										<div className="form__group__header">
											<label className="form__group__label" htmlFor="lblAmexTotalCurrentProfit">
												AMEX
											</label>
										</div>
										<div
											className="input input--med input--disabled"
											name="lblAmexTotalCurrentProfit"
											id="lblAmexTotalCurrentProfit"
										>
											{profit.amexProfitBreakdown.totalCurrentProfit}
										</div>
									</div>
								</div>
							</div>

							<div className="col col-sml-12 col-med-6">
								<h6 className="spc--bottom--lrg">Additional Savings</h6>
								<div className="row">
									<div className="col col-sml-12 col-med-6 form__group">
										<div className="form__group__header">
											<label className="form__group__label" htmlFor="lblBankcardAdditionalSavings">
												V/MC
											</label>
										</div>
										<div
											className="input input--med input--disabled"
											name="lblBankcardAdditionalSavings"
											id="lblBankcardAdditionalSavings"
										>
											{profit.bankcardProfitBreakdown.additionalSavings}
										</div>
									</div>
									<div className="col col-sml-12 col-med-6 form__group">
										<div className="form__group__header">
											<label className="form__group__label" htmlFor="lblAmexAdditionalSavings">
												AMEX
											</label>
										</div>
										<div
											className="input input--med input--disabled"
											name="lblAmexAdditionalSavings"
											id="lblAmexAdditionalSavings"
										>
											{profit.amexProfitBreakdown.additionalSavings}
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col col-sml-12 col-med-6">
								<h6 className="spc--bottom--lrg">Effective Rate</h6>
								<div className="row">
									<div className="col col-sml-12 col-med-6 form__group">
										<div className="form__group__header">
											<label className="form__group__label" htmlFor="lblBankcardEffectiveRate">
												V/MC
											</label>
										</div>
										<div
											className="input input--med input--disabled"
											name="lblBankcardEffectiveRate"
											id="lblBankcardEffectiveRate"
										>
											{profit.bankcardProfitBreakdown.effectiveRate}
										</div>
									</div>
									<div className="col col-sml-12 col-med-6 form__group">
										<div className="form__group__header">
											<label className="form__group__label" htmlFor="lblAmexEffectiveRate">
												AMEX
											</label>
										</div>
										<div
											className="input input--med input--disabled"
											name="lblAmexEffectiveRate"
											id="lblAmexEffectiveRate"
										>
											{profit.amexProfitBreakdown.effectiveRate}
										</div>
									</div>
								</div>
							</div>

							<div className="col col-sml-12 col-med-6">
								<h6 className="spc--bottom--lrg">Effective Profit Margin</h6>
								<div className="row ">
									<div className="col col-sml-12 col-med-6 form__group">
										<div className="form__group__header">
											<label className="form__group__label" htmlFor="lblBankcardEffectiveProfitMargin">
												V/MC
											</label>
										</div>
										<div
											className="input input--med input--disabled"
											name="lblBankcardEffectiveProfitMargin"
											id="lblBankcardEffectiveProfitMargin"
										>
											{profit.bankcardProfitBreakdown.effectiveProfitMargin}
										</div>
									</div>
									<div className="col col-sml-12 col-med-6 form__group">
										<div className="form__group__header">
											<label className="form__group__label" htmlFor="lblAmexEffectiveProfitMargin">
												AMEX
											</label>
										</div>
										<div
											className="input input--med input--disabled"
											name="lblAmexEffectiveProfitMargin"
											id="lblAmexEffectiveProfitMargin"
										>
											{profit.amexProfitBreakdown.effectiveProfitMargin}
										</div>
									</div>
									<div className="col col-sml-12 col-med-6 form__group">
										<div className="form__group__header">
											<label className="form__group__label" htmlFor="lblCombinedEffectiveRate">
												Combined Effective Rate
											</label>
										</div>
										<div
											className="input input--med input--disabled"
											name="lblCombinedEffectiveRate"
											id="lblCombinedEffectiveRate"
										>
											{profit.combinedEffectiveRate}
										</div>
									</div>
									<div className="col col-sml-12 col-med-6 form__group">
										<div className="form__group__header">
											<label className="form__group__label" htmlFor="lblCombinedEffectiveProfitMargin">
												Combined Effective Profit Margin
											</label>
										</div>
										<div
											className="input input--med input--disabled"
											name="lblCombinedEffectiveProfitMargin"
											id="lblCombinedEffectiveProfitMargin"
										>
											{profit.combinedEffectiveProfitMargin}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

ProfitBreakdown.defaultProps = {
	wrapperClass: 'card--primary card--sml card--primary--popup',
	hideTitle: false,
};

ProfitBreakdown.propTypes = {
	wrapperClass: string,
	hideTitle: bool,
};

export default ProfitBreakdown;
