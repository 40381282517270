import React, { Component, Fragment } from 'react';
import { isEmpty, toLower, map, noop, get } from 'lodash';
import PropTypes from 'prop-types';
import { Toggle } from '../../common/components/toggle';
import { YesNoRadio } from '../../common/components/yes-no-radio';
import AmexRates from './amex-rates';
import { DropdownContainer } from '../../common/components/dropdown-container';
import { CheckboxContainer } from '../../common/components/checkbox-container';
import { CheckboxTxtInput } from '../../common/components/checkbox-text-input';
import { isFd, isAch } from '../../common/utilities';

export default class USSetupConfirm extends Component {
	render() {
		const { setup, mpa, displayRates } = this.props;

		return (
			<Toggle initialToggle={true}>
				{({ isToggled, handleToggle }) =>
					!isEmpty(setup) && (
						<div className={`card${isToggled ? ' is-expanded' : ''} spc--bottom--xlrg`}>
							<button className="card__header card__header--expandable" onClick={handleToggle}>
								<h5>Setup Form</h5>
								<i className={`icon icon--lrg icon--chevron--${isToggled ? 'top' : 'right'}--primary`}></i>
							</button>
							{isToggled && (
								<div className="card__body">
									<div className="spc--bottom--xxxlrg">
										<div className="form__group__header">
											<p className="form__group__label">Rep 2</p>
										</div>
										<div className="input input--med input--disabled">{setup.telemarketerEmail}</div>
									</div>

									<p className="type--p2 type--p2--medium spc--bottom--lrg">Check whatever is applicable:</p>
									<div className="spc--bottom--xxxlrg">
										<div className="spc--bottom--med">
											<p className="form__group__label">
												The signer or business has, or has previously had, an account with Sola, Fidelity or Cardknox
											</p>
										</div>
										<YesNoRadio
											radioClass="input--radio"
											name="hasExistingFidelityAccount"
											yes={setup.hasExistingFidelityAccount}
											onChange={noop}
										/>
									</div>

									{setup.hasExistingFidelityAccount && (
										<div className="row">
											{this.renderFakeInput({
												name: 'DBA/MID',
												value: setup.existingAccountDba,
											})}
										</div>
									)}

									{get(setup, 'additionalSetupDetails') && this.renderAcceptingCreditCards()}

									<div className="spc--bottom--xxxlrg">
										<div className="form__group__header">
											<p className="form__group__label">Goods/Services Sold</p>
										</div>
										<div className="input input--med input--disabled">{setup.goodsOrServicesDescription}</div>
									</div>

									{this.renderProcessingMethod()}

									{this.props.showSubmit && this.renderAmexRates(setup)}
									{get(setup, 'additionalSetupDetails.isCurrentlyAcceptingCreditCards', false) &&
										toLower(setup.proposedSavings.type) !== 'unknown' && (
											<div className="card is-expandable spc--bottom--lrg">
												<div className="card__header">
													<h5>Proposed Savings (only if applicable)</h5>
													{this.props.renderChangeLink(this.props.redirectToSetup)}
												</div>
												<div className="row">
													<div className="col col-sml-12 col-med-6">
														<DropdownContainer
															hideSelect={true}
															labelText="Proposed Savings Type"
															divClass="row"
															labelClass="form__group__label"
															selectClass="input input--med input--select"
															wrapperClass="col col-sml-12 form__group"
															selectName="proposedSavings_type"
															value={setup.proposedSavings.type}
															onChange={noop}
															options={[
																{
																	value: 'MonthlyDollarSavings',
																	text: 'Monthly Dollar Savings',
																	children: map(
																		[
																			{
																				name: 'Monthly Dollar Savings',
																				value: setup.proposedSavings.monthlyDollarSavingsAmounts,
																			},
																		],
																		item => this.renderFakeInput(item)
																	),
																},
																{
																	value: 'EffectiveRateReduction',
																	text: 'Effective Rate Reduction',
																	children: (
																		<DropdownContainer
																			hideSelect={true}
																			labelText="Effective Rate Reduction Type"
																			divClass="row"
																			labelClass="form__group__label"
																			selectClass="input input--med input--select"
																			wrapperClass="col col-sml-12 form__group"
																			selectName="proposedSavings_rateReductionType"
																			value={setup.proposedSavings.rateReductionType || ''}
																			onChange={noop}
																			options={[
																				{
																					value: 'Combined',
																					text: 'Combined',
																					default: true,
																					children: map(
																						[
																							{
																								name: 'Combined',
																								value: setup.proposedSavings.combinedSavingsPercentage,
																							},
																						],
																						item => this.renderFakeInput(item)
																					),
																				},
																				{
																					value: 'Individual',
																					text: 'Individual',
																					children: map(
																						[
																							{
																								name: 'Bankcard',
																								value: setup.proposedSavings.bankcardSavingsPercentage,
																							},
																							{
																								name: 'Amex',
																								value: setup.proposedSavings.amexSavingsPercentage,
																							},
																						],
																						item => this.renderFakeInput(item)
																					),
																				},
																			]}
																		></DropdownContainer>
																	),
																},
															]}
														></DropdownContainer>
													</div>
												</div>
											</div>
										)}

									{displayRates && this.renderDisplayRates()}
									{this.renderOnlineReportingSection(mpa, setup)}
									{!isAch(mpa) && (
										<div>
											<div className="flex--primary flex--gap--sml spc--bottom--lrg">
												<p className="type--p2 type--p2--medium">Internal Notes</p>
												{this.props.renderChangeLink(this.props.redirectToSetup)}
											</div>
											<div className="form__group">
												<div className="form__group__header">
													<p className="form__group__label">Note</p>
												</div>
												<div className="input input--textarea input--fake">{setup.internalNotes}</div>
											</div>
										</div>
									)}
								</div>
							)}
						</div>
					)
				}
			</Toggle>
		);
	}

	renderAmexRates(setup) {
		return <AmexRates amexDetails={setup.amexDetails} renderRateStructure={this.renderRateStructure} />;
	}

	renderOnlineReportingSection(mpa, setup) {
		return (
			<React.Fragment>
				<div className="spc--bottom--xxxlrg">
					{isFd(setup) && (
						<p className="type--p2 type--p2--medium spc--bottom--lrg">
							Online reporting (Check whichever is applicable)
						</p>
					)}
					{isFd(setup) && (
						<div className="form__group">
							<input
								className="input--check"
								type="checkbox"
								name="onlineReportingOptions_isEnabled"
								id="onlineReportingOptions_isEnabled"
								checked={setup.onlineReportingOptions.isEnabled}
								readOnly
							/>
							<label htmlFor="onlineReportingOptions_isEnabled">Enable Access One</label>
						</div>
					)}
					{get(setup, 'onlineReportingOptions.isEnabled', false) && (
						<div className="flex--primary flex--gap--xlrg form__group">
							<div>
								<input
									disabled={true}
									type="radio"
									className="input--radio"
									id="radMarkupFeeFree"
									name="onlineReportingOptions_markupFee"
									value="0"
									checked={setup.onlineReportingOptions.markupFee === '0'}
									onChange={noop}
								/>
								<label htmlFor="radMarkupFeeFree">Free ($0)</label>
							</div>
							<div>
								<input
									disabled={true}
									type="radio"
									className="input--radio"
									id="radMarkupFee5"
									name="onlineReportingOptions_markupFee"
									value="5"
									checked={setup.onlineReportingOptions.markupFee === '5'}
									onChange={noop}
								/>
								<label htmlFor="radMarkupFee5">$5</label>
							</div>
							<div>
								<input
									disabled={true}
									type="radio"
									className="input--radio"
									id="radMarkupFee10"
									name="onlineReportingOptions_markupFee"
									value="10"
									checked={setup.onlineReportingOptions.markupFee === '10'}
									onChange={noop}
								/>
								<label htmlFor="radMarkupFee10">$10</label>
							</div>
						</div>
					)}
				</div>
				{this.renderEidsEnrollment(mpa, setup)}
				{this.renderTaxExemption()}
			</React.Fragment>
		);
	}

	renderEidsEnrollment(mpa, setup) {
		if (!setup.eidsEnrollmentDetails || !isFd(mpa)) return null;
		return (
			<div>
				<div className="flex--primary flex--gap--sml spc--bottom--lrg">
					<p className="type--p2 type--p2--medium">EIDS Enrollment</p>
					{this.props.renderChangeLink(this.props.redirectToSetup)}
				</div>
				<div>
					<div className="spc--bottom--lrg">
						<input
							className="input--check"
							id="eidsEnrollmentDetails_shouldEnrollEids"
							name="eidsEnrollmentDetails_shouldEnrollEids"
							type="checkbox"
							checked={setup.eidsEnrollmentDetails.shouldEnrollEids}
							readOnly
						/>
						<label htmlFor="eidsEnrollmentDetails_shouldEnrollEids">
							Enroll with EIDS (Electronic Integrated Disputes SystemSM)
						</label>
					</div>
					{setup.eidsEnrollmentDetails.shouldEnrollEids && (
						<div className="row">
							<div className="col col-sml-12 col-lrg-6">
								<div className="form__group__header">
									<label className="form__group__label" htmlFor="eidsEnrollmentDetails_eidsMerchantPrice">
										Enter price to bill merchant (cost is $3.95):
									</label>
								</div>
								<input
									className="input input--med input--disabled spc--bottom--sml--alt"
									id="eidsEnrollmentDetails_eidsMerchantPrice"
									name="eidsEnrollmentDetails_eidsMerchantPrice"
									type="decimal"
									value={setup.eidsEnrollmentDetails.eidsMerchantPrice}
								/>
								<div className="notes notes--primary">
									<i className="icon"></i>
									<p className="type--p4">
										Please note, if you would like to bill more than $5.95, you'll need to obtain a signed form from the
										merchant authorizing the charge. Banking Services will follow up with you accordingly for a
										signature.
									</p>
								</div>
							</div>

							<div className="col col-sml-12 col-lrg-6">
								<div className="form__group__header">
									<label className="form__group__label" htmlFor="eidsEnrollmentDetails_eidsMerchantEmail">
										Enter the email the login credentials will be sent to:
									</label>
								</div>
								<input
									className="input input--med input--disabled spc--bottom--sml--alt"
									id="eidsEnrollmentDetails_eidsMerchantEmail"
									name="eidsEnrollmentDetails_eidsMerchantEmail"
									type="text"
									value={setup.eidsEnrollmentDetails.eidsEmail}
								/>
								<div className="notes notes--primary">
									<i className="icon"></i>
									<p className="type--p4">
										It usually takes up to 2-3 days to get an approval and before the dispute reporting kicks in.
									</p>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		);
	}

	renderAcceptingCreditCards() {
		const {
			setup: { additionalSetupDetails },
		} = this.props;
		return (
			<Fragment>
				<div className="form__group">
					<div className="spc--bottom--med">
						<p className="form__group__label">Merchant currently accepts credit cards</p>
					</div>
					<YesNoRadio
						radioClass="input--radio"
						name="additionalSetupDetails_isCurrentlyAcceptingCreditCards"
						yes={additionalSetupDetails.isCurrentlyAcceptingCreditCards}
						onChange={noop}
					/>
				</div>
				<div className="spc--bottom--xxxlrg">
					{additionalSetupDetails.isCurrentlyAcceptingCreditCards ? (
						<div className="form__group">
							<YesNoRadio
								radioClass="input--radio"
								name="additionalSetupDetails_hadStatementsReviewed"
								yes={additionalSetupDetails.hadStatementsReviewed}
								onChange={noop}
								yesLabel="Statements reviewed"
								noLabel="No statements reviewed"
							/>
						</div>
					) : (
						<div className="form__group">
							<YesNoRadio
								radioClass="input--radio"
								name="additionalSetupDetails_requiresPricingAnalysis"
								yes={additionalSetupDetails.requiresPricingAnalysis}
								onChange={noop}
								yesLabel="Standard industry rates"
								noLabel="Use rates provided by agent"
							/>
						</div>
					)}
					{additionalSetupDetails.isCurrentlyAcceptingCreditCards && !additionalSetupDetails.hadStatementsReviewed && (
						<div className="form__group">
							<YesNoRadio
								radioClass="input--radio"
								name="additionalSetupDetails_requiresPricingAnalysis"
								yes={additionalSetupDetails.requiresPricingAnalysis}
								onChange={noop}
								yesLabel="Standard industry rates"
								noLabel="Use rates provided by agent"
							/>
						</div>
					)}
					{additionalSetupDetails.isCurrentlyAcceptingCreditCards && additionalSetupDetails.hadStatementsReviewed && (
						<div className="form__group">
							<div className="form__group__header">
								<p className="form__group__label">DBA As Reviewed</p>
							</div>
							<div className="input input--med input--disabled">{additionalSetupDetails.statementsReviewedDba}</div>
						</div>
					)}
				</div>
			</Fragment>
		);
	}

	renderDisplayRates() {
		const { setup, mpa } = this.props;
		return (
			<Fragment>
				<div className="flex--primary flex--gap--sml spc--bottom--lrg">
					<p className="type--p2 type--p2--medium">Pricing Structure</p>
					{this.props.renderChangeLink(this.props.redirectToSetup)}
				</div>
				<div className="row">
					<div className="col col-sml-12 col-lrg-6 form__group">
						<div className="form__group__header">
							<p className="form__group__label">Bank</p>
						</div>
						<div className="input input--med input--disabled">{mpa.processorName}</div>
					</div>
				</div>
				{this.renderRateStructure('pricingStructure', '')}
				<div className="row">
					<div className="col col-sml-12 col-lrg-6 form__group">
						<div className="form__group__header">
							<p className="form__group__label">Authorization Fee</p>
						</div>
						<div className="input input--med input--disabled input--currency input--currency--sml">
							{setup.pricingStructure.authorizationFee}
						</div>
					</div>
					<div className="col col-sml-12 col-lrg-6 form__group">
						<div className="form__group__header">
							<p className="form__group__label">V/MC/Disc Transaction Fee:</p>
						</div>
						<div className="input input--med input--disabled input--currency input--currency--sml">
							{setup.pricingStructure.bankCardTransactionFee}
						</div>
					</div>
					{this.renderPinDebit()}
				</div>
				<div className="row">
					{!setup.pricingStructure ||
						!setup.pricingStructure.bankName ||
						(toLower(setup.pricingStructure.bankName).indexOf('firstdata') === 0 && (
							<div className="col col-sml-12 col-lrg-6 form__group">
								<div className="form__group__header">
									<p className="form__group__label">Discount Method</p>
								</div>
								<div>
									<input
										name="discountMethod"
										id="discountMethod"
										type="checkbox"
										className="input--radio"
										checked={setup.pricingStructure.discountMethod}
										value={setup.pricingStructure.discountMethod}
										onChange={noop}
									/>
									<label htmlFor="discountMethod">{setup.pricingStructure.discountMethod}</label>
								</div>
							</div>
						))}
					{setup.pricingStructure &&
						setup.pricingStructure.bankName &&
						toLower(setup.pricingStructure.bankName) === 'elavon' && (
							<Fragment>
								<div className="row">
									<div className="col col-sml-12 col-med-6 form__group">
										<input
											className="input--check"
											type="checkbox"
											id="pricingStructure_shouldRequireNextDayFunding"
											name="pricingStructure_shouldRequireNextDayFunding"
											value={setup.pricingStructure.shouldRequireNextDayFunding}
											onChange={noop}
										/>
										<label htmlFor="pricingStructure_shouldRequireNextDayFunding">
											Next Day Funding Required ($5 monthly fee applies)
										</label>
									</div>
								</div>
							</Fragment>
						)}
				</div>

				<div className="flex--primary flex--gap--sml spc--bottom--lrg">
					<p className="type--p2 type--p2--medium">Proposed Fees</p>
					{this.props.renderChangeLink(this.props.redirectToSetup)}
				</div>
				<div className="row">
					<div className="col col-sml-12 col-lrg-6 form__group">
						<div className="form__group__header">
							<p className="form__group__label">Batch Fee</p>
						</div>
						<div className="input input--med input--disabled input--currency">{setup.proposedFees.batchFee}</div>
					</div>
					{this.renderRetrievalCharge(setup)}
					<div className="col col-sml-12 col-lrg-6 form__group">
						<div className="form__group__header">
							<p className="form__group__label">Statement Fee</p>
						</div>
						<div className="input input--med input--disabled input--currency">{setup.proposedFees.statementFee}</div>
					</div>
					<div className="col col-sml-12 col-lrg-6 form__group">
						<div className="form__group__header">
							<p className="form__group__label">Monthly Minimum Fee</p>
						</div>
						<div className="input input--med input--disabled input--currency">
							{setup.proposedFees.monthlyMinimumFee}
						</div>
					</div>
					<div className="col col-sml-12 col-lrg-6 form__group">
						<div className="form__group__header">
							<p className="form__group__label">Annual Fee</p>
						</div>
						<div className="input input--med input--disabled input--currency">{setup.proposedFees.annualFee}</div>
					</div>
				</div>
				<div className="row">
					<div className="col col-sml-12 col-lrg-6 form__group">
						<div className="form__group__header">
							<p className="form__group__label">Chargeback Fee</p>
						</div>
						<div className="input input--med input--disabled input--currency">{setup.proposedFees.chargebackFee}</div>
					</div>
					{this.renderPciFee(setup)}
					{setup.doesAcceptEbt ? (
						<div className="col col-sml-12 col-lrg-6 form__group">
							<div className="form__group__header">
								<p className="form__group__label">EBT Transaction Fee</p>
							</div>
							<div className="input input--med input--disabled input--currency">
								{setup.proposedFees.ebtTransactionFee}
							</div>
						</div>
					) : null}
				</div>
				<p className="type--p2 type--p2--medium spc--bottom--lrg">Early Termination Fee (ETF)</p>
				<div className="row flex--bottom">
					<div className="col col-sml-12 col-med-6 form__group">
						<CheckboxTxtInput
							disabled={true}
							labelText="Not Waived Fee"
							checkboxClass="input--check input--check--preview"
							checkboxName="proposedFees_isEarlyTerminationFeeWaived"
							inputName="proposedFees_earlyTerminationFee"
							inputPlaceholder=""
							checked={!setup.proposedFees.isEarlyTerminationFeeWaived}
							value={String(setup.proposedFees.earlyTerminationFee)}
							onCheckChanged={noop}
							onValueChanged={noop}
						/>
					</div>
					{this.renderFakeInput({
						name: 'Waived',
						value: setup.proposedFees.isEarlyTerminationFeeWaived,
					})}
				</div>
			</Fragment>
		);
	}

	renderPciFee(setup) {
		return (
			<div className="col col-sml-12 col-lrg-6 form__group">
				<div className="form__group__header">
					<p className="form__group__label">PCI Annual Fee</p>
				</div>
				<div className="input input--med input--disabled input--currency input--currency--sml">
					{setup.proposedFees.pciAnnualFee}
				</div>
			</div>
		);
	}

	renderRetrievalCharge(setup) {
		return (
			<div className="col col-sml-12 col-lrg-6 form__group">
				<div className="form__group__header">
					<p className="form__group__label">Retrieval Charge</p>
				</div>
				<div className="input input--med input--disabled input--currency input--currency--sml">
					{setup.proposedFees.retrievalCharge}
				</div>
			</div>
		);
	}

	renderPinDebit() {
		const { setup } = this.props;
		return (
			<div className="col col-sml-12 form__group">
				<CheckboxContainer
					labelText="Setup Merchant with Pin Debit "
					checkboxClass="input--check input--check--preview"
					checkboxName="pricingStructure_shouldSetupPinDebit"
					checked={setup.pricingStructure.shouldSetupPinDebit}
					onCheckChanged={noop}
				>
					{this.renderPinDebitStructure()}
				</CheckboxContainer>
			</div>
		);
	}

	renderPinDebitInput(name, value) {
		return this.renderFakeInput({ className: 'col col-sml-12 col-med-4', name, value });
	}

	renderPinDebitStructure() {
		const { setup } = this.props;
		return (
			<div className="row spc--top--sml  spc--bottom--med">
				{this.renderPinDebitInput('Proposed Structure', setup.pricingStructure.pinDebitStructureType)}
				{this.renderPinDebitInput('PIN Based Debit Trans Fee', setup.pricingStructure.pinDebitTransactionFee)}
				{this.renderPinDebitInput('PIN Based Debit Markup', setup.pricingStructure.pinDebitMarkupAmount)}
			</div>
		);
	}

	renderRateStructure = (rateObjectName, labelPrefix, _, __, tooltip) => {
		const { setup } = this.props;
		const namePrefix = rateObjectName + '_';

		// temporary code to allow ui to work without api
		if (!setup[rateObjectName]) {
			setup[rateObjectName] = {};
		}

		const rateObj = setup[rateObjectName];

		return (
			<DropdownContainer
				hideSelect={true}
				labelText={labelPrefix + ' Rate Structure'}
				labelClass="form__group__label"
				wrapperClass="col col-sml-12 col-med-3 form__group"
				divClass="row"
				selectClass="input input--med input--select"
				selectName={namePrefix + 'pricingStructureType'}
				required={true}
				value={rateObj.pricingStructureType}
				onChange={() => {}}
				tooltip={tooltip}
				options={[
					{
						value: 'PassThrough',
						text: 'Pass Through',
						children: map(
							[
								{
									name: 'Pass Through',
									value: rateObj.passThroughPercentage,
									labelPrefix,
									className: 'form__group',
								},
							],
							item => this.renderFakeInput(item)
						),
					},
					{
						value: 'FlatRate',
						text: 'Flat Rate',
						children: map(
							[
								{
									name: 'Flat Rate',
									value: rateObj.flatRatePercentage,
									labelPrefix,
									className: 'col col-sml-12 col-med-3 form__group',
								},
							],
							item => this.renderFakeInput(item)
						),
					},
					{
						value: 'Tiered',
						text: 'Tiered',
						children: map(
							[
								{
									name: 'Qual',
									value: rateObj.tieredRatePercentages.qualified,
									className: 'col col-sml-12 col-med-3 form__group',
								},
								{
									name: 'Mid-Qual',
									value: rateObj.tieredRatePercentages.midQualified,
									className: 'col col-sml-12 col-med-3 form__group',
								},
								{
									name: 'Non-Qual',
									value: rateObj.tieredRatePercentages.nonQualified,
									shouldRenderInput: () => this.shouldHideNonQual(rateObjectName),
									className: 'col col-sml-12 col-med-3 form__group',
								},
								{
									name: 'Rewards',
									value: rateObj.tieredRatePercentages.rewards,
									shouldRenderInput: () => rateObjectName.indexOf('amex') !== 0,
									className: 'col col-sml-12 col-med-3 form__group',
								},
							],
							item => this.renderFakeInput(item)
						),
					},
					{
						value: 'BillBack',
						text: 'Bill Back',
						children: map(
							[
								{
									name: 'Base Rate',
									value: rateObj.billBackRatePercentages.base,
									labelPrefix,
									className: 'col col-sml-12 col-med-3 form__group',
								},
								{
									name: 'Enhanced Rate',
									value: rateObj.billBackRatePercentages.enhanced,
									labelPrefix,
									shouldRenderInput: () => namePrefix.indexOf('amex') !== 0,
									className: 'col col-sml-12 col-med-3 form__group',
								},
							],
							item => this.renderFakeInput(item)
						),
					},
				]}
			/>
		);
	};

	shouldHideNonQual = () => false;

	renderFakeInput({
		name,
		value,
		labelPrefix = '',
		shouldRenderInput = () => true,
		className = 'col col-sml-12 col-med-6 form__group',
		valueClassName = 'input input--med input--disabled',
	}) {
		if (!shouldRenderInput()) return null;
		return (
			<div key={`${name}.${value}`} className={className}>
				<div className="form__group__header">
					<label className="form__group__label">
						{labelPrefix ? `${labelPrefix} ` : ''}
						{name}{' '}
					</label>
				</div>
				<div className={valueClassName}>{value}</div>
			</div>
		);
	}

	renderProcessingDetailsField(value, name, props) {
		return this.renderFakeInput({ name, value, className: 'col col-sml-12 col-lrg-4 form__group', ...props });
	}

	renderVMField(value, name) {
		return (
			<div className="col col-sml-12 col-lrg-4 form__group">
				<div className="form__group__header">
					<label className="form__group__label">{name} </label>
				</div>
				<div className="input input--med input--disabled">{value}</div>
			</div>
		);
	}

	renderProcessingMethod() {
		const { setup } = this.props;
		const processingDetails = get(setup, 'processingDetails', null);
		if (!processingDetails) {
			return null;
		}
		const {
			internetTransactionsPercentage,
			keyedTransactionsPercentage,
			swipedTransactionsPercentage,
		} = processingDetails;
		const processingDetailsTotalPercentage =
			internetTransactionsPercentage + keyedTransactionsPercentage + swipedTransactionsPercentage;

		return (
			<Fragment>
				<div className="spc--bottom--lrg">
					<div className="flex--primary flex--gap--sml spc--bottom--lrg">
						<p className="type--p2 type--p2--medium">Processing method (Must equal 100%)</p>
						{this.props.renderChangeLink(this.props.redirectToSetup)}
					</div>
					<div className="flex--primary flex--gap--lrg">
						<div className="row flex--grow--1">
							{this.renderProcessingDetailsField(swipedTransactionsPercentage, 'Swiped')}
							{this.renderProcessingDetailsField(keyedTransactionsPercentage, 'Keyed')}
							{this.renderProcessingDetailsField(internetTransactionsPercentage, 'Internet')}
						</div>
						{this.renderProcessingDetailsField(processingDetailsTotalPercentage, 'Total', {
							valueClassName: 'input input--med type--h4 flex--primary',
							className: 'w--90 form__group',
						})}
					</div>
				</div>
				{this.renderTransactionVolume()}
				{this.renderTransactionInformation()}
			</Fragment>
		);
	}

	renderTransactionVolume() {
		const { setup } = this.props;
		const processingDetails = get(setup, 'processingDetails', null);
		if (!processingDetails) {
			return null;
		}
		const { averageTransactionAmount, maxTransactionAmount, yearlyVmcVolume } = processingDetails;
		return (
			<React.Fragment>
				<div className="spc--bottom--lrg">
					<p className="type--p2 type--p2--medium">V/M Transaction Information:</p>
				</div>
				<div className="row flex--bottom spc--bottom--lrg">
					{this.renderVMField(yearlyVmcVolume, 'V/M Average Annual Volume')}
					{this.renderVMField(averageTransactionAmount, 'V/M Average transaction dollar amount')}
					{this.renderVMField(maxTransactionAmount, 'V/M highest transaction dollar amount')}
				</div>
			</React.Fragment>
		);
	}

	renderTransactionInformation() {
		return null;
	}

	renderTaxExemption() {
		const { setup } = this.props;
		if (!get(setup, 'additionalSetupDetails', false)) {
			return null;
		}
		const {
			additionalSetupDetails: { isTaxExemptionRequested },
		} = setup;

		return (
			<div className="spc--bottom--xlrg spc--top--med">
				<input
					name="additionalSetupDetails_isTaxExemptionRequested"
					id="isTaxExemptionRequested"
					type="checkbox"
					className="input--check"
					checked={isTaxExemptionRequested}
					value={isTaxExemptionRequested}
					onChange={noop}
				/>
				<label htmlFor="isTaxExemptionRequested">Request Tax Exemption</label>
			</div>
		);
	}
}

USSetupConfirm.propTypes = {
	mpa: PropTypes.shape({
		processorName: PropTypes.string,
	}),
	setup: PropTypes.shape({
		telemarketerEmail: PropTypes.string,
		hasExistingFidelityAccount: PropTypes.bool,
		existingAccountDba: PropTypes.string,
		goodsOrServicesDescription: PropTypes.string,
		amexDetails: PropTypes.object,
		proposedSavings: PropTypes.object,
		processingDetails: PropTypes.object,
		pricingStructure: PropTypes.object,
		additionalSetupDetails: PropTypes.object,
		processorName: PropTypes.string,
		proposedFees: PropTypes.shape({
			batchFee: PropTypes.string,
			retrievalCharge: PropTypes.string,
			statementFee: PropTypes.string,
			monthlyMinimumFee: PropTypes.string,
			annualFee: PropTypes.string,
			chargebackFee: PropTypes.string,
			pciAnnualFee: PropTypes.string,
			isEarlyTerminationFeeWaived: PropTypes.bool,
			earlyTerminationFee: PropTypes.string,
			ebtTransactionFee: PropTypes.string,
		}),
	}).isRequired,

	additionalSetupDetails: PropTypes.shape({
		isTaxExemptionRequested: PropTypes.bool,
		isCurrentlyAcceptingCreditCards: PropTypes.bool,
		requiresPricingAnalysis: PropTypes.bool,
		hadStatementsReviewed: PropTypes.bool,
		statementsReviewedDba: PropTypes.string,
	}),
};
