import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactToPrint from 'react-to-print';
import { renderIf, OutsideClick } from '../../utilities';

import PrintGridData from './print-grid-data';

class PrintGridButton extends Component {
	constructor(props) {
		super(props);

		this.state = {
			expand: false,
			allData: null,
		};

		this.allRef = React.createRef();
	}

	onOpen = () => {
		this.setState({
			expand: true,
		});
	};

	onClose = () => {
		this.setState({
			expand: false,
		});
	};

	showLoader = show => {
		this.props.showLoaderMethod(show);
	};

	getAll = async () => {
		this.showLoader(true);
		const allData = await this.props.getAll();
		this.setState(
			{
				allData,
				expand: false,
			},
			() => {
				this.allRef.current.handlePrint();
			}
		);
		this.showLoader(false);
	};

	render() {
		const { data, columns, type, allColumns, showDropdown } = this.props;
		let enabled = false;
		if (data !== null && data.length > 0) {
			enabled = true;
		}
		let allTitle = 'All transactions';
		if (type === 'giftCardLiability') {
			allTitle = 'All gift cards';
		} else if (type === 'giftCardSummary') {
			allTitle = 'All summaries';
		}
		return (
			<OutsideClick action={this.onClose}>
				<React.Fragment>
					{renderIf(showDropdown)(() => (
						<React.Fragment>
							<button className="btn btn--med btn--link btn--link--tertiary" onClick={this.onOpen} disabled={!enabled}>
								<i className="icon icon--sml icon--print" />
							</button>
							<div className="buttondropdown">
								<ReactToPrint.default // yes, this is correct, the package is broken and changing the import statement doesn't work
									trigger={() => (
										<div>
											{renderIf(this.state.expand)(
												<ul className="buttondropdown__list">
													<li className="buttondropdown__item" onClick={this.onClose}>
														Current view (PDF)
													</li>
												</ul>
											)}
										</div>
									)}
									content={() => this.print}
								/>
								{renderIf(this.state.expand)(
									<ul className="buttondropdown__list">
										<li className="buttondropdown__item" onClick={this.getAll}>
											{allTitle} (PDF)
										</li>
									</ul>
								)}
								<ReactToPrint.default
									ref={this.allRef}
									trigger={() => <div style={{ display: 'none' }}></div>}
									content={() => this.printAll}
								/>
							</div>
						</React.Fragment>
					))}
					{renderIf(!showDropdown)(
						<ReactToPrint
							trigger={() => (
								<button className="btn btn--med btn--link btn--link--tertiary" disabled={!enabled}>
									<i className="icon icon--sml icon--print" />
									<span>Print</span>
								</button>
							)}
							content={() => this.print}
						/>
					)}
					{enabled ? (
						<div style={{ display: 'none' }}>
							<PrintGridData ref={el => (this.print = el)} data={data} columns={columns} type={type} />
							{this.state.allData ? (
								<PrintGridData
									ref={el => (this.printAll = el)}
									data={this.state.allData}
									columns={allColumns}
									type={type}
								/>
							) : null}
						</div>
					) : null}
				</React.Fragment>
			</OutsideClick>
		);
	}
}

PrintGridButton.propTypes = {
	data: PropTypes.array,
	columns: PropTypes.array.isRequired,
	showLoaderMethod: PropTypes.func,
	type: PropTypes.oneOf(['merchants', 'leads', 'tickets', 'equipment']).isRequired,
	showDropdown: PropTypes.bool,
};

export default PrintGridButton;
