import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { map, find, filter, get, cloneDeep, some } from 'lodash';
import { appService } from '../../services';
import { withLoader } from '../../common/components';
import { modalNames, ModalWrapper } from '../../common/components/modal-wrapper';
import { planTemplate, Schema } from '../../validation';
import { defaultImplicitParse, defaultReactOutput } from 'simple-markdown';
import { withCancelable } from '../../common/components/cancelable';
import { AchPlanTable, filterCheck21TransactionFees, filterMonthlyFees, filterSetupFees, filterAchTransactionFees, isFeePercentage } from './ach-plan-helpers';

const additionalMarkupAchId = 3489;
const setupFeeIds = [12, 13];

class AchPlan extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			equipmentPlan: {},
			merchantPlan: {},
			merchantFees: {},
			errorMessage: '',
			merchantFeesSaved: false,
			planId: '',
			modal: {
				name: modalNames.none,
				data: null,
			},
			appId: this.props.match.params.appid,
			disableSave: false,
			errorList: [],
		};
	}

	scrollToTop = () => {
		const topElement = document.querySelector('#topOfPage');

		if (topElement) {
			topElement.scrollTop = 0;
		}
	};

	async componentDidMount() {
		try {
			this.props.showLoader(true);
			const { makePendingRequest, match } = this.props;
			let appId = match.params.appid;
			this.planSchema = new Schema(planTemplate, { strip: false, typecast: true });
			await makePendingRequest(
				appService
					.getAchPlan(appId)
					.then(({ equipmentPlan }) => {
						return appService.getMerchantPlan(appId).then(({ merchantAchPlanResponse }) => {
							const disableFeeInputs = merchantAchPlanResponse?.fees && merchantAchPlanResponse.fees?.length > 0;
							const merchantGatewayFee = find(merchantAchPlanResponse?.fees, fee => fee.feeName === 'Gateway Fee' && fee.feeType==='MonthlyFee')
							if(!merchantGatewayFee) {
								equipmentPlan.fees = filter(equipmentPlan.fees, fee => !(fee.feeName === 'Gateway Fee' && fee.feeType==='MonthlyFee'));
							}
							this.setState(
								{
									equipmentPlan,
									merchantPlan: merchantAchPlanResponse,
									merchantFeesSaved: disableFeeInputs,
									planId: equipmentPlan.planId,
								},
								this.mapPlans
							);
							this.props.showLoader(false);
						});
					})
					.catch(error => {
						console.log(error);
						this.setState({ errorMessage: 'An Error Ocurred Loading plans' });
						this.props.showLoader(false);
					}),
				'getAchPlan'
			);
		} catch (error) {
			this.props.showLoader(false);
		}
		this.scrollToTop();
	}

	filterRelevantFees = (equipmentPlan, merchantPlan) => {
		let relevantFees = [
			...filterMonthlyFees([...equipmentPlan.fees, ...equipmentPlan.additionalFees]),
			...filterSetupFees([...equipmentPlan.fees, ...equipmentPlan.additionalFees]),
			...filterCheck21TransactionFees([...equipmentPlan.fees, ...equipmentPlan.additionalFees]),
			...filterAchTransactionFees([...equipmentPlan.fees, ...equipmentPlan.additionalFees]),
		];

		let merchantFees = get(merchantPlan, 'fees', []);
		const fees = relevantFees.map(fee => {
			const merchantFee = find(merchantFees, f => fee.feeId === f.feeId);
			const isPercentage = isFeePercentage(fee.feeName);
			return {
				feeId: fee.feeId,
				feeName: fee.feeName,
				feeType: fee.feeType,
				minFee: get(merchantFee, 'minFee', fee.minFee),
				maxFee: get(merchantFee, 'maxFee', fee.maxFee),
				agentCost: isPercentage ? get(merchantFee, 'agentCost', fee.agentCost) * 100 : get(merchantFee, 'agentCost', fee.agentCost),
				freeTransactions: fee.freeTransactions,
				achTransactionType: fee.achTransactionType,
				merchantPrice: isPercentage ? get(merchantFee, 'merchantPrice', fee.retailPrice) * 100 : get(merchantFee, 'merchantPrice', fee.retailPrice),
			};
		});
		return fees;
	};

	mapPlans = () => {
		const { equipmentPlan, merchantPlan } = this.state;
		const fees = this.filterRelevantFees(equipmentPlan, merchantPlan);
		this.setState({ merchantFees: fees });
	};

	handleFeeChange = (feeId, event) => {
		const updateValue = event.floatValue;

		let fees = cloneDeep(this.state.merchantFees);
		let fee = fees.find((fee, i) => fee.feeId == feeId);
		const invalidValue = /^0*\.0*$/.test(updateValue);
		if (invalidValue || updateValue === undefined || isNaN(updateValue)) {
			fee.merchantPrice = null;
		} else {
			fee.merchantPrice = updateValue;
		}
		this.validateFees(fees);
		this.setState({ merchantFees: fees });
	};

	openCloseModal = (modalObj, ...rest) => {
		let state = {
			modal: modalObj,
		};
		this.setState(state);
	};

	onKeyDownHandler = (event, onClickHandler) => {
		if (event.keyCode === 13) onClickHandler();
	};

	getFeesForValidation = fees => {
		const feesToExcludeIfMerchantCostIsZero = filter(
			[
				...filterMonthlyFees(fees),
				...filterCheck21TransactionFees(fees),
				...filter(fees, fee => some([additionalMarkupAchId], id => id === fee.feeId)),
			],
			fee => !fee.merchantPrice
		);
		const feesToValidate = cloneDeep(filter(fees, fee => !some(feesToExcludeIfMerchantCostIsZero, { feeId: fee.feeId })));
		map(feesToValidate, fee => {
			if(isFeePercentage(fee.feeName)) {
				fee.merchantPrice = fee.merchantPrice * 100 / 10000;
			}
			return fee;
		});
		return feesToValidate;
	};

	validateFees = fees => {
		let errorList = [];
		const feesForValidation = [...this.getFeesForValidation(fees)];
		const setupFees = filter(feesForValidation, fee => some(setupFeeIds, id => id === fee.feeId));
		errorList = this.planSchema.validate(Object.assign({}, { fees: feesForValidation }));
		if (!some(setupFees, fee => fee.merchantPrice > 0)) {
			errorList.splice(0, 0, {
				message: '[**Application Fee or Setup Fee**](javascript:void) is required',
				path: 'fees.SetupFees',
			});
		}
		this.setState({ errorList });
		return errorList.length;
	};

	handleSavePlan = goToNextStep => {
		const { merchantFees } = this.state;
		if (this.state.disableSave) return;

		const validationResult = this.validateFees(merchantFees);
		if (validationResult > 0) {
			return;
		}

		this.openCloseModal({
			name: modalNames.confirmAction,
			data: {
				question: 'Please confirm Plan details, fees cannot be adjusted once saved',
				onConfirm: this.savePlan(goToNextStep),
			},
		});
	};

	savePlan = goToNextStep => () => {
		const { merchantFees, planId } = this.state;
		this.props.showLoader(true);
		this.setState({ disableSave: true });
		const merchantFeesToSave = filter(merchantFees, fee => fee.merchantPrice);
		const feesToSave =
			merchantFeesToSave &&
			merchantFeesToSave.map(fee => {
				if(isFeePercentage(fee.feeName)) {
					fee.merchantPrice = fee.merchantPrice * 100 / 10000;
				}
				return { merchantPrice: fee.merchantPrice, feeId: fee.feeId, freeTransactions: fee.freeTransactions };
			});
		appService
			.saveMerchantAchPlan(this.props.match.params.appid, feesToSave, planId)
			.then(() => {
				this.setState({ disableSave: true });
				this.props.showLoader(false);
				if (goToNextStep) this.tabGoTo('ach-confirm');
				this.setState({ merchantFeesSaved: true, errorMessage: '' });
			})
			.catch(error => {
				console.log(error);
				this.setState({ errorMessage: `An Error Ocurred Saving plan: ${error}` });
				this.props.showLoader(false);
				this.setState({ disableSave: false });
			});
	};

	renderErrors() {
		const { errorMessage } = this.state;
		if (!errorMessage) return null;
		return <div className="type--validation spc--bottom--lrg">{errorMessage}</div>;
	}

	tabGoTo = path => {
		const { appid } = this.props.match ? this.props.match.params : '';
		if (appid) this.props.history.push(`/eapp/${path}/${appid}`);
	};

	renderTabs() {
		return (
			<ul className="tabs spc--bottom--lrg">
				<li
					className="tabs__link"
					onKeyDown={e => this.onKeyDownHandler(e, () => this.tabGoTo('ach'))}
					onClick={() => this.tabGoTo('ach')}
				>
					MPA
				</li>
				<li className="tabs__link is-active">Fees</li>
				<li
						className="tabs__link disabled"
						onKeyDown={e => this.onKeyDownHandler(e, () => this.tabGoTo('ach-equipment'))}
						onClick={() => this.tabGoTo('ach-equipment')}
				>
					Equipment
				</li>
				<li
					className="tabs__link"
					onKeyDown={e => this.onKeyDownHandler(e, () => this.tabGoTo('ach-confirm'))}
					onClick={() => this.tabGoTo('ach-confirm')}
				>
					Confirm
				</li>
			</ul>
		);
	}

	renderValidationErrors = () => {
		const { errorList } = this.state;
		return errorList?.length ? (
			<div className="notes notes--warning spc--bottom--lrg">
				<i className="icon"></i>
				<ul className="type--validation__wrapper">
					{errorList.map(elem => {
						let elemSplit = elem.path.split('.');
						let elemId = elemSplit[1];
						return (
							<li key={elem.path} className="type--validation">
								<button
									onKeyDown={e => this.onKeyDownHandler(e, () => this.scrollAndFocusField(elemId))}
									onClick={() => {
										this.scrollAndFocusField(elemId);
									}}
								>
									{defaultReactOutput(defaultImplicitParse(elem.message))}
								</button>
							</li>
						);
					})}
				</ul>
			</div>
		) : null;
	};

	scrollAndFocusField = id => {
		const fee = this.getFeesForValidation(this.state.merchantFees)[id];
		let elem = null;
		if (!fee) {
			elem = document.getElementById(id); //case when id is not for merchantPrice
		} else {
			elem = document.getElementById('merchantPrice_' + fee.feeId);
		}
		if (elem) {
			elem.focus();
			elem.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
	};

	renderButtons = () => {
		const { disableSave, merchantFeesSaved } = this.state;
		const disableSaveButtons = disableSave || merchantFeesSaved || this.state.errorList?.length;

		return <div className="leads__footer">
			{merchantFeesSaved ? (
				<button className="btn btn--med btn--primary" onClick={() => this.tabGoTo('ach-confirm')}>
					Next Step
				</button>
			) : (
				<Fragment>
					<button
						className="btn btn--med btn--tertiary"
						disabled={disableSaveButtons}
						onClick={() => this.handleSavePlan(false)}
					>
						Save
					</button>
					<button
						className="btn btn--med btn--primary"
						disabled={disableSaveButtons}
						onClick={() => this.handleSavePlan(true)}
					>
						Save and Next Step
					</button>
				</Fragment>
			)}
		</div>;

	}

	render() {
		const { merchantFeesSaved, merchantFees: fees, modal } = this.state;
		const { isPreviewOnly } = this.props;

		return (
			<div id="main-div">
				{this.renderErrors()}
				{!isPreviewOnly && this.renderTabs()}
				{merchantFeesSaved && !isPreviewOnly && (
					<div className="notes notes--default note--no-margin spc--bottom--med">
						Fees are not adjustable once they have been saved.{' '}
					</div>
				)}
				<ModalWrapper modal={modal} onModalClose={this.openCloseModal} />
				{fees?.length > 0 && (
					<div className="leads">
						<AchPlanTable {...{ fees, isDisabled: merchantFeesSaved || isPreviewOnly, handleFeeChange: this.handleFeeChange }} />
						{this.renderValidationErrors()}
						{!isPreviewOnly && this.renderButtons()}
					</div>
				)}
			</div>
		);
	}
}
export default withLoader(withCancelable(AchPlan));

AchPlan.propTypes = {
	match: PropTypes.object,
	history: PropTypes.object,
	showLoader: PropTypes.func,
	makePendingRequest: PropTypes.func,
	isLoading: PropTypes.bool,
	isPreviewOnly: PropTypes.bool
};
AchPlan.defaultProps = {
	isPreviewOnly: false
};
