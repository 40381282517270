import React, { Fragment } from 'react';

import { renderIf } from '../../utilities';

const emptyGrid = ({ fetchingData, emptyMessage }) => (
	<Fragment>
		{fetchingData ? (
			<Fragment>
				<div className="loader--progress" />
				<p className="react-grid-Empty-text">Loading...</p>
			</Fragment>
		) : (
			<Fragment>
				<div className="react-grid-Empty-img"></div>
				<div className="react-grid-Empty-title">0 Results</div>
				{renderIf(emptyMessage)(<p className="react-grid-Empty-text">{emptyMessage}</p>)}
			</Fragment>
		)}
	</Fragment>
);

export default emptyGrid;
