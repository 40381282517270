import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { isEmpty, map, memoize } from 'lodash';
import { fileTypes, getFileTypeExtensions } from './fileTypeConstants';

class ImageDropzoneComponent extends React.Component {
	handleDrop = tag => acceptedFiles => {
		this.props.onDrop(tag, acceptedFiles);
	};

	fileTypeExtensions = memoize(getFileTypeExtensions);

	render() {
		const { fileList, tag, validateFileSize } = this.props;
		const maxSize = 10485760; // 5 MB // 10 = 10485760

		return (
			<Dropzone
				accept={this.fileTypeExtensions(this.props.allowedFileTypes)}
				multiple={false}
				onDrop={this.handleDrop(tag)}
				minSize={0}
				disabled={this.props.disabled || (fileList && fileList.length > 0)}
				maxSize={maxSize}
			>
				{({ getRootProps, getInputProps, isDragActive, isDragReject, rejectedFiles }) => {
					const isFileTooLarge =
						validateFileSize && rejectedFiles && rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;
					return (
						<div {...getRootProps()} className="file-upload__container">
							{fileList && fileList.length > 0 ? (
								<div className="fullwidth">
									<div className="upload upload--preview">
										<img src={URL.createObjectURL(fileList[0].file)} alt="icon" />
										<button
											className="btn btn--action btn--action--secondary delete-button datatooltip--left datatooltip--auto"
											onClick={this.props.onRemoveFile}
											data-tooltip="Remove"
										>
											<i className="icon icon--sml icon--delete"></i>
										</button>
									</div>
								</div>
							) : (
								<div className="fullwidth">
									<input {...getInputProps()} />

									{!isDragActive && (
										<div className="upload">
											<div className="upload__icon"></div>
											<div className="upload__text">
												<p className="type--p3 type--p3--medium spc--bottom--tny">Choose a file or drag it here</p>
												<p className="type--p3">Max 10MB file (png, jpeg)</p>
											</div>
										</div>
									)}
									{isDragActive && !isDragReject && (
										<div className="upload">
											<div className="upload__text">
												<p className="type--p3 type--p3--medium spc--bottom--tny">Drop file here</p>
											</div>
										</div>
									)}
									{isDragReject && (
										<div className="upload">
											<div className="upload__text">
												<p className="type--p3 type--p3--medium spc--bottom--tny">File type not accepted, sorry!</p>
											</div>
										</div>
									)}
									{!isEmpty(rejectedFiles) && !isFileTooLarge && (
										<div className="upload">
											<div className="upload__text">
												<p className="type--color--warning type--wgt--medium">File type not accepted, sorry!</p>
											</div>
										</div>
									)}
									{isFileTooLarge && (
										<div className="upload">
											<div className="upload__text">
												<p className="type--p3 type--p3--medium spc--bottom--tny">File is too large.</p>
											</div>
										</div>
									)}
								</div>
							)}
						</div>
					);
				}}
			</Dropzone>
		);
	}
}

ImageDropzoneComponent.propTypes = {
	onRemoveFile: PropTypes.func,
	onDrop: PropTypes.func.isRequired,
	fileList: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	tag: PropTypes.string,
	validateFileSize: PropTypes.bool,
	disabled: PropTypes.bool,
	allowedFileTypes: PropTypes.array,
};

ImageDropzoneComponent.defaultProps = {
	tag: '',
	disabled: false,
	validateFileSize: true,
	allowedFileTypes: map(fileTypes, (val, key) => val),
};

export default ImageDropzoneComponent;
