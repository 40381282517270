import { isEmpty, get, split } from 'lodash';
import { getValueForProperty, minFee, maxFee } from './validators';

const requiredOptions = (val, ctx) => {
	const isRequired = val.isRequired;
	if (val.isNotAvailable) return true;
	if (isRequired && !val.dependentOnName && (!ctx.equipmentOptions || !ctx.equipmentOptions[val.name])) {
		return false;
	} else if (
		isRequired &&
		val.dependentOnName &&
		ctx.equipmentOptions &&
		ctx.equipmentOptions[val.dependentOnName] == val.dependentOnValue &&
		!ctx.equipmentOptions[val.name]
	) {
		return false;
	} else if (ctx.equipmentOptions) {
		let fullOptionVal = ctx.equipmentOptions[val.name];

		if (fullOptionVal) {
			let splitOptionVal = fullOptionVal.toString().split('|');
			let option = val.options.length > 1 && val.options.find(o => o.value == splitOptionVal[0]);
			if (option && option.requireInput && (splitOptionVal.length == 1 || !splitOptionVal[1].trim())) return false;
		}
	}

	return true;
};
const paymentSchedule = (val, ctx) => (ctx.purchaseType == 'purchase' ? !!val : true);
const platform = (val, ctx) => (isEmpty(get(ctx, 'frontendProcessors', false)) ? true : !!val);
const quantity = (val, ctx) => (ctx.category === 'Accessories' ? val <= 300 : val <= 20);
const shippingSpeed = (val, ctx) => (ctx.shippingOption == 'Pickup' ? true : !!val);

const merchantPrice = {
	type: Number,
	use: { minFee, maxFee },
	required: true,
	message: {
		type: (val, ctx) => `[**Merchant Cost**](javascript:void) is invalid`,
		required: (val, ctx) => `[**Merchant Cost**](javascript:void) is required`,
		minFee: (path, ctx) => {
			const minFee = getValueForProperty(ctx, path, 'minFee');
			const feeName = getValueForProperty(ctx, path, 'feeName');
			return `[**Merchant Cost**](javascript:void) must be equal or higher than ${minFee} for [**${feeName}**](javascript:void)`;
		},
		maxFee: (path, ctx) => {
			const maxFee = getValueForProperty(ctx, path, 'maxFee');
			const feeName = getValueForProperty(ctx, path, 'feeName');
			return `[**Merchant Cost**](javascript:void) must be equal or lower than ${maxFee} for [**${feeName}**](javascript:void)`;
		},
	},
};

export const equipmentTemplate = {
	settingsSource: [
		{
			required: false,
			use: { requiredOptions },
			message: {
				requiredOptions: (path, ctx) => {
					let indx = path.split('.')[1];
					let optionName = ctx.settingsSource[indx].friendlyName;
					let errorMessage = `[**${optionName}**](javascript:void) is required`;
					if (ctx.settingsSource[indx].dependentOnName) {
						errorMessage += ` when ${ctx.settingsSource[indx].dependentOnName} is `;
						let val = ctx.settingsSource[indx].dependentOnValue;
						errorMessage += val == 1 ? 'selected' : val == 0 ? 'not selected' : val;
					}
					return errorMessage;
				},
			},
		},
	],
	fees: [{ merchantPrice }],
	platform: {
		use: { platform },
		message: {
			platform: (val, ctx) => `[**Platform**](javascript:void) is required`,
		},
	},
	purchaseType: {
		required: true,
		message: {
			required: (val, ctx) => `[**Purchase Type**](javascript:void) is required`,
		},
	},
	purchasePlanId: {
		required: true,
		message: {
			required: (val, ctx) => `[**Purchase Plan**](javascript:void) is required`,
		},
	},
	paymentSchedule: {
		use: { paymentSchedule },
		message: {
			paymentSchedule: (val, ctx) => {
				return `[**Payment Schedule**](javascript:void) is required`;
			},
		},
	},
	quantity: {
		use: { quantity },
		message: {
			quantity: (val, ctx) => {
				return `[**Quantity**](javascript:void) cannot exceed ${ctx.category === 'Accessories' ? 300 : 20}`;
			},
		},
	},
	shippingOption: {
		type: String,
		required: true,
		message: {
			required: '[**Shipping Details**](javascript:void) is required',
		},
	},
	shippingSpeed: {
		use: { shippingSpeed },
		message: {
			shippingSpeed: (val, ctx) => {
				return `[**Shipping Speed**](javascript:void) is required`;
			},
		},
	},
	equipmentOptions: {
		required: false,
	},
	accessories: [
		{
			fees: [
				{
					merchantPrice: {
						...merchantPrice,
						message: {
							...merchantPrice.message,
							minFee: (path, ctx) => {
								const minFee = getValueForProperty(ctx, path, 'minFee');
								let subequipmentIndex = split(path, '.')[1];
								const subequipmentName = get(ctx, `accessories[${subequipmentIndex}].name`);
								return `[**Merchant Cost**](javascript:void) must be equal or higher than [**${minFee}**](javascript:void) for [**${subequipmentName}**](javascript:void)`;
							},
							maxFee: (path, ctx) => {
								const maxFee = getValueForProperty(ctx, path, 'maxFee');
								let subequipmentIndex = split(path, '.')[1];
								const subequipmentName = get(ctx, `accessories[${subequipmentIndex}].name`);
								return `[**Merchant Cost**](javascript:void) must be equal or lower than [**${maxFee}**](javascript:void) for [**${subequipmentName}**](javascript:void)`;
							},
						},
					},
				},
			],
		},
	],
	subequipment: [
		{
			fees: [
				{
					merchantPrice: {
						...merchantPrice,
						message: {
							...merchantPrice.message,
							minFee: (path, ctx) => {
								const minFee = getValueForProperty(ctx, path, 'minFee');
								const feeName = getValueForProperty(ctx, path, 'feeName');
								let subequipmentIndex = split(path, '.')[1];
								const subequipmentName = get(ctx, `subequipment[${subequipmentIndex}].name`);
								return `[**Merchant Cost**](javascript:void) must be equal or higher than [**${minFee}**](javascript:void) for [**${subequipmentName} ${feeName}**](javascript:void)`;
							},
							maxFee: (path, ctx) => {
								const maxFee = getValueForProperty(ctx, path, 'maxFee');
								const feeName = getValueForProperty(ctx, path, 'feeName');
								let subequipmentIndex = split(path, '.')[1];
								const subequipmentName = get(ctx, `subequipment[${subequipmentIndex}].name`);
								return `[**Merchant Cost**](javascript:void) must be equal or lower than [**${maxFee}**](javascript:void) for [**${subequipmentName} ${feeName}**](javascript:void)`;
							},
						},
					},
				},
			],
		},
	],
};
