import React, { Component, Fragment, createRef } from 'react';
import {
	find,
	filter,
	cloneDeep,
	map,
	get,
	orderBy,
	toLower,
	upperFirst,
	uniqBy,
	findIndex,
	includes,
	startsWith,
	some,
	noop,
} from 'lodash';
import { func, array, object } from 'prop-types';
import { withRouter } from 'react-router-dom';

import { emailTemplates, posSystemList } from '../../common/utilities';
import { modalNames } from '../../common/components/modal-wrapper/modal-names';
import principalService from '../../services/principalService';
import emailService from '../../services/emailService';
import { ModalWrapper } from '../../common/components/modal-wrapper';
import { withContext, withLoader } from '../../common/components';
import { Notification } from '../../common/components/notifications';
import { MerchantContext } from '../MerchantDetails';
import { equipmentDB, equipmentDatabaseKeys as keys } from '../../helpers/indexedDB';
import { Modal } from '../../common/components/modal';

class PosSystemListComponent extends Component {
	constructor(props) {
		super(props);

		const principal = principalService.get();

		this.state = {
			filteredList: this.initialFilteredList,
			expanded: {},
			filterSettings: this.initialFilterSettings,
			fromEmail: principal.email,
			fromName: principal.name,
			modal: {
				name: modalNames.none,
				data: null,
			},
			merchant: {},
			errorMessage: null,
			isOpenNoteModal: null,
		};

		this.notificationRef = createRef();
	}

	get initialFilteredList() {
		return map(
			orderBy(posSystemList, ({ name }) => toLower(name), ['asc']),
			(item, index) => ({ ...item, index, isSelected: false })
		);
	}

	get initialFilterSettings() {
		return {
			name: '',
			supportedIndustries: '',
			supportedGateways: '',
		};
	}

	get supportedIndustriesOptions() {
		return orderBy(
			uniqBy(posSystemList, 'supportedIndustries'),
			({ supportedIndustries }) => toLower(supportedIndustries),
			['asc']
		);
	}

	get supportedGatewaysOptions() {
		return orderBy(uniqBy(posSystemList, 'supportedGateways'), ({ supportedGateways }) => toLower(supportedGateways), [
			'asc',
		]);
	}

	openCloseModal = modalObj => {
		let state = {
			modal: modalObj,
		};
		this.setState(state);
	};

	sendInquireEmail = async () => {
		try {
			const {
				merchantPosSystemList,
				clearMerchantPosSystemList,
				merchant: { appId: appId, mid, dba },
			} = this.props;
			const { fromEmail, fromName } = this.state;
			const addNotification = get(this.notificationRef, 'current.addNotification', noop);
			const selectedPosSystems = map(
				filter(merchantPosSystemList, ({ isSelected }) => isSelected),
				({ name }) => name
			);

			await emailService.send(emailTemplates.posSystemInquire, {
				toEmail: process.env.REACT_APP_TECH_SUPPORT_EMAIL,
				fromEmail,
				fromName,
				appId,
				mid,
				dba,
				posSystems: selectedPosSystems,
			});

			equipmentDB.deleteEquipment(keys.merchantPosSystemList, appId);
			this.setState({
				filteredList: this.initialFilteredList,
				filterSettings: this.initialFilterSettings,
				expanded: {},
			});
			clearMerchantPosSystemList();

			addNotification({
				message: 'Sent successfully.',
				success: true,
			});
		} catch (e) {
			this.setState({ errorMessage: e.message });
		}
	};

	openConfirmSendEmailPopup = () =>
		this.setState({
			modal: {
				name: modalNames.confirmAction,
				data: {
					question: 'Are you sure you want to inquire the selected POS Systems?',
					onConfirm: this.sendInquireEmail,
				},
			},
		});

	toggleSelect = name => () => {
		let posSystem = cloneDeep(this.props.merchantPosSystemList?.find(e => e.name === name));

		if (!!posSystem) {
			posSystem.isSelected = !posSystem.isSelected;
		} else {
			posSystem = find(this.initialFilteredList, { name });
			posSystem.isSelected = true;
		}

		delete posSystem.index;

		this.props.onChange(posSystem);
	};

	setFilter(fieldName, valueToFilter, clearOtherFilters = false) {
		const { filterSettings } = this.state;

		let query = cloneDeep(filterSettings);
		let filteredList = [];

		if (clearOtherFilters) query = {};

		query[fieldName] = toLower(valueToFilter);

		filteredList = filter(this.initialFilteredList, item => {
			for (let key in query) {
				if (
					item[key] === undefined ||
					!includes(toLower(item[key]), query[key]) ||
					(startsWith(key, 'supported') && query[key] && toLower(item[key]) !== query[key])
				) {
					return false;
				}
			}
			return true;
		});

		this.setState({
			filterSettings: query,
			filteredList: orderBy(filteredList, ({ name }) => toLower(name), ['asc']),
		});
	}

	handleFiltersChange = ({ target: { name, value } }) => this.setFilter(name, value);

	handleChange = name => ({ target: { name: key, value } }) => {
		const { merchantPosSystemList } = this.props;
		let index = findIndex(merchantPosSystemList, { name });
		let posSystem = null;

		if (index > -1) {
			posSystem = {
				...merchantPosSystemList[index],
				[key]: value,
			};
		} else {
			posSystem = find(this.initialFilteredList, { name });
			posSystem[key] = value;
		}

		this.props.onChange(posSystem);
	};
	toggleAddNoteModal = noteName => () => {
		this.setState({ isOpenNoteModal: noteName });
	};

	renderRowDetails = ({
		supportedDevices,
		resellerCommision,
		billingPlan,
		setupProcess,
		setupAndSupportBy,
		additionalInformation,
		addonsAndIntegrations,
		cost,
		url,
	}) => (
		<div className="equipment__list__item--expanded">
			<div className="item">
				<p className="type--p3 type--p3--medium type--uppercase type--color--text--light">Supported Devices</p>
				<span className="type--p2">{supportedDevices}</span>
			</div>
			<div className="item">
				<p className="type--p3 type--p3--medium type--uppercase type--color--text--light">Cost</p>
				<span className="type--p2">{cost}</span>
			</div>
			<div className="item">
				<p className="type--p3 type--p3--medium type--uppercase type--color--text--light">Reseller Commission</p>
				<span className="type--p2">{resellerCommision}</span>
			</div>
			<div className="item">
				<p className="type--p3 type--p3--medium type--uppercase type--color--text--light">Billing Plan</p>
				<span className="type--p2">{billingPlan}</span>
			</div>
			<div className="item">
				<p className="type--p3 type--p3--medium type--uppercase type--color--text--light">Setup Process</p>
				<span className="type--p2">{setupProcess}</span>
			</div>
			<div className="item">
				<p className="type--p3 type--p3--medium type--uppercase type--color--text--light">Setup and Support by</p>
				<span className="type--p2">{setupAndSupportBy}</span>
			</div>
			<div className="item">
				<p className="type--p3 type--p3--medium type--uppercase type--color--text--light">Additional Information</p>
				<span className="type--p2">{additionalInformation}</span>
			</div>
			<div className="item">
				<p className="type--p3 type--p3--medium type--uppercase type--color--text--light">Add-ons & Integrations</p>
				<span className="type--p2">{addonsAndIntegrations}</span>
			</div>
		</div>
	);

	renderTableBody = row => {
		const { name, supportedIndustries, specialty, supportedProcessors, supportedGateways, note } = row;
		const { expanded } = this.state;
		const { merchantPosSystemList } = this.props;
		let merchantPosSystem = find(merchantPosSystemList, e => e.name === name);
		const isExpanded = expanded.name === name;

		return (
			<Fragment>
				<div className="equipment__list__item">
					<div className="equipment__list__th">Name</div>
					<div className="equipment__list__td cursor--pointer">
						<div
							className="table--accessories__list__item__thumbnail"
							style={{
								backgroundImage:
									'url(' +
									process.env.REACT_APP_CDN_URL +
									name
										.toLowerCase()
										.replace(' ', '_')
										.replace(/[^a-z0-9_-]/gi, '') +
									'/thumbnail.png' +
									')',
							}}
						></div>
						<p>{name}</p>
					</div>
				</div>
				<div className="equipment__list__item">
					<div className="equipment__list__th">Industries</div>
					<div className="equipment__list__td">{supportedIndustries}</div>
				</div>
				<div className="equipment__list__item">
					<div className="equipment__list__th">Specialty</div>
					<div className="equipment__list__td">{specialty}</div>
				</div>
				<div className="equipment__list__item">
					<div className="equipment__list__th">Processors</div>
					<div className="equipment__list__td">{supportedProcessors}</div>
				</div>
				<div className="equipment__list__item">
					<div className="equipment__list__th">Supported Gateways</div>
					<div className="equipment__list__td">{supportedGateways}</div>
				</div>
				<div className="equipment__list__item">
					<div className="equipment__list__th">Note</div>
					<div className="equipment__list__td">
						<Modal isOpen={this.state.isOpenNoteModal === name} onClose={this.toggleAddNoteModal(null)}>
							<div className="modal__header">
								<h4>Add Note</h4>
							</div>
							<div className="modal__body">
								<div>
									<div className="form__group__header">
										<p className="form__group__label">Note</p>
									</div>
									<textarea
										name="note"
										id={`${name}.note`}
										className="input input--textarea"
										rows="5"
										placeholder="Type note here"
										value={get(merchantPosSystem, 'note', note)}
										onChange={this.handleChange(name)}
										onClick={e => e.stopPropagation()}
									></textarea>
								</div>
							</div>
							<div className="modal__footer">
								<button className="btn btn--med btn--primary" onClick={this.toggleAddNoteModal(null)}>
									Save
								</button>
							</div>
						</Modal>
						<button
							className={`btn btn--note ${get(merchantPosSystem, 'note', note) ? 'has-note' : ''}`}
							onClick={this.toggleAddNoteModal(name)}
						>
							<i
								className="icon icon--sml icon--edit datatooltip--auto datatooltip--no-wrap"
								data-tooltip="Add Note"
							></i>
						</button>
					</div>
				</div>
				<div className="equipment__list__item">
					<div className="equipment__list__th right"></div>
					<div className="equipment__list__td right">
						{merchantPosSystem && merchantPosSystem.isSelected ? (
							<div>
								<input
									type="checkbox"
									className="input--check input--check--no-label"
									id={`added.${name}`}
									checked={!!merchantPosSystem.isSelected}
									value={!!merchantPosSystem.isSelected}
									onChange={this.toggleSelect(name)}
								/>
								<label htmlFor={`added.${name}`}>Label</label>
							</div>
						) : (
							<div>
								<input
									type="checkbox"
									className="input--check input--check--no-label"
									id={`select.${name}`}
									checked={false}
									value={false}
									onChange={this.toggleSelect(name)}
								/>
								<label htmlFor={`select.${name}`}>Label</label>
							</div>
						)}
						<button className="btn btn--link spc--left--med" onClick={this.onRowClick(row.name)}>
							<i className={`icon icon--med icon--chevron--${isExpanded ? 'top' : 'right'}--primary`}></i>
						</button>
					</div>
				</div>
				{isExpanded && this.renderRowDetails(row)}
			</Fragment>
		);
	};

	onRowClick = name => () => {
		let expanded = {};

		if (name !== this.state.expanded.name) {
			expanded = find(this.state.filteredList, { name });
		}

		this.setState({ expanded });
	};

	renderRow = (row, index) => (
		<div key={index}>
			<div className="equipment__list equipment__list--pos">{this.renderTableBody(row)}</div>
		</div>
	);

	render() {
		const { isLoading, merchantPosSystemList } = this.props;
		const { filteredList, filterSettings, errorMessage } = this.state;
		const hasSelectedPosSystems = some(merchantPosSystemList, ({ isSelected }) => isSelected);
		const disabled = isLoading || !hasSelectedPosSystems;

		return (
			<Fragment>
				<Notification ref={this.notificationRef} />
				<ModalWrapper modal={this.state.modal} onModalClose={this.openCloseModal} />
				<div className="notes notes--primary w--max--790 spc--bottom--lrg">
					<i className="icon"></i>
					<p className="type--p3">
						The POS system(s) you add here are not automatically added to the merchant's account. After you submit the
						eApp, the internal team reviews your selections and will contact you for additional details and to confirm
						compatibility.
					</p>
				</div>
				<div className="flex--secondary flex--gap--sml spc--bottom--xxlrg">
					<div className="flex--primary flex--bottom flex--gap--sml">
						<div>
							<select
								className="filter__select--native w--170"
								name="supportedIndustries"
								id="supportedIndustries"
								onChange={this.handleFiltersChange}
								value={filterSettings.supportedIndustries}
							>
								<option value="">Industry Type</option>
								{map(
									this.supportedIndustriesOptions,
									({ supportedIndustries }, index) =>
										supportedIndustries && (
											<option key={`${supportedIndustries}.${index}`} value={toLower(supportedIndustries)}>
												{upperFirst(supportedIndustries)}
											</option>
										)
								)}
							</select>
						</div>
						<div>
							<select
								className="filter__select--native w--170"
								name="supportedGateways"
								id="supportedGateways"
								onChange={this.handleFiltersChange}
								value={filterSettings.supportedGateways}
							>
								<option value="">Supported Gateways</option>
								{map(
									this.supportedGatewaysOptions,
									({ supportedGateways }, index) =>
										supportedGateways && (
											<option key={`${supportedGateways}.${index}`} value={toLower(supportedGateways)}>
												{upperFirst(supportedGateways)}
											</option>
										)
								)}
							</select>
						</div>
					</div>
					<button className="btn btn--sml btn--primary" onClick={this.openConfirmSendEmailPopup} disabled={disabled}>
						Inquire Selected POS Systems
					</button>
				</div>
				<div className="table--accessories__list__group">
					{filteredList.length === 0 ? (
						<div className="notes notes--default">No POS Systems found for the current filter</div>
					) : (
						map(filteredList, (row, index) => this.renderRow(row, index))
					)}
					{errorMessage && <div className="type--validation">{errorMessage}</div>}
				</div>
			</Fragment>
		);
	}
}

PosSystemListComponent.propTypes = {
	merchantPosSystemList: array,
	onChange: func,
	merchant: object,
};

export default withLoader(withRouter(withContext(PosSystemListComponent, MerchantContext, 'merchant')));
