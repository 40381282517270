import React from 'react';
import { string, bool } from 'prop-types';

class AdditionalNotes extends React.Component {
	render() {
		const { notes, wrapperClass, hideTitle } = this.props;

		return (
			<div className={wrapperClass}>
				<div className="form--popup">
					{!hideTitle && <h6 className="spc--bottom--med">Current Pricing Breakdown</h6>}
					<div className="details__content">
						<div className="row">
							<div className="col col-sml-12 col-med-6 form__group">
								<div className="form__group__header">
									<label className="form__group__label" htmlFor="lblSicCode">
										Sic Code
									</label>
								</div>
								<div className="input input--med input--disabled" name="lblSicCode" id="lblSicCode">
									{notes.sicCode}
								</div>
							</div>
							<div className="col col-sml-12 col-med-6 form__group">
								<div className="form__group__header">
									<label className="form__group__label" htmlFor="lblDuesAndAssessments">
										Dues and Assessments
									</label>
								</div>
								<div
									className="input input--med input--disabled"
									name="lblDuesAndAssessments"
									id="lblDuesAndAssessments"
								>
									{notes.duesAndAssessments}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

AdditionalNotes.defaultProps = {
	wrapperClass: 'card--primary card--sml card--primary--popup',
	hideTitle: false,
};

AdditionalNotes.propTypes = {
	wrapperClass: string,
	hideTitle: bool,
};

export default AdditionalNotes;
