import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ProcessorListSelectionComponent extends Component {
	filterText = () => {
		const {
			filter: { values },
		} = this.props;

		let textElements = [];

		const indexOfCkGo = textElements.indexOf('CK-Go');
		const indexOfACHQ = textElements.indexOf('ACHQ');
		const indexOfFirstData = textElements.indexOf('First Data');
		const indexOfFDCanada = textElements.indexOf('FD Canada');
		const indexOfElavonCanada = textElements.indexOf('Elavon Canada');
		const indexOfElavonCanadaUSD = textElements.indexOf('Elavon Canada USD');
		const indexOfProfitStarsAch = textElements.indexOf('ProfitStars Ach');
		const indexOfFDNorthFACS = textElements.indexOf('FD North FACS');
		const indexOfElavon = textElements.indexOf('Elavon');
		const indexOfVantiv = textElements.indexOf('Vantiv');
		const indexOfDeveloper = textElements.indexOf('Developer');

		if (values['Go Plus'] && indexOfCkGo === -1) {
			textElements.push('CK-Go');
		}
		if (values['ACHQ'] && indexOfACHQ === -1) {
			textElements.push('ACHQ');
		}
		if (values['First Data'] && indexOfFirstData === -1) {
			textElements.push('First Data');
		}
		if (values['FD Canada'] && indexOfFDCanada === -1) {
			textElements.push('FD Canada');
		}
		if (values['Elavon Canada'] && indexOfElavonCanada === -1) {
			textElements.push('Elavon Canada');
		}
		if (values['Elavon Canada USD'] && indexOfElavonCanadaUSD === -1) {
			textElements.push('Elavon Canada USD');
		}
		if (values['ACH-CK21'] && indexOfProfitStarsAch === -1) {
			textElements.push('ProfitStars Ach');
		}
		if (values['FD North FACS'] && indexOfFDNorthFACS === -1) {
			textElements.push('FD North FACS');
		}
		if (values['Elavon'] && indexOfElavon === -1) {
			textElements.push('Elavon');
		}
		if (values['Vantiv'] && indexOfVantiv === -1) {
			textElements.push('Vantiv');
		}
		if (values['Developer'] && indexOfDeveloper === -1) {
			textElements.push('Developer');
		}

		return textElements.join(', ');
	};

	filterRemoved = () => {
		this.props.onFilterRemoved({
			id: 'processorList',
			emptyValue: false,
		});
	};

	render() {
		return (
			<div className="filter__toolbar__selected-filter">
				Processor: <span className="type--wgt--medium">{this.filterText()}</span>
				<button onClick={this.filterRemoved} className="btn btn--link datatooltip--auto">
					<i className="icon icon--tny icon--close--text"></i>
				</button>
			</div>
		);
	}
}

ProcessorListSelectionComponent.propTypes = {
	filter: PropTypes.shape({
		values: PropTypes.object,
	}),
	onFilterRemoved: PropTypes.func,
};

export default ProcessorListSelectionComponent;
