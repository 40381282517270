import React, { Component } from 'react';
import PropTypes from 'prop-types';

class DescriptionSelectionComponent extends Component {
	get descriptionValue() {
		return this.props.filter.values.description;
	}

	filterRemoved = e => {
		this.props.onFilterRemoved({
			id: 'description',
			emptyValue: null,
		});
	};

	render() {
		return (
			<div className="filter__toolbar__selected-filter">
				Description: <span className="type--wgt--medium">{this.descriptionValue}</span>
				<button onClick={this.filterRemoved} className="btn btn--link datatooltip--auto">
					<i className="icon icon--tny icon--close--text"></i>
				</button>
			</div>
		);
	}
}

DescriptionSelectionComponent.propTypes = {
	filter: PropTypes.object.isRequired,
	onFilterRemoved: PropTypes.func.isRequired,
};

export default DescriptionSelectionComponent;
