import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { getThumbnailOverlay } from '../../../common/utilities';
import { formatCurrency } from '../../../helpers/currency-formatter';

const HardwareCard = forwardRef(
	(
		{ hardware, merchantHardware, accessoriesTotal, price, outOfStock, hardwareSelectToggle, onViewEquipmentDetail },
		ref
	) => {
		return (
			<div className="equipment-card" ref={ref}>
				<button
					className={`equipment-card__thumbnail${getThumbnailOverlay(outOfStock, hardware.purchaseTypes)}${
						merchantHardware?.isSelected ? ' equipment-card__thumbnail__badge added' : ''
					}`}
					style={{
						backgroundImage: `url(${process.env.REACT_APP_CDN_URL}${hardware.name
							.toLowerCase()
							.replace(' ', '_')
							.replace(/[^a-z0-9_-]/gi, '')}/thumbnail.png)`,
					}}
					onClick={onViewEquipmentDetail(hardware.equipmentId)}
				></button>
				<div className="equipment-card__body">
					<button
						className="equipment-card__title spc--bottom--sml"
						onClick={onViewEquipmentDetail(hardware.equipmentId)}
					>
						{hardware.name}
					</button>
					<p className="equipment-card__description spc--bottom--lrg">
						{hardware.shortDescription || hardware.description}
					</p>
					<div className="flex--secondary flex--gap--med">
						<p className="equipment-card__amount">{formatCurrency(price + accessoriesTotal)}</p>
						{merchantHardware?.isSelected && (
							<button className="btn btn--sml btn--tertiary" onClick={hardwareSelectToggle(hardware.equipmentId)}>
								Remove
							</button>
						)}
					</div>
				</div>
			</div>
		);
	}
);
HardwareCard.propTypes = {
	hardware: PropTypes.object.isRequired,
	merchantHardware: PropTypes.object,
	accessoriesTotal: PropTypes.number,
	price: PropTypes.number,
	outOfStock: PropTypes.bool,
	hardwareSelectToggle: PropTypes.func.isRequired,
	onViewEquipmentDetail: PropTypes.func.isRequired,
};

export default HardwareCard;
