import React, { useState } from 'react';
import PropTypes from 'prop-types';

const HiddenFilterComponent = ({ handleChange, exclusiveOnTrue }) => {
	const [filterTerm, setFilterTerm] = useState(false);

	const handleButtonClick = () => {
		const newVal = !filterTerm;
		setFilterTerm(newVal);
		handleChange(String(newVal));
	};

	return (
		<button
			onClick={handleButtonClick}
			className="btn btn--sml btn--tertiary"
			title={filterTerm ? 'Hide hidden accounts' : 'Show hidden accounts'}
		>
			<i className="icon icon--tny icon--view--text" />
			<span>Show/Hide</span>
		</button>
	);
};

HiddenFilterComponent.defaultProps = {
	exclusiveOnTrue: false,
};

HiddenFilterComponent.propTypes = {
	handleChange: PropTypes.func.isRequired,
	exclusiveOnTrue: PropTypes.bool,
};

export default HiddenFilterComponent;
