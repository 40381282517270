import React, { Fragment, createRef } from 'react';
import AchBaseClass from './AchBaseClass';
import { withLoader } from '../../common/components';
import { bool, func } from 'prop-types';
import { appService, signatureService } from '../../services';
import { defaultInvalidDates, identifyFullStoryWithToken } from '../../common/utilities';
import {
	toLower,
	split,
	isObject,
	each,
	cloneDeep,
	some,
	set,
	includes,
	filter,
	orderBy,
	groupBy,
	keys,
	join,
	map,
	startCase,
	every,
	get
} from 'lodash';
import { decode } from 'jsonwebtoken';
import { Notification } from '../../common/components/notifications';
import AppSummary from './AppSummary';
import { Toggle, ToggleContainer } from '../../common/components/toggle';
import ClickWrapComponent from './../EApp/Clickwrap';
import { Schema, merchantMPATemplate, generalTransactionInfo } from '../../validation';
import { defaultImplicitParse, defaultReactOutput } from 'simple-markdown';
import { ModalWrapper } from '../../common/components/modal-wrapper';
import { AchPlanTable, isFeePercentage } from '../Equipment/ach-plan-helpers';

merchantMPATemplate.generalTransactionInfo = generalTransactionInfo;

const conditionalRequiredFields = {
	enableSecondaryBankAccountInfo: {
		condition: state => state['enableSecondaryBankAccountInfo'],
		fields: [
			'bankAccountInformation.secondaryBankName',
			'bankAccountInformation.secondaryAccountNumber',
			'bankAccountInformation.secondaryRoutingNumber',
		],
	},
	alternateBankAccountInfo: {
		condition: state => state['enableAlternativeBankAccountInfo'],
		fields: [
			'bankAccountInformation.alternateBankName',
			'bankAccountInformation.alternateAccountNumber',
			'bankAccountInformation.alternateRoutingNumber',
		],
	},
};

const requiredDocs = ['BankStatements', 'ProofOfAddress', 'VoidedCheck', 'DriversLicense'];

const upnKey = 'upn';

function getRequiredFieldsFromToken(token) {
	const { payload } = decode(token, { complete: true });
	if (!payload[upnKey]) return [];
	const upnData = split(payload[upnKey], '|');
	return upnData;
}

function getFlattenedKeys(object, parentKey = '') {
	const flattenedKeys = [];
	if (parentKey && object && (object.type || object.message)) return flattenedKeys;
	each(object, (val, key) => {
		if (!isObject(val)) return;
		const newKey = parentKey ? `${parentKey}.${key}` : key;
		flattenedKeys.push(newKey);
		flattenedKeys.push(...getFlattenedKeys(val, newKey));
	});
	return flattenedKeys;
}

class AchMerchantMpaComponent extends AchBaseClass {
	constructor(props) {
		super(props, [], requiredDocs, conditionalRequiredFields);
		const token = props.location.search.substr(1);
		identifyFullStoryWithToken(token);
		const requiredFields = [
			...getRequiredFieldsFromToken(token),  
			'generalTransactionInfo.maxPeriod',
			'generalTransactionInfo.maxPeriodAmount',
			'generalTransactionInfo.maxDaily',
			'generalTransactionInfo.maxDailyAmount',
			'generalTransactionInfo.maxSingle',
			'generalTransactionInfo.averageTransactionAmount'
		];
		const template = cloneDeep(merchantMPATemplate);
		const flattenedKeys = getFlattenedKeys(template);
		each(requiredFields, key => {
			const lowerKey = toLower(key);
			const matchedKeys = filter(
				flattenedKeys,
				flattenedKey =>
					includes(toLower(flattenedKey), lowerKey) &&
					!includes(toLower(flattenedKey), 'corporateaddress') &&
					!some(
						[
							'secondarybankname',
							'alternatebankname',
							'alternateaccountnumber',
							'secondaryaccountnumber',
							'secondaryroutingnumber',
							'alternateroutingnumber',
						],
						field => includes(toLower(flattenedKey), field)
					)
			);
			const groupedKeys = groupBy(matchedKeys, matchedKey => split(matchedKey, '.').length);
			const sortedKeys = orderBy(keys(groupedKeys), [groupKey => parseInt(groupKey, 10)], ['desc']);
			const mappedKeys = [];
			each(sortedKeys, sortedKey => {
				each(groupedKeys[sortedKey], fieldKey => {
					if (some(mappedKeys, mappedKey => includes(mappedKey, fieldKey))) return;
					mappedKeys.push(fieldKey);
					requiredFields.push(fieldKey);
					set(template, `${fieldKey}.required`, true);
				});
			});
		});
		requiredFields.push('dba');
		this.mpaSchema = new Schema(template, { strip: false, typecast: true });
		this.requiredFields = requiredFields;

		this.state = {
			downloadUrl: '',
			ip: '',
			timestamp: '',
			...this.initialState,
			token: this.props.location.search.substr(1),
			initialLoadingInProgress: true,
		};
		this.notificationRef = createRef();
	}

	async componentDidMount() {
		await this.fetchAndSetData();
	}

	save = () => {
		const mpa = cloneDeep(this.state.fields);
		const { enableSecondaryBankAccountInfo, enableAlternativeBankAccountInfo } = this.state;
		mpa.signature = {
			ip: this.state.ip,
			timestamp: this.state.timestamp,
		};

		const errorList = this.mpaSchema.validate(
			Object.assign({}, { ...mpa, enableSecondaryBankAccountInfo, enableAlternativeBankAccountInfo })
		);
		const errorListPaths = errorList.map(e => e.path);
		if (errorList.length > 0 || this.validateDocuments().length > 0) {
			this.setState({ errorList, errorListPaths, showValidation: true });
			return;
		}

		this.props.showLoader(true);

		return signatureService
			.prepareSignatureFileForTransfer(this.state.fields, ['ClickwrapMPA'], this.state.downloadUrl)
			.then(fileForTransfer => appService.saveAchEApp(mpa, fileForTransfer))
			.then(() => {
				return appService
					.prefillDocumentFields(this.state.fields, 'ACH_application_worksheet', this.achWorksheetFieldNames, false)
					.then(blob => {
						const prefilledMpa = { file: new File([blob], `mpa_${this.state.appId}.pdf`), fileDescription: '' };
						return new Promise(resolve => {
							this.setState(
								{
									localFiles: {
										...this.state.localFiles,
										Other: [prefilledMpa],
									},
								},
								resolve
							);
						});
					});
			})
			.then(this.saveFiles)
			.then(() => {
				this.props.showLoader(false);
				const redirectPage = 'thankyou?noSign=true';
				this.props.history.push(`/merchant/${redirectPage}`);
			})
			.catch(err => {
				this.handleError(err);
			});
	};

	renderUploadSignedMpa() {
		return null;
	}

	renderSendFormToMerchant() {
		return null;
	}

	fetchData = async () => {
		const token = this.props.location.search.substr(1);
		const mpa = await appService.getEAppForMerchant(token);
		return mpa;
	};
	fetchAndSetData = async () => {
		let mpa = null;
		try {
			this.props.showLoader(true);
			mpa = await this.fetchData();
			if (get(mpa, 'signatureDoc.status') === 'Signed') {
				this.setState({ errorMessageOnLoad: 'Signature documents have already been submitted successfully' });
			} else {
				const secondaryBankInfo = [
					mpa.bankAccountInformation.secondaryBankName,
					mpa.bankAccountInformation.secondaryAccountNumber,
					mpa.bankAccountInformation.secondaryRoutingNumber,
				];
				const alternateBankInfo = [
					mpa.bankAccountInformation.alternateBankName,
					mpa.bankAccountInformation.alternateAccountNumber,
					mpa.bankAccountInformation.alternateRoutingNumber,
				];
				const enableSecondaryBankAccountInfo = some(secondaryBankInfo, val => val);
				const enableAlternativeBankAccountInfo = some(alternateBankInfo, val => val);
				const sameAsBusiness = every(mpa.corporateAddress, (value, key) => {
					return toLower(value) === toLower(mpa.businessInformation.businessAddress[key]);
				});
				this.setState(
					{
						enableSecondaryBankAccountInfo,
						enableAlternativeBankAccountInfo,
						sameAsBusiness,
						fields: defaultInvalidDates(mpa, ''),
						appId: mpa.appId,
						initialLoadingInProgress: false,
					}, this.createFormSnapshot
				);
			}
			this.props.showLoader(false);
		} catch (err) {
			let errorMessageOnLoad = 'An error occurred: ' + err;
			if (toLower(err) === 'unauthorized' || err == 401) {
				const token = this.props.location.search.substr(1);
				if (decode(token, { complete: true }).payload.actort) {
					errorMessageOnLoad =
						'The link you followed has expired. To complete your application, please reach out to your agent to request a new eApp link.';
				}
			}
			this.setState({ errorMessageOnLoad, initialLoadingInProgress: false }, () => {
				this.props.showLoader(false);
			});
		}

		try {
			if(mpa?.appId){
				const token = this.props.location.search.substr(1);
				const { merchantPlanFees } = await appService.getMerchantAchPlanFees(mpa.appId, token)
				map(merchantPlanFees, fee => {
					const isPercentage = isFeePercentage(fee.feeName);
					if(isPercentage){
						fee.agentCost = fee.agentCost * 100;
						fee.merchantPrice = fee.merchantPrice * 100;
					}
					return fee;
				})
				this.setState({fees: merchantPlanFees})
			}
		} catch (error) {}
	};

	renderUploadDocuments = () => {
		return (
			<div className="card__body">
				{this.renderUploadDocumentRow(
					'BankStatements',
					'Bank Statements',
					'Please include your three most recent bank statements',
					true,
					'bankstatements_div'
				)}
				{this.renderUploadDocumentRow('VoidedCheck', 'Voided Check', 'Or Bank Letter', true, 'voidedcheck_div')}
				{this.renderUploadDocumentRow(
					'ProofOfAddress',
					'Proof of Address',
					'Utility bill or Lease Agreement',
					true,
					'proofofaddress_div'
				)}
				{this.renderUploadDocumentRow('DriversLicense', 'Drivers License', null, true, 'driverslicense_div')}
			</div>
		);
	};
	
	renderFees = () => {
		const {fees} = this.state;
		return (
			<AchPlanTable {...{ fees, isDisabled: true, handleFeeChange: this.handleFeeChange, hideAgentCost: true, hideTableHead: true }} />
		);
	};

	renderRep2Field = () => {};
	renderHasExistingFidelityAccount = () => {};

	renderFooter() {
		const { downloadUrl, showValidation, errorList, errorListPaths, fields } = this.state;
		const incompleteDocs = this.validateDocuments();
		const disableConfirmAndSignButton =
			!downloadUrl || (showValidation && (errorListPaths.length > 0 || incompleteDocs.length > 0));
		const confirmationErrors = [];
		if (incompleteDocs) {
			confirmationErrors.push('Upload required files to save and proceed.');
		}
		if (!downloadUrl) {
			confirmationErrors.push('Terms and Conditions need to be acknowledged before you can save and proceed.');
		}
		const tooltip = join(confirmationErrors, '\n');
		return (
			<React.Fragment>
				<ClickWrapComponent
					groupKey="group-profitstars"
					appId={fields.appId.toString()}
					setInfoToApp={this.setInfoToApp}
					handleError={this.handleSubmitError}
					allowDisagreed={true}
				/>

				{showValidation && (errorListPaths.length > 0 || incompleteDocs.length > 0) ? (
					<ul className="type--validation__wrapper spc--bottom--med">
						{errorList.map(elem => {
							return (
								<li key={elem.path} className="type--validation">
									<button
										onClick={() => this.onErrorClick(elem)}
										onKeyDown={e => this.onEnterKeyDownHandler(e, () => this.onErrorClick(elem))}
									>
										{defaultReactOutput(defaultImplicitParse(elem.message))}
									</button>
								</li>
							);
						})}
						{map(incompleteDocs, (fileTag, index) => (
							<li className="type--validation" key={`${fileTag}.${index}`}>
								<button
									onKeyDown={e =>
										this.onEnterKeyDownHandler(e, () => this.scrollTo(toLower(fileTag).replace(/[.]/g, '_') + '_div'))
									}
									onClick={() => this.scrollTo(toLower(fileTag).replace(/[.]/g, '_') + '_div')}
								>
									<span className="type--wgt--medium">{startCase(fileTag)} document is required</span>
								</button>
							</li>
						))}
					</ul>
				) : null}
				<div className="flex--primary flex--right spc--top--lrg">
					<div className="datatooltip--top-left" data-tooltip={disableConfirmAndSignButton ? tooltip : null}>
						<button
							className="btn btn--primary btn--med"
							onClick={this.save}
							disabled={disableConfirmAndSignButton || this.props.isLoading}
						>
							Confirm
						</button>
					</div>
				</div>
			</React.Fragment>
		);
	}

	setInfoToApp = async (downloadUrl, ip, timestamp) => {
		this.setState({ downloadUrl, ip, timestamp });
	};

	handleSubmitError = e => {
		this.setState({ error: e });
	};

	renderToggleableSection(title, component, tooltip = null, initialToggle = true) {
		return (
			<Toggle initialToggle={initialToggle}>
				{({ isToggled, handleToggle }) => {
					return (
						<Fragment>
							<button
								onKeyDown={e => this.onEnterKeyDownHandler(e, handleToggle)}
								onClick={handleToggle}
								className="card__header card__header--expandable"
							>
								<div className="flex--primary flex--gap--sml">
									<h5>{title}</h5>
									{tooltip && (
										<i className="icon icon--sml icon--regular--info datatooltip--200" data-tooltip={tooltip}></i>
									)}
								</div>
								<i className={`icon icon--lrg icon--chevron--${isToggled ? 'top' : 'right'}--primary`}></i>
							</button>
							{isToggled && component}
						</Fragment>
					);
				}}
			</Toggle>
		);
	}
	render() {
		const { fields, errorMessageOnLoad, initialLoadingInProgress, modal, fees } = this.state;
		const { isLoading } = this.props;

		if (errorMessageOnLoad) {
			return (
				<div id="main-div" className="l--content l--content--lrg">
					<div className="type--validation spc--bottom--lrg">{errorMessageOnLoad}</div>
				</div>
			);
		}

		if (initialLoadingInProgress) {
			return null;
		}

		return (
			<div id="main-div" className="w--max--880">
				{this.renderErrors()}
				<Notification ref={this.notificationRef} />
				<ModalWrapper modal={modal} onModalClose={this.openCloseModal} />
				<fieldset disabled={isLoading}>
					<AppSummary app={fields} isEappWebForm={false} showLeadDetailsLink={false} />
					<div>
						<ToggleContainer>
							<div className="card spc--bottom--lrg">
								{this.renderToggleableSection('Business Information', this.renderBusinessInfo())}
							</div>
							<div className="card spc--bottom--lrg">
								{this.renderToggleableSection('Signer Information', this.renderSignerInformation())}
							</div>
							<div className="card spc--bottom--lrg">
								{this.renderToggleableSection('Banking Information', this.renderBankingInfo())}
							</div>
							<div className="card spc--bottom--lrg">
									{this.renderToggleableSection('Additional Transaction Details', this.renderAdditionalTransactionInformation())}
								</div>
							<div className="card spc--bottom--lrg">
								{this.renderToggleableSection('Mailing Address', this.renderMailingInfo())}
							</div>
							<div className="card spc--bottom--lrg">
								{this.renderToggleableSection(
									'Upload documents',
									this.renderUploadDocuments(),
									'This will be visible to merchants'
								)}
							</div>
							<div className="card spc--bottom--lrg">
								{this.renderToggleableSection(
									'Other Notes',
									this.renderOtherNotes(),
									'This will be visible to merchants'
								)}
							</div>
							{fees?.length > 0 && <div className="card spc--bottom--lrg">
								{this.renderToggleableSection(
									'Fees',
									this.renderFees(),
									'',
									false
								)}
							</div>}
						</ToggleContainer>
						{this.renderFooter()}
					</div>
				</fieldset>
			</div>
		);
	}
}

AchMerchantMpaComponent.propTypes = {
	isLoading: bool,
	showLoader: func.isRequired,
};

export default withLoader(AchMerchantMpaComponent);
