import React, { createRef } from 'react';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
import { withLoader } from '../../common/components';
import withBlock from '../../common/components/block/block-hoc';
import { IdentificationTypeDropdownComponent } from '../../common/components/identification-type-dropdown';
import { CanadaMerchantMPATemplate, CanadaMPAInvalidTemplate, CanadaMPARequiredTemplate } from '../../validation';
import BaseMPA from './BaseMPA';
import withMerchantMpa from './withMerchantMpa';
import withMpa from './withMpa';
import SingleDatePicker from '../../common/filters/date-picker/single-date-picker';
import moment from 'moment';

class CanadaMPA extends BaseMPA {
	identificationIssueDateRef = createRef();
	identificationExpirationDateRef = createRef();

	renderRoutingNumber(mpa) {
		return (
			<div className="col col-sml-12">
				<div className="row ">
					{this.renderField(
						mpa,
						'bankAccountInformation.institutionNumber',
						'Institution Number',
						{ maxLength: 3 },
						'fs-mask'
					)}
					{this.renderField(mpa, 'bankAccountInformation.transitNumber', 'Transit Number', { maxLength: 5 }, 'fs-mask')}
				</div>
			</div>
		);
	}

	renderAdditionalPaymentDetails(mpa, isToggled, handleToggle) {
		return (
			<div className={`card${isToggled ? ' is-expanded' : ''} spc--bottom--lrg`}>
				{this.renderCardHeader('Additional Payment Details', isToggled, handleToggle)}
				{isToggled && (
					<div className="card__body">
						<div className="row">{this.renderAmexEsa(mpa)}</div>
					</div>
				)}
			</div>
		);
	}

	renderIdentificationInfo(mpa) {
		return (
			<div className="col col-sml-12">
				<div className="row">
					<div className="col col-sml-12 col-med-6 form__group">
						<div className="form__group__header">
							<p className="form__group__label">Identification Type</p>
							{this.props.requiredFields['IdentificationType'] && (
								<span className="form__group__required" data-tooltip="Required">
									*
								</span>
							)}
							<IdentificationTypeDropdownComponent
								className={`input input--med input--select${this.props.validateField(
									'signerInformation.identificationType'
								)}`}
								id="signerInformation_identificationType"
								value={mpa.signerInformation.identificationType}
								onChange={this.props.isMerchant ? this.props.handleChange : this.props.handleIdNumberAndTypeChange}
							/>
						</div>
					</div>
					<div className="col col-sml-12 col-med-6 form__group">
						<div className="form__group__header">
							<p className="form__group__label">Identification Number </p>
						</div>
						<input
							type="text"
							className={`input input--med${this.props.validateField('signerInformation.identificationNumber')}`}
							name="signerInformation_identificationNumber"
							value={mpa.signerInformation.identificationNumber}
							onChange={this.props.isMerchant ? this.props.handleChange : this.props.handleIdNumberAndTypeChange}
						/>
					</div>
					{this.renderIdentificationIssueDate(mpa, this.props.requiredFields['IdentificationIssueDate'])}
					{this.renderIdentificationExpirationDate(mpa, this.props.requiredFields['IdentificationExpirationDate'])}
				</div>
			</div>
		);
	}

	renderIdentificationIssueDate(mpa, required) {
		return (
			<div id="signerInformation_identificationIssueDate_div" className="col col-sml-12 col-med-6 form__group">
				<div className="form__group__header">
					<p className="form__group__label">Identification Issue Date</p>
					{required && <span className="form__group__required">*</span>}
					<i
						className="icon icon--tny icon--regular--info datatooltip--right datatooltip--200"
						data-tooltip={'Issue Date must precede Expiration Date'}
					></i>
				</div>
				<SingleDatePicker
					name={'signerInformation_identificationIssueDate'}
					onChange={date =>
						this.props.handleChange({ target: { name: 'signerInformation_identificationIssueDate', value: date } })
					}
					validateField={() => this.props.validateField('signerInformation.identificationIssueDate')}
					value={get(mpa, 'signerInformation.identificationIssueDate', '')}
					disabledDays={{
						after:
							get(mpa, 'signerInformation.identificationExpirationDate', null) &&
							moment(get(mpa, 'signerInformation.identificationExpirationDate', null))
								.subtract(1, 'day')
								.toDate(),
					}}
				/>
			</div>
		);
	}

	renderIdentificationExpirationDate(mpa, required) {
		return (
			<div className="col col-sml-12 col-med-6 form__group">
				<div id="signerInformation_identificationExpirationDate_div" className="form__group__header">
					<p className="form__group__label">Identification Expiration Date </p>
					{required && (
						<span className="form__group__required" data-tooltip="Required">
							*
						</span>
					)}
				</div>
				<SingleDatePicker
					name={'signerInformation_identificationExpirationDate'}
					onChange={date =>
						this.props.handleChange({
							target: { name: 'signerInformation_identificationExpirationDate', value: date },
						})
					}
					validateField={() => this.props.validateField('signerInformation.identificationExpirationDate')}
					value={get(mpa, 'signerInformation.identificationExpirationDate', '')}
					disabledDays={{
						before:
							get(mpa, 'signerInformation.identificationIssueDate', null) &&
							moment(get(mpa, 'signerInformation.identificationIssueDate', null))
								.add(1, 'day')
								.toDate(),
					}}
				/>
			</div>
		);
	}

	renderSocialSecurityNumberLabel() {
		return 'Social Insurance Number';
	}

	isSSNRequired(mpa) {
		const missingIdInfo =
			!mpa.signerInformation.identificationIssueDate &&
			!mpa.signerInformation.identificationExpirationDate &&
			!mpa.signerInformation.identificationNumber &&
			!mpa.signerInformation.identificationType;
		return this.props.requiredFields['signerInformation.socialSecurityNumber'] && missingIdInfo ? ' required' : '';
	}
}

export const CanadaAgentMPA = withBlock(
	withLoader(
		withMpa(CanadaMPA, CanadaMPAInvalidTemplate, CanadaMPARequiredTemplate, {
			'bankAccountInformation.institutionNumber': true,
			'bankAccountInformation.transitNumber': true,
		})
	)
);
export const CanadaMerchantMPA = withRouter(withLoader(withMerchantMpa(CanadaMPA, CanadaMerchantMPATemplate)));
