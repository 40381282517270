import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import { trim, toLower, split, includes } from 'lodash';
import { decode } from 'jsonwebtoken';

import { appService, principalService } from '../../services';
import { withContext, withLoader } from '../../common/components';
import { parseError, logClientError } from '../../common/utilities';
import { withCancelable } from '../../common/components/cancelable';
import { PasswordInput } from '../../common/components/password-input';
import PaymentPointsUpdateContext from '../../common/components/user-account-panel/PaymentPointsUpdateContext';
import UserContext from './UserContext';
import { Notification } from '../../common/components/notifications';
import { getRedirectRoute } from './loginUtils';
import { logLoginAttempt } from '../../common/utilities/loginAttemptsHandler';
import PrivacyPolicy from '../privacy-policy/PrivacyPolicy';

const { REACT_APP_AGENT_SUPPORT_EMAIL } = process.env;

class LoginComponent extends Component {
	constructor(props) {
		super(props);
		this.usernamePersistenceKey = 'prsstnm';
		let isInactive = props?.location?.state?.inactivity || false;
		let username = props?.location?.state?.username || '';

		this.state = {
			username: username,
			password: '',
			errorMessage: null,
			inactivityWarning: isInactive,
			rememberUser: '',
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}
	componentDidMount() {
		const persistedUserName = this.getPersistedUsername();
		this.setState({
			rememberUser: persistedUserName.length > 1,
		});
	}
	componentWillMount() {
		let userObj = principalService.get();
		let isExpired = false;
		if (!!userObj) {
			const token = userObj.token;
			isExpired = this.isTokenExpired(token);

			if (isExpired) {
				//console.log('expired');

				// clear all info so can check after if refreshed successfully
				userObj = null;

				// try to get a new token using the cognito authentication token
				this.refreshLoginToken().then(refreshed => {
					if (refreshed) {
						userObj = principalService.get();

						if (!!userObj && userObj.agentId > 0) this.redirect();
					} else {
						appService.logout();
					}
				});
			}
		} // had user object
		else {
			// try to refresh login token in case somehow there is no token from api but cognito token is present
			//console.log('no user obj');

			this.refreshLoginToken().then(refreshed => {
				//console.log('refreshed='+refreshed);
				if (refreshed) {
					userObj = principalService.get();
					this.props.getAgentPoints();
				}
				if (!!userObj && userObj.agentId > 0) this.redirect();
				else appService.logout();
			});
		}
	}

	toggleRememberMe = event => {
		this.setState({ rememberUser: event.target.checked });
	};

	getPersistedUsername = () => {
		let persistedUserName = localStorage.getItem(this.usernamePersistenceKey) || '';
		return persistedUserName.length > 1 ? persistedUserName : '';
	};

	isTokenExpired(token) {
		const decodedToken = decode(token, { complete: true });
		const dateNow = Math.floor(Date.now() / 1000);
		return decodedToken.payload.exp < dateNow;
	}

	refreshLoginToken() {
		this.props.showLoader(true);
		return Auth.currentAuthenticatedUser()
			.then(user => {
				//console.log('here');
				//console.log(user);
				if (user) {
					const token =
						(user &&
							user.signInUserSession &&
							user.signInUserSession.idToken &&
							user.signInUserSession.idToken.jwtToken) ||
						false;
					let username = user.attributes && user.attributes.email;
					if (token && username) {
						return appService
							.login(token, username)
							.then(() => {
								this.props.showLoader(false);
								this.props.getAgentPoints();
								return true;
							})
							.catch(err => {
								this.props.showLoader(false);
								return false;
							});
					} else {
						//console.log('no cognito token');
						this.props.showLoader(false);
						return false;
					}
				} else {
					//console.log('no cognito user');
					this.props.showLoader(false);
					return false;
				}
			})
			.catch(err => {
				//console.log('No authenticated user...will redirect');
				this.props.showLoader(false);
				return false;
			});
	}

	setErrorMessage = errorMessage => {
		this.setState({ errorMessage });
	};

	handleChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}

	async handleSubmit(event) {
		event.preventDefault();
		const { history, isLoading } = this.props;
		if (isLoading) {
			return;
		}
		const { password } = this.state;
		let { username, rememberUser } = this.state;
		username = trim(toLower(username));

		this.setState({ errorMessage: '' });
		this.props.showLoader(true);
		logLoginAttempt(username);

		let user;
		try {
			user = await Auth.signIn(username, password);
		} catch (err) {
			//eslint-disable-next-line
			console.log('signIn error', err);
			let message;
			switch (err.code) {
				case 'InvalidParameterException': {
					if (toLower(err.message) === 'custom auth lambda trigger is not configured for the user pool.') {
						message = 'You need to enter a password.';
					} else {
						message = err.message;
					}
					break;
				}
				case 'UserNotConfirmedException': {
					history.push({
						pathname: '/confirm-registration',
						state: { username: username },
					});
					break;
				}
				case 'PasswordResetRequiredException': {
					this.notification.addNotification({
						message: `As part of our ongoing efforts to ensure your account's security, your password has been scheduled to be reset. We just sent you an email with a secure code. On the next screen, you will be required to enter the code, and then set your new password.	If you do not receive the email code, click on “Resend Code”. To keep your account safe and secure, make sure not to reuse old passwords!`,
						success: false,
						forceCloseHandler: true,
						onClose: async () => {
							await Auth.forgotPassword(username);
							history.push({
								pathname: '/confirm-new-password',
								state: { username: username },
							});
						},
					});
					break;
				}
				case 'NotAuthorizedException':
				case 'UserNotFoundException': {
					if (includes(toLower(err.message), 'security')) {
						message =
							'We cannot continue with your login at this time. Please check your email to confirm this login and then try again.';
					} else {
						message = 'The username/password provided is incorrect.';
					}
					break;
				}
				default: {
					message = 'Something went wrong. Please try again.';
					break;
				}
			}
			this.setState({ errorMessage: message });
			this.props.showLoader(false);
			return;
		}
		// Force change password
		if (user && user.challengeName && user.challengeName === 'NEW_PASSWORD_REQUIRED') {
			this.props.showLoader(false);
			history.push({
				pathname: '/change-password',
				state: { username: username },
			});
			return;
		} else if (
			user &&
			user.challengeName &&
			(user.challengeName === 'SOFTWARE_TOKEN_MFA' || user.challengeName === 'SMS_MFA')
		) {
			this.props.showLoader(false);
			UserContext.setUser(user);
			history.push({
				pathname: '/confirm-mfa',
				state: {
					username,
					password,
					challengeName: user.challengeName,
					usernamePersistenceKey: this.usernamePersistenceKey,
					rememberUser,
					challengeDestination:
						user.challengeParam.CODE_DELIVERY_DESTINATION || user.challengeParam.FRIENDLY_DEVICE_NAME,
				},
			});
			return;
		} else {
			const token =
				(user && user.signInUserSession && user.signInUserSession.idToken && user.signInUserSession.idToken.jwtToken) ||
				false;
			if (token) {
				try {
					const auth = await this.props.makePendingRequest(appService.login(token, username));

					this.props.showLoader(false);
					this.props.getAgentPoints();
					if (auth.token) {
						rememberUser
							? localStorage.setItem(this.usernamePersistenceKey, username)
							: localStorage.removeItem(this.usernamePersistenceKey);
						this.redirect();
					} else {
					}
				} catch (err) {
					if (err && err.isCanceled) {
						return;
					}
					const { stack } = parseError(err);
					this.props.showLoader(false);
					//eslint-disable-next-line
					console.log('Login error', err);
					if (err && err.ex && err.ex.response && (err.ex.response.status === 401 || err.ex.response.status === 403)) {
						this.setState({
							errorMessage: 'You are not authorized to access the page. Contact customer support: cs@cardknox.com',
						});
					} else if (err && err.startsWith && err.startsWith('User authenticated but not found')) {
						this.setState({
							errorMessage: (
								<span>
									Email address is not registered for the Partner Portal. For assistance, reach out to{' '}
									<a className="btn btn--link type--underline" href={`mailto:${REACT_APP_AGENT_SUPPORT_EMAIL}`}>
										Agent Support.
									</a>
								</span>
							),
						});
					} else {
						this.setState({ errorMessage: logClientError(stack) });
					}
				}
			} else {
				this.setState({
					errorMessage: 'You are not authorized to access the page. Contact customer support: cs@cardknox.com',
				});
				this.props.showLoader(false);
			}
		}
	}

	async redirect() {
		const { history, location } = this.props;
		let redirectUrl = getRedirectRoute();
		let search = '';
		let additionalState;

		// hide loader before redirect
		this.props.showLoader(false);

		if (location.state && location.state.returnUrl) {
			[redirectUrl, search] = split(location.state.returnUrl, '?');
		}

		history.push({
			pathname: redirectUrl,
			search,
			...(additionalState || {}),
		});
	}

	redirectToRegister = () => {
		const { history } = this.props;
		history.push('/register');
	};

	redirectToForgotPassword = () => {
		const { history } = this.props;
		history.push('/forgot-password');
	};

	render() {
		const { username, password, errorMessage, inactivityWarning } = this.state;

		return (
			<React.Fragment>
				<form className="auth__form" onSubmit={this.handleSubmit}>
					<h2 className="auth__form__title">Sign in to SOLA Partner Portal!</h2>
					{inactivityWarning ? (
						<p className="type--color--error spc--bottom--sml">Your session has expired. Please sign in again.</p>
					) : null}
					<div className="form__group">
						<div className="form__group__header">
							<span className="form__group__label">Email</span>
						</div>
						<input
							name="username"
							type="text"
							className="input input--med"
							placeholder="user@email.com"
							value={username}
							onChange={this.handleChange}
							autoFocus
							tabIndex="0"
						/>
					</div>
					<div className="form__group">
						<div className="form__group__header">
							<span className="form__group__label">Password</span>
						</div>
						<PasswordInput
							value={password}
							onChange={this.handleChange}
							tabIndex="0"
							setErrorMessage={this.setErrorMessage}
						/>
						{errorMessage ? <p className="spc--top--sml spc--bottom-med type--validation">{errorMessage}</p> : null}
					</div>
					<div className="flex--secondary flex--gap--sml--alt spc--bottom--xlrg">
						<div>
							<input
								onChange={this.toggleRememberMe}
								type="checkbox"
								id="rememberuser"
								className="input--check"
								tabIndex="0"
								checked={this.state.rememberUser}
							></input>
							<label htmlFor="rememberuser">Remember me</label>
						</div>
						<button onClick={this.redirectToForgotPassword} className="btn btn--link" type="button">
							Forgot your password?
						</button>
					</div>
					<button
						disabled={this.props.isLoading}
						type="submit"
						className="btn btn--primary btn--lrg spc--bottom--lrg--alt"
						tabIndex="0"
					>
						Sign in
					</button>
					<div className="auth__form__create-pass">
						<p>Need a login?</p>{' '}
						<button
							type="button"
							onClick={this.redirectToRegister}
							className="btn btn--sml btn--link btn--link--underline"
						>
							Create your password
						</button>
					</div>
				</form>

				<PrivacyPolicy showCopyright />

				<Notification ref={el => (this.notification = el)} />
			</React.Fragment>
		);
	}
}

LoginComponent.propTypes = {
	location: PropTypes.object,
	history: PropTypes.object,
	isLoading: PropTypes.bool,
	showLoader: PropTypes.func,
	makePendingRequest: PropTypes.func,
};

export default withCancelable(withLoader(withContext(LoginComponent, PaymentPointsUpdateContext, 'getAgentPoints')));
