import React, { Component } from 'react';
import { NumericFormat as NumberFormat } from 'react-number-format';
import PropTypes from 'prop-types';
import { map, toLower, filter, get, noop, includes } from 'lodash';

export const filterCheck21TransactionFees = fees =>
	filter(
		fees,
		fee =>
			(toLower(fee.feeType) === 'transactionfee' || toLower(fee.feeType) === 'discountfee') &&
			fee.achTransactionType == 'Check21'
	);
export const filterAchTransactionFees = fees =>
	filter(
		fees,
		fee =>
			(toLower(fee.feeType) === 'transactionfee' || toLower(fee.feeType) === 'discountfee') &&
			fee.achTransactionType == 'Ach'
	);
export const filterMonthlyFees = fees => filter(fees, fee => toLower(fee.feeType) === 'monthlyfee');
export const filterSetupFees = fees => filter(fees, fee => toLower(fee.feeType) === 'onetimefee');
export const isFeePercentage = feeName => includes(feeName, '%');

export class AchPlanTable extends Component {
	renderFeeHeader = feeType => {
		const { hideAgentCost } = this.props;
		return (
			<tr className="table--plans__subheader">
				<th id={feeType.split(' ').join('')}>{feeType}</th>
				{!hideAgentCost && <th>Agent Cost:</th>}
				<th>Merchant Cost:</th>
			</tr>
		);
	};
	renderFeesForType = fees => {
		const { isDisabled, handleFeeChange, hideAgentCost } = this.props;
		return (
			<React.Fragment>
				{map(fees, fee => {
					const feeName = get(fee, 'feeName', '');
					const feeId = get(fee, 'feeId', '');
					const merchantPrice = get(fee, 'merchantPrice', 0);
					const agentCost = get(fee, 'agentCost', 0);
					const isPercentage = isFeePercentage(feeName);
					const formatting = {
						prefix: isPercentage ? '' : '$',
						suffix: isPercentage ? '%' : ''
					}
					return (
						<tr key={feeId}>
							<td>{feeName}</td>
							{!hideAgentCost && <td>
								<NumberFormat
									disabled={true}
									{...formatting}
									decimalScale={2}
									value={agentCost}
									id={`agentCost_${feeId}`}
									className="input input--med type--center"
								/>
							</td>}
							<td>
								<NumberFormat
									{...formatting}
									decimalScale={2}
									value={merchantPrice}
									disabled={isDisabled}
									fixedDecimalScale={false}
									id={`merchantPrice_${feeId}`}
									className="input input--med type--center"
									onValueChange={e => handleFeeChange(feeId, e)}
								/>
							</td>
						</tr>
					);
				})}
			</React.Fragment>
		);
	};
	render(){
		const { fees, hideTableHead } = this.props;
		if (fees.length === 0) {
			return null;
		}
		const monthlyFees = filterMonthlyFees(fees);
		const setupFees = filterSetupFees(fees);
		const check21TransactionFees = filterCheck21TransactionFees(fees);
		const achTransactionFees = filterAchTransactionFees(fees);

		return <div className="table--plans__wrapper spc--bottom--lrg">
			<table className="table table--plans">
				{!hideTableHead && <thead>
					<tr>
						<th colSpan={3} className="table--plans__header">
							<p>Fees</p>
						</th>
					</tr>
				</thead>}
				<tbody>
					{this.renderFeeHeader('Monthly Fees')}
					{this.renderFeesForType(monthlyFees)}
					{this.renderFeeHeader('Setup Fees')}
					{this.renderFeesForType(setupFees)}
					{this.renderFeeHeader('Check 21 Fees')}
					{this.renderFeesForType(check21TransactionFees)}
					{this.renderFeeHeader('ACH Fees')}
					{this.renderFeesForType(achTransactionFees)}
				</tbody>
			</table>
		</div>
	}
}
AchPlanTable.propTypes = {
	fees: PropTypes.array,
	isDisabled: PropTypes.bool,
	handleFeeChange: PropTypes.func,
	hideAgentCost: PropTypes.bool,
	hideTableHead: PropTypes.bool,
};

AchPlanTable.defaultProps = {
	fees: [],
	isDisabled: false,
	handleFeeChange: noop,
	hideAgentCost: false,
	hideTableHead: false,
};