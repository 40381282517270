import React from 'react';
import { get } from 'lodash';
import { withRouter } from 'react-router-dom';
import { Toggle, ToggleContainer } from './../../common/components/toggle';
import { getProcessorDisplayName } from '../../common/utilities/processorDisplayName';

const isExpand = true;

class AppSummary extends React.Component {
	viewLeadDetails = async () => {
		const leadId = get(this.props.app, 'leadId', false);
		if (leadId) {
			this.props.history.push('/leads/account/' + leadId);
		}
	};

	render() {
		const { app, showLeadDetailsLink, isEappWebForm } = this.props;

		return (
			<ToggleContainer>
				<Toggle initialToggle={isExpand}>
					{({ isToggled, handleToggle }) => (
						<React.Fragment>
							<div className={`card${isToggled ? ' is-expanded' : ''} spc--bottom--lrg`}>
								<button className="card__header card__header--expandable" onClick={handleToggle}>
									<div className="flex--primary flex--gap--sml">
										<h5>Account Information</h5>
										{showLeadDetailsLink && (
											<button className="btn btn--sml btn--tertiary" onClick={this.viewLeadDetails}>
												View Lead Details
											</button>
										)}
									</div>
									<i className={`icon icon--lrg icon--chevron--${isToggled ? 'top' : 'right'}--primary`}></i>
								</button>
								{isToggled && (
									<div className="card__body">
										<div className="row">
											<div className="col col-sml-12 col-med-6">
												{!isEappWebForm && (
													<div className="form__group">
														<div className="form__group__header">
															<p className="form__group__label" htmlFor="lblAppId">
																App ID
															</p>
														</div>
														<p className="type--p2 type--color--text--secondary" name="lblAppId" id="lblAppId">
															{app.appId}
														</p>
													</div>
												)}
												<div className="form__group">
													<div className="form__group__header">
														<p className="form__group__label" htmlFor="lblDBA">
															DBA/Merchant Name
														</p>
													</div>
													<p className="type--p2 type--color--text--secondary" name="lblDBA" id="lblDBA">
														{app.dba}
													</p>
												</div>
												{!isEappWebForm && (
													<div className="form__group">
														<div className="form__group__header">
															<p className="form__group__label" htmlFor="lblProcessor">
																Processor
															</p>
														</div>
														<p className="type--p2 type--color--text--secondary" name="lblProcessor" id="lblProcessor">
															{getProcessorDisplayName(app.processorName)}
														</p>
													</div>
												)}
												{!isEappWebForm && (
													<div className="form__group">
														<div className="form__group__header">
															<p className="form__group__label" htmlFor="lblOwner">
																Owner Name
															</p>
														</div>
														<p className="type--p2 type--color--text--secondary" name="lblOwner" id="lblOwner">
															{app.ownerName}
														</p>
													</div>
												)}
											</div>
											<div className="col col-sml-12 col-med-6">
												<div className="form__group">
													<div className="form__group__header">
														<p className="form__group__label" htmlFor="lblContact">
															Main Contact
														</p>
													</div>
													<p className="type--p2 type--color--text--secondary" name="lblContact" id="lblContact">
														{app.businessContactName}
													</p>
												</div>
												<div className="form__group">
													<div className="form__group__header">
														<p className="form__group__label" htmlFor="lblAddress">
															Address
														</p>
													</div>
													<p className="type--p2 type--color--text--secondary" name="lblAddress" id="lblAddress">
														{app.businessInformation.businessAddress.streetAddress}
													</p>
												</div>
												<div className="form__group">
													<div className="form__group__header">
														<p className="form__group__label" htmlFor="lblPhone">
															Phone
														</p>
													</div>
													<p className="type--p2 type--color--text--secondary" name="lblPhone" id="lblPhone">
														{app.businessInformation.businessPhone}
													</p>
												</div>
												<div className="form__group">
													<div className="form__group__header">
														<p className="form__group__label" htmlFor="lblEmail">
															Email
														</p>
													</div>
													<p
														className="type--p2 type--color--text--secondary type--break-word"
														name="lblEmail"
														id="lblEmail"
													>
														{app.businessInformation.businessEmail}
													</p>
												</div>
											</div>
										</div>
									</div>
								)}
							</div>
						</React.Fragment>
					)}
				</Toggle>
			</ToggleContainer>
		);
	}
}

export default withRouter(AppSummary);
