import React from 'react';
import { withLoader } from './../../common/components';
import { appService } from '../../services';
import { identifyFullStoryWithToken, openFile } from '../../common/utilities';

class DownloadFiservApplication extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			token: this.props.location.search.substr(1),
			errorMessage: '',
			success: false,
		};
		identifyFullStoryWithToken(this.state.token);
	}

	componentDidMount() {
		this.props.showLoader(true);
		const isDownload = false;
		appService.getMerchantFiservApplicationFile(this.state.token).then(({ url, fileName, errorMessage }) => {
			if (errorMessage) {
				this.setState({ errorMessage });
			} else {
				openFile(url, fileName, !isDownload, false);

				if (isDownload) {
					this.setState({ success: true });
				}
			}
			this.props.showLoader(false);
		});
	}

	renderSuccessMessage() {
		return <p className="type--p2 type--p2--medium type--color--success">Downloaded successfully</p>;
	}

	renderResultMessage() {
		return this.state.errorMessage ? (
			<p className="type--validation spc--bottom--lrg">{this.state.errorMessage}</p>
		) : (
			this.state.success && this.renderSuccessMessage()
		);
	}
	render() {
		return (
			<div id="main-div" className="l--content">
				{this.state.errorMessage || this.state.success ? (
					this.renderResultMessage()
				) : (
					<p className="type--p2">Loading File...</p>
				)}
			</div>
		);
	}
}

export default withLoader(DownloadFiservApplication);
