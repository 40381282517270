import React, { Fragment, Component } from 'react';
import { cloneDeep, compact, get, keys, map } from 'lodash';
import { object, func, bool } from 'prop-types';
import { focusField, getThumbnailOverlay, scrollTo } from '../../common/utilities';
import KeyCodes from '../../common/components/react-data-grid/src/KeyCodes';
import { defaultImplicitParse, defaultReactOutput } from 'simple-markdown';

class PosSystemAPIListItemComponent extends Component {
	constructor(props) {
		super(props);
		const { isExpanded } = props;

		this.state = {
			isExpanded,
		};

		this.scrollTo = scrollTo(props.equipment);
		this.focusField = focusField(props.equipment);
	}

	get merchantEquipment() {
		if (this.props.merchantEquipment) return this.props.merchantEquipment;
		return this.props.createNewEquipment(
			this.props.equipment.equipmentId,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			0
		);
	}

	renderPurchaseType() {
		const { equipment } = this.props;
		return (
			<div className="row">
				<div className="col col-sml-12 col-med-6 col-lrg-4 col-xlrg-3 spc--bottom--sml required">
					<label htmlFor="purchaseType" className="label required">
						Purchase Type
					</label>
					<select
						className="input input--med input--select"
						name="purchaseType"
						id="purchaseType"
						value={get(this.merchantEquipment, 'purchaseType')}
						onChange={this.handleChange}
					>
						<option value="">Please select...</option>
						{map(keys(equipment.purchaseTypes), (key, id) => (
							<option key={id} value={key}>
								{equipment.purchaseTypes[key]}
							</option>
						))}
					</select>
				</div>
				{get(this.merchantEquipment, 'purchaseType') === 'rollover' && (
					<div className="col col-sml-12 col-med-6 col-lrg-4 col-xlrg-3 spc--bottom--sml required">
						<label htmlFor="originalEquipmentId" className="label required">
							Original Equipment Id
						</label>
						<input
							type="text"
							className="input input--med"
							name={'originalEquipmentId'}
							id={'originalEquipmentId'}
							placeholder="Original Equipment Id"
							value={this.merchantEquipment.originalEquipmentId}
							onChange={this.handleChange}
						/>
					</div>
				)}
			</div>
		);
	}

	renderNotes() {
		const { equipment } = this.props;
		return (
			<div className="row">
				<div className="col col-sml-12 col-lrg-8 col-xlrg-6 form__group">
					<div className="form__group__header">
						<label htmlFor={`${equipment.name}_notes`} className="form__group__label">
							Notes
						</label>
					</div>
					<textarea
						id={`${equipment.name}_notes`}
						className="input input--textarea"
						rows="6"
						placeholder="Notes"
						name={`${equipment.name}_notes`}
						value={this.merchantEquipment.notes}
						onChange={this.handleChange}
					/>
				</div>
			</div>
		);
	}

	handleChange = e => {
		let equipment = cloneDeep(this.merchantEquipment);
		equipment.quantity = 1;
		let itemToSet, itemKey;
		let strName = e.target.name;
		if (strName.startsWith(this.props.equipment.name + '_'))
			strName = strName.substr(this.props.equipment.name.length + 1);

		if (strName.indexOf('_') > 0) {
			let keyList = compact(strName.split('_'));
			itemToSet = keyList.reduce((prev, curItem, idx) => {
				if (idx < keyList.length - 1) {
					return prev[curItem];
				}
				return prev;
			}, equipment);
			itemKey = keyList[keyList.length - 1];
		} else {
			itemToSet = equipment;
			itemKey = strName;
		}
		let newVal = e.target.value;
		if (e.target.type && e.target.type === 'checkbox') newVal = e.target.checked;

		itemToSet[itemKey] = newVal;
		if (itemKey === 'purchaseType') {
			equipment.originalEquipmentId = '';
		}
		this.props.onChange(equipment);
	};

	toggleSection = (isExpanded = !this.state.isExpanded) => this.setState({ isExpanded });

	equipmentSelect = e => {
		const merchantEquipment = this.merchantEquipment;
		merchantEquipment.isSelected = e.target.checked;
		merchantEquipment.quantity = 1;
		this.props.onChange(merchantEquipment, false);
	};

	onEnterKeyDownHandler = (event, onClickHandler) => {
		if (event.keyCode === KeyCodes.Enter) onClickHandler();
	};

	onErrorClick = elem => {
		let elemId = elem.path.replace(/[.]/g, '_');
		this.scrollTo(elemId + '_wrapper');
		this.focusField(elemId);
	};

	render() {
		const { isExpanded } = this.state;
		let { equipment } = this.props;
		const errorList = this.merchantEquipment.errors;
		let errorListPaths = errorList && errorList.length > 0 && errorList.map(e => e.path);
		return (
			<Fragment>
				<div
					className="gateway__list__item"
					onClick={() => this.toggleSection()}
					onKeyDown={e => this.onEnterKeyDownHandler(e, this.toggleSection)}
				>
					<div className="flex--primary">
						<div className="gateway__list__radio-button__wrapper">
							<input
								className="gateway__list__radio-button"
								type="checkbox"
								name={`${equipment.equipmentId}.isSelected`}
								id={`${equipment.equipmentId}.isSelected`}
								value={equipment.equipmentId}
								checked={get(this.merchantEquipment, 'isSelected')}
								onChange={this.equipmentSelect}
							/>
							<label htmlFor={`${equipment.equipmentId}.isSelected`}>
								{get(this.merchantEquipment, 'isSelected') ? 'Selected' : 'Select'}
							</label>
						</div>
						<div
							className={`table--accessories__list__item__thumbnail${getThumbnailOverlay(
								equipment.outOfStock,
								equipment.purchaseTypes,
								true
							)}`}
							style={{
								backgroundImage:
									'url(' +
									process.env.REACT_APP_CDN_URL +
									equipment.name
										.toLowerCase()
										.replace(' ', '_')
										.replace(/[^a-z0-9_-]/gi, '') +
									'/thumbnail.png' +
									')',
							}}
						></div>
						<div>
							<h2>{equipment.name}</h2>
							<p>{equipment.description}</p>
						</div>
					</div>
					<i className={`icon icon--lrg icon--chevron--${this.state.isExpanded ? 'top' : 'right'}--primary`}></i>
				</div>
				{this.merchantEquipment.isSelected && errorListPaths ? (
					<div className="type--validation spc--bottom--lrg spc--top--sml">
						<ul>
							{' '}
							{errorList.map(elem => {
								return (
									<li key={elem.path}>
										<div
											className="btn btn--link"
											onClick={() => this.onErrorClick(elem)}
											onKeyDown={e => this.onEnterKeyDownHandler(e, () => this.onErrorClick(elem))}
										>
											<i className="icon icon--nano icon--text-top icon--alert spc--right--tny"></i>
											{defaultReactOutput(defaultImplicitParse(elem.message))}
										</div>
									</li>
								);
							})}
						</ul>
					</div>
				) : null}
				{isExpanded && (
					<div className="spc--top--sml gateway__top">
						<div className="form__content">
							{this.renderPurchaseType()}
							{this.renderNotes()}
						</div>
					</div>
				)}
			</Fragment>
		);
	}
}

PosSystemAPIListItemComponent.propTypes = {
	equipment: object.isRequired,
	isExpanded: bool.isRequired,
	merchantEquipment: object,
	onChange: func.isRequired,
	createNewEquipment: func.isRequired,
	openCloseModal: func.isRequired,
	equipmentSelect: func.isRequired,
};

export default PosSystemAPIListItemComponent;
