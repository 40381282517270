import React, { Component } from 'react';
import { get, filter, toLower, map } from 'lodash';
import { Toggle } from '../../common/components/toggle';
import PropTypes from 'prop-types';

export default class AchPlanConfirm extends Component {
	onKeyDownHandler = (event, onClickHandler) => {
		if (event.keyCode === 13) {
			onClickHandler();
		}
	};

	render() {
		const { fees } = this.props;

		const monthlyFees = filter(fees, fee => toLower(fee.feeType) === 'monthlyfee');
		const setupFees = filter(fees, fee => toLower(fee.feeType) === 'onetimefee');
		const check21TransactionFees = filter(
			fees,
			fee =>
				(toLower(fee.feeType) === 'transactionfee' || toLower(fee.feeType) === 'discountfee') &&
				fee.achTransactionType == 'Check21'
		);
		const achTransactionFees = filter(
			fees,
			fee =>
				(toLower(fee.feeType) === 'transactionfee' || toLower(fee.feeType) === 'discountfee') &&
				fee.achTransactionType == 'Ach'
		);

		return (
			<Toggle initialToggle={true}>
				{({ isToggled, handleToggle }) => (
					<div className={`card${isToggled ? ' is-expanded' : ''} spc--bottom--lrg`}>
						<button
							className="card__header card__header--expandable"
							onKeyDown={e => this.onKeyDownHandler(e, () => handleToggle)}
							onClick={handleToggle}
						>
							<h5>Plans</h5>
							<i className={`icon icon--lrg icon--chevron--${isToggled ? 'top' : 'right'}--primary`}></i>
						</button>
						{isToggled && (
							<div className="card__body">
								<h6 className="spc--bottom--sml">Monthly Fees</h6>
								<div className="row">{this.renderFeesForType(monthlyFees)}</div>

								<h6 className="spc--bottom--sml">Setup Fees</h6>
								<div className="row">{this.renderFeesForType(setupFees)}</div>

								<h6 className="spc--bottom--sml">Check 21 Fees</h6>
								<div className="row">{this.renderFeesForType(check21TransactionFees)}</div>

								<h6 className="spc--bottom--sml">ACH Fees</h6>
								<div className="row">{this.renderFeesForType(achTransactionFees)}</div>
							</div>
						)}
					</div>
				)}
			</Toggle>
		);
	}

	renderFeesForType = fees => {
		return (
			<React.Fragment>
				{map(fees, fee => {
					const merchantPrice = get(fee, 'merchantPrice', 0);
					const feeName = get(fee, 'feeName', 0);
					return (
						<div className="col col-sml-6 col-med-6 form__group">
							<div className="form__group__header">
								<label className="form__group__label">{feeName} </label>
							</div>
							<div className={`input input--med input--disabled`}>{merchantPrice}</div>
						</div>
					);
				})}
			</React.Fragment>
		);
	};
}
AchPlanConfirm.propTypes = {
	fees: PropTypes.array.isRequired,
};
