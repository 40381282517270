import React from 'react';
import PropTypes from 'prop-types';
import { map, isEmpty, get, find, includes } from 'lodash';
import { formatCurrency } from '../../../helpers/currency-formatter';
import { getThumbnailOverlay } from '../../../common/utilities';

export default function EquipmentLookupTable({ equipmentList, filteredEquipments, onExpand, equipmentType }) {
	if (isEmpty(equipmentList))
		return (
			<div className="table--emptystate spc--bottom--lrg">
				<div className="table--emptystate--img"></div>
				<p className="table--emptystate--title">No Equipments Yet</p>
			</div>
		);
	if (isEmpty(filteredEquipments))
		return (
			<div className="table--emptystate spc--bottom--lrg">
				<div className="table--emptystate--img"></div>
				<p className="table--emptystate--title">No Equipments Matching The Filter</p>
			</div>
		);
	const showImage = !includes(['gateway', 'addOns', 'software'], equipmentType);
	return (
		<table className="table table--primary table--primary--card-row spc--bottom--lrg">
			<colgroup>
				<col />
				<col />
				<col />
				<col width="72" />
			</colgroup>
			<thead>
				<tr>
					<th>Equipment Name</th>
					<th>Agent Cost</th>
					<th>Merchant Cost</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				<tr className="table--primary--card-row__separator">
					<td colSpan={4} style={{ height: '24px' }}></td>
				</tr>
				{map(
					filteredEquipments,
					({ equipmentId, name, outOfStock, purchasePlans, purchaseTypes, isExpanded, description }, index) => {
						const agentCost = get(find(get(purchasePlans, '0.fees'), { feeType: 'OneTimeFee' }), 'agentCost', 0);
						const merchantCost = get(find(get(purchasePlans, '0.fees'), { feeType: 'OneTimeFee' }), 'retailPrice', 0);
						return (
							<React.Fragment key={equipmentId}>
								<tr className={isExpanded && 'is-expanded'}>
									<td>
										<div className="flex--primary flex--gap--lrg">
											{showImage && (
												<div
													className={`equipment-card__thumbnail${getThumbnailOverlay(outOfStock, purchaseTypes)}`}
													style={{
														backgroundImage:
															'url(' +
															process.env.REACT_APP_CDN_URL +
															name
																.toLowerCase()
																.replace(' ', '_')
																.replace(/[^a-z0-9_-]/gi, '') +
															'/thumbnail.png)',
													}}
													alt={name}
												></div>
											)}
											<div>
												<div
													className={`badge badge--filled badge--filled--primary spc--bottom--tny${
														getThumbnailOverlay(outOfStock, purchaseTypes) ? '' : ' display--n'
													}`}
												>
													Out of Stock
												</div>
												<p className="type--p2 type--p2--medium">{name}</p>
											</div>
										</div>
									</td>
									<td>{formatCurrency(agentCost)}</td>
									<td>{formatCurrency(merchantCost)}</td>
									<td className="center">
										<button className="btn btn--link" type="button" onClick={() => onExpand(equipmentId)}>
											<i className={`icon icon--sml icon--chevron--${isExpanded ? 'top' : 'down'}--primary`}></i>
										</button>
									</td>
								</tr>
								{!isExpanded && (
									<tr className="table--primary--card-row__separator">
										<td colSpan={4} style={{ height: '16px' }}></td>
									</tr>
								)}
								{isExpanded && (
									<React.Fragment>
										<tr className="subrow">
											<td colSpan={4}>{description}</td>
										</tr>
										<tr className="table--primary--card-row__separator">
											<td colSpan={4} style={{ height: '16px' }}></td>
										</tr>
									</React.Fragment>
								)}
							</React.Fragment>
						);
					}
				)}
			</tbody>
		</table>
	);
}

EquipmentLookupTable.propTypes = {
	equipmentList: PropTypes.array.isRequired,
	filteredEquipments: PropTypes.array.isRequired,
	onExpand: PropTypes.func.isRequired,
	equipmentType: PropTypes.string.isRequired,
};
