import React, { Component } from 'react';
import { each, cloneDeep, isEmpty, filter, toLower } from 'lodash';
import { array, func } from 'prop-types';

import VarListItemComponent from './var-list-item';

class VarListComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			errorMessage: null,
		};
	}

	componentWillMount() {
		let { varList, merchantEquipment } = this.props;
		let newVarList = filter(merchantEquipment, ({ category }) => toLower(category) === 'var');
		each(varList, item => {
			let merchantVar = merchantEquipment.find((e, i) => e.equipmentId == item.equipmentId);
			if (!merchantVar) {
				let newVar = this.props.createNewEquipment(item.equipmentId, false);
				newVarList.push(newVar);
			}
		});
		this.props.onListChange(newVarList);
	}

	componentDidUpdate() {
		if (isEmpty(this.props.merchantEquipment)) {
			this.componentWillMount();
		}
	}

	VarSelect = (varItem, unselectOther) => {
		if (unselectOther) {
			let newVarList = cloneDeep(this.props.merchantEquipment);
			each(newVarList, item => {
				if (item.category == 'Var' && item.equipmentId != varItem.equipmentId) {
					item.isSelected = false;
				}
			});
			this.props.onListChange(newVarList);
		}
		this.props.onChange(varItem);
	};

	onChange = (varItem, selectVar = true) => {
		if (selectVar) {
			varItem.isSelected = true;
		}
		this.props.onChange(varItem);
	};

	render() {
		const { varList, merchantEquipment, selectedEquipmentId, downloadPrefilledVarSheet } = this.props;
		const { errorMessage } = this.state;

		return (
			<div className="card is-expanded">
				<div className="card__header">
					<h4>Submit a VAR request</h4>
				</div>
				<div className="card__body">
					<div className="notes notes--primary spc--bottom--lrg">
						<i className="icon"></i>
						<p className="type--p4">
							A VAR Request is for any Gateway or Software that requires coding from the bank/processor, which
							Deployments will obtain from the bank/processor, and provide to the Agent (who is supposed to provide to
							the merchant).
						</p>
					</div>
					{errorMessage && <div className="type--validation spc--bottom--med">{errorMessage}</div>}
					{isEmpty(varList) ? (
						<div className="emptystate">
							<div className="emptystate__title">0 Results</div>
							<p className="emptystate__message">No VAR found for this merchant</p>
						</div>
					) : (
						varList.map(varItem => {
							let merchantVar = merchantEquipment.find(e => e.equipmentId == varItem.equipmentId);

							return (
								<div key={varItem.name} className="spc--bottom--lrg">
									<VarListItemComponent
										varItem={varItem}
										isExpanded={varList.length == 1 || selectedEquipmentId == varItem.equipmentId}
										merchantVar={merchantVar}
										onChange={this.onChange}
										createNewEquipment={this.props.createNewEquipment}
										openCloseModal={this.props.openCloseModal}
										varSelect={this.VarSelect}
										downloadPrefilledVarSheet={downloadPrefilledVarSheet}
									/>
								</div>
							);
						})
					)}
				</div>
			</div>
		);
	}
}

VarListComponent.propTypes = {
	varList: array,
	merchantVarList: array,
	onChange: func,
	downloadPrefilledVarSheet: func.isRequired,
};

export default VarListComponent;
