import React from 'react';
import PropTypes from 'prop-types';
import { includes } from 'lodash';

import { Toggle, ToggleContainer } from './../../common/components/toggle';

const isExpand = true;

class GeneralInformation extends React.Component {
	handleAddEmail = () => {
		alert('placeholder for add email');
	};

	handleAddPhone = () => {
		alert('placeholder for add phone');
	};

	handleChange = e => {
		const { name, value } = e.target;
		this.props.onChange('generalInfo', name, value);
	};

	render() {
		const { generalInfo: info, errorListPaths, validateFieldOnBlur, isCanadaLead } = this.props;
		const dbaRequired = typeof this.props.dbaRequired === 'undefined' ? true : this.props.dbaRequired;
		return (
			<ToggleContainer>
				<Toggle initialToggle={isExpand}>
					{({ isToggled, handleToggle }) => (
						<div className={`card${isToggled ? ' is-expanded' : ''} spc--bottom--lrg`}>
							<button className="card__header card__header--expandable" onClick={handleToggle}>
								<h5>General Information</h5>
								<i className={`icon icon--sml icon--chevron--${isToggled ? 'top' : 'right'}--primary`}></i>
							</button>
							{isToggled && (
								<div className="card__body">
									{info.canUpdateStatus && (
										<div className="row">
											<div className="col col-sml-12 col-lrg-6 form__group">
												<div className="form__group__header">
													<p className="form__group__label">Change Status</p>
												</div>
												<select
													className="input input--med input--select"
													name="leadStatus"
													disabled={this.props.disabled}
													id="generalInfo_leadStatus"
													value={info.leadStatus}
													onChange={this.handleChange}
												>
													{info.statusOptions.map(s => {
														return (
															<option key={s} value={s}>
																{s}
															</option>
														);
													})}
												</select>
											</div>
										</div>
									)}
									<div className="row">
										<div className="col col-sml-12 col-lrg-6 form__group">
											<div className="form__group__header">
												<p className="form__group__label">First name</p>
											</div>
											<input
												disabled={this.props.disabled}
												type="text"
												className="input input--med"
												name="firstName"
												id="generalInfo_firstName"
												value={info.firstName}
												onChange={this.handleChange}
											></input>
										</div>
										<div className="col col-sml-12 col-lrg-6 form__group">
											<div className="form__group__header">
												<p className="form__group__label">Last Name</p>
											</div>
											<input
												disabled={this.props.disabled}
												type="text"
												className="input input--med"
												name="lastName"
												id="generalInfo_lastName"
												value={info.lastName}
												onChange={this.handleChange}
											></input>
										</div>
									</div>
									<div className="row">
										<div className="col col-sml-12 col-lrg-6 form__group">
											<div className="form__group__header">
												<p className="form__group__label">Title</p>
											</div>
											<input
												disabled={this.props.disabled}
												type="text"
												className="input input--med"
												name="title"
												id="generalInfo_title"
												value={info.title}
												onChange={this.handleChange}
											></input>
										</div>
										<div className="col col-sml-12 col-lrg-6 form__group">
											<div className="form__group__header">
												<p className="form__group__label">DBA</p>
												{dbaRequired ? (
													<span className="form__group__required" data-tooltip="Required">
														*
													</span>
												) : null}
											</div>
											<input
												disabled={this.props.disabled}
												type="text"
												className={`input input--med${
													includes(errorListPaths, 'generalInfo.dba') ? ' is-invalid' : ''
												}`}
												name="dba"
												id="generalInfo_dba"
												value={info.dba}
												onChange={this.handleChange}
												onBlur={validateFieldOnBlur}
											/>
										</div>
									</div>
									<div className="row">
										<div className="col col-sml-12 col-lrg-6 form__group">
											<div className="form__group__header">
												<p className="form__group__label">
													{isCanadaLead ? 'Business Phone Number:' : 'Phone Number:'}
												</p>
											</div>
											<div
												className={`input input--prefix${
													includes(errorListPaths, 'generalInfo.phoneNumber') ? ' is-invalid' : ''
												}`}
											>
												<div>+1</div>
												<input
													disabled={this.props.disabled}
													type="text"
													className="input--prefix__main"
													name="phoneNumber"
													id="generalInfo_phoneNumber"
													value={info.phoneNumber}
													onChange={this.handleChange}
													onBlur={validateFieldOnBlur}
												/>
											</div>
											{/* ToDo - uncomment when backend is ready */}
											{/* <a className="btn btn--link btn--link--underline spc--top--sml display--b" href="javascript:void(0);" onClick={this.handleAddPhone}>Add additional number</a> */}
										</div>
										<div className="col col-sml-12 col-lrg-6 form__group">
											<div className="form__group__header">
												<p className="form__group__label">Email</p>
											</div>
											<input
												disabled={this.props.disabled}
												type="text"
												className="input input--med"
												name="email"
												id="generalInfo_email"
												value={info.email}
												onChange={this.handleChange}
											></input>
										</div>
									</div>
								</div>
							)}
						</div>
					)}
				</Toggle>
			</ToggleContainer>
		);
	}
}
GeneralInformation.propTypes = {
	generalInfo: PropTypes.shape({
		canUpdateStatus: PropTypes.bool,
		leadStatus: PropTypes.string,
		statusOptions: PropTypes.arrayOf(PropTypes.string),
		firstName: PropTypes.string,
		lastName: PropTypes.string,
		title: PropTypes.string,
		dba: PropTypes.string,
		phoneNumber: PropTypes.string,
		email: PropTypes.string,
	}).isRequired,
	onChange: PropTypes.func.isRequired,
	errorListPaths: PropTypes.arrayOf(PropTypes.string).isRequired,
	validateFieldOnBlur: PropTypes.func.isRequired,
	disabled: PropTypes.bool.isRequired,
	isCanadaLead: PropTypes.bool.isRequired,
	dbaRequired: PropTypes.bool,
};
export default GeneralInformation;
