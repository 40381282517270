import React from 'react';
import PropTypes from 'prop-types';
import { find, findIndex, get, map, toLower } from 'lodash';

function OptionComponent({
	accessoryName = 'Quantity',
	equipmentId,
	equipmentOptions,
	accessories,
	handleChange,
	isHardware = false,
	data,
}) {
	let loweredAccessoryName = toLower(accessoryName);
	const stateAccessory = isHardware ? data : find(accessories, a => a.equipmentId == equipmentId);

	const isQuantity = loweredAccessoryName.indexOf('quantity') > -1;
	if (isQuantity) {
		const relevantQuantityOption = find(
			equipmentOptions,
			e =>
				toLower(e.friendlyName) === loweredAccessoryName &&
				e.dependentOnValue === get(stateAccessory, 'equipmentOptions.CaseOrRoll')
		);
		if (!relevantQuantityOption) {
			return null;
		}
		loweredAccessoryName = toLower(relevantQuantityOption.name);
	}

	const option = find(equipmentOptions, e => toLower(e.name) === loweredAccessoryName);
	const accessoryIndex = findIndex(accessories, a => a.equipmentId == equipmentId);
	const value = get(stateAccessory, isQuantity ? 'quantity' : `equipmentOptions.${accessoryName}`, '');

	if (!option) {
		return null;
	}

	const { name, isRequired, friendlyName, options } = option;
	let id = `${accessoryIndex}${isQuantity ? '' : '_equipmentOptions'}_${isQuantity ? 'quantity' : name}`;
	if (isQuantity && isHardware) {
		id = 'quantity';
	}

	return (
		<div className="fullwidth">
			<div className="form__group__header">
				<label htmlFor={id} className="form__group__label">
					{friendlyName || name}{' '}
				</label>
				{isRequired && (
					<span data-tooltip="Required" className="form__group__required">
						*
					</span>
				)}
			</div>
			<select id={id} name={id} className="input input--sml input--select" onChange={handleChange} value={value}>
				{map(options, o => (
					<option key={o.value} value={o.value}>
						{o.friendlyName || o.value}
					</option>
				))}
			</select>
		</div>
	);
}

OptionComponent.propTypes = {
	accessoryName: PropTypes.string,
	equipmentId: PropTypes.number,
	equipmentOptions: PropTypes.array,
	accessories: PropTypes.array,
	handleChange: PropTypes.func,
	isHardware: PropTypes.bool,
	data: PropTypes.object,
};

export default OptionComponent;
