import React from 'react';
import PropTypes from 'prop-types';

const renderRow = (label, value) => (
	<div className="col col-sml-12 col-med-6 col-lrg-4 form__group">
		<div className="form__group__header">
			<label className="form__group__label">{label}</label>
		</div>
		<p className="type--p2 type--is-empty">{value}</p>
	</div>
);

export const TicketDetailsRenderer = ({
	dba,
	subject,
	issue,
	assignedTo,
	dateOpenedDisplay,
	modifiedDisplay,
	callerName,
	callerPhone,
}) => {
	return (
		<div className="row">
			{renderRow('DBA', dba)}
			{renderRow('Subject', subject)}
			{renderRow('Issue', issue)}
			{renderRow('Assigned to', assignedTo)}
			{renderRow('Opened', dateOpenedDisplay)}
			{renderRow('Modified', modifiedDisplay)}
			{renderRow('Caller Name', callerName)}
			{renderRow('Caller Phone', callerPhone)}
		</div>
	);
};

TicketDetailsRenderer.propTypes = {
	dba: PropTypes.string.isRequired,
	subject: PropTypes.string.isRequired,
	issue: PropTypes.string.isRequired,
	assignedTo: PropTypes.string.isRequired,
	dateOpenedDisplay: PropTypes.string.isRequired,
	modifiedDisplay: PropTypes.string.isRequired,
	callerName: PropTypes.string.isRequired,
	callerPhone: PropTypes.string.isRequired,
};
