import React, { Fragment } from 'react';
import { map, startCase, includes } from 'lodash';
import { func, object } from 'prop-types';
import moment from 'moment';

const EquipmentDetailsPopup = ({
	closeModal,
	row: {
		name,
		category,
		purchaseType,
		enteredDateDisplay,
		deactivatedDateDisplay,
		equipmentMid,
		serialNumber,
		planName,
		fees,
		freeTransactionAmount,
	},
}) => (
	<div>
		<div className="modal__header">
			<h4>Equipment Details</h4>
		</div>
		<div className="modal__body">
			<div className="row spc--bottom--lrg">
				<div className="col col-sml-12 col-med-6 form__group">
					<div className="form__group__header">
						<span className="form__group__label">Equipment Name</span>
					</div>
					<p className="type--p2 type--color--text--secondary type--is-empty">{name}</p>
				</div>
				<div className="col col-sml-12 col-med-6 form__group">
					<div className="form__group__header">
						<span className="form__group__label">Plan Name</span>
					</div>
					<p className="type--p2 type--color--text--secondary type--is-empty">{planName}</p>
				</div>
				<div className="col col-sml-12 col-med-6 form__group">
					<div className="form__group__header">
						<span className="form__group__label">Equipment Type</span>
					</div>
					<p className="type--p2 type--color--text--secondary type--is-empty">{category}</p>
				</div>
				<div className="col col-sml-12 col-med-6 form__group">
					<div className="form__group__header">
						<span className="form__group__label">Purchase Type</span>
					</div>
					<p className="type--p2 type--color--text--secondary type--is-empty">{startCase(purchaseType)}</p>
				</div>
				<div className="col col-sml-12 col-med-6 form__group">
					<div className="form__group__header">
						<span className="form__group__label">Entered Date</span>
					</div>
					<p className="type--p2 type--color--text--secondary type--is-empty">{enteredDateDisplay}</p>
				</div>
				<div className="col col-sml-12 col-med-6 form__group">
					<div className="form__group__header">
						<span className="form__group__label">Deactivated Date</span>
					</div>
					<p className="type--p2 type--color--text--secondary type--is-empty">{deactivatedDateDisplay}</p>
				</div>
				<div className="col col-sml-12 col-med-6 form__group">
					<div className="form__group__header">
						<span className="form__group__label">Equipment MID</span>
					</div>
					<p className="type--p2 type--color--text--secondary type--is-empty">{equipmentMid}</p>
				</div>
				<div className="col col-sml-12 col-med-6 form__group">
					<div className="form__group__header">
						<span className="form__group__label">Serial #</span>
					</div>
					<p className="type--p2 type--color--text--secondary type--is-empty">{serialNumber}</p>
				</div>
				{includes(['Cardknox', 'Fidelipay'], name) && (
					<div className="col col-sml-12 col-med-6 form__group">
						<div className="form__group__header">
							<span className="form__group__label">Free Transactions Included</span>
						</div>
						<p className="type--p2 type--color--text--secondary type--is-empty">{freeTransactionAmount}</p>
					</div>
				)}
			</div>
			{fees && fees.length > 0 && (
				<Fragment>
					<h5 className="spc--bottom--med">Fees</h5>
					<table className="table table--primary">
						<thead>
							<tr>
								<th>Description</th>
								<th>Activated</th>
								<th className="right">Price</th>
							</tr>
						</thead>
						<tbody>
							{map(fees, ({ feeName, merchantPrice, activatedDate }, index) => (
								<tr key={`${feeName}.${index}`} className={index % 2 ? 'odd' : 'even'}>
									<td>{feeName}</td>
									<td>{moment(activatedDate).format(process.env.REACT_APP_DATE_FORMAT)}</td>
									<td className="type--right type--wgt--semibold">
										${(isNaN(merchantPrice) ? 0 : merchantPrice).toFixed(4)}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</Fragment>
			)}
		</div>
		<div className="modal__footer"></div>
	</div>
);

EquipmentDetailsPopup.defaultProps = {
	row: {},
};

EquipmentDetailsPopup.propTypes = {
	closeModal: func.isRequired,
	row: object.isRequired,
};

export default EquipmentDetailsPopup;
