import React, { Fragment, useState } from 'react';
import { get, find, isEmpty, toLower, noop } from 'lodash';
import { modalNames } from './../../common/components/modal-wrapper';
import { NumericFormat as NumberFormat } from 'react-number-format';
import { formatCurrency } from '../../helpers/currency-formatter';
import { principalService } from '../../services';

function getAdditionalFee(sub, fee, merchantFee, merchantPrice, mappedRules, priceType) {
	if (!mappedRules.planIdForFirstEquipment) return;
	if (mappedRules === sub.defaultPlanId) return;
	const defaultFee = find(find(sub.purchasePlans, { planId: sub.defaultPlanId }).fees, { feeType: fee.feeType });
	if (merchantFee && merchantFee[priceType] !== defaultFee[priceType]) return defaultFee[priceType];
	if (!merchantFee && merchantPrice === fee[priceType]) return fee[priceType];
}

function setAdditionalText(sub, fee, merchantFee, merchantPrice, mappedRules, priceType = 'retailPrice') {
	const additionalFee = getAdditionalFee(sub, fee, merchantFee, merchantPrice, mappedRules, priceType);
	if (!additionalFee) return;
	return `(${formatCurrency(additionalFee)}) per additional`;
}

const AddonListItemComponent = props => {
	const [principal] = useState(principalService.get());
	let {
		sub,
		merchantGateway,
		gateway,
		availablePurchasePlans,
		selectSubequipment,
		handleSubOptionSelect,
		handleSubOptionChange,
		handleSubOptionMoreInfo,
		handleSubFeeChange,
		handleSubNote,
		openCloseModal,
		renderEquipmentOptions,
		suffix,
		hideDisabledPurchasePlans,
		renderAddonsPaymentScheduleOptions,
		onMerchantSubEqpChange,
	} = props;

	let defaultPlan = sub.purchasePlans.find(pln => pln.planId == sub.defaultPlanId);
	let merchantSub = merchantGateway.subequipment.find((s, i) => s.equipmentId == sub.equipmentId);

	let disableSubequipment = false;
	const isFirstAddon = !props.existingAddons.find(a => a.equipmentId == sub.equipmentId);
	if (!!sub.hasPlanMapping) {
		let mappingRule = sub.planMapping.find((pln, i) => pln.parentEquipmentPlanId == merchantGateway.purchasePlanId);
		disableSubequipment = mappingRule && mappingRule.excludeSubequipment;
		if (
			mappingRule &&
			mappingRule.planIdForFirstEquipment &&
			mappingRule.planIdForFirstEquipment != '0' &&
			isFirstAddon
		) {
			defaultPlan = sub.purchasePlans.find(pln => pln.planId === mappingRule.planIdForFirstEquipment);
		}
	}
	const hasNoPurchasePlans = isEmpty(availablePurchasePlans);
	if (hasNoPurchasePlans) {
		availablePurchasePlans = [defaultPlan];
		hideDisabledPurchasePlans = false;
	}

	const handleMerchantSubEqpChange = (equipmentId, value, field) => {
		onMerchantSubEqpChange(equipmentId, value, field);
	};

	return defaultPlan.fees.map((fee, idx) => {
		return (
			<Fragment key={idx + 'fee'}>
				<tr>
					{idx == 0 && (
						<Fragment>
							<td colSpan={2} rowSpan={defaultPlan.fees.length} className="is-first">
								<div className="flex--primary flex--column flex--gap--med flex--top">
									<div className="flex--primary flex--gap--sml flex--no-wrap">
										<div>
											<input
												type="checkbox"
												className="input--check"
												name={gateway.name + '_' + sub.equipmentId + '_opt' + suffix}
												id={gateway.name + '_' + sub.equipmentId + '_opt' + suffix}
												value={sub.equipmentId}
												checked={!!merchantSub && !disableSubequipment}
												disabled={disableSubequipment}
												onChange={selectSubequipment}
											/>
											<label htmlFor={gateway.name + '_' + sub.equipmentId + '_opt' + suffix}>
												<div className="flex--primary flex--top flex--nowrap">
													<span>{sub.name}</span>
													{!!sub.description && (
														<i
															className="icon icon--tny icon--regular--info datatooltip--top-right datatooltip--200 spc--left--tny flex--no-shrink"
															data-tooltip={sub.description}
														></i>
													)}
												</div>
											</label>
										</div>
										<button
											disabled={!merchantSub}
											onClick={
												merchantSub
													? notesModal(openCloseModal, merchantSub.notes, handleSubNote(sub.equipmentId))
													: undefined
											}
											className="btn btn--link datatooltip--top-right datatooltip--auto"
											data-tooltip="Add Note"
										>
											<i className="icon icon--sml icon--edit"></i>
											<div className={!!merchantSub && !!merchantSub.notes ? 'gateway--has-note' : ''}></div>
										</button>
									</div>
									{renderEquipmentOptions(
										sub,
										merchantSub,
										availablePurchasePlans,
										handleSubOptionSelect(sub.equipmentId),
										handleSubOptionChange(sub.equipmentId),
										handleSubOptionMoreInfo(sub.equipmentId),
										suffix
									)}
									{renderAddonsPaymentScheduleOptions(sub, merchantSub)}
								</div>
							</td>
						</Fragment>
					)}
					<td colSpan={2} className="is-second">
						{fee.feeName}
					</td>
					{availablePurchasePlans.map((plan, i) => {
						// default logic
						let renderingSelectedPlan = merchantGateway.purchasePlanId == plan.planId || hasNoPurchasePlans;
						let merchantFee =
							renderingSelectedPlan && merchantSub && merchantSub.fees.find((f, i) => f.feeType == fee.feeType);

						let merchantPrice = fee.retailPrice;
						let agentCost = fee.agentCost;
						if (merchantFee) {
							if (Object.keys(merchantFee).includes('merchantPrice')) {
								merchantPrice = merchantFee.merchantPrice;
							}
							if (Object.keys(merchantFee).includes('agentCost')) {
								agentCost = merchantFee.agentCost;
							}
						}
						let additionalText = '';
						let agentCostAdditionalText = '';
						// check if custom mapping
						if (!!sub.hasPlanMapping) {
							let mappedRules = sub.planMapping.find((map, i) => map.parentEquipmentPlanId == plan.planId);
							if (mappedRules) {
								if (mappedRules.excludeSubequipment) {
									// can't add subequipment - exit
									return (
										!hideDisabledPurchasePlans && (
											<React.Fragment key={i + 'mappedRules'}>
												<td className="type--center">-</td>
												<td className="type--center">-</td>
											</React.Fragment>
										)
									);
								}
								//console.log('merchant fee ' + merchantFee);
								if (!merchantFee) {
									let planId =
										!mappedRules.planIdForFirstEquipment || mappedRules.planIdForFirstEquipment == '0' || !isFirstAddon
											? sub.defaultPlanId
											: mappedRules.planIdForFirstEquipment;

									merchantFee = sub.purchasePlans
										.find(p => p.planId == planId)
										.fees.find(f => f.feeType == fee.feeType);
									merchantPrice = (!!merchantFee && merchantFee.retailPrice) || 0;
									agentCost = (!!merchantFee && merchantFee.agentCost) || 0;
								}

								// This checks if this plan's first equipment price is different from the regular plan's price
								//
								additionalText = setAdditionalText(sub, fee, merchantFee, merchantPrice, mappedRules);
								agentCostAdditionalText = setAdditionalText(
									sub,
									fee,
									merchantFee,
									merchantPrice,
									mappedRules,
									'agentCost'
								);
							} else if (!merchantFee) {
								merchantFee = sub.purchasePlans
									.find(p => p.planId === sub.defaultPlanId)
									.fees.find(f => f.feeType === fee.feeType);
								merchantPrice = (!!merchantFee && merchantFee.retailPrice) || 0;
								agentCost = (!!merchantFee && merchantFee.agentCost) || 0;
							}
						}
						const hideDisabledPurchasePlan = merchantGateway.purchasePlanId != plan.planId && hideDisabledPurchasePlans;
						const originalPrice = `$${fee.retailPrice}`;
						const canPriceBeChanged = fee.minFee !== fee.maxFee;
						const isOneTimeFeeAndDisabled =
							merchantGateway.purchasePlanId == plan.planId &&
							toLower(merchantGateway.paymentSchedule) === 'billagent' &&
							toLower(fee.feeType) === 'onetimefee';
						const disabled =
							(merchantGateway.purchasePlanId != plan.planId && !hasNoPurchasePlans) ||
							!merchantSub ||
							!merchantFee ||
							!canPriceBeChanged ||
							isOneTimeFeeAndDisabled;
						let tooltip = null;

						if (!canPriceBeChanged) {
							tooltip = 'This fee cannot be adjusted';
						} else if (!merchantSub) {
							tooltip = 'To adjust this field, you must first check the box to the left of the add-on.';
						} else if (merchantGateway.purchasePlanId != plan.planId && !hasNoPurchasePlans) {
							tooltip = `To adjust this field, you must change the plan to ${plan.planName}.`;
						} else if (isOneTimeFeeAndDisabled) {
							tooltip = `Merchant Cost is not editable when Payment Schedule is Bill Agent.`;
						} else {
							if (!merchantFee) {
								tooltip = 'You cannot adjust this field';
							}
						}
						if (hideDisabledPurchasePlan) return null;

						return (
							<React.Fragment key={plan.planId + i}>
								<td>
									<NumberFormat
										value={agentCost}
										placeholder={originalPrice}
										thousandSeparator={true}
										prefix={'$'}
										decimalScale={3}
										fixedDecimalScale={false}
										className="input input--med table--gateway--add-ons__fee"
										name={'agentCost_' + idx}
										disabled={true}
									/>
									{agentCostAdditionalText && (
										<p className="type--p4 type--color--text--light type--center spc--top--tny">
											{agentCostAdditionalText}
										</p>
									)}
								</td>
								<td>
									<div className="datatooltip--250 datatooltip--left" data-tooltip={disabled ? tooltip : null}>
										<NumberFormat
											id={`${gateway.name}_planId_${plan.planId}_fee_${fee.feeId}`}
											value={merchantPrice}
											placeholder={originalPrice}
											thousandSeparator={true}
											allowNegative={false}
											prefix={'$'}
											decimalScale={3}
											fixedDecimalScale={false}
											className="input input--med table--gateway--add-ons__fee"
											name={'fee_' + idx}
											disabled={disabled}
											onValueChange={values => {
												handleSubFeeChange(sub.equipmentId, get(merchantFee, 'feeId'), values);
											}}
										/>
										{additionalText && (
											<p className="type--p4 type--color--text--light type--center spc--top--tny">{additionalText}</p>
										)}
									</div>
								</td>
							</React.Fragment>
						);
					})}

					{idx == 0 && (
						<td rowSpan={defaultPlan.fees.length} className="action-cell">
							<button
								disabled={!merchantSub}
								onClick={
									merchantSub
										? notesModal(openCloseModal, merchantSub.notes, handleSubNote(sub.equipmentId))
										: undefined
								}
								className="btn btn--link datatooltip--auto datatooltip--no-wrap"
								data-tooltip="Add Note"
							>
								<i className="icon icon--sml icon--document"></i>
								<div className={!!merchantSub && !!merchantSub.notes ? 'gateway--has-note' : ''}></div>
							</button>
						</td>
					)}
				</tr>

				{get(principal, 'isSamlLogin') && onMerchantSubEqpChange !== noop && idx == defaultPlan.fees.length - 1 && (
					<tr style={{ backgroundColor: '#202e3c2b' }}>
						<td colSpan={5 + (hideDisabledPurchasePlans ? 2 : availablePurchasePlans.length * 2)}>
							<div className="row">
								<div className="col col-sml-12 col-med-6 col-xlrg-4 spc--bottom--sml w--max--300">
									<div className="form__group__header">
										<p className="form__group__label" htmlFor="purchaseType">
											Purchase Type
										</p>
										{!!merchantSub && (
											<span className="form__group__required" data-tooltip="Required">
												{' '}
												*
											</span>
										)}
									</div>
									<select
										className="input input--med input--select"
										name="purchaseType"
										id="purchaseType"
										value={get(merchantSub, 'purchaseType')}
										onChange={e => handleMerchantSubEqpChange(sub.equipmentId, e.target.value, 'purchaseType')}
										disabled={!merchantSub}
									>
										<option value="">Please select...</option>
										{Object.keys(get(sub, 'purchaseTypes', {})).map(opt => {
											return (
												<option key={opt + 'purchaseTypes'} value={opt}>
													{get(sub, 'purchaseTypes', {})[opt]}
												</option>
											);
										})}
									</select>
								</div>
								{get(merchantSub, 'purchaseType') === 'rollover' && (
									<div className="col col-sml-12 col-med-6 col-xlrg-4 spc--bottom--sml w--max--300">
										<div className="form__group__header">
											<p className="form__group__label" htmlFor="originalEquipmentId">
												Original Equipment Id
											</p>
											<span className="form__group__required" data-tooltip="Required">
												*
											</span>
										</div>
										<input
											disabled={!merchantSub}
											className="input input--med spc--bottom--sml"
											value={get(merchantSub, 'originalEquipmentId')}
											type="text"
											placeholder="Original Equipment Id"
											name="originalEquipmentId"
											onChange={e => handleMerchantSubEqpChange(sub.equipmentId, e.target.value, 'originalEquipmentId')}
										/>
									</div>
								)}
								{get(principal, 'roles', []).includes('CanEditSerialNumber') && (
									<div className="col col-sml-12 col-med-6 col-xlrg-4 spc--bottom--sml w--max--300">
										<div className="form__group__header">
											<p className="form__group__label" htmlFor="serialNumber">
												Serial #
											</p>
										</div>
										<input
											disabled={!merchantSub}
											className="input input--med spc--bottom--sml"
											value={get(merchantSub, 'serialNumber')}
											type="text"
											placeholder="Serial #"
											name="serialNumber"
											onChange={e => handleMerchantSubEqpChange(sub.equipmentId, e.target.value, 'serialNumber')}
										/>
									</div>
								)}
							</div>
						</td>
					</tr>
				)}
			</Fragment>
		);
	});
};

const notesModal = (openCloseModal, note, handleChange) => () => {
	//console.log('open modal');
	openCloseModal({
		name: modalNames.gatewayNote,
		data: {
			note: note,
			handleChange: handleChange,
		},
	});
};

AddonListItemComponent.defaultProps = {
	existingAddons: [],
	onMerchantSubEqpChange: noop,
};

export default AddonListItemComponent;
