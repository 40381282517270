import React, { Component } from 'react';
import PropTypes from 'prop-types';

class GeneralFilterSelectionComponent extends Component {
	get value() {
		return this.props.filter.values[this.props.filter.key];
	}

	filterRemoved = e => {
		this.props.onFilterRemoved({
			id: this.props.filter.key,
			emptyValue: null,
		});
	};

	render() {
		return (
			<div className="filter__tag">
				<label className="filter__tag__label">{this.props.filter.name}: </label>
				{this.value}
				<button onClick={this.filterRemoved} className="btn btn--reset filter__tag__clear"></button>
			</div>
		);
	}
}

GeneralFilterSelectionComponent.propTypes = {
	filter: PropTypes.object.isRequired,
	onFilterRemoved: PropTypes.func.isRequired,
};

export default GeneralFilterSelectionComponent;
