import React, { Component, Fragment } from 'react';
import { map, startCase } from 'lodash';
import { func } from 'prop-types';
import { appService } from '../../../services';

class GoPlusTierPopup extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			tiers: { '': { value: '', label: 'Please Select...', industry: '' } },
			tierName: '',
		};
	}

	get SelectedTier() {
		const { tierName, tiers } = this.state;

		return tiers && tiers[tierName];
	}

	async componentDidMount() {
		await this.loadGoPlusTiers();
	}

	loadGoPlusTiers = async () => {
		const { closeModal, addNotification, appId, tiers, isAchqOnly } = this.props;

		this.showLoader(true);
		try {
			if (tiers && tiers.length > 0) {
				const newTiers = Object.assign({ '': { value: '', label: 'Please Select...', industry: '' } }, tiers);
				this.setState(
					{
						tiers: newTiers,
					},
					this.showLoader
				);
			} else {
				const data = await appService.getGoPlusTiers(appId, isAchqOnly);
				this.mapTiersToState(data);
			}
		} catch (error) {
			this.showLoader();
			closeModal();
			addNotification({
				success: false,
				message: 'An error occurred: ' + error,
			});
		}
	};

	mapTiersToState = ({ tiers }) => {
		const mappedTiers = map(tiers, ([key, industry, achEnabled], value) => ({
			value,
			label: startCase(key),
			industry,
			achEnabled: achEnabled === 'true',
		}));
		const mappedTiersDict = {
			'': { value: '', label: 'Please Select...', industry: '' },
		};
		mappedTiers.forEach(tier => (mappedTiersDict[tier.value] = tier));
		mappedTiersDict.length = mappedTiers.length;
		this.setState(
			{
				tiers: mappedTiersDict,
			},
			this.showLoader
		);
	};

	confirm = () => {
		const { closeModal, setTierName, setTiers, setIsECommerce, onConfirm } = this.props;
		const { tierName, tiers } = this.state;
		setTierName(tierName);
		setTiers(tiers);
		setIsECommerce(this.SelectedTier?.industry === 'ecommerce');
		onConfirm(tierName, tiers);
		closeModal();
	};

	showLoader = (isLoading = false) => this.setState({ isLoading });

	renderPopupBody = () => (
		<div>
			{this.state.isLoading ? this.renderLoader() : null}
			{this.renderTierDropdown()}
		</div>
	);

	handleChange = ({ target: { name, value } }) => this.setState({ [name]: value });

	renderTierOptions = () =>
		Object.entries(this.state.tiers).map(([_, { label, value }]) =>
			label ? (
				<option key={`${value}.${label}`} value={value}>
					{label}
				</option>
			) : null
		);

	renderTierDropdown = () => (
		<select
			className="input input--med input--select"
			id="tierName"
			name="tierName"
			value={this.SelectedTier.value}
			onChange={this.handleChange}
			disabled={this.state.isLoading}
		>
			{this.renderTierOptions()}
		</select>
	);

	renderLoader = () => <div className="loader--progress"></div>;

	render() {
		const { tierName } = this.state;
		const { isAchqOnly, isSplitAchq, isLinkedToAchq } = this.props;
		const displayTierNote =
			!isAchqOnly && !isLinkedToAchq && this.SelectedTier.achEnabled && (isSplitAchq || appService.isDev());
		return (
			<Fragment>
				<div className="modal__header">
					<h4>Select Tier</h4>
				</div>
				<div className="modal__body">
					{this.renderPopupBody()}
					{displayTierNote && (
						<div className="notes notes--default spc--top--med">
							Selecting this tier will create an ACHQ account in addition to the Cardknox Go account.
						</div>
					)}
				</div>
				<div className="modal__footer">
					<button disabled={!tierName} onClick={this.confirm} className="btn btn--med btn--primary">
						Confirm
					</button>
				</div>
			</Fragment>
		);
	}
}

GoPlusTierPopup.propTypes = {
	closeModal: func.isRequired,
	addNotification: func.isRequired,
	setTierName: func.isRequired,
	setTiers: func.isRequired,
	onConfirm: func.isRequired,
};

export default GoPlusTierPopup;
