import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, get, noop, startCase } from 'lodash';

import { FileDropzoneComponent } from '../../common/components/file-dropzone';
import { modalNames } from './../../common/components/modal-wrapper';
import CreatableSelect from 'react-select/creatable';
import { PredefinedLeadLabels, default as LabelComponent } from '../LeadGrid/formatters/label';
import { appService } from '../../services/appService';
import { Toggle, ToggleContainer } from './../../common/components/toggle';
import { Notification } from '../../common/components/notifications';
import { handleFileDownload } from '../../common/utilities/commonFileHandlingMethods';
import { canPreviewFile } from '../../common/utilities/canPreviewFile';

const isExpand = true;

class AdditionalInfo extends React.Component {
	constructor(props) {
		super(props);
		this.state = { downloadErrorMessage: '' };

		this.notificationRef = React.createRef();
		this.handleFileDownload = handleFileDownload.bind(this);
	}

	handleChange = (nestedKey, arrayIdx) => e => {
		const { name, value } = e.target;
		this.props.onChange('addlInfo', name, value, nestedKey, arrayIdx);
	};

	handleLabelChange = (newValue, actionMeta) => {
		this.props.onChange('addlInfo', 'label', actionMeta.action == 'clear' ? '' : newValue.value, null, null);
	};

	renderLabelOption = data => {
		return <LabelComponent value={data.value} />;
	};

	handleRemoveFile = (tag, fileIndx) => {
		let fullFileList = cloneDeep(this.props.addlInfo.fileUploads);
		fullFileList.splice(fileIndx, 1);
		this.props.onChange('addlInfo', 'fileUploads', fullFileList);
	};

	handleUpdateFileDescription = (tag, i, description) => {
		let fullFileList = cloneDeep(this.props.addlInfo.fileUploads);
		fullFileList[i].fileDescription = description;
		this.props.onChange('addlInfo', 'fileUploads', fullFileList);
	};

	handleUpdateFileTag = (tag, i, newTag) => {
		let fullFileList = cloneDeep(this.props.addlInfo.fileUploads);
		fullFileList[i].fileTag = newTag;
		this.props.onChange('addlInfo', 'fileUploads', fullFileList);
	};

	handleFileDelete = (leadId, fileId, fileName) => e => {
		this.props.openCloseModal({
			name: modalNames.confirmAction,
			data: {
				question: 'Are you sure you want to delete ' + fileName + '?',
				onConfirm: this.deleteFile(leadId, fileId),
			},
		});
	};

	deleteFile = (leadId, fileId) => () => {
		console.log('deleting...');
		appService
			.deleteLeadFile(leadId, fileId)
			.then(() => {
				console.log('successfully deleted!');
				this.props.reload(leadId);
				const addNotification = get(this.notificationRef, 'current.addNotification', noop);
				addNotification({
					message: 'Successfully deleted',
					success: true,
				});
			})
			.catch(ex => {
				console.log('Error deleting file');
				console.log(ex);
				this.setState({ downloadErrorMessage: ex });
			});
	};

	onDrop = (tag, acceptedFiles) => {
		let fullFileList = cloneDeep(this.props.addlInfo.fileUploads);

		let newFilesList = acceptedFiles.map((itm, i) => {
			return { file: itm, fileDescription: '' };
		});

		if (fullFileList) fullFileList.push.apply(fullFileList, newFilesList);
		else fullFileList = newFilesList;

		this.props.onChange('addlInfo', 'fileUploads', fullFileList);
	};

	render() {
		const info = this.props.addlInfo;

		return (
			<React.Fragment>
				<Notification ref={this.notificationRef} />
				<ToggleContainer>
					<Toggle initialToggle={isExpand}>
						{({ isToggled, handleToggle }) => (
							<React.Fragment>
								<div className={`card${isToggled ? ' is-expanded' : ''} spc--bottom--lrg`}>
									<button className="card__header card__header--expandable" onClick={handleToggle}>
										<h5>Custom Field</h5>
										<i
											className={`icon icon--sml icon--chevron--${isToggled ? 'top' : 'right'}--primary`}
											onClick={handleToggle}
										></i>
									</button>
									{isToggled && (
										<div className="card__body">
											<div className="form__group">
												<div className="form__group__header">
													<p className="form__group__label">Custom 01:</p>
												</div>
												<input
													disabled={this.props.disabled}
													type="text"
													className="input input--med"
													name="custom01"
													id="custom01"
													value={info.custom01}
													onChange={this.handleChange(null, null)}
												></input>
											</div>
										</div>
									)}
								</div>
							</React.Fragment>
						)}
					</Toggle>
					<div className="card is-expanded spc--bottom--lrg">
						<div className="card__header">
							<h5>Files</h5>
						</div>
						<div className="card__body">
							{info.files && info.files.length > 0 ? (
								<React.Fragment>
									{/* <label className="label spc--bottom--sml">Existing Files: </label> */}
									{this.state.downloadErrorMessage ? (
										<p className="type--validation spc--bottom--med">{this.state.downloadErrorMessage}</p>
									) : null}
									<ul className="upload__list spc--bottom--med">
										{info.files.map(file => (
											<li key={file.fileId} className="upload__list__item">
												<div className="upload__list__item__thumbnail"></div>
												<p className="upload__list__name">{file.fileName}</p>
												<p className="type--p2 type--color--text--light">
													{file.fileTag && file.fileDescription
														? startCase(file.fileTag) + ' - ' + file.fileDescription
														: startCase(file.fileTag)}
												</p>
												<div className="flex--primary flex--gap--sml align--h--right">
													{canPreviewFile(file.fileName) && (
														<a
															className="btn btn--link datatooltip--auto"
															data-tooltip="View"
															href="javascript:void(0);"
															onClick={this.handleFileDownload(
																file.parentId,
																file.fileId,
																file.fileName,
																this.props.showLoader
															)}
														>
															<i className="icon icon--sml icon--view--text "></i>
														</a>
													)}
													<a
														className="btn btn--link datatooltip--auto"
														data-tooltip="Download"
														href="javascript:void(0);"
														onClick={this.handleFileDownload(
															file.parentId,
															file.fileId,
															file.fileName,
															this.props.showLoader,
															true
														)}
														download
													>
														<i className="icon icon--sml icon--download "></i>
													</a>
													{/* TODO: Add delete functionality */}
													{!this.props.disabled && (
														<a
															href="javascript:void(0);"
															data-tooltip="Delete"
															className="btn btn--link datatooltip--auto"
															onClick={this.handleFileDelete(file.parentId, file.fileId, file.fileName)}
														>
															<i className="icon icon--sml icon--delete"></i>
														</a>
													)}
												</div>
											</li>
										))}
									</ul>
								</React.Fragment>
							) : null}
							{!this.props.disabled && (
								<FileDropzoneComponent
									multiple={true}
									showDescription={true}
									showTagDropdown={true}
									fileList={info.fileUploads}
									onDrop={this.onDrop}
									tag="MerchantStatement"
									onRemoveFile={this.handleRemoveFile}
									onUpdateDescription={this.handleUpdateFileDescription}
									onUpdateTag={this.handleUpdateFileTag}
									validateFileSize={false}
								/>
							)}
						</div>
					</div>
					<div className="card is-expanded spc--bottom--lrg">
						<div className="card__header">
							<h5>Labels</h5>
						</div>
						<div className="card__body">
							<div>
								<div className="form__group__header">
									<p className="form__group__label">Label:</p>
								</div>
								<CreatableSelect
									isDisabled={this.props.disabled}
									isClearable
									onChange={this.handleLabelChange}
									options={PredefinedLeadLabels}
									value={{ label: info.label, value: info.label }}
									formatOptionLabel={this.renderLabelOption}
									className="react-select-container"
									classNamePrefix="react-select"
								/>
							</div>
						</div>
					</div>

					<div className="card is-expanded spc--bottom--lrg">
						<div className="card__header">
							<h5>Notes</h5>
						</div>
						<div className="card__body">
							<div>
								<div className="form__group__header">
									<p className="form__group__label">Note:</p>
								</div>
								<textarea
									disabled={this.props.disabled}
									type="text"
									className="input input--textarea"
									name="notes"
									id="notes"
									value={info.notes}
									onChange={this.handleChange(null, null)}
								></textarea>
							</div>
						</div>
					</div>
				</ToggleContainer>
			</React.Fragment>
		);
	}
}
AdditionalInfo.propTypes = {
	addlInfo: PropTypes.shape({
		custom01: PropTypes.string,
		fileUploads: PropTypes.array,
		files: PropTypes.array,
		label: PropTypes.string,
		notes: PropTypes.string,
	}),
	onChange: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	openCloseModal: PropTypes.func.isRequired,
	reload: PropTypes.func.isRequired,
	showLoader: PropTypes.func,
};
export default AdditionalInfo;
