import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { appService } from '../../services/appService';
import { ApplyValuesFromTemplateMethods, EquipmentEditMethods } from './utilities/equipment-edit-methods';
import { Notification } from '../../common/components/notifications';
import { modalNames, ModalWrapper } from '../../common/components/modal-wrapper';
import EquipmentTemplateTabs from './EquipmentTemplateTabs';
import {
	isEqual,
	isEmpty,
	map,
	findIndex,
	get,
	find,
	filter,
	includes,
	toLower,
	some,
	orderBy,
	noop,
	cloneDeep,
	each,
	has,
	isBoolean,
	endsWith,
	lastIndexOf,
	startsWith,
	split,
	values,
	mapKeys,
} from 'lodash';
import { GatewayEditTemplate, AccessoriesEditTemplate, HardwareEditTemplate } from './edit-templates';
import Schema from '../../validation/BaseSchema';
import { equipmentTemplate } from '../../validation/equipmentTemplate.validation';
import { defaultImplicitParse, defaultReactOutput } from 'simple-markdown';
import { withLoader } from '../../common/components';
import withError from '../../common/components/error/error-hoc';

class EquipmentTemplate extends Component {
	constructor(props) {
		super(props);
		this.equipmentEditMethods = new EquipmentEditMethods(noop, null, null);
		this.notificationRef = createRef();
		this.state = {
			errors: [],
			typeFilter: 'gateways',
			nameFilter: '',
			equipmentList: [],
			filteredEquipment: [],
			defaultEquipmentList: [],
			modal: {
				name: modalNames.none,
				data: null,
			},
			errorMessage: null,
		};
		this.equipmentRefs = createRef();
		this.equipmentRefs.current = {};
	}
	async componentDidMount() {
		try {
			await this.fetchEquipment(() => this.handleFilterChange({ target: { name: 'typeFilter', value: this.state.typeFilter} }));
		} catch (err) {
			this.setState({ errors: ['An error occurred while loading the equipment list'] });
		}
	}
	componentDidUpdate(_, prevState) {
		if (!isEqual(this.state.equipmentList, prevState.equipmentList)) {
			this.validateEquipment();
		}
	}
	openCloseModal = modalObj => {
		let state = {
			modal: modalObj,
		};
		this.setState(state);
	};
	actionInModal = (action, question, bodyHTML = null) => {
		this.openCloseModal({
			name: modalNames.confirmAction,
			data: {
				bodyHTML: bodyHTML,
				onConfirm: action,
				question: question,
			},
		});
	};
	handleSelectedChange = ({ target: { checked, name } }) => {
		const { equipmentList } = this.state;
		const index = findIndex(equipmentList, item => item.equipmentId == name);
		const newEquipments = [...equipmentList];
		newEquipments[index] = {
			...equipmentList[index],
			isSelected: checked,
		};
		this.setState({ equipmentList: newEquipments }, this.handleFilterChange);
	};
	handleExpand = (equipmentId, valueToSet = null) => {
		const { equipmentList } = this.state;
		const index = findIndex(equipmentList, { equipmentId });
		const newEquipments = [...equipmentList];
		newEquipments[index] = {
			...equipmentList[index],
			isExpanded: isBoolean(valueToSet) ? valueToSet : !equipmentList[index].isExpanded,
		};
		this.setState(
			{
				equipmentList: newEquipments,
			},
			this.handleFilterChange
		);
	};
	fetchUserEquipmentTemplate = async () => {
		const userEquipment = await appService.GetTemplateEquipment(0);
		return userEquipment;
	};
	fetchEquipment = async (callback = noop) => {
		this.props.showLoader(true);
		try {
			const userEquipmentTemplate = await this.fetchUserEquipmentTemplate();
			const userEquipmentList = userEquipmentTemplate.equipmentList;
			let { equipmentList } = await appService.getEquipmentListByProcessor(60);
			each(equipmentList, equipment => {
				if (has(equipment, 'equipmentOptions')) {
					const newEquipmentOptions = {};
					equipment.equipmentOptions = mapKeys(equipment.equipmentOptions, (value, key) => {
						if (!(get(value, 'dataType') === 'file')) {
							newEquipmentOptions[key] = value;
						}
					});
					equipment.equipmentOptions = newEquipmentOptions;
				}
			});
			equipmentList = orderBy(equipmentList, 'name');
			const hardwareList = filter(
				equipmentList,
				({ category, purchasePlans }) =>
					!includes(['Gateway', 'Software', 'Var'], category) &&
					some(purchasePlans, ({ fees }) => some(fees, { feeType: 'OneTimeFee' }))
			);
			const gateway = filter(equipmentList, { category: 'Gateway' });
			const hardware = filter(hardwareList, ({ category }) => category !== 'Accessories');
			const accessories = filter(hardwareList, { category: 'Accessories' });

			const filteredEquipment = [...gateway, ...hardware, ...accessories];
			const filteredCreatedEquipmentList = map(filteredEquipment, this.equipmentEditMethods.createEquipment);

			this.equipmentIdsDictionary = [
				{
					type: 'gateways',
					ids: map(gateway, 'equipmentId'),
				},
				{
					type: 'hardwares',
					ids: map(hardware, 'equipmentId'),
				},
				{
					type: 'accessories',
					ids: map(accessories, 'equipmentId'),
				},
			];

			map(userEquipmentList, userEquipment => {
				const equipmentId = userEquipment.equipmentId;
				const defaultEquipment = find(filteredEquipment, eqp => eqp.equipmentId == equipmentId);
				const createdEquipment = find(filteredCreatedEquipmentList, eqp => eqp.equipmentId == equipmentId);
				const isEquipmentType = equipmentType =>
					some(filter(this.equipmentIdsDictionary, { type: equipmentType })[0].ids, id => id == equipmentId);

				if (isEquipmentType('gateways')) {
					ApplyValuesFromTemplateMethods.updateGatewayProperties(
						defaultEquipment,
						createdEquipment,
						userEquipment,
						this.equipmentEditMethods.createEquipment
					);
				} else if (isEquipmentType('hardwares')) {
					ApplyValuesFromTemplateMethods.updateHardwareProperties(defaultEquipment, createdEquipment, userEquipment);
				} else if (isEquipmentType('accessories')) {
					ApplyValuesFromTemplateMethods.updateAccessoryProperties(defaultEquipment, createdEquipment, userEquipment);
				}
			});

			this.setState({ equipmentList: filteredCreatedEquipmentList, defaultEquipmentList: filteredEquipment }, callback);
		} catch (err) {
			this.props.handleError(err);
		}
		this.props.showLoader(false);
	};
	getEquipmentByType = type => {
		if (type === '') return [];

		const { equipmentList } = this.state;
		const ids = filter(this.equipmentIdsDictionary, { type: type })[0];
		return filter(equipmentList, ({ equipmentId }) => some(ids.ids, id => id == equipmentId));
	};
	getTypeByEquipmentId = id => {
		return find(this.equipmentIdsDictionary, ({ ids }) => some(ids, i => i == id)).type;
	};
	getSelectedEquipment = () => {
		const { equipmentList } = this.state;
		return filter(equipmentList, { isSelected: true });
	};
	handlePreSave = equipment => {
		const gatewayIds = filter(this.equipmentIdsDictionary, { type: 'gateways' })[0];
		each(equipment, eqp => {
			if (some(gatewayIds.ids, id => id == eqp.equipmentId) && eqp.additionalFees.length > 0) {
				const selectedAdditionalFees = filter(eqp.additionalFees, { isSelected: true });
				eqp.fees = [...eqp.fees, ...selectedAdditionalFees];
				delete eqp.additionalFees;
			}
			if (toLower(eqp.category) !== 'gateway' && toLower(eqp.paymentSchedule) === 'billagent') {
				map(eqp.fees, fee => (fee.merchantPrice = fee.agentCost));
				map(eqp.accessories, accessory => map(accessory.fees, fee => (fee.merchantPrice = fee.agentCost)));
			}
		});
	};
	handleSaveEquipment = () => {
		this.props.showLoader(true);
		const selectedEquipmentClone = cloneDeep(this.getSelectedEquipment());
		this.handlePreSave(selectedEquipmentClone);
		appService
			.saveEquipmentTemplate(selectedEquipmentClone)
			.then(resp => {
				this.props.showLoader(false);
				this.setState({
					errorMessage: null,
				});
				this.notificationRef.current.addNotification({
					message: 'Template Saved successfully',
					ref: resp.refNum,
					success: true,
				});
			})
			.catch(err => {
				this.handleErrorOnSave(err);
			});
	};

	handleErrorOnSave = err => {
		console.log('save error', err);
		this.props.showLoader(false);
		this.setState({
			errorMessage: err,
		});
	};

	handleSaveEquipmentPopup = () => {
		const selectedEquipment = this.getSelectedEquipment();
		const names = map(selectedEquipment, 'name');
		let body = null;
		if (names.length !== 0) {
			const equipmentNamesListHTML = map(names, name => <li>{name}</li>);
			body = (
				<div className="spc--top--med">
					<ul>{map(equipmentNamesListHTML)}</ul>
				</div>
			);
		}
		this.actionInModal(this.handleSaveEquipment, 'Save selected equipment?', body);
	};
	handleResetEquipmentToDefault = () => {
		this.props.showLoader(true);
		appService
			.saveEquipmentTemplate([])
			.then(resp => {
				return this.fetchEquipment(() => this.handleFilterChange()).then(() => {
					this.props.showLoader(false);
					this.notificationRef.current.addNotification({
						message: 'Template successfully reseted to default',
						ref: resp.refNum,
						success: true,
					});
				});
			})
			.catch(err => {
				this.props.showLoader(false);
				this.notificationRef.current.addNotification({
					message: 'An error occurred while reseting the template to default',
					success: false,
				});
			});
	};
	handleResetEquipmentToDefaultPopup = () =>
		this.actionInModal(this.handleResetEquipmentToDefault, 'Are you sure you want to reset to default?');
	getFilterValueFromEvent = (event, filterName) => {
		let name, value;
		let filterValue = '';
		if (event) {
			({ name, value } = event.target);
		}

		if (name === filterName) {
			filterValue = value;
			this.setState({ [filterName]: value });
		} else {
			filterValue = this.state[filterName];
		}
		return filterValue;
	};

	handleFilterChange = event => {
		if (event && has(event, 'preventDefault')) {
			event.preventDefault();
		}
		let typeFilter = this.getFilterValueFromEvent(event, 'typeFilter');
		let nameFilter = this.getFilterValueFromEvent(event, 'nameFilter');
		const filteredEquipment = filter(
			this.getEquipmentByType(typeFilter),
			({ name }) => !nameFilter || includes(toLower(name), toLower(nameFilter))
		);
		this.setState({ filteredEquipment });
	};

	updateEquipment = equipment => {
		const { equipmentList } = this.state;
		const index = findIndex(equipmentList, { equipmentId: equipment.equipmentId });
		const newEquipments = [...equipmentList];
		newEquipments[index] = equipment;
		this.setState(
			{
				equipmentList: newEquipments,
			},
			this.handleFilterChange
		);
	};
	validateEquipment = () => {
		let equipmentList = cloneDeep(this.state.equipmentList);
		if (!isEmpty(equipmentList)) {
			let selectedEquipment = cloneDeep(this.getSelectedEquipment());
			const schema = new Schema({ ...equipmentTemplate, platform: null }, { strip: false, typecast: true });
			const gatewaySchema = new Schema(
				{ ...equipmentTemplate, shippingOption: null, shippingSpeed: null },
				{ strip: false, typecast: true }
			);
			each(selectedEquipment, e => {
				let defaultEqp = find(this.state.defaultEquipmentList, de => de.equipmentId == e.equipmentId);
				if (toLower(defaultEqp.category) === 'gateway') {
					e.errors = [
						...gatewaySchema.validate({ ...e, settingsSource: values(get(defaultEqp, 'equipmentOptions', {})) }),
					];
				} else {
					e.errors = [...schema.validate({ ...e, settingsSource: values(get(defaultEqp, 'equipmentOptions', {})) })];
				}
				let equipIndex = equipmentList.findIndex(eq => eq.equipmentId === e.equipmentId);
				equipmentList[equipIndex] = e;
			});
		}
		this.setState({ equipmentList });
	};
	onKeyDownHandler = (event, onClickHandler) => {
		if (event.keyCode === 13) {
			onClickHandler();
		}
	};
	determinateElementToFocus = (equipment, error) => {
		let { path } = error;
		const { equipmentId } = equipment;
		let ref = get(this.equipmentRefs.current, equipmentId);
		const findElementById = id => ref.ownerDocument.getElementById(id);

		const elemId = path.replace(/[.]/g, '_');
		let elementToFocus = findElementById(elemId);
		if (!elementToFocus) {
			elementToFocus = findElementById(equipment.name + '_' + elemId);
		}
		if (!elementToFocus && endsWith(path, '.merchantPrice')) {
			let indexOf = lastIndexOf(path, '.');
			let feeId = get(equipment, `${path.substring(0, indexOf)}.feeId`);
			if (feeId) {
				elementToFocus = findElementById('fee_' + feeId);
			}
			if (!elementToFocus && feeId) {
				elementToFocus = findElementById(`${equipment.name}_planId_${equipment.purchasePlanId}_fee_${feeId}`);
			}
		}
		if (!elementToFocus && startsWith(path, 'settingsSource') > -1) {
			let defaultEqp = find(this.state.defaultEquipmentList, de => de.equipmentId == equipmentId);
			const eqpOptionsArray = values(get(defaultEqp, 'equipmentOptions', {}));
			let optionIndex = split(path, '.')[1];
			elementToFocus =
				eqpOptionsArray[optionIndex] &&
				findElementById(equipment.name + '_equipmentOptions__' + eqpOptionsArray[optionIndex].name);
		}
		return elementToFocus;
	};
	handleEquipmentErrorClick = (equipment, error = null) => {
		const { equipmentId } = equipment;
		const { equipmentList } = this.state;
		const newEquipments = [...equipmentList];
		const index = findIndex(equipmentList, { equipmentId });
		newEquipments[index] = {
			...equipmentList[index],
			isExpanded: true,
		};
		this.setState(
			{ equipmentList: newEquipments, typeFilter: this.getTypeByEquipmentId(equipmentId) },
			this.handleFilterChange
		);
		setTimeout(() => {
			if (get(error, 'path')) {
				let elementToFocus = this.determinateElementToFocus(equipment, error);
				if (elementToFocus) {
					elementToFocus.scrollIntoView({ behavior: 'smooth', block: 'center' });
					setTimeout(() => {
						elementToFocus.focus();
					}, 0);
				}
			}
		}, 0);
	};
	renderEquipmentValidationErrors = () => {
		const erroredEquip = filter(this.getSelectedEquipment(), e => e.errors && e.errors.length > 0);
		return (
			erroredEquip.length > 0 && (
				<div className="flex--primary flex--top flex--gap--lrg spc--bottom--med">
					{map(erroredEquip, (eqp, i) => (
						<ul className="type--validation__wrapper" key={`${eqp.name}_errors`}>
							<li className="type--validation type--wgt--bold">{eqp.name}:</li>
							<li>
								<ul className="type--validation__wrapper">
									{map(eqp.errors, (err, idx) => (
										<li className="type--validation" key={`${eqp.name}_errors_${idx}`}>
											<button
												onClick={() => this.handleEquipmentErrorClick(eqp, err)}
												onKeyDown={e => this.onKeyDownHandler(e, () => this.handleEquipmentErrorClick(eqp, err))}
											>
												{defaultReactOutput(defaultImplicitParse(err.message))}
											</button>
										</li>
									))}
								</ul>
							</li>
						</ul>
					))}
				</div>
			)
		);
	};

	renderErrors = () => {
		const { errors } = this.state;
		return (
			errors.length > 0 && (
				<div className="spc--bottom--lrg">
					{map(errors, (err, i) => (
						<ul className="type--validation__wrapper">
							<li className="type--validation">{err}</li>
						</ul>
					))}
				</div>
			)
		);
	};

	getSelectedEquipmentTabsInfo = () => {
		const selectedEquipment = this.getSelectedEquipment();
		const selectedEquipmentIds = map(selectedEquipment, 'equipmentId');
		const selectedEquipmentInfo = {};
		each(this.equipmentIdsDictionary, equipmentType => {
			selectedEquipmentInfo[equipmentType.type] = filter(selectedEquipmentIds, id =>
				some(equipmentType.ids, equipmentId => equipmentId == id)
			).length;
		});
		return selectedEquipmentInfo;
	};
	render() {
		const { typeFilter, nameFilter, filteredEquipment, defaultEquipmentList, errorMessage } = this.state;
		const { isLoading } = this.props;
		const equipmentHasErrors = this.getSelectedEquipment().some(e => e.errors && e.errors.length > 0);
		const disableSaveButton = equipmentHasErrors || isLoading;
		const selectedEquipmentInfo = this.getSelectedEquipmentTabsInfo();
		return (
			<div id="main-div">
				<ModalWrapper modal={this.state.modal} onModalClose={this.openCloseModal} />
				<div className="notes notes--primary w--max--790--max spc--bottom--med">
					<i className="icon"></i>
					<p className="type--p3">
						Use the Default Equipment to select equipment that you frequently order for new accounts. The Default
						Equipment can be easily applied to your cart prior to account submittal. Note: Default Equipment can not be
						used when submitting CardknoxGO accounts.
					</p>
				</div>
				{this.renderErrors()}
				{this.renderEquipmentValidationErrors()}
				<EquipmentTemplateTabs
					onTabSelect={tab => this.handleFilterChange({ target: { name: 'typeFilter', value: toLower(tab) } })}
					currentTab={typeFilter}
					disabled={isLoading}
					selectedEquipmentInfo={selectedEquipmentInfo}
				/>
				<Notification ref={this.notificationRef} />
				{errorMessage ? (
					<div className="type--validation spc--bottom--lrg" style={{ whiteSpace: 'pre-wrap' }}>
						{errorMessage}
					</div>
				) : null}
				<input
					className="chips chips--search spc--bottom--lrg w-200"
					value={nameFilter}
					name="nameFilter"
					id="nameFilter"
					onChange={this.handleFilterChange}
					type="text"
					placeholder="Search equipment"
					disabled={isLoading}
				></input>

				<EquipmentTable
					filteredEquipment={filteredEquipment}
					equipmentType={typeFilter}
					handleSelectedChange={this.handleSelectedChange}
					onExpand={this.handleExpand}
					updateEquipment={this.updateEquipment}
					defaultEquipmentList={defaultEquipmentList}
					ref={this.equipmentRefs}
					onKeyDownHandler={this.onKeyDownHandler}
				/>

				<div className="settings__footer">
					<button
						className="btn btn--med btn--tertiary align--h--left"
						disabled={isLoading}
						onClick={this.handleResetEquipmentToDefaultPopup}
					>
						Reset To Default
					</button>
					<p className="type--p3 type--color--text--secondary hide--to--med">
						Settings are saved only after clicking on Save button
					</p>
					<button
						className="btn btn--med btn--primary"
						disabled={disableSaveButton}
						onClick={this.handleSaveEquipmentPopup}
					>
						Save changes
					</button>
				</div>
			</div>
		);
	}
}

const EquipmentTable = React.forwardRef(
	(
		{
			filteredEquipment,
			equipmentType,
			handleSelectedChange,
			onExpand,
			updateEquipment,
			defaultEquipmentList,
			onKeyDownHandler,
		},
		ref
	) => {
		if (isEmpty(filteredEquipment))
			return (
				<div className="card--equipment__empty-state">
					<div className="card--equipment__empty-state__img"></div>
					<p className="type--center type--color--light spc--bottom--lrg spc--top--med">
						No Equipment Matches The Filters
					</p>
				</div>
			);
		const showImage = !includes(['gateways'], equipmentType);
		return (
			<div>
				{map(filteredEquipment, (item, index) => {
					const { equipmentId, name, isExpanded, isSelected } = item;
					const defaultEquipment = find(defaultEquipmentList, dEqp => dEqp.equipmentId == equipmentId);
					return (
						<div
							className={`card card--equipment spc--bottom--med ${isExpanded ? 'is-expanded' : ''}`}
							key={equipmentId}
						>
							<button
								className="card--equipment__header"
								onClick={() => onExpand(equipmentId)}
								onKeyDown={e => onKeyDownHandler(e, () => onExpand(equipmentId))}
							>
								<div className="spc--right--lrg">
									<input
										className="input--radio input--radio--no-label"
										id={`isSelected-${equipmentId}`}
										type="checkbox"
										name={equipmentId}
										checked={isSelected}
										onChange={handleSelectedChange}
									/>
									<label
										onClick={e => e.stopPropagation()}
										onKeyDown={e => onKeyDownHandler(e, () => e.stopPropagation())}
										htmlFor={`isSelected-${equipmentId}`}
									>
										Label
									</label>
								</div>
								<div className="flex--grow--1">
									{showImage && (
										<div
											alt={name}
											className={`table--accessories__list__item__thumbnail`}
											style={{
												backgroundImage:
													'url(' +
													process.env.REACT_APP_CDN_URL +
													name
														.toLowerCase()
														.replace(' ', '_')
														.replace(/[^a-z0-9_-]/gi, '') +
													'/thumbnail.png)',
											}}
										></div>
									)}
									<h5>{name}</h5>
								</div>
								<i className={`icon icon--lrg icon--chevron--${isExpanded ? 'down' : 'right'} spc--left--sml`}></i>
							</button>
							{isExpanded && (
								<div ref={el => (ref.current[equipmentId] = el)} className="spc--top--xxlrg">
									{renderEquipmentDetails(item, defaultEquipment, equipmentType, updateEquipment)}
								</div>
							)}
						</div>
					);
				})}
			</div>
		);
	}
);

const renderEquipmentDetails = (equipment, defaultEquipment, equipmentType, updateEquipment) => {
	if (equipmentType === 'gateways') {
		return <GatewayEditTemplate equipment={equipment} equipmentDefault={defaultEquipment} onChange={updateEquipment} />;
	} else if (equipmentType === 'accessories') {
		return (
			<AccessoriesEditTemplate equipment={equipment} equipmentDefault={defaultEquipment} onChange={updateEquipment} />
		);
	} else if (equipmentType === 'hardwares') {
		return (
			<HardwareEditTemplate equipment={equipment} equipmentDefault={defaultEquipment} onChange={updateEquipment} />
		);
	} else {
		return <span>This equipment type is not yet implemented.</span>;
	}
};

EquipmentTable.propTypes = {
	equipmentType: PropTypes.string,
	filteredEquipment: PropTypes.array,
	onExpand: PropTypes.func.isRequired,
	updateEquipment: PropTypes.func.isRequired,
	onKeyDownHandler: PropTypes.func.isRequired,
	handleSelectedChange: PropTypes.func.isRequired,
	defaultEquipmentList: PropTypes.array.isRequired,
};

EquipmentTemplate.propTypes = {
	isLoading: PropTypes.bool,
	showLoader: PropTypes.func,
	handleError: PropTypes.func,
};

export default withError(withLoader(EquipmentTemplate));
