import React, { useEffect, useState } from 'react';
import Autocomplete from 'react-google-autocomplete';
import { appService } from '../../services/appService';
import { get, find, includes, some, trim } from 'lodash'

const AddressAutocomplete = ({ onSelectAddress }) => {
    const [apiKey, setApiKey] = useState(null);

    useEffect(() => {
        const getApiKey = async () => {
            try {
                const response = await appService.getGoogleMapsApiKey();
                if (response.ok) {
                    const key = await response.text();
                    setApiKey(key);
                } 
            } catch (error) {}
        }
        getApiKey();
    }, []);

    const handleOnChange = async (address) => {
        try {
            const addressComponent = get(address, "address_components");
            if (addressComponent) {
                const hasLocality = some(addressComponent, item => includes(item.types, "locality"));
                const addressObject = {
                    street: trim(`${getAddressInfoByType(addressComponent, "street_number")} ${getAddressInfoByType(addressComponent, "route")}`),
                    city: getAddressInfoByType(addressComponent, hasLocality ? "locality" : "sublocality"),
                    state: getAddressInfoByType(addressComponent, "administrative_area_level_1"),
                    zip: getAddressInfoByType(addressComponent, "postal_code")
                };
                onSelectAddress(addressObject);
            }
        } catch (error) { }
    };

    const getAddressInfoByType = (data, type) => {
        const foundType = find(data, item => includes(item.types, type));
        if (!foundType) return "";
        return type === "administrative_area_level_1" ? foundType.short_name : foundType.long_name;
    };

    return (
        <>
            {apiKey &&
                <Autocomplete
                    apiKey={apiKey}
                    className='input input--med'
                    onPlaceSelected={(place) => { handleOnChange(place) }}
                    options={{
                        types: ["address"],
                        componentRestrictions: { country: "us" },
                    }}
                />
            }
        </>
    );
}

export default AddressAutocomplete;