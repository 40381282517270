import React from 'react';

function NewNote(props) {
	const noteText = props.noteText;
	const idx = props.index;
	//const today = new Date().toDateString();
	return (
		<div className="form__group">
			<div className="form__group__header">
				<div className="flex--secondary fullwidth">
					<label className="form__group__label">Note {idx + 1}</label>
					<button onClick={props.onDelete(idx)} className="btn btn--action btn--action--secondary" title="Delete">
						<i className="icon icon--sml icon--delete" />
					</button>
				</div>
			</div>
			<textarea
				className="input input--textarea"
				rows="4"
				cols="50"
				onChange={props.onTextChange(idx)}
				value={noteText}
			/>
		</div>
	);
}

NewNote.propTypes = {
	index: React.PropTypes.number,
	noteText: React.PropTypes.string,
	onTextChange: React.PropTypes.func,
	onDelete: React.PropTypes.func,
};

export default NewNote;
