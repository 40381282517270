import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

class DatePickerSelectionComponent extends Component {
	get keys() {
		return {
			startKey: get(this.props, 'filter.props.startKey', 'startDate'),
			endKey: get(this.props, 'filter.props.endKey', 'endDate'),
		};
	}
	get startDate() {
		return this.props.filter.values[this.keys.startKey];
	}
	get endDate() {
		return this.props.filter.values[this.keys.endKey];
	}
	render() {
		const startDate = this.startDate.format(process.env.REACT_APP_SHORT_DATE_TIME_FORMAT);
		const endDate = this.endDate.format(process.env.REACT_APP_SHORT_DATE_TIME_FORMAT);
		const filter = this.props.filter;

		return (
			<div className="filter__toolbar__selected-filter">
				{filter.name}:
				<span className="type--wgt--medium">
					{startDate} - {endDate}
				</span>
			</div>
		);
	}
}

DatePickerSelectionComponent.propTypes = {
	filter: PropTypes.object.isRequired,
};

export default DatePickerSelectionComponent;
