import React from 'react';
import { string, bool } from 'prop-types';

class AccountRecap extends React.Component {
	render() {
		const { accountInfo, wrapperClass, hideTitle } = this.props;

		return (
			<div className={wrapperClass}>
				<div className="form--popup">
					{!hideTitle && <h6 className="spc--bottom--med">Account Recap </h6>}
					<div>
						<div className="row spc--bottom--lrg">
							<div className="col col-sml-12 col-med-6 form__group">
								<div className="form__group__header">
									<label className="form__group__label" htmlFor="lblGoodsOrServicesSold">
										Goods or Services Sold{' '}
									</label>
								</div>
								<div
									className="input input--med input--disabled"
									name="lblGoodsOrServicesSold"
									id="lblGoodsOrServicesSold"
								>
									{accountInfo.goodsOrServicesSold}
								</div>
							</div>
							<div className="col col-sml-12 col-med-6 form__group">
								<div className="form__group__header">
									<label className="form__group__label" htmlFor="lblPercentKeyed">
										Percent Keyed
									</label>
								</div>
								<div className="input input--med input--disabled" name="lblPercentKeyed" id="lblPercentKeyed">
									{accountInfo.percentKeyed}
								</div>
							</div>
							<div className="col col-sml-12 col-med-6 form__group">
								<div className="form__group__header">
									<label className="form__group__label" htmlFor="lblPercentSwiped">
										Percent Swiped
									</label>
								</div>
								<div className="input input--med input--disabled" name="lblPercentSwiped" id="lblPercentSwiped">
									{accountInfo.percentSwiped}
								</div>
							</div>
							<div className="col col-sml-12 col-med-6 form__group">
								<div className="form__group__header">
									<label className="form__group__label" htmlFor="lblAverageTicketCombined">
										Average Ticket
									</label>
								</div>
								<div
									className="input input--med input--disabled"
									name="lblAverageTicketCombined"
									id="lblAverageTicketCombined"
								>
									{accountInfo.averageTicketCombined}
								</div>
							</div>
						</div>
						<div className="spc--bottom--lrg">
							<h6 className="spc--bottom--lrg">Average Monthly Volume</h6>
							<div className="row">
								<div className="col col-sml-12 col-med-6 form__group">
									<div className="form__group__header">
										<label className="form__group__label" htmlFor="lblAverageMonthlyBankcardVolume">
											V/MC
										</label>
									</div>
									<div
										className="input input--med input--disabled"
										name="lblAverageMonthlyBankcardVolume"
										id="lblAverageMonthlyBankcardVolume"
									>
										{accountInfo.averageMonthlyBankcardVolume}
									</div>
								</div>
								<div className="col col-sml-12 col-med-6 form__group">
									<div className="form__group__header">
										<label className="form__group__label" htmlFor="lblAverageMonthlyAmexVolume">
											AMEX
										</label>
									</div>
									<div
										className="input input--med input--disabled"
										name="lblAverageMonthlyAmexVolume"
										id="lblAverageMonthlyAmexVolume"
									>
										{accountInfo.averageMonthlyAmexVolume}
									</div>
								</div>
							</div>
						</div>
						<div>
							<h6 className="spc--bottom--lrg">Average Ticket</h6>
							<div className="row">
								<div className="col col-sml-12 col-med-6 form__group">
									<div className="form__group__header">
										<label className="form__group__label" htmlFor="lblAverageBankcardTicket">
											V/MC
										</label>
									</div>
									<div
										className="input input--med input--disabled"
										name="lblAverageBankcardTicket"
										id="lblAverageBankcardTicket"
									>
										{accountInfo.averageBankcardTicket}
									</div>
								</div>
								<div className="col col-sml-12 col-med-6 form__group">
									<div className="form__group__header">
										<label className="form__group__label" htmlFor="lblAverageAmexTicketAmex">
											AMEX
										</label>
									</div>
									<div
										className="input input--med input--disabled"
										name="lblAverageAmexTicketAmex"
										id="lblAverageAmexTicketAmex"
									>
										{accountInfo.averageAmexTicket}
									</div>
								</div>
								<div className="col col-sml-12 col-med-6 form__group">
									<div className="form__group__header">
										<label className="form__group__label" htmlFor="lblPinDebitMonthlyVolume">
											PIN Debit Monthly Volume
										</label>
									</div>
									<div
										className="input input--med input--disabled"
										name="lblPinDebitMonthlyVolume"
										id="lblPinDebitMonthlyVolume"
									>
										{accountInfo.pinDebitMonthlyVolume}
									</div>
								</div>
								<div className="col col-sml-12 col-med-6 form__group">
									<div className="form__group__header">
										<label className="form__group__label" htmlFor="lblTotalMonthlyVolume">
											Total Monthly Volume
										</label>
									</div>
									<div
										className="input input--med input--disabled"
										name="lblTotalMonthlyVolume"
										id="lblTotalMonthlyVolume"
									>
										{accountInfo.totalMonthlyVolume}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

AccountRecap.defaultProps = {
	wrapperClass: 'card--primary card--sml card--primary--popup',
	hideTitle: false,
};

AccountRecap.propTypes = {
	wrapperClass: string,
	hideTitle: bool,
};

export default AccountRecap;
