import React from 'react';
import { cloneDeep, isEqual, get, isEmpty, filter, includes, split, map, noop } from 'lodash';

import AppSummary from './AppSummary';
import { NavbarComponent } from './nav-bar';
import { appService } from '../../services/appService';
import { withLoader } from './../../common/components';
import { defaultImplicitParse, defaultReactOutput } from 'simple-markdown';
import {
	setupFormTemplate,
	setupFormInvalidTemplate,
	canadaSetupFormTemplate,
	canadaSetupFormInvalidTemplate,
	Schema,
} from '../../validation';
import { handleRemoveFile } from '../../common/utilities/commonFileHandlingMethods';
import { isFd } from '../../common/utilities/processorType';
import withBlock from '../../common/components/block/block-hoc';
import CanadaSetupForm from './CanadaSetupForm';
import USSetupForm from './USSetupForm';

class SetupFormComponent extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			appId: this.props.match.params.appid,
			setup: null,
			files: {},
			errorMessage: null,
			isExpanded: true,
			alreadyUploadedTaxExemption: [],
			errorList: [],
			errorListPaths: [],
			isSaving: false,
			processorList: [],
			isTaxExemptionFileValid: false,
		};
		this.topRef = React.createRef();
		this.sidebarRef = React.createRef();

		this.handleRemoveFile = handleRemoveFile.bind(this);
	}

	toggleExpand = () => {
		this.setState({ isExpanded: !this.state.isExpanded });
	};

	componentDidMount() {
		this.loadSetupFormFromService();
	}

	componentDidUpdate(_prevProps, prevState) {
		if (
			prevState.setup &&
			this.state.setup &&
			(!isEqual(prevState.setup, this.state.setup) || !isEqual(prevState.files, this.state.files)) &&
			!this.state.dirty
		) {
			this.setState({ dirty: true });
			this.props.handleBlockChange(true);
		}

		if (this.state.errorMessage && this.state.isNewError) {
			setTimeout(() => {
				this.topRef.current.scrollIntoView({ behavior: 'smooth' });
			}, 200);
			this.setState({ isNewError: false });
		}
	}

	scrollTo = id => {
		const elem = document.getElementById(`${id}_div`) || document.getElementById(id);
		elem && elem.scrollIntoView({ behavior: 'smooth', block: 'center' });
	};

	focusField = name => {
		const elem = document.getElementsByName(name);
		//console.log(name);
		elem && elem[0] && elem[0].focus();
	};

	loadSetupFormFromService = () => {
		this.setState({ errorMessage: null });
		this.props.showLoader(true);
		Promise.all([appService.getEApp(this.state.appId), appService.getProcessorListByAppId(this.state.appId)])
			.then(([eApp, { processorList }]) => {
				const isCanadian = eApp.isCanadian;
				const isAlreadyUploadedTaxExemption = filter(eApp.files, x => includes(split(x.fileTag, '_'), 'TaxExemption'));

				this.setupSchema = new Schema(isCanadian ? canadaSetupFormTemplate : setupFormTemplate, {
					strip: false,
					typecast: true,
				});
				this.setupInvalidSchema = new Schema(isCanadian ? canadaSetupFormInvalidTemplate : setupFormInvalidTemplate, {
					strip: false,
					typecast: true,
				});
				if (!isCanadian) eApp.isTaxExemptionFileValid = isAlreadyUploadedTaxExemption.length > 0;
				const newState = {
					mpa: eApp,
					setup: eApp,
					processorList,
					alreadyUploadedTaxExemption: isAlreadyUploadedTaxExemption,
				};
				this.loadAppStatus(
					this.setState(newState, () => {
						this.props.showLoader();
						this.props.handleBlockChange(false);
					})
				);
			})
			.catch(err => {
				this.props.showLoader(false);
				this.setState({ errorMessage: 'An error occurred: ' + err, isNewError: true });
			});
	};

	loadAppStatus = (resolve = noop) => {
		return get(this.sidebarRef, 'current.loadAppStatus', noop)(resolve);
	};

	handleEnableAccessOneChange = e => {
		let onlineReportingOptions = { ...this.state.setup.onlineReportingOptions };
		onlineReportingOptions.isEnabled = e.target.checked;
		onlineReportingOptions.markupFee = '';
		this.setState({ setup: { ...this.state.setup, onlineReportingOptions } });
	};

	handleChange = (e, callback) => {
		let setup = cloneDeep(this.state.setup);
		let itemToSet, itemKey;
		if (e.target.name.indexOf('_') > 0) {
			let keyList = e.target.name.split('_');
			itemToSet = keyList.reduce((prev, curItem, idx) => {
				if (idx < keyList.length - 1) {
					return prev[curItem];
				}
				return prev;
			}, setup);
			itemKey = keyList[keyList.length - 1];
		} else {
			itemToSet = setup;
			itemKey = e.target.name;
		}
		let newVal = e.target.value;
		if (includes(['checkbox'], e.target.type)) newVal = e.target.checked;
		itemToSet[itemKey] = newVal;

		this.setState({ setup }, callback);
	};

	handleProcessorChange = e => {
		this.handleChange(e, () => {
			if (!isFd({ processorId: e.target.value })) {
				let onlineReportingOptions = { ...this.state.setup.onlineReportingOptions };
				let eidsEnrollmentDetails = { ...this.state.setup.eidsEnrollmentDetails };
				onlineReportingOptions.isEnabled = false;
				onlineReportingOptions.markupFee = '';
				eidsEnrollmentDetails.shouldEnrollEids = false;
				this.setState({ setup: { ...this.state.setup, onlineReportingOptions, eidsEnrollmentDetails } });
			}
		});
	};

	handleChangeAsync = event => {
		return new Promise(resolve => this.handleChange(event, resolve));
	};

	handleErrorClick = elem => {
		const elemId = elem.path.replace(/[.]/g, '_');
		this.scrollTo(elemId);
		this.focusField(elemId);
	};

	handleTelemarketerChange = e => {
		const telemarketer = this.state.setup.telemarketerList.find(x => x.agentName === e.target.value);

		let telemarketerEmail = get(telemarketer, 'agentName', '');
		let telemarketerId = get(telemarketer, 'agentId', 0);

		this.setState({ setup: { ...this.state.setup, telemarketerEmail, telemarketerId } });
	};

	handleAmexChange = ({ target: { value, checked } }) => {
		this.setState({
			setup: {
				...this.state.setup,
				amexDetails: {
					...this.state.setup.amexDetails,
					status: checked ? value : '',
				},
			},
		});
	};

	handlePinDebitChange = ({ target: { value, checked } }) => {
		this.setState({
			setup: {
				...this.state.setup,
				pricingStructure: {
					...this.state.setup.pricingStructure,
					shouldSetupPinDebit: checked ? value : '',
				},
			},
		});
	};

	handleProgramChange = ({ target: { value } }) => {
		this.setState({
			setup: {
				...this.state.setup,
				amexDetails: {
					...this.state.setup.amexDetails,
					program: value,
					status: value === 'OptBlue' ? 'New' : this.state.setup.amexDetails.status,
				},
			},
		});
	};

	validateTaxExemptionFiles = () => {
		const { files } = this.state;

		if (!this.state.alreadyUploadedTaxExemption.length > 0) {
			let TaxExemptionKey = Object.keys(files).find(key => key === 'TaxExemption');
			if (TaxExemptionKey) {
				const isTaxExemptionFileValid = files[TaxExemptionKey].length > 0;
				this.setState({ setup: { ...this.state.setup, isTaxExemptionFileValid } });
			}
		}
	};

	onDropFile = (fileType, acceptedFiles) => {
		let fullFileList = cloneDeep(this.state.files);
		let newFilesList = acceptedFiles.map(itm => {
			return { file: itm, fileDescription: '' };
		});

		if (fullFileList[fileType]) fullFileList[fileType].push.apply(fullFileList[fileType], newFilesList);
		else fullFileList[fileType] = newFilesList;

		this.setState({ files: fullFileList }, this.validateTaxExemptionFiles);
	};

	save = goToNextStep => {
		if (this.state.isSaving) {
			return;
		}
		this.setState({ isSaving: true });
		this.props.showLoader(true);
		const { history } = this.props;
		const setup = cloneDeep(this.state.setup);
		if (this.state.dirty) {
			appService
				.saveEApp(setup)
				.then(() => {
					this.saveFiles().then(() => {
						this.props.showLoader(false);
						this.setState({ dirty: false, isSaving: false });
						this.props.handleBlockChange(false).then(() => {
							if (!goToNextStep) {
								this.loadSetupFormFromService();
								return;
							}
							history.push('/eapp/equipment/' + this.state.appId);
						});
					});
				})
				.catch(err => {
					console.log('save error', err);
					this.props.showLoader(false);
					this.setState({ errorMessage: 'An error occurred: ' + err, isNewError: true, isSaving: false });
				});
		} else {
			this.props.showLoader(false);
			this.setState({ dirty: false, isSaving: false });
			this.props.handleBlockChange(false).then(() => {
				if (!goToNextStep) {
					this.loadSetupFormFromService();
					return;
				}
				history.push('/eapp/equipment/' + this.state.appId);
			});
		}
	};

	saveFiles = () => {
		if (
			!get(this.state.setup, 'additionalSetupDetails.isTaxExemptionRequested') &&
			!isEmpty(this.state.alreadyUploadedTaxExemption)
		) {
			return Promise.all(
				map(this.state.alreadyUploadedTaxExemption, ({ parentId, fileId }) =>
					appService.deleteMerchantFile(parentId, fileId)
				)
			).catch(err => {
				console.log('delete files error', err);
				this.props.showLoader(false);
				this.setState({
					errorMessage: 'Setup form saved, but files could not be deleted - an error occurred: ' + err,
					isNewError: true,
				});
			});
		}
		if (!get(this.state.setup, 'additionalSetupDetails.isTaxExemptionRequested')) return Promise.resolve();
		const filesToSave = cloneDeep(this.state.files);
		return appService.saveMerchantFiles(this.state.appId, filesToSave).catch(err => {
			console.log('save files error', err);
			this.props.showLoader(false);
			this.setState({
				errorMessage: 'Setup form saved, but files could not be uploaded - an error occurred: ' + err,
				isNewError: true,
			});
		});
	};

	render() {
		const { setup, appId, errorMessage, isSaving } = this.state;
		const merchantDba = get(setup, 'dba');

		let errorList = [];
		let invalidErrorList = [];
		if (this.setupSchema) {
			errorList = this.setupSchema.validate(Object.assign({}, setup));
		}
		const errorListPaths = errorList.map(e => e.path);
		if (this.setupInvalidSchema) {
			invalidErrorList = this.setupInvalidSchema.validate(Object.assign({}, setup));
		}
		const invalidErrorListPaths = invalidErrorList.map(e => e.path);
		return (
			<div id="main-div" ref={this.topRef}>
				<NavbarComponent ref={this.sidebarRef} appId={appId} location={this.props.location} />
				{errorMessage ? <div className="type--validation spc--bottom--lrg">{errorMessage}</div> : null}
				{setup && (
					<div className="leads">
						<div className="spc--bottom--xxlrg">
							{merchantDba && appId && (
								<p className="type--title type--color--text--secondary spc--bottom--lrg">
									{merchantDba} - {appId}
								</p>
							)}
							<h3>Setup Form</h3>
						</div>
						<AppSummary app={this.state.mpa}></AppSummary>

						{this.renderSetupForm()}

						{invalidErrorListPaths.length > 0 && (
							<ul className="type--validation__wrapper">
								{map(invalidErrorList, elem => (
									<li key={elem.path} className="type--validation">
										<button onClick={() => this.handleErrorClick(elem)}>
											{defaultReactOutput(defaultImplicitParse(elem.message))}
										</button>
									</li>
								))}
							</ul>
						)}
						{errorListPaths.length ? (
							<div className="notes notes--warning flex--top spc--bottom--lrg">
								<i className="icon"></i>
								<div>
									<p className="type--p4 spc--bottom--med">
										These fields are required for submitting the app; however, you can save the form without this
										information now and complete the form at a later time.
									</p>
									<ul className="type--validation__wrapper">
										{errorList.map(elem => {
											return (
												<li className="type--validation" key={elem.path}>
													<button onClick={() => this.handleErrorClick(elem)}>
														{defaultReactOutput(defaultImplicitParse(elem.message))}
													</button>
												</li>
											);
										})}
									</ul>
								</div>
							</div>
						) : null}

						<div className="leads__footer">
							<button
								className="btn btn--med btn--tertiary"
								disabled={isSaving || invalidErrorList.length > 0}
								onClick={() => this.save(false)}
							>
								Save
							</button>
							<button
								className="btn btn--med btn--primary"
								disabled={isSaving || invalidErrorList.length > 0}
								onClick={() => this.save(true)}
							>
								Save and Next Step
							</button>
						</div>
					</div>
				)}
			</div>
		);
	}

	renderSetupForm() {
		const {
			handleChange,
			handleAmexChange,
			handlePinDebitChange,
			handleTelemarketerChange,
			handleProgramChange,
			handleRemoveFile,
			validateTaxExemptionFiles,
			onDropFile,
			handleEnableAccessOneChange,
			handleChangeAsync,
			handleProcessorChange,
		} = this;
		const { mpa, setup, processorList, alreadyUploadedTaxExemption, files } = this.state;
		const props = {
			setup,
			processorList,
			files,
			alreadyUploadedTaxExemption,
			handleChange,
			handleAmexChange,
			handlePinDebitChange,
			handleTelemarketerChange,
			handleProgramChange,
			handleRemoveFile,
			validateTaxExemptionFiles,
			onDropFile,
			handleEnableAccessOneChange,
			handleChangeAsync,
			handleProcessorChange,
		};
		if (mpa.isCanadian) return <CanadaSetupForm {...props} />;
		return <USSetupForm {...props} />;
	}
}

export default withBlock(withLoader(SetupFormComponent));
