import React, { Fragment, useEffect, useRef, useState } from 'react';
import { appService, principalService } from '../../services/';
import { get, map, repeat, replace, last, remove } from 'lodash';
import { Link } from 'react-router-dom';
import { isAch, isAchq, isGoPlus } from '../../common/utilities';
import withCancelableRequests from '../../common/utilities/hoc-cancelable-requests';
import PropTypes from 'prop-types';

const CloneAppPopup = ({ appType, data, closeModal, createAbortController = () => new AbortController() }) => {
	const [processor, setProcessor] = useState('');
	const [country, setCountry] = useState(get(data, 'businessCountry', '') || get(data, 'market', ''));
	const [isCloningInProgress, setIsCloningInProgress] = useState(false);
	const [cloningResponse, setCloningResponse] = useState(null);
	const [processors, setProcessors] = useState([]);
	const [allowAchqApplication, setAllowAchq] = useState(false);
	const [isLoadingProcessors, setIsLoadingProcessors] = useState(false);
	const [canBoardGoPlus] = useState(principalService.get().canBoardGoPlus);
	const fetchProcessorListAbortController = useRef([]);

	const handleSubmit = async () => {
		try {
			setIsCloningInProgress(true);
			let url = null;
			if (appType === 'Lead') {
				const cloningResponse = await appService.saveAccountDetails({
					...data,
					leadId: null,
					businessInfo: {
						ownershipType: 'Unknown',
					},
				});
				url = `/leads/account/${cloningResponse.leadId}`;
			} else {
				const appId = await appService.cloneEApp(data.appId, country, processor);
				if (isGoPlus({ processorId: processor })) {
					url = `/eapp/cardknox-go/${appId}`;
				} else if (isAchq({ processorId: processor })) {
					url = `/eapp/achq/${appId}`;
				} else if (isAch({ processorId: processor })) {
					url = `/eapp/ach/${appId}`;
				} else {
					url = `/eapp/mpa/${appId}`;
				}
			}
			setCloningResponse({
				url,
				success: true,
				redirectionError: false,
				message: `${appType} successfuly cloned, you are being redirected.`,
			});
			handleRedirection(url);
		} catch (error) {
			setCloningResponse({ message: `An error occurred while duplicating ${appType}.`, success: false });
		} finally {
			setIsCloningInProgress(false);
		}
	};

	const handleRedirection = url => {
		setTimeout(() => {
			const win = window.open(window.location.origin + url, '_blank');
			if (win === null) {
				setCloningResponse({ message: `${appType} successfuly cloned.`, success: true, url, redirectionError: true });
			} else {
				closeModal();
			}
		}, 3000);
	};

	const handleProcessorChange = e => {
		const { value } = e.target;
		setProcessor(value);
	};

	const handleCountryChange = e => {
		const { value } = e.target;
		setCountry(value);
	};

	const fetchProcessorList = async () => {
		try {
			if (country === '') {
				setProcessors([]);
				return;
			}
			setIsLoadingProcessors(true);
			map(fetchProcessorListAbortController.current || [], controller => controller.abort());
			fetchProcessorListAbortController.current.push(createAbortController());
			const { allowAchqApplication } = await appService.getAllowAchqApplication(
				last(fetchProcessorListAbortController.current).signal
			);
			setAllowAchq(allowAchqApplication);
			setProcessor('');
			const { processorList } = await appService.getProcessorListByCountry(
				country,
				last(fetchProcessorListAbortController.current).signal
			);
			if (!canBoardGoPlus) {
				remove(processorList, processor => isGoPlus({ processorId: processor.key }));
			}
			setProcessors(processorList);
			setIsLoadingProcessors(false);
		} catch (error) {
			if (error instanceof DOMException && error.name === 'AbortError') {
				return; //cancelled request, do nothing
			}
			setIsLoadingProcessors(false);
			setCloningResponse({ message: 'Error fetching processors', success: false });
		}
	};

	useEffect(() => {
		if (appType !== 'Lead') {
			fetchProcessorList();
		}
	}, [country]);

	const renderBody = () => (
		<Fragment>
			{appType !== 'Lead' ? (
				<Fragment>
					<p className="form__group__label spc--bottom--med spc--top--med">Select Country and Processor to continue.</p>
					<div className="flex--primary flex--gap--xxlrg spc--bottom--xlrg">
						<div>
							<input
								type="radio"
								className="input--radio"
								id="countryUSA"
								name="countryUSA"
								value="USA"
								checked={country === 'USA'}
								onChange={handleCountryChange}
							/>
							<label htmlFor="countryUSA">USA</label>
						</div>
						<div>
							<input
								type="radio"
								className="input--radio"
								id="countryCanada"
								name="countryCanada"
								value="Canada"
								checked={country === 'Canada'}
								onChange={handleCountryChange}
							/>
							<label htmlFor="countryCanada">Canada</label>
						</div>
					</div>
					<div className="form__group">
						<div className="form__group__header">
							<p className="form__group__label">Processor</p>
						</div>
						<select
							className="input input--med input--select"
							name="processors_list"
							value={processor}
							onChange={handleProcessorChange}
							disabled={isLoadingProcessors}
						>
							<option value={''}>Select Processor</option>
							{country === 'USA' && <option value={85}>ProfitStars Ach</option>}
							{country === 'USA' && allowAchqApplication && <option value={130}>ACHQ</option>}
							{map(processors, processor => (
								<option value={processor.key}>{processor.value}</option>
							))}
						</select>
					</div>
				</Fragment>
			) : (
				<p className="type--p2 type--p2--medium">Are you sure you want to clone this lead?</p>
			)}
		</Fragment>
	);

	const renderResponse = () => (
		<Fragment>
			<div>
				<div className={`notes notes--${cloningResponse.success ? 'primary' : 'warning'}`}>
					{!cloningResponse.success && <i className="icon"></i>}
					<p className="type--p3">{cloningResponse.message}</p>
				</div>
			</div>
			{cloningResponse.success && (
				<div className="spc--top--med">
					{cloningResponse.redirectionError === false ? (
						<LoaderWithDotsWrapper isLoading={true} template={'Redirecting[dots]'} renderSpinner={false} />
					) : (
						<Fragment>
							<p className="notes notes--warning spc--bottom--med">
								<p className="type--p3">Redirection failed. Make sure your browser has redirection enabled.</p>
							</p>
							<Link
								to={{
									pathname: cloningResponse.url,
								}}
								href="javascript:void(0)"
								target="_blank"
								title={`Cloned ${appType}`}
							>
								<button className="btn btn--sml btn--primary" onClick={closeModal}>
									Open {appType}
								</button>
							</Link>
						</Fragment>
					)}
				</div>
			)}
		</Fragment>
	);

	return (
		<div>
			{
				<Fragment>
					{isCloningInProgress === false && (
						<div className="modal__header">
							<h4>
								Clone {appType}: {data.dba}
							</h4>
						</div>
					)}
					<div className="modal__body">
						{cloningResponse === null ? (
							<Fragment>
								<LoaderWithDotsWrapper isLoading={isCloningInProgress} Children={renderBody} />
							</Fragment>
						) : (
							renderResponse()
						)}
					</div>
					<div className="modal__footer">
						<div className="type--right">
							<button
								className="btn btn--med btn--primary"
								onClick={handleSubmit}
								disabled={
									isCloningInProgress ||
									(appType !== 'Lead' && (country === '' || processor === '')) ||
									cloningResponse !== null
								}
							>
								Clone {appType}
							</button>
						</div>
					</div>
				</Fragment>
			}
		</div>
	);
};

const LoaderWithDotsWrapper = ({
	isLoading,
	Children,
	template = 'Cloning in progress[dots]',
	renderSpinner = true,
}) => {
	const [count, setCount] = useState(1);

	useEffect(() => {
		if (isLoading) {
			const intervalId = setInterval(() => {
				if (count === 3) {
					setCount(1);
				} else {
					setCount(t => t + 1);
				}
			}, 500);
			return () => clearInterval(intervalId);
		}
	}, [count]);

	return (
		<Fragment>
			{isLoading ? (
				<Fragment>
					<p className="type--p1 type--p1--medium type--center spc--bottom--med">
						{replace(template, '[dots]', repeat('.', count))}
					</p>
					{renderSpinner && (
						<div className="pos--rel h--32">
							<div className="loader__spinner"></div>
						</div>
					)}
				</Fragment>
			) : (
				<Children />
			)}
		</Fragment>
	);
};

LoaderWithDotsWrapper.propTypes = {
	isLoading: PropTypes.bool,
	Children: PropTypes.object,
	template: PropTypes.string,
	renderSpinner: PropTypes.bool,
};

CloneAppPopup.propTypes = {
	appType: PropTypes.string,
	data: PropTypes.object,
	closeModal: PropTypes.func,
	createAbortController: PropTypes.func,
};

export default withCancelableRequests(CloneAppPopup);
