import React, { Component, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { each } from 'lodash';

function withCancelableRequests(WrappedComponent) {
	class CancelableComponent extends Component {
		constructor(props) {
			super(props);
			this.abortControllers = [];
		}

		componentWillUnmount = () => {
			each(this.abortControllers, (abortController) => {
				abortController.abort();
			});
		};

		createAbortController = () => {
			const abortController = new AbortController();
			this.abortControllers.push(abortController);
			return abortController;
		}

        render = () => {
			const { forwardedRef, ...rest } = this.props;
			return <WrappedComponent createAbortController={this.createAbortController} ref={forwardedRef} {...rest} />;
		};
	}

	CancelableComponent.propTypes = {
		forwardedRef: PropTypes.any,
	};

	return forwardRef((props, ref) => <CancelableComponent {...props} forwardedRef={ref} />);
}

export default withCancelableRequests;