import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { OutsideClick } from '../../common/utilities';
import { map, find, get } from 'lodash';

class EquipmentTemplateTabs extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isNavOpen: false,
		};
	}

	tabs = [
		{ id: 'gateways', name: 'Gateways and Add-ons' },
		{ id: 'hardwares', name: 'Hardware' },
		{ id: 'accessories', name: 'Accessories' },
	];

	onKeyDownHandler = (event, onClickHandler) => {
		if (event.keyCode === 13) {
			onClickHandler();
		}
	};

	renderTab(tab, i) {
		const isDisabled = this.props.disabled;
		const selectedEquipmentInfo = this.props.selectedEquipmentInfo;
		return (
			<li
				key={tab.id}
				className={`nav--secondary__item${isDisabled ? ' tabs--disabled' : ''}`}
				onKeyDown={e => this.onKeyDownHandler(e, () => this.onTabSelect(tab.id))}
			>
				<button
					className={`nav--secondary__link${this.props.currentTab == tab.id ? ' is-active' : ''} cursor--pointer`}
					onClick={() => this.onTabSelect(tab.id)}
				>
					{tab.name}
					{get(selectedEquipmentInfo, tab.id, 0) ? (
						<span className="tabs__link__counter">{get(selectedEquipmentInfo, tab.id, 0)}</span>
					) : (
						''
					)}
				</button>
			</li>
		);
	}

	onTabSelect = tabId => {
		const isDisabled = this.props.disabled;
		if (isDisabled) return;
		this.props.onTabSelect(tabId);
		this.onNavClick();
	};

	onNavClick = () => {
		if (!this.state.isNavOpen) return;
		this.openCloseNav();
	};

	openCloseNav = () => {
		const isDisabled = this.props.disabled;
		if (isDisabled) return;
		this.setState({
			isNavOpen: !this.state.isNavOpen,
		});
	};

	render = () => {
		const tabs = this.tabs;
		const navigationList = map(tabs, (t, i) => this.renderTab(t, i));
		return (
			<React.Fragment>
				<ul className="tabs spc--bottom--xlrg hide--to--med">{navigationList}</ul>
				<OutsideClick action={this.onNavClick}>
					<div className="pos--rel spc--bottom--med hide--from--med">
						<div
							className="input input--med input--select"
							onClick={this.openCloseNav}
							onKeyDown={e => this.onKeyDownHandler(e, this.openCloseNav)}
						>
							{this.props.currentTab && find(tabs, tab => tab.id === this.props.currentTab).name}
						</div>
						<div className={`popover popover--down fullwidth${this.state.isNavOpen ? '' : ' display--n'}`}>
							<ul className="popover__list">{navigationList}</ul>
						</div>
					</div>
				</OutsideClick>
			</React.Fragment>
		);
	};
}

EquipmentTemplateTabs.propTypes = {
	currentTab: PropTypes.string.isRequired,
	onTabSelect: PropTypes.func.isRequired,
	selectedEquipmentInfo: PropTypes.object.isRequired,
	disabled: PropTypes.bool.isRequired,
};

export default EquipmentTemplateTabs;
