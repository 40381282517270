import React from 'react';
import PropTypes from 'prop-types';
const DEFAULT_CLASS_NAMES = {
	stepper: 'input--stepper',
	button: 'input--stepper__button',
	input: 'input--stepper__input',
	container: 'input--stepper fullwidth',
};
export default function QuantityStepper({ disabled, adjustQuantity, quantity, classNames = DEFAULT_CLASS_NAMES }) {
	return (
		<div className={classNames.container}>
			<button type="button" disabled={disabled} className={classNames.button} name="decrease" onClick={adjustQuantity}>
				&#8211;
			</button>
			<input className={classNames.input} disabled type="number" min="1" name="quantity" value={quantity} />
			<button type="button" disabled={disabled} className={classNames.button} name="increase" onClick={adjustQuantity}>
				+
			</button>
		</div>
	);
}

QuantityStepper.propTypes = {
	disabled: PropTypes.bool,
	adjustQuantity: PropTypes.func,
	quantity: PropTypes.number,
	classNames: PropTypes.shape({
		stepper: PropTypes.string,
		button: PropTypes.string,
		input: PropTypes.string,
		container: PropTypes.string,
	}),
};
