import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';

import { withContext } from '../../common/components';
import { MerchantContext } from '../MerchantDetails';
import { isElavon, isFd, isGoPlus, isAch, isAchq } from '../../common/utilities';

class MerchantSidebarComponent extends Component {
	handleRouteChange = () => {
		if (!this.props.layoutRef.current) return;
		this.props.layoutRef.current.removeSubSidebar();
	};

	handleMobileRouteChange = event => {
		const newRoute = event.target.value;
		this.props.history.push(newRoute);
	};
	render() {
		const {
			merchant,
			match: {
				params: { appid: appId },
			},
		} = this.props;

		const displayBankingServices = isElavon(merchant) || isFd(merchant);
		const displayBankReports = false;
		const summaryLink = merchant && isAch(merchant) ? 'ach-summary' : 'summary';

		const equipmentOption = merchant && !isAch(merchant) ? [{ label: 'Add Equipment', to: 'equipment-new' }] : [];
		const ratesOption =
			merchant && !isAch(merchant) && !isGoPlus(merchant) && !isAchq(merchant) ? [{ label: 'Rates', to: 'rates' }] : [];
		const bankingServicesOption = displayBankingServices ? [{ label: 'Banking Services', to: 'banking-services' }] : [];
		const bankReportsOption = displayBankReports ? [{ label: 'Bank Reports', to: 'bank-reports' }] : [];
		let appSummaryOption;
		if (isGoPlus(merchant)) {
			appSummaryOption = [{ label: 'Go App Summary', to: 'gosummary' }];
		} else if (isAchq(merchant)) {
			appSummaryOption = [{ label: 'Achq App Summary', to: 'achq-summary' }];
		} else {
			appSummaryOption = [{ label: 'App Summary', to: summaryLink }];
		}
		const dropdownOptions = [
			{ label: 'Account Overview', to: 'account' },
			{ label: 'Equipment', to: 'equipment' },
			...equipmentOption,
			{ label: 'Tickets', to: 'tickets' },
			{ label: 'Notes', to: 'notes' },
			{ label: 'Files', to: 'files' },
			{ label: 'Invoices', to: 'invoices' },
			...appSummaryOption,
			...ratesOption,
			...bankingServicesOption,
			...bankReportsOption,
		];
		return (
			<Fragment>
				<NavLink className="btn btn--link spc--bottom--xlrg" to="/merchants">
					<i className="icon icon--sml icon--chevron--left--primary spc--right--sml--alt"></i>
					<span className="type--p4 type--uppercase type--wgt--semibold">Merchant Accounts</span>
				</NavLink>
				<div>
					<select
						className="input input--med input--select hide--from--med spc--bottom--med"
						onChange={this.handleMobileRouteChange}
					>
						{dropdownOptions.map(option => (
							<option key={option.to} value={`/merchants/${appId}/${option.to}`}>
								{option.label}
							</option>
						))}
					</select>
					<ul className="nav--secondary hide--to--med">
						<Fragment>
							<SecondaryNavItem
								appId={appId}
								onRouteChange={this.handleRouteChange}
								to="account"
								label="Account Overview"
							/>
							<SecondaryNavItem appId={appId} onRouteChange={this.handleRouteChange} to="equipment" label="Equipment" />
							{merchant && !isAch(merchant) && (
								<Fragment>
									<SecondaryNavItem
										appId={appId}
										onRouteChange={this.handleRouteChange}
										to="equipment-new"
										label={<span>Add Equipment</span>}
									/>
								</Fragment>
							)}
							<SecondaryNavItem appId={appId} onRouteChange={this.handleRouteChange} to="tickets" label="Tickets" />
							<SecondaryNavItem appId={appId} onRouteChange={this.handleRouteChange} to="notes" label="Notes" />
							<SecondaryNavItem appId={appId} onRouteChange={this.handleRouteChange} to="files" label="Files" />
							{isGoPlus(merchant) ? (
								<SecondaryNavItem
									appId={appId}
									onRouteChange={this.handleRouteChange}
									to="gosummary"
									label="Go App Summary"
								/>
							) : isAchq(merchant) ? (
								<SecondaryNavItem
									appId={appId}
									onRouteChange={this.handleRouteChange}
									to="achq-summary"
									label="Achq App Summary"
								/>
							) : (
								<SecondaryNavItem
									appId={appId}
									onRouteChange={this.handleRouteChange}
									to={summaryLink}
									label="App Summary"
								/>
							)}
							{merchant && isAch(merchant) && (
								<SecondaryNavItem appId={appId} onRouteChange={this.handleRouteChange} to="ach-fees" label="Fees" />
							)}
							{merchant && !isAch(merchant) && !isGoPlus(merchant) && !isAchq(merchant) && (
								<SecondaryNavItem appId={appId} onRouteChange={this.handleRouteChange} to="rates" label="Rates" />
							)}
							<SecondaryNavItem appId={appId} onRouteChange={this.handleRouteChange} to="invoices" label="Invoices" />
							{displayBankingServices && (
								<SecondaryNavItem
									appId={appId}
									onRouteChange={this.handleRouteChange}
									to="banking-services"
									label="Banking Services"
								/>
							)}
							{displayBankReports && (
								<SecondaryNavItem
									appId={appId}
									onRouteChange={this.handleRouteChange}
									to="bank-reports"
									label="Bank Reports"
								/>
							)}
						</Fragment>
					</ul>
				</div>
			</Fragment>
		);
	}
}

function SecondaryNavItem({ label, to, onRouteChange, appId }) {
	return (
		<li className="nav--secondary__item">
			<NavLink
				onClick={onRouteChange}
				className="nav--secondary__link"
				activeClassName="is-active"
				to={`/merchants/${appId}/${to}`}
			>
				{label}
			</NavLink>
		</li>
	);
}
MerchantSidebarComponent.propTypes = {
	layoutRef: PropTypes.shape({
		current: PropTypes.shape({
			removeSubSidebar: PropTypes.func.isRequired,
		}),
	}).isRequired,
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
	merchant: PropTypes.object,
	match: PropTypes.shape({
		params: PropTypes.shape({
			appid: PropTypes.string.isRequired,
		}).isRequired,
	}).isRequired,
};

SecondaryNavItem.propTypes = {
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
	to: PropTypes.string.isRequired,
	onRouteChange: PropTypes.func.isRequired,
	appId: PropTypes.string.isRequired,
};
export default withRouter(withContext(MerchantSidebarComponent, MerchantContext, 'merchant'));
