export function getThumbnailOverlay(outOfStock, purchaseTypes, isAccessories = false) {
	const prefix = ` equipment-card__thumbnail__badge`;
	const isAvailableForPurchase = purchaseTypes && purchaseTypes.purchase;

	if (outOfStock === undefined && !isAvailableForPurchase) {
		return prefix + ' not-available';
	} else if (outOfStock) {
		return prefix + ' out-of-stock';
	}

	return '';
}
