import React from 'react';
import cx from 'classnames';

import Page from './Page';

class Paginator {
	constructor(per_page, length) {
		this.per_page = per_page || 25;
		this.length = length || 10;
	}
	build(total_results, current_page) {
		const total_pages = Math.ceil(total_results / this.per_page);
		total_results = parseInt(total_results, 10);
		current_page = parseInt(current_page, 10) || 1;
		if (current_page < 1) {
			current_page = 1;
		}
		if (current_page > total_pages) {
			current_page = total_pages;
		}
		let first_page = Math.max(1, current_page - Math.floor(this.length / 2));
		let last_page = Math.min(total_pages, current_page + Math.floor(this.length / 2));
		if (last_page - first_page + 1 < this.length) {
			if (current_page < total_pages / 2) {
				last_page = Math.min(total_pages, last_page + (this.length - (last_page - first_page)));
			} else {
				first_page = Math.max(1, first_page - (this.length - (last_page - first_page)));
			}
		}
		if (last_page - first_page + 1 > this.length) {
			if (current_page > total_pages / 2) {
				first_page++;
			} else {
				last_page--;
			}
		}
		let first_result = this.per_page * (current_page - 1);
		if (first_result < 0) {
			first_result = 0;
		}
		let last_result = this.per_page * current_page - 1;
		if (last_result < 0) {
			last_result = 0;
		}
		if (last_result > Math.max(total_results - 1, 0)) {
			last_result = Math.max(total_results - 1, 0);
		}
		return {
			total_pages: total_pages,
			pages: Math.min(last_page - first_page + 1, total_pages),
			current_page: current_page,
			first_page: first_page,
			last_page: last_page,
			previous_page: current_page - 1,
			next_page: current_page + 1,
			has_previous_page: current_page > 1,
			has_next_page: current_page < total_pages,
			total_results: total_results,
			results: Math.min(last_result - first_result + 1, total_results),
			first_result: first_result,
			last_result: last_result,
		};
	}
}

export default class Pagination extends React.Component {
	static defaultProps = {
		itemsCountPerPage: 10,
		pageRangeDisplayed: 5,
		activePage: 1,
		prevPageText: '⟨',
		firstPageText: '«',
		nextPageText: '⟩',
		lastPageText: '»',
		innerClass: 'pagination',
		itemClass: 'pagination__item',
		linkClass: undefined,
		activeLinkClass: undefined,
		hideFirstLastPages: false,
		getPageUrl: i => '#',
	};
	isFirstPageVisible(has_previous_page) {
		const { hideDisabled, hideFirstLastPages } = this.props;
		return !(hideFirstLastPages || (hideDisabled && !has_previous_page));
	}

	isPrevPageVisible(has_previous_page) {
		const { hideDisabled, hideNavigation } = this.props;
		return !(hideNavigation || (hideDisabled && !has_previous_page));
	}

	isNextPageVisible(has_next_page) {
		const { hideDisabled, hideNavigation } = this.props;
		return !(hideNavigation || (hideDisabled && !has_next_page));
	}

	isLastPageVisible(has_next_page) {
		const { hideDisabled, hideFirstLastPages } = this.props;
		return !(hideFirstLastPages || (hideDisabled && !has_next_page));
	}

	buildPages() {
		const pages = [];
		const {
			itemsCountPerPage,
			pageRangeDisplayed,
			activePage,
			prevPageText,
			nextPageText,
			firstPageText,
			lastPageText,
			totalItemsCount,
			onChange,
			activeClass,
			itemClass,
			itemClassFirst,
			itemClassPrev,
			itemClassNext,
			itemClassLast,
			activeLinkClass,
			disabledClass,
			linkClass,
			linkClassFirst,
			linkClassPrev,
			linkClassNext,
			linkClassLast,
			getPageUrl,
		} = this.props;

		if (itemsCountPerPage === 0) {
			return null;
		}

		const paginationInfo = new Paginator(itemsCountPerPage, pageRangeDisplayed).build(totalItemsCount, activePage);

		for (let i = paginationInfo.first_page; i <= paginationInfo.last_page; i++) {
			pages.push(
				<Page
					isActive={i === activePage}
					key={i}
					href={getPageUrl(i)}
					pageNumber={i}
					pageText={i + ''}
					onClick={onChange}
					itemClass={itemClass}
					linkClass={linkClass}
					activeClass={activeClass}
					activeLinkClass={activeLinkClass}
					ariaLabel={`Go to page number ${i}`}
				/>
			);
		}

		this.isPrevPageVisible(paginationInfo.has_previous_page) &&
			pages.unshift(
				<Page
					key={'prev' + paginationInfo.previous_page}
					href={getPageUrl(paginationInfo.previous_page)}
					pageNumber={paginationInfo.previous_page}
					onClick={onChange}
					pageText={prevPageText}
					isDisabled={!paginationInfo.has_previous_page}
					itemClass={cx(itemClass, itemClassPrev)}
					linkClass={cx(linkClass, linkClassPrev)}
					disabledClass={disabledClass}
					ariaLabel="Go to previous page"
				/>
			);

		this.isFirstPageVisible(paginationInfo.has_previous_page) &&
			pages.unshift(
				<Page
					key={'first'}
					href={getPageUrl(1)}
					pageNumber={1}
					onClick={onChange}
					pageText={firstPageText}
					isDisabled={!paginationInfo.has_previous_page}
					itemClass={cx(itemClass, itemClassFirst)}
					linkClass={cx(linkClass, linkClassFirst)}
					disabledClass={disabledClass}
					ariaLabel="Go to first page"
				/>
			);

		this.isNextPageVisible(paginationInfo.has_next_page) &&
			pages.push(
				<Page
					key={'next' + paginationInfo.next_page}
					href={getPageUrl(paginationInfo.next_page)}
					pageNumber={paginationInfo.next_page}
					onClick={onChange}
					pageText={nextPageText}
					isDisabled={!paginationInfo.has_next_page}
					itemClass={cx(itemClass, itemClassNext)}
					linkClass={cx(linkClass, linkClassNext)}
					disabledClass={disabledClass}
					ariaLabel="Go to next page"
				/>
			);

		this.isLastPageVisible(paginationInfo.has_next_page) &&
			pages.push(
				<Page
					key={'last'}
					href={getPageUrl(paginationInfo.total_pages)}
					pageNumber={paginationInfo.total_pages}
					onClick={onChange}
					pageText={lastPageText}
					isDisabled={paginationInfo.current_page === paginationInfo.total_pages}
					itemClass={cx(itemClass, itemClassLast)}
					linkClass={cx(linkClass, linkClassLast)}
					disabledClass={disabledClass}
					ariaLabel="Go to last page"
				/>
			);

		return pages;
	}

	render() {
		const pages = this.buildPages();
		return <ul className={this.props.innerClass}>{pages}</ul>;
	}
}
