import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import TimePicker from '../../common/components/react-time-picker';
import EquipmentOption from './equipment-option';

const FrontendProcessorComponent = props => {
	const { merchantEquipment, equipment, handleChange, handleTimeChange, enabled } = props;
	const batchSettlementOption = equipment.equipmentOptions['BatchSettlementType'];

	return (
		<Fragment>
			<div className="input--check--enable-form spc--bottom--lrg">
				<div className="flex--primary flex--gap--med fullwidth" id={equipment.name + '_platform'}>
					{equipment.frontendProcessors &&
						Object.keys(equipment.frontendProcessors).map(opt => {
							return (
								<div className="flex--grow--1" key={opt}>
									<input
										id={equipment.name + '_platform_' + opt}
										name={equipment.name + '_platform'}
										value={opt}
										type="radio"
										className="input--radio"
										onChange={handleChange}
										disabled={!enabled}
										checked={merchantEquipment.platform == opt}
									/>
									<label htmlFor={equipment.name + '_' + 'platform_' + opt}>{equipment.frontendProcessors[opt]}</label>
								</div>
							);
						})}
					{batchSettlementOption && (
						<EquipmentOption
							merchantEquipment={merchantEquipment}
							equipment={equipment}
							option={batchSettlementOption.name}
							listType="radio"
							checkboxHandler={props.optionSelect}
							moreInfoHandler={props.optionSetMoreInfo}
							inputHandler={handleChange}
							disableOption={!enabled}
						/>
					)}
				</div>
			</div>
			<div>
				<div className="form__group__header">
					<p className="form__group__label">Batch out time</p>
				</div>
				<div className="row">
					<div className="col col-sml-12 col-med-6">
						<TimePicker
							value={merchantEquipment.batchoutTime}
							name={equipment.name + '_' + 'batchoutTime'}
							className="input input--med spc--bottom--med"
							clockIcon={null}
							clearIcon={null}
							disableClock={true}
							maxDetail="minute"
							format="h:mm a"
							onChange={handleTimeChange}
							locale="en-US"
							disabled={!enabled}
						/>
					</div>
					<div className="col col-sml-12 col-med-6">
						<div className="notes notes--error">
							<p className="type--p3">
								We strongly recommend that merchants do not set up their own batch processing times. We set up the
								latest possible batch out time on each equipment using the best platform for that equipment.
							</p>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};
FrontendProcessorComponent.propTypes = {
	merchantEquipment: PropTypes.object,
	equipment: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleTimeChange: PropTypes.func.isRequired,
	enabled: PropTypes.bool,
};
FrontendProcessorComponent.defaultProps = {
	enabled: true,
};
export default FrontendProcessorComponent;
