import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { withExpander } from './../../common/components';
import { isFd, isGoPlus, isAchq, renderIf } from './../../common/utilities';
import { MerchantStatusComponent } from '../MerchantGrid/formatters';
import { DisplayDateComponent, DisplayBooleanComponent } from '../../common/formatters';
import { modalNames } from './../../common/components/modal-wrapper';
import { getProcessorDisplayName } from '../../common/utilities/processorDisplayName';

class AccountSummary extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			modal: {
				name: modalNames.none,
				data: null,
			},
		};
	}

	renderInput(name, value, isEditable, labelClass, inputClass, LabelComponent) {
		if (isEditable) {
			return (
				<input
					type="text"
					className={inputClass}
					id={name}
					name={name}
					value={value}
					onChange={this.props.onChange}
					onBlur={this.props.onBlur}
				/>
			);
		}
		return (
			<label className={labelClass} name={name} id={name}>
				{LabelComponent ? <LabelComponent value={value} /> : value}
			</label>
		);
	}

	renderBaseField(name, labelText, value, isEditable, labelClass, inputClass, LabelComponent) {
		return (
			<div className="col col-sml-12 col-med-6 col-lrg-12 col-xlrg-4 form__group">
				<div className="form__group__header">
					<label className="form__group__label" htmlFor={name}>
						{labelText}
					</label>
				</div>
				{this.renderInput(name, value, isEditable, labelClass, inputClass, LabelComponent)}
			</div>
		);
	}

	renderField(
		name,
		labelText,
		key,
		isEditable = false,
		labelClass = 'type--p2 type--color--text--secondary type--is-empty',
		inputClass = 'input input--med',
		LabelComponent = null
	) {
		const data = isEditable ? this.props.merchantDetails : this.props.merchant;
		return this.renderBaseField(name, labelText, get(data, key), isEditable, labelClass, inputClass, LabelComponent);
	}

	renderBooleanField(name, labelText, key) {
		return this.renderField(name, labelText, key, undefined, undefined, undefined, DisplayBooleanComponent);
	}

	renderAdditionalEmail() {
		const { isEditDetails, merchant, merchantDetails } = this.props;
		if (isEditDetails)
			return (
				<div className="col col-sml-12 col-med-6 col-lrg-12 form__group">
					<div className="form__group__header">
						<label className="form__group__label" htmlFor="merchantDetails_alternateEmail">
							Additional Email
						</label>
					</div>
					<input
						type="text"
						className="input input--med"
						id="merchantDetails_alternateEmail"
						name="merchantDetails_alternateEmail"
						value={merchantDetails.alternateEmail}
						onChange={this.props.onChange}
						onBlur={this.props.onBlur}
					/>
				</div>
			);
		return (
			merchant.alternateEmail && (
				<div className="col col-sml-12 col-med-6 col-lrg-12 form__group">
					<div className="form__group__header">
						<label className="form__group__label" htmlFor="lblAdditionalEmail">
							Additional Email
						</label>
					</div>
					<p
						className="type--p2 type--color--text--secondary type--is-empty"
						name="lblAdditionalEmail"
						id="lblAdditionalEmail"
					>
						{merchant.alternateEmail}
					</p>
				</div>
			)
		);
	}

	render() {
		const { merchant, isExpanded, isEditDetails } = this.props;
		return (
			<Fragment>
				<div className="card__header">
					<h5>Account Summary</h5>
					{isEditDetails ? (
						<div className="flex--primary flex--gap--sml flex--no-wrap hide--to--med">
							<button className="btn btn--med btn--secondary" onClick={this.props.onCancel}>
								Cancel
							</button>
							<button className="btn btn--med btn--primary" onClick={this.props.onSave}>
								Save
							</button>
						</div>
					) : (
						<button className="btn btn--action btn--action--secondary" onClick={this.props.onEdit}>
							<i className="icon icon--sml icon--edit"></i>
						</button>
					)}
				</div>
				<div className="card__body card__body--no-bottom-padding">
					<div className="row">
						{this.renderField('lblMID', 'MID', 'mid')}
						{this.renderField('lblDBA', 'DBA', 'dba')}

						{this.renderField(
							'merchantDetails_businessContactName',
							'Main Contact',
							'businessContactName',
							isEditDetails
						)}
						<div className="col col-sml-12 col-med-6 col-lrg-12 form__group">
							<div className="form__group__header">
								<label className="form__group__label" htmlFor="lblStatus">
									Status:
								</label>
							</div>
							<div name="lblStatus" id="lblStatus">
								<MerchantStatusComponent
									value={merchant.statusDisplayName}
									dependentValues={{
										statusTitle: merchant.statusTitle,
										statusDisplayName: merchant.statusDisplayName,
									}}
								/>
							</div>
						</div>
						{this.renderField('lblAddress', 'Address', 'businessAddress.streetAddress')}
						{this.renderBaseField(
							'lblProcessor',
							'Processor',
							getProcessorDisplayName(merchant.processorName),
							false,
							'label--value'
						)}
						{this.renderField('lblFrontEnd', 'Front End', 'frontEndProcessor')}
						{this.renderField('merchantDetails_phoneNumber', 'Phone', 'phoneNumber', isEditDetails)}
						{this.renderField(
							'merchantDetails_businessEmail',
							'Email',
							'businessEmail',
							isEditDetails,
							'label--value type--break-word'
						)}
						{this.renderAdditionalEmail()}
						{!isGoPlus(merchant) &&
							!isAchq(merchant) &&
							this.renderBooleanField('lblPCI', 'PCI Compliant', 'pciCompliance.isCompliant')}
						{merchant.shouldShowTerminalId && this.renderField('lblTID', 'TID', 'terminalId')}
						{isFd(merchant) && this.renderBooleanField('lblIsEidsEnrolled', 'EIDS', 'isEidsEnrolled')}
					</div>
					{renderIf(isExpanded)(
						<div className="row">
							{this.renderField('lblAgent', 'Rep 1', 'agentName')}
							{this.renderField('lblTelemarketer', 'Rep 2', 'telemarketerName')}
							{this.renderField('lblAppId', 'App ID', 'appId')}
							<div className="col col-sml-12 col-med-6 col-lrg-12 form__group">
								<div className="form__group__header">
									<label className="form__group__label" htmlFor="lblApprovedDate">
										Approval Date
									</label>
								</div>
								<p
									className="type--p2 type--color--text--secondary type--is-empty"
									name="lblApprovedDate"
									id="lblApprovedDate"
								>
									<DisplayDateComponent value={merchant.dateApproved} />
								</p>
							</div>
							<div className="col col-sml-12 col-med-6 col-lrg-12 form__group">
								<div className="form__group__header">
									<label className="form__group__label" htmlFor="lblSubmittedDate">
										Date Submitted
									</label>
								</div>
								<p
									className="type--p2 type--color--text--secondary type--is-empty"
									name="lblSubmittedDate"
									id="lblSubmittedDate"
								>
									<DisplayDateComponent value={merchant.dateSigned} />
								</p>
							</div>
							<div className="col col-sml-12 col-med-6 col-lrg-12 form__group">
								<div className="form__group__header">
									<label className="form__group__label" htmlFor="lblSignedDate">
										Date Signed
									</label>
								</div>
								<p
									className="type--p2 type--color--text--secondary type--is-empty"
									name="lblSignedDate"
									id="lblSignedDate"
								>
									<DisplayDateComponent value={merchant.dateSigned} />
								</p>
							</div>
							<div className="col col-sml-12 col-med-6 col-lrg-12 form__group">
								<div className="form__group__header">
									<label className="form__group__label" htmlFor="lblTaxable">
										Taxable
									</label>
								</div>
								<p className="type--p2 type--color--text--secondary type--is-empty" name="lblTaxable" id="lblTaxable">
									N/A
								</p>
							</div>
						</div>
					)}
					{isEditDetails ? (
						<div className="flex--primary flex--gap--sml flex--no-wrap w--max--300 spc--bottom--lrg hide--from--med">
							<button className="btn btn--med btn--secondary" onClick={this.props.onCancel}>
								Cancel
							</button>
							<button className="btn btn--med btn--primary flex--grow--1" onClick={this.props.onSave}>
								Save
							</button>
						</div>
					) : null}
				</div>
			</Fragment>
		);
	}
}

AccountSummary.propTypes = {
	merchant: PropTypes.object,
	merchantDetails: PropTypes.object,
	isExpanded: PropTypes.bool,
	isEditDetails: PropTypes.bool,
	onBlur: PropTypes.func,
	onEdit: PropTypes.func,
	onSave: PropTypes.func,
	onChange: PropTypes.func,
	onCancel: PropTypes.func,
	openCloseModal: PropTypes.func,
};

export default withExpander(AccountSummary);
