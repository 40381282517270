import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import principalService from './../../../services/principalService';
import { get } from 'lodash';
import Points from '../../../components/Dropin/components/Points';

class UserAccountPanelComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user: null,
		};
	}
	componentDidMount() {
		const principal = principalService.get();
		this.setState({
			user: principal,
			email: get(principal, 'email', ''),
			showDropdown: false,
		});
	}
	get userAcronym() {
		const acronym = (this.state.user.name || '')
			.split(' ')
			.map(word => word[0])
			.join('')
			.toUpperCase();
		return acronym;
	}
	toggleHeaderDropdown = () => {
		if (this.props.isLogoutOnly) {
			this.props.handleLogout();
		}
		this.setState(prevState => ({ showDropdown: !prevState.showDropdown }));
	};

	renderImpersonator() {
		const { user } = this.state;

		const impersonator = user ? user.impersonatorUsername : '';
		return impersonator ? (
			<div className="header__dropdown--account__section">
				<div className="spc--left--sml--alt spc--top--tny spc--bottom--tny">
					<p className="type--title type--uppercase spc--bottom--tny">Impersonated by</p>
					<p className="type--p2 type--p2--medium">{impersonator}</p>
				</div>
			</div>
		) : null;
	}

	renderLoggedInSecretary() {
		const { user } = this.state;

		const loggedInSecretary = user ? user.agentSubName : '';
		return loggedInSecretary ? (
			<div className="header__dropdown--account__section">{loggedInSecretary}, secretary for:</div>
		) : null;
	}

	render() {
		const { user, email, showDropdown } = this.state;
		const { isLogoutOnly } = this.props;
		return user ? (
			<Fragment>
				<button className="header__account btn" onClick={this.toggleHeaderDropdown}>
					<div className="header__account__profile">{this.userAcronym}</div>
					<div className="header__account__name__wrapper">
						<div className="header__account__name">{user.name}</div>
						<div className="header__account__email">{email}</div>
						<Points />
					</div>
					{isLogoutOnly ? (
						<i className="icon icon--sml icon--logout align--v--neg--4"></i>
					) : (
						<i className="icon icon--sml icon--chevron--down"></i>
					)}
				</button>
				{showDropdown && (
					<div className="header__dropdown header__dropdown--account">
						{this.renderLoggedInSecretary()}
						{this.renderImpersonator()}
						<div className="header__dropdown--account__section">
							<ul>
								<li>
									<NavLink to="/logout" className="logout">
										Log Out
									</NavLink>
								</li>
							</ul>
						</div>
					</div>
				)}
			</Fragment>
		) : null;
	}
}
UserAccountPanelComponent.propTypes = {
	isLogoutOnly: PropTypes.bool,
	handleLogout: PropTypes.func,
};
export default UserAccountPanelComponent;
