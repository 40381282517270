import React, { useId } from 'react';
import { some, isEmpty, startsWith, toLower, get, find } from 'lodash';
import PropTypes from 'prop-types';
import { NumericFormat as NumberFormat } from 'react-number-format';

const EquipmentOption = props => {
	const {
		merchantEquipment,
		equipment,
		option,
		checkboxHandler,
		inputHandler,
		moreInfoHandler,
		listType,
		disableOption,
		suffix,
		classNames,
		disabledTooltip,
	} = props;
	const inputId = useId();
	let hasMerchantOptions = !!merchantEquipment && !isEmpty(merchantEquipment.equipmentOptions);
	let optionObj = equipment.equipmentOptions[option];
	let optionLabel = optionObj.friendlyName || option;
	const disabled = !merchantEquipment || disableOption;
	const dataType = toLower(optionObj.dataType);
	const useNumberFormat = some(['int', 'decimal'], item => item === dataType);
	const isDecimal = dataType === 'decimal';
	const componentProps = {
		className: 'input input--med',
		value: hasMerchantOptions ? merchantEquipment.equipmentOptions[option] : '',
		onValueChange: ({ value }) =>
			inputHandler({ target: { name: equipment.name + '_equipmentOptions__' + option, value, type: 'text' } }),
		disabled,
		allowNegative: false,
		inputType: 'numeric',
		isNumericString: true,
	};

	if (isDecimal) {
		componentProps.decimalScale = 4;
		componentProps.thousandSeparator = true;
	} else {
		componentProps.decimalScale = 0;
	}

	// options that are discrete true/false values -- render as checkbox
	if (dataType === 'boolean') {
		let isChecked =
			hasMerchantOptions &&
			(merchantEquipment.equipmentOptions[option] === 'true' ||
				startsWith(merchantEquipment.equipmentOptions[option], 'true|') ||
				merchantEquipment.equipmentOptions[option] === '1' ||
				startsWith(merchantEquipment.equipmentOptions[option], '1|'));
		const extendedInfoTooltip = findOptionTooltip(equipment.equipmentOptions[option].options, isChecked);

		return (
			<div className="checkbox">
				<div
					className={'datatooltip--200 datatooltip--bottom' + (!disabled && optionObj.isRequired ? ' required' : '')}
				>
					<div
						data-tooltip={disabled ? disabledTooltip : extendedInfoTooltip}
						className="display--ib datatooltip--150 datatooltip--right spc--bottom--tny"
					>
						<input
							className="input--check"
							type="checkbox"
							name={equipment.name + '_' + option + '_opt' + suffix}
							id={`${inputId}${equipment.name}_${option}_opt${suffix}`}
							onChange={checkboxHandler}
							value={option}
							disabled={disabled}
							checked={isChecked}
						/>
						<label htmlFor={`${inputId}${equipment.name}_${option}_opt${suffix}`}>{optionLabel}</label>
					</div>
					{renderRequiredInput(
						equipment.name,
						option,
						null,
						equipment.equipmentOptions[option].options,
						hasMerchantOptions,
						merchantEquipment,
						moreInfoHandler
					)}
				</div>
			</div>
		);
	} else if (equipment.equipmentOptions[option].isReadOnly) {
		return (
			<div className={classNames.optionWrapper}>
				<div className={`${classNames.labelWrapper || ''}`}>
					<label
						className="form__group__label"
						htmlFor={equipment.name + '_equipmentOptions__' + option}
						id={equipment.name + '_equipmentOptions__' + option}
					>
						{optionLabel}
					</label>
				</div>
				<div className={'datatooltip--200 datatooltip--bottom ' + classNames.inputWrapper}>
					<span className="label--sub">{hasMerchantOptions ? merchantEquipment.equipmentOptions[option] : ''}</span>
				</div>
			</div>
		);
	} else if (
		equipment.equipmentOptions[option].options.length === 1 &&
		equipment.equipmentOptions[option].options[0].value === ''
	) {
		const extendedInfoTooltip = get(equipment, `equipmentOptions[${option}].options[0].extendedInfo`) || null;
		return (
			<div className={classNames.optionWrapper}>
				<div className={`${classNames.labelWrapper || ''}`}>
					<label
						className="form__group__label"
						htmlFor={`${inputId}${equipment.name}_equipmentOptions__${option}`}
						id={equipment.name + '_equipmentOptions__' + option}
					>
						{optionLabel}
					</label>
					{!disabled && optionObj.isRequired ? (
						<span className="form__group__required" data-tooltip="Required">
							*
						</span>
					) : null}
				</div>
				<div
					className={'datatooltip--200 datatooltip--bottom ' + classNames.inputWrapper}
					data-tooltip={disabled ? disabledTooltip : extendedInfoTooltip}
				>
					{useNumberFormat ? (
						<NumberFormat {...componentProps} />
					) : (
						<input
							className="input input--med"
							type="text"
							name={equipment.name + '_equipmentOptions__' + option}
							id={`${inputId}${equipment.name}_equipmentOptions__${option}`}
							disabled={disabled}
							value={hasMerchantOptions ? merchantEquipment.equipmentOptions[option] : ''}
							onChange={inputHandler}
						/>
					)}
				</div>
			</div>
		);
	} else if (listType == 'radio') {
		return (
			<div className="spc--bottom--sml checkbox">
				<div className="flex--primary flex--gap--tny spc--bottom--sml--alt">
					<p
						className="type--title type--uppercase"
						htmlFor={equipment.name + '_equipmentOptions__' + option}
						id={equipment.name + '_equipmentOptions__' + option}
					>
						{optionLabel}
					</p>
					{!disabled && optionObj.isRequired ? (
						<span className="type--title type--color--error datatooltip--auto" data-tooltip="Required">
							*
						</span>
					) : null}
					{toLower(option) === 'cloverplan' ? (
						<i
							className="icon icon--tny icon--regular--info"
							data-tooltip="Clover Plans are not billed by Cardknox. Therefore this amount is not reflected in the order total during checkout."
						></i>
					) : null}
				</div>
				{equipment.equipmentOptions[option].options.map((opt, validx) => {
					let isSelected =
						hasMerchantOptions &&
						(merchantEquipment.equipmentOptions[option] === opt.value ||
							startsWith(merchantEquipment.equipmentOptions[option], opt.value + '|'));
					const extendedInfoTooltip = opt.extendedInfo || null;

					return (
						<React.Fragment key={validx}>
							<div className="flex--primary flex--nowrap spc--bottom--sml">
								<div
									className="datatooltip--200 datatooltip--bottom"
									data-tooltip={disabled ? disabledTooltip : extendedInfoTooltip}
								>
									<input
										type="radio"
										name={equipment.name + '_equipmentOptions__' + option}
										id={`${inputId}${equipment.name}_equipmentOptions__${option}_${validx}`}
										onChange={inputHandler}
										disabled={disabled}
										className="input--radio"
										key={validx}
										checked={isSelected}
										value={opt.value}
									></input>
									<label htmlFor={`${inputId}${equipment.name}_equipmentOptions__${option}_${validx}`}>
										{opt.value}
									</label>
								</div>
								<div className="spc--left--sml">
									{renderRequiredInput(
										equipment.name,
										option,
										opt,
										null,
										hasMerchantOptions,
										merchantEquipment,
										moreInfoHandler
									)}
								</div>
							</div>
						</React.Fragment>
					);
				})}
			</div>
		);
	} else {
		let selectedValue = hasMerchantOptions && merchantEquipment.equipmentOptions[option];
		if (selectedValue && selectedValue.indexOf('|') > -1) {
			selectedValue = selectedValue.substring(0, selectedValue.indexOf('|'));
		}
		if (selectedValue === undefined) selectedValue = '';
		return (
			<div className="fullwidth">
				<div
					className="form__group__header type--nowrap"
					data-tooltip={disabled || !optionObj.extendedInfo ? null : optionObj.extendedInfo}
				>
					<label
						className="form__group__label"
						htmlFor={`${inputId}${equipment.name}_equipmentOptions__${option}`}
						id={equipment.name + '_equipmentOptions__' + option}
					>
						{optionLabel}
					</label>
					{!disabled && optionObj.isRequired ? (
						<div className="form__group__required" data-tooltip="Required">
							*
						</div>
					) : null}
				</div>
				<div className="flex--primary flex--gap--med" data-tooltip={disabled ? disabledTooltip : null}>
					<div className="flex--grow--1">
						<select
							className="input input--med input--select"
							name={equipment.name + '_equipmentOptions__' + option}
							data-tooltip={optionLabel}
							id={`${inputId}${equipment.name}_equipmentOptions__${option}`}
							onChange={inputHandler}
							disabled={disabled}
							value={selectedValue}
						>
							{<option value="">Please select...</option>}
							{equipment.equipmentOptions[option].options.map((opt, validx) => {
								return (
									<option key={validx} value={opt.value}>
										{opt.value}
									</option>
								);
							})}
						</select>
					</div>
					{renderRequiredInput(
						equipment.name,
						option,
						null,
						equipment.equipmentOptions[option].options,
						hasMerchantOptions,
						merchantEquipment,
						moreInfoHandler
					)}
				</div>
			</div>
		);
	}
};

function renderRequiredInput(
	equipmentName,
	optionName,
	opt,
	optionsList,
	hasMerchantOptions,
	merchantEquipment,
	handleMoreInfo
) {
	let fullOptionVal = '',
		optionVal = '',
		sepIdx = -1;
	if (hasMerchantOptions) {
		fullOptionVal = merchantEquipment.equipmentOptions[optionName];
		optionVal = fullOptionVal;
		sepIdx = (optionVal && optionVal.indexOf('|')) || -1;
		if (sepIdx > -1) {
			optionVal = optionVal.substring(0, sepIdx);
		}

		if (!opt) {
			opt = optionsList.find(o => o.value === optionVal);
		}
	}

	let inputName = equipmentName + '_equipmentOptions__' + optionName + '_input';
	const extendedInfoTooltip = (opt && opt.extendedInfo) || null;

	if (opt && opt.requireInput) {
		let isSelectedOption = opt.value == optionVal;
		return (
			<div className="flex--grow--1">
				<input
					className="input input--med"
					type="text"
					name={inputName}
					id={inputName}
					disabled={!merchantEquipment || !isSelectedOption}
					value={
						isSelectedOption && sepIdx > -1 && fullOptionVal.length > sepIdx + 1
							? fullOptionVal.substring(sepIdx + 1)
							: ''
					}
					onChange={handleMoreInfo(optionName, opt.value)}
					data-tooltip={extendedInfoTooltip}
				/>
			</div>
		);
	} else if (optionsList && optionsList.find(o => o.requireInput)) {
		// have any that require input - keep a div there so the options shouldn't jump
		return (
			<div className="flex--grow--1">
				<input className="input input--med" type="text" name="placeholder" id="placeholder" value="" disabled={true} />
			</div>
		);
	}
}

const findOptionTooltip = (options, isChecked) => {
	const option = find(options, item => {
		if (isChecked) {
			return item.value == 1 || item.value == true;
		} else {
			return item.value == 0 || item.value == false;
		}
	});

	return option.extendedInfo || null;
};

EquipmentOption.defaultProps = {
	classNames: {
		optionWrapper: 'w--max--300',
		labelWrapper: 'form__group__header',
		inputWrapper: '',
	},
	suffix: '',
	disabledTooltip: null,
};

EquipmentOption.propTypes = {
	merchantEquipment: PropTypes.object,
	equipment: PropTypes.object.isRequired,
	option: PropTypes.string.isRequired,
	checkboxHandler: PropTypes.func.isRequired,
	inputHandler: PropTypes.func.isRequired,
	moreInfoHandler: PropTypes.func.isRequired,
	listType: PropTypes.oneOf(['radio', 'select']),
	disableOption: PropTypes.bool,
	classNames: PropTypes.object,
	suffix: PropTypes.string,
	disabledTooltip: PropTypes.any,
};

export default EquipmentOption;
