import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { OutsideClick } from '../../common/utilities';
import { withContext } from '../../common/components';
import { MerchantContext } from '../MerchantDetails';

class EquipmentTabsComponent extends Component {
	get tabs() {
		const tabs = {
			gateways: 'Gateways and Add-ons',
			hardware: 'Hardware',
		};
		if(!this.props.isAch){
			tabs.software = 'Software';
			tabs.accessories = 'Accessories';
			tabs.other = 'Other';
		}
		if (!this.props.isAch && this.props.merchant && !this.props.merchant.isCanadian) {
			tabs.posSystems = 'POS Systems';
		}
		return tabs;
	}
	state = {
		isNavOpen: false,
	};

	renderTab(tab) {
		return (
			<li
				key={tab}
				className={`tabs__link${this.props.currentTab == tab ? ' is-active' : ''}`}
				onClick={() => this.onTabSelect(tab)}
			>
				{this.tabs[tab]}
			</li>
		);
	}

	onTabSelect = tab => {
		this.props.onTabSelect(tab);
		this.onNavClick();
	};

	onNavClick = () => {
		if (!this.state.isNavOpen) return;
		this.openCloseNav();
	};

	openCloseNav = () => {
		this.setState({
			isNavOpen: !this.state.isNavOpen,
		});
	};

	render = () => {
		const tabs = this.tabs;
		const navigationList = Object.keys(tabs).map(t => {
			return this.renderTab(t);
		});
		return (
			<React.Fragment>
				<ul className="tabs spc--bottom--xlrg hide--to--sml">{navigationList}</ul>
				<OutsideClick action={this.onNavClick}>
					<div className="pos--rel spc--bottom--med hide--from--sml">
						<button className="input input--med input--select type--left" onClick={this.openCloseNav}>
							{tabs[this.props.currentTab]}
						</button>
						{this.state.isNavOpen ? (
							<div className="popover popover--down popover--fullwidth">
								<ul className="popover__list">{navigationList}</ul>
							</div>
						) : null}
					</div>
				</OutsideClick>
			</React.Fragment>
		);
	};
}

EquipmentTabsComponent.propTypes = {
	onTabSelect: PropTypes.func.isRequired,
	currentTab: PropTypes.string,
	merchant: PropTypes.shape({
		isCanadian: PropTypes.bool,
	}),
	isAch: PropTypes.bool,
};

export default withContext(EquipmentTabsComponent, MerchantContext, 'merchant');
