import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

class ModalComponent extends Component {
	render() {
		const { children, onClose, hideCloseButton, ...props } = this.props;
		const rootElement = document.getElementById('root');

		return (
			<ReactModal onRequestClose={onClose} appElement={rootElement} {...props}>
				<div className="modal__content__wrapper">{children}</div>
				{!hideCloseButton && (
					<button tabIndex="-1" className="btn btn--med btn--tertiary modal__close" onClick={onClose}>
						Close
					</button>
				)}
			</ReactModal>
		);
	}
}

ModalComponent.defaultProps = {
	hideCloseButton: false,
	shouldCloseOnOverlayClick: true,
	overlayClassName: 'modal__overlay',
	className: 'modal__content',
};

ModalComponent.propTypes = {
	children: PropTypes.element.isRequired,
	onClose: PropTypes.func.isRequired,
	isOpen: PropTypes.bool,
	overlayClassName: PropTypes.string,
	className: PropTypes.string,
	shouldCloseOnOverlayClick: PropTypes.bool,
};

export default ModalComponent;
