import React, { Component, createRef, Fragment } from 'react';
import { get, noop } from 'lodash';
import PropTypes from 'prop-types';

import { SidebarComponent } from '../components/sidebar';
import principalService from '../services/principalService';
import { Notification } from '../common/components/notifications';
import PrivacyPolicy from '../components/privacy-policy/PrivacyPolicy';

class MainLayoutComponent extends Component {
	constructor(props) {
		super(props);
		this.isBetaPartner = window.location.hostname === 'betapartner.cardknox.com';

		const showMerchantSidebar = props.location.pathname.indexOf('/merchants/') != -1;
		let isExpanded = true;
		let isSubExpanded = true;

		if (sessionStorage.getItem('sidebarExpanded') !== null) {
			const sidebarExpanded = JSON.parse(sessionStorage.getItem('sidebarExpanded'));
			isExpanded = sidebarExpanded;
		} else if (showMerchantSidebar) {
			isExpanded = false;
		}

		if (sessionStorage.getItem('sidebarSubExpanded') !== null) {
			const sidebarSubExpanded = JSON.parse(sessionStorage.getItem('sidebarSubExpanded'));
			isSubExpanded = sidebarSubExpanded;
		}

		this.state = {
			sidebarVisible: false,
			sidebarSubVisible: false,
			privacyPolicyVisible: false,
			showMerchantSidebar,
			showUserDropDown: false,
			user: null,
			isExpanded,
			isSubExpanded,
		};

		this.notificationRef = createRef();
	}

	componentDidMount() {
		this.collapseExpandSidebar();
		const principal = principalService.get();
		this.setState({
			user: principal,
		});

		if (window.innerWidth <= 768) {
			this.setState({ sidebarVisible: false, sidebarSubVisible: false, isExpanded: false });
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.location.pathname !== this.props.location.pathname) {
			if (
				window.innerWidth <= 768 &&
				this.state.sidebarVisible &&
				this.state.sidebarSubVisible &&
				this.state.isExpanded
			) {
				this.setState({
					sidebarVisible: false,
					sidebarSubVisible: false,
					isExpanded: false,
				});
			}
		}

		this.collapseExpandSidebar();
	}

	collapseExpandSidebar = () => {
		const collapseSidebar = JSON.parse(sessionStorage.getItem('collapseSidebar'));
		if (collapseSidebar !== null) {
			this.toggleExpandCollapseSidebar(collapseSidebar);
			delete sessionStorage.collapseSidebar;
		}
	};

	removeSubSidebar = () => {
		this.setState({
			sidebarSubVisible: false,
			sidebarVisible: false,
		});
	};

	toggleExpandCollapseSidebar = (isExpanded = this.state.isExpanded) => {
		this.setState({ isExpanded: !isExpanded }, () => {
			sessionStorage.setItem('sidebarExpanded', this.state.isExpanded);
		});
	};

	toggleSubSidebar = () => {
		const { sidebarSubVisible, sidebarVisible } = this.state;
		this.setState({
			sidebarSubVisible: !sidebarSubVisible,
			sidebarVisible: !sidebarVisible,
		});
	};

	toggleExpandCollapseSubSidebar = () => {
		const { isSubExpanded } = this.state;
		this.setState(
			{
				isSubExpanded: !isSubExpanded,
			},
			() => {
				sessionStorage.setItem('sidebarSubExpanded', this.state.isSubExpanded);
			}
		);
	};

	renderMfaRequired = (isMfaRequired, isExpanded, sidebarCssClass, collapsedClass, sidebarSubCssClass, version) => {
		return isMfaRequired ? null : (
			<Fragment>
				<button
					type="button"
					className={`btn btn--action btn--sidebar-toggle ${collapsedClass}`}
					onClick={() => this.toggleExpandCollapseSidebar()}
				>
					<i
						className={`icon${isExpanded ? ' menu-collapse' : ' menu-expand'}`}
						data-tooltip={isExpanded ? 'Collapse Menu' : 'Expand Menu'}
					/>
				</button>
				<div className={`l--aside${sidebarCssClass}${collapsedClass}${sidebarSubCssClass}`}>
					<SidebarComponent
						isExpanded={isExpanded}
						addNotification={get(this.notificationRef, 'current.addNotification', noop)}
						history={this.props.history}
					/>

					<div className={`sidebar__footer${!isExpanded ? ' is-collapsed' : ''}`}>
						{!isExpanded && <i className="icon icon--sml icon--info--light"></i>}
						<div className={`sidebar__footer__privacy${!isExpanded ? ' is-collapsed' : ''}`}>
							<PrivacyPolicy showCopyright />
							<p>({version})</p>
						</div>
					</div>
				</div>
			</Fragment>
		);
	};

	render() {
		const { children } = this.props;
		const { sidebarVisible, sidebarSubVisible, showMerchantSidebar, isExpanded, isSubExpanded } = this.state;
		const sidebarCssClass = sidebarVisible ? ' is-revealed' : '';
		const collapsedClass = isExpanded ? ' sidebar-not-collapsed' : ' is-collapsed';
		const hiddenClass = principalService.get().isQaUser ? ' l--sidebar-hidden' : '';

		const sidebarSubCssClass = sidebarSubVisible ? ' is-sub-revealed' : '';
		const collapsedSubClass = isSubExpanded ? '' : ' is-sub-collapsed';

		const version = process.env.AppBuildTag;
		const isMfaRequired = get(this.props.history, 'location.state.isMfaRequired');
		return (
			<div className={`${hiddenClass}`}>
				<Notification ref={this.notificationRef} />
				{showMerchantSidebar && (
					<header className="header--sub--mobile">
						<button
							onClick={this.toggleSubSidebar}
							type="button"
							className="btn btn--reset header--mobile__btn spc--left--auto"
						>
							<i className="icon icon--sml icon--menu--positive"></i>
						</button>
					</header>
				)}
				{this.renderMfaRequired(
					isMfaRequired,
					isExpanded,
					sidebarCssClass,
					collapsedClass,
					sidebarSubCssClass,
					version
				)}
				<div
					id="topOfPage"
					className={`l--main${sidebarCssClass}${collapsedClass}${showMerchantSidebar ? ' subnav-visible' : ''} ${
						this.props?.customLayoutClassName
					}`}
				>
					{this.isBetaPartner && (
						<div className="l--main__banner">
							Betapartner.cardknox.com is closing down soon. Please be sure to use Partner.cardknox.com instead to
							prevent disruption. Thank you.
						</div>
					)}
					<div className={`l--content l--content--no-bottom-padding${showMerchantSidebar ? ' subnav-visible' : ''}`}>
						{showMerchantSidebar && (
							<div
								id="merchantSidebarPortal"
								className={`l--aside--sub${sidebarSubCssClass}${collapsedSubClass}${collapsedClass}`}
							/>
						)}
						{children}
					</div>
				</div>
			</div>
		);
	}
}

MainLayoutComponent.propTypes = {
	history: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
	customLayoutClassName: PropTypes.string,
};

export default MainLayoutComponent;
