import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
	cloneDeep,
	map,
	toLower,
	find,
	sumBy,
	some,
	has,
	findIndex,
	isEmpty,
	get,
	each,
	compact,
	transform,
	first,
	filter,
	split,
	noop,
} from 'lodash';
import { NumericFormat as NumberFormat } from 'react-number-format';

import { AddressComponent, AddressDisplayComponent } from '../../common/components/address';
import { formatCurrency } from '../../helpers/currency-formatter';
import { getThumbnailOverlay, focusField, renderIf, scrollTo } from './../../common/utilities';
import { appService } from '../../services/appService';
import HardwarePurchasePlanComponent from './hardware-purchase-plan';
import FrontendProcessorComponent from './frontend-processor';
import EquipmentOption from './equipment-option';
import { ToggleContainer } from './../../common/components/toggle';
import { Modal } from '../../common/components/modal';
import { defaultImplicitParse, defaultReactOutput } from 'simple-markdown';
import EquipmentFileUpload from './EquipmentFileUpload';
import { MerchantContext } from '../MerchantDetails';
import { withContext } from '../../common/components';
import OptionComponent from './components/OptionComponent';
import QuantityStepper from './components/QuantityStepper';

class HardwareListItemComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			shippingResults: null,
			isAccessoriesPopupOpen: false,
			accessories: cloneDeep(get(this.props.merchantHardware, 'accessories', [])),
			showExistingAppInput: false,
			showReasonInput: false,
			isSerialNumberValid: true,
			serialNumberValidationMessage: '',
		};

		this.scrollTo = scrollTo(props.hardware);
		this.focusField = focusField(props.hardware);
		this.setDefaults();
	}

	get canSelectPlatform() {
		const merchantHardware = this.props.merchantHardware;
		let routeToCardknox = get(merchantHardware, 'equipmentOptions.RouteToCardknox', false);

		return routeToCardknox == 0 || routeToCardknox == 'false';
	}

	get shouldValidateSerialNumber() {
		if (
			(this.props.hardware.category === 'Terminal' && this.props.hardware.subcategory.startsWith('Wireless')) ||
			this.props.hardware.category === 'Clover'
		)
			return true;

		return false;
	}

	get equipmentFiles() {
		const {
			hardware: { equipmentOptions },
			merchantHardware,
		} = this.props;
		return transform(equipmentOptions, (acc, item, key) => {
			if (item.dataType !== 'file') return;
			if (!this.props.optionIsAvailableForRules(item, merchantHardware)) return;
			if (
				item.dependentOnName &&
				(!merchantHardware.equipmentOptions[item.dependentOnName] ||
					merchantHardware.equipmentOptions[item.dependentOnName] !== item.dependentOnValue)
			) {
				return;
			}
			acc[key] = item;
		});
	}

	validateSerialNumber = e => {
		const merchantHardware = cloneDeep(this.props.merchantHardware);
		merchantHardware.subcategory = this.props.hardware.subcategory;
		appService
			.validateSerialNumber(merchantHardware)
			.then(validationResult => {
				let {
					isSerialNumberValid,
					errorMessage,
					requireAppOfExisitingSerial,
					requireReasonForTransfer,
				} = validationResult.validation;
				let needHardwareChange = false;

				if (isSerialNumberValid) errorMessage = 'Serial number is valid';
				if (!requireAppOfExisitingSerial && !!merchantHardware.appWithActiveSerialNumber) {
					merchantHardware.appWithActiveSerialNumber = '';
					needHardwareChange = true;
				}
				if (!requireReasonForTransfer && !!merchantHardware.reasonToTransferFromAnotherAgent) {
					merchantHardware.reasonToTransferFromAnotherAgent = '';
					needHardwareChange = true;
				}

				this.setState({
					showExistingAppInput: requireAppOfExisitingSerial,
					showReasonInput: requireReasonForTransfer,
					isSerialNumberValid: isSerialNumberValid,
					serialNumberValidationMessage: errorMessage,
				});
				if (needHardwareChange) this.props.onChange(merchantHardware);
			})
			.catch(e =>
				this.setState({
					serialNumberValidationMessage: e,
					isSerialNumberValid: false,
				})
			);
	};

	openAccessoriesPopup = () => this.setState({ isAccessoriesPopupOpen: true }, this.defaultAllPaperAccessories);

	closeAccessoriesPopup = () =>
		this.setState({
			isAccessoriesPopupOpen: false,
			accessories: cloneDeep(get(this.props.merchantHardware, 'accessories', [])),
		});

	defaultAllPaperAccessories = () => {
		const { hardware } = this.props;
		const { accessories } = this.state;

		each(hardware.accessories, ({ subcategory, equipmentId, equipmentOptions }) => {
			const isPaper = toLower(subcategory) === 'paper';
			const hasCaseOrRollOption = some(equipmentOptions, e =>
				some(['caseorroll', 'case/roll'], item => item === toLower(e.name))
			);

			if (isPaper && hasCaseOrRollOption && !find(accessories, a => a.equipmentId == equipmentId)) {
				this.defaultAccessoryQuantity(true, equipmentId);
			}
		});
	};

	toggleAccessory = (equipmentId, isSelected = false) => {
		let accessories = cloneDeep(this.state.accessories);
		let acc = find(accessories, { equipmentId });

		if (!acc) {
			this.toggleNewAccessory(isSelected, acc, accessories, equipmentId);
		} else {
			this.toggleExistingAccessory(isSelected, acc);

			if (!isSelected) {
				accessories = filter(this.state.accessories, a => a.equipmentId != equipmentId);
			}
		}

		this.setState({ accessories }, () => {
			if (!isSelected) {
				this.saveHardwareAccessories();
			}
		});
	};

	toggleNewAccessory = (isSelected, acc, accessories, equipmentId) => {
		if (isSelected) {
			const sourceAcc = this.props.hardware.accessories.find(a => a.equipmentId == equipmentId);
			acc = this.props.createNewEquipment(equipmentId, isSelected, false, true);
			acc.name = sourceAcc ? sourceAcc.name : '';
			accessories.push(acc);
		}
	};

	toggleExistingAccessory = (isSelected, acc) => {
		if (isSelected) {
			acc.quantity = isSelected ? (acc.quantity || 0) + 1 : 0;
			acc.isSelected = isSelected;
		}
	};

	addAccessoriesToHardware = () => {
		this.setState({ isAccessoriesPopupOpen: false });
		this.saveHardwareAccessories();
	};

	saveHardwareAccessories = () => {
		const hardware = { ...this.props.merchantHardware, accessories: this.state.accessories };
		this.selectHardware(hardware);
		this.props.onChange(hardware);
	};

	hardwareRemove = e => {
		const hardware = cloneDeep(this.props.merchantHardware);
		hardware.isSelected = false;
		hardware.quantity = 1;

		if (!isEmpty(hardware.accessories)) {
			hardware.accessories = [];
			this.setState({ accessories: [] });
		}

		this.props.onChange(hardware);
	};

	purchaseTypeSelect = e => {
		const hardware = cloneDeep(this.props.merchantHardware);
		if (e.target.checked) {
			hardware.purchaseType = e.target.value;
		} else if (hardware.purchaseType == e.target.value) {
			hardware.purchaseType = ''; // clear
		}
		// get fees for the new purchase type
		let purchasePlan = this.props.hardware.purchasePlans.find((p, i) => p.planId == hardware.purchasePlanId);
		this.copyFees(purchasePlan, hardware, hardware.purchaseType);
		this.selectHardware(hardware);
		this.props.onChange(hardware);
	};

	copyFees(sourceObj, destObj, purchaseType) {
		if (!sourceObj || !destObj) {
			return;
		}
		purchaseType = purchaseType || this.props.merchantHardware.purchaseType;

		destObj.fees = cloneDeep(sourceObj.fees.filter(f => f.purchaseTypes.includes(purchaseType)));
		each(destObj.fees, fee => {
			if (!has(fee, 'merchantPrice')) fee.merchantPrice = fee.retailPrice;
		});
	}

	optionSelect = e => {
		let hardware = cloneDeep(this.props.merchantHardware);
		if (e.target.checked) {
			hardware.equipmentOptions[e.target.value] = '1';
		} else if (Object.keys(hardware.equipmentOptions).includes(e.target.value)) {
			hardware.equipmentOptions[e.target.value] = '0';
		}
		this.selectHardware(hardware);
		this.props.onChange(hardware);
	};

	optionSetMoreInfo = (optKey, optValue) => e => {
		let hardware = cloneDeep(this.props.merchantHardware);
		hardware.equipmentOptions[optKey] = `${optValue}|${e.target.value}`;
		this.selectHardware(hardware);
		this.props.onChange(hardware);
	};

	handleFeeChange = (planId, feeId) => ({ floatValue }) => {
		let hardware = cloneDeep(this.props.merchantHardware);
		let fee = hardware.fees.find((fee, i) => fee.feeId === feeId);
		fee.merchantPrice = floatValue;
		this.props.onChange(hardware);
	};

	handleTimeChange = time => {
		let hardware = cloneDeep(this.props.merchantHardware);
		hardware.batchoutTime = time;
		this.props.onChange(hardware);
	};

	handleShippingOptionChange = e => {
		let hardware = cloneDeep(this.props.merchantHardware);
		let opt = e.target.value;
		if (opt.toLowerCase() == 'merchantdba') {
			hardware.shippingAddress = this.props.physicalAddress;
		} else if (opt.toLowerCase() == 'merchant') {
			hardware.shippingAddress = this.props.corporateAddress;
		} else if (opt.toLowerCase() == 'agent' || opt.toLowerCase() == 'pickup') {
			hardware.shippingSpeed = '';
			hardware.shippingAddress = null;
			hardware.saturdayDelivery = false;
		} else {
			// hardware.shippingaddress = {streetAddress: '', city: '', state: '', zip: ''}
		}
		hardware.shippingOption = opt;
		this.selectHardware(hardware);
		this.props.onChange(hardware);
	};

	handleAddressChange = ({ target: { value, name } }) => {
		const { merchantHardware } = this.props;
		const hardware = { ...merchantHardware };
		const [section, key] = split(name, '_');

		hardware[section] = {
			...hardware[section],
			[key]: value,
		};
		this.selectHardware(hardware);

		this.props.onChange(hardware);
	};

	calculateShipping = () => {
		const hardware = this.props.merchantHardware;
		let fromAddress = {
			streetAddress: '465 Oak Glen Road',
			city: 'Howell Township',
			state: 'NJ',
			zip: '07731',
		};
		appService
			.getShippingRates(
				fromAddress,
				hardware.shippingAddress,
				this.props.hardware.shippingWeight * hardware.quantity,
				hardware.satDelivery,
				hardware.shippingSpeed
			)
			.then(res => {
				this.setState({ shippingResults: res });
			})
			.catch(ex => {
				console.log('Error calculating shipping rate ' + ex);
				this.setState({ shippingResults: 'Error calculating shipping rate.' });
			});
	};

	handleChange = (e, packageType) => {
		let hardware = cloneDeep(this.props.merchantHardware);
		let hardwareName = this.props.hardware.name;

		let itemToSet, itemKey;
		let strName = e.target.name;
		if (strName.startsWith(hardwareName + '_')) strName = strName.substr(hardwareName.length + 1);

		if (strName.indexOf('_') > 0) {
			let keyList = compact(strName.split('_'));
			itemToSet = keyList.reduce((prev, curItem, idx) => {
				if (idx < keyList.length - 1) {
					return prev[curItem];
				}
				return prev;
			}, hardware);
			itemKey = keyList[keyList.length - 1];
		} else {
			itemToSet = hardware;
			itemKey = strName;
		}
		//console.log('form item was ' + e.target.name);
		//console.log('updating ' + itemKey + ' property of the ' + itemToSet + ' object');
		let newVal = e.target.value;
		if (e.target.type && e.target.type == 'checkbox') newVal = e.target.checked;

		itemToSet[itemKey] = newVal;

		if (packageType) {
			const option = find(
				this.props.hardware.equipmentOptions,
				o => toLower(o.name).indexOf(`${toLower(newVal)} quantity`) > -1
			);

			if (option) {
				hardware.quantity = parseInt(get(first(option.options), 'value', 1));
			}
		}
		this.selectHardware(hardware);

		this.props.onChange(hardware);
	};

	adjustQuantity = e => {
		const hardware = cloneDeep(this.props.merchantHardware);
		if (e.target.name === 'increase') {
			hardware.quantity += 1;
			this.selectHardware(hardware);
		} else if (e.target.name === 'decrease' && hardware.quantity > 0) {
			hardware.quantity -= 1;
			this.selectHardware(hardware);
		}
		this.props.onChange(hardware);
	};

	selectHardware = hardware => {
		if (this.props.hardware.outOfStock && hardware.purchaseType === 'purchase') return;
		if (hardware.quantity > 0 || this.props.merchantHardware.quantity === 0) {
			hardware.isSelected = true;
			hardware.quantity = Math.max(hardware.quantity, 1);
		} else {
			hardware.isSelected = false;
		}
	};

	handleAccessoriesPriceChange = equipmentId => ({ floatValue }) => {
		const { accessories } = this.state;
		const newState = { accessories: [...accessories] };
		const accIndex = findIndex(accessories, { equipmentId });

		if (accIndex === -1) {
			const acc = this.props.createNewEquipment(equipmentId, false, false, true);
			acc.quantity = 0;
			const feeIndex = findIndex(acc.fees, ({ feeType }) => toLower(feeType) === 'onetimefee');

			if (feeIndex === -1) {
				return;
			}

			acc.fees[feeIndex].merchantPrice = floatValue;
			newState.accessories.push(acc);
		} else {
			const feeIndex = findIndex(accessories[accIndex].fees, ({ feeType }) => toLower(feeType) === 'onetimefee');

			if (feeIndex === -1) {
				return;
			}

			newState.accessories[accIndex].fees[feeIndex] = {
				...newState.accessories[accIndex].fees[feeIndex],
				merchantPrice: floatValue,
			};
		}

		this.setState(newState);
	};

	handleAccessoriesEquipmentOptionChange = (isQuantity, equipmentId) => e => {
		const {
			target: { value, name },
		} = e;
		const accessories = [...this.state.accessories];
		const [index, key, optionKey] = name.split('_');
		const hasChanged = isQuantity
			? value != get(this.state.accessories, `${index}.${key}`)
			: value != get(this.state.accessories, `${index}.${key}.${optionKey}`);
		const accIndex = findIndex(accessories, { equipmentId });

		if (accIndex == -1) {
			const acc = this.props.createNewEquipment(equipmentId, true, false, true);

			if (isQuantity) {
				acc.quantity = parseInt(value || 0);
			} else {
				acc.quantity = 0;
				acc[key][optionKey] = value;
			}

			accessories.push(acc);
		} else {
			if (!hasChanged) {
				return;
			}

			if (isQuantity) {
				accessories[index][key] = parseInt(value || 0);
			} else {
				accessories[index].quantity = 0;
				accessories[index][key] = {
					...accessories[index][key],
					[optionKey]: value,
				};
			}

			accessories[index].isSelected = true;
		}

		this.setState({ accessories }, () => this.defaultAccessoryQuantity(!isQuantity, equipmentId));
	};

	defaultAccessoryQuantity = (isQuantity, equipmentId) => {
		if (isQuantity) {
			const { accessories } = this.state;
			const hardwareAccessory = find(this.props.hardware.accessories, a => a.equipmentId == equipmentId);

			if (hardwareAccessory) {
				const { equipmentOptions } = hardwareAccessory;
				const stateAccessory = find(accessories, a => a.equipmentId == equipmentId);
				const accIndex = findIndex(accessories, { equipmentId });
				const type = toLower(get(stateAccessory, 'equipmentOptions.CaseOrRoll', 'case'));
				const option = find(equipmentOptions, o => toLower(o.name).indexOf(`${type} quantity`) > -1);
				let value = 0;

				if (option) {
					value = get(option.defaultValue || first(option.options), 'value', 0);
				}

				this.handleAccessoriesEquipmentOptionChange(
					isQuantity,
					equipmentId
				)({ target: { name: accIndex + '_quantity', value } });
			}
		}
	};

	adjustAccessoriesQuantity = equipmentId => ({ target: { name } }) => {
		const { accessories } = this.state;
		const newState = { accessories: [...accessories] };
		const accIndex = findIndex(accessories, { equipmentId });

		if (accIndex < 0) {
			const acc = this.props.createNewEquipment(equipmentId, name === 'increase', false, true);
			newState.accessories.push({ ...acc, quantity: name === 'increase' ? 1 : 0 });
		} else {
			newState.accessories[accIndex] = {
				...accessories[accIndex],
				isSelected: true,
				quantity: name === 'increase' ? accessories[accIndex].quantity + 1 : accessories[accIndex].quantity - 1,
			};

			if (newState.accessories[accIndex].quantity < 0) {
				return;
			}
		}

		this.setState(newState);
	};

	setDefaults() {
		let { hardware } = this.props;

		let merchantHardware;
		let changed = false;
		if (this.props.merchantHardware) {
			merchantHardware = cloneDeep(this.props.merchantHardware);
		} else {
			merchantHardware = this.props.createNewEquipment(
				hardware.equipmentId,
				undefined,
				undefined,
				undefined,
				undefined,
				undefined,
				0
			);
			changed = true;
		}

		if (!Object.keys(merchantHardware).includes('shippingAddress') || !merchantHardware.shippingAddress) {
			merchantHardware.shippingAddress = { streetAddress: '', city: '', state: '', zip: '' };
			changed = true;
		}
		if (!Object.keys(merchantHardware).includes('satDelivery')) {
			merchantHardware.satDelivery = false;
			changed = true;
		}
		const platforms = hardware.frontendProcessors ? Object.keys(hardware.frontendProcessors) : [];
		if (platforms.length === 1) {
			merchantHardware.platform = platforms[0];
			changed = true;
		}
		each(hardware.equipmentOptions, ({ dataType, isReadOnly, options }, option) => {
			if (dataType === 'boolean' || isReadOnly || options.length !== 1 || options[0].value === '') return;
			merchantHardware.equipmentOptions[option] = options[0].value;
			changed = true;
		});
		if (changed) {
			this.props.onChange(merchantHardware);
		}
	}

	optionIsAvailableForPurchaseType(opt, hardware, merchantHardware) {
		let optionIsAvailable = true;
		hardware.purchasePlans.forEach(p => {
			let dependentFee = p.fees.find(fee => fee.dependencySettings && fee.dependencySettings[opt.name]);
			//console.log(p.purchaseTypes, dependentFee.purchaseTypes, merchantHardware.purchaseType);
			if (
				dependentFee &&
				(!p.purchaseTypes.includes(merchantHardware.purchaseType) ||
					!dependentFee.purchaseTypes.includes(merchantHardware.purchaseType))
			)
				optionIsAvailable = false;
		});
		//console.log(optionIsAvailable);
		return optionIsAvailable;
	}

	handleRemoveFile = (fileType, i) => {
		let item = cloneDeep(this.props.merchantHardware);
		item.attachment.file = {};
		this.props.onChange(item);
	};

	onDropFile = (fileType, acceptedFiles) => {
		let item = cloneDeep(this.props.merchantHardware);
		// will really be only a single file, but param type is an array
		item.attachment = {};
		each(acceptedFiles, f => (item.attachment.file = f));
		console.log(item.attachment.file);
		this.props.onChange(item);
	};

	checkIfShouldRenderPaperQuantityModule = (isPaper, equipmentId, equipmentOptions) => {
		const { accessories } = this.state;
		const accessory = find(accessories, a => a.equipmentId == equipmentId);
		const caseOrRoll = get(accessory, 'equipmentOptions.CaseOrRoll');

		return (
			isPaper &&
			some(equipmentOptions, ({ name, dependentOnName, dependentOnValue }) => {
				const loweredDependentOnName = toLower(dependentOnName);
				return (
					toLower(name) === 'quantity' &&
					(loweredDependentOnName === 'case/roll' || loweredDependentOnName === 'caseorroll') &&
					toLower(caseOrRoll) === toLower(dependentOnValue)
				);
			})
		);
	};

	renderAccessoriesPopup = () => {
		const {
			hardware,
			merchantHardware: { paymentSchedule },
		} = this.props;
		const { isAccessoriesPopupOpen, accessories } = this.state;
		const hasSelectedItems = some(accessories, ({ isSelected }) => isSelected);
		const hardwareAccessories = hardware.accessories;
		const isBillAgentPaymentSchedule = toLower(paymentSchedule) === 'billagent';
		const tooltip = isBillAgentPaymentSchedule ? 'Price is not editable when Payment Schedule is Bill Agent.' : null;
		return (
			<Modal
				shouldCloseOnOverlayClick={false}
				isOpen={isAccessoriesPopupOpen}
				onClose={this.closeAccessoriesPopup}
				className="modal__content modal--lrg"
			>
				<div>
					<div className="modal__header">
						<h4>{hardware.name} Add-ons</h4>
					</div>
					<div className="modal__body">
						<div className="modal--accessories-list">
							{map(
								hardwareAccessories,
								({ equipmentId, name, description, purchasePlans, subcategory, equipmentOptions, outOfStock }) => {
									const accessory = accessories.find(a => a.equipmentId == equipmentId);
									let quantity = accessory ? accessory.quantity : 0;
									const plan = purchasePlans[0];
									const fee = find(plan.fees, { feeType: 'OneTimeFee' });
									let price = accessory
										? get(find(accessory.fees, { feeType: 'OneTimeFee' }), 'merchantPrice')
										: fee.retailPrice;
									const isPaper = toLower(subcategory) === 'paper';

									return (
										<div className="addon--popup" key={`${equipmentId}.${name}`}>
											<div
												className={`spc--bottom--med checkout__img pos--rel${getThumbnailOverlay(
													outOfStock,
													hardware.purchaseTypes
												)}`}
												style={{
													backgroundImage:
														'url(' +
														process.env.REACT_APP_CDN_URL +
														toLower(name)
															.replace(' ', '_')
															.replace(/[^a-z0-9_-]/gi, '') +
														'/thumbnail.png' +
														')',
												}}
												alt={name}
											></div>
											<div className="flex--secondary flex--column flex--top fullheight">
												<div className="flex--primary flex--gap--sml flex--top fullwidth spc--bottom--lrg">
													<h5>{name}</h5>
													{description && (
														<i
															className="icon icon--sml icon--regular--info spc--top--tny"
															data-tooltip={description}
														/>
													)}
												</div>
												<div className={`flex--primary flex--gap--sml--alt${isPaper ? '' : ' flex--nowrap '}`}>
													<div
														className="datatooltip--250 datatooltip--bottom fullwidth"
														data-tooltip={isBillAgentPaymentSchedule ? tooltip : null}
													>
														<NumberFormat
															className="input input--sml input--currency type--right"
															value={isBillAgentPaymentSchedule ? fee.agentCost : price}
															thousandSeparator={true}
															fixedDecimalScale={true}
															decimalScale={2}
															disabled={isBillAgentPaymentSchedule}
															onValueChange={
																isBillAgentPaymentSchedule ? noop : this.handleAccessoriesPriceChange(equipmentId)
															}
														/>
													</div>
													{isPaper && (
														<OptionComponent
															accessoryName="CaseOrRoll"
															equipmentId={equipmentId}
															equipmentOptions={equipmentOptions}
															handleChange={this.handleAccessoriesEquipmentOptionChange(false, equipmentId)}
															data={this.props.merchantHardware}
															accessories={this.state.accessories}
														/>
													)}
													<div className={isPaper ? 'fullwidth' : 'w--108 flex--no-shrink'}>
														{this.renderQuantityModule(isPaper, equipmentId, equipmentOptions, quantity, outOfStock)}
													</div>
												</div>
											</div>
										</div>
									);
								}
							)}
						</div>
					</div>
					<div className="modal__footer">
						<button
							className="btn btn--med btn--primary"
							onClick={this.addAccessoriesToHardware}
							disabled={!hasSelectedItems}
						>
							Add-on items ({hasSelectedItems ? sumBy(accessories, ({ quantity }) => parseInt(quantity || 0)) : 0})
						</button>
					</div>
				</div>
			</Modal>
		);
	};

	renderDependentOptions = (mainOptionName, hardware, merchantHardware, index, errorListPaths) => {
		// console.log(mainOptionName);
		return (
			<Fragment>
				{Object.values(hardware.equipmentOptions)
					.filter(o => mainOptionName == o.dependentOnName && o.dataType !== 'file')
					.map((dependentOpt, idx) => {
						let disableOption =
							!merchantHardware.equipmentOptions[mainOptionName] ||
							merchantHardware.equipmentOptions[mainOptionName] != dependentOpt.dependentOnValue;
						if (disableOption) return null;
						return (
							<Fragment>
								<div>
									<EquipmentOption
										merchantEquipment={merchantHardware}
										equipment={hardware}
										option={dependentOpt.name}
										index={index}
										errorListPaths={errorListPaths}
										listType="radio"
										checkboxHandler={this.optionSelect}
										moreInfoHandler={this.optionSetMoreInfo}
										inputHandler={this.handleChange}
										disableOption={disableOption}
									/>
								</div>
								{this.renderDependentOptions(dependentOpt.name, hardware, merchantHardware, index, errorListPaths)}
							</Fragment>
						);
					})}
			</Fragment>
		);
	};

	renderEquipmentFiles = merchantHardware => {
		const { getAuthorizationFormName, downloadPrefilledAuthorizationForm } = this.props;
		const file = get(merchantHardware, 'attachment.file');

		return (
			!isEmpty(this.equipmentFiles) && (
				<div className="col col-sml-12 col-xlrg-6 spc--bottom--sml">
					<section>
						<label className="label display--b spc--bottom--sml">File Attachment</label>
						{!!getAuthorizationFormName() && (
							<div
								data-tooltip={
									!merchantHardware.isSelected ? 'Equipment must be Added to Cart before downloading.' : null
								}
							>
								<button
									onClick={downloadPrefilledAuthorizationForm}
									className="btn btn--med btn--primary spc--bottom--med"
									style={{ cursor: !merchantHardware.isSelected ? 'not-allowed' : 'pointer' }}
								>
									Download Authorization Form
								</button>
							</div>
						)}
						<EquipmentFileUpload
							equipmentFiles={this.equipmentFiles}
							file={file}
							onDropFile={this.onDropFile}
							handleRemoveFile={this.handleRemoveFile}
						/>
					</section>
				</div>
			)
		);
	};

	renderEquipmentOptions = (hardware, merchantHardware, errorListPaths, isPaper) => {
		return Object.values(hardware.equipmentOptions).filter(o => !o.dependentOnName).length > 0 ? (
			<div className="spc--bottom--xxlrg">
				<h5 className="spc--bottom--sml--alt">Features and Setup</h5>
				<div>
					<div className="row">
						{Object.values(hardware.equipmentOptions)
							.filter(o => !o.dependentOnName && o.dataType !== 'file')
							.map((opt, idx) => {
								if (opt.name == 'BatchSettlementType') return null; // handled separately
								if (!this.optionIsAvailableForPurchaseType(opt, hardware, merchantHardware)) return;
								if (!this.props.optionIsAvailableForRules(opt, merchantHardware)) return;

								return (
									<div className="col col-sml-12 col-xlrg-6 spc--bottom--sml" key={opt.name}>
										<EquipmentOption
											merchantEquipment={merchantHardware}
											equipment={hardware}
											option={opt.name}
											index={idx}
											errorListPaths={errorListPaths}
											listType="radio"
											checkboxHandler={this.optionSelect}
											moreInfoHandler={this.optionSetMoreInfo}
											inputHandler={e => this.handleChange(e, isPaper)}
										/>
										<div>{this.renderDependentOptions(opt.name, hardware, merchantHardware, idx, errorListPaths)}</div>
									</div>
								);
							})}

						{this.renderEquipmentFiles(merchantHardware)}
					</div>
				</div>
			</div>
		) : null;
	};

	renderPurchaseType = (hardware, merchantHardware) => {
		const { isSerialNumberValid, showReasonInput, showExistingAppInput, serialNumberValidationMessage } = this.state;

		return (
			<div className="spc--bottom--xxlrg">
				<p className="type--title type--uppercase spc--bottom--sml--alt">Purchase type</p>
				<div>
					<div>
						{Object.keys(hardware.purchaseTypes).map(pt => {
							const checked = merchantHardware.purchaseType == pt;
							const isPurchaseAndDisabled = hardware.outOfStock && toLower(pt) === 'purchase';
							const isCustomerOwnedOption = toLower(pt) === 'existing' && checked && hardware.category !== 'Clover';
							let tooltip = null;

							if (isPurchaseAndDisabled) {
								tooltip = 'Out of Stock';
							}

							return (
								<div key={pt} className="spc--bottom--sml" data-tooltip={tooltip}>
									<div>
										<input
											className="input--radio"
											type="radio"
											name={hardware.name + '_' + pt + '_pt'}
											id={hardware.name + '_' + pt + '_pt'}
											onChange={this.purchaseTypeSelect}
											value={pt}
											checked={checked}
											disabled={isPurchaseAndDisabled}
										/>
										<label htmlFor={hardware.name + '_' + pt + '_pt'}>{hardware.purchaseTypes[pt]}</label>
									</div>

									{isCustomerOwnedOption && (
										<div className="notes notes--primary spc--top--med spc--bottom--lrg">
											<div className="icon"></div>
											<div>
												<p className="type--p3 spc--bottom--sml">
													Depending on the device and specific scenario, there may be various steps that need to be
													taken to properly set up the hardware, which may including loading OS and/or XPI files and
													encrypting the device. Occasionally, the device may need to be shipped to a third party vendor
													for encryption.
												</p>
												<p className="type--p3 spc--bottom--sml">
													Please note, even with the appropriate actions taken, not all customer owned devices may work
													properly. And in certain circumstances, a device might not be able to revert back to it
													original state.
												</p>
												<p className="type--p3">Kindly consult technical support with any additional questions.</p>
											</div>
										</div>
									)}

									{toLower(pt) === 'rollover' && checked && (
										<div className="form__group">
											<div className="form__group__header">
												<p className="form__group__label">Original EquipmentID</p>
											</div>
											<input
												type="text"
												className="input input--med"
												name={hardware.name + '_originalEquipmentId'}
												placeholder="EquipmentId"
												value={hardware.originalEquipmentId}
												onChange={this.handleChange}
											/>
										</div>
									)}
								</div>
							);
						})}
					</div>
					{(merchantHardware.purchaseType === 'existing' || merchantHardware.purchaseType === 'agent_owned') && (
						<div className="row">
							<div className="col col-sml-12 col-med-4 form__group">
								<div className="form__group__header">
									<label className="form__group__label" htmlFor="serialNumber">
										Serial Number
									</label>
									<span className="form__group__required" data-tooltip="Required">
										*
									</span>
								</div>
								<div className="flex--primary flex--gap--sml">
									<input
										className="input input--med"
										type="text"
										id="serialNumber"
										name="serialNumber"
										value={merchantHardware.serialNumber}
										onChange={this.handleChange}
									/>
									{this.shouldValidateSerialNumber && (
										<button className="btn btn--med btn--primary" onClick={this.validateSerialNumber}>
											Validate
										</button>
									)}
								</div>
								{!isSerialNumberValid ? (
									<div className="col col-sml-12 col-med-4 form__group">
										<div className="type--validation">{serialNumberValidationMessage}</div>
									</div>
								) : (
									serialNumberValidationMessage && (
										<div className="type--color--success">{serialNumberValidationMessage}</div>
									)
								)}
							</div>
							{this.shouldValidateSerialNumber && (
								<Fragment>
									{(!!showExistingAppInput || !!merchantHardware.appWithActiveSerialNumber) && (
										<div className="col col-sml-12 col-med-4 form__group">
											<div className="form__group__header">
												<label className="form__group__label" htmlFor="appWithActiveSerialNumber">
													App ID or MID this serial number is currently active on
												</label>
											</div>
											<input
												className="input input--med"
												type="text"
												name="appWithActiveSerialNumber"
												value={merchantHardware.appWithActiveSerialNumber}
												onChange={this.handleChange}
											/>
										</div>
									)}
									{(!!showReasonInput || !!merchantHardware.reasonToTransferFromAnotherAgent) && (
										<div className="col col-sml-12 col-med-4 form__group">
											<div className="form__group">
												<label className="form__group__label" htmlFor="reasonToTransferFromAnotherAgent">
													Reason for transferring serial to another agent
												</label>
											</div>
											<input
												className="input input--med"
												type="text"
												name="reasonToTransferFromAnotherAgent"
												value={merchantHardware.reasonToTransferFromAnotherAgent}
												onChange={this.handleChange}
											/>
										</div>
									)}
								</Fragment>
							)}
						</div>
					)}
				</div>
			</div>
		);
	};

	renderQuantityModule = (isPaper, equipmentId, equipmentOptions, quantity, outOfStock) =>
		isPaper ? (
			<OptionComponent
				accessoryName="Quantity"
				equipmentId={equipmentId}
				equipmentOptions={equipmentOptions}
				handleChange={this.handleAccessoriesEquipmentOptionChange(true, equipmentId)}
				data={this.props.merchantHardware}
				accessories={this.state.accessories}
			/>
		) : (
			<QuantityStepper
				quantity={quantity}
				adjustQuantity={this.adjustAccessoriesQuantity(equipmentId)}
				disabled={outOfStock}
			/>
		);

	render() {
		let { hardware, merchantHardware } = this.props;
		let errorList, errorListPaths;
		if (merchantHardware) {
			errorList = merchantHardware.errors;
			// make sure error object is valid -- otherwise ignore it
			// this is because it seems JSON.stringify doesn't handle this object properly and saves it empty
			errorListPaths = errorList && errorList.length > 0 && errorList.map(e => e.path);
		}

		const isPaper = get(merchantHardware, 'equipmentOptions.CaseOrRoll');
		const isPickup = toLower(get(merchantHardware, 'shippingOption', '')) === 'pickup';
		const outOfStock = hardware.outOfStock;
		const disableAddButton = outOfStock && get(merchantHardware, 'purchaseType') === 'purchase';
		const isCanadianRental = this.props.merchant.isCanadian && get(merchantHardware, 'purchaseType') === 'rental';

		return merchantHardware ? (
			<React.Fragment>
				{this.renderAccessoriesPopup()}
				<div className="equipment__detail">
					<div className="spc--bottom--xlrg">
						<div className="flex--primary flex--gap--sml spc--bottom--med">
							<button type="button" className="btn btn--link" onClick={this.props.handleBackClick}>
								<i className="icon icon--med icon--chevron--left"></i>
							</button>
							<h4>{hardware.name}</h4>
						</div>
						<p className="type--p3 type--color--text--light spc--bottom--med">{hardware.description}</p>
						{merchantHardware.isSelected && errorListPaths ? (
							<ul className="type--validation__list spc--bottom--med">
								{' '}
								{errorList.map(elem => {
									return (
										<li key={elem.path}>
											<div
												className="btn btn--link btn--link--error flex--gap--tny type--validation"
												onClick={() => {
													let elemId = elem.path.replace(/[.]/g, '_');
													this.scrollTo(elemId);
													this.focusField(elemId);
												}}
											>
												{defaultReactOutput(defaultImplicitParse(elem.message))}
											</div>
										</li>
									);
								})}
							</ul>
						) : null}
					</div>

					{renderIf(hardware.datasheetUrl)(
						<div className="spc--bottom--xxlrg">
							<input
								className="input--check"
								type="checkbox"
								name="includeDataSheet"
								id="includeDataSheet"
								onChange={this.handleChange}
							/>
							<label htmlFor="includeDataSheet">
								Include{' '}
								<a className="btn btn--link" href={hardware.datasheetUrl} target="_blank" rel="noreferrer">
									Datasheet
								</a>
							</label>
						</div>
					)}
					{/* Purchase type */}

					{this.renderPurchaseType(hardware, merchantHardware)}
					<ToggleContainer>
						{/* Platform and Settlement */}
						{hardware.frontendProcessors && Object.keys(hardware.frontendProcessors).length > 0 && (
							<Fragment>
								<p className="type--title type--uppercase spc--bottom--sml--alt">Platform and Settlement</p>
								<FrontendProcessorComponent
									equipment={hardware}
									merchantEquipment={merchantHardware}
									handleChange={this.handleChange}
									handleTimeChange={this.handleTimeChange}
									optionSelect={this.optionSelect}
									optionSetMoreInfo={this.optionSetMoreInfo}
									enabled={this.canSelectPlatform}
									errorListPaths={errorListPaths}
								/>
							</Fragment>
						)}
						{/* Features */}
						{this.renderEquipmentOptions(hardware, merchantHardware, errorListPaths, isPaper)}
						{/* Shipping */}
						{merchantHardware.purchaseType != 'existing' && merchantHardware.purchaseType != 'agent_owned' && (
							<div className="spc--bottom--xxlrg">
								<div className="flex--primary flex--gap--tny spc--bottom--sml--alt">
									<p className="type--title type--uppercase">Shipping Details</p>
									<span className="type--title type--color--error datatooltip--auto" data-tooltip="Required">
										*
									</span>
								</div>
								<div id="shippingOption">
									<div className="flex--primary flex--top flex--column flex--gap--sml spc--bottom--lrg">
										{Object.keys(hardware.shippingOptions).map(opt => {
											return (
												<Fragment key={opt}>
													<div>
														<input
															className="input--radio"
															type="radio"
															value={opt}
															checked={merchantHardware.shippingOption === opt}
															name="shippingOption"
															id={'selectedShippingOption_' + opt}
															onChange={this.handleShippingOptionChange}
														/>
														<label htmlFor={'selectedShippingOption_' + opt}>{hardware.shippingOptions[opt]}</label>
													</div>
													{opt.toLowerCase() === 'merchantdba' && (
														<AddressDisplayComponent address={this.props.physicalAddress} />
													)}
													{opt.toLowerCase() === 'merchant' && (
														<AddressDisplayComponent address={this.props.corporateAddress} />
													)}
													{opt.toLowerCase() === 'other' && merchantHardware.shippingOption === opt && (
														<AddressComponent
															address={merchantHardware.shippingAddress || {}}
															namePrefix="shippingAddress"
															required={true}
															onChange={this.handleAddressChange}
															showName={true}
														/>
													)}
												</Fragment>
											);
										})}
									</div>

									<div className="row">
										<div className="col col-sml-12 col-med-6">
											<div className="form__group__header">
												<p className="form__group__label">Shipping Speed</p>
												{isPickup ? null : (
													<span className="form__group__required" data-tooltip="Required">
														*
													</span>
												)}
											</div>
											<select
												className="input input--med input--select"
												name="shippingSpeed"
												id="shippingSpeed"
												onChange={this.handleChange}
												value={merchantHardware.shippingSpeed}
												disabled={isPickup}
											>
												<option value="">Please select...</option>
												{Object.keys(hardware.shippingSpeeds).map(opt => {
													return (
														<option key={opt} value={opt}>
															{hardware.shippingSpeeds[opt]}
														</option>
													);
												})}
											</select>
											{!this.props.merchant.isCanadian && (
												<div className="spc--top--sml">
													<input
														className="input--check"
														type="checkbox"
														id="saturdayDelivery"
														name="saturdayDelivery"
														checked={merchantHardware.saturdayDelivery}
														onChange={this.handleChange}
														disabled={isPickup}
													/>
													<label htmlFor="saturdayDelivery">Saturday Delivery</label>
												</div>
											)}
										</div>
									</div>
									{/*shipping cost*/}
									{!!hardware.shippingWeight && (
										<div className="spc--bottom--sml">
											<button className="btn btn--link btn--link--underline" onClick={this.calculateShipping}>
												Calculate Shipping Costs
											</button>
											{this.state.shippingResults && this.state.shippingResults.serviceCosts && (
												<div className="notes notes--default spc--top--sml">
													{Object.keys(this.state.shippingResults.serviceCosts).map(key => {
														return (
															<div key={key}>
																{key} Price: {formatCurrency(this.state.shippingResults.serviceCosts[key])}
															</div>
														);
													})}
												</div>
											)}

											{this.state.shippingResults && !this.state.shippingResults.serviceCosts && (
												<div className="type--validation spc--bottom--lrg spc--top--sml">
													{this.state.shippingResults}
												</div>
											)}
										</div>
									)}
								</div>
							</div>
						)}
					</ToggleContainer>
					{merchantHardware.purchaseType != 'existing' &&
						merchantHardware.purchaseType != 'agent_owned' &&
						!isCanadianRental && (
							<div className="row spc--bottom--xxlrg">
								<div className="col col-sml-12 col-med-6">
									<div className="form__group__header">
										<p className="form__group__label">Payment Schedule</p>
										<span className="form__group__required" data-tooltip="Required">
											*
										</span>
									</div>
									<select
										className="input input--med input--select"
										name="paymentSchedule"
										id="paymentSchedule"
										value={merchantHardware.paymentSchedule}
										onChange={this.handleChange}
									>
										<option value="">Please select...</option>
										{Object.keys(hardware.paymentSchedules).map(opt => {
											return (
												<option key={opt} value={opt}>
													{hardware.paymentSchedules[opt]}
												</option>
											);
										})}
									</select>
								</div>
							</div>
						)}
					<div className="form__group">
						<div className="form__group__header">
							<p className="form__group__label">Notes</p>
						</div>
						<textarea
							className="input input--textarea"
							type="text"
							placeholder="Write note here..."
							name="notes"
							value={merchantHardware.notes}
							onChange={this.handleChange}
						/>
					</div>
				</div>
				<div>
					<div className="equipment__details__aside">
						<div className="equipment__details__aside__card spc--bottom--med">
							{/* <img className="icon icon--xlrg" src={"/static/media/hardware/" + hardware.name.toLowerCase() + ".svg"} alt={hardware.name} /> */}
							<div
								className={`checkout__img pos--rel${getThumbnailOverlay(outOfStock, hardware.purchaseTypes)}`}
								style={{
									backgroundImage:
										'url(' +
										process.env.REACT_APP_CDN_URL +
										hardware.name
											.toLowerCase()
											.replace(' ', '_')
											.replace(/[^a-z0-9_-]/gi, '') +
										'/thumbnail.png' +
										')',
								}}
								alt={hardware.name}
							></div>
							{hardware.purchasePlans
								.filter(plan => plan.planId == merchantHardware.purchasePlanId)
								.map(plan => {
									return (
										<HardwarePurchasePlanComponent
											key={plan.planId}
											plan={plan}
											merchantFees={merchantHardware.fees}
											purchaseType={merchantHardware.purchaseType}
											quantity={merchantHardware.quantity}
											options={merchantHardware.equipmentOptions}
											onFeeChange={this.handleFeeChange}
											accessories={merchantHardware.accessories}
											toggleAccessory={this.toggleAccessory}
											displayAddonsButton={!isEmpty(hardware.accessories)}
											openAccessoriesPopup={this.openAccessoriesPopup}
											outOfStock={outOfStock}
											purchaseTypes={hardware.purchaseTypes}
											paymentSchedule={merchantHardware.paymentSchedule}
											quantityComponentProps={{
												isPaper,
												data: merchantHardware,
												options: hardware?.equipmentOptions,
												disableAddButton,
												adjustQuantity: this.adjustQuantity,
												handleOptionChange: this.handleChange,
												accessories: this.state.accessories,
											}}
										/>
									);
								})}
						</div>
					</div>
				</div>
			</React.Fragment>
		) : null;
	}
}

HardwareListItemComponent.propTypes = {
	hardware: PropTypes.object.isRequired,
	merchantHardware: PropTypes.object,
	downloadPrefilledAuthorizationForm: PropTypes.func.isRequired,
	getAuthorizationFormName: PropTypes.func.isRequired,
	merchant: PropTypes.object.isRequired,
	physicalAddress: PropTypes.object.isRequired,
	corporateAddress: PropTypes.object.isRequired,
	optionIsAvailableForRules: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	handleBackClick: PropTypes.func.isRequired,
	createNewEquipment: PropTypes.func.isRequired,
};

export default withContext(HardwareListItemComponent, MerchantContext, 'merchant');
