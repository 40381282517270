import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { map, isEmpty, has, find, sortBy, trim, split, get } from 'lodash';

import { departmentOptions, isCanada } from '../../../common/utilities';
import { appService, principalService } from '../../../services';
import { FileDropzoneComponent } from '../../../common/components/file-dropzone';

const required = (
	<span data-tooltip="Required" className="form__group__required">
		{' '}
		*
	</span>
);

const sortedTicketOptionsAll = sortBy(departmentOptions, 'label');
const sortedTicketOptionsWithoutCad = sortedTicketOptionsAll.filter(arr => arr.label.indexOf('CA') !== 0);
class NewTicketComponent extends Component {
	constructor(props) {
		super(props);

		const { dba = '', mid = '', appId = '' } = props;
		const principal = principalService.get();
		const email = principal.agentSubEmail ? principal.agentSubEmail : principal.email;

		this.state = {
			ticketFields: {
				dba,
				mid,
				appId,
				toEmail: '',
				subject: '',
				issue: '',
				uploadNewFile: '1',
				newFile: {},
				existingFile: null,
			},
			fromEmail: email.indexOf(',') > -1 ? trim(split(email, ',')[0]) : email,
			fromName: principal.agentSubName ? principal.agentSubName : principal.name,
			isCanadaAccount: null,
			errorMessages: null,
			isLoading: false,
			isSubmit: false,
			existingFileList: null,
		};
	}

	async componentDidMount() {
		const { appId } = this.props;
		if (!appId) return;

		this.showLoader(true);
		try {
			const { files, isCanadian } = await appService.getEApp(appId);
			this.setState({
				isCanadaAccount: isCanadian,
				existingFileList: map(files, file => ({ label: file.fileName, value: file })),
			});
		} catch (err) {
			console.log('getEApp error', err);
			this.setState({ errorMessage: 'An error occurred: ' + err });
		} finally {
			this.showLoader(false);
		}
	}

	readFile = file => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = () => resolve(reader.result);
			reader.onerror = reject;
			reader.readAsDataURL(file);
		});
	};

	handleSave = async () => {
		try {
			const { ticketFields, existingFileList } = this.state;
			this.setState({ isSubmit: true });
			this.showLoader(true);
			const { uploadNewFile, newFile, existingFile, ...params } = ticketFields;
			if (this.validateInputs()) {
				if (ticketFields.uploadNewFile === '1' && Object.keys(ticketFields.newFile).length) {
					params.fileAttachment = newFile[0].file;
				} else if (ticketFields.uploadNewFile === '0' && ticketFields.existingFile) {
					const { parentId, fileId, fileName } = find(existingFileList, { label: ticketFields.existingFile }).value;
					const blob = await appService.getMerchantFile(parentId, fileId, fileName, true);
					if (blob.errorMessage) {
						throw new Error(blob.errorMessage);
					}
					params.fileAttachment = new File([blob], ticketFields.existingFile);
				}
				await appService.createNewTicket(params).then(response => {
					this.props.addNotification({
						message: `Ticket #${response.ticketId} created successfully.`,
						success: true,
					});
					this.props.closeModal();
				});
			}
		} catch (e) {
			console.log(e);
			this.setState({ errorMessages: { error: e } });
		}
		this.showLoader();
	};

	handleRemoveFile = () => {
		this.setState(({ ticketFields }) => ({
			ticketFields: {
				...ticketFields,
				newFile: null,
			},
		}));
	};

	onDropFile = (fileType, acceptedFiles) => {
		let newFilesList = acceptedFiles.map((itm, i) => {
			return { file: this.getFile(itm), fileDescription: '' };
		});
		this.setState({ ticketFields: { ...this.state.ticketFields, newFile: newFilesList } });
	};

	getFile(file) {
		return new File([file], file.name, {
			type: file.type,
			lastModified: file.lastModified,
		});
	}

	handleChange = ({ target: { name, value } }) =>
		this.setState({ ticketFields: { ...this.state.ticketFields, [name]: value } }, this.validateInputs);

	handleMerchantChange = ({ target: { value } }) => {
		const merchant = find(this.props.merchantOptions, { label: value });
		const { appId, mid, dba } = get(merchant, 'value', { appId: '', mid: '', dba: '' });
		this.setState({
			isCanadaAccount: isCanada(get(merchant, 'value')),
			ticketFields: {
				...this.state.ticketFields,
				appId,
				mid,
				dba,
			},
		});
	};

	showLoader = (value = false) => this.setState({ isLoading: value });

	validateInputs = () => {
		const {
			ticketFields: { subject, issue, dba, toEmail },
		} = this.state;
		const newState = {
			errorMessages: {},
		};

		if (!dba) {
			newState.errorMessages.dba = 'DBA is required.';
		}
		if (!subject) {
			newState.errorMessages.subject = 'Subject is required.';
		}
		if (!issue) {
			newState.errorMessages.issue = 'Issue is required.';
		}
		if (!toEmail) {
			newState.errorMessages.toEmail = 'Ticket Type is required.';
		}
		this.setState(newState);
		return isEmpty(newState.errorMessages);
	};

	getInputProps = (key, baseClassName = '') => {
		const { errorMessages, isSubmit, ticketFields } = this.state;
		const value = get(ticketFields, key, '');
		return {
			className: `${baseClassName}${isSubmit && has(errorMessages, key) ? ' is-invalid' : ''}`,
			name: key,
			id: key,
			onChange: this.handleChange,
			value,
		};
	};

	renderTextInput = (key, { disabled }) => {
		return <input {...this.getInputProps(key, 'input input--med')} disabled={disabled} />;
	};

	renderDropdownInput = (key, { options, disabled, onChange }) => {
		const defaultProps = this.getInputProps(key, 'input input--med input--select');
		if (onChange) {
			defaultProps.onChange = onChange;
		}
		return (
			<select {...defaultProps} disabled={disabled}>
				<option value="">Please select...</option>
				{map(options, ({ tooltip = null, label }, optionKey) => (
					<option title={tooltip} key={`${label}.${optionKey}`} value={label}>
						{label}
					</option>
				))}
			</select>
		);
	};

	renderTextboxInput = (key, { disabled }) => {
		return <textarea {...this.getInputProps(key, 'input input--textarea')} disabled={disabled} cols={15} rows={5} />;
	};

	renderInput = (key, label, isRequired, inputRenderer = this.renderTextInput, inputProps = {}) => {
		return (
			<div key={key} className="form__group">
				<div className="form__group__header">
					<label className="form__group__label" htmlFor={key}>
						{label}
					</label>
					{isRequired ? required : null}
				</div>
				{inputRenderer(key, inputProps)}
			</div>
		);
	};

	renderRadio = key => {
		const { ticketFields } = this.state;
		const trueKey = `${key}.true`;
		const falseKey = `${key}.false`;
		return (
			<div className="flex--primary flex--gap--xxlrg spc--top--med">
				<div>
					<input
						{...this.getInputProps(key, 'input input--radio')}
						id={trueKey}
						type="radio"
						value="1"
						checked={ticketFields[key] === '1'}
					/>
					<label htmlFor={trueKey}>Upload new file</label>
				</div>
				<div>
					<input
						{...this.getInputProps(key, 'input input--radio')}
						id={falseKey}
						type="radio"
						value="0"
						checked={ticketFields[key] === '0'}
					/>
					<label htmlFor={falseKey}>Upload existing file</label>
				</div>
			</div>
		);
	};

	renderFileUpload = () => {
		const { ticketFields, existingFileList } = this.state;
		const { dba, mid, appId } = this.props;
		return (
			<React.Fragment>
				{dba || mid || appId ? this.renderInput('uploadNewFile', 'I want to', false, this.renderRadio) : null}
				{ticketFields.uploadNewFile === '1' ? (
					<FileDropzoneComponent
						multiple={false}
						showDescription={false}
						fileList={ticketFields.newFile}
						onDrop={this.onDropFile}
						onRemoveFile={this.handleRemoveFile}
					/>
				) : (
					this.renderInput('existingFile', 'Select merchant file bucket', false, this.renderDropdownInput, {
						options: existingFileList,
					})
				)}
			</React.Fragment>
		);
	};

	render() {
		const { errorMessages, isLoading, isSubmit, isCanadaAccount } = this.state;
		const { dba, mid, appId, merchantOptions } = this.props;
		const isMerchant = dba || mid || appId;

		return (
			<div>
				<div className="modal__header">
					<h4>Add a new ticket</h4>
				</div>
				<div className="modal__body">
					{isLoading ? (
						<div className="loader--reload">
							<div className="loader--reload__icon"></div>
						</div>
					) : (
						<Fragment>
							{isSubmit &&
								errorMessages &&
								map(errorMessages, (error, key) => (
									<div key={key} className="type--validation">
										{error}
									</div>
								))}
							{this.renderInput('dba', 'DBA', true, isMerchant ? undefined : this.renderDropdownInput, {
								disabled: isMerchant,
								options: merchantOptions,
								onChange: isMerchant ? undefined : this.handleMerchantChange,
							})}
							{this.renderInput('appId', 'App ID', false, undefined, { disabled: true })}
							{this.renderInput('mid', 'MID', false, undefined, { disabled: true })}
							{this.renderInput('toEmail', 'Department', true, this.renderDropdownInput, {
								options: isCanadaAccount ? sortedTicketOptionsAll : sortedTicketOptionsWithoutCad,
							})}
							{this.renderInput('subject', 'Subject', true)}
							{this.renderInput('issue', 'Issue', true, this.renderTextboxInput)}
							{this.renderFileUpload()}
						</Fragment>
					)}
				</div>
				<div className="modal__footer">
					<button className="btn btn--med btn--primary" onClick={this.handleSave} disabled={isLoading}>
						Create Ticket
					</button>
				</div>
			</div>
		);
	}
}

NewTicketComponent.propTypes = {
	dba: PropTypes.string,
	mid: PropTypes.string,
	appId: PropTypes.string,
	merchantOptions: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			value: PropTypes.object.isRequired,
		})
	),
	addNotification: PropTypes.func.isRequired,
	closeModal: PropTypes.func.isRequired,
};

export default NewTicketComponent;
