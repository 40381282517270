import React, { Component } from 'react';
import PropTypes from 'prop-types';

class LabelSelectionComponent extends Component {
	get labelValue() {
		return this.props.filter.values.label;
	}

	filterRemoved = e => {
		this.props.onFilterRemoved({
			id: 'label',
			emptyValue: null,
		});
	};

	render() {
		return (
			<div className="filter__toolbar__selected-filter">
				Label: <span className="type--wgt--medium">{this.labelValue}</span>
				<button onClick={this.filterRemoved} className="btn btn--link datatooltip--auto">
					<i className="icon icon--tny icon--close--text"></i>
				</button>
			</div>
		);
	}
}

LabelSelectionComponent.propTypes = {
	filter: PropTypes.object.isRequired,
	onFilterRemoved: PropTypes.func.isRequired,
};

export default LabelSelectionComponent;
