import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
	orderBy,
	toLower,
	each,
	noop,
	last,
	dropRight,
	camelCase,
	flatMap,
	filter,
	clone,
	sumBy,
	memoize,
	endsWith,
} from 'lodash';
import { ExportToCsv } from 'export-to-csv';

import { TotalAmountCountComponent } from '../../common/components/columns/formatters';
import { exportOptions } from '../../common/components/export/export-options';
import { Grid } from '../../common/components/react-data-grid';
import { WhiteRenderer } from '../../common/components/row';
import { ExportComponent } from '../../common/components/export';

const otherWidth = width => (width >= 768 ? 180 : 130);
const countWidth = width => (width >= 768 ? 130 : 110);

class BreakdownGrid extends Component {
	constructor(props) {
		super(props);
		const columns = this.createColumns(props.columns);
		this.state = {
			data: null,
			gridHeight: null,
			defaultColumns: clone(columns),
			columns,
		};
	}

	createColumns = (columns = this.props.columns) => {
		const { seperateCountColumn } = this.props;

		return [
			{
				key: 'income_source',
				name: 'Income Source',
				sortable: false,
				width: otherWidth(window.innerWidth),
				widthCalc: otherWidth,
				visible: true,
			},
			...flatMap(columns, transactionType => [
				{
					key: camelCase(`${transactionType}Count`),
					name: `${transactionType} Count`,
					visible: seperateCountColumn,
					sortable: true,
					widthCalc: seperateCountColumn ? countWidth : noop,
				},
				{
					key: camelCase(`${transactionType}Amount`),
					name: seperateCountColumn ? 'Total Amount' : transactionType,
					sortable: true,
					formatter: TotalAmountCountComponent,
					getRowMetaData: ({ [camelCase(`${transactionType}Count`)]: count }) =>
						seperateCountColumn ? { seperateCountColumn } : count,
					width: otherWidth(window.innerWidth),
					widthCalc: otherWidth,
					visible: true,
				},
			]),
		];
	};

	componentDidMount = () => {
		window.addEventListener('resize', this.setGridSizes);
		const data = this.getData();
		this.setState(
			{
				data,
			},
			() => {
				this.setGridSizes();
				this.props.disableExport();
			}
		);
	};

	componentDidUpdate = ({ totals }) => {
		if (totals !== this.props.totals) {
			this.setGridSizes();
		}
	};

	componentWillUnmount = () => {
		window.removeEventListener('resize', this.setGridSizes);
	};

	getGridHeight = data => {
		const rowCount = this.rowsCount(data);
		const columnHeight = 48;
		const scrollSize = 16;
		const headerSize = 35;
		const canvasMargin = window.innerWidth >= 1200 ? 7 : window.innerWidth >= 768 ? 13 : 11;
		const offset =
			canvasMargin +
			(window.innerWidth >= 1200
				? headerSize
				: window.innerWidth >= 768
				? headerSize + scrollSize
				: scrollSize + headerSize);
		const height = rowCount * columnHeight + offset;
		const minGridContainerHeight = 144;

		return Math.max(minGridContainerHeight, height);
	};

	getGridWidth = () => {
		const visibleColumns = this.getDisplayedColumns(this.state.columns);
		const widths = sumBy(visibleColumns, 'width') + 6;
		return widths || undefined;
	};

	setGridHeight = () => {
		this.setState({
			gridHeight: this.getGridHeight(),
		});
	};

	setColumnWidths = () => {
		const width = window.innerWidth;
		const columns = clone(this.state.columns);
		each(columns, column => {
			column.width = column.widthCalc(width, this.props);
		});
		this.setState({ columns });
	};

	setGridSizes = () => {
		this.setColumnWidths();
		this.setGridHeight();
	};

	getData = () => {
		const { totals, columns } = this.props;
		const data = [];

		each(this.props.typeList, type => {
			if (totals[camelCase(`${type}TotalCount`)]) {
				const row = { income_source: type };
				each(columns, transactionType => {
					row[camelCase(`${transactionType}Amount`)] =
						parseFloat(totals[camelCase(`${type}${transactionType}Amount`)].toFixed(2)) || 0;
					row[camelCase(`${transactionType}Count`)] = totals[camelCase(`${type}${transactionType}Count`)] || 0;
				});
				data.push(row);
			}
		});
		return data;
	};

	rowsCount = (data = this.state.data) => {
		const rows = data || [];
		return rows.length;
	};

	rowGetter = i => {
		const t = this.state.data[i];

		if (t != null) {
			return t;
		}
	};

	getDisplayedColumns = memoize(columns => filter(columns, ({ visible }) => visible));

	renderEmptyGrid = () => {
		return (
			<div className="spc--top--sml">
				<div className="emptystate">
					<div className="emptystate__title">0 Results</div>
					<p className="emptystate__message">{this.props.emptyMessage}</p>
				</div>
			</div>
		);
	};

	onGridSort = (sortKey, sortDirection) => {
		let data = clone(this.state.data);
		if (sortDirection === 'NONE') {
			data = this.getData();
		} else {
			const lastElement = last(data);
			if (this.props.displayTotal) {
				data = dropRight(data, 1);
			}
			data = orderBy(data, [item => item[sortKey]], [toLower(sortDirection)]);
			if (this.props.displayTotal) {
				data.push(lastElement);
			}
		}

		this.setState({ data });
	};

	getColumns = columns => {
		let returnObj = [];
		for (let col of columns) {
			returnObj[col.key] = col.name.replace(/\u00AD/g, '');
		}
		return returnObj;
	};

	replaceColumns = (data, columnsSource) => {
		const columns = this.getColumns(columnsSource);
		const mappedData = [];
		for (let item of data) {
			let newObj = {};
			const columnKeys = Object.keys(columns);
			for (let colKey of columnKeys) {
				const newPropName = columns[colKey];
				if (endsWith(toLower(colKey), 'amount')) {
					newObj[newPropName] = `$${item[colKey]}`;
				} else {
					newObj[newPropName] = item[colKey];
				}
			}
			mappedData.push(newObj);
		}
		return mappedData;
	};

	download = filename => {
		const { dateRange } = this.props;
		const properties = { filename, ...exportOptions };
		if (dateRange) {
			properties.showTitle = true;
			properties.title = dateRange;
		}
		const exporter = new ExportToCsv(properties);
		const data = this.replaceColumns(this.state.data, this.state.columns);
		exporter.generateCsv(data);
	};

	renderExportButton() {
		const { type } = this.props;
		const visibleColumns = this.getDisplayedColumns(this.state.columns);

		return (
			<div className="flex flex--primary">
				<ExportComponent
					data={this.state.data}
					columns={visibleColumns}
					showLoaderMethod={noop}
					exportType={type}
					type={type}
					showDropdown={false}
				/>
			</div>
		);
	}

	render = () => {
		const { gridHeight, columns } = this.state;
		return (
			<React.Fragment>
				<div className="modal__header">
					<div className="modal__header__title modal__header__title--alt">
						<div>Total by Income Source</div>
					</div>
					<div className="flex--primary spc--bottom--sml">{this.renderExportButton()}</div>
				</div>
				<div className="modal__body modal__body--total-type">
					<div className="grid__holder--override grid__holder__breakdown">
						<Grid
							minWidth={this.getGridWidth()}
							minHeight={gridHeight}
							columns={this.getDisplayedColumns(columns)}
							rowsCount={this.rowsCount()}
							rowGetter={this.rowGetter}
							emptyRowsView={this.renderEmptyGrid}
							rowRenderer={<WhiteRenderer />}
							onGridSort={this.onGridSort}
							rowHeight={48}
							enableRowSelect={null}
							rowScrollTimeout={null}
						/>
					</div>
				</div>
			</React.Fragment>
		);
	};
}

BreakdownGrid.defaultProps = {
	disableExport: noop,
};

BreakdownGrid.propTypes = {
	totals: PropTypes.object.isRequired,
	displayTotal: PropTypes.bool,
	columns: PropTypes.array,
	typeList: PropTypes.array,
	seperateCountColumn: PropTypes.bool,
	dateRange: PropTypes.string,
	disableExport: PropTypes.func.isRequired,
	emptyMessage: PropTypes.string,
};

export default BreakdownGrid;
