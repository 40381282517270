import React, { Component } from 'react';

function withExpander(WrappedComponent, useIcon, showMoreText, showLessText) {
	return class ExpanderComponent extends Component {
		constructor(props) {
			super(props);

			this.state = {
				isExpanded: this.props.isExpanded || false,
			};
		}

		showMore = (isExpanded = false) => {
			this.setState({
				isExpanded: isExpanded || !this.state.isExpanded,
			});
		};

		showMoreForPrint = e => {
			if (!this.state.isExpanded) {
				return new Promise(resolve => {
					this.setState({ isExpanded: !this.state.isExpanded, expandedForPrint: true }, () => resolve());
				});
			} else return Promise.resolve(100);
		};

		collapseAfterPrint = e => {
			if (this.state.expandedForPrint) {
				this.showMore();
				this.setState({
					expandedForPrint: false,
				});
			}
		};

		render() {
			const isExpanded = this.state.isExpanded;
			let moreText = this.props.showMoreText || 'Show More';
			let lessText = this.props.showLessText || 'Show Less';
			return (
				<div className="card is-expanded spc--bottom--xlrg">
					<WrappedComponent
						{...this.props}
						isExpanded={isExpanded}
						showMore={this.showMore}
						showMoreForPrint={this.showMoreForPrint}
						collapseAfterPrint={this.collapseAfterPrint}
					/>
					<div className="card__body">
						{useIcon ? (
							<i
								className={(isExpanded ? 'is-active' : '') + ' icon icon--sml icon--chevron--down'}
								onClick={() => this.showMore()}
							/>
						) : (
							<button className="btn btn--link btn--link--underline" onClick={() => this.showMore()}>
								{isExpanded ? lessText : moreText}
							</button>
						)}
					</div>
				</div>
			);
		}
	};
}

export default withExpander;
