import { createFilters, getFilterValues } from '../../../common/utilities';

export const equipmentFilter = createFilters([]);

export const compileFilter = filters => {
	const { appId } = getFilterValues(filters);
	const filter = {};
	filter.appId = (appId && appId.appId) || 0;
	return filter;
};
