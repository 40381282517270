import React, { Component } from 'react';
import PropTypes from 'prop-types';

class EAppStatusSelectionComponent extends Component {
	renderFilterText = () => {
		const { values } = this.props.filter;
		let valuesArray = Object.entries(values);

		return valuesArray.reduce((txt, val) => {
			if (!val[1]) return txt;
			let newVal = txt ? txt + ', ' + val[0] : val[0];

			if (newVal === 'Incomplete') newVal = 'eAppStarted';
			if (newVal === 'Pending') newVal = 'eAppSubmitted';

			return newVal;
		}, '');
	};

	handleFilterRemoved = () => {
		this.props.onFilterRemoved({
			id: 'merchantStatus',
			emptyValue: false,
		});
	};

	render() {
		return (
			<div className="filter__toolbar__selected-filter">
				{this.renderFilterText()}
				<button onClick={this.handleFilterRemoved} className="btn btn--link datatooltip--auto">
					<i className="icon icon--tny icon--close--text"></i>
				</button>
			</div>
		);
	}
}

EAppStatusSelectionComponent.propTypes = {
	filter: PropTypes.object,
	onFilterRemoved: PropTypes.func,
};

export default EAppStatusSelectionComponent;
