import React from 'react';

import { defaultImplicitParse, defaultReactOutput } from 'simple-markdown';

export const ClickableErrorMessage = ({ index, errorPrefix, elem, scrollTo, focusField }) => (
	<li key={index}>
		<button
			className="btn btn--link btn--link--error flex--gap--tny type--validation"
			onClick={() => {
				let elemId = elem.path.replace(/[.]/g, '_');
				scrollTo(elemId + '_wrapper');
				focusField(elemId);
			}}
		>
			{defaultReactOutput(defaultImplicitParse(errorPrefix + elem.message))}
		</button>
	</li>
);
