import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';

class ProcessorListFilterComponent extends Component {
	constructor(props) {
		super(props);
		this.filterChanged = this.filterChanged.bind(this);
	}

	filterChanged(event) {
		const {
			filter: { values },
		} = this.props;
		const { name, checked } = event.target;

		let valuesArray = map(values, (v, k) => {
			return { key: k, value: v };
		});

		const eventTargetIndex = valuesArray.findIndex(o => o.key == name);
		valuesArray[eventTargetIndex].value = checked;

		this.props.onFilterChanged({ id: 'processorList', values: valuesArray, emptyValue: false });
	}
	render() {
		const {
			filter: { values },
		} = this.props;
		return (
			<div className="fullwidth">
				<div className="spc--bottom--sml">
					<input
						type="checkbox"
						name="Go Plus"
						className="input--check"
						checked={values['Go Plus']}
						value={'Go Plus'}
						id="goplus"
						onChange={this.filterChanged}
					/>
					<label htmlFor="goplus">CK-Go</label>
				</div>
				<div className="spc--bottom--sml">
					<input
						type="checkbox"
						name="ACHQ"
						className="input--check"
						checked={values['ACHQ']}
						value={'ACHQ'}
						id="achq"
						onChange={this.filterChanged}
					/>
					<label htmlFor="achq">ACHQ</label>
				</div>
				<div className="spc--bottom--sml">
					<input
						type="checkbox"
						name="First Data"
						className="input--check"
						checked={values['First Data']}
						value={'First Data'}
						id="firstdata"
						onChange={this.filterChanged}
					/>
					<label htmlFor="firstdata">First Data</label>
				</div>
				<div className="spc--bottom--sml">
					<input
						type="checkbox"
						name="FD Canada"
						className="input--check"
						checked={values['FD Canada']}
						value={'FD Canada'}
						id="fdcanada"
						onChange={this.filterChanged}
					/>
					<label htmlFor="fdcanada">FD Canada</label>
				</div>
				<div className="spc--bottom--sml">
					<input
						type="checkbox"
						name="Elavon Canada"
						className="input--check"
						checked={values['Elavon Canada']}
						value={'Elavon Canada'}
						id="elavoncanada"
						onChange={this.filterChanged}
					/>
					<label htmlFor="elavoncanada">Elavon Canada</label>
				</div>
				<div className="spc--bottom--sml">
					<input
						type="checkbox"
						name="Elavon Canada USD"
						className="input--check"
						checked={values['Elavon Canada USD']}
						value={'Elavon Canada USD'}
						id="elavoncanadausd"
						onChange={this.filterChanged}
					/>
					<label htmlFor="elavoncanadausd">Elavon Canada USD</label>
				</div>
				<div className="spc--bottom--sml">
					<input
						type="checkbox"
						name="ACH-CK21"
						className="input--check"
						checked={values['ACH-CK21']}
						value={'ACH-CK21'}
						id="ach-ck21"
						onChange={this.filterChanged}
					/>
					<label htmlFor="ach-ck21">ProfitStars Ach</label>
				</div>
				<div className="spc--bottom--sml">
					<input
						type="checkbox"
						name="FD North FACS"
						className="input--check"
						checked={values['FD North FACS']}
						value={'FD North FACS'}
						id="fdnorthfacs"
						onChange={this.filterChanged}
					/>
					<label htmlFor="fdnorthfacs">FD North FACS</label>
				</div>
				<div className="spc--bottom--sml">
					<input
						type="checkbox"
						name="Elavon"
						className="input--check"
						checked={values['Elavon']}
						value={'Elavon'}
						id="elavon"
						onChange={this.filterChanged}
					/>
					<label htmlFor="elavon">Elavon</label>
				</div>
				<div className="spc--bottom--sml">
					<input
						type="checkbox"
						name="Vantiv"
						className="input--check"
						checked={values['Vantiv']}
						value={'Vantiv'}
						id="vantiv"
						onChange={this.filterChanged}
					/>
					<label htmlFor="vantiv">Vantiv</label>
				</div>
				<div className="spc--bottom--sml">
					<input
						type="checkbox"
						name="Developer"
						className="input--check"
						checked={values['Developer']}
						value={'Developer'}
						id="developer"
						onChange={this.filterChanged}
					/>
					<label htmlFor="developer">Developer</label>
				</div>
			</div>
		);
	}
}

ProcessorListFilterComponent.propTypes = {
	filter: PropTypes.shape({
		values: PropTypes.object,
	}),
	onFilterChanged: PropTypes.func,
};

export default ProcessorListFilterComponent;
