import React from 'react';
import { string, bool } from 'prop-types';

class PricingBreakdown extends React.Component {
	render() {
		const { pricing, wrapperClass, hideTitle } = this.props;

		return (
			<div className={wrapperClass}>
				{!hideTitle && <h6 className="spc--bottom--med">Current Pricing Breakdown</h6>}
				<div className="details__content">
					<div className="row">
						<div className="col col-sml-12 col-med-6 form__group">
							<div className="form__group__header">
								<p className="form__group__label" htmlFor="lblRateStructure">
									Rate Structure
								</p>
							</div>
							<p className="input input--med input--disabled" name="lblRateStructure" id="lblRateStructure">
								{pricing.rateStructure}
							</p>
						</div>
						<div className="col col-sml-12 col-med-6 form__group">
							<div className="form__group__header">
								<p className="form__group__label" htmlFor="lblInterchange">
									Interchange +K
								</p>
							</div>
							<p className="input input--med input--disabled" name="lblInterchange" id="lblInterchange">
								{pricing.interchange}
							</p>
						</div>
						<div className="col col-sml-12 col-med-6 form__group">
							<div className="form__group__header">
								<p className="form__group__label" htmlFor="lblDebitRate">
									Debit Rate
								</p>
							</div>
							<p className="input input--med input--disabled" name="lblDebitRate" id="lblDebitRate">
								{pricing.debitRate}
							</p>
						</div>
						<div className="col col-sml-12 col-med-6 form__group">
							<div className="form__group__header">
								<p className="form__group__label" htmlFor="lblDebitTransactionFee">
									Debit Transaction Fee{' '}
								</p>
							</div>
							<p className="input input--med input--disabled" name="lblDebitTransactionFee" id="lblDebitTransactionFee">
								{pricing.debitTransactionFee}
							</p>
						</div>
						<div className="col col-sml-12 col-med-6 form__group">
							<div className="form__group__header">
								<p className="form__group__label" htmlFor="lblBankcardTransactionFee">
									BankCard Transaction Fee{' '}
								</p>
							</div>
							<p
								className="input input--med input--disabled"
								name="lblBankcardTransactionFee"
								id="lblBankcardTransactionFee"
							>
								{pricing.bankcardTransactionFee}
							</p>
						</div>
						<div className="col col-sml-12 col-med-6 form__group">
							<div className="form__group__header">
								<p className="form__group__label" htmlFor="lblAmexTransactionFee">
									Amex Transaction Fee{' '}
								</p>
							</div>
							<p className="input input--med input--disabled" name="lblAmexTransactionFee" id="lblAmexTransactionFee">
								{pricing.amexTransactionFee}
							</p>
						</div>
						<div className="col col-sml-12 col-med-6 form__group">
							<div className="form__group__header">
								<p className="form__group__label" htmlFor="lblAmexRateStructure">
									Amex Rate Structure
								</p>
							</div>
							<p className="input input--med input--disabled" name="lblAmexRateStructure" id="lblAmexRateStructure">
								{pricing.amexRateStructure}
							</p>
						</div>
						<div className="col col-sml-12 col-med-6 form__group">
							<div className="form__group__header">
								<p className="form__group__label" htmlFor="lblAmexRate">
									Amex Rate{' '}
								</p>
							</div>
							<p className="input input--med input--disabled" name="lblAmexRate" id="lblAmexRate">
								{pricing.amexRate}
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

PricingBreakdown.defaultProps = {
	wrapperClass: 'card--primary card--sml card--primary--popup',
	hideTitle: false,
};

PricingBreakdown.propTypes = {
	wrapperClass: string,
	hideTitle: bool,
};

export default PricingBreakdown;
