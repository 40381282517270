import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { appService } from '../../../services';
import { has, split, lowerFirst } from 'lodash';
import { openFile } from '../../../common/utilities';

class DownloadButtonFormatter extends Component {
	render() {
		const { row, type } = this.props;
		const isDisabled = !has(row, lowerFirst(type));


		return (
			<div className="type--center">
				<button className="btn btn--sml btn--ghost" onClick={this.handleDownload} disabled={isDisabled}>
					<i className="icon icon--sml icon--print"></i>
				</button>
			</div>
		);
	}

	handleDownload = async () => {
		const { row, type } = this.props;
		const { showLoader, notificationRef } = row;
		const reportType = lowerFirst(type);
		try {
			if (!has(row, reportType)){
				throw new Error('No file available for download');
			}
			showLoader(true);
			const response = await appService.GetFeeReportFile(row[reportType]);
			const { refNum, downloadData: { presignedUrl, encryptionKey, fileKey } } = response;
			const blob = await fetch(presignedUrl).then(r => r.blob());
			const decryptedBlob = await appService.decryptFile(blob, encryptionKey);
			const url = URL.createObjectURL(decryptedBlob);
			const fileName = split(fileKey, '/').pop();
			openFile(url, fileName, false, true);
			notificationRef.current.addNotification({
				message: 'Downloaded successfully',
				success: true,
				refNum: refNum,
			});
		} catch (error) {
			notificationRef.current.addNotification({
				message: 'Failed to download',
				success: false,
			});
		} finally {
			showLoader(false);
		}

	}
}



DownloadButtonFormatter.propTypes = {
	row: PropTypes.object,
};
export default DownloadButtonFormatter;