import React, { Component } from 'react';
import { map } from 'lodash';

class StatusFilterComponent extends Component {
	constructor(props) {
		super(props);
		this.filterChanged = this.filterChanged.bind(this);
	}

	filterChanged(event) {
		let props = this.props;

		let values = props.filter.values;

		const key = event.target.name;
		const checked = event.target.checked;

		let valueArr = map(values, (v, k) => {
			return { key: k, value: v };
		});

		const objIndex = valueArr.findIndex(o => o.key == key);

		valueArr[objIndex].value = checked;

		this.props.onFilterChanged({
			id: 'merchantStatus',
			values: valueArr,
			emptyValue: false,
		});
	}
	render() {
		return (
			<div className="fullwidth">
				<div className="spc--bottom--sml">
					<input
						type="checkbox"
						name="Active"
						className="input--check"
						checked={this.props.filter.values.Active}
						value={'Active'}
						id="active"
						onChange={this.filterChanged}
					/>
					<label htmlFor="active">Active</label>
				</div>
				<div className="spc--bottom--sml">
					<input
						type="checkbox"
						name="Inactive"
						className="input--check"
						checked={this.props.filter.values.Inactive}
						value={'Inactive'}
						id="inactive"
						onChange={this.filterChanged}
					/>
					<label htmlFor="inactive">Inactive</label>
				</div>
				<div className="spc--bottom--sml">
					<input
						type="checkbox"
						name="SeasonalHold"
						className="input--check"
						checked={this.props.filter.values.SeasonalHold}
						value="{'SeasonalHold'}"
						id="seasonalHold"
						onChange={this.filterChanged}
					/>
					<label htmlFor="seasonalHold">Seasonal Hold</label>
				</div>
				<div className="spc--bottom--sml">
					<input
						type="checkbox"
						name="InReview"
						className="input--check"
						checked={this.props.filter.values.InReview}
						value={'InReview'}
						id="inReview"
						onChange={this.filterChanged}
					/>
					<label htmlFor="inReview">In Review</label>
				</div>
				<div className="spc--bottom--sml">
					<input
						type="checkbox"
						name="ConditionallyApproved"
						className="input--check"
						checked={this.props.filter.values.ConditionallyApproved}
						value={'ConditionallyApproved'}
						id="conditionallyApproved"
						onChange={this.filterChanged}
					/>
					<label htmlFor="conditionallyApproved">Conditionally Approved</label>
				</div>
			</div>
		);
	}
}

export default StatusFilterComponent;
