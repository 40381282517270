import React from 'react';
import USSetupForm from './USSetupForm';
import { CanadaProcessorDropdown } from '../../common/components/processor-dropdown';
import ZeroAsEmptyNumberFormat from '../../common/components/zero-as-empty-number-format/ZeroAsEmptyNumberFormat';
import { isFdCad } from '../../common/utilities';

export default class CanadaSetupForm extends USSetupForm {
	renderProcessorDropdown() {
		const { setup, processorList } = this.props;
		return (
			<CanadaProcessorDropdown
				className="input input--med input--select"
				id="processorId"
				value={setup.processorId}
				processorList={processorList}
				setup={setup}
				onChange={this.props.handleChange}
				merchId={setup.appId}
			/>
		);
	}

	renderPinDebitStructure() {
		const { setup } = this.props;
		if (setup.pricingStructure.shouldSetupPinDebit !== 'true') return null;
		return (
			<div className="row">
				<div className="col col-sml-12 col-lrg-4 form__group">
					<div className="form__group__header">
						<p className="form__group__label">Interac Trans Fee </p>
					</div>
					{this.renderNumberInput(
						setup.pricingStructure.pinDebitTransactionFee,
						'pricingStructure_pinDebitTransactionFee',
						'$'
					)}
				</div>
				<div className="col col-sml-12 col-lrg-4 form__group">
					<div className="form__group__header">
						<p className="form__group__label">Interac Flash Trans Fee/Contactless </p>
					</div>
					{this.renderNumberInput(
						setup.pricingStructure.pinDebitInteracFlashTransFee,
						'pricingStructure_pinDebitInteracFlashTransFee',
						'$'
					)}
				</div>
			</div>
		);
	}

	renderPinDebit() {
		const { setup } = this.props;
		return (
			<div className="form__group">
				<div className="form__group__header">
					<p className="form__group__label">Setup Merchant With Pin Debit</p>
					<span className="form__group__required">*</span>
				</div>
				<div className="flex--primary flex--gap--xlrg">
					<div>
						<input
							className="input--radio"
							type="radio"
							id="pricingStructure_shouldSetupPinDebit_yes"
							name="pricingStructure_shouldSetupPinDebit_yes"
							checked={setup.pricingStructure.shouldSetupPinDebit === 'true'}
							value="true"
							onChange={this.props.handlePinDebitChange}
						/>
						<label htmlFor="pricingStructure_shouldSetupPinDebit_yes">Yes</label>
					</div>
					<div>
						<input
							className="input--radio"
							type="radio"
							id="pricingStructure_shouldSetupPinDebit_no"
							name="pricingStructure_shouldSetupPinDebit_no"
							value="false"
							checked={setup.pricingStructure.shouldSetupPinDebit === 'false'}
							onChange={this.props.handlePinDebitChange}
						/>
						<label htmlFor="pricingStructure_shouldSetupPinDebit_no">No</label>
					</div>
				</div>
				{this.renderPinDebitStructure()}
			</div>
		);
	}

	renderOptBlue() {
		const { setup } = this.props;
		return (
			<React.Fragment>
				<div className="row">
					<div className="col col-sml-12">
						{this.renderRateStructure(
							'amexDetails',
							'AMEX ',
							true,
							setup.amexDetails.pricingStructureType,
							'Amex uses the same rate structure as Visa/MC'
						)}
					</div>
					<div className="col col-sml-12 col-med-6 form__group">
						<div className="form__group__header">
							<p className="label__group__label">Amex Trans/Auth Fee (if diff than Visa/MC/Disc) </p>
						</div>
						{this.renderNumberInput(setup.amexDetails.transactionFee, 'amexDetails_transactionFee', '$0')}
					</div>
					<div id="amexDetails_yearlyAmexVolume_div" className="col col-sml-12 col-med-6 form__group">
						<span className="form__group__header">
							<p className="form__group__label">Opt-Blue Average Annual Volume </p>
						</span>
						{this.renderNumberInput(setup.amexDetails.yearlyAmexVolume, 'amexDetails_yearlyAmexVolume', '$0')}
					</div>
				</div>
			</React.Fragment>
		);
	}

	renderNonQual(rateObj, namePrefix) {
		return namePrefix.indexOf('amex') === 0 ? null : (
			<div className="col col-sml-12 col-lrg-3 form__group">
				<div className="form__group__header">
					<p className="form__group__label">Non-Qual </p>
				</div>
				{this.renderNumberInput(
					rateObj.tieredRatePercentages.nonQualified,
					namePrefix + 'tieredRatePercentages_nonQualified',
					'%',
					[0, 100]
				)}
			</div>
		);
	}

	renderTransactionInformation() {
		return (
			<div className="col col-sml-12 col-lrg-4 form__group">
				<div id="pricingStructure_debitRefundCap_div" className="form__group__header">
					<p className="form__group__label">Debit Refund Cap </p>
				</div>
				{this.renderNumberInput(
					this.props.setup.pricingStructure.debitRefundCap,
					'pricingStructure_debitRefundCap',
					'$0'
				)}
			</div>
		);
	}

	renderOnlineReportingSection() {
		return null;
	}

	renderEnhancedRate(value, labelPrefix, namePrefix) {
		const componentProps = {
			value: value,
			name: namePrefix + 'billBackRatePercentages_enhanced',
			placeholder: 'Enhanced Rate',
			className: 'input input--med',
			thousandSeparator: true,
			decimalScale: 2,
			onValueChange: this.handleChangeWithUpdateEnhanced,
			disabled: namePrefix.indexOf('amex') === 0 ? true : false,
		};

		return (
			<div className="form__group">
				<div className="form__group__header">
					<p className="form__group__label">{labelPrefix} Enhanced Rate </p>
				</div>
				<ZeroAsEmptyNumberFormat {...componentProps} />
			</div>
		);
	}

	handleChangeWithUpdate = async e => {
		e.persist();
		await this.props.handleChangeAsync(e);
		await this.props.handleChangeAsync({
			target: {
				type: e.target.type,
				value: e.target.value,
				name: e.target.name.replace('pricingStructure_', 'amexDetails_'),
			},
		});
	};

	handleChangeWithUpdateEnhanced = async ({ floatValue }) => {
		await this.props.handleChangeAsync({
			target: { value: floatValue, name: 'pricingStructure_billBackRatePercentages_enhanced' },
		});
		await this.props.handleChangeAsync({
			target: { value: floatValue, name: 'amexDetails_billBackRatePercentages_enhanced' },
		});
	};

	renderProcessingMethodLabel() {
		return (
			<div className="form__group__header">
				<p className="form__group__label">Processing method (Must equal 100%)</p>
				<span className="form__group__required" data-tooltip="Required">
					*
				</span>
				<i
					className="icon icon--tny icon--regular--info"
					data-tooltip="There must be one processing method that has a higher percentage than the other processing methods."
				></i>
			</div>
		);
	}

	renderEIDSEnrollmentSection() {
		return null;
	}

	renderRetrievalCharge() {
		return null;
	}

	renderPciFee(setup) {
		return (
			<React.Fragment>
				{this.renderProposedFee(setup, 'pciMonthlyFee', 'PCI Monthly Fee', !isFdCad(setup))}
				{this.renderProposedFee(setup, 'applicationFee', 'Application Fee', true)}
			</React.Fragment>
		);
	}
}
