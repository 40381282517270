import React from 'react';
import { get, noop, isEmpty } from 'lodash';
import ReactToPrint from 'react-to-print';
import { ExportToCsv } from 'export-to-csv';
import PropTypes from 'prop-types';

import Schema from '../../validation/BaseSchema';
import { exportOptions } from './../../common/components/export/export-options';
import { generateFileName } from './../../common/components/export/helpers';
import { ModalWrapper, modalNames } from './../../common/components/modal-wrapper';
import { withContext, withLoader } from './../../common/components';
import { default as AccountSummary } from './account-summary';
import { default as BusinessInformation } from './business-information';
import { default as PCICompliance } from './pci-compliance';
import { Notification } from '../../common/components/notifications';
import { principalService } from '../../services';
import MerchantContext from './MerchantContext';
import { _merchantFunctions } from '../../services/_merchantFunctions';
import UpdateMerchantContext from './UpdateMerchantContext';
import { merchantDetailsTemplate } from '../../validation';
import { _sharedFunctions } from '../../services/_sharedFunctions';
import { ValidationError } from '../error';
import { isAchq, isGoPlus, validateOnBlur, OutsideClick } from '../../common/utilities';
import MerchantInfoHeader from '../MerchantEquipment/components/MerchantInfoHeader';

class MerchantDetails extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			modal: {
				name: modalNames.none,
				data: null,
			},
			showUserDropDown: false,
			isEditDetails: false,
			merchantDetails: {
				appId: '',
				businessContactName: '',
				phoneNumber: '',
				businessEmail: '',
				alternateEmail: '',
			},
			errorListPaths: {},
			errorList: [],
			errorMessage: '',
			isSubmit: false,
		};
		this.detailsRef = React.createRef();
		this.summaryRef = React.createRef();
		this.businessInfoRef = React.createRef();
		this.pciRef = React.createRef();
		this.printRef = React.createRef();
		this.notificationRef = React.createRef();
		this.merchantDetailsSchema = new Schema(merchantDetailsTemplate, { strip: false, typecast: true });
	}

	renderRequestUpdateButton = () => {
		return (
			<button className="btn btn--link btn--link--tertiary" onClick={this.openSendEmailPopup}>
				<i className="icon icon--lrg icon--add-user--light" />
				<span>Request Account Update</span>
			</button>
		);
	};
	renderDropInButton = () => {
		const { dba, hasCardknoxEquipment } = this.props.merchant;
		const buttonStatus = hasCardknoxEquipment ? '' : ' is-disabled';
		return (
			<a
				className={`btn btn--link btn--link--tertiary${buttonStatus}`}
				href={`${process.env.REACT_APP_MERCHANT_PORTAL_URL}/login?dropinDBA=${encodeURIComponent(dba)}`}
				target="_blank"
				rel="noreferrer"
				aria-disabled={hasCardknoxEquipment}
			>
				<i className="icon icon--lrg icon--launch-user--light flex--no-shrink" />
				<span>Launch Merchant Portal Account</span>
			</a>
		);
	};

	openSendEmailPopup = () => {
		const { merchant } = this.props;
		const principal = principalService.get();

		this.openCloseModal({
			name: modalNames.sendEmail,
			data: {
				templateParams: {
					appId: merchant.appId,
					mid: merchant.mid,
					toEmail: process.env.REACT_APP_BANKING_SERVICES_EMAIL,
					fromEmail: principal.email,
					dba: merchant.dba,
				},
				addNotification: get(this.notificationRef, 'current.addNotification', noop),
			},
		});
	};

	renderPrintButton = () => {
		return (
			<React.Fragment>
				<button className="btn btn--med btn--link btn--link--tertiary hide--to--lrg" onClick={this.expandAllAndPrint}>
					<i className="icon icon--sml icon--print" />
					<span>Print</span>
				</button>

				<ReactToPrint
					ref={this.printRef}
					trigger={() => <div style={{ display: 'none' }}></div>}
					content={() => this.detailsRef.current}
					onAfterPrint={this.collapseAll}
				/>
			</React.Fragment>
		);
	};

	expandAllAndPrint = () => {
		this.summaryRef.current.showMoreForPrint().then(() => {
			this.businessInfoRef.current.showMoreForPrint().then(() => {
				this.pciRef.current.showMoreForPrint().then(() => {
					this.printRef.current.handlePrint();
				});
			});
		});
	};

	collapseAll = () => {
		this.summaryRef.current.collapseAfterPrint();
		this.businessInfoRef.current.collapseAfterPrint();
		this.pciRef.current.collapseAfterPrint();
	};

	download = () => {
		let merchId = this.props.merchant.appId;
		const exporter = new ExportToCsv({ filename: generateFileName(false, merchId), ...exportOptions });

		let flattenedMerch = _sharedFunctions.flatten(this.props.merchant);
		exporter.generateCsv([flattenedMerch]);
	};

	openCloseModal = (modalObj, ...rest) => {
		let state = {
			modal: modalObj,
		};
		this.setState(state);
	};

	validateFields = (isSubmit = false) => {
		const errorList = this.merchantDetailsSchema.validate({ ...this.state.merchantDetails });

		const errorListPaths = errorList.map(e => e.path);
		const hasErrors = !isEmpty(errorList);
		const newState = { errorList, errorListPaths };

		if (isSubmit && !this.state.isSubmit) {
			newState.isSubmit = true;
		}

		this.setState(newState);

		return hasErrors;
	};

	handleChange = e => {
		let merchantDetails = { ...this.state.merchantDetails };
		let fieldName = e.target.name;
		if (e.target.name.indexOf('_') > -1) {
			let keyList = e.target.name.split('_');
			fieldName = keyList[keyList.length - 1];
		}
		merchantDetails[fieldName] = e.target.value;
		this.setState({ merchantDetails }, () => {
			if (!this.state.isSubmit) return;
			this.validateFields();
		});
	};

	handleBlur = ({ target: { name } }) => {
		const { isSubmit, merchantDetails, errorList } = this.state;
		if (isSubmit) return;
		let fullName = name;
		if (name.indexOf('_') > -1) {
			let keyList = name.split('_');
			fullName = keyList[keyList.length - 1];
		}
		const newState = validateOnBlur(this.merchantDetailsSchema, merchantDetails, errorList, fullName);
		this.setState(newState);
	};

	scrollTo = id => {
		const elem = document.getElementById(`merchantDetails_${id}`);
		elem && elem.scrollIntoView({ behavior: 'smooth', block: 'center' });
	};

	focusField = id => {
		const elem = document.getElementById(`merchantDetails_${id}`);
		elem && elem.focus();
	};

	renderErrors = () => {
		const { errorMessage, errorListPaths, errorList } = this.state;

		return (
			<React.Fragment>
				{errorMessage ? <div className="type--validation spc--bottom--med">{errorMessage}</div> : null}
				<ValidationError
					errorList={errorList}
					errorListPaths={errorListPaths}
					scrollTo={this.scrollTo}
					focusField={this.focusField}
				/>
			</React.Fragment>
		);
	};

	saveChanges = () => {
		if (this.validateFields(true)) {
			return;
		}
		this.props.showLoader(true);
		const addNotification = get(this.notificationRef, 'current.addNotification', noop);
		_merchantFunctions
			.saveMerchantDetails(this.state.merchantDetails)
			.then(response => {
				this.props.updateMerchant(this.state.merchantDetails);
				this.setState({ isEditDetails: false, errorMessage: '' });
				if (response.ticketId !== 0) {
					addNotification({
						message: `Ticket #${response.ticketId} has been created to update the Account Info.`,
						success: true,
					});
				}
			})
			.catch(err => {
				console.log(err);
				this.setState({ errorMessage: err });
			});
		this.props.showLoader(false);
	};

	editDetails = () => {
		const merchantDetails = {
			appId: this.props.merchant.appId,
			businessContactName: this.props.merchant.businessContactName,
			phoneNumber: this.props.merchant.phoneNumber,
			businessEmail: this.props.merchant.businessEmail,
			alternateEmail: this.props.merchant.alternateEmail,
		};
		this.setState({ isEditDetails: true, merchantDetails });
	};

	cancelChanges = () => {
		this.setState({ isEditDetails: false, errorList: [], errorListPaths: {}, errorMessage: '', isSubmit: false });
	};

	renderExportMerchant = () => {
		return (
			<button className="btn btn--med btn--link btn--link--tertiary" onClick={() => this.download()}>
				<i className="icon icon--sml icon--download" />
				<span>Export</span>
			</button>
		);
	};

	renderEmailButton = () => {
		const { dba, mid } = this.props.merchant;
		const subject = dba + (mid ? ` - MID: ${mid}` : '');
		return (
			<a className="btn btn--link btn--link--tertiary" href={`mailto:?subject=${subject}`}>
				<i className="icon icon--lrg icon--mail--light"></i>
				<span>New Email</span>
			</a>
		);
	};

	renderNewNote = () => {
		return (
			<button
				type="button"
				className="btn btn--link btn--link--tertiary"
				onClick={() => this.openCloseModal({ name: modalNames.newNote, data: { appid: this.props.merchant.appId } })}
			>
				<i className="icon icon--lrg icon--reports--light"></i>
				<span>Add note</span>
			</button>
		);
	};

	renderReviewPricingPopup = () => {
		const { dba, appId, leadId, isCanada } = this.props.merchant;
		let merchantInfo = {
			leadId: leadId,
			dba: dba,
			appId: appId,
			market: isCanada ? 'Canada' : 'USA',
		};
		return (
			<button
				type="button"
				className="btn btn--link btn--link--tertiary"
				onClick={() => this.openCloseModal({ name: modalNames.reviewPricing, data: { merchantInfo } })}
			>
				<i className="icon icon--lrg icon--receipt-print--light"></i>
				<span>Submit to Pricing Analysis</span>
			</button>
		);
	};

	toggleUserDropDown = () => {
		this.setState({
			showUserDropDown: !this.state.showUserDropDown,
		});
	};

	onClose = e => {
		this.setState({
			showUserDropDown: false,
		});
	};

	render() {
		const { merchant } = this.props;
		const { showUserDropDown } = this.state;
		//console.log('expand all ' + expandAll);
		return (
			<React.Fragment>
				<Notification ref={this.notificationRef} />
				<div className="l--content--alt" ref={this.detailsRef}>
					{this.renderErrors()}
					{merchant && (
						<React.Fragment>
							<ModalWrapper modal={this.state.modal} onModalClose={this.openCloseModal} />
							<header>
								<MerchantInfoHeader merchant={merchant} />
								<div className="flex--secondary flex--gap--med spc--bottom--xxlrg">
									<h3>Account Overview</h3>
									<div className="flex--primary align--h--right">
										{this.renderPrintButton()}
										{this.renderExportMerchant()}										
										<div className="pos--rel spc--left--med">
											<button
												onClick={this.toggleUserDropDown}
												className={`btn btn--action btn--action--secondary${showUserDropDown ? ' is-active' : ''}`}
											>
												<i className="icon icon--sml icon--more"></i>
											</button>
											{showUserDropDown && (
												<OutsideClick action={this.onClose} className="popover popover--down">
													<ul className="popover__list">
														{merchant.statusDisplayName == 'Underwriting' && <li className="item">{this.renderReviewPricingPopup()}</li>}
														<li className="item">{this.renderEmailButton()}</li>
														<li className="item">{this.renderNewNote()}</li>
														<li className="item">{this.renderDropInButton()}</li>
														<li className="item">{this.renderRequestUpdateButton()}</li>
													</ul>
												</OutsideClick>
											)}
										</div>
									</div>
								</div>
							</header>
							<div className="w--max--790">
								<AccountSummary
									ref={this.summaryRef}
									isEditDetails={this.state.isEditDetails}
									merchant={merchant}
									merchantDetails={this.state.merchantDetails}
									onEdit={this.editDetails}
									onCancel={this.cancelChanges}
									onSave={this.saveChanges}
									onChange={this.handleChange}
									onBlur={this.handleBlur}
									openCloseModal={this.openCloseModal}
								/>
								<BusinessInformation merchant={merchant} ref={this.businessInfoRef} />
								{!isGoPlus(merchant) && !isAchq(merchant) && (
									<PCICompliance pciInfo={merchant.pciCompliance} ref={this.pciRef} />
								)}
							</div>
						</React.Fragment>
					)}
				</div>
			</React.Fragment>
		);
	}
}
MerchantDetails.propTypes = {
	merchant: PropTypes.object,
};

export default withLoader(
	withContext(withContext(MerchantDetails, MerchantContext, 'merchant'), UpdateMerchantContext, 'updateMerchant')
);
