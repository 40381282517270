import { some, each } from 'lodash';

import {
	MIDFilterComponent,
	MIDSelectionComponent,
	DBASelectionComponent,
	CorporateNameSelectionComponent,
	BusinessCountrySelectionComponent,
	OwnerNameSelectionComponent,
	BusinessContactNameSelectionComponent,
	BusinessEmailSelectionComponent,
	StatusFilterComponent,
	StatusSelectionComponent,
	AddressFilterComponent,
	AddressSelectionComponent,
	CitySelectionComponent,
	StateSelectionComponent,
	ZipFilterComponent,
	ZipSelectionComponent,
	PhoneNumberFilterComponent,
	PhoneNumberSelectionComponent,
	GeneralFilterComponent,
} from '../../../common/filters';
import { createFilters, getFilterValues } from '../../../common/utilities';
import ProcessorListFilterComponent from '../../../common/filters/processor-list/processor-list-filter';
import ProcessorListSelectionComponent from '../../../common/filters/processor-list/processor-list-filter-selection';

export const merchantsFilter = createFilters([
	{
		key: 'mid',
		name: 'MID',
		component: MIDFilterComponent,
		selectionComponent: MIDSelectionComponent,
		values: {
			mid: null,
		},
	},
	{
		key: 'dba',
		name: 'DBA',
		component: GeneralFilterComponent,
		selectionComponent: DBASelectionComponent,
		values: {
			dba: null,
			fieldName: 'dba',
		},
	},
	{
		key: 'corporateName',
		name: 'Corporate Name',
		component: GeneralFilterComponent,
		selectionComponent: CorporateNameSelectionComponent,
		values: {
			corporateName: null,
			fieldName: 'corporateName',
		},
	},
	{
		key: 'merchantStatus',
		name: 'Status',
		component: StatusFilterComponent,
		selectionComponent: StatusSelectionComponent,
		values: {
			Active: false,
			Inactive: false,
			SeasonalHold: false,
			InReview: false,
			ConditionallyApproved: false,
			Incomplete: false,
			Pending: false,
		},
	},
	{
		key: 'processorList',
		name: 'Processor',
		component: ProcessorListFilterComponent,
		selectionComponent: ProcessorListSelectionComponent,
		values: {
			'Go Plus': false,
			ACHQ: false,
			'First Data': false,
			'FD Canada': false,
			'Elavon Canada': false,
			'Elavon Canada USD': false,
			'ACH-CK21': false,
			'FD North FACS': false,
			Elavon: false,
			Vantiv: false,
			Developer: false,
		},
	},
	{
		key: 'businessCountry',
		name: 'Business Country',
		component: GeneralFilterComponent,
		selectionComponent: BusinessCountrySelectionComponent,
		values: {
			businessCountry: null,
			fieldName: 'businessCountry',
		},
	},
	{
		key: 'ownerName',
		name: 'Owner Name',
		component: GeneralFilterComponent,
		selectionComponent: OwnerNameSelectionComponent,
		values: {
			ownerName: null,
			fieldName: 'ownerName',
		},
	},
	{
		key: 'businessContactName',
		name: 'Business Contact Name',
		component: GeneralFilterComponent,
		selectionComponent: BusinessContactNameSelectionComponent,
		values: {
			businessContactName: null,
			fieldName: 'businessContactName',
		},
	},
	{
		key: 'address',
		name: 'Address',
		component: AddressFilterComponent,
		selectionComponent: AddressSelectionComponent,
		values: {
			address: null,
		},
	},
	{
		key: 'city',
		name: 'City',
		component: GeneralFilterComponent,
		selectionComponent: CitySelectionComponent,
		values: {
			city: null,
			fieldName: 'city',
		},
	},
	{
		key: 'state',
		name: 'State',
		component: GeneralFilterComponent,
		selectionComponent: StateSelectionComponent,
		values: {
			state: null,
			fieldName: 'state',
		},
	},
	{
		key: 'zip',
		name: 'Zip',
		component: ZipFilterComponent,
		selectionComponent: ZipSelectionComponent,
		values: {
			zip: null,
		},
	},
	{
		key: 'phoneNumber',
		name: 'Phone Number',
		component: PhoneNumberFilterComponent,
		selectionComponent: PhoneNumberSelectionComponent,
		values: {
			phoneNumber: null,
		},
	},
	{
		key: 'businessEmail',
		name: 'Business Email',
		component: GeneralFilterComponent,
		selectionComponent: BusinessEmailSelectionComponent,
		values: {
			businessEmail: null,
			fieldName: 'businessEmail',
		},
	},
	{
		key: 'isPciCompliant',
		name: 'PCI Compliant',
		values: {
			pciFilter: null,
		},
	},
]);

export const compileFilter = filters => {
	const {
		mid,
		dba,
		corporateName,
		merchantStatus,
		businessCountry,
		ownerName,
		businessContactName,
		businessEmail,
		address,
		city,
		state,
		zip,
		phoneNumber,
		isPciCompliant,
		processorList,
	} = getFilterValues(filters);

	const filter = {};

	if (mid.mid != null) {
		filter.MID = mid.mid;
	}

	if (dba.dba != null) {
		filter.DBA = dba.dba;
	}

	if (corporateName.corporateName != null) {
		filter.CorporateName = corporateName.corporateName;
	}

	if (some(merchantStatus, p => p)) {
		filter.StatusList = parseListFilter(merchantStatus);
	}
	/*
    if (status.status != null) {
        filter.StatusList = status.status;
    }*/

	if (ownerName.ownerName != null) {
		filter.OwnerName = ownerName.ownerName;
	}
	if (businessCountry.businessCountry != null) {
		filter.BusinessCountry = businessCountry.businessCountry;
	}

	if (businessContactName.businessContactName != null) {
		filter.BusinessContactName = businessContactName.businessContactName;
	}

	if (businessEmail.businessEmail != null) {
		filter.BusinessEmail = businessEmail.businessEmail;
	}

	if (address.address != null) {
		filter.Address = address.address;
	}

	if (city.city != null) {
		filter.City = city.city;
	}

	if (state.state != null) {
		filter.State = state.state;
	}

	if (zip.zip != null) {
		filter.Zip = zip.zip;
	}

	if (phoneNumber.phoneNumber != null) {
		filter.PhoneNumber = phoneNumber.phoneNumber;
	}

	if (some(processorList, p => p)) {
		filter.ProcessorList = parseListFilter(processorList);
	}

	if (isPciCompliant.pciFilter != null) {
		filter.PCIFilter = isPciCompliant.pciFilter;
	}

	return filter;
};

const parseListFilter = values => {
	const filters = [];

	each(values, (value, stat) => {
		if (value) {
			filters.push(stat);
		}
	});

	return filters.join(',');
};
