import React from 'react';
import { map } from 'lodash';

import {
	MultiselectCardknoxFilterComponent,
} from '../../../common/column-filters';

import createGridColumn from '../../../common/components/grid/createGridColumn';
import DownloadButtonFormatter from '../formatters/DownloadButtonFormatter';

export const FeeReportColumns = map(
	[
		createGridColumn('year', 'Year', 100, {
			getRowMetaData: row => row,
			filterRenderer: MultiselectCardknoxFilterComponent,
		}),
		createGridColumn('month', 'Month', 100, {
			filterRenderer: MultiselectCardknoxFilterComponent,
		}),
		createGridColumn('chargebackSummary', 'Chargeback Summary', 36, {
			sortable: false,
			filterable: false,
			formatter: props => <DownloadButtonFormatter {...props} type={"ChargebackSummary"}/>,
			resizable: false,
		}),
		createGridColumn('interchangeBreakdown', 'Interchange Breakdown', 36, {
			sortable: false,
			filterable: false,
			formatter: props => <DownloadButtonFormatter {...props} type={"InterchangeBreakdown"}/>,
			resizable: false,
		}),
		createGridColumn('feeSummary', 'Fee Summary', 24, {
			sortable: false,
			filterable: false,
			formatter: props => <DownloadButtonFormatter {...props} type={"FeeSummary"}/>,
			resizable: false,
		}),
		createGridColumn('', '', 1, {
			sortable: false,
			filterable: false,
			resizable: false,
			hideable: false
		}),
	],
	(column, index) => ({
		...column,
		order: column.order || index,
	})
);
