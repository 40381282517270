import React, { Component, Fragment, createRef } from 'react';
import { findDOMNode, createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import {
	merge,
	memoize,
	identity,
	cloneDeep,
	map,
	sum,
	each,
	filter,
	toLower,
	orderBy,
	find,
	findIndex,
	findKey,
	clone,
	constant,
	noop,
	some,
	isEmpty,
	has,
	trim,
	isString,
	debounce,
	isArray,
	split,
	last,
	reverse,
	includes,
	transform,
	concat,
	get,
} from 'lodash';

import EmptyGridComponent from './empty-grid';
import TitleComponent from './title';
import ColumnFilterComponent from './column-filter';
import ToolbarComponent from './toolbar';
import ColumnFilterHeader from './column-filter-header';
import ColumnFilterFooter from './column-filter-footer';
import GridFooter from './grid-footer';
import { validInlineFilterValues } from './valid-inline-filter-values';
import { ExportComponent } from '../export';
import { isComponent, ResizeSensor, screenSize } from '../../utilities';
import { PrintGridButton } from '../print-grid-data';
import { modalNames } from '../modal-wrapper/modal-names';
import { Grid as ReactDataGrid } from '../react-data-grid';
import Pagination from './Pagination';
import Header from '../header/Header';

const defaultComponents = {
	emptyGrid: EmptyGridComponent,
	header: constant(null),
	modal: constant(null),
	gridFooter: constant(null),
	gridHeader: constant(null),
	title: TitleComponent,
	tooltip: constant(null),
	columnFilterHeader: ColumnFilterHeader,
	columnFilterFooter: ColumnFilterFooter,
};

const defaultClasses = {
	content: '',
	wrapper: 'fullheight',
	header: '',
	headerMenu: '',
	title: 'spc--bottom--lrg',
	print: 'reportprint__table',
};

const getInlineFilterValues = filterId => {
	return get(validInlineFilterValues, filterId);
};

const showFilterThreshold = 990;
const horizontalScrollHeight = 26;
const minColumnWidth = 45;
const filterMargin = 0;

class Grid extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showFilters: window.innerWidth > showFilterThreshold,
			modal: {
				name: modalNames.none,
				data: null,
			},
			displayHeaderMenu: window.innerWidth < props.headerMenuThreshold,
			isExtraSmallScreen: window.innerWidth < screenSize.sml,
			isExtraLargeScreen: window.innerWidth >= screenSize.xxxlrg,
			isLargeScreen: window.innerWidth >= screenSize.xlrg,
			columnFilterState: {
				columns: cloneDeep(props.columns),
				activeKeys: [],
			},
		};

		this.contentRef = createRef();
		this.gridHolderRef = createRef();
		this.filtersContainerRef = createRef();
		this.gridFooterRef = createRef();
		this.mainFilterRef = createRef();
		this.gridRef = createRef();
		this.actionsGridRef = {
			current: null,
		};
		this.userAccountRef = createRef();
		this.filterSelectionRef = createRef();
		this.printGridButtonRef = createRef();
		this.columnFilterPortalRef = createRef();
		this.actionsGridHolderRef = createRef();

		this.oldVisibleColumns = [];
		// Workaround for minifier
		this.renderEmptyGrid = this.renderEmptyGrid.bind(this);
	}

	get scrollBarWidth() {
		// Creating invisible container
		const outer = document.createElement('div');
		outer.style.visibility = 'hidden';
		outer.style.overflow = 'scroll'; // forcing scrollbar to appear
		outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
		document.body.appendChild(outer);

		// Creating inner element and placing it in the container
		const inner = document.createElement('div');
		outer.appendChild(inner);

		// Calculating difference between container's full width and the child width
		const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

		// Removing temporary elements from the DOM
		outer.parentNode.removeChild(outer);

		return scrollbarWidth;
	}

	get components() {
		return this.mergeComponents(this.props.components);
	}

	get classes() {
		return this.mergeClasses(this.props.classes);
	}

	get actionsGridScroll() {
		if (
			has(this.actionsGridRef, 'current.base.viewport.canvas.canvas') &&
			this.actionsGridRef.current.base.viewport.canvas.canvas
		) {
			return {
				top: this.actionsGridRef.current.base.viewport.canvas.canvas.scrollTop,
				left: this.actionsGridRef.current.base.viewport.canvas.canvas.scrollLeft,
			};
		}
	}

	get scroll() {
		const elem =
			get(this.gridRef, 'current.base.viewport.canvas.canvas') || get(this.gridRef, 'current.base.emptyView');
		if (!elem) {
			return;
		}
		return {
			top: elem.scrollTop,
			left: elem.scrollLeft,
		};
	}

	get headerScroll() {
		const header = get(this.gridRef, 'current.base.header.row');
		if (!header) {
			return;
		}
		//eslint-disable-next-line
		const elem = findDOMNode(header);
		if (!elem) {
			return;
		}
		return {
			top: elem.scrollTop,
			left: elem.scrollLeft,
		};
	}

	componentDidMount = () => {
		const { initialFetch, fetchData } = this.props;
		this.showOrHideFilters();
		this.calculateColumnWidths();
		if (initialFetch) {
			fetchData();
		}
		window.addEventListener('resize', this.setGridSizes);
		this.setGridSizes();
		if (this.contentRef.current) {
			this.oldWidth = window.innerWidth;
			ResizeSensor(this.contentRef.current, async () => {
				this.showOrHideFilters();
				clearTimeout(this.timeout);
				this.timeout = setTimeout(this.calculateColumnWidths, 50);
				this.oldWidth = window.innerWidth;
			});
		}
	};

	componentDidUpdate = prevProps => {
		const { filteredRows: oldRows } = prevProps;
		const { filteredRows: newRows } = this.props;
		if (oldRows !== newRows) {
			const filterRow = get(this.gridRef, 'current.base.header.filterRow');
			if (filterRow) {
				filterRow.forceUpdate();
				//eslint-disable-next-line
				const node = findDOMNode(filterRow);
				if (node) {
					node.removeEventListener('scroll', this.syncScrollsLeft);
					node.addEventListener('scroll', this.syncScrollsLeft);
				}
			}
			const emptyView = get(this.gridRef, 'current.base.emptyView');
			if (emptyView) {
				emptyView.removeEventListener('scroll', this.syncHeaderScrolls);
				emptyView.addEventListener('scroll', this.syncHeaderScrolls);
			}

			const changedEmptiness = (isEmpty(oldRows) && !isEmpty(newRows)) || (isEmpty(newRows) && !isEmpty(oldRows));
			const base = get(this.gridRef, 'current.base');
			if (changedEmptiness && base) {
				base._scrollLeft = 0;
				base._onScroll();
			}
		}
	};

	componentWillUnmount() {
		window.removeEventListener('resize', this.setGridSizes);
	}

	shouldComponentUpdate = (nextProps, nextState) => {
		if (
			nextProps.fetchingData !== this.props.fetchingData ||
			nextProps.fetchingAdditionalData !== this.props.fetchingAdditionalData ||
			nextProps.filteredRows !== this.props.filteredRows ||
			nextProps.columns !== this.props.columns ||
			nextProps.data !== this.props.data ||
			nextProps.inlineFilters !== this.props.inlineFilters ||
			nextProps.expanded !== this.props.expanded ||
			nextProps.lastApiRefNum !== this.props.lastApiRefNum ||
			nextProps.type !== this.props.type ||
			nextProps.filters !== this.props.filters ||
			nextProps.tooltipProps !== this.props.tooltipProps ||
			nextState.showFilters !== this.state.showFilters ||
			nextState.modal !== this.state.modal ||
			nextState.additionalPixel !== this.state.additionalPixel ||
			nextState.columnFilterState !== this.state.columnFilterState ||
			nextProps.pagination !== this.props.pagination
		) {
			return true;
		}
		return false;
	};

	setGridSizes = () => {
		const { displayHeaderMenu, isExtraSmallScreen, isExtraLargeScreen, isLargeScreen } = this.state;
		const { headerMenuThreshold } = this.props;
		let anyChanged = false;
		const newState = {};
		const updatedDisplayHeaderMenu = window.innerWidth < headerMenuThreshold;
		if (updatedDisplayHeaderMenu !== displayHeaderMenu) {
			newState.displayHeaderMenu = updatedDisplayHeaderMenu;
			anyChanged = true;
		}
		const updatedIsExtraLargeScreen = window.innerWidth >= screenSize.xxxlrg;
		if (updatedIsExtraLargeScreen !== isExtraLargeScreen) {
			newState.isExtraLargeScreen = updatedIsExtraLargeScreen;
			anyChanged = true;
		}
		const updatedIsLargeScreen = window.innerWidth >= screenSize.xlrg;
		if (updatedIsLargeScreen !== isLargeScreen) {
			newState.isLargeScreen = updatedIsLargeScreen;
			anyChanged = true;
		}
		const updatedIsExtraSmallScreen = window.innerWidth < screenSize.sml;
		if (updatedIsExtraSmallScreen !== isExtraSmallScreen) {
			newState.isExtraSmallScreen = updatedIsExtraSmallScreen;
			anyChanged = true;
		}
		if (anyChanged) {
			this.setState(newState);
		}
	};

	syncActionScrollsTop = e => {
		if (!this.isSyncingActionsScroll) {
			this.isSyncingGridScroll = true;
			const scrollTop = get(e, 'target.scrollTop', 0);
			const currentScroll = this.actionsGridScroll;
			if (currentScroll && scrollTop !== currentScroll.top) {
				this.actionsGridScrollTo({ top: scrollTop });
			}
		}
		this.isSyncingActionsScroll = false;
	};

	syncScrollsTop = e => {
		if (!this.isSyncingGridScroll) {
			this.isSyncingActionsScroll = true;
			const scrollTop = get(e, 'target.scrollTop', 0);
			const currentScroll = this.scroll;
			if (currentScroll && scrollTop !== currentScroll.top) {
				this.scrollTo({ top: scrollTop });
			}
		}
		this.isSyncingGridScroll = false;
	};

	syncScrollsLeft = debounce(e => {
		const scrollLeft = get(e, 'target.scrollLeft', 0);
		const currentScroll = this.scroll;
		if (currentScroll && scrollLeft !== currentScroll.left) {
			this.scrollTo({ left: scrollLeft });
		}
	}, 25);

	syncHeaderScrolls = debounce(({ target: { scrollLeft } }) => {
		const currentScroll = this.headerScroll;
		if (currentScroll && scrollLeft !== currentScroll.left) {
			this.scrollHeader({ left: scrollLeft });
		}
	}, 25);

	showOrHideFilters = () => {
		const { showFilters } = this.state;
		let shouldChange = false;

		if (
			(window.innerWidth > showFilterThreshold && this.oldWidth <= showFilterThreshold && !showFilters) ||
			(window.innerWidth <= showFilterThreshold && this.oldWidth > showFilterThreshold && showFilters)
		) {
			shouldChange = true;
		}

		return new Promise(resolve => {
			if (shouldChange) {
				this.setState({ showFilters: !showFilters }, resolve);
			} else {
				resolve();
			}
		});
	};

	clearFilters = async () => {
		if (this.props.useInlineFilters) {
			await this.props.onChange([
				{
					key: 'inlineFilters',
					value: {},
				},
			]);
			this.updateToolbar();
		}
		if (this.mainFilterRef.current) {
			this.mainFilterRef.current.resetFilter();
		}
	};

	updateToolbar = callback => {
		// workaround because react-data-grid doesn't have a way of resetting the filters except by toggling the filtering ability altogether
		if (this.gridRef.current && this.props.useInlineFilters) {
			this.gridRef.current.setState({ canFilter: false }, () => {
				this.gridRef.current.setState({ canFilter: true }, callback);
			});
		}
	};

	updateColumnFilterState = newState => {
		this.setState({
			columnFilterState: {
				...this.state.columnFilterState,
				...newState,
			},
		});
	};

	calculateColumnWidths = async (_, updatedColumns, isRecursedCall = false) => {
		const columns = cloneDeep(updatedColumns || this.props.columns);
		const { actionsGridColumn, type } = this.props;
		if (actionsGridColumn) {
			const fixActionColumn = window.innerWidth >= screenSize.sml && !this.detailsRow;
			const emptyColumn = find(columns, { key: 'isHidden' });
			emptyColumn.visible = fixActionColumn;
			emptyColumn.filterable = fixActionColumn;
			if (fixActionColumn) {
				emptyColumn.formatter = () => null;
				delete emptyColumn.filterRenderer;
				emptyColumn.filterable = false;
			} else if (!this.detailsRow) {
				emptyColumn.visible = true;
				emptyColumn.filterable = type === 'merchants';
				emptyColumn.formatter = actionsGridColumn[0].formatter;
				emptyColumn.filterRenderer = actionsGridColumn[0].filterRenderer;
			}
		}
		const displayedColumns = this.getDisplayedColumns(columns);
		const lastColumn = last(filter(displayedColumns, ({ fixWidth }) => !fixWidth));
		const visibleColumnWidths =
			sum(
				map(displayedColumns, ({ resizedWidth, fixWidth, initWidth }) => (!resizedWidth && !fixWidth ? initWidth : 0))
			) || 0;
		const resizedColumnWidths =
			sum(
				map(displayedColumns, ({ resizedWidth, fixWidth, initWidth }) => resizedWidth || (fixWidth && initWidth) || 0)
			) || 0;
		if (!this.gridRef.current || !this.gridRef.current.grid) {
			return;
		}
		each(columns, column => {
			delete column.beforeStretchWidth;
		});
		const verticalScrollbarWidth = this.scrollBarWidth + 3;
		const widthToFill = this.calculateGridWidth(this.contentRef.current) - verticalScrollbarWidth - resizedColumnWidths;
		if (widthToFill > visibleColumnWidths) {
			each(displayedColumns, column => {
				if (!column.resizedWidth) {
					if (column === lastColumn || column.fixWidth) {
						column.width = column.initWidth;
					} else {
						const factor = column.initWidth / visibleColumnWidths;
						column.width = parseInt(factor * widthToFill, 10) - 2;
					}
				} else {
					column.width = column.resizedWidth;
				}
			});
		} else {
			each(displayedColumns, column => {
				column.width = column.resizedWidth || column.initWidth;
			});
		}
		const actualColumnWidth = sum(map(displayedColumns, c => c.width || 0)) || 0;
		const actualWidthToFill = this.calculateGridWidth(this.contentRef.current) - verticalScrollbarWidth;
		if (actualColumnWidth < actualWidthToFill && lastColumn) {
			lastColumn.beforeStretchWidth = lastColumn.width;
			lastColumn.width += actualWidthToFill - actualColumnWidth;
		}
		const changes = [
			{
				key: 'columns',
				value: columns,
			},
		];
		const inlineFilters = clone(this.props.inlineFilters);
		let anyChanged = false;
		each(inlineFilters, (_, key) => {
			const column = find(columns, { key });
			if (!column || !column.visible) {
				delete inlineFilters[key];
				anyChanged = true;
			}
		});
		if (anyChanged) {
			changes.push({
				key: 'inlineFilters',
				value: inlineFilters,
			});
		}
		await this.props.onChange(changes);
		setTimeout(() => {
			if (
				!isRecursedCall &&
				this.calculateGridWidth(this.contentRef.current) !== actualWidthToFill + verticalScrollbarWidth
			) {
				this.calculateColumnWidths(_, updatedColumns, true);
			}
		});
		this.updateViewport();
	};

	calculateGridWidth = ref => {
		try {
			let lContentWidth = 0;
			if (ref) {
				lContentWidth = Math.round(ref.clientWidth) || 0;
				const styles = window.getComputedStyle(ref, null);
				if (styles) {
					const paddingLeft = styles.getPropertyValue('padding-left');
					const paddingRight = styles.getPropertyValue('padding-right');
					if (paddingLeft) {
						const [value] = split(paddingLeft, 'px');
						if (value) {
							lContentWidth -= parseInt(value, 10);
						}
					}
					if (paddingRight) {
						const [value] = split(paddingRight, 'px');
						if (value) {
							lContentWidth -= parseInt(value, 10);
						}
					}
				}
			}
			return lContentWidth;
		} catch (e) {
			//intentionally empty catch block
		}
		return '100%';
	};

	calculateGridHeight = () => {
		try {
			let lContentTop = 0;
			if (this.contentRef.current) {
				lContentTop = Math.round(this.contentRef.current.getBoundingClientRect().top) || 0;
				const styles = window.getComputedStyle(this.contentRef.current, null);
				if (styles) {
					const paddingTop = styles.getPropertyValue('padding-top');
					const paddingBottom = styles.getPropertyValue('padding-bottom');
					if (paddingTop) {
						const [value] = split(paddingTop, 'px');
						if (value) {
							lContentTop += parseInt(value, 10);
						}
					}
					if (paddingBottom) {
						const [value] = split(paddingBottom, 'px');
						if (value) {
							lContentTop += parseInt(value, 10);
						}
					}
				}
			}
			const filtersHeight = this.filtersContainerRef.current
				? Math.round(this.filtersContainerRef.current.getBoundingClientRect().height) || 0
				: 0;
			const gridFooterHeight = this.gridFooterRef.current
				? this.gridFooterRef.current.getBoundingClientRect().height + filterMargin
				: 0;
			const footer = document.querySelector('footer');
			const footerHeight = (footer && footer.getBoundingClientRect().height) || 0;
			const total =
				window.innerHeight -
				(lContentTop + filtersHeight + gridFooterHeight + footerHeight + horizontalScrollHeight + 11);
			return total;
		} catch (e) {
			//intentionally empty catch block
		}
		return 'auto';
	};

	reset = () => {
		this.syncFilters({ isReset: true });
		this.showOrHideFilters();
		this.calculateColumnWidths();
	};

	mergeComponents = memoize(components => merge({}, defaultComponents, components));

	mergeClasses = memoize(classes => merge({}, defaultClasses, classes));

	getDisplayedColumns = memoize(columns => filter(columns, col => col.visible));

	getDetailsRowIndex = () => {
		const { expanded, filteredRows, expandInSidebar } = this.props;
		if (expandInSidebar) {
			return -1;
		}
		const expandedKey = findKey(expanded, Boolean);
		if (expandedKey == null) {
			return -1;
		}
		return findIndex(filteredRows, ({ index }) => index === parseInt(expandedKey, 10));
	};

	rowGetter = i => this.props.filteredRows[i];

	onGridSort = (column, sortDirection) => {
		let data = cloneDeep(this.props.data);
		if (data && data.xReportData && data.xReportData.length > 0) {
			if (sortDirection === 'NONE') {
				data.xReportData = orderBy(data.xReportData, [item => item.index], ['asc']);
			} else {
				const sortKey = this.props.resolveColumnName(column);
				const direction = this.props.resolveSortDirection(sortDirection, column);
				data.xReportData = data.xReportData.sort(({ [sortKey]: a }, { [sortKey]: b }) => {
					if (a === undefined) {
						return b === undefined ? 0 : -1;
					}
					if (b === undefined) {
						return 1;
					}
					const aNumber = Number(a);
					const bNumber = Number(b);
					if (!isNaN(aNumber) && !isNaN(bNumber)) {
						return aNumber - bNumber;
					}
					if (typeof a === 'string' && typeof b === 'string') {
						const aLower = toLower(a);
						const bLower = toLower(b);
						if (aLower === bLower) {
							return 0;
						}
						return aLower < bLower ? -1 : 1;
					}
					if (typeof a === 'string' || typeof a === 'string') {
						return typeof a === 'string' ? -1 : 1;
					}
					if (a == b) {
						return 0;
					}
					return a < b ? -1 : 1;
				});
				if (toLower(direction) === 'desc') {
					reverse(data.xReportData);
				}
			}
			each(data.xReportData, (item, index) => {
				item.gridRowNumber = index;
			});
		}

		const columns = cloneDeep(this.props.columns);
		each(columns, function(item) {
			if (item.key === column) {
				item.sortDirection = sortDirection;
			} else {
				item.sortDirection = null;
			}
		});
		this.props.onChange([
			{
				key: 'columns',
				value: columns,
			},
			{
				key: 'data',
				value: data,
			},
		]);
	};

	onCellExpand = args => {
		const rows = this.props.data.xReportData.slice(0);
		const rowKey = args.rowData.index;
		let rowIndex = findIndex(rows, row => row.index === rowKey);

		const expanded = clone(this.props.expanded);

		if (expanded && !expanded[rowKey]) {
			const expandedItem = find(rows, r => r.isDetails);
			if (expandedItem) {
				const expandedItemRowKey = expandedItem.index;
				const expandedItemRowIndex = rows.indexOf(expandedItem);
				expanded[expandedItemRowKey] = false;
				rows[expandedItemRowIndex] = clone(expandedItem);
				rows[expandedItemRowIndex].isDetails = false;
				delete rows[expandedItemRowIndex].expandedRowProps;
			}
			expanded[rowKey] = true;
		} else if (expanded[rowKey] && !args.openOnly) {
			expanded[rowKey] = false;
		}
		rows[rowIndex] = clone(rows[rowIndex]);
		rows[rowIndex].isDetails = expanded[rowKey];
		if (expanded[rowKey]) {
			rows[rowIndex].expandedRowProps = args.expandedRowProps || {};
		} else {
			delete rows[rowIndex].expandedRowProps;
		}

		const newData = clone(this.props.data);
		newData.xReportData = rows;
		this.props.onChange([
			{
				key: 'expanded',
				value: expanded,
			},
			{
				key: 'data',
				value: newData,
			},
		]);
	};

	onColumnResize = async (index, newWidth) => {
		const columns = cloneDeep(this.props.columns);
		const displayedColumns = this.getDisplayedColumns(columns);
		const column = displayedColumns[index];
		if (!column) {
			return;
		}
		const stretchedColumns = filter(columns, c => c.beforeStretchWidth);
		each(stretchedColumns, stretchedColumn => {
			stretchedColumn.width = stretchedColumn.beforeStretchWidth;
			delete stretchedColumn.beforeStretchWidth;
		});
		column.width = Math.max(newWidth, minColumnWidth);
		column.resizedWidth = column.width;
		const visibleColumnWidths = sum(map(displayedColumns, c => c.width || 0)) || 0;
		const widthToFill = this.calculateGridWidth(this.contentRef.current);
		if (visibleColumnWidths < widthToFill) {
			const lastColumn = last(filter(displayedColumns, ({ fixWidth }) => !fixWidth));
			lastColumn.beforeStretchWidth = lastColumn.width;
			lastColumn.width += widthToFill - visibleColumnWidths - 6;
		}

		await this.props.onChange([
			{
				key: 'columns',
				value: columns,
			},
		]);
		this.updateViewport();
	};

	updateViewport = () => {
		this.setState(
			{
				additionalPixel: true,
			},
			() => {
				this.setState({
					additionalPixel: false,
				});
			}
		);
		const currentScroll = this.scroll;
		if (currentScroll) {
			this.scrollTo({ top: currentScroll.top - 1 });
			this.scrollTo({ top: currentScroll.top + 1 });
			this.scrollTo({ top: currentScroll.top });
			this.actionsGridScrollTo({ top: currentScroll.top - 1 });
			this.actionsGridScrollTo({ top: currentScroll.top + 1 });
			this.actionsGridScrollTo({ top: currentScroll.top });
		}
	};

	handleInitialSort = () => {
		let selectedColumn = find(this.props.columns, column => column.sortDirection);
		if (!selectedColumn) {
			selectedColumn = find(this.props.columns, column => column.isDefaultSorter);
		}
		if (this.gridRef && this.gridRef.current && selectedColumn) {
			if (selectedColumn.isDefaultSorter) {
				this.gridRef.current.handleSort(
					selectedColumn.key,
					selectedColumn.sortDirection || selectedColumn.defaultSortDirection
				);
			} else {
				this.gridRef.current.handleSort(selectedColumn.key, selectedColumn.sortDirection || 'ASC');
			}
		}
	};

	handleInlineFilter = async filter => {
		const newFilters = cloneDeep(this.props.inlineFilters);
		if (filter.filterTerm) {
			newFilters[filter.column.key] = filter;
		} else {
			delete newFilters[filter.column.key];
		}
		await this.props.onChange([
			{
				key: 'inlineFilters',
				value: newFilters,
			},
		]);
		this.scrollTo({ top: 0 });
		this.updateViewport();
	};

	getValidInlineFilterValues = (cid, filterId = cid) => {
		const { data } = this.props;
		const rows = data ? data.xReportData : [];
		const values = this.props.getInlineFilterValues(filterId, rows);

		if (values) {
			let inlineFilterValues = [];
			let mappedInlineFilterValues = [];

			inlineFilterValues = filter(values, ({ key, isDefault }) => {
				if (isDefault) {
					return some(
						rows,
						row => row[cid] !== '' && !some(values, value => !value.isDefault && this.isValueInRow(row, cid, value.key))
					);
				} else {
					return some(rows, row => this.isValueInRow(row, cid, key));
				}
			});

			mappedInlineFilterValues = transform(inlineFilterValues, (acc, value, index) => {
				const item = find(acc, ({ label }) => label === value.label);
				if (item && !includes(item.key, value.key)) {
					if (!isArray(item.key)) {
						item.key = [item.key, value.key];
					} else {
						item.key.push(value.key);
					}
				} else if (!item) {
					acc[index] = value;
				}
			});
			return mappedInlineFilterValues;
		} else {
			return ['Options error'];
		}
	};

	isValueInRow = (row, cid, key) => {
		if (isArray(key)) {
			return some(key, value => trim(toLower(row[cid])) === trim(toLower(value)));
		} else {
			return trim(toLower(row[cid])) === trim(toLower(key));
		}
	};

	onFilterColumns = async (columns, updateDefaultColumns) => {
		this.oldVisibleColumns = concat(
			this.oldVisibleColumns,
			map(this.getDisplayedColumns(this.props.columns), ({ key }) => key)
		);
		const newColumns = orderBy(
			map(this.props.columns, column => {
				const newColumn = find(columns, { key: column.key });
				if (
					newColumn.visible === column.visible &&
					newColumn.order === column.order &&
					newColumn.isAdvancedField === column.isAdvancedField
				) {
					return column;
				}
				return {
					...column,
					visible: newColumn.visible,
					order: newColumn.order,
					isAdvancedField: newColumn.isAdvancedField,
				};
			}),
			'order'
		);
		await this.calculateColumnWidths(null, newColumns);
		const changes = [
			{
				key: 'data',
				value: cloneDeep(this.props.data),
			},
		];
		if (updateDefaultColumns) {
			changes.push({
				key: 'defaultColumns',
				value: newColumns,
			});
		}
		await this.props.onChange(changes);
		const newVisibleColumns = this.getDisplayedColumns(this.props.columns);
		const isAnyColumnNew = some(newVisibleColumns, ({ key }) => !includes(this.oldVisibleColumns, key));
		if (isAnyColumnNew && this.props.refetchOnColumnChange) {
			this.refreshGridData();
		}
	};

	mapColumnsForExport = (columns, isPrint) => {
		let filteredColumns = filter(
			columns,
			c => !c.hideOnExport && (!isPrint || !c.hideOnPrint) && (c.hideable || c.visible)
		);
		each(filteredColumns, (column, index) => {
			if (column.dependentExportKey && !find(filteredColumns, { key: column.dependentExportKey })) {
				let dependentColumn = find(this.props.columns, { key: column.dependentExportKey });
				filteredColumns.splice(index, 0, dependentColumn);
			}
		});
		return map(filteredColumns, ({ key, exportKey, fieldKey, name }) => ({
			key: exportKey || key,
			name,
			fieldKey,
		}));
	};

	showLoader = show => {
		this.props.onChange([
			{
				key: 'fetchingAdditionalData',
				value: show,
			},
		]);
	};

	openCloseModal = (modalObj, ...rest) => {
		let state = {
			modal: modalObj,
		};
		this.setState(state);
		this.props.onModalToggle(modalObj, ...rest);
	};

	refreshGridData = () => {
		this.props.fetchData();
	};

	setRowDetailsRef = ref => {
		this.detailsRow = ref;
		this.calculateColumnWidths();
	};

	setActionsGridRef = ref => {
		const refChanged = !!this.actionsGridRef.current !== !!ref;
		this.actionsGridRef.current = ref;
		if (refChanged) {
			this.calculateColumnWidths();
			const grid = get(this.gridRef, 'current.base.viewport.canvas.canvas');
			if (grid) {
				grid.removeEventListener('scroll', this.syncActionScrollsTop);
				grid.addEventListener('scroll', this.syncActionScrollsTop);
			}
			const actionsGrid = get(this.actionsGridRef, 'current.base.viewport.canvas.canvas');
			if (actionsGrid) {
				actionsGrid.removeEventListener('scroll', this.syncScrollsTop);
				actionsGrid.addEventListener('scroll', this.syncScrollsTop);
			}
		}
	};

	actionsGridScrollTo = ({ top }) => {
		if (
			has(this.actionsGridRef, 'current.base.viewport.canvas.canvas') &&
			this.actionsGridRef.current.base.viewport.canvas.canvas
		) {
			if (top !== undefined) {
				this.actionsGridRef.current.base.viewport.canvas.canvas.scrollTop = top;
			}
		}
	};

	scrollTo = ({ top, left }) => {
		const elem =
			get(this.gridRef, 'current.base.viewport.canvas.canvas') || get(this.gridRef, 'current.base.emptyView');
		if (!elem) {
			return;
		}
		if (top !== undefined) {
			elem.scrollTop = top;
		}
		if (left !== undefined) {
			elem.scrollLeft = left;
		}
	};

	scrollHeader = ({ top, left }) => {
		each(['current.base.header.row', 'current.base.header.filterRow'], getter => {
			const row = get(this.gridRef, getter);
			if (!row) {
				return;
			}
			//eslint-disable-next-line
			const elem = findDOMNode(row);
			if (!elem) {
				return;
			}
			if (top !== undefined) {
				elem.scrollTop = top;
			}
			if (left !== undefined) {
				elem.scrollLeft = left;
			}
		});
	};

	trimValues = filter => {
		each(filter, ({ values }) => {
			each(values, (value, key) => {
				if (!!value && isString(value)) {
					values[key] = trim(value);
				}
			});
		});
	};

	updateFilters = async ({
		filters,
		activeFilters,
		forceRefresh = false,
		enableDateRange = false,
		standaloneFilter = null,
		isReset = false,
	}) => {
		const { syncQueryFilters, queryFilterValues, updateStandaloneFilter, fetchOnReset } = this.props;
		this.trimValues(activeFilters);
		this.trimValues(filters);

		const dateFilter = find(filters, i => i.key === 'date');
		const canDisableDateFilter = has(dateFilter, 'values.disabled');
		const shouldDateFilterEnable =
			canDisableDateFilter &&
			some(filters, item => item.allowsDateDisable && item.hasSelection) &&
			!some(filters, item => item.disallowsDateDisable && item.hasSelection);
		let loadData = false;
		const changes = [];
		if (shouldDateFilterEnable && enableDateRange) {
			const dateFilter = find(filters, i => i.key === 'date');
			dateFilter.values.disabled = false;
			const activeDateFilter = find(activeFilters, i => i.key === 'date');
			activeDateFilter.values.disabled = false;
			const otherFilters = filter(filters, i => i.key !== 'date');
			const otherActiveFilters = filter(activeFilters, i => i.key !== 'date');
			changes.push({
				key: 'filters',
				value: [dateFilter, ...otherFilters],
			});
			changes.push({
				key: 'activeFilters',
				value: [activeDateFilter, ...otherActiveFilters],
			});
			loadData = true;
		} else {
			if (filters) {
				changes.push({
					key: 'filters',
					value: filters,
				});
			}
			if (activeFilters) {
				changes.push({
					key: 'activeFilters',
					value: activeFilters,
				});
				loadData = true;
			}
		}
		this.showOrHideFilters();
		await this.props.onChange(changes);
		if (syncQueryFilters) {
			queryFilterValues(changes[0].value);
		}
		if (standaloneFilter && updateStandaloneFilter) {
			updateStandaloneFilter(standaloneFilter);
		} else if ((loadData || forceRefresh) && (!isReset || fetchOnReset)) {
			this.syncFilters();
		}
	};

	syncFilters = ({ isReset } = {}) => {
		const { fetchData, fetchOnReset } = this.props;
		if (this.mainFilterRef.current) {
			this.mainFilterRef.current.syncFilters();
		}
		if (isReset && !fetchOnReset) {
			return;
		}
		fetchData();
	};

	resizeGrid = () => {
		this.forceUpdate();
	};

	renderGridPagination = () => {
		const {
			showResults,
			hasPaging,
			handlePageChange,
			pagination: { activePage, rowsPerPage, totalRowCount },
		} = this.props;
		if (showResults) {
			return (
				<Fragment>
					{hasPaging && (
						<Pagination
							activePage={activePage || 0}
							itemsCountPerPage={rowsPerPage || 0}
							totalItemsCount={totalRowCount || 0}
							pageRangeDisplayed={3}
							onChange={handlePageChange}
							className="pagination"
						/>
					)}
				</Fragment>
			);
		}
		return null;
	};

	renderPrintButton = () => {
		const {
			data,
			hasMoreData,
			hasPaging,
			showPrintDropdown,
			fetchAllData,
			fullFilteredRows,
			type,
			columns,
			printTitle,
			printComponents,
			printProcessingFee,
			printNetSale,
		} = this.props;
		const visibleColumns = this.mapColumnsForExport(this.getDisplayedColumns(columns), true);
		const allColumns = this.mapColumnsForExport(columns, true);

		return (
			<PrintGridButton
				getAll={fetchAllData}
				data={fullFilteredRows}
				columns={visibleColumns}
				allColumns={allColumns}
				type={type}
				title={printTitle}
				showLoaderMethod={this.showLoader}
				showDropdown={showPrintDropdown}
				ref={this.printGridButtonRef}
				hasMoreData={hasPaging && hasMoreData(data)}
				className={this.classes.print}
				components={printComponents}
				printProcessingFee={printProcessingFee}
				printNetSale={printNetSale}
			/>
		);
	};

	renderExportButton = () => {
		const {
			columns,
			fullFilteredRows,
			activeFilters,
			fetchExportData,
			hasPaging,
			hasMoreData,
			data,
			allTitle,
			type,
			printProcessingFee,
			printNetSale,
			showExportDropdown,
		} = this.props;
		const visibleColumns = this.mapColumnsForExport(this.getDisplayedColumns(columns));

		return (
			<div className="pos--rel">
				<ExportComponent
					data={fullFilteredRows}
					columns={visibleColumns}
					filters={activeFilters}
					showLoaderMethod={this.showLoader}
					exportType={type}
					allTitle={allTitle}
					fetchExportData={fetchExportData}
					hasMoreData={hasPaging && hasMoreData(data)}
					type={type}
					exportProcessingFee={printProcessingFee}
					exportNetSale={printNetSale}
					showDropdown={showExportDropdown}
				/>
			</div>
		);
	};

	renderEmptyGrid() {
		const { columns } = this.props;
		const displayedColumns = this.getDisplayedColumns(columns);
		const columnWidth = sum(map(displayedColumns, c => c.width || 0)) || 0;
		return (
			<div
				style={{
					width: columnWidth,
					minWidth: '500px',
				}}
			>
				<this.components.emptyGrid emptyMessage={this.props.emptyMessage} fetchingData={this.props.fetchingData} />
			</div>
		);
	}

	renderGridBody() {
		const {
			emptyStateClassName,
			isExpandable,
			columns,
			filteredRows,
			useInlineFilters,
			type,
			expandInSidebar,
			rowDetailsProps,
			actionsGridColumn,
			tooltipProps,
		} = this.props;
		const { isExtraSmallScreen, isLargeScreen } = this.state;
		const hasGridActions = !!actionsGridColumn;
		const gridClassName = `${isExpandable ? 'grid--pointer' : ''}${hasGridActions ? ' react-grid--has-actions' : ''} ${
			columns.length === 0 && filteredRows.length === 0 ? emptyStateClassName : ''
		}`;

		const scrollWidth = get(this.gridRef, 'current.base.viewport.canvas.canvas.scrollWidth', 0);
		const clientWidth = get(this.gridRef, 'current.base.viewport.canvas.canvas.clientWidth', 0);
		let gridScrollbarHeight = 0;
		if (scrollWidth !== clientWidth) {
			gridScrollbarHeight = this.scrollBarWidth;
		}

		return (
			<div
				className={this.detailsRow && expandInSidebar && !isLargeScreen ? 'display--n' : 'pos--rel'}
				ref={this.gridHolderRef}
			>
				<div
					style={{
						width: this.calculateGridWidth(this.contentRef.current),
						height: this.calculateGridHeight(),
						minHeight: 300,
					}}
				>
					<this.components.tooltip {...tooltipProps} />
					<div className="react-grid">
						<ReactDataGrid
							minWidth={this.calculateGridWidth(this.contentRef.current) + (this.state.additionalPixel ? 1 : 0)}
							minHeight={this.calculateGridHeight()}
							columns={this.getDisplayedColumns(columns)}
							rowsCount={filteredRows.length}
							rowGetter={this.rowGetter}
							onGridSort={this.onGridSort}
							ref={this.gridRef}
							toolbar={useInlineFilters ? ToolbarComponent : constant(null)}
							onAddFilter={this.handleInlineFilter}
							getValidFilterValues={this.getValidInlineFilterValues}
							onClearFilters={this.handleInlineFilter}
							emptyRowsView={this.renderEmptyGrid}
							onCellExpand={this.onCellExpand}
							onRowClick={this.props.onRowClick}
							onColumnResize={this.onColumnResize}
							minColumnWidth={this.props.minColumnWidth || minColumnWidth}
							enableRowSelect={null}
							rowScrollTimeout={null}
							headerRowHeight={40}
							rowHeight={52}
							onRowContextMenu={this.props.onRowContextMenu}
							enableCellSelect={false}
							getDetailsRowIndex={this.getDetailsRowIndex}
							rowRenderer={
								this.components.rowRenderer ? (
									<this.components.rowRenderer
										createDetailsLink={this.props.createDetailsLink}
										setDetailsRef={this.setRowDetailsRef}
										refreshGridData={this.refreshGridData}
										openModal={this.openCloseModal}
										gridHolder={this.gridHolderRef.current}
										resizeGrid={this.resizeGrid}
										type={type}
										rowDetailsRenderer={expandInSidebar ? null : this.components.rowDetails}
										rowDetailsProps={rowDetailsProps}
									/>
								) : (
									undefined
								)
							}
						/>
					</div>
				</div>
				{hasGridActions && filteredRows.length && !isExtraSmallScreen && (!this.detailsRow || !expandInSidebar) ? (
					<div
						style={{
							width: '78px',
							height: this.calculateGridHeight() - gridScrollbarHeight,
							position: 'absolute',
							top: '0',
							right: '-4px',
						}}
						ref={this.actionsGridHolderRef}
						className={`${gridClassName}react-grid react-grid__fixed`}
					>
						<ReactDataGrid
							minHeight={this.calculateGridHeight() - gridScrollbarHeight}
							columns={actionsGridColumn}
							rowsCount={filteredRows.length}
							rowGetter={this.rowGetter}
							onGridSort={this.onGridSort}
							ref={this.setActionsGridRef}
							toolbar={useInlineFilters ? ToolbarComponent : constant(null)}
							onAddFilter={this.handleInlineFilter}
							getValidFilterValues={this.getValidInlineFilterValues}
							onClearFilters={this.handleInlineFilter}
							emptyRowsView={null}
							onCellExpand={this.onCellExpand}
							onRowClick={this.props.onRowClick}
							onColumnResize={this.onColumnResize}
							onRowContextMenu={this.props.onRowContextMenu}
							minColumnWidth={minColumnWidth}
							enableRowSelect={null}
							rowScrollTimeout={null}
							headerRowHeight={40}
							rowHeight={52}
							enableCellSelect={false}
							getDetailsRowIndex={this.getDetailsRowIndex}
							rowRenderer={
								this.components.rowRenderer ? (
									<this.components.rowRenderer
										setDetailsRef={this.setRowDetailsRef}
										refreshGridData={this.refreshGridData}
										openModal={this.openCloseModal}
										gridHolder={this.gridHolderRef.current}
										resizeGrid={this.resizeGrid}
										type={type}
										rowDetailsRenderer={expandInSidebar ? null : this.components.rowDetails}
										rowDetailsProps={rowDetailsProps}
									/>
								) : (
									undefined
								)
							}
						/>
					</div>
				) : null}
			</div>
		);
	}

	renderHeader() {
		const { showHeader } = this.props;
		return (
			showHeader && (
				<Fragment>
					<Header />
				</Fragment>
			)
		);
	}

	renderResultsAndRefNum = () => {
		const { lastApiRefNum, fullFilteredRows, showResults } = this.props;
		if (!showResults) return null;
		const numRecords = fullFilteredRows.length;
		const refWording = lastApiRefNum ? `(#${lastApiRefNum})` : '';
		return (
			<div className="flex--primary flex--gap--tny spc--bottom--lrg">
				<p className="type--p2 type--color--text--light">Number of results:</p>
				<span className="type--p2 type--color--text">{numRecords}</span>
				<span className="type--p3 type--color--text--light">{refWording}</span>
			</div>
		);
	};

	renderGridHeader() {
		const {
			enableFilters,
			filters,
			activeFilters,
			showGridHeader,
			filterColumns,
			enablePrint,
			enableExport,
			filterProps,
			displayCustomAction,
			isTab,
			preTitle,
		} = this.props;

		return (
			showGridHeader && (
				<div ref={this.filtersContainerRef}>
					{preTitle && this.renderTitle()}
					<div className="flex--secondary spc--bottom--med flex--gap--med">
						<h3>{this.props.title}</h3>
						<div className="flex--primary flex--gap--sml">
							{enableExport && <>{this.renderExportButton()}</>}
							{enablePrint && <>{this.renderPrintButton()}</>}
							<this.components.gridHeader openCloseModal={this.openCloseModal} refreshGridData={this.refreshGridData} />
						</div>
					</div>
					{isTab && this.renderTitle()}
					{this.renderResultsAndRefNum()}
					<div className="filter__toolbar">
						{enableFilters ? (
							<div className="filter__toolbar__list">
								<this.components.filter
									updateFilters={this.updateFilters}
									filters={filters}
									activeFilters={activeFilters}
									ref={this.mainFilterRef}
									filterSelectionRef={this.filterSelectionRef}
									clearFilters={this.clearFilters}
									{...filterProps}
								/>
								<div ref={this.filterSelectionRef} />
							</div>
						) : null}

						<div className="flex--primary flex--gap--sml flex--nowrap">
							{filterColumns && <div ref={this.columnFilterPortalRef} />}
							{displayCustomAction && <this.components.customActionFilter />}
						</div>
					</div>
				</div>
			)
		);
	}

	renderTitle() {
		return (
			<this.components.title
				title={this.props.title}
				className={this.classes.title}
				type={this.props.type}
				onTitleClick={this.props.onTitleClick}
				handleInlineFilter={this.handleInlineFilter}
			/>
		);
	}

	renderModal() {
		return <this.components.modal modal={this.state.modal} onModalClose={this.openCloseModal} />;
	}

	render() {
		const {
			hasPaging,
			canReorderColumns,
			data,
			fetchingAdditionalData,
			fetchingData,
			filteredRows,
			type,
			onLoadMoreLimitChange,
			loadMoreOptions,
			loadMoreLimit,
			expandInSidebar,
			defaultColumns,
			columnFilterType,
			columns,
		} = this.props;
		const { columnFilterState } = this.state;
		const detailRow = find(data && data.xReportData, r => r.isDetails);
		return (
			<React.Fragment>
				{this.renderModal()}
				{this.renderHeader()}
				<div className={this.classes.wrapper}>
					<div
						ref={this.contentRef}
						className={`${this.classes.content} ${
							this.gridRef.current && this.gridRef.current.state.canFilter ? '' : 'grid__holder--override__nofilter'
						} ${this.detailsRow && expandInSidebar ? 'is-expanded' : ''}`}
					>
						<div>
							{this.renderGridHeader()}
							{this.renderGridBody()}
						</div>
						{expandInSidebar && detailRow && (
							<this.components.rowDetails
								key={detailRow.index}
								row={detailRow}
								visibleColumns={this.props.columns}
								openModal={this.openCloseModal}
								refreshGridData={this.refreshGridData}
								gridHolder={this.gridHolderRef.current}
								setDetailsRef={this.setRowDetailsRef}
								resizeGrid={this.resizeGrid}
								type={this.props.type}
								{...(detailRow.expandedRowProps || {})}
								{...this.props.rowDetailsProps}
							/>
						)}
						<GridFooter
							gridFooterRef={this.gridFooterRef}
							isLoadMoreEnabled={hasPaging}
							fetchingAdditionalData={fetchingAdditionalData}
							fetchingData={fetchingData}
							filteredRows={filteredRows}
							type={type}
							onLoadMoreLimitChange={onLoadMoreLimitChange}
							loadMoreLimit={loadMoreLimit}
							loadMoreOptions={loadMoreOptions}
							openCloseModal={this.openCloseModal}
							CustomComponent={this.components.gridFooter}
							renderGridPagination={this.renderGridPagination}
						/>
						{this.columnFilterPortalRef.current &&
							createPortal(
								<ColumnFilterComponent
									defaultColumns={defaultColumns}
									columns={columns}
									filteredColumns={this.onFilterColumns}
									header={this.components.columnFilterHeader}
									footer={this.components.columnFilterFooter}
									isDisabled={data === null}
									type={columnFilterType}
									updateState={this.updateColumnFilterState}
									state={columnFilterState}
									reorderable={canReorderColumns}
								/>,
								this.columnFilterPortalRef.current
							)}{' '}
					</div>
				</div>
			</React.Fragment>
		);
	}
}

Grid.defaultProps = {
	components: {},
	fetchingData: false,
	fetchingAdditionalData: false,
	resolveColumnName: identity,
	resolveSortDirection: toLower,
	mapCellArgs: (rowId, row) => {
		if (rowId < 0) {
			return;
		}
		return {
			rowData: row,
			expandArgs: {
				children: [
					{
						isDetails: true,
						row: row,
					},
				],
			},
		};
	},
	onRowClick: noop,
	isExpandable: false,
	hasPaging: false,
	enableExport: false,
	enablePrint: false,
	onModalToggle: noop,
	enableFilters: false,
	fetchData: noop,
	showResults: false,
	hasMoreData: constant(false),
	showPanel: true,
	showGridHeader: true,
	showHeader: true,
	showPrintDropdown: true,
	showExportDropdown: true,
	initialFetch: true,
	syncQueryFilters: false,
	filterProps: {},
	refetchOnColumnChange: false,
	fetchOnReset: true,
	expandInSidebar: false,
	headerMenuThreshold: screenSize.xlrg,
	getInlineFilterValues: getInlineFilterValues,
};

Grid.propTypes = {
	components: PropTypes.shape({
		emptyGrid: isComponent,
		header: isComponent,
		gridHeader: isComponent,
		modal: isComponent,
		filter: isComponent,
		rowRenderer: isComponent,
		rowDetails: isComponent,
		gridFooter: isComponent,
		title: isComponent,
		tooltip: isComponent,
		columnFilterHeader: isComponent,
		columnFilterFooter: isComponent,
	}),
	classes: PropTypes.shape({
		header: PropTypes.string,
		headerMenu: PropTypes.string,
		content: PropTypes.string,
		wrapper: PropTypes.string,
		title: PropTypes.string,
		print: PropTypes.string,
		gridHeader: PropTypes.string,
		filter: PropTypes.string,
	}),
	emptyMessage: PropTypes.string,
	fetchingData: PropTypes.bool,
	fetchingAdditionalData: PropTypes.bool,
	filteredRows: PropTypes.arrayOf(PropTypes.object),
	fullFilteredRows: PropTypes.arrayOf(PropTypes.object),
	columns: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			name: PropTypes.string,
			initWidth: PropTypes.number.isRequired,
		})
	),
	defaultColumns: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			name: PropTypes.string,
			initWidth: PropTypes.number.isRequired,
		})
	),
	data: PropTypes.shape({
		xReportData: PropTypes.arrayOf(PropTypes.object),
	}),
	resolveColumnName: PropTypes.func,
	resolveSortDirection: PropTypes.func,
	inlineFilters: PropTypes.object,
	filters: PropTypes.arrayOf(PropTypes.object),
	activeFilters: PropTypes.arrayOf(PropTypes.object),
	onChange: PropTypes.func.isRequired,
	mapCellArgs: PropTypes.func,
	isExpandable: PropTypes.bool,
	hasPaging: PropTypes.bool,
	loadMoreOptions: PropTypes.arrayOf(PropTypes.number),
	hasMoreData: PropTypes.func,
	onLoadMoreLimitChange: PropTypes.func,
	loadMoreLimit: PropTypes.number,
	title: PropTypes.string,
	onTitleClick: PropTypes.func,
	enableExport: PropTypes.bool,
	enablePrint: PropTypes.bool,
	type: PropTypes.string,
	onModalToggle: PropTypes.func,
	enableFilters: PropTypes.bool,
	fetchData: PropTypes.func,
	fetchAllData: PropTypes.func,
	lastApiRefNum: PropTypes.string,
	showResults: PropTypes.bool,
	key: PropTypes.any,
	showPanel: PropTypes.bool,
	showGridHeader: PropTypes.bool,
	showHeader: PropTypes.bool,
	showPrintDropdown: PropTypes.bool,
	showExportDropdown: PropTypes.bool,
	initialFetch: PropTypes.bool,
	columnFilterType: PropTypes.string,
	syncQueryFilters: PropTypes.bool,
	queryFilterValues: PropTypes.func,
	allTitle: PropTypes.string,
	exportTypes: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string,
			name: PropTypes.string,
		})
	),
	fetchExportData: PropTypes.shape({
		current: PropTypes.func.isRequired,
		all: PropTypes.func,
	}),
	expanded: PropTypes.any,
	filterColumns: PropTypes.any,
	useInlineFilters: PropTypes.bool,
	filterProps: PropTypes.object,
	refetchOnColumnChange: PropTypes.bool,
	printTitle: PropTypes.string,
	printComponents: PropTypes.object,
	fetchOnReset: PropTypes.bool,
	expandInSidebar: PropTypes.bool,
	rowDetailsProps: PropTypes.object,
	tooltipProps: PropTypes.object,
	actionsGridColumn: PropTypes.array,
	headerMenuThreshold: PropTypes.number,
	selectCustomer: PropTypes.func,
	getInlineFilterValues: PropTypes.func,
	printProcessingFee: PropTypes.bool,
	printNetSale: PropTypes.bool,
	onRowClick: PropTypes.func,
	handlePageChange: PropTypes.func,
	pagination: PropTypes.object,
	canReorderColumns: PropTypes.bool,
	emptyStateClassName: PropTypes.string,
	displayCustomAction: PropTypes.any,
	isTab: PropTypes.bool,
	preTitle: PropTypes.bool,
};

export default Grid;
