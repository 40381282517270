import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, find, toLower, get, isEmpty, keys, each, map, findIndex, filter, noop } from 'lodash';
import { EquipmentEditMethods } from '../../utilities/equipment-edit-methods';
import FrontendProcessorComponent from '../../../Equipment/frontend-processor';
import EquipmentOption from '../../../Equipment/equipment-option';
import HardwarePurchasePlanComponent from '../../../Equipment/hardware-purchase-plan';
import { Modal } from '../../../../common/components/modal';
import { NumericFormat as NumberFormat } from 'react-number-format';
import { getThumbnailOverlay } from '../../../../common/utilities';
import QuantityStepper from '../../../Equipment/components/QuantityStepper';
import OptionComponent from '../../../Equipment/components/OptionComponent';

class HardwareEditTemplate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isAccessoriesPopupOpen: false,
			accessories: map(cloneDeep(get(this.props.equipmentDefault, 'accessories', [])), accessory => {
				let existingAccessory = find(this.props.equipment.accessories, { equipmentId: accessory.equipmentId });
				if (existingAccessory) {
					return existingAccessory;
				}
				accessory.equipmentOptions = {};
				if (toLower(accessory.subcategory) === 'paper') {
					each(accessory.equipmentOptions, option => {
						if (!!option.defaultValue && !option.dependentOnName) {
							accessory.equipmentOptions[option.name] = option.defaultValue;
						}
					});
				}
				let fees = cloneDeep(filter(get(accessory.purchasePlans[0], 'fees', []), { feeType: 'OneTimeFee' }));
				each(fees, fee => (fee.merchantPrice = fee.retailPrice));
				return { ...accessory, fees, quantity: 0, isSelected: true };
			}),
		};
		this.equipmentEditMethods = new EquipmentEditMethods(
			this.props.onChange,
			() => cloneDeep(this.props.equipment),
			() => cloneDeep(this.props.equipmentDefault)
		);
	}
	openAccessoriesPopup = () => this.setState({ isAccessoriesPopupOpen: true });

	closeAccessoriesPopup = () => this.setState({ isAccessoriesPopupOpen: false });

	handleAccessoriesEquipmentOptionChange = isQuantity => e => {
		const {
			target: { value, name },
		} = e;
		const accessories = [...this.state.accessories];
		const [index, key, optionKey] = name.split('_');

		if (isQuantity) {
			accessories[index][key] = parseInt(value || 0);
		} else {
			accessories[index].quantity = 0;
			accessories[index][key] = {
				...accessories[index][key],
				[optionKey]: value,
			};
		}
		this.setState({ accessories }, this.updateEquipmentAccessories);
	};

	adjustAccessoriesQuantity = equipmentId => ({ target: { name } }) => {
		const { accessories } = this.state;
		const newState = { accessories: [...accessories] };
		const accIndex = findIndex(accessories, { equipmentId });

		newState.accessories[accIndex] = {
			...accessories[accIndex],
			quantity: name === 'increase' ? accessories[accIndex].quantity + 1 : accessories[accIndex].quantity - 1,
		};

		if (newState.accessories[accIndex].quantity < 0) {
			return;
		}
		this.setState(newState, this.updateEquipmentAccessories);
	};

	updateEquipmentAccessories = () => {
		const { accessories } = this.state;
		this.equipmentEditMethods.updateAccessories(accessories.filter(accessory => accessory.quantity > 0));
	};

	toggleAccessory = equipmentId => {
		const { accessories } = this.state;
		const newState = { accessories: [...accessories] };
		const acc = find(newState.accessories, { equipmentId });
		acc.quantity = 0;
		this.setState(newState, this.updateEquipmentAccessories);
	};

	handleAccessoriesPriceChange = equipmentId => ({ floatValue }) => {
		const { accessories } = this.state;
		const newState = { accessories: [...accessories] };
		const accIndex = findIndex(accessories, { equipmentId });
		const feeIndex = findIndex(accessories[accIndex].fees);
		if (feeIndex === -1) {
			return;
		}
		newState.accessories[accIndex].fees[feeIndex] = {
			...newState.accessories[accIndex].fees[feeIndex],
			merchantPrice: floatValue,
		};
		this.setState(newState, this.updateEquipmentAccessories);
	};

	renderDependentOptions = (mainOptionName, equipmentDefault, equipment, index, errorListPaths) => {
		return (
			<Fragment>
				{Object.values(equipmentDefault.equipmentOptions)
					.filter(o => mainOptionName == o.dependentOnName && o.dataType !== 'file')
					.map(dependentOpt => {
						let disableOption =
							!equipment.equipmentOptions[mainOptionName] ||
							equipment.equipmentOptions[mainOptionName] != dependentOpt.dependentOnValue;
						if (disableOption) return null;
						return (
							<Fragment key={`${mainOptionName}_dependent_${index}_${dependentOpt.name}`}>
								<div>
									<EquipmentOption
										merchantEquipment={equipment}
										equipment={equipmentDefault}
										option={dependentOpt.name}
										index={index}
										errorListPaths={[]}
										listType="radio"
										checkboxHandler={this.equipmentEditMethods.optionSelect}
										moreInfoHandler={this.equipmentEditMethods.optionSetMoreInfo}
										inputHandler={this.equipmentEditMethods.handleChange}
										disableOption={disableOption}
									/>
								</div>
								{this.renderDependentOptions(dependentOpt.name, equipmentDefault, equipment, index, errorListPaths)}
							</Fragment>
						);
					})}
			</Fragment>
		);
	};

	renderAccessoriesPopup = () => {
		const {
			equipmentDefault,
			equipment: { paymentSchedule },
		} = this.props;
		const { isAccessoriesPopupOpen, accessories } = this.state;
		const defaultAccessories = get(equipmentDefault, 'accessories', []);
		const isBillAgentPaymentSchedule = toLower(paymentSchedule) === 'billagent';
		const tooltip = isBillAgentPaymentSchedule ? 'Price is not editable when Payment Schedule is Bill Agent.' : null;
		return (
			<Modal
				shouldCloseOnOverlayClick={false}
				isOpen={isAccessoriesPopupOpen}
				onClose={this.closeAccessoriesPopup}
				className="modal__content modal--lrg"
			>
				<Fragment>
					<div className="modal__header">
						<h4>{equipmentDefault.name} Add-ons</h4>
					</div>
					<div className="modal__body">
						<div className="equipment__hardware__list equipment__hardware__list--modal">
							{map(accessories, ({ name, fees, quantity, equipmentId, description, subcategory }) => {
								let equipmentOptions = find(defaultAccessories, { equipmentId }).equipmentOptions;
								quantity = quantity || 0;
								let fee = find(fees, { feeType: 'OneTimeFee' });
								let price = get(fee, 'merchantPrice');
								const isPaper = toLower(subcategory) === 'paper';

								return (
									<div className="equipment-card" key={`${equipmentId}.${name}`}>
										<div
											className={`equipment-card__thumbnail${getThumbnailOverlay(false, 'oneTimeFee')}`}
											style={{
												backgroundImage: `url(${process.env.REACT_APP_CDN_URL}${toLower(name)
													.replace(' ', '_')
													.replace(/[^a-z0-9_-]/gi, '')}/thumbnail.png)`,
											}}
											alt={name}
										></div>
										<div className="spc--bottom--lrg flex--grow--1">
											<h5>{name}</h5>
											{description && <p className="type--p3 spc--top--sml">{description}</p>}
										</div>
										<div className="flex--primary flex--column flex--gap--med">
											<div
												className="datatooltip--200 datatooltip--top-right fullwidth"
												data-tooltip={isBillAgentPaymentSchedule ? tooltip : null}
											>
												<NumberFormat
													value={isBillAgentPaymentSchedule ? fee.agentCost : price}
													decimalScale={2}
													thousandSeparator={true}
													fixedDecimalScale={true}
													disabled={isBillAgentPaymentSchedule}
													onValueChange={
														isBillAgentPaymentSchedule ? noop : this.handleAccessoriesPriceChange(equipmentId)
													}
													className="input input--sml input--currency type--right"
												/>
											</div>
											{isPaper && (
												<OptionComponent
													accessoryName="CaseOrRoll"
													equipmentId={equipmentId}
													equipmentOptions={equipmentOptions}
													handleChange={this.handleAccessoriesEquipmentOptionChange(false)}
													data={this.props.equipment}
													accessories={accessories}
												/>
											)}
											{this.renderQuantityModule(isPaper, equipmentId, equipmentOptions, quantity)}
										</div>
									</div>
								);
							})}
						</div>
					</div>
					<div className="modal__footer"></div>
				</Fragment>
			</Modal>
		);
	};

	renderQuantityModule = (isPaper, equipmentId, equipmentOptions, quantity) =>
		isPaper ? (
			<OptionComponent
				equipmentId={equipmentId}
				equipmentOptions={equipmentOptions}
				handleChange={this.handleAccessoriesEquipmentOptionChange(true)}
				data={this.props.equipment}
				accessories={this.state.accessories}
			/>
		) : (
			<QuantityStepper adjustQuantity={this.adjustAccessoriesQuantity(equipmentId)} quantity={quantity} />
		);

	render() {
		const { equipment, equipmentDefault } = this.props;
		const { subcategory, shippingOption } = equipment;

		const isPaper = toLower(subcategory) === 'paper';
		const isPickup = toLower(shippingOption) === 'pickup';

		return (
			<React.Fragment>
				<div>{this.renderAccessoriesPopup()}</div>
				<div className="equipment__hardware equipment-details">
					<div>
						<div className="spc--bottom--xxlrg">
							<div className="flex--primary flex--gap--tny spc--bottom--sml--alt">
								<p className="type--title type--uppercase">Purchase type</p>
								<span className="form__group__required" data-tooltip="Required">
									*
								</span>
							</div>
							{keys(equipmentDefault.purchaseTypes).map(pt => {
								if (toLower(pt) !== 'purchase') return null;
								return (
									<div key={pt}>
										<input
											className="input--radio"
											type="radio"
											name={equipmentDefault.name + '_' + pt + '_pt'}
											id={equipmentDefault.name + '_' + pt + '_pt'}
											onChange={this.equipmentEditMethods.selectPurchaseType}
											value={pt}
											checked={equipment.purchaseType == pt}
										/>
										<label htmlFor={equipment.name + '_' + pt + '_pt'}>{equipmentDefault.purchaseTypes[pt]}</label>
									</div>
								);
							})}
						</div>

						{equipment.frontendProcessors && Object.keys(equipment.frontendProcessors).length > 0 && (
							<div className="spc--bottom--xxlrg">
								<div className="flex--primary flex--gap--tny spc--bottom--sml--alt">
									<p className="type--title type--uppercase">Platform and Settlement</p>
								</div>
								<FrontendProcessorComponent
									equipment={equipmentDefault}
									merchantEquipment={equipment}
									handleChange={this.equipmentEditMethods.handleChange}
									handleTimeChange={this.equipmentEditMethods.handleTimeChange}
									optionSelect={this.equipmentEditMethods.optionSelect}
									optionSetMoreInfo={this.equipmentEditMethods.optionSetMoreInfo}
								/>
							</div>
						)}

						{Object.values(equipmentDefault.equipmentOptions).filter(o => !o.dependentOnName).length > 0 ? (
							<div className="spc--bottom--xxlrg">
								<p className="type--title type--uppercase spc--bottom--sml--alt">Features and Setup</p>
								<div className="row">
									{Object.values(equipmentDefault.equipmentOptions)
										.filter(o => !o.dependentOnName && o.dataType !== 'file')
										.map((opt, idx) => {
											if (opt.name == 'BatchSettlementType') return null; // handled separately
											return (
												<div className="col col-sml-12 col-xlrg-6 spc--bottom--sml" key={opt.name}>
													<EquipmentOption
														merchantEquipment={equipment}
														equipment={equipmentDefault}
														option={opt.name}
														index={idx}
														listType="radio"
														checkboxHandler={this.equipmentEditMethods.optionSelect}
														moreInfoHandler={this.equipmentEditMethods.optionSetMoreInfo}
														inputHandler={e => this.equipmentEditMethods.handleChange(e)}
													/>
													<div>{this.renderDependentOptions(opt.name, equipmentDefault, equipment, idx, [])}</div>
												</div>
											);
										})}
								</div>
							</div>
						) : null}

						<div className="spc--bottom--xxlrg">
							<div className="flex--primary flex--gap--tny spc--bottom--sml--alt">
								<p className="type--title type--uppercase">Shipping Details</p>
								<span className="type--title type--color--error datatooltip--auto">*</span>
							</div>
							<div>
								<div
									className="flex--primary flex--top flex--column flex--gap--sml spc--bottom--lrg"
									id={`${equipmentDefault.name}_shippingOption`}
								>
									{Object.keys(equipmentDefault.shippingOptions).map((opt, idx) => {
										if (toLower(opt) === 'other') return null;
										return (
											<div key={`${equipmentDefault.name}_shippingOptions_${idx}`}>
												<input
													className="input--radio"
													type="radio"
													value={opt}
													checked={equipment.shippingOption === opt}
													name={`${equipmentDefault.name}_shippingOption`}
													id={`${equipmentDefault.name}_selectedShippingOption_${opt}`}
													onChange={this.equipmentEditMethods.handleChange}
												/>
												<label htmlFor={`${equipmentDefault.name}_selectedShippingOption_${opt}`}>
													{equipmentDefault.shippingOptions[opt]}
												</label>
											</div>
										);
									})}
								</div>
								<div className="row">
									<div className="col col-sml-12 col-xxxlrg-6 form__group">
										<div className="form__group__header">
											<p className="form__group__label">Shipping Speed</p>
											<span className="form__group__required" data-tooltip="Required">
												*
											</span>
										</div>
										<select
											className="input input--med input--select"
											name="shippingSpeed"
											id={`${equipmentDefault.name}_shippingSpeed`}
											onChange={this.equipmentEditMethods.handleChange}
											value={equipment.shippingSpeed}
											disabled={isPickup}
										>
											<option value="">Please select...</option>
											{Object.keys(equipmentDefault.shippingSpeeds).map((opt, idx) => {
												return (
													<option key={`${equipmentDefault.name}_shippingSpeeds_${idx}`} value={opt}>
														{equipmentDefault.shippingSpeeds[opt]}
													</option>
												);
											})}
										</select>
										<div className="spc--top--sml">
											<input
												className="input--check"
												type="checkbox"
												id={`${equipmentDefault.name}_saturdayDelivery`}
												name="saturdayDelivery"
												checked={equipment.saturdayDelivery}
												onChange={this.equipmentEditMethods.handleChange}
												disabled={isPickup}
											/>
											<label htmlFor={`${equipmentDefault.name}_saturdayDelivery`}>Saturday Delivery</label>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="spc--bottom--xxlrg">
							<div className="form__group__header">
								<p className="form__group__label">Payment Schedule</p>
								<span className="form__group__required" data-tooltip="Required">
									*
								</span>
							</div>
							<select
								className="input input--med input--select"
								name="paymentSchedule"
								id={`${equipmentDefault.name}_paymentSchedule`}
								value={equipment.paymentSchedule}
								onChange={this.equipmentEditMethods.handleChange}
							>
								<option value="">Please select...</option>
								{Object.keys(equipmentDefault.paymentSchedules).map(opt => {
									return (
										<option key={opt} value={opt}>
											{equipmentDefault.paymentSchedules[opt]}
										</option>
									);
								})}
							</select>
						</div>

						<div className="spc--bottom--med">
							<div className="form__group__header">
								<p className="form__group__label">Notes</p>
							</div>
							<textarea
								className="input input--textarea"
								type="text"
								placeholder="Write note here..."
								name="notes"
								value={equipment.notes}
								onChange={this.equipmentEditMethods.handleChange}
							/>
						</div>
					</div>
					{equipmentDefault.purchasePlans
						.filter(plan => plan.planId == equipment.purchasePlanId)
						.map(plan => {
							return (
								<div key={plan.planid}>
									<HardwarePurchasePlanComponent
										plan={plan}
										merchantFees={equipment.fees}
										purchaseType={equipment.purchaseType}
										quantity={equipment.quantity}
										options={equipment.equipmentOptions}
										onFeeChange={(planId, feeId) => event =>
											this.equipmentEditMethods.handleFeeChange(planId, feeId, event)}
										accessories={equipment.accessories}
										toggleAccessory={this.toggleAccessory}
										displayAddonsButton={!isEmpty(equipmentDefault.accessories)}
										openAccessoriesPopup={this.openAccessoriesPopup}
										purchaseTypes={equipmentDefault.purchaseTypes}
										paymentSchedule={equipment.paymentSchedule}
										quantityComponentProps={{
											isPaper,
											data: equipment,
											options: equipmentDefault?.equipmentOptions,
											adjustQuantity: this.equipmentEditMethods.adjustQuantity,
											handleOptionChange: this.handleAccessoriesEquipmentOptionChange(true),
											accessories: this.state.accessories,
										}}
									/>
								</div>
							);
						})}
				</div>
			</React.Fragment>
		);
	}
}

HardwareEditTemplate.propTypes = {
	equipmentDefault: PropTypes.object.isRequired,
	equipment: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
};

export default HardwareEditTemplate;
