import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { modalNames } from './modal-names';
import { Modal } from '../modal';
import { default as ConfirmAction } from './confirmAction';
import { NewNotePopup } from '../../../components/MerchantNotes';
import { NewAccount } from '../../../components/NewAccount';
import { ReviewPricing } from '../../../components/ReviewPricing';
import { SendEmailPopup, ShareMPAForm } from '../../../components/EApp';
import { GatewayAddons } from '../../../components/Equipment';
import { FileUploadPopup } from '../../../components/FileUploadPopup';
import { ReviewSummary } from '../../../components/ReviewPricingSummary';
import { NotePopup } from '../../../components/EApp';
import { NewTicket, TicketDetails } from '../../../components/TicketGrid/popup/';
import EquipmentDetailsPopup from '../../../components/EquipmentGrid/popup/EquipmentDetailsPopup';
import NewFilePopup from '../../../components/MerchantFiles/NewFilePopup';
import GoPlusTierPopup from '../../../components/EApp/popup/GoPlusTierPopup';
import BreakdownGrid from '../../../components/Residuals/BreakdownGrid';
import SendInvoiceEmailPopup from '../../../components/InvoiceGrid/popup/SendInvoiceEmailPopup';
import CloneAppPopup from '../../../components/CloneAppPopup/CloneAppPopup';

const modalComponents = {
	[modalNames.newNote]: {
		component: NewNotePopup,
		props: {
			shouldCloseOnOverlayClick: false,
			className: 'modal__content',
		},
	},
	[modalNames.confirmAction]: {
		component: ConfirmAction,
		props: {
			className: 'modal__content',
		},
	},
	[modalNames.newLead]: {
		component: NewAccount,
		props: {
			className: 'modal__content modal--new-lead',
		},
	},
	[modalNames.reviewPricing]: {
		component: ReviewPricing,
		props: {
			className: 'modal__content modal__content--gradient-bg',
		},
	},
	[modalNames.pricingResults]: {
		component: ReviewSummary,
		props: {
			className: 'modal__content modal--lrg',
		},
	},
	[modalNames.shareMPA]: {
		component: ShareMPAForm,
		props: {
			className: 'modal__content',
			hideCloseButton: true,
		},
	},
	[modalNames.fileUpload]: {
		component: FileUploadPopup,
		props: {},
	},
	[modalNames.gatewayNote]: {
		component: NotePopup,
		props: {
			shouldCloseOnOverlayClick: true,
			className: 'modal__content',
		},
	},

	[modalNames.sendEmail]: {
		component: SendEmailPopup,
		props: {
			shouldCloseOnOverlayClick: false,
			className: 'modal__content',
		},
	},

	[modalNames.gatewayAddons]: {
		component: GatewayAddons,
		props: {
			shouldCloseOnOverlayClick: false,
			className: 'modal__content modal--lrg',
			hideCloseButton: true,
		},
	},

	[modalNames.newTicket]: {
		component: NewTicket,
		props: {
			shouldCloseOnOverlayClick: false,
			className: 'modal__content',
		},
	},

	[modalNames.ticketDetails]: {
		component: TicketDetails,
		props: {
			overlayClassName: 'modal__overlay modal__overlay--flex',
			className: 'modal__content modal--lrg',
			hideCloseButton: true,
		},
	},

	[modalNames.equipmentDetails]: {
		component: EquipmentDetailsPopup,
		props: {
			className: 'modal__content',
		},
	},

	[modalNames.newFile]: {
		component: NewFilePopup,
		props: {
			className: 'modal__content',
		},
	},

	[modalNames.goPlusTier]: {
		component: GoPlusTierPopup,
		props: {
			className: 'modal__content',
			shouldCloseOnOverlayClick: false,
			hideCloseButton: true,
		},
	},

	[modalNames.groupedTotals]: {
		component: BreakdownGrid,
		props: {
			className: 'modal__content modal__content--breakdown',
		},
	},

	[modalNames.invoiceEmail]: {
		component: SendInvoiceEmailPopup,
		props: {
			className: 'modal__content',
		},
	},

	[modalNames.cloneEApp]: {
		component: CloneAppPopup,
		props: {
			className: 'modal__content',
		},
	},
};

class ModalWrapperComponent extends Component {
	onModalClose = () => {
		this.props.onModalClose({
			name: modalNames.none,
			data: null,
		});
	};

	renderSpecificModal = () => {
		const { name, data } = this.props.modal;

		const modal = modalComponents[name];
		if (!modal) {
			return null;
		}

		return (
			<Modal isOpen={true} onClose={this.onModalClose} {...modal.props}>
				<modal.component closeModal={this.onModalClose} {...data} />
			</Modal>
		);
	};

	render = () => {
		return this.renderSpecificModal();
	};
}

ModalWrapperComponent.propTypes = {
	modal: PropTypes.shape({
		name: PropTypes.string,
		data: PropTypes.object,
	}),
	onModalClose: PropTypes.func.isRequired,
};

export default ModalWrapperComponent;
