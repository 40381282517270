import React from 'react';
class MerchantCompleteComponent extends React.Component {
	renderGoAppSuccess = () => {
		const isPendingAdditionalValidation = window.location.search.includes('pendingAdditionalValidation=true');
		return (
			<div className="type--p2 type--p2--medium type--color--success">
				{isPendingAdditionalValidation
					? 'Thank you for submitting your application. Your account is pending additional review. We will be in touch with you shortly.'
					: 'We successfully received your application. Thank you!'}
			</div>
		);
	};
	renderAppSuccess = () => {
		const noSign = window.location.search.includes('noSign=true');
		return (
			<div className="type--p2 type--p2--medium type--color--success">
				Thank you - your{' '}
				{noSign
					? 'application is being processed. We will be reaching out to you shortly for a final signature.'
					: 'signed documents have been submitted successfully.'}
			</div>
		);
	};
	render() {
		const goApp = window.location.search.includes('goApp=true');
		return (
			<div id="main-div" className="l--content">
				{goApp ? this.renderGoAppSuccess() : this.renderAppSuccess()}
			</div>
		);
	}
}

export default MerchantCompleteComponent;
