import React, { Component, Fragment } from 'react';
import {
	each,
	isEmpty,
	map,
	find,
	toLower,
	sumBy,
	split,
	filter,
	some,
	flatMap,
	get,
	noop,
	first,
	includes,
} from 'lodash';
import { object, func, array, string, bool } from 'prop-types';

import { Modal } from '../../common/components/modal';
import { defaultImplicitParse, defaultReactOutput } from 'simple-markdown';
import { Toggle, ToggleContainer } from './../../common/components/toggle';
import { modalNames } from '../../common/components/modal-wrapper/modal-names';
import { ModalWrapper } from '../../common/components/modal-wrapper';
import { withRouter } from 'react-router';
import { renderEquipmentOption } from '../../common/components/equipmentOption/renderEquipmentOption';
import { OutsideClick } from '../../common/utilities';
import { _eappFunctions } from '../../services/_eappFunctions';

const isExpand = false;
const incompleteCross = elem => <React.Fragment>{elem}</React.Fragment>;

class MerchantEquipmentFooter extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isItemsModalOpen: false,
			modal: {
				name: modalNames.none,
				data: null,
			},
			deleteItemModal: {
				isOpen: false,
				item: null,
			},
			errorMessage: '',
		};
	}

	removeEquipment = (equipmentId, parentEquipmentId) => {
		const { merchantEquipment } = this.props;
		const item = parentEquipmentId
			? find(merchantEquipment, { equipmentId, parentEquipmentId })
			: find(merchantEquipment, { equipmentId });
		const accessories = get(item, 'accessories', []);

		if (!isEmpty(accessories)) {
			each(accessories, (_, index) => {
				accessories[index] = {
					...accessories[index],
					quantity: 0,
					isSelected: false,
				};
			});
		}

		this.props.handleEquipmentChange({
			...item,
			isSelected: false,
			quantity: item.parentEquipmentId ? 0 : 1,
			accessories,
		});
	};

	findEquipmentTab = (equipmentId, category) => {
		const { hardwareList, gatewayList, softwareList } = this.props;

		if (toLower(category).indexOf('accessories') > -1) {
			return 'accessories';
		} else if (toLower(category).indexOf('pos') > -1) {
			return 'posSystems';
		} else if (find(gatewayList, g => g.equipmentId == equipmentId)) {
			return 'gateways';
		} else if (find(softwareList, s => s.equipmentId == equipmentId)) {
			return 'software';
		} else if (find(hardwareList, h => h.equipmentId == equipmentId)) {
			return 'hardware';
		} else {
			return 'other';
		}
	};

	openAddonsModal = parentEquipmentId => {
		const { history, appId, isPopup } = this.props;

		if (isPopup) {
			this.props.updateState({ modal: { name: modalNames.none, data: null } }, () => {
				history.replace({ disableRefresh: true, state: { openAddonsModal: true, parentEquipmentId } });
			});
		} else {
			history.push({
				pathname: `/merchants/${appId}/equipment`,
				state: { openAddonsModal: true, parentEquipmentId },
			});
		}
	};

	displayItem = (equipmentId, category, parentEquipmentId) => () => {
		const { updateState, isPopup } = this.props;

		this.closeItemsModal();

		if (parentEquipmentId) {
			this.openAddonsModal(parentEquipmentId);
		} else {
			if (isPopup) {
				const { history, appId } = this.props;

				history.push({
					pathname: `/merchants/${appId}/equipment-new`,
					state: {
						openSelectedEquipment: true,
						currentTab: this.findEquipmentTab(equipmentId, category),
						selectedEquipmentId: equipmentId,
					},
				});
			} else {
				updateState({ currentTab: this.findEquipmentTab(equipmentId, category), selectedEquipmentId: equipmentId });
			}
		}
	};

	adjustQuantity = ({ target: { name } }) => {
		const [equipmentId, action] = split(name, '.');
		const { merchantEquipment } = this.props;
		const item = find(merchantEquipment, { equipmentId });
		let diff = item.quantity > 0 ? -1 : 0;

		if (action === 'increase') {
			diff = 1;
		}

		const changedItem = { ...item, quantity: item.quantity + diff };

		if (changedItem.quantity < 1) {
			this.openDeleteItemModal(changedItem);
		} else {
			this.props.handleEquipmentChange(changedItem);
		}
	};

	removeItem = () => {
		const { merchantEquipment } = this.props;
		const {
			deleteItemModal: { item },
		} = this.state;
		const newItem = { ...item };

		newItem.isSelected = false;

		this.props.handleEquipmentChange(newItem);

		const newState = { deleteItemModal: { isOpen: false, item: null } };

		if (!some(merchantEquipment, e => e.isSelected && e.equipmentId != newItem.equipmentId)) {
			newState.isItemsModalOpen = false;
		}

		this.setState(newState);
	};

	openDeleteItemModal = item => {
		this.setState({ deleteItemModal: { isOpen: true, item } });
	};

	closeDeleteItemModal = () => this.setState({ deleteItemModal: { isOpen: false, item: null } });

	openCloseModal = modal => this.setState({ modal });

	openItemsModal = () => {
		if (some(this.props.merchantEquipment, ({ isSelected, quantity }) => isSelected && quantity)) {
			this.setState({ isItemsModalOpen: true });
		}
	};

	closeItemsModal = () => this.setState({ isItemsModalOpen: false });

	openCloseAddonsModal = (masterId, parentEquipmentId, parentEquipmentName, parentPlanName, existingAddons) => () => {
		this.openCloseModal({
			name: modalNames.gatewayAddons,
			data: {
				masterId,
				parentEquipmentId,
				parentEquipmentName,
				parentPlanName,
				gatewayList: this.props.equipmentList,
				merchantEquipment: this.props.merchantEquipment,
				handleChange: this.props.handleEquipmentChange,
				existingAddons,
			},
		});
	};

	toggleSelect = ({ target: { value, name, checked, type } }, saveEquipmentToState = noop) => {
		const { merchantEquipment } = this.props;
		const [equipmentId, action] = split(name, '.');
		const val = type === 'checkbox' ? checked : value;
		let item = find(merchantEquipment, { equipmentId });

		if (!item) {
			item = this.props.createNewEquipment(equipmentId, true, true);
		}

		item[action] = val;

		if (action === 'isSelected' && !item.isSelected) {
			item.quantity = 0;
		} else {
			item.quantity = 1;
		}

		saveEquipmentToState(equipmentId, item);
		this.props.handleEquipmentChange(item);
	};

	getFeesInTotalPrice = equipmentItem => {
		let fees = equipmentItem.fees.map(fee => {
			if (equipmentItem.category != 'Gateway' && Object.keys(fee.dependencySettings || {}).length > 0) {
				if (!!equipmentItem.equipmentOptions) {
					let dependentOption = Object.keys(equipmentItem.equipmentOptions).find(o =>
						Object.keys(fee.dependencySettings).includes(o)
					);
					if (
						dependentOption &&
						fee.dependencySettings[dependentOption].includes(equipmentItem.equipmentOptions[dependentOption])
					) {
						return { ...fee, includeInTotal: true };
					}
				}
			} else if (
				toLower(equipmentItem.purchaseType) === 'rental' &&
				includes(['weeklyfee', 'monthlyfee'], toLower(fee.feeType))
			) {
				return fee;
			} else if (fee.feeType === 'OneTimeFee') return fee;
		});
		return fees.filter(f => !!f);
	};

	calculateEquipmentFee(fees, quantity, purchaseType, paymentSchedule) {
		return sumBy(
			fees,
			({ merchantPrice, agentCost, feeType, includeInTotal }) =>
				((toLower(purchaseType) === 'rental' && includes(['weeklyfee', 'monthlyfee'], toLower(feeType))) ||
					toLower(feeType) === 'onetimefee' ||
					includeInTotal) &&
				(paymentSchedule === 'BillAgent' ? agentCost : merchantPrice) * quantity
		);
	}

	calculateAccessoryFee(accessories, paymentSchedule) {
		return sumBy(accessories, ({ quantity, fees }) => {
			if (fees) {
				const fee = find(fees, {
					feeType: 'OneTimeFee',
				});
				return (paymentSchedule === 'BillAgent' ? fee.agentCost : fee.merchantPrice || fee.retailPrice) * quantity;
			}
			return 0;
		});
	}

	calculateItemTotalPrice = (equipmentId, returnFormatted = false) => {
		const item = find(this.props.merchantEquipment, { equipmentId });
		const { quantity, paymentSchedule, purchaseType } = item;
		const fees = [...this.getFeesInTotalPrice(item), ...flatMap(item.subequipment, 'fees')];

		let equipmentTotal = this.calculateEquipmentFee(fees, quantity, purchaseType, paymentSchedule);
		const accessoriesTotal = this.calculateAccessoryFee(item.accessories, paymentSchedule) || 0;

		if (returnFormatted) {
			return `$${(equipmentTotal + accessoriesTotal || 0).toFixed(2)}`;
		}

		return equipmentTotal + accessoriesTotal;
	};

	calculateTotalPrice = () => {
		let total = 0;

		each(this.props.merchantEquipment, item => {
			let { isSelected, equipmentId } = item;
			if (isSelected) {
				total += this.calculateItemTotalPrice(equipmentId);
			}
		});

		return `$${(total || 0).toFixed(2)}`;
	};

	checkIfEquipmentLocallyComplete = () => {
		const { isValid, existingMerchantEquipment } = this.props;

		return existingMerchantEquipment && existingMerchantEquipment.length && isValid;
	};

	handleSave = (openIncompletePopup = false) => e => {
		const { appStatus, displayMissingFieldsNotification } = this.props;
		const isSetupFormComplete = appStatus.isSetupFormComplete;
		const incompleteDocs = filter(get(appStatus, 'requiredDocuments'), doc => !doc.isComplete);
		const notificationMessage =
			openIncompletePopup && (!isEmpty(incompleteDocs) || !isSetupFormComplete) && displayMissingFieldsNotification ? (
				<div>
					<h3 className="modal__header__title spc--bottom--sml">Your information was saved successfully.</h3>
					<div>
						{(!isEmpty(incompleteDocs) || !isSetupFormComplete) && (
							<Fragment>
								<p className="notes notes--default spc--bottom--sml">
									Please note that you're still missing the following to complete this application:
								</p>
								{!isEmpty(incompleteDocs) && (
									<h3 className="popup--notification--error">
										<span className="type--wgt--bold">MPA</span> is incomplete.
									</h3>
								)}
								{!isSetupFormComplete &&
									incompleteCross(
										<h3 className="popup--notification--error">
											<span className="type--wgt--bold">Setup Form</span> is incomplete.
										</h3>
									)}
							</Fragment>
						)}
					</div>
				</div>
			) : null;

		this.props.handleSave(e, false, notificationMessage);
	};

	renderSavedEquipmentSidebar = () => {
		const { existingMerchantEquipment, gatewayList, isEApp } = this.props;
		const { errorMessage } = this.state;
		return (
			<ToggleContainer>
				<Toggle initialToggle={isEApp && !isEmpty(existingMerchantEquipment)}>
					{({ isToggled, handleToggle }) => (
						<OutsideClick action={() => handleToggle(false)} className="equipment__footer__saved__wrapper">
							<button
								className="equipment__footer__saved__select datatooltip--top-right"
								data-tooltip="Previously Saved Equipment"
								onClick={() => {
									this.setState({ errorMessage: '' });
									handleToggle();
								}}
							>
								<span>
									Saved <span className="hide--to--sml">Equipment</span>
								</span>
								<span className="type--color--text--light type--wgt--medium">
									{existingMerchantEquipment && `(${existingMerchantEquipment.length})`}
								</span>
								<i className={`icon icon--sml icon--chevron--${isToggled ? 'top' : 'down'} align--h--right`}></i>
							</button>
							<div className={`equipment__saved${isToggled ? ' is-expanded' : ''}`}>
								<div className="equipment__saved__header">
									<h5>Saved Equipment</h5>
									{errorMessage && <p className="type--validation spc--top--sml--alt">{errorMessage}</p>}
								</div>
								<table className="table--saved-equipment">
									<thead>
										<tr>
											<th className="type--left">Name</th>
											<th className="type--right">Qty.</th>
											{isEApp && <th className="w--42"></th>}
										</tr>
									</thead>
									<tbody>
										{map(
											filter(existingMerchantEquipment, ({ parentEquipmentId }) => !parentEquipmentId),
											({ id, equipmentId, quantity, categoryDescription, isActiveEquipment, name, planName }) => {
												const subequipment = get(
													find(gatewayList, {
														equipmentId: String(equipmentId),
													}),
													'subequipment'
												);
												const equipmentAddons = filter(
													existingMerchantEquipment,
													({ parentEquipmentId }) => parentEquipmentId == id
												);

												return (
													<tr key={id}>
														<td>
															<div className="flex--primary flex--gap--sml--alt">
																{categoryDescription !== 'Gateway' && (
																	<div
																		className="table--saved-equipment__thumbnail hide--to--sml"
																		style={{
																			backgroundImage:
																				'url(' +
																				process.env.REACT_APP_CDN_URL +
																				toLower(name)
																					.replace(' ', '_')
																					.replace(/[^a-z0-9_-]/gi, '') +
																				'/thumbnail.png' +
																				')',
																		}}
																		alt={name}
																	></div>
																)}
																{categoryDescription ? `${categoryDescription} - ` : ''}
																<p>
																	{name} {isActiveEquipment || '(disabled)'}
																</p>
																{!isEmpty(equipmentAddons) && (
																	<ul className="hardware__saved__option">
																		{map(equipmentAddons, addon => (
																			<li
																				key={`${addon.id}.${addon.equipmentId}.${addon.name}`}
																				className="hardware__saved__option__item"
																			>
																				<span className="type--sml type--wgt--sml spc--right--sml">{addon.name}</span>
																				<span className="type--sml type--wgt--sml">{addon.quantity}</span>
																			</li>
																		))}
																	</ul>
																)}
															</div>
														</td>

														<td className="type--right">
															<div className="flex--primary flex--gap--sml flex--nowrap flex--right">
																{isActiveEquipment && !isEmpty(subequipment) && (
																	<button
																		type="button"
																		className="btn btn--addon"
																		onClick={this.openCloseAddonsModal(
																			id,
																			equipmentId,
																			name,
																			planName,
																			equipmentAddons
																		)}
																	>
																		<i className="icon icon--tny icon--add--white"></i>
																		<span>Add-on</span>
																	</button>
																)}
																{categoryDescription === 'Gateway' ? (
																	<React.Fragment> - </React.Fragment>
																) : (
																	<React.Fragment>({quantity})</React.Fragment>
																)}
															</div>
														</td>
														{isEApp && (
															<td className="type--right">
																<button className="btn btn--link" onClick={this.cancelEquipment(id)}>
																	<i className="icon icon--sml icon--delete"></i>
																</button>
															</td>
														)}
													</tr>
												);
											}
										)}
									</tbody>
								</table>
							</div>
						</OutsideClick>
					)}
				</Toggle>
			</ToggleContainer>
		);
	};

	cancelEquipment = equipmentId => () => {
		this.props.showMainLoader(true);
		_eappFunctions
			.cancelEquipment(this.props.appId, equipmentId)
			.then(() => {
				this.props.reloadExistingEquipment();
				this.props.showMainLoader(false);
			})
			.catch(err => {
				console.log(err);
				this.setState({ errorMessage: 'Error deleting equipment!' });
				this.props.showMainLoader(false);
			});
	};

	handleChange = (equipmentId, equipmentOptions) => ({ target: { name, value } }) => {
		const { merchantEquipment } = this.props;
		const item = find(merchantEquipment, e => e.equipmentId == equipmentId);

		if (name === 'quantity') {
			item.quantity = parseInt(value);
		} else {
			const [section, key] = split(name, '_');
			item[section] = {
				...item[section],
				[key]: value,
			};

			const option = find(equipmentOptions, o => toLower(o.name).indexOf(`${toLower(value)} quantity`) > -1);
			item.quantity = parseInt(get(first(option.options), 'value'));
		}

		this.props.handleEquipmentChange(item);
	};

	renderSelectLabel = (isSelected, equipmentId) => (
		<label htmlFor={`${equipmentId}.isSelected`}>{`Select${isSelected ? 'ed' : ''}`}</label>
	);

	renderAdjustQuantityModule = ({ allowMultiple, equipmentId, isSelected, quantity, isPaper }) => {
		const { merchantEquipment, findEquipment } = this.props;
		const merchantAccessories = find(merchantEquipment, e => e.equipmentId == equipmentId);
		const equipmentOptions = get(findEquipment(equipmentId, false, true), 'equipmentOptions');

		return allowMultiple === false ? (
			<div className="gateway__list__radio-button__wrapper">
				<input
					className="gateway__list__radio-button"
					type="checkbox"
					name={`${equipmentId}.isSelected`}
					id={`${equipmentId}.isSelected`}
					value={equipmentId}
					checked={isSelected}
					onChange={this.toggleSelect}
				/>
				{this.renderSelectLabel(isSelected, equipmentId)}
			</div>
		) : (
			<div className="input--stepper">
				{isPaper ? (
					<div className="inputgroup--main">
						<div className="flex--primary">
							<div className="flex--grow--1">
								{renderEquipmentOption(
									'CaseOrRoll',
									equipmentOptions,
									merchantAccessories,
									this.handleChange(equipmentId, equipmentOptions)
								)}
							</div>
							<div className="flex--grow--1">
								{renderEquipmentOption(
									'Quantity',
									equipmentOptions,
									merchantAccessories,
									this.handleChange(equipmentId, equipmentOptions)
								)}
							</div>
						</div>
					</div>
				) : (
					<Fragment>
						<button
							type="button"
							className="input--stepper__button"
							name={`${equipmentId}.decrease`}
							onClick={this.adjustQuantity}
						>
							<i className="icon icon--sml icon--minus"></i>
						</button>
						<input className="input--stepper__input" disabled type="number" min="1" name="quantity" value={quantity} />
						<button
							type="button"
							className="input--stepper__button"
							name={`${equipmentId}.increase`}
							onClick={this.adjustQuantity}
						>
							<i className="icon icon--sml icon--add"></i>
						</button>
					</Fragment>
				)}
			</div>
		);
	};

	renderMerchantEquipment = () => {
		const { merchantEquipment, findEquipment, existingMerchantEquipment } = this.props;

		return map(
			merchantEquipment,
			({
				equipmentId,
				quantity,
				isSelected,
				purchasePlanId,
				errors,
				fees,
				subequipment,
				parentEquipmentId,
				allowMultiple,
				equipmentOptions,
			}) => {
				const isSubequipment = !!parentEquipmentId;
				let item;
				if (isSubequipment) {
					const parentEquipment = find(existingMerchantEquipment, { id: parentEquipmentId });
					if (!parentEquipment) return;
					item = findEquipment(equipmentId, true, false, parentEquipment.equipmentId);
				} else {
					item = findEquipment(equipmentId);
				}

				if (!isSelected || !item) {
					return;
				}
				const { name, category, purchasePlans, subcategory } = item;

				let plan;

				if (category === 'Gateway') {
					plan = find(purchasePlans, { planId: parseInt(purchasePlanId, 10) });
				}

				let errorListPaths = errors && errors.length > 0 && errors.map(e => e.path);
				const isPaper = toLower(subcategory) === 'paper';

				return (
					<Fragment key={equipmentId}>
						<tr className={errorListPaths && 'has-subrow'}>
							<td>
								<div className="flex--primary flex--nowrap flex--gap--sml--alt">
									{category !== 'Gateway' && (
										<div
											className="table--chart__thumbnail spc--top--med spc--bottom--med"
											style={{
												backgroundImage:
													'url(' +
													process.env.REACT_APP_CDN_URL +
													toLower(name)
														.replace(' ', '_')
														.replace(/[^a-z0-9_-]/gi, '') +
													'/thumbnail.png' +
													')',
											}}
											alt={name}
										></div>
									)}
									<button
										className="btn btn--link type--color--text"
										onClick={this.displayItem(equipmentId, category, parentEquipmentId)}
									>
										{name}
										{plan && ` ${plan.planName}`}
									</button>
								</div>
							</td>
							<td>
								{category === 'Gateway' || category === 'PosSystems' ? (
									<div className="spc--right--xxxlrg">-</div>
								) : (
									this.renderAdjustQuantityModule({
										allowMultiple,
										equipmentId,
										isSelected,
										quantity,
										isPaper,
										equipmentOptions,
									})
								)}
							</td>
							<td className="type--right">
								<span className="type--p2 type--p2--medium type--color--primary">
									{this.state.isItemsModalOpen && this.calculateItemTotalPrice(equipmentId, true)}
								</span>
							</td>
							<td className="center">
								<button className="btn btn--link" onClick={() => this.removeEquipment(equipmentId, parentEquipmentId)}>
									<i className="icon icon--sml icon--delete"></i>
								</button>
							</td>
						</tr>
						{errorListPaths && (
							<tr>
								<td colSpan={4}>
									<ul className="type--validation__wrapper spc--bottom--med">
										{' '}
										{errors.map(elem => {
											let errorPrefix = '';
											let pathArr = elem.path.split('.');
											if (pathArr[0] == 'fees') {
												if (fees.length > 0 && fees[pathArr[1]]) errorPrefix = fees[pathArr[1]].feeName;
											} else if (pathArr[0] == 'subequipment') {
												let equipId = subequipment[pathArr[1]].equipmentId;
												errorPrefix = item.subequipment.find(s => s.equipmentId == equipId).name;
											}
											errorPrefix = errorPrefix && '**' + errorPrefix + '**: ';
											return (
												<li key={elem.path} className="type--validation">
													{defaultReactOutput(defaultImplicitParse(errorPrefix + elem.message))}
												</li>
											);
										})}
									</ul>
								</td>
							</tr>
						)}
					</Fragment>
				);
			}
		);
	};

	renderItemsModal = () => {
		const { isItemsModalOpen } = this.state;

		return (
			<Modal isOpen={isItemsModalOpen} onClose={this.closeItemsModal} className="modal__content modal--lrg">
				<div>
					<div className="modal__header">
						<h4>Cart</h4>
					</div>
					<div className="modal__body">
						<table className="table table--primary">
							<colgroup>
								<col />
								<col width={150} />
								<col width={100} />
								<col width={60} />
							</colgroup>
							<thead>
								<tr>
									<th>Name</th>
									<th>Quantity</th>
									<th className="right">Total Price</th>
									<th></th>
								</tr>
							</thead>
							<tbody>{this.renderMerchantEquipment()}</tbody>
						</table>
					</div>
					<div className="modal__footer"></div>
				</div>
			</Modal>
		);
	};

	renderDeleteItemModal = () => {
		const {
			deleteItemModal: { isOpen, item },
		} = this.state;

		return (
			<Modal isOpen={isOpen} onClose={this.closeDeleteItemModal} className="modal__content">
				<div>
					<div className="modal__body">
						<h6>Are you sure you want to remove {get(item, 'name', 'this item')}?</h6>
					</div>
					<div className="modal__footer">
						<button className="btn btn--ghost btn--med spc--left--tny" onClick={this.closeDeleteItemModal}>
							No
						</button>
						<button className="btn btn--primary btn--med spc--left--tny" onClick={this.removeItem}>
							Yes
						</button>
					</div>
				</div>
			</Modal>
		);
	};

	render() {
		const {
			merchantEquipment,
			isValid,
			appStatus: { isReadyToSubmit, isMpaComplete, isSetupFormComplete, isEquipmentComplete },
			dirty,
			isEApp,
			isAch,
			isGoPlus,
			isLoading,
			isPopup,
		} = this.props;
		const items = filter(merchantEquipment, ({ isSelected }) => isSelected);
		const buttonsDisabled = (!this.checkIfEquipmentLocallyComplete() && !dirty) || isLoading || !isValid;
		const saveDisabled =
			buttonsDisabled ||
			!isMpaComplete ||
			!isSetupFormComplete ||
			(!isReadyToSubmit
				? isMpaComplete && isSetupFormComplete && !isEquipmentComplete && this.checkIfEquipmentLocallyComplete()
				: !isReadyToSubmit);
		const saveButtonTooltip = isLoading ? 'Saving in progress...' : null;

		return (
			<ToggleContainer>
				<Toggle initialToggle={isExpand}>
					{({ isToggled, handleToggle }) => (
						<div
							className={`equipment__footer${isPopup ? ' is-modal' : ''}${isToggled ? ' is-toggled' : ''}${
								isGoPlus ? ' fullwidth' : ''
							}`}
						>
							<ModalWrapper modal={this.state.modal} onModalClose={this.openCloseModal} />
							{/* <div onClick={handleToggle}></div> */}

							{this.renderSavedEquipmentSidebar()}
							{this.renderItemsModal()}
							{this.renderDeleteItemModal()}

							<div className="equipment__footer__summary">
								{!isValid && (
									<div data-tooltip="Information is missing for one or more items in your cart">
										<i className="icon icon--med icon--regular--warning"></i>
									</div>
								)}
								<button className="btn btn--link" onClick={this.openItemsModal}>
									<p className="type--p3 spc--right--tny">Cart</p>
									<span className="type--p3 type--p3--medium">
										({sumBy(items, item => get(item, 'quantity', 1)) || 0})
									</span>
								</button>
								<span className="type--color--text--opaque">|</span>
								<div className="flex--primary flex--gap--tny type--color--text--secondary">
									<span className="type--p3">Total Price</span>
									<span className="type--p3 type--p3--medium">{this.calculateTotalPrice()}</span>
								</div>
							</div>
							<div className="equipment__footer__actions">
								<button
									data-tooltip={saveButtonTooltip}
									className={`btn btn--med btn--${isEApp ? 'tertiary' : 'primary'}`}
									onClick={this.handleSave(!isAch && !isGoPlus && (!buttonsDisabled || dirty))}
									disabled={buttonsDisabled || !dirty}
								>
									{isEApp ? 'Save' : 'Submit'}
								</button>
								{isEApp && !isGoPlus && !isAch &&(
									<button
										data-tooltip={saveButtonTooltip}
										className="btn btn--med btn--primary"
										onClick={e => this.props.handleSave(e, true)}
										disabled={saveDisabled}
									>
										Save and Next Step
									</button>
								)}
							</div>
						</div>
					)}
				</Toggle>
			</ToggleContainer>
		);
	}
}

MerchantEquipmentFooter.propTypes = {
	appId: string.isRequired,
	merchantEquipment: array.isRequired,
	existingMerchantEquipment: array.isRequired,
	gatewayList: array.isRequired,
	isValid: bool.isRequired,
	appStatus: object.isRequired,
	isEApp: bool.isRequired,
	isGoPlus: bool,
	isAch: bool,
	dirty: bool.isRequired,
	isLoading: bool.isRequired,
	setErrorState: func.isRequired,
	handleEquipmentChange: func.isRequired,
	createNewEquipment: func.isRequired,
	updateState: func.isRequired,
	handleSave: func.isRequired,
	isPopup: bool,
	displayMissingFieldsNotification: bool,
	reloadExistingEquipment: func,
	showMainLoader: func,
};

export default withRouter(MerchantEquipmentFooter);
