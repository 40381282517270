import React from 'react';
import { cloneDeep, each, isEmpty } from 'lodash';
import { FileDropzoneComponent } from '../../common/components/file-dropzone';
import { defaultImplicitParse, defaultReactOutput } from 'simple-markdown';

import { YesNoRadio } from '../../common/components/yes-no-radio';
import Schema from '../../validation/BaseSchema';
import { reviewPricingTemplate, defaultReviewPricingTemplate, EnumReviewPricingRushCredits } from '../../validation';

import { appService } from '../../services/appService';
import { withLoader } from './../../common/components';
import { handleRemoveFile } from '../../common/utilities/commonFileHandlingMethods';

class ReviewPricing extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			merchantInfo: Object.assign({}, defaultReviewPricingTemplate, props.merchantInfo),
			highlightMissingFields: false,
			files: {},
		};
		this.merchantInfoSchema = new Schema(reviewPricingTemplate, { strip: false, typecast: true });

		this.handleRemoveFile = handleRemoveFile.bind(this);
	}

	handleUpdateFileDescription = (fileType, i, description) => {
		let fullFileList = cloneDeep(this.state.files);
		fullFileList[fileType][i].fileDescription = description;
		this.setState({ files: fullFileList });
	};

	onDropFile = (fileType, acceptedFiles) => {
		let fullFileList = cloneDeep(this.state.files);
		let newFilesList = acceptedFiles.map((itm, i) => {
			return { file: itm, fileDescription: '' };
		});

		if (fullFileList[fileType]) fullFileList[fileType].push.apply(fullFileList[fileType], newFilesList);
		else fullFileList[fileType] = newFilesList;

		this.setState({ files: fullFileList });
	};

	validateForm = () => {
		const validateList = this.merchantInfoSchema.validate(Object.assign({}, this.state.merchantInfo));
		if (isEmpty(this.state.files)) {
			validateList.push({
				expose: true,
				message: '[**Merchants Statement**](javascript:void) is required',
				path: 'merchantStatement',
			});
		}
		return validateList;
	};

	handleSubmit = e => {
		const errorList = this.validateForm();
		if (errorList.length) {
			this.setState({ highlightMissingFields: true });
			return;
		}
		this.props.showLoader(true);
		const reviewPricing = this.state.merchantInfo;
		appService
			.saveLeadFiles(reviewPricing.leadId, this.state.files)
			.then(() => {
				if (reviewPricing.appId) return appService.saveMerchantFiles(reviewPricing.appId, this.state.files);
			})
			.then(() => {
				return appService.submitReviewPricing(reviewPricing);
			})
			.then(() => {
				this.props.showLoader(false);
				this.setState({ successMessage: 'Submitted successfully.' });
			})
			.catch(err => {
				console.log('save files error', err);
				this.props.showLoader(false);
				this.setState({ errorMessage: 'An error occurred: ' + err, isNewError: true });
			});
	};

	handleChange = e => {
		let { name, value } = e.target;
		try {
			value = JSON.parse(value);
		} catch (ex) {}
		this.setState({ merchantInfo: Object.assign({}, this.state.merchantInfo, { [name]: +value || value }) });
	};

	handleCheckedChange = e => {
		const { name, checked } = e.target;
		this.setState({ merchantInfo: Object.assign({}, this.state.merchantInfo, { [name]: checked }) });
	};

	handleYesNoChange = (e, defaultValues, defaultValue) => {
		const newState = {
			merchantInfo: Object.assign({}, this.state.merchantInfo, { [e.target.name]: e.target.checked }),
		};

		if (e.target.checked === defaultValue) {
			each(defaultValues, (name, value) => {
				newState.merchantInfo[name] = value;
			});
		}

		this.setState(newState);
	};

	scrollTo = id => {
		this.setState({ highlightMissingFields: true });
		const elem = document.getElementById(id);
		elem && elem.scrollIntoView({ behavior: 'smooth', block: 'center' });
	};

	getRequiredClassNameText = field => {
		const errorList = this.validateForm();
		const errorListPaths = errorList.map(e => e.path);
		return this.state.highlightMissingFields && errorListPaths.includes(field) ? ' is-invalid' : '';
	};

	render() {
		const { merchantInfo, errorMessage, successMessage } = this.state;
		const errorList = this.validateForm();
		const errorListPaths = errorList.map(e => e.path);
		return successMessage ? (
			<React.Fragment>
				<div className="modal__body">
					<div className="flex--secondary flex--gap--med flex--top flex--nowrap">
						<h5>{successMessage}</h5>
						<i className="icon icon--med icon--regular--check"></i>
					</div>
				</div>
				<div className="modal__footer"></div>
			</React.Fragment>
		) : (
			<React.Fragment>
				<header className="modal__header">
					<h4>Pricing Analysis For {merchantInfo.dba || ''}</h4>
				</header>
				<form className="modal__body" onSubmit={this.submit}>
					<div className="row flex--bottom">
						<div id="dbadiv" className="col col-sml-12 col-lrg-6 form__group">
							<div className="form__group__header">
								<label htmlFor="dba" className="form__group__label">
									Merchant DBA
								</label>
								<span data-tooltip="Required" className="form__group__required">
									*
								</span>
							</div>
							<input
								className={'input input--med' + this.getRequiredClassNameText('merchantDBA')}
								value={merchantInfo.dba}
								name="dba"
								onChange={this.handleChange}
							/>
						</div>
						<div id="corporateNamediv" className="col col-sml-12 col-lrg-6 form__group">
							<div className="form__group__header">
								<p className="form__group__label">
									Merchant name on statement
									<br />
									(if diff. than DBA)
								</p>
							</div>
							<input
								className="input input--med"
								value={merchantInfo.corporateName}
								name="corporateName"
								onChange={this.handleChange}
							/>
						</div>
					</div>
					<div className="notes notes--default spc--bottom--xxxlrg">
						<p className="type--p2">Statements are required to be uploaded to request a review.</p>
					</div>
					<div id="merchantStatement" className={'form__group' + this.getRequiredClassNameText('merchantStatement')}>
						<div className="form__group__header">
							<p className="form__group__label">Upload Statement(s)</p>
							<span className="form__group__required" data-tooltip="Required">
								*
							</span>
						</div>
						<FileDropzoneComponent
							multiple={true}
							tag="MerchantStatement"
							showDescription={true}
							fileList={this.state.files}
							onDrop={this.onDropFile}
							onRemoveFile={this.handleRemoveFile}
							onUpdateDescription={this.handleUpdateFileDescription}
							validateFileSize={false}
						/>
					</div>
					<div className="row">
						<div className="col col-sml-12 col-lrg-6 form__group" id="descriptionOfGoodsdiv">
							<div className="form__group__header">
								<p className="form__group__label">Description of goods / services sold</p>
								<span className="form__group__required" data-tooltip="Required">
									*
								</span>
							</div>
							<input
								className={'input input--med' + this.getRequiredClassNameText('descriptionOfGoods')}
								name="descriptionOfGoods"
								value={merchantInfo.descriptionOfGoods}
								onChange={this.handleChange}
							/>
						</div>
						<div id="percentageKeyeddiv" className="col col-sml-12 col-lrg-6 form__group">
							<div className="form__group__header">
								<p className="form__group__label">Percentage keyed</p>
								<span className="form__group__required" data-tooltip="Required">
									*
								</span>
							</div>
							<input
								type="number"
								min="0"
								className={'input input--med' + this.getRequiredClassNameText('percentageKeyed')}
								name="percentageKeyed"
								value={merchantInfo.percentageKeyed}
								onChange={this.handleChange}
							/>
						</div>
					</div>
					<div id="percentageSwipeddiv" className="form__group">
						<div className="form__group__header">
							<p className="form__group__label">Percentage swiped</p>
							<span className="form__group__required" data-tooltip="Required">
								*
							</span>
						</div>
						<input
							type="number"
							min="0"
							className={'input input--med' + this.getRequiredClassNameText('percentageSwiped')}
							name="percentageSwiped"
							value={merchantInfo.percentageSwiped}
							onChange={this.handleChange}
						/>
					</div>
					<div id="representsAverageMonthdiv" className="form__group">
						<div className="form__group__header spc--bottom--med">
							<p className="form__group__label">Does this represent an average month of swiping?</p>
							<span className="form__group__required" data-tooltip="Required">
								*
							</span>
						</div>
						<YesNoRadio
							name="representsAverageMonth"
							yes={merchantInfo.representsAverageMonth}
							onChange={e => this.handleYesNoChange(e, ['averageMonthlyVolume'], true)}
						/>
					</div>
					{merchantInfo.representsAverageMonth ? (
						''
					) : (
						<div id="averageMonthlyVolumediv" className="form__group">
							<div className="form__group__header">
								<p className="form__group__label">Please specify avg. monthly volume</p>
								<span className="form__group__required" data-tooltip="Required">
									*
								</span>
							</div>
							<input
								type="number"
								min="0"
								step="0.01"
								className={'input input--med input--currency' + this.getRequiredClassNameText('averageMonthlyVolume')}
								name="averageMonthlyVolume"
								value={merchantInfo.averageMonthlyVolume}
								onChange={this.handleChange}
							/>
						</div>
					)}

					<div id="preauthAndCapturediv" className="form__group">
						<div className="form__group__header spc--bottom--med">
							<p className="form__group__label">Pre-authorization and captures</p>
							<span className="form__group__required" data-tooltip="Required">
								*
							</span>
						</div>
						<YesNoRadio
							name="preauthAndCapture"
							yes={merchantInfo.preauthAndCapture}
							onChange={e => this.handleYesNoChange(e, ['daysBetweenAuthAndCapture'], false)}
						/>
					</div>

					{true}
					{merchantInfo.preauthAndCapture && (
						<React.Fragment>
							<div id="daysBetweenAuthAndCapturediv" className="form__group">
								<div className="form__group__header">
									<p className="form__group__label">How many days between authorization and capture?</p>
								</div>
								<input
									type="number"
									min="0"
									className={'input input--med' + this.getRequiredClassNameText('daysBetweenAuthAndCapture')}
									name="daysBetweenAuthAndCapture"
									value={merchantInfo.daysBetweenAuthAndCapture}
									onChange={this.handleChange}
								/>
							</div>

							<div id="captureSameAsAuthdiv" className="form__group">
								<div className="form__group__header spc--bottom--med">
									<p className="form__group__label">Is capture amount same as the authorization amount?</p>
								</div>
								<YesNoRadio
									name="isCaptureSameAsAuth"
									yes={merchantInfo.isCaptureSameAsAuth}
									onChange={this.handleYesNoChange}
								/>
							</div>

							{merchantInfo.captureSameAsAuth || (
								<div
									id="isCaptureGreaterThanAuthdiv"
									className={'form__group' + this.getRequiredClassNameText('isCaptureGreaterThanAuth')}
								>
									<div className="form__group__header">
										<p className="form__group__label">The capture amount is</p>
									</div>
									<div className="row flex--bottom">
										<div className="col col-sml-12 col-lrg-6 form__field">
											<input
												className="input--radio"
												type="radio"
												id="isCaptureGreaterThanAuthGreater"
												name="isCaptureGreaterThanAuth"
												onChange={this.handleChange}
												value={true}
												checked={merchantInfo.isCaptureGreaterThanAuth}
											/>
											<label htmlFor="isCaptureGreaterThanAuthGreater">Greater than the authorization amount</label>
										</div>
										<div className="col col-sml-12 col-lrg-6 form__field">
											<input
												className="input--radio"
												type="radio"
												id="isCaptureGreaterThanAuthLess"
												name="isCaptureGreaterThanAuth"
												onChange={this.handleChange}
												value={false}
												checked={!merchantInfo.isCaptureGreaterThanAuth}
											/>
											<label htmlFor="isCaptureGreaterThanAuthLess">Less than the authorization amount</label>
										</div>
									</div>
								</div>
							)}
						</React.Fragment>
					)}
					<div id="doesProcessTipsdiv" className="form__group">
						<div className="form__group__header spc--bottom--med">
							<p className="form__group__label">Process tips</p>
							<span className="form__group__required" data-tooltip="Required">
								*
							</span>
						</div>
						<YesNoRadio name="doesProcessTips" yes={merchantInfo.doesProcessTips} onChange={this.handleYesNoChange} />
					</div>
					<div id="hasAmexEsadiv" className="form__group">
						<div className="form__group__header spc--bottom--med">
							<p className="form__group__label">Does the merchant currently have Amex ESA?</p>
						</div>
						<YesNoRadio name="hasAmexEsa" yes={merchantInfo.hasAmexEsa} onChange={this.handleYesNoChange} />
					</div>
					{merchantInfo.hasAmexEsa && (
						<React.Fragment>
							<div className="notes notes--default spc--bottom--med">
								<p className="type--p2">Please provide Amex ESA statement for possible additional savings</p>
							</div>
							<div className="form__group">
								<div className="form__group__header">
									<p className="form__group__label">Upload Statement(s)</p>
									<span className="form__group__required" data-tooltip="Required">
										*
									</span>
								</div>
								<FileDropzoneComponent
									multiple={true}
									tag="MerchantStatement"
									showDescription={true}
									fileList={this.state.files}
									onDrop={this.onDropFile}
									onRemoveFile={this.handleRemoveFile}
									onUpdateDescription={this.handleUpdateFileDescription}
								/>
							</div>
						</React.Fragment>
					)}

					{merchantInfo.market === 'Canada' && (
						<div id="currencydiv" className="form__group">
							<div className="form__group__header spc--bottom--med">
								<p className="form__group__label">Currency</p>
							</div>
							<div className="flex--primary flex--gap--xxlrg">
								<div>
									<input
										type="radio"
										className="input--radio"
										id="idcurrencyCad"
										value="CAD"
										name="currency"
										checked={merchantInfo.currency === 'CAD'}
										onChange={this.handleChange}
									/>
									<label htmlFor="idcurrencyCad">CAD</label>
								</div>
								<div>
									<input
										type="radio"
										className="input--radio"
										id="idcurrencyUsd"
										value="USD"
										name="currency"
										checked={merchantInfo.currency === 'USD'}
										onChange={this.handleChange}
									/>
									<label htmlFor="idcurrencyUsd">USD</label>
								</div>
							</div>
						</div>
					)}

					<p className="type--title type--uppercase spc--bottom--med">Did you ensure the following?</p>

					<div id="areStatementsRecentdiv" className="spc--bottom--xlrg">
						<div className="spc--bottom--sml">
							<input
								className="input--check"
								type="checkbox"
								checked={merchantInfo.areStatementsRecent}
								name="areStatementsRecent"
								id="areStatementsRecent"
								onChange={this.handleCheckedChange}
							/>
							<label className="type--p2" htmlFor="areStatementsRecent">
								Statements provided are recent<span className="type--color--error">*</span>
							</label>
						</div>
						<p className="type--p4 type--color--text--light spc--left--lrg">
							Reviewing old statements is baseless because it will give inaccurate results. In an event that you are
							unable to obtain a recent statement, please make a note of it. Otherwise, we will not be able to complete
							the review.
						</p>
					</div>

					<p className="type--title type--uppercase spc--bottom--med">Did you ensure the following?</p>

					<div id="hasAllPagesOfStatementdiv" className="spc--bottom--xxlrg">
						<div className="spc--bottom--sml">
							<input
								className="input--check"
								type="checkbox"
								checked={merchantInfo.hasAllPagesOfStatement}
								name="hasAllPagesOfStatement"
								id="hasAllPagesOfStatement"
								onChange={this.handleCheckedChange}
							/>
							<label className="type--p2" htmlFor="hasAllPagesOfStatement">
								All pages of the statements are attached<span className="type--color--error">*</span>
							</label>
						</div>
						<p className="type--p4 type--color--text--light spc--left--lrg">
							Common scenario is that merchant provides only even or odd pages.
						</p>
					</div>

					<div id="notesdiv" className="spc--bottom--xxlrg">
						<div className="notes notes--default spc--bottom--lrg">
							<p className="type--p2">
								If you discussed the account with anyone from Agent Support beforehand, please note exactly what was
								discussed. Do not take for granted that we will know what you had in mind.
							</p>
						</div>
						<input className="input input--med" name="notes" value={merchantInfo.notes} onChange={this.handleChange} />
					</div>

					<div id="rushdiv" className="form__group">
						<div className="form__group__header spc--bottom--med">
							<p className="form__group__label">Is this review a rush?</p>
						</div>
						<YesNoRadio name="isRushRequest" yes={merchantInfo.isRushRequest} onChange={this.handleYesNoChange} />
					</div>

					{merchantInfo.isRushRequest && (
						<div id="rushReasondiv" className={'form__group' + this.getRequiredClassNameText('rushReason')}>
							<div className="form__group__header spc--bottom--med">
								<p className="form__group__label">Select one of the below:</p>
							</div>
							<div className="flex--primary flex--gap--xxlrg">
								{EnumReviewPricingRushCredits.map((v, i) => (
									<div key={v}>
										<input
											type="radio"
											className="input--radio"
											id={'rushReasonPoints' + i}
											name="rushReason"
											onChange={this.handleChange}
											value={v}
											checked={merchantInfo.rushReason === v}
										/>
										<label htmlFor={'rushReasonPoints' + i}>{v}</label>
									</div>
								))}
							</div>
						</div>
					)}
					{errorListPaths.length ? (
						<ul className="type--validation__wrapper spc--bottom--med">
							{errorList.map(elem => (
								<li key={elem.path} className="type--validation">
									<button onClick={() => this.scrollTo(elem.path + 'div')}>
										{defaultReactOutput(defaultImplicitParse(elem.message))}
									</button>
								</li>
							))}
						</ul>
					) : null}
					{errorMessage ? <div className="type--validation">{errorMessage}</div> : null}
				</form>
				<div className="modal__footer">
					<button
						// disabled={this.props.isLoading}
						disabled={errorListPaths.length}
						onClick={this.handleSubmit}
						className="btn btn--med btn--primary"
					>
						Submit Form
					</button>
				</div>
			</React.Fragment>
		);
	}
}

export default withLoader(ReviewPricing);
