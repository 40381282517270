import React, { Component } from 'react';
import PropTypes from 'prop-types';

class StateSelectionComponent extends Component {
	get stateValue() {
		return this.props.filter.values.state;
	}

	filterRemoved = e => {
		this.props.onFilterRemoved({
			id: 'state',
			emptyValue: null,
		});
	};

	render() {
		return (
			<div className="filter__toolbar__selected-filter">
				State: <span className="type--wgt--medium">{this.stateValue}</span>
				<button onClick={this.filterRemoved} className="btn btn--link datatooltip--auto">
					<i className="icon icon--tny icon--close--text"></i>
				</button>
			</div>
		);
	}
}

StateSelectionComponent.propTypes = {
	filter: PropTypes.object.isRequired,
	onFilterRemoved: PropTypes.func.isRequired,
};

export default StateSelectionComponent;
