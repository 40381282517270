import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

const ExcelColumn = {
	name: PropTypes.node.isRequired,
	key: PropTypes.string.isRequired,
	width: PropTypes.number.isRequired,
	filterable: PropTypes.bool,
};

class StringFilterComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filterTerm: '',
		};
	}

	componentDidMount() {
		const columnKey = this.props.column.key;
		const search = window.location.search;
		const params = new URLSearchParams(search);
		const value = params.get(`_${columnKey}`);
		if (value) {
			this.setState({ filterTerm: value });
			this.props.onChange({ filterTerm: value, column: this.props.column });
		}
	}

	clearFilters = () => {
		const e = {
			target: {
				value: undefined,
			},
		};
		this.handleChange(e);

		this.setState({
			filterTerm: '',
		});
	};

	handleChange = e => {
		let val = e.target.value;
		this.setState({ filterTerm: val });
		this.props.onChange({ filterTerm: val, column: this.props.column });
	};

	renderInput = () => {
		if (this.props.column.filterable === false) {
			return <span />;
		}
		let inputKey = 'header-filter-' + this.props.column.key;
		let dataTestId = 'inline_filter-' + get(this.props, 'column.name'); //note: required for automatic tests (do not update)
		return (
			<input
				key={inputKey}
				data-tid={dataTestId}
				type="text"
				className="input input--med"
				placeholder=""
				value={this.state.filterTerm}
				onChange={this.handleChange}
				autoComplete='new-password' //disables autofill
			/>
		);
	};

	render() {
		return (
			<Fragment>
				{this.renderInput()}
				{this.props.column.filterable === true && (this.state.filters || this.state.filterTerm) ? (
					<button className="btn btn--link clear-filter-button" onClick={this.clearFilters}>
						<i className="icon icon--tny icon--close"></i>
					</button>
				) : null}
			</Fragment>
		);
	}
}

StringFilterComponent.propTypes = {
	onChange: PropTypes.func.isRequired,
	column: PropTypes.shape(ExcelColumn),
};

export default StringFilterComponent;
