import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

class PasswordInputComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			hidePassword: true,
			isFocused: false,
		};
	}

	componentDidMount = () => {
		window.addEventListener('keyup', this.isCapslock);
	};

	componentWillUnmount = () => {
		window.removeEventListener('keyup', this.isCapslock);
	};

	isCapslock = event => {
		if (event.getModifierState && event.getModifierState('CapsLock')) {
			this.props.setErrorMessage('Caps Lock is on');
		} else {
			this.props.setErrorMessage(null);
		}
	};

	showHidePassword = () => {
		this.setState({ hidePassword: !this.state.hidePassword });
	};

	handleFocus = () => {
		if (this.props.onFocus) {
			this.props.onFocus(true);
		}
		this.setState({ isFocused: true });
	};

	handleBlur = () => {
		if (this.props.onFocus) {
			this.props.onFocus(false);
		}
		this.setState({ isFocused: false });
	};

	render = () => {
		const { value, placeholder, onChange, tabIndex, name } = this.props;
		const { hidePassword, isFocused } = this.state;

		return (
			<Fragment>
				<div className={`input--password${isFocused ? ' focus' : ''}`}>
					<input
						name={name}
						type={hidePassword ? 'password' : 'text'}
						className="input input--med"
						placeholder={placeholder}
						value={value}
						onChange={onChange}
						onFocus={this.handleFocus}
						onBlur={this.handleBlur}
						tabIndex={tabIndex}
						autoComplete="on"
					/>
					<div
						className="visibility-icon datatooltip--auto datatooltip--no-wrap"
						data-tooltip={hidePassword ? 'Show password' : 'Hide password'}
					>
						<i
							className={`icon icon--sml icon--${hidePassword ? 'view' : 'hide'}--text`}
							onClick={this.showHidePassword}
							tabIndex="-1"
						></i>
					</div>
				</div>
			</Fragment>
		);
	};
}

PasswordInputComponent.defaultProps = {
	placeholder: 'Enter your password',
	tabIndex: '-1',
	name: 'password',
};

PasswordInputComponent.propTypes = {
	placeholder: PropTypes.string,
	tabIndex: PropTypes.string,
	value: PropTypes.string,
	name: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	setErrorMessage: PropTypes.func.isRequired,
	onFocus: PropTypes.func,
};

export default PasswordInputComponent;
