import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { noop, every, toLower, get, isEmpty, map, identity } from 'lodash';

import { CheckboxTxtInput } from '../../common/components/checkbox-text-input';
import { Toggle } from '../../common/components/toggle';

export default class USMpaConfirm extends Component {
	render() {
		const { mpa } = this.props;

		const mailingAddressSameAsLocationAddress = every(mpa.corporateAddress, (value, key) => {
			return toLower(value) === toLower(mpa.businessInformation.businessAddress[key]);
		});
		return (
			<Toggle initialToggle={true}>
				{({ isToggled, handleToggle }) => (
					<Fragment>
						<div className={`card${isToggled ? ' is-expanded' : ''} spc--bottom--xlrg`}>
							<button className="card__header card__header--expandable" onClick={handleToggle}>
								<h5>MPA Form</h5>
								<i className={`icon icon--lrg icon--chevron--${isToggled ? 'top' : 'right'}--primary`}></i>
							</button>
							{isToggled && (
								<div className="card__body">
									<div className="flex--primary flex--gap--sml spc--bottom--lrg">
										<p className="type--p2 type--p2--medium">Business Information</p>
										{this.props.renderChangeLink(this.props.redirectToMpa)}
									</div>
									<div className="row spc--bottom--lrg">
										{this.renderField(mpa, 'corporateName', 'Corporate Name')}
										{this.renderField(mpa, 'dba', 'DBA')}
										{this.renderField(mpa, 'contactPhoneNumber', 'Contact Phone Number')}
										{this.renderField(mpa, 'businessContactName', 'Contact Name')}
										{this.renderBusinessEmail(mpa)}
										{this.renderBusinessPhoneNumber(mpa)}
										{this.renderField(mpa, 'businessInformation.businessFax', 'Business Fax Number')}
										{this.renderField(mpa, 'businessStartDate', 'Business Start Date')}
										{this.renderField(mpa, 'businessInformation.businessAddress.streetAddress', 'Business Address')}
										{this.renderField(mpa, 'businessInformation.businessAddress.city', 'City')}
										{this.renderStateAndZip(mpa, 'businessInformation.businessAddress')}
									</div>
									<div className="spc--bottom--xxxlrg">
										<div className="flex--primary flex--gap--sml form__group">
											<p className="type--p2 type--p2--medium">Mailing Address</p>
											{this.props.renderChangeLink(this.props.redirectToMpa)}
										</div>
										<div className="form__group">
											<input
												name="mailingAddressSameAsLocationAddress"
												type="checkbox"
												id="mailingAddressSameAsLocationAddress"
												className="input--check"
												checked={mailingAddressSameAsLocationAddress}
												value={mailingAddressSameAsLocationAddress}
												onChange={noop}
											/>
											<label htmlFor="mailingAddressSameAsLocationAddress">
												Mailing address same as location address.
											</label>
										</div>
										{this.renderMailingAddress(mailingAddressSameAsLocationAddress)}
									</div>
									<div className="spc--bottom--lrg">
										<div className="flex--primary flex--gap--sml form__group">
											<p className="type--p2 type--p2--medium">Additional Information</p>
											{this.props.renderChangeLink(this.props.redirectToMpa)}
										</div>
										<div className="row">
											{this.renderField(mpa, 'goodsOrServicesDescription', 'Goods/Services Sold')}
											{this.renderField(mpa, 'businessInformation.website', 'Website')}
											{this.renderField(mpa, 'businessInformation.ownershipType', 'Ownership Type')}
											{this.renderField(mpa, 'taxID', 'Tax ID (9 digits)', this.renderLastFourOrValue, 'fs-mask')}
										</div>
									</div>
									<div className="spc--bottom--lrg">
										<div className="flex--primary flex--gap--sml form__group">
											<p className="type--p2 type--p2--medium">Bank Information</p>
											{this.props.renderChangeLink(this.props.redirectToMpa)}
										</div>
										<div className="row">
											{this.renderField(mpa, 'bankAccountInformation.bankName', 'Bank Name')}
											{this.renderField(
												mpa,
												'bankAccountInformation.accountNumber',
												'Account DDA #',
												this.renderLastFourOrValue,
												'fs-mask'
											)}
											{this.renderRoutingNumber(mpa, 'bankAccountInformation.routingNumber', 'Bank Routing #')}
											{(mpa.bankAccountInformation.secondaryAccountNumber ||
												mpa.bankAccountInformation.secondaryRoutingNumber) && (
												<div className="row">
													{this.renderField(mpa, 'bankAccountInformation.secondaryBankName', 'Secondary Bank Name')}
													{this.renderField(
														mpa,
														'bankAccountInformation.secondaryAccountNumber',
														'Secondary Account DDA #',
														this.renderLastFourOrValue,
														'fs-mask'
													)}
													{this.renderRoutingNumber(
														mpa,
														'bankAccountInformation.secondaryRoutingNumber',
														'Secondary Bank Routing #'
													)}
												</div>
											)}
										</div>
									</div>

									{this.renderAlternateBankInfo(mpa)}

									<div className="spc--bottom--lrg">
										<div className="flex--primary flex--gap--sml spc--bottom--lrg">
											<p className="type--p2 type--p2--medium">Signer Information</p>
											{this.props.renderChangeLink(this.props.redirectToMpa)}
										</div>
										<div className="row">
											{this.renderField(mpa, 'signerInformation.firstName', 'Signer First Name')}
											{this.renderField(mpa, 'signerInformation.lastName', 'Signer Last Name')}
											{this.renderField(mpa, 'signerInformation.address.streetAddress', 'Street Address')}
											{this.renderField(mpa, 'signerInformation.address.city', 'City')}
											{this.renderStateAndZip(mpa, 'signerInformation.address')}
											{this.renderField(mpa, 'signerInformation.phoneNumber', 'Phone Number')}
											{this.renderField(mpa, 'signerInformation.cellPhoneNumber', 'Signer Cell Number')}
											{this.renderSocialSecurityNumber(mpa)}
											{this.renderField(mpa, 'signerInformation.dateOfBirth', 'Date of Birth', undefined, 'fs-mask')}
											{this.renderAdditionalSignerInfo(mpa)}
										</div>
									</div>

									{this.renderAdditionalPaymentDetails(mpa)}

									<div>
										<div className="flex--primary flex--gap--sml spc--bottom--lrg">
											<p className="type--p2 type--p2--medium">Other Notes</p>
											{this.props.renderChangeLink(this.props.redirectToMpa)}
										</div>
										<div className="form__group">
											<div className="form__group__header">
												<p className="form__group__label">Note</p>
											</div>
											<div className="input input--textarea input--fake">{mpa.accountNotes}</div>
										</div>
									</div>
									{this.renderUploadDocuments()}
								</div>
							)}
						</div>
					</Fragment>
				)}
			</Toggle>
		);
	}
	renderAlternateBankInfo = () => {};
	renderBusinessEmail(mpa) {
		return this.renderField(mpa, 'businessInformation.businessEmail', 'Business Email');
	}
	renderAdditionalPaymentDetails(mpa) {
		if (!get(mpa, 'amexDetails', false)) {
			return null;
		}

		return (
			<div className="spc--bottom--lrg">
				<div className="flex--primary flex--gap--sml spc--bottom--med">
					<p className="type--p2 type--p2--medium">Additional Payment Details</p>
					{this.props.renderChangeLink(this.props.redirectToMpa)}
				</div>
				<div className="row">
					{this.renderEsaNumber(mpa)}
					{this.renderEbtNumber(mpa)}
				</div>
			</div>
		);
	}

	renderEbtNumber(mpa) {
		return (
			<div className="col col-sml-12 col-med-6 spc--bottom--med">
				<CheckboxTxtInput
					disabled={true}
					labelText="Do you have an existing EBT account number?"
					checkboxName="doesAcceptEbt"
					inputName="ebtNumber"
					inputPlaceholder="EBT Number"
					checked={mpa.doesAcceptEbt}
					value={mpa.ebtNumber}
					checkboxClass="input--check input--check--preview"
					inputClass="input input--med"
					toggleId="2"
				/>
			</div>
		);
	}

	renderEsaNumber(mpa) {
		return (
			<div className="col col-sml-12 col-med-6 spc--bottom--med">
				<CheckboxTxtInput
					disabled={true}
					labelText="Do you have an existing Amex ESA number?"
					checkboxName="amexDetails_status"
					inputName="amexEsaNumber"
					inputPlaceholder="ESA Number"
					checked={get(mpa, 'amexDetails.status') === 'Existing'}
					value={get(mpa, 'amexDetails.esaNumber')}
					checkboxClass="input--check input--check--preview"
					inputClass="input input--med"
					toggleId="1"
				/>
			</div>
		);
	}

	renderField(form, field, label, renderer = identity, className = '', tooltip = '') {
		return (
			<div className="col col-sml-12 col-med-6 form__group">
				<div className="form__group__header">
					{
						tooltip ? 
						<div className="flex--primary">
							<label className="label spc--right--tny">
								{label}
							</label>
							<i className="icon icon--tny icon--info datatooltip--200 datatooltip--right" data-tooltip={tooltip}></i>
						</div> : 
						<label className="label">{label}</label>
					}
				</div>
				<div className={`input input--med input--disabled${className || ''}`}>{renderer(get(form, field))}</div>
			</div>
		);
	}

	renderBusinessPhoneNumber(mpa) {
		return this.renderField(mpa, 'businessInformation.businessPhone', 'Business Phone Number');
	}

	renderFiles(files) {
		if (isEmpty(files)) return null;
		return map(files, (file, idx) => (
			<div key={idx} className="flex--primary flex--gap--sml spc--top--sml">
				<i className="icon icon--sml icon--save--primary" />
				<p className="type--color--primary">{file.fileName}</p>
			</div>
		));
	}

	renderUploadDocumentRow(tag, label) {
		const id = `MPAFileTag.${tag}`;
		const files = this.props.getFilesForTag(tag);
		if (!files.length && label == 'Signature') return;

		return (
			<div className="col col-sml-12 col-lrg-6 form__group">
				<div>
					<input
						name={id}
						type="checkbox"
						id={id}
						className="input--check"
						checked={!isEmpty(files)}
						value={!isEmpty(files)}
						onChange={noop}
					/>
					<label htmlFor={id}>{label}</label>
				</div>
				{this.renderFiles(files)}
			</div>
		);
	}

	renderUploadDocuments() {
		return (
			<Fragment>
				<div className="flex--primary flex--gap--sml spc--bottom--lrg">
					<p className="type--p2 type--p2--medium">Upload Documents</p>
					{this.props.renderChangeLink(this.props.redirectToMpa)}
				</div>
				<div className="row">
					{this.renderUploadDocumentRow('ProofOfAddress', 'Proof of Address')}
					{this.renderUploadDocumentRow('SignaturePages', 'Signature')}
					{this.renderUploadDocumentRow('VoidedCheck', 'Voided Check')}
					{this.renderUploadDocumentRow('CompanyLogo', 'Marketing')}
					{this.renderUploadDocumentRow('MerchantStatement', 'Recent Processing Statements')}
					{this.renderUploadDocumentRow('BankStatements', 'Bank Statements')}
					{this.renderUploadDocumentRow('TaxExemption', 'Tax Exempt Docs')}
					{this.renderUploadDocumentRow('CorporateDocs', 'Corp Docs')}
					{this.renderDriversLicense()}
				</div>
			</Fragment>
		);
	}
	renderDriversLicense = () => {};

	renderStateAndZip(mpa, addressField) {
		return (
			<React.Fragment>
				{this.renderField(mpa, `${addressField}.state`, 'State')}
				{this.renderField(mpa, `${addressField}.zip`, 'ZIP')}
			</React.Fragment>
		);
	}

	renderMailingAddress(mailingAddressSameAsLocationAddress) {
		const { mpa } = this.props;
		const { streetAddress, city, state, zip } = mpa.corporateAddress;
		const showMailingAddress = !mailingAddressSameAsLocationAddress && (streetAddress || city || state || zip);
		if (!showMailingAddress) return null;
		return (
			<div className="row">
				{this.renderField(mpa, 'corporateAddress.streetAddress', 'Street Address')}
				{this.renderField(mpa, 'corporateAddress.city', 'City')}
				{this.renderStateAndZip(mpa, 'corporateAddress')}
			</div>
		);
	}

	renderLastFourOrValue = value => {
		if (this.props.displayLastFourOnly && value) {
			return `**** ${value.slice(-4)}`;
		}

		return value;
	};

	renderRoutingNumber(mpa, fieldName, labelText) {
		return this.renderField(mpa, fieldName, labelText, this.renderLastFourOrValue, 'fs-mask');
	}

	renderSocialSecurityNumber(mpa) {
		if (!this.props.showSSN) return null;
		return this.renderField(
			mpa,
			'signerInformation.socialSecurityNumber',
			'Social Security Number',
			undefined,
			'fs-mask'
		);
	}

	renderAdditionalSignerInfo() {
		return null;
	}
}

USMpaConfirm.propTypes = {
	mpa: PropTypes.shape({
		accountNotes: PropTypes.string,
	}).isRequired,
	renderChangeLink: PropTypes.func.isRequired,
	redirectToMpa: PropTypes.string.isRequired,
	displayLastFourOnly: PropTypes.bool,
	showSSN: PropTypes.bool,
	getFilesForTag: PropTypes.func.isRequired,
};
