import React, { Component, Fragment } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { map } from 'lodash';

class NotificationComponent extends Component {
	constructor(props) {
		super(props);
		this.container = document.createElement('div');
		document.body.appendChild(this.container);

		this.state = {
			visible: false,
			notification: {},
		};
	}

	componentWillUnmount() {
		document.body.removeChild(this.container);
	}

	showNotification = () => {
		this.setState({
			visible: true,
		});
	};

	closeNotification = () => {
		this.setState({
			visible: false,
		});
	};

	hideNotification = () => {
		let cb = () => {};
		const {
			notification: { success, forceCloseHandler, onClose },
		} = this.state;
		if ((success || forceCloseHandler) && typeof onClose === 'function') {
			cb = onClose;
		}

		this.setState(
			{
				visible: false,
			},
			() => {
				cb();
			}
		);
	};

	addNotification = notification => {
		this.setState({
			visible: true,
			notification: notification,
		});
	};

	renderNotification() {
		const { visible, notification } = this.state;
		const { additional, style } = this.props;

		let title = null;
		let message = null;
		let ref = null;

		const notifications = notification.children ? [notification, ...notification.children] : [notification];

		if (notification.title) {
			title = (
				<div className="modal__header">
					<div className="modal__header__title">{notification.title}</div>
					{map(additional, ({ Title }, index) => Title && <Title key={index} notification={notification} />)}
				</div>
			);
		}

		if (notification.message) {
			message = (
				<div className="flex--primary flex--column flex--top flex--gap--lrg">
					{notifications.map((item, index) => {
						return (
							<div className="flex--primary flex--top flex--nowrap" key={item.ref || index}>
								<h4 className="spc--right--med">{item.message}</h4>
								<i
									className={
										`spc--top--tny flex--no-shrink icon icon--med icon--regular--` + (item.success ? 'check' : 'error')
									}
								></i>
							</div>
						);
					})}
					{map(additional, ({ Message }, index) => Message && <Message key={index} notification={notification} />)}
				</div>
			);
		}

		if (notification.ref) {
			ref = (
				<Fragment>
					<div className="type--color--text--regular spc--top--sml">(Reference Number {notification.ref})</div>
					{map(additional, ({ Ref }, index) => Ref && <Ref key={index} notification={notification} />)}
				</Fragment>
			);
		}

		return (
			<div>
				{visible ? (
					<div>
						<button className="modal__overlay" onClick={this.hideNotification}></button>
						<div className="modal__content" style={style}>
							<div className="modal__content__wrapper">
								{title}
								<div className="modal__body">
									{message}
									{ref}
								</div>
								<div className="modal__footer">
									<button
										tabIndex="-1"
										className="btn btn--med btn--tertiary modal__close"
										onClick={this.hideNotification}
									>
										Close
									</button>
								</div>
							</div>
						</div>
					</div>
				) : null}
				{map(additional, ({ Body }, index) => Body && <Body key={index} notification={notification} />)}
			</div>
		);
	}

	render() {
		return createPortal(this.renderNotification(), this.container);
	}
}

NotificationComponent.propTypes = {
	additional: PropTypes.arrayOf(
		PropTypes.shape({
			Ref: PropTypes.func,
			Title: PropTypes.func,
			Message: PropTypes.func,
			Body: PropTypes.func,
		})
	),
	style: PropTypes.object,
};

export default NotificationComponent;
