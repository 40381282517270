import React, { Component, Fragment, createRef } from 'react';
import { map, get, find, filter, clone, isEmpty, toLower, some, includes } from 'lodash';
import { object, func, bool, string } from 'prop-types';
import moment from 'moment';
import { withRouter } from 'react-router-dom';

import { appService, principalService } from '../../services';
import { NavbarComponent } from './nav-bar';
import { Toggle, ToggleContainer } from './../../common/components/toggle';
import { withContext, withLoader } from '../../common/components';
import { Modal } from '../../common/components/modal';
import { Notification } from '../../common/components/notifications';
import { getFilesForTag } from '../../common/utilities/commonFileHandlingMethods';
import PaymentPointsContext from '../../common/components/user-account-panel/PaymentPointsContext';
import { DisplayBooleanComponent } from '../../common/formatters';
import { MerchantContext } from '../MerchantDetails';
import USMpaConfirm from './USMpaConfirm';
import CanadaMpaConfirm from './CanadaMpaConfirm';
import CanadaSetupConfirm from './CanadaSetupConfirm';
import USSetupConfirm from './USSetupConfirm';
import { isFd } from '../../common/utilities';
import { getProcessorDisplayName } from '../../common/utilities/processorDisplayName';
import MerchantInfoHeader from '../MerchantEquipment/components/MerchantInfoHeader';

const isExpand = true;

const dateFormat = process.env.REACT_APP_DISPLAY_DATE_FORMAT;

class EAppConfirmComponent extends Component {
	constructor(props) {
		super(props);
		this.state = clone(this.initialState);

		this.errorRef = createRef();
		this.notificationRef = createRef();

		this.getFilesForTag = getFilesForTag.bind(this);
	}

	get initialState() {
		const principal = principalService.get();
		return {
			errorMessage: null,
			appId: this.props.match.params.appid,
			isOpenSubmitApp: false,
			isRequestingRush: false,
			rushReason: '',
			loggedInAgentId: principal.agentId,
			loggedInEmail: principal.email,
			mpa: {
				refNum: '',
				agentName: '',
				signerInformation: {
					socialSecurityNumber: '',
					dateOfBirth: '',
					firstName: '',
					lastName: '',
					email: '',
					title: '',
					phoneNumber: '',
					cellPhoneNumber: '',
					address: {
						streetAddress: '',
						city: '',
						state: '',
						zip: '',
						country: '',
					},
				},
				corporateAddress: {
					streetAddress: '',
					city: '',
					state: '',
					zip: '',
					country: '',
				},
				corporateName: '',
				businessInformation: {
					businessAddress: {
						streetAddress: '',
						city: '',
						state: '',
						zip: '',
						country: '',
					},
					businessPhone: '',
					businessFax: '',
					businessEmail: '',
					website: '',
					ownershipType: '',
				},
				bankAccountInformation: {
					accountNumber: '',
					routingNumber: '',
					bankName: '',
					secondaryAccountNumber: '',
					secondaryRoutingNumber: '',
					secondaryBankName: '',
				},
				businessStartDate: '',
				goodsOrServicesDescription: '',
				taxID: '',
				shouldShowTaxInfo: '',
				doesAcceptEbt: false,
				ebtNumber: '',
				isNewAccount: false,
				amexDetails: {
					status: '',
					esaNumber: '',
				},
				accountNotes: '',
				files: [],
				isTaxable: false,
				appId: '',
				leadId: '',
				agentId: '',
				businessContactName: '',
				ownerName: '',
				mid: '',
				dba: '',
				processorName: '',
				processorId: '',
				isPciCompliant: '',
				statusTitle: '',
				statusDisplayName: '',
				dateSigned: '',
				dateApproved: '',
				submittedBy: '',
			},
		};
	}

	componentDidMount() {
		this.loadDataFromService();
	}

	loadDataFromService = () => {
		const { displayEquipmentInfo } = this.props;
		this.props.showLoader(true);
		const response = {};
		const promises = [this.getApp(response)];

		if (displayEquipmentInfo) {
			promises.push(this.getEquipment(response));
		}

		Promise.all(promises)
			.then(() => {
				this.props.showLoader(false);
				this.mapResponseToState(response);
			})
			.catch(() => {
				this.props.showLoader(false);
			});
	};

	getEquipment(response) {
		return (
			appService
				.getGatewayList(this.state.appId)
				.then(gateways => {
					response.gatewayList = gateways.equipmentList;
				})
				.catch(err => {
					console.log('getGatewayList error', err);
					this.setState({
						errorMessage: 'An error occurred: ' + err,
						isNewError: true,
					});
					return Promise.reject(err);
				}),
			appService
				.getHardwareList(this.state.appId)
				.then(hardware => {
					response.hardwareList = hardware.equipmentList;
				})
				.catch(err => {
					console.log('getHardwareList error', err);
					this.setState({
						errorMessage: 'An error occurred: ' + err,
						isNewError: true,
					});
					return Promise.reject(err);
				}),
			appService
				.getMerchantEquipment(this.state.appId)
				.then(eqp => {
					response.existingMerchantEquipment = eqp.equipmentList;
				})
				.catch(err => {
					console.log('getMerchantEquipment error', err);
					this.setState({
						errorMessage: 'An error occurred: ' + err,
						isNewError: true,
					});
				})
		);
	}
	getApp(response) {
		return appService.getEApp(this.state.appId).then(res => {
			res.signerInformation.ownershipPercentage = res.signerInformation.ownershipPercentage || '';
			response.mpa = res;
			response.setup = res;
		});
	}

	componentDidUpdate() {
		if (this.state.errorMessage && this.state.isNewError) {
			setTimeout(() => {
				if (this.errorRef.current) {
					this.errorRef.current.scrollIntoView({
						behavior: 'smooth',
						block: 'center',
					});
				}
			}, 200);
			this.setState({ isNewError: false });
		}
	}

	mapResponseToState = response => {
		const newState = { ...response };
		newState.mpa = this.mapResponseToMpa(newState);
		this.setState(newState);
	};

	mapResponseToMpa = ({ mpa }) => {
		return {
			...mpa,
			businessStartDate: moment(mpa.businessStartDate).format(dateFormat),
			signerInformation: {
				...mpa.signerInformation,
				dateOfBirth: moment(mpa.signerInformation.dateOfBirth).format(dateFormat),
			},
		};
	};

	redirectToMerchantAccount = () => {
		if (
			this.state.mpa.submittedBy != this.state.mpa.agentId &&
			this.state.mpa.submittedBy === this.state.loggedInAgentId
		) {
			this.props.history.push(`/merchants`);
		} else {
			this.props.history.push(`/merchants/${this.state.appId}/account`);
		}
	};

	submitApp = () => {
		const { showLoader } = this.props;
		const { appId, isRequestingRush, rushReason } = this.state;
		showLoader(true);
		appService
			.submitApplication(appId, isRequestingRush, rushReason)
			.then(({ refNum, status }) => {
				showLoader();
				this.closeSubmitApp();
				this.notificationRef.current.addNotification({
					message: 'Application submitted successfully',
					ref: refNum,
					success: toLower(status) === 's',
					onClose: this.redirectToMerchantAccount,
				});
			})
			.catch(err => {
				showLoader();
				this.closeSubmitApp();
				this.setState({
					errorMessage: 'An error occurred: ' + err,
					isNewError: true,
				});
			});
	};

	handleChange = ({ target: { name, value, checked, type } }) =>
		this.setState({ [name]: type === 'checkbox' ? checked : value });

	closeSubmitApp = () => this.setState({ isOpenSubmitApp: false });

	openSubmitApp = () => this.setState({ isOpenSubmitApp: true, isRequestingRush: false, rushReason: '' });

	redirect = pathname => this.props.history.push({ pathname });

	redirectToMpa = () => this.redirect(`/eapp/mpa/${this.state.appId}`);

	redirectToSetup = () => this.redirect(`/eapp/setup/${this.state.appId}`);

	redirectToEquipment = () => this.redirect(`/eapp/equipment/${this.state.appId}`);

	hasSufficientPoints = () => this.props.agentPoints.points >= 25;

	canSubmitRush = () => this.hasSufficientPoints() || this.isAgentCardknoxRep();

	renderHardwareAndAccessories() {
		const hardware = filter(this.state.existingMerchantEquipment, ({ category }) => toLower(category) !== 'gateway');

		return (
			<Toggle initialToggle={isExpand}>
				{({ isToggled, handleToggle }) =>
					!isEmpty(hardware) && (
						<div>
							<div className={`card${isToggled ? ' is-expanded' : ''} spc--bottom--med`}>
								<button className="card__header card__header--expandable" onClick={handleToggle}>
									<h6>Hardware and Accessories</h6>
									<i className={`icon icon--sml icon--chavron--${isToggled ? 'top' : 'right'}--primary`}></i>
								</button>
								{isToggled && (
									<div className="card__body">
										<div className="flex--primary flex--gap--sml spc--bottom--lrg">
											<p className="type--p2 type--p2--medium">Hardware</p>
											{this.renderChangeLink(this.redirectToEquipment)}
										</div>
										<table className="table table--primary">
											<thead>
												<tr>
													<th>Name & Model</th>
													<th>Quantity</th>
													<th>Merchant Price</th>
													<th>Total Price</th>
												</tr>
											</thead>
											<tbody>
												{map(hardware, ({ name, quantity, oneTimeFeeAmount }) => (
													<tr key={name}>
														<td>
															<div className="flex--primary flex--no-wrap flex--gap--sml">
																<div
																	className="checkout__img checkout__img__thumb checkout__img__thumb--36"
																	style={{
																		backgroundImage:
																			'url(' +
																			process.env.REACT_APP_CDN_URL +
																			toLower(name)
																				.replace(' ', '_')
																				.replace(/[^a-z0-9_-]/gi, '') +
																			'/thumbnail.png' +
																			')',
																	}}
																	alt={name}
																></div>
																<p className="type--p2">{name}</p>
															</div>
														</td>
														<td>
															<div className="input input--sml input--disabled">{quantity}</div>
														</td>
														<td>
															<div className="input input--sml input--disabled input--currency">{oneTimeFeeAmount}</div>
														</td>
														<td>
															<div className="input input--sml input--disabled input--currency">
																{quantity * oneTimeFeeAmount}
															</div>
														</td>
													</tr>
												))}
											</tbody>
										</table>
									</div>
								)}
							</div>
						</div>
					)
				}
			</Toggle>
		);
	}

	renderGatewayPurchasePlan = ({ planName, fees, defaultFreeTransactions, includedFeatures }) => {
		const setupFee = get(find(fees, { feeType: 'OneTimeFee' }), 'retailPrice');
		const monthlyFee = get(find(fees, { feeType: 'MonthlyFee' }), 'retailPrice');
		const transactionFee = get(find(fees, { feeType: 'TransactionFee' }), 'retailPrice');

		return (
			<Fragment>
				<div className="flex--primary flex--gap--sml spc--bottom--lrg">
					<p className="type--p2 type--p2--medium">{planName}</p>
					{this.renderChangeLink(this.redirectToEquipment)}
				</div>
				<div className="row">
					<div className="col col-sml-12 col-med-6 col-xlrg-3 form__group">
						<div className="form__group__header">
							<p className="form__group__label">Setup Fee</p>
						</div>
						<div className="input input--med input--disabled input--currency">{setupFee}</div>
					</div>
					<div className="col col-sml-12 col-med-6 col-xlrg-3 form__group">
						<div className="form__group__header">
							<p className="form__group__label">Monthly Fee</p>
						</div>
						<div className="input input--med input--disabled input--currency">{monthlyFee}</div>
					</div>
					<div className="col col-sml-12 col-med-6 col-xlrg-3 form__group">
						<div className="form__group__header">
							<p className="form__group__label">Transaction</p>
						</div>
						<div className="input input--med input--disabled input--currency">{transactionFee}</div>
					</div>
					<div className="col col-sml-12 col-med-6 col-xlrg-3 form__group">
						<div className="form__group__header">
							<p className="form__group__label">Transactions included</p>
						</div>
						<div className="input input--med input--disabled">{defaultFreeTransactions}</div>
					</div>
				</div>
				{this.renderGatewayIncludedFeatures(includedFeatures)}
			</Fragment>
		);
	};

	renderGatewayIncludedFeatures = includedFeatures => (
		<Fragment>
			<div className="flex--primary flex--gap--sml spc--bottom--lrg">
				<p className="type--p2 type--p2--medium">Features</p>
				{this.renderChangeLink(this.redirectToEquipment)}
			</div>
			<div className="list--secondary">
				{map(includedFeatures, (value, key) => (
					<div key={`${key}.${value}`} className="item">
						<p className="type--p2">{value}</p>
					</div>
				))}
			</div>
		</Fragment>
	);

	renderGatewaysAndAddons = () => {
		const { existingMerchantEquipment, gatewayList } = this.state;
		let existingGatewayList = [];

		if (existingMerchantEquipment) {
			existingGatewayList = filter(existingMerchantEquipment, {
				category: 'Gateway',
			});
		}

		const hasGateways = some(gatewayList, ({ equipmentId }) =>
			some(existingGatewayList, item => item.equipmentId == equipmentId)
		);

		return (
			<Toggle initialToggle={isExpand}>
				{({ isToggled, handleToggle }) =>
					!hasGateways ? null : (
						<Fragment>
							<div className={`card${isToggled ? ' is-expanded' : ''} spc--bottom--lrg`}>
								<button onClick={handleToggle} className="card__header card__header--expandable">
									<h5>Gateways and Add-ons</h5>
									<i className={`icon icon--med icon--chevron--${isToggled ? 'top' : 'right'}--primary`}></i>
								</button>
								{isToggled && (
									<div className="card__body">
										{map(existingGatewayList, ({ equipmentId, planName }) => {
											const gatewayInfo = find(gatewayList, item => item.equipmentId == equipmentId);

											return (
												gatewayInfo && (
													<Fragment>
														<div className="row">
															<div className="col col-sml-12 col-lrg-6 form__group">
																<div className="form__group__header">
																	<p className="form__group__label">Equipment ID</p>
																</div>
																<div className="input input--med input--disabled">{equipmentId}</div>
															</div>
														</div>
														{this.renderGatewayPurchasePlan(
															find(gatewayInfo.purchasePlans, { planName }) ||
																find(gatewayInfo.purchasePlans, pp => includes(pp.planName, planName))
														)}
													</Fragment>
												)
											);
										})}
									</div>
								)}
							</div>
						</Fragment>
					)
				}
			</Toggle>
		);
	};

	renderAccountInformation = () => {
		const { mpa, setup } = this.state;
		const { showEidsAndPci, merchant } = this.props;

		return (
			<Toggle initialToggle={isExpand}>
				{({ isToggled, handleToggle }) => (
					<Fragment>
						<div className={`card${isToggled ? ' is-expanded' : ''} spc--bottom--xlrg`}>
							<button className="card__header card__header--expandable" onClick={handleToggle}>
								<h5>Account Information</h5>
								<i className={`icon icon--lrg icon--chevron--${isToggled ? 'top' : 'right'}--primary`}></i>
							</button>
							{isToggled && (
								<div className="card__body">
									<div className="row">
										<div className="col col-sml-12 col-med-6 form__group">
											<div className="form__group__header">
												<p className="form__group__label" htmlFor="lblMID">
													App ID
												</p>
											</div>
											<p className="type--p2" name="lblMID" id="lblMID">
												{mpa.appId}
											</p>
										</div>
										<div className="col col-sml-12 col-med-6 form__group">
											<div className="form__group__header">
												<p className="form__group__label" htmlFor="lblDBA">
													DBA/Merchant Name
												</p>
											</div>
											<p className="type--p2" name="lblDBA" id="lblDBA">
												{mpa.dba}
											</p>
										</div>
										<div className="col col-sml-12 col-med-6 form__group">
											<div className="form__group__header">
												<p className="form__group__label" htmlFor="lblProcessor">
													Processor
												</p>
											</div>
											<p className="type--p2" name="lblProcessor" id="lblProcessor">
												{getProcessorDisplayName(mpa.processorName)}
											</p>
										</div>

										{showEidsAndPci && (
											<div className="col col-sml-12 col-med-6 form__group">
												<div className="form__group__header">
													<p className="form__group__label" htmlFor="lblPCI">
														PCI Compliant
													</p>
												</div>
												<p className="type--p2" name="lblPCI" id="lblPCI">
													<DisplayBooleanComponent value={mpa.isPciCompliant} />
												</p>
											</div>
										)}

										<div className="col col-sml-12 col-med-6 form__group">
											<div className="form__group__header">
												<p className="form__group__label" htmlFor="lblOwner">
													Owner Name
												</p>
											</div>
											<p className="type--p2" name="lblOwner" id="lblOwner">
												{mpa.ownerName}
											</p>
										</div>
										<div className="col col-sml-12 col-med-6 form__group">
											<div className="form__group__header">
												<p className="form__group__label" htmlFor="lblContact">
													Main Contact
												</p>
											</div>
											<p className="type--p2" name="lblContact" id="lblContact">
												{mpa.businessContactName}
											</p>
										</div>
										<div className="col col-sml-12 col-med-6 form__group">
											<div className="form__group__header">
												<p className="form__group__label" htmlFor="lblAddress">
													Address
												</p>
											</div>
											<p className="type--p2" name="lblAddress" id="lblAddress">
												{mpa.businessInformation.businessAddress.streetAddress}
											</p>
										</div>
										<div className="col col-sml-12 col-med-6 form__group">
											<div className="form__group__header">
												<p className="form__group__label" htmlFor="lblPhone">
													Phone
												</p>
											</div>
											<p className="type--p2" name="lblPhone" id="lblPhone">
												{mpa.businessInformation.businessPhone}
											</p>
										</div>
										<div className="col col-sml-12 col-med-6 form__group">
											<div className="form__group__header">
												<p className="form__group__label" htmlFor="lblEmail">
													Email
												</p>
											</div>
											<p className="type--p2" name="lblEmail" id="lblEmail">
												{mpa.businessInformation.businessEmail}
											</p>
										</div>
										{showEidsAndPci && isFd(setup) && (
											<div className="col col-sml-12 col-med-6 form__group">
												<div className="form__group__header">
													<p className="form__group__label" htmlFor="lblIsEidsEnrolled">
														EIDS
													</p>
												</div>
												<p className="type--p2" name="lblIsEidsEnrolled" id="lblIsEidsEnrolled">
													<DisplayBooleanComponent value={merchant && merchant.isEidsEnrolled} />
												</p>
											</div>
										)}
									</div>
								</div>
							)}
						</div>
					</Fragment>
				)}
			</Toggle>
		);
	};

	renderSetupForm = () => {
		const { mpa, setup } = this.state;
		if (isEmpty(mpa) || isEmpty(setup)) return null;
		const { displayRates, showSubmit } = this.props;
		const { renderChangeLink, redirectToSetup } = this;
		const props = {
			mpa,
			setup,
			displayRates,
			showSubmit,
			renderChangeLink,
			redirectToSetup,
		};

		if (mpa.isCanadian) {
			return <CanadaSetupConfirm {...props} />;
		}
		return <USSetupConfirm {...props} />;
	};

	renderMpaForm = () => {
		const { mpa } = this.state;
		if (isEmpty(mpa)) return null;
		const props = {
			mpa,
			renderChangeLink: this.renderChangeLink,
			redirectToMpa: this.redirectToMpa,
			displayLastFourOnly: this.props.displayLastFourOnly,
			getFilesForTag: this.getFilesForTag,
			showSSN: this.props.showSSN,
		};

		if (mpa.isCanadian) {
			return <CanadaMpaConfirm {...props} />;
		}
		return <USMpaConfirm {...props} />;
	};

	isAgentCardknoxRep = () => {
		const cardknoxDomain = 'cardknox.com';
		const domain = this.state.loggedInEmail.split('@')[1];

		if (domain === cardknoxDomain) {
			return true;
		}
	};
	renderSubmitAppModal = () => {
		const { isOpenSubmitApp, isRequestingRush, rushReason } = this.state;

		return (
			<Modal shouldCloseOnOverlayClick={false} isOpen={isOpenSubmitApp} onClose={this.closeSubmitApp}>
				<div>
					<div className="modal__header">
						<h5>Submit App</h5>
					</div>
					<div className="modal__body">
						<div className="flex--primary flex--column flex--gap--lrg">
							{this.isAgentCardknoxRep() ? null : (
								<div className="notes notes--primary" ref={this.errorRef}>
									<p className="type--p2">
										Requesting a rush deducts 25 payment points. Sufficient payment points are needed to be able to
										submit a rush request
									</p>
								</div>
							)}
							{this.canSubmitRush() ? (
								<div className="fullwidth spc--bottom--sml">
									<div className="spc--bottom--sml">
										<input
											type="checkbox"
											name="isRequestingRush"
											className="input--check"
											checked={isRequestingRush}
											value="isRequestingRush"
											id="isRequestingRush"
											onChange={this.handleChange}
										/>
										<label htmlFor="isRequestingRush">Request Rush</label>
									</div>
									<input
										type="text"
										placeholder="Rush Reason"
										className="input input--number input--med"
										value={rushReason}
										id="rushReason"
										name="rushReason"
										onChange={this.handleChange}
										disabled={!isRequestingRush}
									/>
								</div>
							) : null}
						</div>
					</div>
					<div className="modal__footer">
						<button className="btn btn--med btn--primary" onClick={this.submitApp} disabled={this.props.isLoading}>
							Submit App
						</button>
					</div>
				</div>
			</Modal>
		);
	};

	renderConfirmButton = () => {
		return (
			<button type="button" onClick={this.openSubmitApp} className="btn btn--med btn--primary">
				Confirm App
			</button>
		);
	};

	renderChangeLink = handleRedirect =>
		this.props.showChangeLink && (
			<button className="btn btn--sml btn--secondary" onClick={handleRedirect}>
				<i className="icon icon--sml icon--edit--primary"></i>
				<span>Change</span>
			</button>
		);
	renderHeader = () => {
		const { errorMessage, appId } = this.state;
		return (
			<React.Fragment>
				<Notification ref={this.notificationRef} />
				{this.props.showNavbar && (
					<div className="spc--bottom--med">
						<NavbarComponent appId={appId} location={this.props.location} />
					</div>
				)}
				{errorMessage ? (
					<div className="type--validation spc--bottom--lrg" ref={this.errorRef}>
						{errorMessage}
					</div>
				) : null}
			</React.Fragment>
		);
	};

	render() {
		const { showSubmit, title, displayEquipmentInfo } = this.props;
		const { appId, mpa } = this.state;
		const merchantDba = get(mpa, 'dba');
		return (
			<div id="main-div" className="l--content">
				{this.renderHeader()}
				<div className="w--max--790">
					<ToggleContainer>
						<div className="spc--bottom--xlrg">
							<MerchantInfoHeader merchant={{ appId, dba: merchantDba }} />
							<h3>{title}</h3>
						</div>
						{this.renderAccountInformation()}
						{this.renderMpaForm()}
						{this.renderSetupForm()}
						{displayEquipmentInfo && this.renderGatewaysAndAddons()}
						{displayEquipmentInfo && this.renderHardwareAndAccessories()}
						{showSubmit && (
							<div className="flex--primary flex--gap--sml flex--right spc--bottom--xxlrg">
								{this.renderConfirmButton()}
								{this.renderSubmitAppModal()}
							</div>
						)}
					</ToggleContainer>
				</div>
			</div>
		);
	}
}

EAppConfirmComponent.defaultProps = {
	showNavbar: false,
	showChangeLink: true,
	showSubmit: true,
	showSSN: true,
	displayRates: false,
	displayEquipmentInfo: false,
	title: 'Form Confirmation',
	displayLastFourOnly: false,
};

EAppConfirmComponent.propTypes = {
	match: object,
	history: object,
	showLoader: func,
	isLoading: bool,
	showNavbar: bool,
	showChangeLink: bool,
	showSubmit: bool,
	displayRates: bool,
	displayEquipmentInfo: bool,
	title: string,
	displayLastFourOnly: bool,
	showEidsAndPci: bool,
	showSSN: bool,
	agentPoints: object,
};

export const WrappedEappConfirm = withLoader(
	withContext(
		withContext(withRouter(EAppConfirmComponent), PaymentPointsContext, 'agentPoints'),
		MerchantContext,
		'merchant'
	)
);
export const EappConfirm = EAppConfirmComponent;
