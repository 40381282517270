import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { startCase } from 'lodash';
class ProperCaseFormatterComponent extends Component {
	render() {
		return <span>{startCase(this.props.value)} </span>;
	}
}

ProperCaseFormatterComponent.propTypes = {
	value: PropTypes.string,
};

export default ProperCaseFormatterComponent;
