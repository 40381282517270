import React, { Component, forwardRef } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import ConfirmMFAStepUp from './ConfirmMFAStepUp';
import { principalService } from '../../services';
import { persistState, removeState, restoreState } from '../../helpers/persist-state';
import moment from 'moment';
import { localStorageKeys, componentKeys } from './stepupConstants';

function isMfaVerified(key) {
	const mfaTime = restoreState(localStorageKeys.MFA_CONFIRMED_TIME(key));
	const momentMfaTime = moment(mfaTime, true);
	if (!momentMfaTime.isValid()) {
		removeState(localStorageKeys.MFA_CONFIRMED_TIME(key));
		return false;
	}
	const differenceInSeconds = moment().diff(momentMfaTime, 'seconds');
	if (differenceInSeconds >= 1800) {
		//mfa expired after 30 minutes
		removeState(localStorageKeys.MFA_CONFIRMED_TIME(key));
		return false;
	}
	return true;
}

function persistMfaTime(key) {
	persistState(localStorageKeys.MFA_CONFIRMED_TIME(key), moment().toISOString());
}

function withStepup(WrappedComponent, componentKey, bypassStepupInitially, componentProps = {}) {
	class StepupComponent extends Component {
		constructor(props) {
			super(props);
			this.principal = principalService.get();
			this.state = {
				mfaType: null,
				verified:
					isMfaVerified(componentKey) ||
					bypassStepupInitially ||
					(componentKey === componentKeys.SETTINGS_SECURITY && get(this.principal, 'redirectToSecurity')),
				isSamlLogin: this.principal.isSamlLogin,
				isInitialized: !bypassStepupInitially,
			};
		}

		promiseRejectionHandler = error => {
			// Update state so the next render will show the fallback UI.
			const mfaType = get(error, 'reason.mfaError');
			if (!mfaType) return;
			this.setState({
				mfaType,
				verified: false,
			});
		};

		componentDidMount() {
			// Add an event listener to the window to catch unhandled promise rejections & stash the error in the state
			window.addEventListener('unhandledrejection', this.promiseRejectionHandler);
		}

		componentWillUnmount() {
			window.removeEventListener('unhandledrejection', this.promiseRejectionHandler);
		}

		markAsVerified = () => {
			persistMfaTime(componentKey);
			removeState(localStorageKeys.SMS_MFA_CODE_SENT_TIME(componentKey));
			this.setState({ verified: true, isInitialized: true });
		};

		render() {
			const { verified, isSamlLogin, mfaType, isInitialized } = this.state;
			const { forwardedRef, ...rest } = this.props;

			return (
				<React.Fragment>
					{!verified && !isSamlLogin ? (
						<ConfirmMFAStepUp
							markAsVerified={this.markAsVerified}
							mfaType={mfaType}
							isStepup={true}
							invokeSendCodeOnAppear={!bypassStepupInitially}
							componentKey={componentKey}
							{...componentProps}
							{...rest}
						></ConfirmMFAStepUp>
					) : (
						<WrappedComponent ref={forwardedRef} isMfaInitialized={isInitialized} {...rest} {...componentProps} />
					)}
				</React.Fragment>
			);
		}
	}
	StepupComponent.propTypes = {
		forwardedRef: PropTypes.any,
		bypassStepup: PropTypes.bool,
	};
	return forwardRef((props, ref) => <StepupComponent {...props} forwardedRef={ref} />);
}

export default withStepup;
