import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { has, toLower, get, noop } from 'lodash';

import { modalNames } from './../../../common/components/modal-wrapper';
import TooltipFormatterComponent from '../../MerchantGrid/formatters/TooltipFormatter';
import { principalService } from '../../../services';

class ReviewPricingStatusComponent extends TooltipFormatterComponent {
	static instances = [];

	get statusClasses() {
		return {
			Incomplete: {
				showInfo: false,
				className: 'badge badge--error',
			},
			Pending: {
				showInfo: false,
				className: 'badge badge--warning',
			},
			Waiting: {
				showInfo: false,
				className: 'badge badge--warning',
			},
			Complete: {
				showInfo: true,
				className: 'badge badge--success',
			},
			NoReview: {
				showInfo: false,
				className: 'badge badge--success',
			},
			NotSubmitted: {
				showInfo: false,
				className: 'badge badge--default',
			},
			Unknown: {
				showInfo: false,
				className: 'badge badge--default',
			},
		};
	}

	tooltipClass = 'datatooltip--fake';

	get tooltip() {
		return "Click to check if there's an available review";
	}

	constructor(props) {
		super(props, ReviewPricingStatusComponent.instances);
		this.getStyle = this.getStyle.bind(this);
	}

	lookupReview = async e => {
		e.preventDefault();
		e.stopPropagation();
		const { dependentValues } = this.props;
		const lookupReview = get(dependentValues, 'lookupReview', noop);
		const leadId = get(dependentValues, 'leadId');
		const uniqueId = get(dependentValues, 'uniqueId');
		await lookupReview(leadId, uniqueId);
	};

	getStyle = () => {
		if (has(this.statusClasses, this.props.value)) {
			return this.statusClasses[this.props.value].className;
		} else {
			return 'unknown';
		}
	};

	getShowInfo = () => {
		const principal = principalService.get();
		if (
			has(this.statusClasses, this.props.value) &&
			!principal.isAgentSubUser &&
			!principal.isLinkedUserSubAgent &&
			!principal.isLinkedUserSupportRep
		) {
			return this.statusClasses[this.props.value].showInfo;
		} else {
			return false;
		}
	};

	closePopup = e => {
		this.props.dependentValues.onInfoHover({}, null);
	};

	openModal = e => {
		e.preventDefault();
		e.stopPropagation();
		this.props.dependentValues.openCloseModal({
			name: modalNames.pricingResults,
			data: { leadId: this.props.dependentValues.leadId },
		});
	};

	renderLookupReviewButton = () => {
		return (
			<button
				ref={this.elementRef}
				onMouseEnter={this.displayPopup}
				onMouseLeave={this.closePopupLater}
				className="btn btn--sml btn--ghost"
				onClick={this.lookupReview}
			>
				Lookup Review
			</button>
		);
	};

	render = () => {
		const { value } = this.props;

		return value ? (
			<Fragment>
				<div className="flex--primary">
					{toLower(value) === 'unknown' && !!this.props.dependentValues.leadId ? (
						this.renderLookupReviewButton()
					) : (
						<Fragment>
							<span ref={el => (this.info = el)} className={this.getStyle()}>
								{value}
							</span>
							{this.getShowInfo() && (
								<i className="icon icon--sml icon--info spc--left--tny cursor--pointer" onClick={this.openModal} />
							)}
						</Fragment>
					)}
				</div>
			</Fragment>
		) : null;
	};
}

export default ReviewPricingStatusComponent;

ReviewPricingStatusComponent.propTypes = {
	value: PropTypes.string,
	dependentValues: PropTypes.shape({
		leadId: PropTypes.string,
		leadStatus: PropTypes.string,
		openCloseModal: PropTypes.func,
	}),
};
