import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';

class FeeReportRadioComponent extends Component {
	filterChanged = event => {
		let { values, key } = this.props.filter;
		const { name } = event.target;

		let valueArr = map(values, (v, k) => {
			return { key: k, value: k !== name ? false : !v };
		});

		this.props.onFilterChanged({
			id: key,
			values: valueArr,
			emptyValue: false,
		});
	};

	render() {
		const { filter: { values } } = this.props;
		return (
			<div>
				{map(values, (v, k) => {
					return (
						<div key={k} className="spc--bottom--sml">
							<input
								type="radio"
								name={k}
								className="input--radio"
								checked={!!v}
								value={k}
								key={`radio_${k}`}
								id={`radio_${k}`}
								onClick={this.filterChanged}
							/>
							<label htmlFor={`radio_${k}`}>{k}</label>
						</div>
					);
				})}
			</div>
		);
	}
}

FeeReportRadioComponent.propTypes = {
	filter: PropTypes.object,
	onFilterChanged: PropTypes.func,
}

export default FeeReportRadioComponent;
