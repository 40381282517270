import React from 'react';
import PropTypes from 'prop-types';
export const DEFINE_SORT = {
	ASC: 'ASC',
	DESC: 'DESC',
	NONE: 'NONE',
};

class SortableHeaderCell extends React.Component {
	static propTypes = {
		columnKey: PropTypes.string.isRequired,
		column: PropTypes.shape({ name: PropTypes.node }),
		onSort: PropTypes.func.isRequired,
		sortDirection: PropTypes.oneOf(Object.keys(DEFINE_SORT)),
		headerRenderer: PropTypes.node,
		sortDescendingFirst: PropTypes.bool,
	};

	onClick = () => {
		let direction;
		const { sortDirection, sortDescendingFirst } = this.props;
		switch (sortDirection) {
			default:
			case null:
			case undefined:
			case DEFINE_SORT.NONE:
				direction = sortDescendingFirst ? DEFINE_SORT.DESC : DEFINE_SORT.ASC;
				break;
			case DEFINE_SORT.ASC:
				direction = sortDescendingFirst ? DEFINE_SORT.NONE : DEFINE_SORT.DESC;
				break;
			case DEFINE_SORT.DESC:
				direction = sortDescendingFirst ? DEFINE_SORT.ASC : DEFINE_SORT.NONE;
				break;
		}
		this.props.onSort(this.props.columnKey, direction);
	};

	getSortByText = () => {
		const unicodeKeys = {
			ASC: '9650',
			DESC: '9660',
		};
		return this.props.sortDirection === 'NONE' ? '' : String.fromCharCode(unicodeKeys[this.props.sortDirection]);
	};

	render() {
		const ascClassName = this.props.sortDirection === 'ASC' ? 'top--primary' : null;
		const descClassName = this.props.sortDirection === 'DESC' ? 'down--primary' : 'down';
		const className = ascClassName || descClassName;

		const content = this.props.headerRenderer
			? React.cloneElement(this.props.headerRenderer, this.props)
			: this.props.column.name;
		return (
			<div className="react-grid-HeaderCell-content">
				<p className="name">{content}</p>
				<button
					data-tooltip="Column Sorting"
					className="sorting-icon-wrapper datatooltip--left datatooltip--auto datatooltip--no-wrap"
					onClick={this.onClick}
				>
					<span className={`icon icon--chevron--${className} sorting-icon`}>{this.getSortByText()}</span>
				</button>
			</div>
		);
	}
}

export default SortableHeaderCell;
