import React, { Component } from 'react';
import PropTypes from 'prop-types';

class DBASelectionComponent extends Component {
	get dbaValue() {
		return this.props.filter.values.dba;
	}

	filterRemoved = e => {
		this.props.onFilterRemoved({
			id: 'dba',
			emptyValue: null,
		});
	};

	render() {
		return (
			<div className="filter__toolbar__selected-filter">
				DBA: <span className="type--wgt--medium">{this.dbaValue}</span>
				<button onClick={this.filterRemoved} className="btn btn--link datatooltip--auto">
					<i className="icon icon--tny icon--close--text"></i>
				</button>
			</div>
		);
	}
}

DBASelectionComponent.propTypes = {
	filter: PropTypes.object.isRequired,
	onFilterRemoved: PropTypes.func.isRequired,
};

export default DBASelectionComponent;
