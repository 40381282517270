import { isEmpty, get, split, find, includes, trim } from 'lodash';
import { getValueForProperty, minFee, maxFee } from './validators';

const requiredOptions = (val, ctx) => {
	if (val.isNotAvailable) {
		return true; //passed validation
	}
	if (val.isRequired && val.dataType === 'file' && !isEmpty(get(ctx, 'attachment.file'))) {
		return true;
	}
	if (val.isRequired && !val.dependentOnName && (!ctx.equipmentOptions || !ctx.equipmentOptions[val.name])) {
		return false;
	} else if (
		val.isRequired &&
		val.dependentOnName &&
		ctx.equipmentOptions &&
		ctx.equipmentOptions[val.dependentOnName] == val.dependentOnValue &&
		!ctx.equipmentOptions[val.name]
	) {
		return false;
	} else if (ctx.equipmentOptions) {
		let fullOptionVal = ctx.equipmentOptions[val.name];

		if (fullOptionVal) {
			let splitOptionVal = fullOptionVal.toString().split('|');
			let option = val.options.length > 1 && val.options.find(o => o.value == splitOptionVal[0]);
			if (option && option.requireInput && (splitOptionVal.length == 1 || !splitOptionVal[1].trim())) return false;
		}
	}

	return true;
};
const serial = (val, ctx) => (ctx.purchaseType == 'existing' || ctx.purchaseType == 'agent_owned' ? !!val : true);
const shipping = (val, ctx) => (ctx.purchaseType == 'existing' || ctx.purchaseType == 'agent_owned' ? true : !!val);
const shippingaddress = (val, ctx) =>
	(ctx.purchaseType !== 'existing' || ctx.purchaseType !== 'agent_owned') && ctx.shippingOption == 'Other'
		? !!val
		: true;
const shippingspeed = (val, ctx) =>
	ctx.purchaseType == 'existing' || ctx.purchaseType == 'agent_owned' || ctx.shippingOption == 'Pickup' ? true : !!val;
const paymentSchedule = (val, ctx) => (ctx.purchaseType == 'purchase' ? !!val : true);
const platform = (val, ctx) => (isEmpty(ctx.equipment?.frontendProcessors) ? true : !!val);
const quantity = (val, ctx) => (ctx.category === 'Accessories' ? val <= 300 : val <= 20);
function checkIfRelatedFee(markupFeeName, transactionFeeName) {
	return includes(markupFeeName, trim(transactionFeeName, 'Transaction Fee'));
}
function checkIfRelatedMarkupFee(fee, feeName) {
	return fee.feeType === 'DiscountFee' && checkIfRelatedFee(fee.feeName, feeName);
}
function checkIfRelatedTransactionFee(fee, feeName) {
	return fee.feeType === 'SpecificTransactionFee' && checkIfRelatedFee(feeName, fee.feeName);
}
function getRelatedFee(feeType, feeName, additionalFees) {
	const checkFunction = feeType === 'DiscountFee' ? checkIfRelatedTransactionFee : checkIfRelatedMarkupFee;
	return find(additionalFees, fee => checkFunction(fee, feeName));
}
const originalEquipmentId = (val, ctx) => (ctx.purchaseType == 'rollover' ? !!val : true);
const subeqpOriginalEquipmentId = (val, ctx, path) => {
	return getValueForProperty(ctx, path, 'purchaseType') === 'rollover' ? !!val : true;
};
const additionalFee = (val, ctx, path) => {
	const [key, index, field] = split(path, '.');
	const { isSelected, feeType, feeName } = ctx[key][index];
	if (!isSelected) return true;
	if (val > 0) return true;
	const relatedFee = getRelatedFee(feeType, feeName, ctx[key]);
	if (!relatedFee || !relatedFee.isSelected) return false;
	return relatedFee[field] > 0;
};

export const equipmentTemplate = {
	settingsSource: [
		{
			required: false,
			use: { requiredOptions },
			message: {
				requiredOptions: (path, ctx) => {
					let indx = path.split('.')[1];
					let optionName = ctx.settingsSource[indx].friendlyName;
					let errorMessage = `[**${optionName}**](javascript:void) is required`;
					if (ctx.settingsSource[indx].dependentOnName) {
						errorMessage += ` when ${ctx.settingsSource[indx].dependentOnName} is `;
						let val = ctx.settingsSource[indx].dependentOnValue;
						errorMessage += val == 1 ? 'selected' : val == 0 ? 'not selected' : val;
					}
					return errorMessage;
				},
			},
		},
	],
	purchaseType: {
		type: String,
		required: true,
		message: '[**Purchase Type**](javascript:void) is required',
	},
	purchasePlanId: {
		type: String,
		required: true,
		message: '[**Purchase Plan**](javascript:void) is required',
	},
	paymentSchedule: {
		use: { paymentSchedule },
		message: '[**Payment Schedule**](javascript:void) is required',
	},
	quantity: {
		use: { quantity },
		message: {
			quantity: (val, ctx) => {
				return `[**Quantity**](javascript:void) cannot exceed ${ctx.category === 'Accessories' ? 300 : 20}`;
			},
		},
	},
	fees: [
		{
			merchantPrice: {
				type: Number,
				use: { minFee, maxFee },
				required: true,
				message: {
					type: '[**Merchant Cost**](javascript:void) is invalid',
					required: '[**Merchant Cost**](javascript:void) is required',
					minFee: (path, ctx) => {
						const minFee = getValueForProperty(ctx, path, 'minFee');
						return `[**Merchant Cost**](javascript:void) must be equal or higher than ${minFee}`;
					},
					maxFee: (path, ctx) => {
						const maxFee = getValueForProperty(ctx, path, 'maxFee');
						return `[**Merchant Cost**](javascript:void) must be equal or lower than ${maxFee}`;
					},
				},
			},
		},
	],
	additionalFees: [
		{
			merchantPrice: {
				type: Number,
				use: {
					additionalFee,
				},
				message: {
					type: '[**Markup Merchant Cost**](javascript:void) is invalid',
					additionalFee: '[**Markup Merchant Cost**](javascript:void) is required',
				},
			},
		},
	],
	equipmentOptions: {
		required: false,
	},
};

export const gatewayTemplate = {
	...equipmentTemplate,
	platform: {
		type: String,
		use: {
			platform,
		},
		message: '[**Platform**](javascript:void) is required',
	},
	subequipment: [
		{
			...equipmentTemplate,
			originalEquipmentId: {
				use: { subeqpOriginalEquipmentId },
				message: {
					subeqpOriginalEquipmentId: '[**Original Equipment Id**](javascript:void) is required',
				},
			},
		},
	],
};

export const softwareTemplate = {
	...equipmentTemplate,
	subequipment: [equipmentTemplate],
};

export const posTemplate = {
	purchaseType: {
		type: String,
		required: true,
		message: '[**Purchase Type**](javascript:void) is required',
	},
	originalEquipmentId: {
		use: { originalEquipmentId: originalEquipmentId },
		message: {
			originalEquipmentId: '[**Original Equipment Id**](javascript:void) is required',
		},
	},
};

export const hardwareTemplate = {
	...equipmentTemplate,
	shippingOption: {
		type: String,
		use: { shipping },
		message: '[**Shipping Details**](javascript:void) is required',
	},
	shippingAddress: {
		name: {
			use: { shippingaddress },
			message: '[**Shipping Address Name**](javascript:void) is required',
		},
		streetAddress: {
			use: { shippingaddress },
			message: '[**Shipping Address Street**](javascript:void) is required',
		},
		city: {
			use: { shippingaddress },
			message: '[**Shipping Address City**](javascript:void) is required',
		},
		state: {
			use: { shippingaddress },
			message: '[**Shipping Address State**](javascript:void) is required',
		},
		zip: {
			use: { shippingaddress },
			message: '[**Shipping Address Zip**](javascript:void) is required',
		},
	},
	shippingSpeed: {
		use: { shippingspeed },
		message: '[**Shipping Speed**](javascript:void) is required',
	},
	serialNumber: {
		use: { serial },
		message: '[**Serial Number**](javascript:void) is required',
	},
};

export const varTemplate = {
	settingsSource: [
		{
			required: false,
			use: { requiredOptions },
			message: {
				requiredOptions: (path, ctx) => {
					let indx = path.split('.')[1];
					let optionName = ctx.settingsSource[indx].friendlyName;
					let errorMessage = `[**${optionName}**](javascript:void) is required`;
					if (ctx.settingsSource[indx].dependentOnName) {
						errorMessage += ` when ${ctx.settingsSource[indx].dependentOnName} is `;
						let val = ctx.settingsSource[indx].dependentOnValue;
						errorMessage += val == 1 ? 'selected' : val == 0 ? 'not selected' : val;
					}
					return errorMessage;
				},
			},
		},
	],
	equipmentOptions: {
		required: false,
	},
};

export const subequipmentTemplate = {
	...equipmentTemplate,
	shippingAddress: {
		streetAddress: {
			use: { shippingaddress },
			message: '[**Shipping Address Street**](javascript:void) is required',
		},
		city: {
			use: { shippingaddress },
			message: '[**Shipping Address City**](javascript:void) is required',
		},
		state: {
			use: { shippingaddress },
			message: '[**Shipping Address State**](javascript:void) is required',
		},
		zip: {
			use: { shippingaddress },
			message: '[**Shipping Address Zip**](javascript:void) is required',
		},
	},
	serialNumber: {
		use: { serial },
		message: '[**Serial Number**](javascript:void) is required',
	},
	originalEquipmentId: {
		use: { originalEquipmentId: originalEquipmentId },
		message: {
			originalEquipmentId: '[**Original Equipment Id**](javascript:void) is required',
		},
	},
};
