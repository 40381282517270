import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compact, get, toLower } from 'lodash';
import { NumericFormat as NumberFormat } from 'react-number-format';
const PREDEFINED_FEATURES = [
	'Non-Terminal Transactions',
	'Customer Database + Recurring Schedules',
	'EMV',
	'Intelligent Routing',
];
const GatewayPurchasePlanComponent = props => {
	const {
		gateway,
		merchantGateway,
		availablePurchasePlans,
		handleSelectPlan,
		setIncludedTransactions,
		handleFeeChange,
		handleOpenAdditionalFeesPopup,
	} = props;

	return (
		<div className="table--plans__list">
			{availablePurchasePlans.map(plan => {
				return (
					<div className="table--plans__wrapper table--plans__wrapper--plans" key={plan.planId}>
						<table className="table table--plans">
							<thead>
								<tr>
									<th
										colSpan={3}
										className={`table--plans__header${
											merchantGateway.purchasePlanId == plan.planId ? ' is-selected' : ''
										}`}
									>
										<input
											className="input--radio"
											type="radio"
											name={gateway.name + '_selectedPlan'}
											id={gateway.name + '_' + plan.planId + '_opt'}
											value={plan.planId}
											checked={merchantGateway.purchasePlanId == plan.planId}
											onChange={handleSelectPlan}
										/>
										<label htmlFor={gateway.name + '_' + plan.planId + '_opt'}>{plan.planName}</label>
									</th>
								</tr>
							</thead>
							<tbody>
								<tr className="table--plans__subheader">
									<th>{gateway.name}</th>
									<th>Agent Cost</th>
									<th>Merchant Cost</th>
								</tr>
								{renderPlanFees(merchantGateway, plan, handleFeeChange, handleOpenAdditionalFeesPopup)}
								{renderIncludedTransactions(gateway, merchantGateway, plan, setIncludedTransactions)}
								{renderIncludedFeatures(plan, merchantGateway)}
							</tbody>
						</table>
					</div>
				);
			})}
		</div>
	);
};

const renderPlanFee = (planFee, plan, merchantGateway, handleFeeChange, i) => {
	const minFee = get(planFee, 'minFee');
	const maxFee = get(planFee, 'maxFee');
	const isMerchantPriceDisabled = minFee > 0 && maxFee > 0 && minFee === maxFee;
	const isOneTimeFeeAndDisabled =
		merchantGateway.purchasePlanId == plan.planId &&
		toLower(merchantGateway.paymentSchedule) === 'billagent' &&
		toLower(planFee.feeType) === 'onetimefee';
	let merchantFee = merchantGateway.fees.find((f, i) => f.feeId == planFee.feeId);
	let merchantPrice =
		merchantFee && Object.keys(merchantFee).includes('merchantPrice') ? merchantFee.merchantPrice : planFee.retailPrice;
	const agentCost =
		merchantFee && Object.keys(merchantFee).includes('agentCost') ? merchantFee.agentCost : planFee.agentCost;
	const originalPrice = `$${planFee.retailPrice}`;
	let tooltip = isOneTimeFeeAndDisabled ? `Merchant Cost is not editable when Payment Schedule is Bill Agent.` : null;
	return (
		<Fragment key={plan.planId + i}>
			<td className={merchantGateway.purchasePlanId == plan.planId ? ' is-selected' : ''}>
				<div className="table--gateway__fee">
					<div className="item">
						<NumberFormat
							value={agentCost}
							prefix={'$'}
							decimalScale={3}
							className="input input--med"
							name="agent_cost"
							disabled={true}
						/>
					</div>
				</div>
			</td>
			<td className={merchantGateway.purchasePlanId == plan.planId ? ' is-selected' : ''}>
				<div className={`${isMerchantPriceDisabled ? 'flex--primary flex--nowrap' : ''} item`}>
					<div className="datatooltip--250 datatooltip--left" data-tooltip={tooltip}>
						<NumberFormat
							id={'fee_' + planFee.feeId}
							value={merchantPrice}
							allowNegative={false}
							placeholder={originalPrice}
							thousandSeparator={true}
							prefix={'$'}
							decimalScale={3}
							fixedDecimalScale={false}
							className="input input--med"
							name={'fee_' + planFee.feeId}
							disabled={
								merchantGateway.purchasePlanId != plan.planId || isMerchantPriceDisabled || isOneTimeFeeAndDisabled
							}
							onValueChange={values => {
								handleFeeChange(planFee.planId, planFee.feeId, values);
							}}
						/>
					</div>
					{isMerchantPriceDisabled && !isOneTimeFeeAndDisabled && (
						<i
							className="icon icon--tny icon--info datatooltip--200 spc--left--tny flex--shrink--0"
							data-tooltip={`Merchant cost must equal ${minFee}`}
						/>
					)}
				</div>
			</td>
		</Fragment>
	);
};

const renderNonPlanFee = (plan, merchantGateway, i) => {
	return (
		<td className={merchantGateway.purchasePlanId == plan.planId ? ' is-selected' : ''} key={plan.planId + i}>
			<div className="table--gateway__fee">
				<div className="item">
					<NumberFormat
						value={0}
						prefix={'$'}
						decimalScale={3}
						className="input input--med type--center"
						name="agent_cost"
						disabled={true}
					/>
				</div>
				<div className="item">
					<NumberFormat
						value={0}
						prefix={'$'}
						decimalScale={3}
						className="input input--med type--center"
						name="fee_disabled"
						disabled={true}
					/>
				</div>
			</div>
		</td>
	);
};

const renderPlanFees = (merchantGateway, plan, handleFeeChange, handleOpenAdditionalFeesPopup) => {
	const mergedFees = plan.fees;
	const distinctFeeNames = [...new Set(mergedFees.map(item => item.feeName))];
	const currentPlan = plan;

	return (
		<React.Fragment>
			{distinctFeeNames.map((feeName, idx) => {
				if (mergedFees.find(fee => fee.feeName === feeName && Object.keys(fee.dependencySettings || {}).length > 0)) {
					// this fee type has conditions - take care of it later
					return null;
				}
				return (
					<tr key={idx}>
						<td>
							<p className="type--p3 type--p3--medium">{feeName}</p>
							{currentPlan?.additionalFees?.length > 0 && feeName === 'Transaction Fee' ? (
								<button
									className="btn btn--link spc--top--tny datatooltip--w--100"
									onClick={() => handleOpenAdditionalFeesPopup(true)}
									data-tooltip="Markup Transaction Fees"
								>
									<i className="icon icon--tny icon--add--primary spc--right--tny"></i>
									<span className="type--p3">Markup Fees</span>
								</button>
							) : null}
						</td>
						{[plan].map((plan, i) => {
							let planFee = plan.fees.find(
								f => f.feeName === feeName && f.purchaseTypes.includes(merchantGateway.purchaseType)
							);
							if (planFee) {
								return renderPlanFee(planFee, plan, merchantGateway, handleFeeChange, i);
							} else {
								return renderNonPlanFee(plan, merchantGateway, i);
							}
						})}
					</tr>
				);
			})}
		</React.Fragment>
	);
};

const renderIncludedTransactions = (gateway, merchantGateway, plan, setIncludedTransactions) => {
	const transNumber = plan.defaultFreeTransactions || 300;
	const isSelected = parseInt(merchantGateway.purchasePlanId, 10) === plan.planId;
	const name = `${gateway.name}.${plan.planId}.numberOfFreeTransactions`;

	return (
		<tr>
			<td>Free {transNumber} Transactions</td>
			<td colSpan={2} className={`type--center${isSelected ? ' is-selected' : ''}`}>
				<div className="display--if">
					<input
						className="input--toggle input--toggle--no-label"
						type="checkbox"
						id={name}
						name={name}
						value={transNumber}
						checked={isSelected && merchantGateway.numberOfFreeTransactions > 0}
						onChange={setIncludedTransactions}
						disabled={!isSelected || !plan.defaultFreeTransactions}
					/>
					<label htmlFor={name}>Label</label>
				</div>
			</td>
		</tr>
	);
};

const renderIncludedFeatures = (plan, merchantGateway) => {
	const mergedFeatures = compact(get(plan, 'includedFeatures.featureDescriptions', []));
	const distinctFeatures = new Set(mergedFeatures);

	return (
		<React.Fragment>
			<tr className="table--plans__subheader">
				<th colSpan={3}>Features</th>
			</tr>
			{PREDEFINED_FEATURES.map((featureName, idx) => {
				const content = distinctFeatures.has(featureName) ? (
					<div className="icon icon--med icon--regular--check"></div>
				) : (
					<span className="type--color--text--light">-</span>
				);
				return (
					<tr key={featureName + idx}>
						<td>{featureName}</td>
						<td colSpan={2} className="type--center">
							{content}
						</td>
					</tr>
				);
			})}
		</React.Fragment>
	);
};

GatewayPurchasePlanComponent.propTypes = {
	handleSelectPlan: PropTypes.func.isRequired,
	setIncludedTransactions: PropTypes.func.isRequired,
	handleFeeChange: PropTypes.func.isRequired,
	gateway: PropTypes.object.isRequired,
	merchantGateway: PropTypes.object,
	availablePurchasePlans: PropTypes.array.isRequired,
};

export default GatewayPurchasePlanComponent;
