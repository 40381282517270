import React from 'react';
import { includes } from 'lodash';

class ContactInfo extends React.Component {
	state = { isExpanded: true };

	toggleExpand = () => {
		this.setState({ isExpanded: !this.state.isExpanded });
	};

	handleChange = e => {
		const { name, value } = e.target;
		this.props.onChange('contactInfo', name, value, null);
	};

	render() {
		const { errorListPaths, validateFieldOnBlur, contactInfo: info } = this.props;

		return (
			<div className={`card${this.state.isExpanded ? ' is-expanded' : ''} spc--bottom--lrg`}>
				<button className="card__header card__header--expandable" onClick={this.toggleExpand}>
					<h5>Additional Contact Information</h5>
					<i
						className={`icon icon--sml icon--chevron--${this.state.isExpanded ? 'top' : 'right'}--primary`}
						onClick={this.toggleExpand}
						href="javascript:void(0)"
					></i>
				</button>
				{this.state.isExpanded ? (
					<div className="card__body">
						<div className="row">
							<div className="col col-sml-12 col-lrg-6 form__group">
								<div className="form__group__header">
									<p className="form__group__label">Name</p>
								</div>
								<input
									disabled={this.props.disabled}
									type="text"
									className="input input--med"
									name="alternateContactName"
									id="contactInfo_alternateContactName"
									value={info.alternateContactName}
									onChange={this.handleChange}
								></input>
							</div>
							<div className="col col-sml-12 col-lrg-6 form__group">
								<div className="form__group__header">
									<p className="form__group__label">Title</p>
								</div>
								<input
									disabled={this.props.disabled}
									type="text"
									className="input input--med"
									name="contactTitle"
									id="contactInfo_contactTitle"
									value={info.contactTitle}
									onChange={this.handleChange}
								></input>
							</div>
							<div className="col col-sml-12 col-lrg-6 form__group">
								<div className="form__group__header">
									<p className="form__group__label">Phone Number</p>
								</div>
								<div
									className={`input--prefix${
										includes(errorListPaths, 'contactInfo.contactPhone') ? ' is-invalid' : ''
									}`}
								>
									<div>+1</div>
									<input
										disabled={this.props.disabled}
										type="text"
										className="input--prefix__main"
										name="contactPhone"
										id="contactInfo_contactPhone"
										value={info.contactPhone}
										onChange={this.handleChange}
										onBlur={validateFieldOnBlur}
									/>
								</div>
							</div>
						</div>
					</div>
				) : null}
			</div>
		);
	}
}

export default ContactInfo;
