import React, { Component } from 'react';

class ReviewPricingStatusSelectionComponent extends Component {
	filterText = () => {
		const values = this.props.filter.values;

		let textElements = [];

		const indexOfWaiting = textElements.indexOf('Waiting');
		const indexOfComplete = textElements.indexOf('Complete');
		const indexOfNoReview = textElements.indexOf('NoReview');
		const indexOfIncomplete = textElements.indexOf('Incomplete');
		const indexOfPending = textElements.indexOf('Pending');
		const indexOfNotSubmitted = textElements.indexOf('NotSubmitted');

		if (values.Waiting && indexOfWaiting === -1) {
			textElements.push('Waiting');
		}
		if (values.Complete && indexOfComplete === -1) {
			textElements.push('Complete');
		}
		if (values.NoReview && indexOfNoReview === -1) {
			textElements.push('NoReview');
		}
		if (values.Pending && indexOfPending === -1) {
			textElements.push('Pending');
		}
		if (values.Incomplete && indexOfIncomplete === -1) {
			textElements.push('Incomplete');
		}
		if (values.NotSubmitted && indexOfNotSubmitted === -1) {
			textElements.push('NotSubmitted');
		}

		return textElements.join(', ');
	};

	filterRemoved = event => {
		this.props.onFilterRemoved({
			id: 'reviewStatus',
			emptyValue: false,
		});
	};

	render() {
		return (
			<div className="filter__toolbar__selected-filter">
				{this.filterText()}
				<button onClick={this.filterRemoved} className="btn btn--link datatooltip--auto">
					<i className="icon icon--tny icon--close--text"></i>
				</button>
			</div>
		);
	}
}

export default ReviewPricingStatusSelectionComponent;
