import React, { Component } from 'react';
import PropTypes from 'prop-types';

class FeeReportRadioSelectionComponent extends Component {
	filterText = () => {
		const { filter: { name, values } } = this.props;
		let valuesArr = Object.entries(values);
		let stringDisplay = valuesArr.reduce((txt, val, i) => {
			if (!val[1]) return txt;
			let newVal = txt ? txt + ', ' + val[0] : val[0];
			return newVal;
		}, '');
		return `${name}: ${stringDisplay}`;
	};

	filterRemoved = event => {
		this.props.onFilterRemoved({
			id: this.props.filter.key,
			emptyValue: false,
		});
	};

	render() {
		return (
			<div className="filter__tag">
				{this.filterText()}
				<button onClick={this.filterRemoved} className="btn btn--reset filter__tag__clear"></button>
			</div>
		);
	}
}

FeeReportRadioSelectionComponent.propTypes = {
	filter: PropTypes.object,
	onFilterRemoved: PropTypes.func,
}

export default FeeReportRadioSelectionComponent;
