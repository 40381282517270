import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from '../../common/components/modal';
export default function PrivacyPolicy({ showCopyright }) {
	const [privacyPolicyVisible, setPrivacyPolicyVisible] = useState(false);
	function togglePrivacyPolicy() {
		setPrivacyPolicyVisible(!privacyPolicyVisible);
	}
	return (
		<Fragment>
			<div className="flex--primary flex--gap--tny flex--center flex--nowrap">
				{showCopyright && `© Copyright ${new Date().getFullYear()} Sola -`}
				<button className="btn btn--link type--p3 type--underline" onClick={togglePrivacyPolicy}>
					Privacy Policy
				</button>
			</div>

			<Modal
				isOpen={privacyPolicyVisible}
				onClose={togglePrivacyPolicy}
				className="modal__content modal__content--privacy modal--med"
			>
				<Fragment>
					<div className="modal__body type--color--text--regular">
						<div className="spc--bottom--med">
							<h4>Privacy Policy for Sola Web Sites and Services</h4>
						</div>
						<div>
							<p className="spc--bottom--med">
								This Privacy Policy describes Sola’s practices with regards to the collection, use, disclosure and sale
								of the personal information of consumers. The purpose of this Privacy Policy is to provide you, the
								consumer, with a comprehensive outline of our online and offline practices, and a description of your
								rights, with regards to such activities. This Privacy Policy also describes the terms and conditions
								under which our Web sites and services, including payment processing services and payment gateway
								services, are made available to you.
							</p>
							<p className="spc--bottom--med">
								We recommend that you carefully read this Privacy Policy in its entirety and that you understand it. By
								visiting our Web sites or using our services, you agree to the terms of this Privacy Policy. If you do
								not accept the terms of this Privacy Policy, you are directed to discontinue accessing or otherwise
								using our Web sites, our services, or any materials obtained from them. If you are dissatisfied with our
								Web sites or our services, then please contact us at{' '}
								<a href="mailto:info@solapayments.com" className="btn btn--link">
									info@solapayments.com
								</a>
								; otherwise, your only recourse is to disconnect from our Web sites or our services and to refrain from
								visiting our Web sites, or using our services, in the future.
							</p>
							<p className="spc--bottom--med">
								The process of maintaining our Web sites, and our services, is an evolving one, and we may decide at
								some point in the future, without advance notice (except in cases where advance notice is required by
								law, in which case we will provide you with advance notice), to modify the terms of this Privacy Policy.
								Your use of our Web sites, or any materials obtained from our Web sites, or our services, indicates your
								assent to the Privacy Policy at the time of such use. The effective Privacy Policy will be posted on our
								Web sites, and on all relevant Cardknox services, and you should check upon every visit for any changes.
							</p>
						</div>
						<div>
							<p className="spc--bottom--med type--wgt--bold">From Whom Do We Collect Personal Information</p>
							<p className="spc--bottom--med">
								We collect personal information from, primarily, three categories of consumers: Website Users,
								Merchants, and Cardholders.
							</p>
							<p className="spc--bottom--med">A Website User is a consumer who visits any of our Web sites.</p>
							<p className="spc--bottom--med">
								A Merchant is an individual or legal entity to whom we provide payment processing services and/or
								payment gateway services.
							</p>
							<p className="spc--bottom--med">
								A Cardholder is an individual or legal entity who is a customer of a Merchant and who has authorized a
								payment card transaction by a Merchant. If you are a Cardholder, we usually will not collect personal
								information directly from you, but, rather, via the Merchant with which your transaction is associated.
								Your agreement with the Merchant should explain how the Merchant collects, and shares, your personal
								information.
							</p>
						</div>
						<div>
							<p className="spc--bottom--med type--wgt--bold">What Types of Personal Information Do We Collect?</p>
							<p className="spc--bottom--med">
								Depending on how you use and access our services, we may collect the following information from you:
								<br />
								<ul className="list list--primary spc--top--sml">
									<li className="spc--bottom--tny">
										Personal identifiers, including any of the following: your name, postal address, IP address, email
										address, social security number, driver’s license number or other government-issued identification
										number, signature, bank account number, credit card number, debit card number, payment method and
										other financial information;
									</li>
									<li className="spc--bottom--tny">
										Commercial information, including records of products or services you’ve purchased from us or our
										vendors;
									</li>
									<li>
										Internet or other electronic network activity information, including: browsing history, search
										history, and information regarding your interaction with our Internet Web sites.
									</li>
								</ul>
							</p>
						</div>
						<div className="spc--bottom--med">
							<p className="spc--bottom--med type--wgt--bold">How Do We Collect This Information?</p>
							<p className="spc--bottom--med">
								If you are a Web User, we collect your internet or other electronic network activity information when
								you access our web sites. We do this through the uses of cookies. For more information on our use of
								cookies, please see the section below titled Cookie Notice. Additionally, if you are a Web User, we may
								collect any of your personal identifiers through the use of online forms which you may choose to submit.
							</p>
							<p className="spc--bottom--med">
								If you are a Merchant, we collect your personal identifiers through the use of online forms. You may
								enter your personal identifiers on such forms and submit them to us. We may also collect such
								information through the use of paper forms which you may submit to us. We may also collect your
								commercial information through the use of an internal tracking and/or ticketing system which maintains a
								record of your transactions with us. We will also collect your personal information if you log in to any
								of our online merchant portals.
							</p>
							<p>
								If you are a Cardholder, we collect your personal identifiers when you authorize a transaction at any of
								our Merchants. Such information will be transmitted to us via whatever payment transaction system the
								Merchant is using.
							</p>
						</div>
						<div className="spc--bottom--med">
							<p className="spc--bottom--med type--wgt--bold">Why Do We Collect This Information?</p>
							<div className="spc--bottom--med">
								<p className="spc--bottom--sml">We collect personal identifiers for the following reasons:</p>
								<ul className="list list--primary">
									<li className="spc--bottom--tny">
										If you are a Web User, to provide you with an enhanced and tailor-made experience when visiting our
										Web sites and to provide you with appropriate marketing materials, upon your request.
									</li>
									<li className="spc--bottom--tny">
										If you are a Merchant, to set up a payment processing account for your organization; we are required
										by the payment processing institutions we work with to collect this information.
									</li>
									<li>
										If you are a Cardholder, to transmit the details of your transaction to the appropriate Card
										Association or network (such as Visa, Mastercard, Discover and American Express) so that they may
										verify and approve your transaction. Such collection is done in accordance with the rules and
										regulations of the relevant Card Associations.
									</li>
								</ul>
							</div>
							<div className="spc--bottom--med">
								<p className="spc--bottom--sml">We collect commercial information for the following reason:</p>
								<ul className="list list--primary spc--top--sml spc--bottom--sml">
									<li>
										If you are a Merchant, to maintain a record of your transactions with us so that we may provide you
										with more efficient and effective service.
									</li>
								</ul>
							</div>
							<div>
								<p className="spc--bottom--sml">
									We collect Internet and other electronic network information for the following reason:
								</p>
								<ul className="list list--primary">
									<li>
										If you are a Web User, to provide you with an enhanced and tailor-made experience when visiting our
										Web sites and to provide you with appropriate marketing materials, upon your request.
									</li>
								</ul>
							</div>
						</div>
						<div className="spc--bottom--med">
							<p className="spc--bottom--med type--wgt--bold">With Whom Do We Share Your Information?</p>
							<p className="spc--bottom--med">
								We share personal identifiers with the following types of third-parties:
							</p>
							<ul className="list list--primary spc--bottom--med">
								<li className="spc--bottom--tny">
									If you are a Merchant, we share your personal identifiers with the relevant payment processing
									institutions in order to set up your account.
								</li>
								<li className="spc--bottom--tny">
									If you are a Cardholder, we transmit your personal identifiers to the relevant Card Associations in
									order to authenticate and approve your transaction, but only to the extent necessary for such
									authentication and approval.
								</li>
								<li>If you are a Web User, we do not share your personal identifiers with any third party.</li>
							</ul>
							<p className="spc--bottom--med">
								If you are a Merchant, we may share your commercial information with the relevant payment processing
								institutions in order to complete any order you’ve requested, or in order to maintain a record of your
								transactions, as requested by such institutions.
							</p>
							<p className="spc--bottom--med">
								If you are a Web User, we do not share your internet or other electronic network activity information
								with third-parties, except as noted in our Cookie Notice.
							</p>
							<p>
								Please note, that under all circumstances, we may share your information with government or law
								enforcement authorities, if required to do so by law, court order, or government regulation, warrant or
								subpoena.
							</p>
						</div>
						<div className="spc--bottom--med">
							<p className="spc--bottom--med type--wgt--bold">To Whom Do We Sell Information</p>
							<p>
								We currently do not sell any consumer’s personal information we collect or process, including the
								personal information of minors under the age of 16. We will not sell your personal information without
								first notifying you of such a sale and obtaining your consent or providing you with the chance to
								opt-out of such a sale.
							</p>
						</div>
						<div className="spc--bottom--med">
							<p className="spc--bottom--med type--wgt--bold">Cookie Notice</p>
							<p className="spc--bottom--med">
								Our Web site may use a feature of your browser to set a "cookie" on your computer. Cookies are small
								packets of information that a Web site's computer stores on your computer. Our Web sites can then read
								the cookies whenever you visit our sites. We may use cookies in a number of ways, such as to save your
								login information so you don't have to re-enter it each time you visit our site, to deliver content
								specific to your interests and to track the pages you've visited. These cookies allow us to use the
								information we collect to customize web experience so that your visit to our site is as relevant and as
								valuable to you as possible.
							</p>
							<p>
								Most browser software can be set up to manage use of cookies. You may modify your browser preference to
								provide you with choices relating to cookies. You have the choice to accept all cookies, to be notified
								when a cookie is set or to reject all cookies, except those cookies necessary for the normal operation
								and functioning of our Web sites. If you choose to reject cookies, certain of the functions and
								conveniences of our Web sites may not work properly, and you may be unable to use services that require
								registration in order to participate, or you will have to re-register each time you visit our sites.
								Most browsers offer instructions on how to reset the browser to reject cookies in the "Help" section of
								the toolbar. Under all circumstances, we will not link non-personal information from cookies to
								personally identifiable information without your permission.
							</p>
						</div>
						<div className="spc--bottom--med">
							<p className="spc--bottom--med type--wgt--bold">Our Commitment to You</p>
							<p>
								Cardknox does not sell, rent or lease any consumer’s personal information or lists of contact
								information to any third-party for marketing purposes, and we take numerous steps to maintain the
								security of your information. We respect your privacy and we will only collect and process your personal
								information for legitimate business purposes.
							</p>
						</div>
						<div className="spc--bottom--med">
							<div className="spc--bottom--med type--wgt--bold">Privacy Rights for California Consumers</div>
							<p className="spc--bottom--tny type--underline">Your Rights</p>
							<div className="spc--bottom--med">
								<p className="spc--bottom--med">
									Subject to certain limitations, the California Consumer Privacy Act, or “CCPA”, provides certain
									rights to consumers who are California residents. If you are a California resident (as defined under
									California law), the CCPA provides you with the following rights:
								</p>
								<ul className="list list--primary">
									<li className="spc--bottom--tny">
										The Right to Know – You have the right to request that we disclose the categories, and specific
										pieces, of your personal information we have collected, used, disclosed or sold during the past 12
										months.
										<ul className="list list--primary spc--left--med spc--top--tny">
											<li className="spc--bottom--tny">
												For a list of the categories of information we collect, please see the section titled What Types
												of Personal Information Do We Collect? above.
											</li>
											<li className="spc--bottom--tny">
												For a list of the sources from which we collect such information, please see the section titled
												How Do We Collect This Information? above.
											</li>
											<li className="spc--bottom--tny">
												For more information regarding the business and commercial purposes for such collection, please
												see the section titled Why Do We Collect This Information? above.
											</li>
											<li className="spc--bottom--tny">
												For a list of the categories of third-parties with whom we share this information, please see
												the section titled With Whom Do We Share Your Information? above.
											</li>
											<li className="spc--bottom--tny">
												We will update all of the above lists at least once every 12 months;
											</li>
										</ul>
									</li>
									<li className="spc--bottom--tny">
										The Right to Delete – You have the right to request that we delete any of your personal information
										we have collected or maintained;
									</li>
									<li className="spc--bottom--tny">
										The Right to Opt-Out – You have the right to opt-out of the sale of your personal information.
										Please note that we currently do not sell any California consumer’s personal information and we will
										not, in the future, sell your personal information without first notifying you in advance of such
										sale and providing you with the opportunity to opt-out of such sale.
									</li>
									<li>
										The Right to Non-Discrimination – You have the right not to receive discriminatory treatment by us
										for your exercise of the privacy rights granted to you by the CCPA.
									</li>
								</ul>
							</div>
							<p className="spc--bottom--med type--underline">How to Exercise Your Rights</p>
							<p className="spc--bottom--med">
								If you wish to make a request pursuant to your rights under the CCPA, you may do so through any of the
								following methods: (1) Call us toll-free at 844-227-3566; or (2) Send us an email at{' '}
								<a href="mailto:legal@solapayments.com" className="btn btn--link">
									legal@solapayments.com{' '}
								</a>
								, and reference CCPA in the subject line. You also have the right to appoint an authorized agent to make
								such a request, and receive a response, on your behalf. You may use any legally recognized method to
								appoint an authorized agent, but we will need to verify that the authorized agent has been properly
								appointed prior to making any response to such authorized agent.
							</p>
							<p className="spc--bottom--sml type--underline">How We Will Process Your Request</p>
							<p className="spc--bottom--med">
								For your security, upon receiving your request, we will attempt to verify your identity using the
								information you’ve provided to us in your request and the information we have collected from you. In
								some instances, we may require additional information, including government-issued identification, prior
								to responding to your request.
							</p>
						</div>
						<div className="spc--bottom--med">
							<p className="spc--bottom--med type--wgt--bold">
								Privacy Rights for Users of Countries Located Within the European Economic Area (EEA) and the UK
							</p>
							<p>
								Although Cardknox does not actively market or sell our services or products in the European Economic
								Area or the UK, we may, in order to continue serving an existing business relationship, collect or
								transfer personal information of individuals located within the EEA and the UK (“Data Subjects”).
								Accordingly, in such instances, Cardknox. is the data controller.
							</p>
						</div>
						<div className="spc--bottom--med">
							<p className="type--wgt--bold">
							Collection of Your Personal Information
							</p>
						</div>
						<div className="spc--bottom--med">
							<p>
								Personal information that may be collected by us from a Data Subject in the EEA or the UK may include:
								name, postal address, IP address, phone number, email address, government-issued identification number,
								payment method information (such as credit or debit card number, bank account information, or other
								financial information), transaction amount, date of transaction, and transaction history with a specific
								Merchant, Internet browsing history, Internet search history, and other information regarding your
								interaction with our Internet Web sites.
							</p>
						</div>
						<div className="spc--bottom--med">
							<p className="spc--bottom--sml type--underline">Legal Basis</p>
							<p>
								In addition to the reasons stated above for why we use your personal information, such as consent for
								marketing, contractual obligation to provide you with the services, and to process payments, we will
								also use your personal information when it is in our legitimate interest to do so.
							</p>
						</div>
						<div className="spc--bottom--med">
							<p className="spc--bottom--sml type--underline">Your Rights</p>
							<p className="spc--bottom--med">
								Data Subjects whose personal information is collected in the EEA or UK may withdraw consent at any time
								where consent is the lawful basis for processing the Data Subject’s information. Should you withdraw
								consent for processing or otherwise object to processing that impedes our ability to comply with
								applicable regulations, please be advised that you may be unable to fully avail yourself of our services
								or products.
							</p>
							<p className="spc--bottom--med">
								If you are a Data Subject whose personal information is processed and maintained by Cardknox, you have
								the right to:
							</p>
							<ul className="list list--primary">
								<li className="spc--bottom--tny">Ask what information Cardknox holds about you and why;</li>
								<li className="spc--bottom--tny">Ask for a copy of such information or access to such information;</li>
								<li className="spc--bottom--tny">Be informed how to correct or keep that information up to date;</li>
								<li className="spc--bottom--tny">
									Be informed on how Cardknox is meeting its data protection obligations;
								</li>
								<li className="spc--bottom--tny">Ask for a copy of such information to be sent to a third party;</li>
								<li className="spc--bottom--tny">
									Ask for data to be deleted, if possible and required under EU or UK GDPR;
								</li>
								<li className="spc--bottom--tny">
									Ask for processing of personal information to be restricted, if possible and required under EU or UK
									GDPR;
								</li>
								<li className="spc--bottom--tny">
									Object to processing of personal information, if possible and required under EU or UK GDPR;
								</li>
								<li className="spc--bottom--tny">Object to automated decision-making, where applicable; and</li>
								<li>
									Contact a supervisory authority in the EEA or the UK to file a complaint regarding Cardknox’s
									processing of your personal data.
								</li>
							</ul>
						</div>
						<div className="spc--bottom--med">
							<p className="spc--bottom--sml type--underline">How We Share Your Personal Information</p>
							<div>
								<p className="spc--bottom--med">
									We do not, nor will we, share any nonpublic personal information with any nonaffiliated third parties,
									except in the following circumstances:
								</p>
								<ul className="list list--primary">
									<li className="spc--bottom--tny">
										As necessary to provide the service that a Data Subject has requested or authorized, or to maintain
										and service the Data Subject’s account;
									</li>
									<li className="spc--bottom--tny">
										As required by regulatory authorities or law enforcement officials who have jurisdiction over us and
										our affiliates, or as otherwise required by any applicable law; and
									</li>
									<li>To the extent reasonably necessary to prevent fraud and unauthorized transactions.</li>
								</ul>
								<p>
									If we do share any personal information with a third party or an affiliate who is located outside of
									the EEA or the UK, we shall do so in compliance with the EU GDPR, UK GDPR and the Data Privacy Act
									2018. Accordingly, we will ensure that the relevant supervisory authority has deemed that the country
									or organization provide an adequate level of protection for personal information, or the transfer will
									be subject to contractual arrangements approved under the relevant data privacy legislation, such as
									the EU Standard Contractual Clauses or the UK’s International Data Transfer Agreement.
								</p>
								<p>
									Cardknox employees are prohibited, both during and after termination of their employment, from
									disclosing nonpublic personal information to any person or entity outside Cardknox, including family
									members, except under the circumstances described above. An employee is permitted to disclose
									nonpublic personal information only to such other employees who need to have access to such
									information to deliver our services to the Data Subject.
								</p>
							</div>
						</div>
						<div className="spc--bottom--med">
							<p className="spc--bottom--sml type--underline">Retention</p>
							<p>
								We will retain your personal information for the period necessary to fulfil the purposes outlined in
								this Privacy Policy and in each case in accordance with the applicable legal and regulatory
								requirements. Upon expiry of the retention period, such personal information will be deleted.
							</p>
						</div>
						<div className="spc--bottom--med">
							<p className="spc--bottom--sml type--underline">Cookie Notice</p>
							<p>
								In addition to the information provided above, please refer to our Cookie Policy at{' '}
								<a href="https://www.solapayments.com/cookies-policy/" className="btn btn--link" target='_blank'>
									https://www.solapayments.com/cookies-policy/
								</a>
								{' '}for more detailed information relating to the cookies used
								on our website and the choices available to you.
							</p>
						</div>
						<div className="spc--bottom--med">
							<p className="spc--bottom--sml type--underline">Contact Information</p>
							<p>
								If you are a Data Subject and have questions or concerns regarding the processing of your personal
								information, you may contact us at:{' '}
								<a href="mailto:legal@solapayments.com" className="btn btn--link">
									legal@solapayments.com
								</a>
								{' '}(reference “GDPR” in the subject line), or write to us at: Sola, 465 Oak Glen Road, Howell, New Jersey
								07731 USA. If as a Data Subject, you believe that we have not adequately resolved any such issues, you
								have the right to contact the Supervisory Authority in your country of residence.
							</p>
						</div>
						<div>
							<p className="spc--bottom--med type--wgt--bold">Last Updated</p>
							<p>This Privacy Policy has been last updated September 2024.</p>
						</div>
					</div>
					<div className="modal__footer"></div>
				</Fragment>
			</Modal>
		</Fragment>
	);
}
PrivacyPolicy.propTypes = {
	showCopyright: PropTypes.bool,
};
