import React from 'react';
import { Link } from 'react-router-dom';
import { withLoader } from './../../common/components';
import { appService } from '../../services/appService';
import principalService from '../../services/principalService';
import { getApiUrl } from '../../common/utilities/apiConfiguration';

class LoginPage extends React.Component {
	constructor(props) {
		super(props);

		const settings = {
			'Portal API URL': getApiUrl(),
			'App Build Version': process.env.AppBuildTag,
			AppBuildCommitHash: process.env.AppBuildCommitHash,
			AppBuildBranch: process.env.AppBuildBranch,
		};

		let isLoggedIn = true;
		const principal = principalService.get();
		if (!principal) isLoggedIn = false;

		this.state = {
			errorMessage: null,
			isUserAuthenticated: isLoggedIn,
			settings: settings,
			apiSettings: [],
		};
	}

	componentDidMount() {
		this.refreshData();
	}

	refreshData = () => {
		if (this.state.isUserAuthenticated) {
			this.props.showLoader(true);
			appService
				.apiInfo()
				.then(apiInfo => {
					this.props.showLoader(false);
					this.setState({ apiSettings: apiInfo });
				})
				.catch(err => {
					console.log('apiInfo error', err);
					this.props.showLoader(false);
					this.setState({ errorMessage: 'An error occurred retrieving api info: ' + err });
				});
		} else {
			this.setState({ errorMessage: 'Must be logged in to view API info' });
		}
	};

	handleRefresh = () => {
		this.refreshData();
	};

	renderSettings(settings) {
		return Object.entries(settings).map((val, i) => {
			//console.log(val);
			return (
				<li className="item" key={i}>
					<p className="type--p2 type--color--light--secondary type--nowrap">{val[0]}:</p>
					<p className="type--p2 type--p2--medium type--color--light--secondary type--right">{val[1]}</p>
				</li>
			);
		});
	}

	render() {
		const { settings, apiSettings, errorMessage, isUserAuthenticated } = this.state;

		return (
			<React.Fragment>
				<div className="auth__main__info__header">
					<img src="/static/media/sola-logo.svg" alt="Sola Logo" className="auth__sidebar__logo" />
				</div>
				<div className="auth__main__info">
					<div>
						<h3 className="spc--bottom--xlrg">Web Application Settings</h3>
						<ul className="list--bordered spc--bottom--xxxlrg">{this.renderSettings(settings)}</ul>
					</div>
					<div className="spc--bottom--med">
						<h3 className="spc--bottom--xlrg">API Settings</h3>
						<ul className="list--bordered spc--bottom--xxxlrg">{this.renderSettings(apiSettings)}</ul>
						{errorMessage ? (
							<div className="spc--top--med type--validation spc--bottom--lrg">{errorMessage}</div>
						) : null}
					</div>
					{isUserAuthenticated ? (
						<div className="flex--secondary">
							<button type="button" className="btn btn--primary btn--med" onClick={this.handleRefresh}>
								Refresh
							</button>
							<Link className="btn btn--link" to="/">
								Return to Portal
							</Link>
						</div>
					) : (
						<Link className="btn btn--med btn--primary" to="/login">
							Sign In
						</Link>
					)}
				</div>
			</React.Fragment>
		);
	}
}

export default withLoader(LoginPage);
