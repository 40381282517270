import USMpaConfirm from './USMpaConfirm';
import React from 'react';
import { identity } from 'lodash'

export default class AchMpaConfirm extends USMpaConfirm {
	renderAdditionalPaymentDetails(mpa) {
		return (
			<div>
				<div className="flex--primary flex--gap--sml spc--bottom--lrg">
					<p className="type--p2 type--p2--medium">Additional Transaction Details</p>
					{this.props.renderChangeLink(this.props.redirectToMpa)}
				</div>
				<div className="row">
					{this.renderField(mpa, 'generalTransactionInfo.maxSingle', 'Max Single', identity, '', 'What is the largest transaction size ($) you expect to process?')}
					{this.renderField(mpa, 'generalTransactionInfo.maxDaily', 'Daily Check Amount', identity, '', 'How many payments do you expect to process per day?')}
					{this.renderField(mpa, 'generalTransactionInfo.maxDailyAmount', 'Max Daily Volume', identity, '', 'What is the largest daily payment total ($) you expect?')}
					{this.renderField(mpa, 'generalTransactionInfo.maxPeriod', 'Monthly Check Amount', identity, '', 'How many payments do you expect to process per month?')}
					{this.renderField(mpa, 'generalTransactionInfo.maxPeriodAmount', 'Max Monthly Volume', identity, '', 'What is the largest monthly payment total ($) you expect?')}
					{this.renderField(mpa, 'generalTransactionInfo.averageTransactionAmount', 'Expected Average Amount', identity, '', 'What is your expected average transaction size ($)?')}
				</div>
			</div>
		);
	}
	renderAlternateBankInfo = mpa => {
		const showAlternate =
			mpa.bankAccountInformation.alternateAccountNumber || mpa.bankAccountInformation.alternateRoutingNumber;
		return (
			showAlternate && (
				<div className="row ">
					{this.renderField(mpa, 'bankAccountInformation.alternateBankName', 'Alternate Bank Name')}
					{this.renderField(
						mpa,
						'bankAccountInformation.alternateAccountNumber',
						'Alternate Account DDA #',
						this.renderLastFourOrValue,
						'fs-mask'
					)}
					{this.renderRoutingNumber(mpa, 'bankAccountInformation.alternateRoutingNumber', 'Alternate Bank Routing #')}
				</div>
			)
		);
	};

	renderBusinessEmail(mpa) {
		return this.renderField(mpa, 'businessInformation.businessEmail', 'Contact Email');
	}
	renderAdditionalSignerInfo(mpa) {
		return (
			<div className="col col-sml-12">
				<div className="row">
					{this.renderField(mpa, 'signerInformation.ownershipPercentage', 'Ownership Percentage')}
					{this.renderField(mpa, 'signerInformation.email', 'Signer Email')}
					{this.renderField(mpa, 'signerInformation.title', 'Signer Title')}
				</div>
			</div>
		);
	}
	renderDriversLicense = () => {
		return this.renderUploadDocumentRow('DriversLicense', 'Drivers License');
	};
}
