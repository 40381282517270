import React from 'react';

class NotePopup extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			note: this.props.note,
		};
	}

	onSave = e => {
		this.props.handleChange(this.state.note);
		this.props.closeModal();
	};

	handleChange = e => {
		this.setState({ note: e.target.value });
	};

	render() {
		const note = this.state.note;
		return (
			<React.Fragment>
				<div className="modal__header">
					<h4>Add Note</h4>
				</div>
				<div className="modal__body">
					<div className="form__group">
						<div className="form__group__header">
							<label className="form__group__label">Note</label>
						</div>
						<textarea
							className="input input--textarea"
							rows="5"
							placeholder="Type note here"
							value={note}
							onChange={this.handleChange}
						></textarea>
					</div>
				</div>
				<div className="modal__footer">
					<button className="btn btn--med btn--primary" onClick={this.onSave}>
						Save
					</button>
				</div>
			</React.Fragment>
		);
	}
}

export default NotePopup;
