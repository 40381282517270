import { getValueForProperty, minFee, maxFee } from './validators';
import { includes } from 'lodash'

export const planTemplate = {
	fees: [
		{
			merchantPrice: {
				type: Number,
				use: { minFee, maxFee },
				message: {
					type: (path, ctx) => {
						const feeName = getValueForProperty(ctx, path, 'feeName');
						return `[**${feeName} Merchant Cost**](javascript:void) is invalid`;
					},
					minFee: (path, ctx) => {
						const feeName = getValueForProperty(ctx, path, 'feeName');
						const minFee = getValueForProperty(ctx, path, 'minFee');
						const isPercentage = includes(feeName, '%');
						return ` [**${feeName} Merchant Cost**](javascript:void) must be equal or higher than ${isPercentage ? (minFee * 100).toFixed(2) : minFee}`;
					},
					maxFee: (path, ctx) => {
						const feeName = getValueForProperty(ctx, path, 'feeName');
						const maxFee = getValueForProperty(ctx, path, 'maxFee');
						const isPercentage = includes(feeName, '%');
						return `[**${feeName} Merchant Cost**](javascript:void) must be equal or lower than ${isPercentage ?  (maxFee * 100).toFixed(2) : maxFee}`;
					},
				},
			},
		},
	],
};
