import React, { Component, Fragment, createRef } from 'react';
import { map, get, set, clone, isEmpty, toLower, noop } from 'lodash';
import { object, func, bool } from 'prop-types';
import { withRouter } from 'react-router-dom';

import { appService } from '../../services';
import { Toggle, ToggleContainer } from './../../common/components/toggle';
import { withLoader } from '../../common/components';
import { DropdownContainer } from '../../common/components/dropdown-container';
import { CheckboxContainer } from '../../common/components/checkbox-container';
import { CheckboxTxtInput } from '../../common/components/checkbox-text-input';
import AmexRates from './amex-rates';
import { isGoPlus } from '../../common/utilities/processorType';
import MerchantInfoHeader from '../MerchantEquipment/components/MerchantInfoHeader';

const isExpand = true;

class RatesComponent extends Component {
	constructor(props) {
		super(props);
		this.state = clone(this.initialState);

		this.errorRef = createRef();
	}

	get initialState() {
		return {
			errorMessage: null,
			appId: this.props.match.params.appid,
			setup: {},
		};
	}

	componentDidMount() {
		this.loadDataFromService();
	}

	loadDataFromService = () => {
		this.props.showLoader(true);
		appService
			.getEApp(this.state.appId)
			.then(setup => {
				if (isGoPlus(setup)) {
					appService.getGoPlus(this.state.appId).then(goPlus => {
						const { goPlusEApp } = goPlus;
						set(setup, 'achProcessingInformation', goPlusEApp.achProcessingInformation);
						this.setState({ setup });
						this.props.showLoader(false);
					});
				} else {
					this.setState({ setup });
					this.props.showLoader(false);
				}
			})
			.catch(error => {
				this.props.showLoader(false);
				this.setState({
					errorMessage: 'An error occurred: ' + error,
					isNewError: true,
				});
			});
	};

	componentDidUpdate() {
		if (this.state.errorMessage && this.state.isNewError) {
			setTimeout(() => {
				if (this.errorRef.current) {
					this.errorRef.current.scrollIntoView({
						behavior: 'smooth',
						block: 'center',
					});
				}
			}, 200);
			this.setState({ isNewError: false });
		}
	}

	renderFakeInput = (
		{
			name,
			value,
			labelPrefix = '',
			shouldRenderInput = () => true,
			className = 'col col-sml-12 col-med-6 form__group',
		},
		index
	) =>
		shouldRenderInput() && (
			<div key={`${name}.${value}`} className={className}>
				<div key={`${name}.${index}`} className="form__group__header">
					<p className="form__group__label">
						{labelPrefix ? `${labelPrefix} ` : ''}
						{name}{' '}
					</p>
				</div>
				<div className="input input--med input--disabled">{value}</div>
			</div>
		);

	renderRateStructure = (rateObjectName, labelPrefix, displayCustom = false) => {
		const { setup } = this.state;
		const namePrefix = rateObjectName + '_';
		let rateObj = setup[rateObjectName];
		const divClass = displayCustom ? 'row' : 'form__group';
		const wrapperClassName = `form__group ${displayCustom ? 'col col-sml-12 col-med-6' : ''}`;

		return (
			<DropdownContainer
				hideSelect={true}
				labelText={labelPrefix + ' Rate Structure'}
				labelClass="form__group__label"
				divClass={divClass}
				wrapperClass={wrapperClassName}
				selectClass="input input--med input--select"
				selectName={namePrefix + 'pricingStructureType'}
				value={rateObj.pricingStructureType}
				onChange={() => {}}
				options={[
					{
						value: 'PassThrough',
						text: 'Pass Through',
						children: map(
							[
								{
									name: 'Pass Through',
									value: rateObj.passThroughPercentage,
									className: 'col col-sml-12 col-med-6 form__group',
									labelPrefix,
								},
							],
							item => this.renderFakeInput(item)
						),
					},
					{
						value: 'FlatRate',
						text: 'Flat Rate',
						children: map(
							[
								{
									name: 'Flat Rate',
									value: rateObj.flatRatePercentage,
									className: 'col col-sml-6 col-med-2',
									labelPrefix,
								},
							],
							item => this.renderFakeInput(item)
						),
					},
					{
						value: 'Tiered',
						text: 'Tiered',
						children: (
							<div className="row spc--top--med">
								{map(
									[
										{
											name: 'Qual',
											value: rateObj.tieredRatePercentages.qualified,
											className: 'col col-sml-12 col-med-3',
										},
										{
											name: 'Mid-Qual',
											value: rateObj.tieredRatePercentages.midQualified,
											className: 'col col-sml-12 col-med-3',
										},
										{
											name: 'Non-Qual',
											value: rateObj.tieredRatePercentages.nonQualified,
											className: 'col col-sml-12 col-med-3',
										},
										{
											name: 'Rewards',
											value: rateObj.tieredRatePercentages.rewards,
											shouldRenderInput: () => rateObjectName.indexOf('amex') !== 0,
											className: 'col col-sml-12 col-med-3',
										},
									],
									item => this.renderFakeInput(item)
								)}
							</div>
						),
					},
					{
						value: 'BillBack',
						text: 'Bill Back',
						children: map(
							[
								{
									name: 'Base Rate',
									value: rateObj.billBackRatePercentages.base,
									labelPrefix,
								},
								{
									name: 'Enhanced Rate',
									value: rateObj.billBackRatePercentages.enhanced,
									labelPrefix,
									shouldRenderInput: () => namePrefix.indexOf('amex') !== 0,
								},
							],
							item => this.renderFakeInput(item)
						),
					},
				]}
			/>
		);
	};

	renderRatesForm = () => {
		const { setup } = this.state;

		return (
			<Toggle initialToggle={isExpand}>
				{({ isToggled, handleToggle }) =>
					!isEmpty(setup) && (
						<Fragment>
							<div className={`card${isToggled ? ' is-expanded' : ''}`}>
								<button className="card__header card__header--expandable" onClick={handleToggle}>
									<h5>Rates Form</h5>
									<i className={`icon icon--lrg icon--chevron--${isToggled ? 'top' : 'right'}--primary`}></i>
								</button>
								{isToggled && (
									<div className="card__body">
										<p className="type--p2 type--p2--medium spc--bottom--lrg">Pricing Structure</p>
										<div className="row">
											<div className="col col-sml-12 col-lrg-6 form__group">
												<div className="form__group__header">
													<p className="form__group__label">Bank</p>
												</div>
												<div className="input input--med input--disabled">{setup.processorName}</div>
											</div>
											<div className="col col-sml-12 col-lrg-6">{this.renderRateStructure('pricingStructure', '')}</div>
										</div>
										{setup.pricingStructure.otherVolumePercent && (
											<div className="row">
												<div className="col col-sml-12 col-lrg-6 form__group">
													<div className="form__group__header">
														<p className="form__group__label">Other Volume Percent Type</p>
													</div>
													<div className="input input--med input--disabled">
														{setup.pricingStructure.otherVolumePercentType}
													</div>
												</div>
												<div className="col col-sml-12 col-lrg-6 form__group">
													<div className="form__group__header">
														<p className="form__group__label">Other Volume Percent</p>
													</div>
													<div className="input input--med input--disabled">
														{setup.pricingStructure.otherVolumePercent}
													</div>
												</div>
											</div>
										)}
										<div className="row">
											<div className="col col-sml-12 col-lrg-6 form__group">
												<div className="form__group__header">
													<p className="form__group__label">Authorization Fee</p>
												</div>
												<div className="input input--med input--disabled">
													{setup.pricingStructure.authorizationFee}
												</div>
											</div>
											<div className="col col-sml-12 col-lrg-6 form__group">
												<div className="form__group__header">
													<p className="form__group__label">V/MC/Disc Transaction Fee:</p>
												</div>
												<div className="input input--med input--disabled">
													{setup.pricingStructure.bankCardTransactionFee}
												</div>
											</div>
											<div className="col col-sml-12 spc--bottom--xxxlrg">
												<CheckboxContainer
													labelText="Setup Merchant with Pin Debit "
													checkboxClass="input--check input--check--preview"
													checkboxName="pricingStructure_shouldSetupPinDebit"
													checked={setup.pricingStructure.shouldSetupPinDebit}
													onCheckChanged={this.handleChange}
												>
													<div className="row spc--top--sml  spc--bottom--med">
														{this.renderFakeInput({
															name: 'Proposed Structure',
															value: setup.pricingStructure.pinDebitStructureType,
															className: 'col col-sml-12 col-med-4',
														})}
														{this.renderFakeInput({
															name: 'PIN Based Debit Trans Fee',
															value: setup.pricingStructure.pinDebitTransactionFee,
															className: 'col col-sml-12 col-med-4',
														})}
														{this.renderFakeInput({
															name: 'PIN Based Debit Markup',
															value: setup.pricingStructure.pinDebitMarkupAmount,
															className: 'col col-sml-12 col-med-4',
														})}
													</div>
												</CheckboxContainer>
											</div>
										</div>
										{get(setup, 'achProcessingInformation.achEnabled') && (
											<div className="row">
												<div className="col col-sml-12 col-lrg-6 form__group">
													<div className="form__group__header">
														<p className="form__group__label">ACH Transaction Fee</p>
													</div>
													<div className="input input--med input--disabled">
														{setup.achProcessingInformation.achTransactionFee}
													</div>
												</div>
												<div className="col col-sml-12 col-lrg-6 form__group">
													<div className="form__group__header">
														<p className="form__group__label">ACH Percent Fee</p>
													</div>
													<div className="input input--med input--disabled">
														{setup.achProcessingInformation.achPercentFee}
													</div>
												</div>
												<div className="col col-sml-12 col-lrg-6 form__group">
													<div className="form__group__header">
														<p className="form__group__label">ACH Return Fee</p>
													</div>
													<div className="input input--med input--disabled">
														{setup.achProcessingInformation.achReturnFee}
													</div>
												</div>
											</div>
										)}
										<div className="row">
											{!setup ||
												!setup.processorName ||
												(toLower(setup.processorName).indexOf('firstdata') === 0 && (
													<div className="col col-sml-12 col-lrg-6 form__group">
														<div className="form__group__header">
															<p className="form__group__label">Discount Method</p>
														</div>
														<div>
															<input
																name="discountMethod"
																id="discountMethod"
																type="checkbox"
																className="input--radio"
																checked={setup.pricingStructure.discountMethod}
																value={setup.pricingStructure.discountMethod}
																onChange={noop}
															/>
															<label htmlFor="discountMethod">{setup.pricingStructure.discountMethod}</label>
														</div>
													</div>
												))}
											{setup.pricingStructure &&
												setup.pricingStructure.bankName &&
												toLower(setup.processorName) === 'elavon' && (
													<div className="row">
														<div className="col col-sml-12 col-med-6 form__group">
															<input
																className="input--check"
																type="checkbox"
																id="pricingStructure_shouldRequireNextDayFunding"
																name="pricingStructure_shouldRequireNextDayFunding"
																value={setup.pricingStructure.shouldRequireNextDayFunding}
																onChange={noop}
															/>
															<label htmlFor="pricingStructure_shouldRequireNextDayFunding">
																Next Day Funding Required ($5 monthly fee applies)
															</label>
														</div>
													</div>
												)}
										</div>

										{!isGoPlus(setup) && (
											<Fragment>
												<p className="type--p2 type--p2--medium spc--bottom--lrg">Proposed Fees</p>
												<div className="row spc--bottom--xxxlrg">
													<div className="col col-sml-12 col-lrg-6 form__group">
														<div className="form__group__header">
															<p className="form__group__label">Batch Fee</p>
														</div>
														<div className="input input--med input--disabled input--currency">
															{setup.proposedFees.batchFee}
														</div>
													</div>
													<div className="col col-sml-12 col-lrg-6 form__group">
														<div className="form__group__header">
															<p className="form__group__label">Retrival Charge</p>
														</div>
														<div className="input input--med input--disabled input--currency">
															{setup.proposedFees.retrievalCharge}
														</div>
													</div>
													<div className="col col-sml-12 col-lrg-6 form__group">
														<div className="form__group__header">
															<p className="form__group__label">Statement Fee</p>
														</div>
														<div className="input input--med input--disabled input--currency">
															{setup.proposedFees.statementFee}
														</div>
													</div>
													<div className="col col-sml-12 col-lrg-6 form__group">
														<div className="form__group__header">
															<p className="form__group__label">Monthly Minimum Fee</p>
														</div>
														<div className="input input--med input--disabled input--currency">
															{setup.proposedFees.monthlyMinimumFee}
														</div>
													</div>
													<div className="col col-sml-12 col-lrg-6 form__group">
														<div className="form__group__header">
															<p className="form__group__label">Annual Fee</p>
														</div>
														<div className="input input--med input--disabled input--currency">
															{setup.proposedFees.annualFee}
														</div>
													</div>
													<div className="col col-sml-12 col-lrg-6 form__group">
														<div className="form__group__header">
															<p className="form__group__label">Chargeback Fee</p>
														</div>
														<div className="input input--med input--disabled input--currency">
															{setup.proposedFees.chargebackFee}
														</div>
													</div>
													<div className="col col-sml-12 col-lrg-6 form__group">
														<div className="form__group__header">
															<p className="form__group__label">PCI Annual Fee</p>
														</div>
														<div className="input input--med input--disabled input--currency">
															{setup.proposedFees.pciAnnualFee}
														</div>
													</div>
													{setup.doesAcceptEbt ? (
														<div className="col col-sml-12 col-lrg-6 form__group">
															<div className="form__group__header">
																<p className="form__group__label">EBT Transaction Fee</p>
															</div>
															<div className="input input--med input--disabled input--currency">
																{setup.proposedFees.ebtTransactionFee}
															</div>
														</div>
													) : null}
												</div>

												<p className="type--p2 type--p2--medium spc--bottom--lrg">Early Termination Fee (ETF)</p>
												<div className="row flex--bottom spc--bottom--xxxlrg">
													<div className="col col-sml-12 col-med-6 form__group">
														<CheckboxTxtInput
															disabled={true}
															labelText="Not Waived Fee"
															checkboxClass="input--check input--check--preview"
															checkboxName="proposedFees_isEarlyTerminationFeeWaived"
															inputName="proposedFees_isEarlyTerminationFee"
															inputPlaceholder=""
															checked={!setup.proposedFees.isEarlyTerminationFeeWaived}
															value={String(setup.proposedFees.earlyTerminationFee)}
															onCheckChanged={this.handleChange}
															onValueChanged={this.handleChange}
															format="NumberFormat"
														/>
													</div>
													{this.renderFakeInput({
														name: 'Waived',
														value: setup.proposedFees.isEarlyTerminationFeeWaived,
													})}
												</div>
											</Fragment>
										)}
										{!isEmpty(setup) && (
											<AmexRates amexDetails={setup.amexDetails} renderRateStructure={this.renderRateStructure} />
										)}
									</div>
								)}
							</div>
						</Fragment>
					)
				}
			</Toggle>
		);
	};

	render() {
		const { appId, errorMessage, setup } = this.state;
		const merchantDba = get(setup, 'dba', '');

		return (
			<div id="main-div" className="l--content--alt">
				<div className="w--max--790">
					{errorMessage ? (
						<p className="type--validation" ref={this.errorRef}>
							{errorMessage}
						</p>
					) : null}
					<ToggleContainer>
						<div className="spc--bottom--xlrg">
							<MerchantInfoHeader merchant={{ appId, dba: merchantDba }} />
							<h3>Rates</h3>
						</div>
						{this.renderRatesForm()}
					</ToggleContainer>
				</div>
			</div>
		);
	}
}

RatesComponent.propTypes = {
	match: object,
	history: object,
	showLoader: func,
	isLoading: bool,
};

export default withLoader(withRouter(RatesComponent));
