import React, { Fragment } from 'react';
import { withExpander } from './../../common/components';
import { renderIf } from './../../common/utilities';
import { DisplayDateComponent, DisplayBooleanComponent } from '../../common/formatters';

class PCICompliance extends React.Component {
	render() {
		const { pciInfo, isExpanded } = this.props;

		return (
			<Fragment>
				<div className="card__header">
					<h5>PCI Compliance</h5>
				</div>

				<div className="card__body card__body--no-bottom-padding">
					<div className="row">
						<div className="col col-sml-12 col-med-6 col-lrg-4 form__group">
							<div className="form__group__header">
								<label className="form__group__label" htmlFor="lblCompliant">
									PCI Compliant
								</label>
							</div>
							<div name="lblCompliant" id="lblCompliant">
								<DisplayBooleanComponent value={pciInfo.isCompliant} />
							</div>
						</div>
						<div className="col col-sml-12 col-med-6 col-lrg-4 form__group">
							<div className="form__group__header">
								<label className="form__group__label" htmlFor="lblComplianceStatus">
									Compliance Status
								</label>
							</div>
							<p
								className="type--p2 type--color--text--secondary type--is-empty"
								name="lblComplianceStatus"
								id="lblComplianceStatus"
							>
								{pciInfo.complianceStatus}
							</p>
						</div>
						<div className="col col-sml-12 col-med-6 col-lrg-4 form__group">
							<div className="form__group__header">
								<label className="form__group__label" htmlFor="lblSAQType">
									SAQ Type
								</label>
							</div>
							<p className="type--p2 type--color--text--secondary type--is-empty" name="lblSAQType" id="lblSAQType">
								{pciInfo.saqValidationType}
							</p>
						</div>
					</div>
					{renderIf(isExpanded)(
						<div className="row">
							<div className="col col-sml-12 col-med-6 col-lrg-4 form__group">
								<div className="form__group__header">
									<label className="form__group__label" htmlFor="lblLastSelfAssessmentDate">
										Last Compliant Self Assessment:
									</label>
								</div>
								<div name="lblLastSelfAssessmentDate" id="lblLastSelfAssessmentDate">
									<DisplayDateComponent value={pciInfo.lastSelfAssessmentDate} />
								</div>
							</div>
							<div className="col col-sml-12 col-med-6 col-lrg-4 form__group">
								<div className="form__group__header">
									<label className="form__group__label" htmlFor="lblLastScanDate">
										Compliant Scan:
									</label>
								</div>
								<div name="lblLastScanDate" id="lblLastScanDate">
									<DisplayDateComponent value={pciInfo.lastScanDate} />
								</div>
							</div>
							<div className="col col-sml-12 col-med-6 col-lrg-4 form__group">
								<div className="form__group__header">
									<label className="form__group__label" htmlFor="lblNonCompliantBillable">
										Non Compliant Billable
									</label>
								</div>
								<div name="lblNonCompliantBillable" id="lblNonCompliantBillable">
									<DisplayBooleanComponent value={pciInfo.isNonCompliantBillable} />
								</div>
							</div>
							<div className="col col-sml-12 col-med-6 col-lrg-4 form__group">
								<div className="form__group__header">
									<label className="form__group__label" htmlFor="lblAnnualFee">
										Annual Fee
									</label>
								</div>
								<div name="lblAnnualFee" id="lblAnnualFee">
									{pciInfo.annualFee}
								</div>
							</div>
						</div>
					)}
				</div>
			</Fragment>
		);
	}
}

export default withExpander(PCICompliance);
