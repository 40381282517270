import React, { Component } from 'react';
import { debounce, findIndex, filter, includes, toLower, flatMap, uniqBy, some, orderBy, find } from 'lodash';

import Header from '../../common/components/header/Header';
import { withLoader } from '../../common/components';
import withError from '../../common/components/error/error-hoc';
import { appService } from '../../services/appService';
import EquipmentLookupTable from './components/EquipmentLookupTable';
import TabList from '../../common/components/TabList/TabList';

const equipmentTypes = [
	{ key: 'gateway', label: 'Gateway' },
	{ key: 'addOns', label: 'Gateway Add-ons' },
	{ key: 'hardware', label: 'Hardware' },
	{ key: 'accessories', label: 'Accessories' },
	{ key: 'software', label: 'Software' },
];
class EquipmentLookup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			equipmentType: 'gateway',
			inlineFilterValue: '',
			filteredEquipments: [],
			gateway: [],
			addOns: [],
			hardware: [],
			accessories: [],
			errorMessage: '',
		};
		this.handleInlineFilter = debounce(this.handleInlineFilter.bind(this), 300);
	}
	componentDidMount() {
		this.fetchEquipment();
	}
	handleInlineFilter = ({ target: { value, name } }) => {
		this.setState(
			{
				[name]: value,
			},
			this.filterEquipment
		);
	};
	handleExpand = equipmentId => {
		const equipmentList = this.state[this.state.equipmentType];
		const index = findIndex(equipmentList, { equipmentId });
		const newEquipments = [...equipmentList];
		newEquipments[index] = {
			...equipmentList[index],
			isExpanded: !equipmentList[index].isExpanded,
		};
		this.setState(
			{
				[this.state.equipmentType]: newEquipments,
			},
			this.filterEquipment
		);
	};
	fetchEquipment = async () => {
		const { showLoader } = this.props;
		showLoader(true);
		try {
			let { equipmentList } = await appService.getEquipmentList();
			equipmentList = orderBy(equipmentList, 'name');
			const gateway = filter(equipmentList, { category: 'Gateway' });
			const software = filter(equipmentList, { category: 'Software' });
			const addOns = orderBy(
				uniqBy(
					flatMap(gateway, ({ subequipment }) => subequipment || []),
					'equipmentId'
				),
				'name'
			);
			const hardwareList = filter(
				equipmentList,
				({ category, purchasePlans }) =>
					!includes(['Gateway', 'Software', 'Var'], category) &&
					some(purchasePlans, ({ fees }) => some(fees, { feeType: 'OneTimeFee' }))
			);
			const hardware = filter(hardwareList, ({ category }) => category !== 'Accessories');
			const accessories = filter(hardwareList, { category: 'Accessories' });
			this.setState({ gateway, addOns, hardware, software, accessories }, this.filterEquipment);
		} catch (err) {
			this.props.handleError(err);
		}
		showLoader(false);
	};

	filterEquipment = e => {
		if (e) e.preventDefault();
		const { inlineFilterValue, equipmentType } = this.state;
		const equipmentList = this.state[equipmentType];
		const filteredEquipments = filter(
			equipmentList,
			({ name }) => !inlineFilterValue || includes(toLower(name), toLower(inlineFilterValue))
		);
		this.setState({
			filteredEquipments,
		});
	};

	handleTabChange = tab => {
		const equipmentType = find(equipmentTypes, type => toLower(type.label) === toLower(tab));
		this.setState(
			{
				equipmentType: equipmentType.key,
			},
			this.filterEquipment
		);
	};

	render() {
		const { equipmentType, filteredEquipments } = this.state;
		const { isLoading } = this.props;
		return (
			<div id="main-div" className="flex--primary flex--top flex--column flex--nowrap fullheight">
				<Header />
				<h3 className="spc--bottom--lrg">Equipment Lookup</h3>

				<TabList titles={equipmentTypes} className="spc--bottom--lrg" onChange={this.handleTabChange} />
				<input
					className="chips chips--search spc--bottom--lrg"
					name="inlineFilterValue"
					id="inlineFilterValue"
					onChange={this.handleInlineFilter}
					type="text"
					placeholder="Search"
					disabled={isLoading}
				></input>

				<EquipmentLookupTable
					equipmentType={equipmentType}
					equipmentList={this.state[equipmentType]}
					filteredEquipments={filteredEquipments}
					onExpand={this.handleExpand}
				/>
			</div>
		);
	}
}

export default withError(withLoader(EquipmentLookup));
