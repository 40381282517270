import React from 'react';
import { each, cloneDeep } from 'lodash';

import GatewayListItemComponent from './gateway-list-item';

class GatewayListComponent extends React.Component {
	getAvailablePurchasePlans = equipmentId => {
		const { gatewayList, merchantEquipment } = this.props;
		const merchantGateway = merchantEquipment.find((e, i) => e.equipmentId === equipmentId);
		const gateway = gatewayList.find((e, i) => e.equipmentId === equipmentId);

		if (!merchantGateway) {
			return gateway.purchasePlans;
		}

		return gateway.purchasePlans.filter(plan => plan.purchaseTypes.includes(merchantGateway.purchaseType));
	};

	gatewaySelect = (gateway, unselectOther) => {
		if (unselectOther) {
			let newGatewayList = cloneDeep(this.props.merchantEquipment);
			each(newGatewayList, item => {
				if (item.category == 'Gateway' && item.equipmentId != gateway.equipmentId) {
					item.isSelected = false;
				}
			});
			this.props.onListChange(newGatewayList);
		}
		this.props.onChange(gateway);
	};

	onChange = (gateway, selectGateway = true) => {
		if (selectGateway) {
			gateway.isSelected = true;
		}
		this.props.onChange(gateway);
	};

	render() {
		const { gatewayList, merchantEquipment, selectedEquipmentId } = this.props;
		/*if (gatewayList.some(eqp => !merchantEquipment.find(e => e.equipmentId == eqp.equipmentId))) {

            return null;
        }*/
		return (
			<div>
				{gatewayList.map((gateway, idx) => {
					if (gateway.purchasePlans.length == 0) return null;

					let merchantGateway = merchantEquipment.find(e => e.equipmentId == gateway.equipmentId);
					if (gatewayList.length == 1 || (!merchantGateway && idx == 0)) {
						gateway.defaultSelected = true;
					}

					return (
						<div key={idx}>
							<GatewayListItemComponent
								gateway={gateway}
								isExpanded={gatewayList.length == 1 || selectedEquipmentId == gateway.equipmentId}
								merchantGateway={merchantGateway}
								onChange={this.onChange}
								createNewEquipment={this.props.createNewEquipment}
								openCloseModal={this.props.openCloseModal}
								gatewaySelect={this.gatewaySelect}
								downloadPrefilledGatewaySetupForm={this.props.downloadPrefilledGatewaySetupForm}
							/>
						</div>
					);
				})}
			</div>
		);
	}
}

export default GatewayListComponent;
