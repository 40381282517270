import React from 'react';
import propTypes from 'prop-types';
import { UserAccountPanel } from '../user-account-panel';
import { Dropin } from '../../../components/Dropin';

export default function Header({ className }) {
	return (
		<header className={className || 'header'}>
			<div className="header__menu">
				<Dropin />
				<UserAccountPanel />
			</div>
		</header>
	);
}

Header.propTypes = {
	className: propTypes.string,
};
