import React, { Component } from 'react';
import { startCase, map, isEmpty, includes, every } from 'lodash';
import PropTypes from 'prop-types';

export class HardwareSidebarComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			activeKeys: [],
			categories: {
				all: '',
				terminals: 'terminal',
				pinPads: 'pinpad',
				cardReaders: 'cardreader',
				clover: 'clover',
				checkScanner: 'checkscanner',
			},
		};
	}

	onHover = (activeKeys = []) => () => this.setState({ activeKeys });

	getStyles = (activeKeys, categories, isHover) => {
		let styles = 'tabs--vertical__item__link';

		if (every(activeKeys, key => includes(categories, key)) || (!activeKeys && !categories)) {
			styles += ' is-active';
		}
		if (isHover) {
			styles += ' nav--tertiary__link--hover';
		}
		return styles;
	};

	renderSubCategories = categoryKey => {
		const { optionsList, activeCategory, activeSubcategory } = this.props;
		const { activeKeys } = this.state;
		const options = optionsList[categoryKey];

		return (
			!isEmpty(options) && (
				<div className="tabs--vertical__wrapper__subcategory">
					<div className="tabs--vertical__wrapper">
						<ul className="tabs--vertical">
							{map(options, (brands, subcategoryKey) => (
								<li
									key={subcategoryKey}
									onMouseEnter={this.onHover([categoryKey, `${categoryKey}.${subcategoryKey}`])}
									onMouseLeave={this.onHover([categoryKey])}
									className="tabs--vertical__item"
								>
									<button
										className={this.getStyles(
											[activeCategory, `${activeCategory}.${activeSubcategory}`],
											[categoryKey, `${categoryKey}.${subcategoryKey}`]
										)}
										onClick={this.props.onClick({
											category: categoryKey,
											subcategory: subcategoryKey,
										})}
									>
										{startCase(subcategoryKey)}
										<i className="icon icon--sml icon--chevron--right"></i>
									</button>
									{includes(activeKeys, `${categoryKey}.${subcategoryKey}`) &&
										this.renderBrands(categoryKey, subcategoryKey, brands)}
								</li>
							))}
						</ul>
					</div>
				</div>
			)
		);
	};

	renderBrands = (categoryKey, subcategoryKey, brands) =>
		!isEmpty(brands) && (
			<div className="tabs--vertical__wrapper__subcategory">
				<div className="tabs--vertical__wrapper">
					<ul className="tabs--vertical">
						{map(brands, brand => (
							<li key={brand} className="tabs--vertical__item">
								<button
									className={this.getStyles(
										[`${this.props.activeCategory}.${this.props.activeSubcategory}.${this.props.activeBrand}`],
										[`${categoryKey}.${subcategoryKey}.${brand}`]
									)}
									onClick={this.props.onClick({
										category: categoryKey,
										subcategory: subcategoryKey,
										brand,
									})}
								>
									{startCase(brand)}
								</button>
							</li>
						))}
					</ul>
				</div>
			</div>
		);

	render = () => {
		const { activeCategory, optionsList, applicableCategories, filterValue, handleFilter } = this.props;
		const { categories, activeKeys } = this.state;
		return (
			<nav className="tabs--vertical__wrapper">
				<input
					type="text"
					className="tabs--vertical__search"
					placeholder="Search..."
					name="searchInput"
					id="searchInput"
					value={filterValue}
					onChange={handleFilter}
					autoFocus
				/>
				<ul className="tabs--vertical">
					{map(
						categories,
						(value, key) =>
							applicableCategories.includes(value) && (
								<li
									key={key}
									className="tabs--vertical__item"
									onMouseEnter={this.onHover([value])}
									onMouseLeave={this.onHover()}
								>
									<button
										className={this.getStyles([activeCategory], [value], includes(activeKeys, value))}
										onClick={this.props.onClick({ category: value })}
									>
										{startCase(key)}
										{!isEmpty(optionsList[value]) && <i className="icon icon--sml icon--chevron--right"></i>}
									</button>
									{includes(activeKeys, value) && this.renderSubCategories(value)}
								</li>
							)
					)}
				</ul>
			</nav>
		);
	};
}

HardwareSidebarComponent.propTypes = {
	onClick: PropTypes.func.isRequired,
	optionsList: PropTypes.object.isRequired,
	activeCategory: PropTypes.string,
	activeSubcategory: PropTypes.string,
	activeBrand: PropTypes.string,
	filterValue: PropTypes.string,
	handleFilter: PropTypes.func.isRequired,
	applicableCategories: PropTypes.array.isRequired,
};
