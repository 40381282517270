import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { withLoader } from './../../common/components';
import { appService } from '../../services/appService';
import { default as NewNote } from './new-note';
import { filter, isEmpty } from 'lodash';

class NewNotePopup extends React.Component {
	constructor(props) {
		super(props);
		//console.log(this.props);
		this.state = {
			notesList: ['', ''],
			saveResults: null,
		};
	}

	onNoteChange = idx => e => {
		//console.log('updating');
		const notesArray = [...this.state.notesList];
		notesArray[idx] = e.target.value;
		this.setState({ notesList: notesArray });
	};

	onSave = () => {
		this.props.showLoader(true);
		const { appid } = this.props;
		const notes = this.state.notesList;
		let saveResults = [];

		let allNotes = notes.map((note, idx) => {
			if (note && note.trim().length > 0) {
				return appService
					.addNote(appid, note)
					.then(() => {
						saveResults.push({ index: idx, result: true });
					})
					.catch(err => {
						saveResults.push({ index: idx, result: false, msg: err });
					});
			}
			return Promise.resolve(null);
		});
		Promise.all(allNotes).then(() => {
			let errorMessage = '',
				successMessage = '';
			this.props.showLoader(false);
			if (saveResults.find(s => !s.result) && saveResults.find(s => s.result)) {
				errorMessage = 'Some notes could not be saved - ' + saveResults.find(s => !s.result).msg;
			} else if (saveResults[0]?.result) {
				successMessage = 'Saved successfully';
			} else errorMessage = 'Error saving notes = ' + saveResults[0]?.msg;
			this.setState({ errorMessage, successMessage });
		});
		//console.log(saveResults);
	};

	onDelete = idx => () => {
		//console.log('deleting');
		const notesArray = [...this.state.notesList];
		notesArray.splice(idx, 1);
		this.setState({ notesList: notesArray });
	};

	onAdd = () => {
		//console.log('adding');
		const notesArray = [...this.state.notesList];
		notesArray.push('');
		this.setState({ notesList: notesArray });
	};

	renderNotesList(notes) {
		return !notes
			? null
			: notes.map((note, idx) => {
					return (
						<NewNote key={idx} index={idx} noteText={note} onTextChange={this.onNoteChange} onDelete={this.onDelete} />
					);
			  });
	}

	render() {
		const { notesList, errorMessage, successMessage } = this.state;

		return (
			<React.Fragment>
				<div ref={this.detailsRef}>
					{errorMessage ? (
						<div className="modal__body">
							<div className="flex--primary flex--top flex--nowrap">
								<h4 className="spc--right--med">{errorMessage}</h4>
								<i className="spc--top--tny flex--no-shrink icon icon--med icon--regular--error"></i>
							</div>
						</div>
					) : null}

					{successMessage ? (
						<div className="modal__body">
							<div className="flex--primary flex--top flex--nowrap">
								<h4 className="spc--right--med">{successMessage}</h4>
								<i className="spc--top--tny flex--no-shrink icon icon--med icon--regular--check"></i>
							</div>
						</div>
					) : null}

					{successMessage || errorMessage ? <div className="modal__footer"></div> : null}

					{!errorMessage && !successMessage && notesList ? (
						<React.Fragment>
							<header className="modal__header">
								<h4>Add Note</h4>
							</header>
							<div className="modal__body">
								<div className="spc--bottom--med">{this.renderNotesList(notesList)}</div>
								<button className="btn btn--link" onClick={this.onAdd}>
									+ New Note
								</button>
							</div>
							<div className="modal__footer">
								{!this.props.hideViewAll && (
									<NavLink className="btn btn--med btn--tertiary" to={`/merchants/${this.props.appid}/notes`}>
										View All Notes
									</NavLink>
								)}
								<button
									onClick={this.onSave}
									className="btn btn--med btn--primary"
									disabled={this.props.isLoading || isEmpty(filter(notesList, Boolean))}
								>
									Save
								</button>
							</div>
						</React.Fragment>
					) : null}
				</div>
			</React.Fragment>
		);
	}
}
NewNotePopup.propTypes = {
	appid: PropTypes.string.isRequired,
	hideViewAll: PropTypes.bool,
	isLoading: PropTypes.bool,
	showLoader: PropTypes.func,
};

export default withLoader(NewNotePopup);
