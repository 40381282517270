import React, { Component, Fragment } from 'react';
import { func, object } from 'prop-types';

import { emailService } from '../../services';
import { emailTemplates } from '../../common/utilities';

class SendEmailPopup extends Component {
	constructor() {
		super();

		this.state = {
			isLoading: false,
			emailBody: '',
			isValidEmailBody: true,
			errorMessage: null,
		};
	}

	sendEmail = async () => {
		this.showLoader(true);
		try {
			const { closeModal, addNotification, templateParams } = this.props;

			if (!this.validateInput()) {
				return;
			}

			await emailService.send(emailTemplates.requestAccountUpdate, {
				...templateParams,
				emailBody: this.state.emailBody,
			});
			addNotification({
				message: 'Email sent successfully.',
				success: true,
			});
			closeModal();
		} catch (e) {
			this.setState({ errorMessage: e.message });
		}
		this.showLoader();
	};

	showLoader = (value = false) => this.setState({ isLoading: value });

	handleChange = ({ target: { value, name } }) => this.setState({ [name]: value }, this.validateInput);

	validateInput = () => {
		try {
			const newState = {
				isValidEmailBody: this.state.emailBody.length > 0,
				errorMessage: null,
			};

			this.setState(newState);

			if (!newState.isValidEmailBody) {
				throw Error('Message is required.');
			}

			return newState.isValidEmailBody;
		} catch (e) {
			this.setState({ errorMessage: e.message, isLoading: false });
		}
	};

	render = () => {
		const { isLoading, emailBody, errorMessage, isValidEmailBody } = this.state;

		return (
			<div className="modal">
				<div className="modal__header">
					<h4>Request Account Update</h4>
				</div>
				<div className="modal__body">
					{isLoading ? (
						<div className="spc--bottom--lrg">
							<div className="loader__spinner"></div>
						</div>
					) : (
						<Fragment>
							{errorMessage && <p className="type--validation spc--bottom--sml">{errorMessage}</p>}
							<div>
								<div className="form__group__header">
									<label htmlFor="emailBody" className="form__group__label">
										Message
									</label>
									<span className="form__group__required" data-tooltip="Required">
										*
									</span>
								</div>
								<textarea
									rows="5"
									cols="15"
									id="emailBody"
									name="emailBody"
									className={`input input--textarea${isValidEmailBody ? '' : ' is-invalid'}`}
									value={emailBody}
									onChange={this.handleChange}
								/>
							</div>
						</Fragment>
					)}
				</div>
				<div className="modal__footer">
					<button
						type="button"
						tabIndex="-1"
						className="btn btn--med btn--primary"
						onClick={this.sendEmail}
						disabled={isLoading}
					>
						Send Email
					</button>
				</div>
			</div>
		);
	};
}

SendEmailPopup.propTypes = {
	closeModal: func.isRequired,
	addNotification: func.isRequired,
	templateParams: object.isRequired,
};

export default SendEmailPopup;
