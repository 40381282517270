import { StringFilterComponent as StringFilter } from '../../../common/column-filters';
import { LinkFormatter, ProperCaseFormatter } from '../formatters';
import ExpandableComponent from '../../../common/formatters/Expandable';

export const EquipmentColumns = [
	{
		key: 'id',
		name: 'Equipment ID',
		sortable: true,
		hideable: false,
		visible: true,
		filterable: true,
		filterRenderer: StringFilter,
		isDefaultSorter: true,
		isAdvancedField: false,
		defaultSortDirection: 'DESC',
		initWidth: 130,
		resizable: true,
		formatter: ExpandableComponent,
		getRowMetaData: () => ({ className: 'btn btn--link' }),
	},
	{
		key: 'name',
		name: 'Equipment Name',
		sortable: true,
		hideable: true,
		visible: true,
		formatter: LinkFormatter,
		getRowMetaData: row => row,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 200,
		order: 1,
		resizable: true,
	},
	{
		key: 'category',
		name: 'Equipment Type',
		sortable: true,
		hideable: true,
		visible: true,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 150,
		resizable: true,
	},
	{
		key: 'purchaseType',
		name: 'Purchase Type',
		sortable: true,
		hideable: true,
		visible: true,
		formatter: ProperCaseFormatter,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 150,
		resizable: true,
	},
	{
		key: 'enteredDateDisplay',
		name: 'Entered Date',
		sortable: true,
		hideable: true,
		visible: true,
		//formatter: DisplayDateComponent,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 150,
		resizable: true,
	},
	{
		key: 'equipmentMid',
		name: 'Equipment MID',
		sortable: true,
		hideable: true,
		visible: true,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 100,
		resizable: true,
	},
	{
		key: 'serialNumber',
		name: 'Serial #',
		sortable: true,
		hideable: true,
		visible: true,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 100,
		resizable: true,
	},
	{
		key: 'deactivatedDateDisplay',
		name: 'Deactivated Date',
		sortable: true,
		hideable: true,
		visible: true,
		// formatter: DisplayDateComponent,
		filterable: true,
		filterRenderer: StringFilter,
		initWidth: 150,
		resizable: true,
	},
];
