import React from 'react';
import PropTypes from 'prop-types';
import { defaultImplicitParse, defaultReactOutput } from 'simple-markdown';

class ValidationError extends React.Component {
	render() {
		const { errorListPaths, errorList } = this.props;
		return (
			<React.Fragment>
				{errorListPaths.length ? (
					<ul className="type--validation__wrapper spc--bottom--med">
						{errorList.map(elem => {
							return (
								<li key={elem.path} className="type--validation">
									<button
										onClick={() => {
											let elemId = elem.path;
											this.props.scrollTo(elemId);
											this.props.focusField(elemId);
										}}
									>
										{defaultReactOutput(defaultImplicitParse(elem.message))}
									</button>
								</li>
							);
						})}
					</ul>
				) : null}
			</React.Fragment>
		);
	}
}

ValidationError.propTypes = {
	errorListPaths: PropTypes.array.isRequired,
	errorList: PropTypes.array.isRequired,
	scrollTo: PropTypes.func.isRequired,
	focusField: PropTypes.func.isRequired,
};

export default ValidationError;
