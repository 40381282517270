import React from 'react';
import PropTypes from 'prop-types';
import { find, sumBy, cloneDeep, map, some, each, includes, toLower, first, isEqual } from 'lodash';

import { HardwareSidebarComponent } from './hardware-side-bar';
import HardwareListItemComponent from './hardware-list-item';
import HardwareCard from './components/HardwareCard';
import { renderIf } from '../../common/utilities';

class HardwareListComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			filteredList: props.hardwareList,
			subs: [],
			searchInput: '',
			filterSettings: {},
			categoryOptions: this.getCategoryOptions,
			categories: [''],
		};
		this.hardwareRefs = {};
	}

	componentDidMount() {
		this.initializeRefs(this.props.hardwareList);
		this.filterCategories(this.props.hardwareList);
	}

	componentDidUpdate(prevProps) {
		if (!isEqual(prevProps.hardwareList, this.props.hardwareList)) {
			this.initializeRefs(this.props.hardwareList);
		}
		if (prevProps.selectedEquipmentId !== this.props.selectedEquipmentId && prevProps.selectedEquipmentId !== null) {
			this.scrollToSelectedHardware(prevProps.selectedEquipmentId);
		}
	}

	initializeRefs = hardwareList => {
		hardwareList.forEach(hardware => {
			if (!this.hardwareRefs[hardware.equipmentId]) {
				this.hardwareRefs[hardware.equipmentId] = React.createRef();
			}
		});
	};
	filterCategories(hardwareList) {
		const categories = [''];
		hardwareList.forEach(hardware => {
			const category = toLower(hardware.category);
			if (!categories.includes(category)) {
				categories.push(category);
			}
		});
		this.setState({ categories });
	}

	get getCategoryOptions() {
		const { hardwareList } = this.props;
		const options = {};
		each(hardwareList, ({ category, subcategory, brand }) => {
			category = toLower(category);
			subcategory = toLower(subcategory);
			brand = toLower(brand);

			if (category && !options[category]) {
				options[category] = {};
			}
			if (subcategory && !options[category][subcategory]) {
				options[category][subcategory] = [];
			}

			if (brand && options[category][subcategory] && !includes(options[category][subcategory], brand)) {
				options[category][subcategory].push(brand);
			}
		});

		return options;
	}

	hardwareSelectToggle = equipmentId => e => {
		let hardware = cloneDeep(this.props.merchantEquipment.find((e, i) => e.equipmentId == equipmentId));
		if (!!hardware) {
			hardware.isSelected = !hardware.isSelected;

			if (!hardware.isSelected && hardware.accessories) {
				hardware.accessories = map(hardware.accessories, item => {
					return { ...item, isSelected: false, quantity: 0 };
				});
			}
		} else {
			hardware = this.props.createNewEquipment(equipmentId, true);
		}
		this.props.onChange(hardware);
	};

	onViewEquipmentDetail = equipmentId => e => {
		this.scrollToTop();
		this.props.onShowEquipment(equipmentId);
	};

	scrollToTop = () => {
		const topElement = document.querySelector('#topOfPage');

		if (topElement) {
			topElement.scrollTop = 0;
		}
	};

	scrollToSelectedHardware = equipmentId => {
		if (this.hardwareRefs[equipmentId]?.current) {
			this.hardwareRefs[equipmentId].current.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	};

	handleBackClick = () => {
		this.props.onShowEquipment(null);
	};

	submenuClick = (filters, clearOtherFilters = true) => () => {
		this.scrollToTop();
		this.onViewEquipmentDetail(null);
		this.setFilters(filters, clearOtherFilters);
		this.props.onShowEquipment();
	};

	setFilters = (filters, clearOtherFilters = false) => {
		const { hardwareList } = this.props;
		const { filterSettings } = this.state;

		let query = cloneDeep(filterSettings);
		let filteredList = [];
		let subs = [];

		if (clearOtherFilters) {
			query = {}; // reinitialize;
		}

		each(filters, (valueToFilter, fieldName) => {
			if (!!valueToFilter) {
				// for now putting single item in array, but might have case where we want multiple values possible
				query[fieldName] = [valueToFilter.toLowerCase()];
			} else if (query && query[fieldName]) {
				delete query[fieldName];
			}

			filteredList = hardwareList.filter(h => {
				for (let key in query) {
					if (h[key] === undefined || !some(query[key], value => h[key].toLowerCase().includes(value))) {
						return false;
					}
				}
				return true;
			});

			if (filteredList.length > 0 && query.category) {
				subs = [...new Set(filteredList.map(e => e.subcategory))];
			}
		});

		this.setState({ filterSettings: query, filteredList, subs });
	};

	handleChange = ({ target: { name, value } }) => {
		this.setState({ [name]: value }, () => this.setFilters({ name: value }, false));
	};

	getHardwarePrice = (hardware, isBillAgentPaymentSchedule, merchantHardware) => {
		const { fees: merchantFees } = merchantHardware || {};
		const { purchasePlans } = hardware || {};
		const oneTimeFee = purchasePlans?.[0]?.fees?.find(f => f.feeType === 'OneTimeFee');

		if (isBillAgentPaymentSchedule) {
			return merchantFees?.[0]?.agentCost || oneTimeFee?.agentCost;
		} else {
			return merchantFees?.[0]?.merchantPrice || oneTimeFee?.retailPrice;
		}
	};

	render() {
		let { filteredList, subs, searchInput, filterSettings, categoryOptions, categories } = this.state;
		const {
			physicalAddress,
			corporateAddress,
			hardwareList,
			merchantEquipment,
			selectedEquipmentId,
			downloadPrefilledAuthorizationForm,
			getAuthorizationFormName,
		} = this.props;

		return (
			<div className={`equipment__hardware${selectedEquipmentId ? ' equipment-details' : ''}`}>
				{!selectedEquipmentId && (
					<div className="equipment__hardware__navigation">
						<HardwareSidebarComponent
							onClick={this.submenuClick}
							optionsList={categoryOptions}
							activeCategory={first(filterSettings.category) || ''}
							activeSubcategory={first(filterSettings.subcategory) || ''}
							activeBrand={first(filterSettings.brand) || ''}
							applicableCategories={categories}
							handleFilter={this.handleChange}
							filterValue={searchInput}
						/>
					</div>
				)}
				{!selectedEquipmentId ? (
					<div>
						{renderIf(filteredList.length == 0)(
							<div className="notes notes--default spc--bottom--lrg">No equipment found for the current filter</div>
						)}
						{subs.length > 0
							? subs.map((sub, i) => {
									return (
										<div key={sub} className="spc--bottom--lrg">
											<h5 onClick={this.submenuClick({ subcategory: sub }, false)}>{sub}</h5>
										</div>
									);
							  })
							: null}

						<div className="equipment__hardware__list">
							{filteredList.map(hardware => {
								let merchantHardware = merchantEquipment.find((e, i) => e.equipmentId == hardware.equipmentId);
								const isBillAgentPaymentSchedule =
									merchantHardware && toLower(merchantHardware.paymentSchedule) === 'billagent';
								let accessoriesTotal = 0;
								const outOfStock = hardware.outOfStock;

								if (merchantHardware) {
									accessoriesTotal = sumBy(merchantHardware.accessories, ({ isSelected, quantity, fees }) => {
										if (isSelected && fees) {
											const fee = find(fees, { feeType: 'OneTimeFee' });
											return (
												(isBillAgentPaymentSchedule ? fee.agentCost : fee.merchantPrice || fee.retailPrice) * quantity
											);
										}
										return 0;
									});
								}

								return (
									<HardwareCard
										ref={this.hardwareRefs[hardware.equipmentId]}
										key={hardware.equipmentId}
										hardware={hardware}
										price={this.getHardwarePrice(hardware, isBillAgentPaymentSchedule, merchantHardware)}
										merchantHardware={merchantHardware}
										accessoriesTotal={accessoriesTotal}
										outOfStock={outOfStock}
										hardwareSelectToggle={this.hardwareSelectToggle}
										onViewEquipmentDetail={this.onViewEquipmentDetail}
									/>
								);
							})}
						</div>
					</div>
				) : (
					// Detail view
					<HardwareListItemComponent
						hardware={hardwareList.filter(h => h.equipmentId == selectedEquipmentId)[0]}
						merchantHardware={merchantEquipment.find((e, i) => e.equipmentId == selectedEquipmentId)}
						physicalAddress={physicalAddress}
						corporateAddress={corporateAddress}
						onChange={this.props.onChange}
						createNewEquipment={this.props.createNewEquipment}
						handleBackClick={this.handleBackClick}
						downloadPrefilledAuthorizationForm={downloadPrefilledAuthorizationForm}
						getAuthorizationFormName={getAuthorizationFormName}
						optionIsAvailableForRules={this.props.optionIsAvailableForRules}
					/>
				)}
			</div>
		);
	}
}
HardwareListComponent.propTypes = {
	hardwareList: PropTypes.array.isRequired,
	merchantEquipment: PropTypes.array.isRequired,
	selectedEquipmentId: PropTypes.number,
	onChange: PropTypes.func.isRequired,
	onShowEquipment: PropTypes.func.isRequired,
	createNewEquipment: PropTypes.func.isRequired,
	downloadPrefilledAuthorizationForm: PropTypes.func.isRequired,
	getAuthorizationFormName: PropTypes.func.isRequired,
	physicalAddress: PropTypes.object,
	corporateAddress: PropTypes.object,
	optionIsAvailableForRules: PropTypes.func.isRequired,
};

export default HardwareListComponent;
