import React, { Component } from 'react';
import PropTypes from 'prop-types';

class AddressSelectionComponent extends Component {
	get addressValue() {
		return this.props.filter.values.address;
	}

	filterRemoved = () => {
		this.props.onFilterRemoved({
			id: 'address',
			emptyValue: null,
		});
	};

	render() {
		return (
			<div className="filter__toolbar__selected-filter">
				Address: <span className="type--wgt--medium">{this.addressValue}</span>
				<button onClick={this.filterRemoved} className="btn btn--link datatooltip--auto">
					<i className="icon icon--tny icon--close--text"></i>
				</button>
			</div>
		);
	}
}

AddressSelectionComponent.propTypes = {
	filter: PropTypes.object.isRequired,
	onFilterRemoved: PropTypes.func.isRequired,
};

export default AddressSelectionComponent;
