import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

const IGNORE_CLASSNAMES = ['ReactModal__Overlay', 'grid__multiselect__item'];

const OutsideClick = ({ children, action, className, customIgnoreClassNames = [] }) => {
	const wrapperRef = useRef(null);

	const handleClickOutside = event => {
		if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
			let currentNode = event.target;
			const mergedIgnoreClassNames = [...IGNORE_CLASSNAMES, ...customIgnoreClassNames];
			const isIgnoredClass = node => mergedIgnoreClassNames.some(className => node.classList.contains(className));
			while (currentNode) {
				if (isIgnoredClass(currentNode)) {
					return;
				}
				currentNode = currentNode.parentElement;
			}
			action(event);
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	return (
		<div className={className} ref={wrapperRef}>
			{children}
		</div>
	);
};

OutsideClick.propTypes = {
	children: PropTypes.any.isRequired,
	action: PropTypes.func,
	className: PropTypes.string,
	customIgnoreClassNames: PropTypes.array,
};

export default OutsideClick;
