import React, { Fragment } from 'react';
import { noop } from 'lodash';
import { DropdownContainer } from '../../common/components/dropdown-container';

class AmexRates extends React.Component {
	renderFakeInput = ({ name, value, shouldRenderInput = () => true }, index) =>
		shouldRenderInput() && (
			<div key={`${name}.${value}`} className="col col-sml-12 col-med-6 form__group">
				<div key={`${name}.${index}`} className="form__group__header">
					<label className="form__group__label">{name} </label>
				</div>
				<div className="input input--med input--disabled">{value}</div>
			</div>
		);

	renderESA = (isNew, amexDetails) => {
		return (
			<div className="row">
				{this.renderFakeInput({
					name: 'Amex ESA number',
					value: amexDetails.esaNumber,
				})}
				{isNew && (
					<Fragment>
						{this.renderFakeInput({
							name: 'ESA Annual Volume',
							value: amexDetails.esaAnnualVolume,
						})}
						{this.renderFakeInput({
							name: 'ESA Average Ticket',
							value: amexDetails.esaAverageTicket,
						})}
						{this.renderFakeInput({
							name: 'ESA High Ticket',
							value: amexDetails.esaHighTicket,
						})}
						<div className="col col-sml-12 col-med-6 form__group">
							<input
								className="input--check"
								type="checkbox"
								id="amexDetails_hasReversePip"
								name="amexDetails_hasReversePip"
								checked={amexDetails.hasReversePip}
								value={amexDetails.hasReversePip}
								onChange={noop}
							/>
							<label htmlFor="amexDetails_hasReversePip">Set Up Merchant with Pip</label>
						</div>
					</Fragment>
				)}
			</div>
		);
	};

	renderAmex = amexDetails => {
		return (
			<Fragment>
				<DropdownContainer
					labelClass="form__group__label"
					selectClass="input input--med input--select"
					wrapperClass="form__group col col-sml-12 col-med-6"
					labelText="Amex Entitlement"
					divClass="row"
					selectName="amexDetails_status"
					value={amexDetails.status}
					onChange={this.handleChange}
					options={[
						{
							value: 'New',
							text: 'New AMEX',
							children: this.renderESA(true, amexDetails),
						},
						{
							value: 'Existing',
							text: 'Existing AMEX',
							children: this.renderESA(false, amexDetails),
						},
					]}
				></DropdownContainer>
				<div className="row">
					{this.renderFakeInput({
						name: 'Amex Trans/Auth Fee (if diff than Visa/MC/Disc)',
						value: amexDetails.transactionFee,
					})}
				</div>
			</Fragment>
		);
	};

	renderAmexEntitlement(amexDetails) {
		if (!amexDetails.status || amexDetails.status === 'None') return null;
		return (
			<DropdownContainer
				type="radio"
				selectName="amexDetails_program"
				value={amexDetails.program}
				hideSelect={true}
				labelText=""
				divClass="spc--top--lrg"
				wrapperClass="1"
				selectClass=""
				options={[
					{
						value: 'OptBlue',
						text: 'Opt Blue',
						children: (
							<React.Fragment>
								{this.props.renderRateStructure('amexDetails', 'AMEX', amexDetails, undefined, this.props.tooltip)}
								<div className="row">
									{this.renderFakeInput({
										name: 'Amex Trans/Auth Fee (if diff than Visa/MC/Disc)',
										value: amexDetails.transactionFee,
									})}
								</div>
								<div className="row">
									{this.renderFakeInput({
										name: 'Opt-Blue Average Annual Volume',
										value: amexDetails.yearlyAmexVolume,
									})}
								</div>
							</React.Fragment>
						),
					},
					{ value: 'ESA', text: 'Amex ESA', children: this.renderAmex(amexDetails) },
				]}
			/>
		);
	}

	render() {
		const { amexDetails } = this.props;
		return (
			<div>
				<p className="type--p2 type--p2--medium spc--bottom--med">Amex Entitlement:</p>
				<div className="flex--primary flex--gap--xlrg spc--bottom--lrg">
					<div className="spc--right--med">
						<input
							className="input--radio"
							type="radio"
							id="amexDetails_status_yes"
							name="amexDetails_status_yes"
							checked={amexDetails && amexDetails.status && amexDetails.status !== 'None'}
							disabled={true}
						/>
						<label htmlFor="amexDetails_status_yes">Yes</label>
					</div>
					<div>
						<input
							className="input--radio"
							type="radio"
							id="amexDetails_status_no"
							name="amexDetails_status_no"
							checked={amexDetails && amexDetails.status === 'None'}
							disabled={true}
						/>
						<label htmlFor="amexDetails_status_no">No</label>
					</div>
				</div>
				{this.renderAmexEntitlement(amexDetails)}
			</div>
		);
	}
}
export default AmexRates;
