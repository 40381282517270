import moment from 'moment';
import { toLower, trim, get, includes, sumBy, toNumber, some, each } from 'lodash';
import {
	url,
	incorrectCharacters,
	maxLength,
	digitLength,
	ascii,
	alphaOnly,
	phoneNumber,
	addressPOBox,
	ownershipTypeCorpName,
	email,
	MASKED_VALUE,
} from './validators';
import { addressValidation } from './address.validation';

const outputFormat = process.env.REACT_APP_DISPLAY_DATE_FORMAT;
const greaterThanZero = val => val > 0;
const date = val => moment(val, outputFormat, true).isValid() && dob(val);
const dob = val => moment(val, outputFormat).isAfter(moment(new Date(0, 0, 0, 0)));
const is18YearsOld = val =>
	moment(val, outputFormat).isBefore(moment().subtract(18, 'years')) ||
	moment(val, outputFormat).isSame(moment().subtract(18, 'years'));
const agentName = val => {
	if (!val) {
		return true;
	}

	return toLower(trim(val)) !== 'unknown';
};
const required = value => !!value;
const ownerPercent = value => {
	return parseFloat(value) > 24.99 && parseFloat(value) < 100.01;
};
const notOtherOrUnknown = val => {
	return toLower(trim(val)) !== 'other' && toLower(trim(val)) !== 'unknown';
};
const websiteRequired = (val, ctx) => !ctx.isECommerce || val !== '';
const requiredIfMaskedValueChanged = val => (val === MASKED_VALUE ? true : required(val));
const triggerValidationIfMaskedChanged = validationMethod => (val, ctx, path) =>
	val === MASKED_VALUE ? true : validationMethod(val, ctx, path);
const containsSpaces = val => val.indexOf(' ') === -1;
const isNotGovernmentOrPublic = (_, ctx) => !includes(['GovernmentEntity', 'PubliclyTraded'], ctx.ownershipType);
const validateIf = (validator, predicate) => (val, ctx) => (predicate(val, ctx) ? validator(val, ctx) : true);
const propertiesValuesEqual = (firstPropertyPath, secondPropertyPath) => (val, ctx) => {
	const firstValue = get(ctx, firstPropertyPath, null);
	const secondValue = get(ctx, secondPropertyPath, null);
	return firstValue === secondValue;
};
const sumTotal = (sumTotal, properties) => (val, ctx) => {
	return sumBy(properties, propertyName => toNumber(get(ctx, propertyName, 0)) || 0) === sumTotal;
};
const numberOfOwners = (val, ctx) => {
	const ownershipType = get(ctx, 'ownershipType', null);
	return !(ownershipType === 'SoleProprietor' && val != 1);
};
const totalOwnersPercentage = (val, ctx) => {
	const signerInformationList = get(ctx, 'signerInformationList', []);
	const totalMinorOwnershipPercent = parseFloat(get(ctx, 'totalMinorOwnershipPercent')) || 0;
	const ownershipPercentageSum =
		parseFloat(sumBy(signerInformationList, value => parseFloat(get(value, 'ownershipPercentage')) || 0)) || 0;
	let total = parseFloat(totalMinorOwnershipPercent + ownershipPercentageSum).toFixed(0);
	return !(some(signerInformationList, item => item.ownershipPercentage) && total != 100);
};
const bankInfoValidated = (val, ctx) => {
	return ctx.isIntegratedWithBank || val !== '';
};
const tinCheck = val => {
	return val !== false;
};

const verifyAccount = val => {
	return val !== 'E';
};
function goAddressValidation(prefix) {
	const validation = addressValidation(prefix, 64, 64);
	each(validation, field => {
		field.required = true;
	});
	const { length, ...zipValidators } = validation.zip.use;
	return {
		...validation,
		zip: {
			...validation.zip,
			use: { ...zipValidators, digitLength: digitLength(5) },
			message: {
				...validation.zip.message,
				digitLength: `[**${prefix}Zip Code**](javascript:void) is invalid`,
			},
		},
	};
}

export const goPlusMerchantMpaBusinessInfoFormTemplate = {
	agentName: {
		type: String,
		required: true,
		use: { agentName },
		message: {
			required: '[**Fidelity Sales Rep Name**](javascript:void) is required',
			agentName: '[**Fidelity Sales Rep Name**](javascript:void) is invalid',
		},
	},
	corporateName: {
		type: String,
		required: true,
		use: { ascii },
		message: {
			required: '[**Company Legal Name**](javascript:void) is required',
			ascii: '[**Company Legal Name**](javascript:void) contains invalid characters',
		},
	},
	dbaName: {
		type: String,
		required: true,
		use: { ascii },
		message: {
			required: '[**DBA**](javascript:void) is required',
			ascii: '[**DBA**](javascript:void) contains invalid characters',
		},
	},
	taxId: {
		type: String,
		required: true,
		use: { taxid: digitLength(9) },
		message: {
			required: '[**EIN**](javascript:void) is required',
			taxid: '[**EIN**](javascript:void) is invalid',
		},
	},
	website: {
		type: String,
		use: { url, incorrectCharacters, websiteRequired, length: maxLength(64) },
		message: {
			url: '[**Website**](javascript:void) is invalid',
			incorrectCharacters:
				"The [**url**](javascript:void) contains one of the following sets of characters which are not allowed; 'google', 'index', 'gmail', or 'yahoo'.",
			websiteRequired: '[**Website**](javascript:void) is required',
			length: '[**Website**](javascript:void) is invalid. Maximum number of characters allowed is 64.',
		},
	},
	businessStartDate: {
		type: String,
		required: true,
		use: { date },
		message: {
			required: '[**Business Start Date**](javascript:void) is required',
			date: '[**Business Start Date**](javascript:void) is invalid',
		},
	},
	businessPhone: {
		type: String,
		use: { phoneNumber },
		message: {
			requiredIfMaskedValueChanged: '[**Primary Business Phone number**](javascript:void) is required',
			phoneNumber: '[**Primary Business Phone number**](javascript:void) is invalid',
		},
	},
	ownershipType: {
		type: String,
		required: true,
		use: { notOtherOrUnknown, ownershipTypeCorpName: ownershipTypeCorpName('SoleProprietor') },
		message: {
			required: '[**Ownership Type**](javascript:void) is required',
			notOtherOrUnknown: '[**Ownership Type**](javascript:void) is required',
			ownershipTypeCorpName:
				'[**Ownership Type**](javascript:void) Sole Proprietor is not allowed on Incorporated Business or Limited Liability Company',
		},
	},
	businessEmail: {
		type: String,
		required: true,
		use: { email: email(false) },
		message: {
			required: '[**Business Email**](javascript:void) is required',
			email: '[**Business Email**](javascript:void) is invalid',
		},
	},
	businessAddress: {
		...goAddressValidation('Business '),
		streetAddress: {
			use: { required, ascii, length: maxLength(64), addressPOBox },
			type: String,
			message: {
				required: '[**Business Address**](javascript:void) is required',
				ascii: `[**Business Address**](javascript:void) contains invalid characters`,
				length: `[**Business Address**](javascript:void) is invalid. Maximum number of characters allowed is 64.`,
				addressPOBox: `[**Business Address**](javascript:void) cannot be a PO Box address`,
			},
		},
	},
	mailingAddress: goAddressValidation('Mailing '),
	productSold: {
		type: String,
		required: true,
		use: { length: maxLength(64), ascii },
		message: {
			required: '[**Products/Services Sold**](javascript:void) is required',
			length: '[**Products/Services Sold**](javascript:void) is invalid. Maximum number of characters allowed is 64.',
			ascii: '[**Products/Services Sold**](javascript:void) contains invalid characters',
		},
	},
};

export const goPlusMerchantMpaBusinessInfoAPIFormTemplate = {
	isValidTin: {
		type: Boolean,
		use: { tinCheck },
		message: {
			tinCheck:
				'According to the IRS, the Legal Corp Name and Tax ID/EIN provided does not match. Please check your SS4 document and update the information accordingly.',
		},
	},
};

export const goPlusMerchantMpaBankingInfoApiFormTemplate = {
	accountVerificationStatus: {
		type: String,
		use: { verifyAccount },
		message: {
			verifyAccount: 'Account and/or Routing Number is invalid. Please re-enter information and resubmit.',
		},
	},
};

export const goPlusMerchantMpaBankingInfoFormTemplate = {
	bankingInformation: {
		bankName: {
			type: String,
			use: { bankInfoValidated, ascii },
			message: {
				bankInfoValidated: '[**Bank Name**](javascript:void) is required',
				ascii: '[**Bank Name**](javascript:void) contains invalid characters',
			},
		},
		accountType: {
			type: String,
			use: { bankInfoValidated },
			message: {
				bankInfoValidated: '[**Account Type**](javascript:void) is required',
			},
		},
		routingNumber: {
			type: String,
			use: {
				bankInfoValidated: triggerValidationIfMaskedChanged(bankInfoValidated),
				routingNumber: triggerValidationIfMaskedChanged(digitLength(9)),
			},
			message: {
				bankInfoValidated: '[**Routing Number**](javascript:void) is required',
				routingNumber: '[**Routing Number**](javascript:void) is invalid',
			},
		},
		accountNumber: {
			type: String,
			use: {
				bankInfoValidated: triggerValidationIfMaskedChanged(bankInfoValidated),
				accountNumber: triggerValidationIfMaskedChanged(digitLength(6, 17)),
			},
			message: {
				bankInfoValidated: '[**Account Number**](javascript:void) is required',
				accountNumber: '[**Account Number**](javascript:void) is invalid',
			},
		},
		confirmAccountNumber: {
			type: String,
			use: {
				bankInfoValidated: triggerValidationIfMaskedChanged(bankInfoValidated),
				accountNumbersEqual: propertiesValuesEqual(
					'bankingInformation.accountNumber',
					'bankingInformation.confirmAccountNumber'
				),
			},
			message: {
				bankInfoValidated: '[**Confirm Account Number**](javascript:void) is required',
				accountNumbersEqual: '[**Account Numbers**](javascript:void) for primary account must match',
			},
		},
	},
};

export const goPlusMerchantMpaSignerAndProcessingInfoFormTemplate = {
	processingInformation: {
		percentCardPresent: {
			type: String,
			required: true,
			message: '[**Percent of Card Present Sales (%)**](javascript:void) is required',
		},
		percentCardNotPresent: {
			type: String,
			required: true,
			message: '[**Percent of Card-Not-Present Sales (%)**](javascript:void) is required',
		},
		averageTicket: {
			type: Number,
			use: { greaterThanZero },
			message: {
				greaterThanZero: '[**Average Ticket**](javascript:void) is required',
			},
		},
		highTicket: {
			type: Number,
			use: { greaterThanZero },
			message: {
				greaterThanZero: '[**High Ticket**](javascript:void) is required',
			},
		},
		monthlySalesVolume: {
			type: Number,
			use: { greaterThanZero },
			message: {
				greaterThanZero: '[**Monthly Sales Volume**](javascript:void) is required',
			},
		},
	},
	totalSales: {
		type: Number,
		required: true,
		use: {
			totalSale: sumTotal(100, [
				'processingInformation.percentCardPresent',
				'processingInformation.percentCardNotPresent',
			]),
		},
		message: {
			totalSale: '[**Total Card Sales**](javascript:void) must equal 100%',
		},
	},
	numberOfOwners: {
		type: Number,
		required: true,
		use: {
			numberOfOwners: numberOfOwners,
		},
		message: {
			numberOfOwners: '[**Sole Proprietor**](javascript:void) ownership type requires only 1 owner or principal',
		},
	},
	totalOwnershipPercentage: {
		type: Number,
		required: true,
		use: {
			totalOwnersPercentage: totalOwnersPercentage,
		},
		message: {
			totalOwnersPercentage: '[**Total Ownership Percentage**](javascript:void) must equal 100%',
		},
	},
	downloadUrl: {
		type: String,
		required: true,
		message: {
			required:
				'[**In order to proceed, you must check the box acknowledging that you have read, understand and agree to the full terms and conditions set forth in the merchant agreement.**](javascript:void)',
		},
	},
	signerInformationList: [
		{
			firstName: {
				type: String,
				use: { containsSpaces, length: maxLength(24), alphaOnly },
				required: true,
				message: {
					containsSpaces: '[**First Name**](javascript:void) cannot contain spaces',
					required: '[**Signer First Name**](javascript:void) is required',
					length: '[**Signer First Name**](javascript:void) is invalid. Maximum number of characters allowed is 24.',
					alphaOnly: '[**Signer First Name**](javascript:void) is invalid. Only alphabetic characters allowed.',
				},
			},
			lastName: {
				type: String,
				use: { containsSpaces, length: maxLength(24), alphaOnly },
				required: true,
				message: {
					containsSpaces: '[**Last Name**](javascript:void) cannot contain spaces',
					required: '[**Signer Last Name**](javascript:void) is required',
					length: '[**Signer Last Name**](javascript:void) is invalid. Maximum number of characters allowed is 24.',
					alphaOnly: '[**Signer First Name**](javascript:void) is invalid. Only alphabetic characters allowed.',
				},
			},
			ownershipPercentage: {
				type: String,
				required: true,
				use: { ownerPercent },
				message: {
					required: '[**Ownership Percentage**](javascript:void) is required',
					ownerPercent: '[**Ownership Percentage**](javascript:void) must be at least 25%',
				},
			},
			title: {
				type: String,
				required: true,
				message: {
					required: '[**Signer Title**](javascript:void) is required',
				},
			},
			ssn: {
				type: String,
				use: {
					ssn: triggerValidationIfMaskedChanged(validateIf(digitLength(9), isNotGovernmentOrPublic)),
					required: triggerValidationIfMaskedChanged(validateIf(required, isNotGovernmentOrPublic)),
					ascii: triggerValidationIfMaskedChanged(validateIf(ascii, isNotGovernmentOrPublic)),
				},
				message: {
					required: '[**Signer SSN**](javascript:void) is required',
					ssn: '[**Signer SSN**](javascript:void) is invalid',
					ascii: '[**Signer SSN**](javascript:void) contains invalid characters',
				},
			},
			dateOfBirth: {
				type: String,
				required: true,
				use: {
					dob: triggerValidationIfMaskedChanged(validateIf(dob, isNotGovernmentOrPublic)),
					is18YearsOld: triggerValidationIfMaskedChanged(validateIf(is18YearsOld, isNotGovernmentOrPublic)),
					required: triggerValidationIfMaskedChanged(validateIf(required, isNotGovernmentOrPublic)),
				},
				message: {
					required: '[**Date of Birth**](javascript:void) is required',
					dob: '[**Date of Birth**](javascript:void) is invalid',
					is18YearsOld: '[**Date of Birth**](javascript:void) must be at least 18 years old',
				},
			},
			cellPhone: {
				type: String,
				required: true,
				use: { phoneNumber },
				message: {
					required: '[**Signer Cell Phone**](javascript:void) is required',
					phoneNumber: '[**Signer Cell Phone**](javascript:void) is invalid',
				},
			},
			address: {
				...goAddressValidation(''),
				streetAddress: {
					use: { required, ascii, length: maxLength(64), addressPOBox },
					type: String,
					message: {
						required: '[**Home Address**](javascript:void) is required',
						ascii: `[**Home Address**](javascript:void) contains invalid characters`,
						length: `[**Home Address**](javascript:void) is invalid. Maximum number of characters allowed is 64.`,
						addressPOBox: `[**Home Address**](javascript:void) cannot be a PO Box address`,
					},
				},
			},
		},
	],
};
