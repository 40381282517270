import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isObject, map } from 'lodash';

class FormErrorsComponent extends Component {
	renderError = (error, key) => {
		if (isObject(error)) {
			return map(error, (subError, subKey) => this.renderError(subError, key ? `${key}.${subKey}` : subKey));
		}
		if (error) {
			return (
				<li className="type--validation" key={key}>
					{error}
				</li>
			);
		}
		return null;
	};

	render = () => {
		const { errors } = this.props;
		return <ul className="type--validation__wrapper">{this.renderError(errors)}</ul>;
	};
}

FormErrorsComponent.propTypes = {
	errors: PropTypes.object,
};

export default FormErrorsComponent;
