import React, { Component } from 'react';
import PropTypes from 'prop-types';

class CitySelectionComponent extends Component {
	get cityValue() {
		return this.props.filter.values.city;
	}

	filterRemoved = e => {
		this.props.onFilterRemoved({
			id: 'city',
			emptyValue: null,
		});
	};

	render() {
		return (
			<div className="filter__toolbar__selected-filter">
				City: <span className="type--wgt--medium">{this.cityValue}</span>
				<button onClick={this.filterRemoved} className="btn btn--link datatooltip--auto">
					<i className="icon icon--tny icon--close--text"></i>
				</button>
			</div>
		);
	}
}

CitySelectionComponent.propTypes = {
	filter: PropTypes.object.isRequired,
	onFilterRemoved: PropTypes.func.isRequired,
};

export default CitySelectionComponent;
