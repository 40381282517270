import React, { Fragment, createRef } from 'react';
import PropTypes from 'prop-types';
import AchBaseClass from './AchBaseClass';
import Notification from '../../common/components/notifications/notifications';
import { appService } from '../../services/appService';
import { ModalWrapper, modalNames } from '../../common/components/modal-wrapper';
import withLoader from '../../common/components/loader/loader-hoc';
import withBlock from '../../common/components/block/block-hoc';
import { Toggle, ToggleContainer } from '../../common/components/toggle';
import AppSummary from './AppSummary';
import { defaultInvalidDates, openFile, OutsideClick } from '../../common/utilities';
import { get, cloneDeep, map, startCase, toLower, some, noop, trim, every } from 'lodash';
import { defaultImplicitParse, defaultReactOutput } from 'simple-markdown';
import { AchInvalidTemplate, AchRequiredTemplate } from '../../validation';
import Schema from '../../validation/BaseSchema';
import { withCancelable } from '../../common/components/cancelable';
import { YesNoRadio } from '../../common/components/yes-no-radio';
import { principalService } from '../../services';

const requiredFields = [
	'businessInformation.businessPhone',
	'businessInformation.businessAddress.streetAddress',
	'businessInformation.businessAddress.city',
	'businessInformation.businessAddress.state',
	'businessInformation.businessAddress.zip',
	'businessInformation.businessEmail',
	'businessInformation.website',
	'businessInformation.ownershipType',
	'businessStartDate',
	'corporateName',
	'corporateAddress.streetAddress',
	'corporateAddress.city',
	'corporateAddress.state',
	'corporateAddress.zip',
	'signerInformation.address.streetAddress',
	'signerInformation.address.city',
	'signerInformation.address.state',
	'signerInformation.address.zip',
	'signerInformation.firstName',
	'signerInformation.lastName',
	'signerInformation.phoneNumber',
	'signerInformation.socialSecurityNumber',
	'signerInformation.dateOfBirth',
	'signerInformation.ownershipPercentage',
	'signerInformation.email',
	'signerInformation.title',
	'IdentificationNumber',
	'contactPhoneNumber',
	'businessContactName',
	'IdentificationType',
	'IdentificationIssueDate',
	'IdentificationExpirationDate',
	'dba',
	'goodsOrServicesDescription',
	'taxID',
	'bankAccountInformation.bankName',
	'bankAccountInformation.accountNumber',
	'bankAccountInformation.routingNumber',
	'generalTransactionInfo.maxSingle',
	'generalTransactionInfo.maxDaily',
	'generalTransactionInfo.maxDailyAmount',
	'generalTransactionInfo.maxPeriod',
	'generalTransactionInfo.maxPeriodAmount',
	'generalTransactionInfo.averageTransactionAmount',
];

const conditionalRequiredFields = {
	enableSecondaryBankAccountInfo: {
		condition: state => state['enableSecondaryBankAccountInfo'],
		fields: [
			'bankAccountInformation.secondaryBankName',
			'bankAccountInformation.secondaryAccountNumber',
			'bankAccountInformation.secondaryRoutingNumber',
		],
	},
	alternateBankAccountInfo: {
		condition: state => state['enableAlternativeBankAccountInfo'],
		fields: [
			'bankAccountInformation.alternateBankName',
			'bankAccountInformation.alternateAccountNumber',
			'bankAccountInformation.alternateRoutingNumber',
		],
	},
};

const requiredDocs = ['BankStatements', 'ProofOfAddress', 'VoidedCheck', 'DriversLicense', 'SignedMPA'];
const IGNORE_CLASS_NAMES = ['btn--action--secondary'];
class AchEappFormComponent extends AchBaseClass {
	constructor(props) {
		super(props, requiredFields, requiredDocs, conditionalRequiredFields);
		this.notificationRef = createRef();
		this.mpaRequiredSchema = new Schema(cloneDeep(AchRequiredTemplate), { strip: false, typecast: true });
		this.mpaInvalidSchema = new Schema(cloneDeep(AchInvalidTemplate), { strip: false, typecast: true });
		this.state = cloneDeep(this.initialState);
	}

	validateField = () => '';

	scrollToTop = () => {
		const topElement = document.querySelector('#topOfPage');

		if (topElement) {
			topElement.scrollTop = 0;
		}
	};

	componentDidMount() {
		this.fetchAndSetData();
		this.scrollToTop();
	}

	fetchData = async () => {
		const { appid } = this.props.match ? this.props.match.params : '';
		return appService.getEApp(appid);
	};

	fetchAndSetData = async () => {
		const { appid } = this.props.match ? this.props.match.params : '';
		const { showLoader, makePendingRequest } = this.props;
		try {
			showLoader(true);
			const mpa = await makePendingRequest(
				this.fetchData()
					.then(data => data)
					.catch(err => {
						this.setState({ errorMessage: 'An error occurred: ' + err, isNewError: true });
					}),
				'getEApp'
			);
			const secondaryBankInfo = [
				mpa.bankAccountInformation.secondaryBankName,
				mpa.bankAccountInformation.secondaryAccountNumber,
				mpa.bankAccountInformation.secondaryRoutingNumber,
			];
			const alternateBankInfo = [
				mpa.bankAccountInformation.alternateBankName,
				mpa.bankAccountInformation.alternateAccountNumber,
				mpa.bankAccountInformation.alternateRoutingNumber,
			];
			const enableSecondaryBankAccountInfo = some(secondaryBankInfo, val => val);
			const enableAlternativeBankAccountInfo = some(alternateBankInfo, val => val);
			const sameAsBusiness = every(mpa.corporateAddress, (value, key) => {
				return (
					toLower(value) === toLower(mpa.businessInformation.businessAddress[key]) && value !== null && value !== ''
				);
			});
			this.setState(
				{
					enableSecondaryBankAccountInfo,
					enableAlternativeBankAccountInfo,
					sameAsBusiness,
					fields: defaultInvalidDates(mpa, ''),
					appId: appid,
				},
				this.createFormSnapshot
			);
			showLoader(false);
		} catch (err) {
			showLoader(false);
		}
	};

	save = async goToNextStep => {
		if (this.state.disableSave) {
			return;
		}
		this.setState({ disableSave: true });
		const { history } = this.props;
		const mpa = cloneDeep(this.state.fields);
		this.props.showLoader(true);
		if (this.isDirty) {
			appService
				.saveAchEApp(mpa)
				.then(() => {
					this.saveFiles().then(() => {
						this.props.showLoader(false);
						this.setState({ localFiles: {}, disableSave: false, errorMessage: '' }, async () => {
							await this.props.handleBlockChange(false);
							if (!goToNextStep) {
								return await this.fetchAndSetData();
							}
							history.push('/eapp/ach-fees/' + this.state.appId);
						});
					});
				})
				.catch(err => {
					this.handleError(err);
				});
		} else {
			this.props.showLoader(false);
			this.setState({ disableSave: false }, async () => {
				await this.props.handleBlockChange(false);
				if (!goToNextStep) return;
				history.push('/eapp/ach-fees/' + this.state.appId);
			});
		}
	};

	renderToggleableSection(title, component, tooltip = null) {
		return (
			<Toggle initialToggle={true}>
				{({ isToggled, handleToggle }) => {
					return (
						<Fragment>
							<button
								onKeyDown={e => this.onEnterKeyDownHandler(e, handleToggle)}
								onClick={handleToggle}
								className="card__header card__header--expandable"
							>
								<div className="flex--primary flex--gap--sml">
									<h5>{title}</h5>
									{tooltip && (
										<i className="icon icon--sml icon--regular--info datatooltip--200" data-tooltip={tooltip}></i>
									)}
								</div>
								<i className={`icon icon--lrg icon--chevron--${isToggled ? 'top' : 'right'}--primary`}></i>
							</button>
							{isToggled && component}
						</Fragment>
					);
				}}
			</Toggle>
		);
	}

	renderValidationInfo() {
		const { fields, disableSave, enableAlternativeBankAccountInfo, enableSecondaryBankAccountInfo } = this.state;
		const requiredErrorList = this.mpaRequiredSchema.validate(
			Object.assign({}, { ...fields, enableAlternativeBankAccountInfo, enableSecondaryBankAccountInfo })
		);
		const invalidErrorList = this.mpaInvalidSchema.validate(Object.assign({}, fields));
		if (invalidErrorList.length > 0) {
			if (!disableSave) this.setState({ disableSave: true });
		} else {
			if (disableSave) this.setState({ disableSave: false });
		}
		const requiredErrorListPaths = requiredErrorList.map(error => error.path);
		const invalidErrorListPaths = invalidErrorList.map(error => error.path);
		const incompleteDocs = this.validateDocuments();

		if (
			this.props.isLoading ||
			!(incompleteDocs.length || requiredErrorListPaths.length || invalidErrorListPaths.length)
		)
			return null;
		return (
			<React.Fragment>
				{invalidErrorListPaths.length > 0 && (
					<ul className="type--validation__wrapper spc--bottom--med">
						{map(invalidErrorList, elem => (
							<li key={elem.path} className="type--validation">
								<button
									onKeyDown={e => this.onEnterKeyDownHandler(e, () => this.onErrorClick(elem))}
									onClick={() => this.onErrorClick(elem)}
								>
									{defaultReactOutput(defaultImplicitParse(elem.message))}
								</button>
							</li>
						))}
					</ul>
				)}
				{(incompleteDocs.length > 0 || requiredErrorListPaths.length > 0) && (
					<div className="notes notes--warning flex--top spc--bottom--lrg">
						<i className="icon"></i>
						<div>
							<p className="type--p4 spc--bottom--med">
								The fields below are required for submitting the app; however, you can save the form without this
								information now and complete the form at a later time.
							</p>
							<ul className="type--validation__wrapper">
								{map(requiredErrorList, elem => (
									<li className="type--validation" key={elem.path}>
										<button
											onKeyDown={e => this.onEnterKeyDownHandler(e, () => this.onErrorClick(elem))}
											onClick={() => this.onErrorClick(elem)}
										>
											{defaultReactOutput(defaultImplicitParse(elem.message))}
										</button>
									</li>
								))}
								{map(incompleteDocs, (fileTag, index) => (
									<li className="type--validation" key={`${fileTag}.${index}`}>
										<button
											onKeyDown={e =>
												this.onEnterKeyDownHandler(e, () =>
													this.scrollTo(toLower(fileTag).replace(/[.]/g, '_') + '_div')
												)
											}
											onClick={() => this.scrollTo(toLower(fileTag).replace(/[.]/g, '_') + '_div')}
										>
											{fileTag == 'SignedMPA' ? 'Signed ACH Worksheet' : startCase(fileTag)} document
										</button>
									</li>
								))}
							</ul>
						</div>
					</div>
				)}
			</React.Fragment>
		);
	}

	renderFooter() {
		const { disableSave } = this.state;
		const { isLoading } = this.props;
		return (
			<React.Fragment>
				{this.renderValidationInfo()}
				<div className="leads__footer">
					<button
						className="btn btn--med btn--tertiary"
						disabled={disableSave || isLoading}
						onClick={() => this.save(false)}
					>
						Save
					</button>
					<button
						className="btn btn--med btn--primary"
						disabled={disableSave || isLoading}
						onClick={() => this.save(true)}
					>
						Save and Next Step
					</button>
				</div>
			</React.Fragment>
		);
	}

	renderUploadDocuments() {
		const {
			fields: { files },
		} = this.state;
		return (
			<Fragment>
				{files && this.getFilesForTag('SignedMPA').length > 0 ? (
					<div>
						<span className="type--p2">Signature Documents</span>
						<div className="card--no-expand">{this.renderFilesForTag(['SignedMPA'])}</div>
					</div>
				) : null}
				<div className="card__body">
					{this.renderUploadDocumentRow(
						'BankStatements',
						'Bank Statements',
						'Please include your three most recent bank statements',
						true,
						'bankstatements_div'
					)}
					{this.renderUploadDocumentRow('VoidedCheck', 'Voided Check', 'Or Bank Letter', true, 'voidedcheck_div')}
					{this.renderUploadDocumentRow(
						'ProofOfAddress',
						'Proof of Address',
						'Utility bill or Lease Agreement',
						true,
						'proofofaddress_div'
					)}
					{this.renderUploadDocumentRow('DriversLicense', 'Drivers License', null, true, 'driverslicense_div')}
					{this.renderUploadDocumentRow('TaxExemption', 'Tax Exempt Docs', null, true, 'taxexemption_div')}
					{this.renderUploadDocumentRow('CorporateDocs', 'Corp Docs', null, true, 'corporatedocs_div')}
					{this.renderUploadDocumentRow(
						'MerchantStatement',
						'Recent Processing Statement',
						null,
						true,
						'merchantstatement_div'
					)}
					{this.renderUploadDocumentRow(
						'CompanyLogo',
						'Marketing',
						'Flyer, Brochure or Company Website',
						true,
						'companylogo_div'
					)}
				</div>
			</Fragment>
		);
	}
	renderRep2Field = (telemarketerList, telemarketerEmail) => {
		const isCardknox = telemarketerList?.length > 0;
		const telemarketerLabel = isCardknox ? 'Rep 2' : 'Rep 2 Email';
		return (
			<div className="col col-sml-12 col-med-6 form__group">
				<div className="form__group__header">
					<p className="form__group__label">{telemarketerLabel}</p>
					{isCardknox && (
						<span data-tooltip="Required" className="form__group__required">
							*
						</span>
					)}
				</div>
				{isCardknox ? (
					<select
						placeholder="Telemarketer"
						className="input input--med input--select"
						name="telemarketers"
						value={get(
							telemarketerList.find(x => x.agentName === telemarketerEmail),
							'agentName',
							''
						)}
						onChange={this.handleTelemarketerChange}
					>
						<option value="">Please select...</option>
						{telemarketerList.map(telemarketer => (
							<option key={telemarketer.agentId}>{telemarketer.agentName}</option>
						))}
					</select>
				) : (
					<input
						type="text"
						placeholder="Rep 2 Email"
						className="input input--med"
						name="telemarketerEmail"
						value={trim(telemarketerEmail)}
						onChange={this.handleChange}
					></input>
				)}
			</div>
		);
	};

	renderHasExistingFidelityAccount = (hasExistingFidelityAccount, existingAccountDba) => {
		return (
			<React.Fragment>
				<div className="col col-sml-12 form__group">
					<div className="form__group__header">
						<p className="form__group__label">
							The signer or business has, or has previously had, an account with Fidelity
						</p>
						<span className="form__group__required" data-tooltip="Required">
							*
						</span>
					</div>
					<YesNoRadio
						name="hasExistingFidelityAccount"
						yes={hasExistingFidelityAccount}
						onChange={this.handleYesNoChange}
					/>
				</div>
				{hasExistingFidelityAccount && (
					<div className="col col-sml-12 col-med-6 form__group">
						<div className="form__group__header">
							<p className="form__group__label">DBA/MID</p>
						</div>
						<input
							type="text"
							placeholder="DBA/MID of existing account"
							className="input input--med"
							name="existingAccountDba"
							value={existingAccountDba}
							onChange={this.handleChange}
						></input>
					</div>
				)}
			</React.Fragment>
		);
	};

	tabGoTo = path => {
		const { appid } = this.props.match ? this.props.match.params : '';
		if (appid) this.props.history.push(`/eapp/${path}/${appid}`);
	};

	renderTabs() {
		return (
			<React.Fragment>
				<ul className="tabs spc--bottom--lrg">
					<li className="tabs__link is-active">MPA</li>
					<li
						className="tabs__link disabled"
						onKeyDown={e => this.onKeyDownHandler(e, () => this.tabGoTo('ach-fees'))}
						onClick={() => this.tabGoTo('ach-fees')}
					>
						Fees
					</li>
					<li
						className="tabs__link disabled"
						onKeyDown={e => this.onKeyDownHandler(e, () => this.tabGoTo('ach-equipment'))}
						onClick={() => this.tabGoTo('ach-equipment')}
					>
						Equipment
					</li>
					<li
						className="tabs__link"
						onKeyDown={e => this.onKeyDownHandler(e, () => this.tabGoTo('ach-confirm'))}
						onClick={() => this.tabGoTo('ach-confirm')}
					>
						Confirm
					</li>
				</ul>
			</React.Fragment>
		);
	}

	sendSharedFormEmail = () => {
		const {
			fields: {
				ownerName,
				businessContactName,
				dba,
				businessInformation: { businessEmail },
			},
			appId,
		} = this.state;
		this.openCloseModal({
			name: modalNames.shareMPA,
			data: {
				dba: dba,
				appid: appId,
				fieldOptions: this.state.fields.fieldOptionsRequiredForMerchant,
				documents: ['ProfitStarsConfirmation'],
				merchantEmail: businessEmail,
				merchantName: ownerName || businessContactName || dba,
			},
		});
	};

	saveAndEmailApplication = () => {
		if (this.state.disableSave) {
			return;
		}
		this.setState({ disableSave: true });
		const mpa = cloneDeep(this.state.fields);
		if (this.isDirty) {
			this.props.showLoader(true);
			appService
				.saveEApp(mpa)
				.then(() => {
					this.props.showLoader(false);
					this.setState({ disableSave: false }, () => {
						this.props.handleBlockChange(false);
					});
					this.sendSharedFormEmail();
				})
				.catch(err => {
					this.handleError(err);
					this.setState({ disableSave: false });
				});
		} else {
			this.setState({ disableSave: false });
			this.sendSharedFormEmail();
		}
	};

	showUploadMPA = () => {
		this.openCloseModal({
			name: modalNames.fileUpload,
			data: {
				tagList: ['SignedMPA'],
				handleSave: this.saveMPAFile,
				closeModal: this.openCloseModal,
				allowedFileTypes: this.allowedFileTypes,
			},
		});
	};

	saveMPAFile = (fileTags, files) => {
		if (!fileTags || !files || !files[fileTags]) {
			this.openCloseModal({
				name: modalNames.none,
			});
			return;
		}
		return appService
			.saveMerchantFiles(this.state.appId, files)
			.then(() => {
				const { fields, disableSave } = this.state;
				if (this.isDirty && !disableSave) {
					return appService
						.saveAchEApp(fields)
						.then(this.saveFiles)
						.then(() => {
							return new Promise(resolve => {
								this.setState({ localFiles: {}, disableSave: false }, () => {
									this.props
										.handleBlockChange(false)
										.then(resolve)
										.then(this.fetchAndSetData());
								});
							});
						})
						.catch(err => {
							this.handleError(err);
						});
				} else if (!disableSave) {
					return this.fetchAndSetData();
				}
			})
			.then(() => {
				const addNotification = get(this.notificationRef, 'current.addNotification', noop);
				this.openCloseModal({
					name: modalNames.none,
				});
				addNotification({
					message: 'Successfully uploaded',
					success: true,
				});
			})
			.catch(err => {
				console.log('save files error', err);
				this.props.showLoader(false);
				this.setState({ errorMessage: 'Files could not be uploaded - an error occurred: ' + err, isNewError: true });
			});
	};

	renderUploadSignedMpa() {
		const { isLoading } = this.props;
		return (
			<button
				id="uploadsignedmpa"
				disabled={isLoading}
				className="btn btn--med btn--tertiary"
				onClick={this.showUploadMPA}
			>
				<i className="icon icon--sml icon--upload"></i>
				<span>Upload Signed ACH Worksheet</span>
			</button>
		);
	}

	renderSendFormToMerchant() {
		const { disableSave } = this.state;
		const { isLoading } = this.props;

		return (
			<button
				className="btn btn--med btn--primary datatooltip--down datatooltip--200"
				data-tooltip="Sending the MPA through Partner Portal is the fastest way to get it to the lead."
				disabled={disableSave || isLoading}
				onClick={this.saveAndEmailApplication}
			>
				Send Form to Merchant
			</button>
		);
	}

	handleMPADownload = forPrint => e => {
		this.props.showLoader(true);
		let agent = principalService.get();
		let agentName = agent ? agent.name : '';
		const data = {
			...this.state.fields,
			agentName,
			enableAlternativeBankAccountInfo: this.state.enableAlternativeBankAccountInfo,
			enableSecondaryBankAccountInfo: this.state.enableSecondaryBankAccountInfo,
		};
		data.signerInformation.ownershipPercentage = data.signerInformation.ownershipPercentage.toString();
		appService
			.prefillDocumentFields(data, 'ACH_application_worksheet', this.achWorksheetFieldNames, false)
			.then(blob => {
				const url = URL.createObjectURL(blob);
				openFile(url, 'fileName', forPrint, forPrint);
				this.props.showLoader(false);
			});
	};

	toggleDownloadExportSelect = () => {
		this.setState({
			showDownloadExport: !this.state.showDownloadExport,
		});
	};

	onCloseToggleDownloadExportSelect = () => {
		this.setState({
			showDownloadExport: false,
		});
	};

	renderToolbar() {
		const { isLoading } = this.props;
		const { showDownloadExport } = this.state;
		const isDisabled = this.state.disableSave;
		return (
			<div className="flex--primary flex--gap--sml flex--right spc--bottom--lrg">
				{this.renderUploadSignedMpa()}
				<div className="pos--rel">
					<button
						onClick={this.toggleDownloadExportSelect}
						className={`btn btn--action btn--action--secondary${showDownloadExport ? ' is-active' : ''}`}
					>
						<i className="icon icon--sml icon--more"></i>
					</button>
					{showDownloadExport && (
						<OutsideClick
							action={this.onCloseToggleDownloadExportSelect}
							customIgnoreClassNames={IGNORE_CLASS_NAMES}
							className="popover popover--down w--270"
						>
							<ul className="popover__list">
								<li className="item">
									<button
										className="datatooltip--auto datatooltip--no-wrap datatooltip--down"
										data-tooltip="Download a prefilled MPA"
										disabled={isLoading || isDisabled}
										onClick={this.handleMPADownload(false)}
									>
										<i className="icon icon--sml icon--download" />
										<span>Download</span>
									</button>
								</li>
								<li className="item">
									<button
										className="datatooltip--auto datatooltip--no-wrap"
										disabled={isLoading || isDisabled}
										data-tooltip="Print a prefilled MPA"
										onClick={this.handleMPADownload(true)}
									>
										<i className="icon icon--sml icon--print" />
										<span>Print</span>
									</button>
								</li>
							</ul>
						</OutsideClick>
					)}
				</div>
				{this.renderSendFormToMerchant()}
			</div>
		);
	}

	render() {
		const { fields, modal, isExpanded } = this.state;
		const { isLoading } = this.props;

		return (
			<div id="main-div" className="l--content">
				{this.renderTabs()}
				{this.renderErrors()}
				<Notification ref={this.notificationRef} />
				<ModalWrapper modal={modal} onModalClose={this.openCloseModal} />
				<fieldset disabled={isLoading}>
					<div className="leads">
						<AppSummary app={fields} isEappWebForm={false} showLeadDetailsLink={true} />
						{this.renderToolbar()}
						<ToggleContainer>
							<div className={`card${isExpanded ? ' is-expanded' : ''} spc--bottom--lrg`}>
								{this.renderToggleableSection('Business Information', this.renderBusinessInfo())}
							</div>
							<div className={`card${isExpanded ? ' is-expanded' : ''} spc--bottom--lrg`}>
								{this.renderToggleableSection('Signer Information', this.renderSignerInformation())}
							</div>
							<div className={`card${isExpanded ? ' is-expanded' : ''} spc--bottom--lrg`}>
								{this.renderToggleableSection('Banking Information', this.renderBankingInfo())}
							</div>
							<div className={`card${isExpanded ? ' is-expanded' : ''} spc--bottom--lrg`}>
								{this.renderToggleableSection('Mailing Address', this.renderMailingInfo())}
							</div>
							<div className={`card${isExpanded ? ' is-expanded' : ''} spc--bottom--lrg`}>
								{this.renderToggleableSection(
									'Additional Transaction Details',
									this.renderAdditionalTransactionInformation()
								)}
							</div>
							<div className={`card${isExpanded ? ' is-expanded' : ''} spc--bottom--lrg`}>
								{this.renderToggleableSection(
									'Upload documents',
									this.renderUploadDocuments(),
									'This will be visible to merchants'
								)}
							</div>
							<div className={`card${isExpanded ? ' is-expanded' : ''} spc--bottom--lrg`}>
								{this.renderToggleableSection(
									'Other Notes',
									this.renderOtherNotes(),
									'This will be visible to merchants'
								)}
							</div>
						</ToggleContainer>
						{this.renderFooter()}
					</div>
				</fieldset>
			</div>
		);
	}
}

AchEappFormComponent.propTypes = {
	match: PropTypes.object,
	history: PropTypes.object,
	handleBlockChange: PropTypes.func,
	showLoader: PropTypes.func,
	isLoading: PropTypes.func,
};

export default withBlock(withLoader(withCancelable(AchEappFormComponent)));
