import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toLower } from 'lodash';

const TabList = ({ titles, onChange, className }) => {
	const [activeTab, setActiveTab] = useState(titles[0].key);

	const handleTabClick = (key, label) => {
		onChange(toLower(label));
		setActiveTab(key);
	};

	return (
		<div className={`tabs ${className}`}>
			{titles.map(({ label, key }) => (
				<button
					key={key}
					className={`btn tabs__link${activeTab === key ? ' is-active' : ''}`}
					onClick={() => handleTabClick(key, label)}
				>
					{label}
				</button>
			))}
		</div>
	);
};

TabList.propTypes = {
	titles: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, key: PropTypes.string })).isRequired,
	onChange: PropTypes.func.isRequired,
	className: PropTypes.string,
};

export default TabList;
