import { noop } from 'lodash';

const createTab = (title, rolesWithAccess, link, icon, type, rolesWithoutAccess = []) => {
	let tab = { title, rolesWithAccess, link, icon, type, rolesWithoutAccess };
	if (tab.type === 'OnClickHandler') {
		tab.onClick = noop;
	}
	return tab;
};

const tabTypes = {
	navLink: 'NavLink',
	onClickHandler: 'OnClickHandler',
	outsideLink: 'OutsideLink',
};

const sidebarTabs = [
	createTab('Merchant Accounts', ['isAdmin', 'isAgent'], '/merchants', 'view', tabTypes.navLink),
	createTab('Leads', ['isAdmin', 'isAgent'], '/leads', 'customers', tabTypes.navLink),
	createTab('EApps', ['isAdmin', 'isAgent'], '/eapps', 'eapps', tabTypes.navLink),
	createTab('New Account', ['isAdmin', 'isAgent'], null, 'plus-sqare', tabTypes.onClickHandler, [
		'isLinkedUserSupportRep',
	]),
	createTab('Tickets', ['isAdmin', 'isAgent'], '/tickets', 'receipt', tabTypes.navLink),
	createTab('Equipment Lookup', ['isAdmin', 'isAgent'], '/equipment', 'equipment', tabTypes.navLink),
	createTab('Residuals', ['isResidual'], '/residuals', 'chart', tabTypes.navLink, ['isDropinUser']),
	createTab(
		'Feedback',
		['isAdmin', 'isAgent'],
		'https://sola.wufoo.com/forms/rq3hwt0keb1a8',
		'swap',
		tabTypes.outsideLink
	),
	createTab(
		'User Guide',
		['isAdmin', 'isAgent'],
		'https://docs.cardknox.com/cardknox-products/partner-portal',
		'layout',
		tabTypes.outsideLink
	),
	createTab('Reports', ['isReports'], '/fee-reports', 'reports', tabTypes.navLink, ['isDropinUser']),
	createTab('Settings', ['isAdmin', 'isAgent'], '/settings', 'settings', tabTypes.navLink, ['isDropinUser']),
];

export default sidebarTabs;
