import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import { trim, toLower } from 'lodash';

import { principalService } from '../../services';
import PrivacyPolicy from '../privacy-policy/PrivacyPolicy';

class ForgotPasswordComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			username: '',
			errorMessage: null,
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentWillMount() {
		if (principalService.get()) {
			this.redirect();
		}
	}

	handleChange(event) {
		this.setState({ [event.target.name]: event.target.value });
	}

	async handleSubmit(event) {
		const { history } = this.props;
		let { username } = this.state;
		username = trim(toLower(username));

		event.preventDefault();

		Auth.forgotPassword(username)
			.then(() => {
				history.push({
					pathname: '/confirm-new-password',
					state: { username: username },
				});
			})
			.catch(err => {
				let message;
				switch (err && err.code) {
					case 'UserNotConfirmedException': {
						history.push({
							pathname: '/confirm-registration',
							state: { username: username },
						});
						break;
					}
					case 'UserNotFoundException': {
						message = 'User with provided email does not exist.';
						break;
					}
					case 'LimitExceededException': {
						message = 'Attempt limit exceeded, please try after some time.';
						break;
					}
					default: {
						message = 'Something went wrong. Please try again.';
						break;
					}
				}
				//eslint-disable-next-line
				console.log(err);
				this.setState({
					errorMessage: message,
				});
			});
	}

	redirectToRegister = () => {
		const { history } = this.props;
		history.push('/register');
	};

	redirectToLogin = () => {
		const { history } = this.props;
		history.push('/login');
	};

	redirect() {
		const { history, location } = this.props;
		let redirectUrl = '/';

		if (location.state && location.state.returnUrl) {
			redirectUrl = location.state.returnUrl;
		}

		history.push(redirectUrl);
	}

	render() {
		const { username, errorMessage } = this.state;

		return (
			<React.Fragment>
				<form className="auth__form" onSubmit={this.handleSubmit}>
					<h2 className="auth__form__title">Forgot your password?</h2>
					<p className="auth__form__description">
						Enter your email address and we'll email you instructions on how to reset your password
					</p>
					<div className="form__group">
						<div className="form__group__header">
							<p className="form__group__label">Email</p>
						</div>
						<input
							name="username"
							type="email"
							className="input input--med"
							placeholder="user@gmail.com"
							value={username}
							onChange={this.handleChange}
						/>
						{errorMessage ? (
							<div className="spc--top--sml spc--bottom--sml type--validation spc--bottom--lrg">{errorMessage}</div>
						) : null}
					</div>
					<button type="submit" className="btn btn--primary btn--lrg spc--bottom--lrg--alt">
						Reset password
					</button>
					<div className="auth__form__create-pass">
						<p>Do you have an account?</p>{' '}
						<button type="button" onClick={this.redirectToLogin} className="btn btn--sml btn--link">
							Sign in
						</button>
					</div>
				</form>
				<PrivacyPolicy showCopyright />
			</React.Fragment>
		);
	}
}

ForgotPasswordComponent.propTypes = {
	history: PropTypes.object,
	location: PropTypes.object,
};

export default ForgotPasswordComponent;
