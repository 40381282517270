import React, { Component, Fragment, createRef } from 'react';
import PropTypes from 'prop-types';
import { Data } from '../../common/components/react-data-grid-addons';
import { cloneDeep, each, find, unionBy, map, toLower, findIndex, get, noop, concat, includes } from 'lodash';

import { LeadColumns as Columns } from './column-filter/leadColumns';
import { leadsFilter as Filter, compileFilter } from './filter/leadsFilter';
import { appService } from '../../services/appService';
import { GridComponent, ToolbarComponent } from '../../common/components/grid';
import { ModalWrapper, modalNames } from './../../common/components/modal-wrapper';
import { ZebraRenderer } from '../../common/components/row';
import { actionsGridColumn } from '../../common/components/actionsGrid/leadsActionsGridColumn';
import { withLoader } from '../../common/components';
import withError from '../../common/components/error/error-hoc';
import { GridTooltip } from '../../common/components/tooltips';
import { Notification } from '../../common/components/notifications';
import { LoadMoreOptions } from '../../common/utilities';
import { principalService } from '../../services';
import {
	getPage,
	hasMoreData,
	onLoadMoreLimitChange,
	openActions,
	parseQueriesToFilters,
	queryFilterValues,
	saveCurrentView,
	deleteAgentView,
	applyAgentView,
	clearAgentView,
	fetchAgentViews,
} from '../../common/components/grid/commonGridMethods';
import { TabFilter } from '../../common/column-filters';
import { SavedFilterComponent } from '../../common/components/filter';
import { getProcessorDisplayName } from '../../common/utilities/processorDisplayName';

const loadMoreOptionsWithAll = concat(LoadMoreOptions, [0]);

class LeadGrid extends Component {
	constructor(props) {
		super(props);
		this.viewType = 'Lead';
		this.state = cloneDeep(this.initialState);

		this.gridRef = createRef();
		this.notificationRef = createRef();

		this.setState = this.setState.bind(this);
		this.onLoadMoreLimitChange = onLoadMoreLimitChange.bind(this);
		this.queryFilterValues = queryFilterValues.bind(this);
		this.parseQueriesToFilters = parseQueriesToFilters.bind(this);
		this.saveCurrentView = saveCurrentView.bind(this);
		this.deleteAgentView = deleteAgentView.bind(this);
		this.applyAgentView = applyAgentView.bind(this);
		this.clearAgentView = clearAgentView.bind(this);
		this.fetchAgentViews = fetchAgentViews.bind(this);
		this.updateParameters = 'openNewAccountPopup';
	}

	get initialState() {
		const filters = unionBy(this.props.location.filters, Filter, 'key') || Filter;
		const principal = principalService.get();
		const columns = cloneDeep(Columns);

		if (principal.canBoardGoPlus) {
			const mpaSignedColumnIndex = findIndex(columns, ({ key }) => toLower(key) === 'ismpasigned');
			if (mpaSignedColumnIndex > -1) {
				columns[mpaSignedColumnIndex].name = 'eApp Signed';
			}
		}
		if (principal.agentList && Object.keys(principal.agentList).length > 0) {
			const repColumns = ['agentName', 'telemarketerName'];
			repColumns.forEach(columnName => {
				const column = find(columns, ({ key }) => toLower(key) === toLower(columnName));
				if (column) column.visible = true;
			});
		}

		return {
			data: null,
			filteredRows: [],
			fullFilteredRows: [],
			filters: filters,
			activeFilters: cloneDeep(filters),
			inlineFilters: {},
			expanded: {},
			maxGridHeight: 0,
			columns,
			defaultColumns: columns,
			fetchingData: true,
			fetchingAdditionalData: false,
			showFilters: true,
			lastApiRefNum: null,
			activePage: 1,
			rowsPerPage: 20,
			totalRowCount: 0,
			modal: {
				name: modalNames.none,
				data: null,
			},
			filterProps: {
				isLoadingAgentViews: false,
				agentViews: [],
				clearAgentView: (...params) => this.clearAgentView(...params),
				applyAgentView: (...params) => this.applyAgentView(...params),
				saveCurrentView: viewName => this.saveCurrentView(viewName),
				deleteAgentView: viewName => this.deleteAgentView(viewName),
			},
			isLinkedUserSupportRep: principal.isLinkedUserSupportRep,
			initiallyOpenNewAccountPopup: false,
		};
	}

	componentDidMount() {
		this.parseQueriesToFilters();
	}

	componentDidUpdate = prevProps => {
		this.checkIfShouldOpenNewAccountPopup();
		if (prevProps.location.key !== this.props.location.key && includes(this.props.location.search, 'refresh=true')) {
			this.clearAgentView();
		}
	};

	checkIfShouldOpenNewAccountPopup = () => {
		const { location, history } = this.props;
		const openCloseModal = get(this.gridRef, 'current.openCloseModal', noop);

		if (this.state.initiallyOpenNewAccountPopup === false && get(location, 'openNewAccountPopup')) {
			openCloseModal(
				{
					name: modalNames.newLead,
					data: { history, addNotification: get(this.notificationRef, 'current.addNotification', noop) },
				},
				true
			);
			history.replace({ openNewAccountPopup: false });
		}
	};

	handleModalToggle = (modalObj, openNew = false) => {
		const state = {};
		if (modalObj.data === null) {
			state['initiallyOpenNewAccountPopup'] = false;
		}
		if (openNew === true) {
			state['initiallyOpenNewAccountPopup'] = true;
		}
		this.setState(state);
	};

	handlePageChange = pageNumber => {
		//console.log('active page is ' + pageNumber);
		this.handleChange([{ key: 'activePage', value: pageNumber }]);
	};

	fetchData = async filters => {
		const filter = compileFilter(filters);
		//console.log('fetching data');
		this.setState({
			fetchingData: true,
			data: null,
			filteredRows: [],
			fullFilteredRows: [],
			expanded: {},
			lastApiRefNum: null,
			totalRowCount: 0,
		});

		let lastApiRefNum = null;
		const { rowsPerPage, activePage } = this.state;
		//console.log('rows per page: ' + rowsPerPage + ' active page ' + activePage);

		let data = await appService.getLeads(filter); //(filter);

		// check for gridRef.current to make sure page didn't unload while data was loading
		if (this.gridRef.current) {
			lastApiRefNum = data.refNum;
			let dataObj = { xReportData: data.leads };

			if (dataObj && dataObj.xReportData) {
				dataObj.xReportData = map(dataObj.xReportData, this.mapRow);
			}

			const formattedColumns = this.formatColumns(this.state.columns, cloneDeep(filter));
			this.mapData(dataObj);
			const filteredRows = data.leads
				? Data.Selectors.getRows({
						rows: dataObj.xReportData,
						filters: this.state.inlineFilters,
				  })
				: [];

			const pagedFilteredRows = getPage(filteredRows, activePage, rowsPerPage);

			if (this.gridRef.current) {
				this.gridRef.current.scrollTo({ top: 0, left: 0 });
			}

			//console.log('total rows: ' + filteredRows.length);

			this.setState(
				{
					data: dataObj,
					fullFilteredRows: filteredRows,
					filteredRows: pagedFilteredRows,
					fetchingData: false,
					columns: formattedColumns,
					lastApiRefNum: lastApiRefNum,
					totalRowCount: filteredRows.length,
				},
				() => {
					if (this.gridRef.current) {
						this.gridRef.current.handleInitialSort();
					}
				}
			);
		} // if this.gridRef.current
	};

	mapData = data => {
		let i = 0;
		if (data && data.xReportData && data.xReportData.length > 0) {
			each(data.xReportData, item => {
				item.uniqueId = item.leadId + item.appId;
				item.openActions = openActions((e, d = noop) => this.setState(e, d), this.gridRef);
				item.showLoader = this.props.showLoader;
				item.gridRowNumber = i;
				item.index = i;
				i++;
			});
		}
	};

	getCollectionItem = (collection, key) => {
		return find(collection, { key });
	};

	formatColumns = (columns, appliedFilter = null) => {
		if (appliedFilter) {
			for (let prop in appliedFilter) {
				if (appliedFilter.hasOwnProperty(prop)) {
					let column = find(columns, i => {
						return i.key.toLowerCase() === prop.toLowerCase() && !i.visible;
					});

					if (column) {
						column.visible = true;
					}
				}
			}
		}
		return columns;
	};

	lookupReview = async (leadId, uniqueId) => {
		const { data, filteredRows, fullFilteredRows } = this.state;
		const leadDetails = await appService.getLeadDetails(leadId);
		const reviewStatus = get(leadDetails, 'reviewStatus', false);
		if (!reviewStatus) {
			return;
		}
		const newState = {
			data: { ...data, xReportData: [...data.xReportData] },
			filteredRows: [...filteredRows],
			fullFilteredRows: [...fullFilteredRows],
		};
		each(['data.xReportData', 'filteredRows', 'fullFilteredRows'], key => {
			const value = get(newState, key);
			const index = findIndex(value, { uniqueId });
			if (index === -1) {
				return;
			}
			value[index] = {
				...value[index],
				reviewStatus,
			};
		});
		this.setState(newState);
	};

	mapRow = row => ({
		...row,
		isExpandable: true,
		refreshGridData: this.gridRef.current ? this.gridRef.current.refreshGridData : null,
		processor: getProcessorDisplayName(row.processor),
		onInfoHover: this.onInfoHover,
		openCloseModal: this.openCloseModal,
		lookupReview: this.lookupReview,
	});

	mapCellArgs = (rowId, row, column, openOnly = false, focusSchedule = false) => {
		if (rowId < 0) {
			return; // header row
		}
		const args = {
			rowData: row,
			expandArgs: {
				children: [
					{
						isDetails: true,
						row: row,
						focusSchedule: focusSchedule,
					},
				],
			},
			openOnly: openOnly,
		};

		return args;
	};

	onInfoHover = (infoDimensions, tooltip) => {
		this.setState({ tooltipProps: { infoDimensions, tooltip } });
	};

	calculatePopupLeft = () => {
		return this.state.infoDimensions.width - 36;
	};

	calculatePopupTop = () => {
		let offset = 0;
		if (this.gridRef.current && this.gridRef.current.gridHolderRef.current) {
			offset =
				this.gridRef.current.gridHolderRef.current.getBoundingClientRect().y -
				this.gridRef.current.gridHolderRef.current.scrollTop -
				25;
		}
		return this.state.infoDimensions.height - offset;
	};
	renderTooltip = () =>
		this.state.tooltip ? (
			<div
				style={{
					left: this.calculatePopupLeft(),
					top: this.calculatePopupTop(),
					position: 'absolute',
					backgroundColor: '#fff',
					zIndex: 99,
				}}
			>
				{this.state.tooltip}
			</div>
		) : null;

	refetchData = () => {
		this.fetchData(this.state.activeFilters);
	};

	handleChange = changes => {
		const newState = {};
		const { rowsPerPage, activePage } = this.state;
		each(changes, ({ key, value }) => {
			if (key === 'data' || key === 'inlineFilters' || key === 'activePage') {
				let filters, data, activePage;
				if (key === 'data') {
					filters = this.state.inlineFilters;
					activePage = 1;
					data = value;
				} else if (key === 'inlineFilters') {
					filters = value;
					data = this.state.data;
					activePage = 1;
				} else {
					activePage = value;
					data = this.state.data;
					filters = this.state.inlineFilters;
				}
				let filteredRows =
					data && data.xReportData
						? Data.Selectors.getRows({
								rows: data.xReportData,
								filters,
						  })
						: [];
				const pagedFilteredRows = getPage(filteredRows, activePage, this.state.rowsPerPage);
				newState.filteredRows = pagedFilteredRows;
				newState.fullFilteredRows = filteredRows;
				newState.totalRowCount = filteredRows.length;
				newState.activePage = activePage;
				//console.log('setting total rows to ' + newState.totalRowCount);
			}
			newState[key] = value;
		});
		return new Promise(resolve => {
			this.setState(newState, resolve);
		});
	};

	openCloseModal = (modalObj, ...rest) => {
		let state = {
			modal: modalObj,
		};
		this.setState(state);
	};

	onRowClick = (...params) => {
		if (params[0] < 0 || params[2].key === 'isHidden') {
			return;
		}
		const { history } = this.props;
		const row = params[1];

		history.push(this.createDetailsLink(row));
	};

	createDetailsLink = row => {
		const eAppStarted = toLower(row.leadStatus) === 'eapp started' || toLower(row.leadStatus) === 'pend bank approval';

		let detailsLink = '/leads/account/' + row.leadId;
		if (eAppStarted) {
			if (row.processor === 'CK-Go') {
				detailsLink = '/eapp/cardknox-go/' + row.appId;
			} else if (row.processor === 'ACHQ') {
				detailsLink = '/eapp/achq/' + row.appId;
			} else {
				detailsLink = '/eapp/mpa/' + row.appId;
			}
		} else if (
			toLower(row.leadStatus) === 'eapp submitted' ||
			toLower(row.leadStatus) === 'pending fd sig' ||
			toLower(row.leadStatus) === 'pending elavon sig' ||
			toLower(row.leadStatus) === 'pend underwriting'
		) {
			detailsLink = `/merchants/${row.appId}/account`;
		}
		return detailsLink;
	};

	renderNewLeadPopup = () => {
		const { isLinkedUserSupportRep } = this.state;

		return (
			<Fragment>
				{!isLinkedUserSupportRep && (
					<button
						type="button"
						className="btn btn--primary btn--med spc--left--sml"
						onClick={() =>
							this.openCloseModal({
								name: modalNames.newLead,
								data: {
									history: this.props.history,
									addNotification: get(this.notificationRef, 'current.addNotification', noop),
								},
							})
						}
					>
						<i className="icon icon--sml icon--add--white"></i>
						<span>New Account</span>
					</button>
				)}
			</Fragment>
		);
	};

	renderTabs = ({ className, handleInlineFilter }) => (
		<div>
			<TabFilter
				className={className}
				column={find(this.state.columns, { key: 'isHidden' })}
				trueLabel="Archived Leads"
				falseLabel="Leads"
				onChange={handleInlineFilter}
			/>
		</div>
	);

	renderHeader = ({ refreshGridData }) => <div className="push">{this.renderNewLeadPopup()}</div>;

	renderGridHeader = ({ refreshGridData }) => <div className="type--right">{this.renderNewLeadPopup()}</div>;

	renderTotalRows = () => {
		const { lastApiRefNum, totalRowCount } = this.state;
		const numRecords = totalRowCount;
		const resultsWording = numRecords === 1 ? 'Result' : 'Results';
		const refWording = lastApiRefNum ? `(Reference #${lastApiRefNum})` : '';
		return (
			<Fragment>
				<span className="spc--left--auto">
					<span>
						<strong>{numRecords}</strong> {resultsWording} <strong>{refWording}</strong>
					</span>
				</span>
			</Fragment>
		);
	};

	renderModals = props => <ModalWrapper {...props} />;

	render = () => {
		const {
			modal,
			fetchingAdditionalData,
			fetchingData,
			filteredRows,
			fullFilteredRows,
			columns,
			data,
			inlineFilters,
			expanded,
			filters,
			activeFilters,
			lastApiRefNum,
			defaultColumns,
			activePage,
			rowsPerPage,
			totalRowCount,
			tooltipProps,
			filterProps,
		} = this.state;

		return (
			<Fragment>
				<Notification ref={this.notificationRef} />
				<ModalWrapper modal={modal} onModalClose={this.openCloseModal} />
				<GridComponent
					emptyMessage="You should change your filter options"
					fetchingData={fetchingData}
					fetchingAdditionalData={fetchingAdditionalData}
					filteredRows={filteredRows}
					fullFilteredRows={fullFilteredRows}
					loadMoreOptions={loadMoreOptionsWithAll}
					onLoadMoreLimitChange={this.onLoadMoreLimitChange}
					loadMoreLimit={rowsPerPage}
					columns={columns}
					fixHeader={true}
					data={data}
					inlineFilters={inlineFilters}
					components={{
						toolbar: ToolbarComponent,
						header: this.renderHeader,
						gridHeader: this.renderGridHeader,
						filter: SavedFilterComponent,
						rowRenderer: ZebraRenderer,
						tooltip: GridTooltip,
						modal: this.renderModals,
						title: this.renderTabs,
					}}
					onChange={this.handleChange}
					rowKey="uniqueId"
					isExpandable={true}
					title="Leads"
					isTab={true}
					enableExport={true}
					enablePrint={true}
					type="leads"
					filters={filters}
					activeFilters={activeFilters}
					showFilters={true}
					fetchData={this.refetchData}
					lastApiRefNum={lastApiRefNum}
					showResults={true}
					mapCellArgs={this.mapCellArgs}
					showExportDropdown={false}
					showPrintDropdown={false}
					filterColumns={true}
					defaultColumns={defaultColumns}
					onRowClick={this.onRowClick}
					createDetailsLink={this.createDetailsLink}
					expanded={expanded}
					enableFilters={true}
					ref={this.gridRef}
					useInlineFilters={true}
					initialFetch={false}
					hasPaging={true}
					hasMoreData={hasMoreData(rowsPerPage, totalRowCount)}
					handlePageChange={this.handlePageChange}
					pagination={{ activePage, rowsPerPage, totalRowCount }}
					actionsGridColumn={actionsGridColumn}
					tooltipProps={tooltipProps}
					filterProps={filterProps}
					syncQueryFilters={true}
					queryFilterValues={this.queryFilterValues}
					onModalToggle={this.handleModalToggle}
					canReorderColumns={true}
				/>
			</Fragment>
		);
	};
}

LeadGrid.propTypes = {
	history: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
	showLoader: PropTypes.func.isRequired,
};

export default withError(withLoader(LeadGrid));
