import React from 'react';
import { map, cloneDeep, find, some, isEmpty } from 'lodash';
import Menu, { SubMenu, Item as MenuItem } from 'rc-menu';

import MainFilterComponent from './MainFilter';

class SavedFilter extends MainFilterComponent {
	constructor(props) {
		super(props);
		this.state = {
			activeKeys: [],
			displayAdvancedFilter: false,
			filters: cloneDeep(props.filters),
			dates: cloneDeep(props.predefinedDates),
			isLoading: false,
			viewName: '',
			errorMessage: '',
			isSaveViewOpen: false,
			selectedView: null,
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.selectedView === this.props.selectedView) return;
		this.setState({
			selectedView: this.props.selectedView,
		});
	}

	toggleSaveView = () => {
		this.setState({
			isSaveViewOpen: !this.state.isSaveViewOpen,
		});
	};

	handleChange = ({ target: { name, value, checked, type } }) => {
		const newValue = type === 'checkbox' ? checked : value;
		this.setState({
			[name]: newValue,
		});
	};

	applyAgentView = () => {
		const { agentViews, applyAgentView } = this.props;
		const { selectedView } = this.state;
		const { viewJson } = find(agentViews, { viewName: selectedView });
		viewJson && applyAgentView(selectedView, JSON.parse(viewJson));
		this.setState({
			activeKeys: [],
		});
	};

	clearView = clearDefault => {
		this.props.clearAgentView(clearDefault);
		this.setState({
			selectedView: null,
		});
	};

	deleteAgentView = async viewName => {
		this.setState({ isLoading: true });
		try {
			await this.props.deleteAgentView(viewName);
			if (this.state.selectedView == viewName) this.clearView();
			this.setState({ isLoading: false });
		} catch (err) {
			this.setState({
				errorMessage: ['Error: ' + err],
				isLoading: false,
			});
		}
	};

	selectView = ({ target: { name, checked } }) => {
		const selectedView = checked ? name : null;
		this.setState({
			selectedView,
		});
	};

	saveView = async () => {
		this.setState({ isLoading: true });
		try {
			await this.props.saveCurrentView(this.state.viewName);
			this.setState({ isLoading: false, isSaveViewOpen: false, viewName: '' });
		} catch (err) {
			this.setState({
				errorMessage: ['Error: ' + err],
				isLoading: false,
			});
		}
	};

	renderAdditionalFilter = () => {
		const { agentViews } = this.props;
		const { isSaveViewOpen, viewName, isLoading, errorMessage } = this.state;
		const isDuplicateView = some(agentViews, { viewName });
		return (
			<div className="filter__select filter__select--save" tabIndex={0}>
				<Menu
					disabledOverflow={true}
					className="rc-menu-datepicker-tooltip rc-menu"
					mode={'horizontal'}
					motion={'slide-up'}
					triggerSubMenuAction={'click'}
					openKeys={isSaveViewOpen ? ['saveView'] : []}
					onOpenChange={this.toggleSaveView}
				>
					<SubMenu title={<span>Save View</span>} key="saveView" popupClassName="rc-menu-save-view">
						<MenuItem key="saveView.menuItem" disabled>
							<h5 className="spc--bottom--med type--color--text">Save View</h5>
							<p className="spc--bottom--lrg w--max--440 type--p2 type--color--text">
								Includes filter settings, column sorting, column selections, reordering columns, column sizing, and
								display amounts
							</p>
							<div className="spc--bottom--xlrg">
								<input
									className={`input input--med${errorMessage ? ' is-invalid' : ''}`}
									type="text"
									name="viewName"
									placeholder="Name of the view"
									value={viewName}
									onChange={this.handleChange}
								/>
								{isDuplicateView && (
									<div className="type--validation spc--top--sml">View "{viewName}" already exists</div>
								)}
								{errorMessage && (
									<p className="type--validation spc--top--sml" style={{ whiteSpace: 'initial' }}>
										{errorMessage}
									</p>
								)}
							</div>

							<div className="rc-menu-save-view__footer">
								<button
									disabled={isLoading}
									className="btn btn--med btn--tertiary"
									type="button"
									onClick={this.toggleSaveView}
								>
									Cancel
								</button>
								<button
									disabled={isLoading || isDuplicateView}
									className="btn btn--med btn--primary"
									type="button"
									onClick={this.saveView}
								>
									Save
								</button>
							</div>
						</MenuItem>
					</SubMenu>
				</Menu>
			</div>
		);
	};

	renderTopFilter = () => {
		const { agentViews, isLoadingAgentViews } = this.props;
		const { selectedView, isLoading } = this.state;

		let content;
		if (isLoadingAgentViews) {
			content = (
				<MenuItem key="loading" disabled={true}>
					<span>Loading...</span>
				</MenuItem>
			);
		} else if (isEmpty(agentViews)) {
			content = <p className="spc--left--med spc--bottom--sml spc--right--med">There are no saved views.</p>;
		} else {
			content = map(agentViews, ({ viewName }) => (
				<MenuItem key={viewName} disabled>
					<div className="flex--secondary">
						<div className="flex--grow--1">
							<input
								id={`agentViews.${viewName}`}
								className="input--check"
								type="checkbox"
								name={viewName}
								checked={viewName === selectedView}
								onChange={this.selectView}
							/>
							<label className="fullwidth" htmlFor={`agentViews.${viewName}`}>
								{viewName}
							</label>
						</div>
						<button
							disabled={isLoading}
							onClick={() => this.deleteAgentView(viewName)}
							className="btn btn--link"
							title="Delete"
						>
							<i className="icon icon--sml icon--delete" />
						</button>
					</div>
				</MenuItem>
			));
		}

		return [
			<SubMenu mode={'vertical-left'} title={<span>My Saved Views</span>} key="savedViews">
				{content}
				<MenuItem key="defaultView" disabled={true} className="rc-menu-abs-footer">
					<div className="rc-menu-footer rc-menu-footer-alt">
						<button type="button" onClick={this.clearView} className="btn btn--link btn--link--underline">
							Reset
						</button>
						<button
							type="button"
							disabled={!selectedView}
							onClick={this.applyAgentView}
							className="btn btn--sml btn--primary"
						>
							Done
						</button>
					</div>
				</MenuItem>
			</SubMenu>,
		];
	};
}

export default SavedFilter;
