import React from 'react';
import { noop } from 'lodash';

import USSetupConfirm from './USSetupConfirm';
import AmexRates from './amex-rates';

export default class CanadaSetupConfirm extends USSetupConfirm {
	renderPinDebit() {
		return (
			<div className="col col-sml-12">
				<div className="form__group__header">
					<p className="form__group__label">Setup Merchant With Pin Debit</p>
				</div>
				<div className="flex--primary flex--gap--xlrg form__group">
					{this.renderSetupPinDebitRadio(true)}
					{this.renderSetupPinDebitRadio(false)}
				</div>
				{this.renderPinDebitStructure()}
			</div>
		);
	}

	renderAmexRates(setup) {
		return (
			<AmexRates
				tooltip="Amex uses the same rate structure as Visa/MC"
				amexDetails={setup.amexDetails}
				renderRateStructure={this.renderRateStructure}
			/>
		);
	}

	renderSetupPinDebitRadio(isTrue, className) {
		const { setup } = this.props;
		const id = `pricingStructure_shouldSetupPinDebit_${isTrue ? 'yes' : 'no'}`;
		return (
			<div className={className}>
				<input
					disabled
					className="input--radio"
					type="radio"
					id={id}
					name={id}
					checked={setup.pricingStructure.shouldSetupPinDebit === isTrue}
					onChange={noop}
				/>
				<label htmlFor={id}>{isTrue ? 'Yes' : 'No'}</label>
			</div>
		);
	}

	renderOnlineReportingSection() {
		return null;
	}

	renderPinDebitStructure() {
		const { setup } = this.props;
		if (!setup.pricingStructure.shouldSetupPinDebit) return null;
		return (
			<div className="row spc--top--sml  spc--bottom--med">
				{this.renderPinDebitInput('Interac Trans Fee', setup.pricingStructure.pinDebitTransactionFee)}
				{this.renderPinDebitInput(
					'Interac Flash Trans Fee/Contactless',
					setup.pricingStructure.pinDebitInteracFlashTransFee
				)}
			</div>
		);
	}

	renderEidsEnrollment() {
		return null;
	}

	renderTransactionInformation() {
		return this.renderFakeInput({
			name: 'Debit Refund Cap',
			value: this.props.setup.pricingStructure.debitRefundCap,
			className: 'spc--bottom--xxxlrg',
		});
	}

	renderRetrievalCharge() {
		return null;
	}

	renderPciFee(setup) {
		return (
			<React.Fragment>
				<div className="col col-sml-12 col-lrg-6 form__group">
					<div className="form__group__header">
						<p className="form__group__label">PCI Monthly Fee</p>
					</div>
					<div className="input input--med input--disabled input--currency">{setup.proposedFees.pciMonthlyFee}</div>
				</div>
				<div className="col col-sml-12 col-lrg-6 form__group">
					<div className="form__group__header">
						<p className="form__group__label">Application Fee</p>
						<div className="input input--med input--disabled input--currency">{setup.proposedFees.applicationFee}</div>
					</div>
				</div>
			</React.Fragment>
		);
	}

	shouldHideNonQual = name => name.indexOf('amex') !== 0;

	renderTransactionVolume() {
		const {
			setup: {
				processingDetails: { averageTransactionAmount, maxTransactionAmount, yearlyVmcVolume },
			},
		} = this.props;
		return (
			<div className="spc--bottom--lrg">
				<p className="type--p2 type--p2--medium spc--bottom--lrg">V/MC/AX Transaction Information:</p>
				<div className="row flex--bottom">
					{this.renderVMField(yearlyVmcVolume, 'V/MC/AX Average Annual Volume')}
					{this.renderVMField(averageTransactionAmount, 'V/MC/AX Average transaction dollar amount')}
					{this.renderVMField(maxTransactionAmount, 'V/MC/AX highest transaction dollar amount')}
				</div>
			</div>
		);
	}
}
