import React from 'react';
import PropTypes from 'prop-types';
import { withLoader } from './../../common/components';
import { appService } from '../../services';
import { modalNames, ModalWrapper } from '../../common/components/modal-wrapper';
import { Schema, ShareMpaFormTemplate } from '../../validation';
import { ClickableErrorMessage } from '../../common/components/error/ClickableErrorMessage';
import { includes, map, join, debounce, first, isEmpty, remove, some, find } from 'lodash';

const signerIdentificationTypeFields = [
	'IdentificationNumber',
	'IdentificationExpirationDate',
	'IdentificationIssueDate',
];

class ShareMPAForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			merchantEmail: this.props.merchantEmail,
			invalidErrorList: [],
			ccEmail: '',
			sendAgentCopy: true,
			documents: this.props.documents,
			saveResults: null,
			errorMessage: null,
			copied: false,
			modal: {
				name: modalNames.none,
				data: null,
			},
			fieldsSelected: [],
			fieldOptions: this.formatFieldOptions(this.props.fieldOptions, option => option.fieldName),
		};
		this.schema = new Schema(ShareMpaFormTemplate, { strip: false, typecast: true });
	}

	componentDidMount() {
		this.setState({ fieldsSelected: map(this.state.fieldOptions, option => option.fieldName) }, this.getEmailLink);
	}

	formatFieldOptions = fields => {
		if (
			!includes(fields, 'SignerIdentificationType') &&
			some(fields, field => includes(signerIdentificationTypeFields, field.fieldName))
		) {
			return [...fields, { fieldName: 'SignerIdentificationType', displayName: 'Signer Identification Type' }];
		}
		return fields;
	};

	getStyle = show => {
		return {
			display: show ? '' : 'none',
		};
	};

	onEmailChange = e => {
		//console.log('updating');
		this.setState({ merchantEmail: e.target.value }, this.formChangedCallback);
	};

	onCCEmailChange = e => {
		//console.log('updating');
		this.setState({ ccEmail: e.target.value }, this.formChangedCallback);
	};

	onSendCopyChange = e => {
		this.setState({ sendAgentCopy: e.target.checked }, this.formChangedCallback);
	};

	onFieldSelected = e => {
		const { id, checked } = e.target;
		let newFieldsSelected = [...this.state.fieldsSelected];

		if (id === 'SignerIdentificationType') {
			if (checked && !some(newFieldsSelected, field => includes(signerIdentificationTypeFields, field))) {
				newFieldsSelected = [...newFieldsSelected, ...signerIdentificationTypeFields];
			} else if (!checked) {
				remove(newFieldsSelected, field => includes(signerIdentificationTypeFields, field));
			}
		}

		checked ? newFieldsSelected.push(id) : (newFieldsSelected = newFieldsSelected.filter(item => item !== id));
		this.setState({ fieldsSelected: newFieldsSelected }, this.formChangedCallback);
	};

	handleSendClick = () => {
		this.onSend();
	};

	debounceGetEmailLink = debounce(() => this.getEmailLink(), 750);

	formChangedCallback = () => {
		const invalidErrorList = this.schema.validate(Object.assign({}, this.state));
		this.setState({ invalidErrorList, linkToCopy: null }, () => {
			if (!isEmpty(invalidErrorList)) return;
			this.debounceGetEmailLink();
		});
	};

	getEmailLink = async () => {
		const { appid, merchantName, dba } = this.props;
		const { merchantEmail, ccEmail, sendAgentCopy, documents, fieldsSelected } = this.state;
		const fieldString = join(fieldsSelected, '|');
		try {
			let appInfo = { appId: appid, merchantDba: dba, merchantEmail: merchantEmail, merchantName: merchantName };
			let result = await appService.shareMPAForm(appInfo, ccEmail, sendAgentCopy, documents, fieldString, true);
			this.setState({ linkToCopy: result.url });
		} catch (error) {
			this.setState({ errorMessage: error });
		}
	};

	copyEmailLink = () => {
		try {
			const { linkToCopy } = this.state;
			navigator.clipboard.writeText(linkToCopy);

			this.setState({ copied: true, errorMessage: null });

			setTimeout(() => {
				this.setState({ copied: false });
			}, 2000);
		} catch (error) {
			this.setState({ errorMessage: error });
		}
	};

	onSend = () => {
		this.setState({ copied: false });
		this.props.showLoader(true);
		const { appid, merchantName, dba } = this.props;
		const { merchantEmail, ccEmail, sendAgentCopy, documents, fieldsSelected } = this.state;
		const fieldString = join(fieldsSelected, '|');
		let saveResults = 'Sent successfully';
		let appInfo = { appId: appid, merchantDba: dba, merchantEmail: merchantEmail, merchantName: merchantName };
		return appService
			.shareMPAForm(appInfo, ccEmail, sendAgentCopy, documents, fieldString)
			.then(() => {
				this.props.showLoader(false);
				this.setState({ saveResults, errorMessage: null });
			})
			.catch(err => {
				this.props.showLoader(false);
				this.setState({ errorMessage: err });
			});
		//console.log(saveResults);
	};

	renderErrorMessage = () =>
		this.state.errorMessage && <div className="type--validation spc--bottom--med">{this.state.errorMessage}</div>;

	renderSaveResults = () => {
		const { merchantEmail, ccEmail, sendAgentCopy, saveResults, documents } = this.state;
		const shareFileName = documents.includes('ProfitStarsConfirmation') ? 'eAch Worksheet' : 'Fidelity MPA';
		return saveResults ? (
			<div className="flex--primary flex--gap--sml spc--bottom--med">
				<p className="type--p1 type--p1--medium type--color--success">{saveResults}</p>
				<i className="icon icon--med icon--regular--check"></i>
			</div>
		) : (
			<React.Fragment>
				<div className="row">
					<div className="col col-sml-12 col-med-6 form__group">
						<div className="form__group__header">
							<p className="form__group__label">Merchant Email:</p>
						</div>
						<input
							type="text"
							className="input input--med"
							name="merchantEmail"
							value={merchantEmail}
							onChange={this.onEmailChange}
						/>
					</div>
					<div className="col col-sml-12 col-med-6 form__group">
						<div className="form__group__header">
							<p className="form__group__label">CC:</p>
						</div>
						<input
							type="text"
							className="input input--med"
							name="ccEmail"
							value={ccEmail}
							onChange={this.onCCEmailChange}
						/>
					</div>
				</div>
				<div className="spc--bottom--xxlrg">
					<input
						type="checkbox"
						className="input--check"
						id="chkSendAgentCopy"
						checked={sendAgentCopy}
						onChange={this.onSendCopyChange}
					/>
					<label htmlFor="chkSendAgentCopy">Send me a copy</label>
				</div>
				{!documents.includes('FDGoConfirmation') && (
					<React.Fragment>
						<h5 className="spc--bottom--lrg">Attached Documents</h5>

						<div className="spc--bottom--xxlrg">
							<input type="checkbox" className="input--check" disabled id="chkFidelityMPA" checked />
							<label htmlFor="chkFidelityMPA">{shareFileName}</label>
						</div>
					</React.Fragment>
				)}
				{this.renderFieldsRequired()}
			</React.Fragment>
		);
	};
	setAllFieldsRequired = e => {
		if (e.target.checked) {
			this.setState(
				{ fieldsSelected: map(this.state.fieldOptions, option => option.fieldName) },
				this.formChangedCallback
			);
		} else {
			this.setState({ fieldsSelected: [] }, this.formChangedCallback);
		}
	};

	renderFieldsRequired = () => {
		const fields = this.state.fieldOptions;
		if (!fields) return;

		const fieldsCopy = [...fields];
		const signerIdentificationTypeField = find(fields, field => field.fieldName === 'SignerIdentificationType');
		if (signerIdentificationTypeField) {
			remove(fieldsCopy, field => includes(signerIdentificationTypeFields, field.fieldName));
		}
		return (
			<React.Fragment>
				<h5 className="spc--bottom--lrg">Select which fields are required for the merchant to submit</h5>
				<div className="spc--bottom--xlrg">
					<input
						type="checkbox"
						className="input--check"
						id="selectAll"
						onChange={this.setAllFieldsRequired}
						checked={this.state.fieldsSelected.length == this.state.fieldOptions.length}
					/>
					<label htmlFor="selectAll">Select All</label>
				</div>
				<div className="row">
					{map(fieldsCopy, displayField => (
						<div className="col col-sml-12 col-med-6 spc--bottom--med" key={displayField.fieldName}>
							<input
								id={displayField.fieldName}
								className="input--check"
								type="checkbox"
								checked={includes(this.state.fieldsSelected, displayField.fieldName)}
								onChange={this.onFieldSelected}
							/>
							<label htmlFor={displayField.fieldName}>{displayField.displayName}</label>
						</div>
					))}
				</div>
			</React.Fragment>
		);
	};

	scrollTo = id => {
		let elem = document.getElementById(id);
		elem && elem.scrollIntoView({ behavior: 'smooth', block: 'center' });
	};

	focusField = name => {
		const elem = first(document.getElementsByName(name));
		if (!elem) return;
		elem.focus();
		elem.click();
	};

	renderInvalidFields() {
		const { invalidErrorList } = this.state;
		if (isEmpty(invalidErrorList)) return null;
		return (
			<div className="col-sml-12 type--validation spc--bottom--med">
				<ul>
					{map(invalidErrorList, elem => (
						<ClickableErrorMessage
							key={elem.path}
							errorPrefix=""
							elem={elem}
							scrollTo={this.scrollTo}
							focusField={this.focusField}
						/>
					))}
				</ul>
			</div>
		);
	}

	render() {
		const { saveResults, invalidErrorList } = this.state;
		const hasValidationErrors = !isEmpty(invalidErrorList);
		return (
			<React.Fragment>
				<div className="modal__header flex--nowrap">
					<h4>Share MPA Form with Merchant</h4>
					<button className="btn btn--action btn--action--secondary" onClick={this.props.closeModal}>
						<i className="icon icon--sml icon--close"></i>
					</button>
				</div>
				<div className="modal__body" ref={this.detailsRef}>
					<ModalWrapper modal={this.state.modal} onModalClose={this.openCloseModal} />
					{this.renderErrorMessage()}
					{this.renderSaveResults()}
					{this.renderInvalidFields()}
				</div>
				{!saveResults ? (
					<div className="modal__footer">
						<div className="flex--secondary flex--gap--sml fullwidth">
							<div className="flex--primary flex--gap--sml">
								<button
									disabled={this.state.linkToCopy == null || hasValidationErrors}
									onClick={this.copyEmailLink}
									className={`btn btn--med btn--tertiary ${
										this.state.linkToCopy == null && !hasValidationErrors ? 'btn--loader' : ''
									}`}
								>
									<span>Copy Form Link</span>
									<i className="icon icon--sml icon--copy"></i>
								</button>
								{this.state.copied ? <p className="type--color--success">Copied!</p> : null}
							</div>
							<button
								onClick={this.handleSendClick}
								disabled={hasValidationErrors}
								className="btn btn--med btn--primary"
							>
								Send
							</button>
						</div>
					</div>
				) : null}
			</React.Fragment>
		);
	}
}

ShareMPAForm.propTypes = {
	merchantName: PropTypes.string.isRequired,
	merchantEmail: PropTypes.string.isRequired,
	appid: PropTypes.string.isRequired,
	closeModal: PropTypes.func.isRequired,
};

export default withLoader(ShareMPAForm);
